<div
  *ngIf="{ value: value$ | async, loading: loading$ | async, editMode: editMode$ | async } as state"
  class="flex-row align-items-center"
>
  <ng-container *ngIf="analystStates$ | async as analysisStates; else fetching">
    <mat-form-field class="full-width">
      <mat-label>{{ 'label.select_analyst' | translate }}</mat-label>
      <mat-select
        id="selectAnalyst"
        (selectionChange)="changeUser($event)"
        [value]="state.value"
        [compareWith]="byId"
        [disabled]="state.loading"
      >
        <mat-select-trigger *ngIf="state.value">
          <ng-container
            *ngTemplateOutlet="optionTpl; context: { $implicit: state.value, showReference: true }"
          ></ng-container>
        </mat-select-trigger>
        <mat-optgroup [label]="'title.users' | translate" *ngIf="analysisStates?.length">
          <mat-option [value]="analystState" *ngFor="let analystState of analysisStates">
            <ng-container
              *ngTemplateOutlet="optionTpl; context: { $implicit: analystState, showReference: true }"
            ></ng-container>
          </mat-option>
        </mat-optgroup>
      </mat-select>
      <mat-hint *ngIf="!state.editMode">{{ 'hint.read_only_analysis' | translate }}</mat-hint>
    </mat-form-field>
    <button
      mat-icon-button
      *ngIfRole="['analystmanager']"
      (click)="toggleReferenceAnalysis()"
      [style.marginBottom.px]="12"
      [disabled]="!state.value?.isCurrentUser"
      [class.text-primary]="state.value?.isCurrentUser"
      data-cy="button-mark-reference"
    >
      <ng-container *ngIf="state.value?.isCurrentUser; else nonActionableTpl">
        <ng-container *ngIf="!state.value.analysisState.data?.reference; else removeReferenceTpl">
          <i class="ri-bookmark-3-line" [matTooltip]="'tooltip.mark_reference_analysis' | translate"></i>
        </ng-container>
        <ng-template #removeReferenceTpl>
          <i class="ri-bookmark-3-fill" [matTooltip]="'tooltip.unmark_reference_analysis' | translate"></i>
        </ng-template>
      </ng-container>
      <ng-template #nonActionableTpl></ng-template>
    </button>
  </ng-container>
  <ng-template #fetching> {{ 'info.fetching' | translate }}... </ng-template>
</div>

<ng-template #optionTpl let-analystState let-showReference="showReference">
  <div class="flex-row align-items-center justify-content-between">
    <span
      [matTooltip]="analystState.analysisState.state"
      class="state-indicator flex-grow-0"
      [class.state-indicator--in-progress]="analystState.analysisState.state === 'inProgress'"
      [class.state-indicator--analyzed]="analystState.analysisState.state === 'analyzed'"
    ></span>
    <span class="flex-grow-1">
      <ng-container>
        {{ analystState.isCurrentUser ? ('core.me' | translate | uppercase) : analystState.analysisState.user?.username }}
      </ng-container>
    </span>

    <span class="text-gray align-self-end flex-row align-items-center">
      <i
        class="ri-bookmark-3-fill text-primary"
        *ngIf="analystState.analysisState.data?.reference && showReference"
        [matTooltip]="'tooltip.marked_as_reference_analysis' | translate"
        data-cy="reference-analysis-mark"
      ></i>
      <small>{{ analystState.analysisState.updatedAt | date : 'short' }}</small></span
    >
  </div>
</ng-template>
