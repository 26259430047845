export interface RoleAttributes {
  id?: string;
  name: string;
}

export class Role {

  private users: string[];

  constructor(private readonly attributes: RoleAttributes) {
    this.users = [];
  }

  public get id() {
    return this.attributes.id;
  }

  public get name() {
    return this.attributes.name;
  }

  public get usersIds(): ReadonlyArray<string> {
    return this.users;
  }

  public grantTo(userId: string): Role {
    this.users.push(userId);
    return this;
  }
}
