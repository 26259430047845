import { Injectable } from "@angular/core";
import { Organization, Query, Workspace } from "@telespot/sdk";

export interface ListWorkspacesOptions {
  organization: Organization;
  skip?;
  limit?;
  filters?;
}

@Injectable({
  providedIn: "root",
})
export class WorkspaceService {
  /* Main Functions */
  public async count(options: ListWorkspacesOptions) {
    const query = this._mapOptionsToQuery(options);
    return query.count();
  }

  public async list(options: ListWorkspacesOptions) {
    const query = this._mapOptionsToQuery(options);

    const workspaces = await query.find();

    return workspaces;
  }

  /* Helper Functions */
  private _mapOptionsToQuery(options: ListWorkspacesOptions) {
    const { organization, skip, limit, filters } = options;

    const query = new Query(Workspace)
      .equalTo("organization", organization)
      .includeAll()
      .descending("createdAt");

    if (filters) {
      for (const filter of filters) {
        const nameConstraints = { ...filter.constraints };
        if (nameConstraints.contains) {
          const value = nameConstraints.contains as string;
          query.matches("name", new RegExp(`${value.toLowerCase()}`), "i");
        }
      }
    }

    if (limit) {
      query.limit(limit);
    }
    if (skip) {
      query.skip(skip);
    }

    return query;
  }
}
