import { Object as ParseObject } from "parse";
import { MethodType } from "../method-type/method-type.model";
import { Pipeline } from "../pipeline/pipeline.model";

/**
 * Represents the assignment of pipelines to protocols.
 *
 * @export
 * @class ProtocolPipelines
 * @extends {ParseObject}
 */

export enum ProtocolPipelineFields {
  TABLE = "ProtocolPipeline",
  METHOD_TYPE = "methodType",
  PIPELINE = "pipeline",
}

export class ProtocolPipeline extends ParseObject {
  static className = "ProtocolPipeline";

  constructor() {
    super(ProtocolPipeline.className);
  }

  get methodType(): MethodType {
    return this.get(ProtocolPipelineFields.METHOD_TYPE);
  }

  set methodType(methodType: MethodType) {
    this.set(ProtocolPipelineFields.METHOD_TYPE, methodType);
  }

  get pipeline(): Pipeline {
    return this.get(ProtocolPipelineFields.PIPELINE);
  }

  set pipeline(pipeline: Pipeline) {
    this.set(ProtocolPipelineFields.PIPELINE, pipeline);
  }
}
