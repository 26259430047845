import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedLocalizationModule } from '@shared/localization';

import { DataTableCellTemplateDirective } from './data-table/data-table-cell-template.directive';
import { DataTableHeaderTemplateDirective } from './data-table/data-table-header-template.directive';
import { DataTableToolbarDirective } from './data-table/data-table-toolbar-template.directive';
import { DataTableComponent } from './data-table/data-table.component';
import { TableFilterBadgeComponent } from './table-filter-badge/table-filter-badge.component';
import { TableFiltersComponent } from './table-filters/table-filters.component';
import { TableSearchModule } from './table-search/table-search.component';
import { TableDecryptionComponent } from './table-decryption/table-decryption.component';
@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatRadioModule,
    RouterModule,
    MatTooltipModule,

    MatButtonToggleModule,
    MatProgressBarModule,
    MatDialogModule,
    OverlayModule,
    TranslateModule,
    SharedLocalizationModule,
    TableSearchModule,
  ],
  declarations: [
    DataTableComponent,
    DataTableCellTemplateDirective,
    DataTableHeaderTemplateDirective,
    DataTableToolbarDirective,
    TableFiltersComponent,
    TableFilterBadgeComponent,
    TableDecryptionComponent,
  ],
  exports: [DataTableComponent, DataTableCellTemplateDirective, DataTableHeaderTemplateDirective],
})
export class DataTableModule { }
