import { inject, InjectionToken } from '@angular/core';
import { AnalysisState, Case, Organization, Query } from '@telespot/sdk';
import { combineLatest, defer, from, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, skipWhile } from 'rxjs/operators';

import { AuthService } from '../services/auth/auth.service';
import { DataService } from '../services/data/data.service';

export interface IOrganizationData {
  organization: Organization;
  selected: boolean;
  new_cases: Observable<boolean>;
  last_case?: Observable<AnalysisState>;
}

export const USER_ORGANIZATIONS = new InjectionToken<Observable<IOrganizationData[]>>('USER_ORGANIZATIONS');

const userOrganizationsDataFactory = (authService: AuthService, dataService: DataService) => {
  return combineLatest([
    authService.organizations$.pipe(
      skipWhile((orgs) => !orgs.length),
      distinctUntilChanged(),
      map((organizations) =>
        organizations.map<IOrganizationData>((organization) => ({
          organization,
          selected: false,
          new_cases: defer(() =>
            dataService
              .first(
                new Query(Case)
                  .equalTo('organization', organization.toPointer())
                  .doesNotMatchKeyInQuery(
                    'objectId',
                    'case.objectId',
                    new Query(AnalysisState)
                      .equalTo('user', authService.currentUser?.toPointer())
                      .select('case')
                      .doesNotExist('sample')
                  )
              )
              .then((_case) => !!_case)
          ).pipe(shareReplay({ bufferSize: 1, refCount: true })),
          last_case: defer(() =>
            dataService.first(
              new Query(AnalysisState)
                .descending('updatedAt')
                .equalTo('user', authService.currentUser?.toPointer())
                .include(['case.workspace.caseType'])
                .select('case')
                .matchesQuery('case', new Query(Case).equalTo('organization', organization.toPointer()))
            )
          ).pipe(shareReplay({ bufferSize: 1, refCount: true })),
        }))
      )
    ),
    authService.currentOrganization$.pipe(
      filter((current) => !!current),
      distinctUntilChanged((previous, current) => previous.id === current.id)
    ),
  ]).pipe(
    map(([organizations, current]) =>
      organizations.map((organization) => ({
        ...organization,
        selected: organization.organization.id === current.id,
      }))
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  );
};

export const userOrganizationsDataProvider = {
  provide: USER_ORGANIZATIONS,
  useFactory: userOrganizationsDataFactory,
  deps: [AuthService, DataService],
};

export const CURRENT_ORGANIZATION = new InjectionToken<Observable<Organization>>('CURRENT_ORGANIZATION', {
  factory: () => inject(AuthService).currentOrganization$,
});
