<span class="button-bar align-right">
  <button class="btn btn-flat-primary btn-auto" (click)="create()">
    <i class="ri-add-circle-line"></i>
    {{ 'button.new_analysis_type' | translate | uppercase }}
  </button>
</span>
<div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between" *ngIf="!showAnalysisTypeEditor">
  <div class="column">
    <h4>
      {{ 'title.existing_analysis_types' | translate }}
    </h4>

    <ts-data-table [config]="tableConfig" [dataSource]="dataSource">
      <ng-template dataTableCell="available_analysisTypes" let-item>
        <ng-container>
          <li>
            <span> {{ item.analysisType.name }}</span>
            <span class="button-bar stretch">
              <button
                mat-icon-button
                class="btn btn-flat-primary align-right"
                data-cy="button-add-analysis"
                (click)="addAnalysisType(item.analysisType)"
              >
                <i class="ri-add-circle-line"></i>
              </button>
            </span>
          </li>
        </ng-container>
      </ng-template>
    </ts-data-table>
  </div>
  <div class="column">
    <h4>
      {{ 'title.analysis_types' | translate }}
    </h4>
    <ul class="list-group">
      <ng-container *ngIf="analysisTypes.length; else noItems">
        <li
          class="list-group-item"
          *ngFor="let analysisType of analysisTypes"
          cdkDrag
          [cdkDragData]="analysisType"
          data-bs-toggle="tooltip"
          [title]="analysisType.description || ('tooltip.no_description' | translate)"
        >
          <span>{{ analysisType.name }}</span>
          <span class="button-bar stretch">
            <button mat-icon-button class="btn btn-flat-primary" data-cy="edit-analysisType" (click)="edit(analysisType)">
              <i class="ri-pencil-line"></i>
            </button>
            <button mat-icon-button class="btn btn-flat-danger" (click)="removeAnalysisType(analysisType)">
              <i class="ri-delete-bin-6-line"></i>
            </button>
          </span>
        </li>
      </ng-container>
    </ul>
    <ng-template #noItems> Add some analysis task </ng-template>
  </div>

  <ts-analysis-type-editor
    *ngIf="showAnalysisTypeEditor"
    [analysisType]="analysisType"
    (submitted)="addAnalysisType($event)"
  >
  </ts-analysis-type-editor>
</div>
