import { CommonModule } from "@angular/common";
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgModule,
  Output,
} from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[scrollDetector]",
})
export class ScrollDetectorDirective {
  @Input() scrollThreshold = 0.5;
  @Output() scrolledThreshold = new EventEmitter<void>();

  private previousScrollTop = 0;

  constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  @HostListener("scroll")
  onScroll(): void {
    const { scrollTop, clientHeight, scrollHeight } =
      this.elementRef.nativeElement;

    const scrollPosition = scrollTop + clientHeight;
    const targetThreshold = scrollHeight - scrollHeight * this.scrollThreshold;

    const isScrollingDown = scrollTop > this.previousScrollTop;

    if (isScrollingDown && scrollPosition >= targetThreshold) {
      this.scrolledThreshold.emit();
    }

    this.previousScrollTop = scrollTop;
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [ScrollDetectorDirective],
  exports: [ScrollDetectorDirective],
})
export class ScrollDetectorDirectiveModule {}
