export class Filename {

  private _name: string;
  private _extension: string;

  constructor(filename: string) {
    const fileComponents = filename.split(".");
    const ext = fileComponents.pop().toLowerCase();
    const nameWithoutExt = fileComponents.join(".");

    this._name = nameWithoutExt;
    this._extension = ext;
  }

  get name(): string {
    return this._name;
  }

  get extension(): string {
    return this._extension;
  }
}
