import { AnalysisProtocolTask } from "./analysis-protocol-task";
import {
  NO_TAGS_COLOR,
  FUZZY_COLOR,
  ROI_COLORS,
  DEFAULT_ROI_COLOR,
} from "./colors";

export class LabelUtils {
  getLabelColor(
    labels: string[],
    opaque = false,
    activeTasks: AnalysisProtocolTask[]
  ) {
    if (!labels) return;
    if (!labels.length) return NO_TAGS_COLOR;

    let color: string;
    let alpha = 0.2;
    if (labels.length > 1) {
      color = FUZZY_COLOR;
    } else {
      const task = activeTasks.find((task) => {
        const matchingOption = task?.options.find(
          (option) => option.uuid === labels[0]
        );
        return !!matchingOption;
      });

      const option = task?.options.find((option) => option.uuid === labels[0]);
      color = option?.color;
      if (option?.alpha) alpha = option?.alpha;

      const taskIndex = task?.options.indexOf(option);

      if (!color || color?.length !== 7)
        color =
          ROI_COLORS?.[taskIndex % ROI_COLORS.length] || DEFAULT_ROI_COLOR;
    }

    return this.hexToRGBA(color, opaque ? 1 : alpha);
  }

  hexToRGBA(hex: string, opacity: number): string {
    hex = hex.replace("#", "");

    if (hex.length === 3) {
      hex = hex
        .split("")
        .map((hexChar) => hexChar + hexChar)
        .join("");
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
}
