<div class="card">
  <table mat-table [dataSource]="dataSource" class="spotlab-table" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'core.name' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item">
        {{ item.deviceType.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'core.version' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item">
        {{ item.deviceType.version }}
      </td>
    </ng-container>
    <ng-container matColumnDef="numDevices">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'core.num_devices' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item">
        {{ item.numDevices | async }}
      </td>
    </ng-container>
    <ng-container matColumnDef="_actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'core.actions' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item" (click)="$event.stopPropagation()">
        <button mat-icon-button [matMenuTriggerFor]="menu" data-cy="button-menu">
          <i class="ri-more-line"></i>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="delete(item)" class="flex-row align-items-center" data-cy="button-menu-delete">
            <i class="ri-delete-bin-6-line"></i>
            {{ 'core.delete' | translate }}
          </button>
        </mat-menu>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns$ | async"></tr>
    <tr mat-row *matRowDef="let item; columns: visibleColumns$ | async" [routerLink]="['edit', item.deviceType.id]"></tr>
  </table>
</div>
