import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { HttpClientTestingModule } from '@angular/common/http/testing';
import { NgModule } from '@angular/core';
import { environment } from '@telespot/shared/environment';

import { ILoggerConfig, LOGGER_CONFIG, LoggerLevel } from './logger.config';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: LOGGER_CONFIG,
      useValue: {
        level: environment.logging?.level ?? 0,
        serverLogLevel: environment.serverLogging?.level ?? 5,
        fullStoryLogs: environment.recorder?.enable && !!environment.recorder?.loggingLevel,
        fullStoryLogLevel: environment.recorder?.loggingLevel,
        httpHeaders: new HttpHeaders()
          .set('Content-Type', 'application/json; charset=utf-8')
          .set('X-API-Key', environment.serverLogging?.apiKey)
          .set('X-App-Id', environment.serverLogging?.appId),
      } as ILoggerConfig,
    },
  ],
})
export class LoggerModule {}

@NgModule({
  imports: [HttpClientTestingModule, LoggerModule],
  exports: [LoggerModule],
  providers: [
    {
      provide: LOGGER_CONFIG,
      useValue: {
        level: LoggerLevel.DEBUG,
        serverLogLevel: null,
      } as ILoggerConfig,
    },
  ],
})
export class LoggerTestingModule {}
