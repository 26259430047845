<form [formGroup]="form" *ngIf="form" (submit)="submit()" (reset)="cancel()">
  <div class="card-body">
    <mat-form-field style="width: 100%">
      <input
        matInput
        type="text"
        placeholder="{{ 'label.display_name' | translate | uppercase }}"
        formControlName="displayName"
      />
      <mat-hint>
        {{ task.name }}
      </mat-hint>
    </mat-form-field>

    <mat-label>Type</mat-label>
    <mat-form-field class="full-width" [floatLabel]="'never'">
      <mat-select
        formControlName="type"
        [placeholder]="'type'"
        (valueChange)="updateBasedOnType($event)"
      >
        <mat-option
          *ngFor="let option of analysisTypeTasks"
          [value]="option.type"
          >{{ option.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <ng-container
      *ngIf="
        form.value.type === 'selection' ||
        form.value.type === 'switcher' ||
        form.value.type === 'segmentation'
      "
    >
      <mat-label for="">
        {{ "label.options" | translate }}
      </mat-label>
      <mat-form-field style="margin-right: 4px; flex-grow: 1; width: 100%">
        <mat-chip-list #chipList style="width: 100%">
          <mat-chip
            *ngFor="
              let defaultSampleName of optionsFormArray.controls;
              let i = index
            "
            [selectable]="true"
            [removable]="true"
            [color]="defaultSampleName?.get('name').errors ? 'warn' : 'primary'"
            [selected]="defaultSampleName?.get('name').errors"
            (removed)="removeOptionAt(i)"
            (click)="editOptionAt(i)"
          >
            <p
              style="margin: 0"
              [style.color]="defaultSampleName?.get('color').value"
            >
              {{ defaultSampleName?.get("displayName").value }}
            </p>
            <mat-icon
              style="margin-left: 4px"
              class="text-primary"
              *ngIf="defaultSampleName?.get('ai_models').value.length"
              fontSet="remixicon"
              fontIcon="ri-robot-line"
            ></mat-icon>
            <mat-icon
              matChipRemove
              fontSet="remixicon"
              fontIcon="ri-close-line"
            ></mat-icon>
          </mat-chip>
          <input
            placeholder=""
            style="width: 100%"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addOption($event)"
          />
        </mat-chip-list>
      </mat-form-field>
      <mat-error *ngIf="form.get('options')?.invalid">{{
        "label.invalid_character_error" | translate
      }}</mat-error>
    </ng-container>

    <div>
      <ng-container>
        <mat-checkbox
          formControlName="assetSpecific"
          [matTooltip]="'tooltip.asset_specific' | translate"
        >
          {{ "check.asset_specific" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>

    <div>
      <ng-container *ngIf="form.value.type === 'selection'">
        <mat-checkbox
          formControlName="multiple"
          [matTooltip]="'tooltip.analysis_selection_multiple' | translate"
        >
          {{ "input.multiple" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>
  </div>

  <div class="card-body">
    <div>
      <ng-container *ngIf="form.value.type === 'selection'">
        <mat-checkbox formControlName="allowAddingNewClasses">
          {{ "check.allow_new_classes" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>
    <div>
      <ng-container *ngIf="form.value.type === 'selection'">
        <mat-checkbox
          formControlName="roiSelection"
          (change)="updateBasedOnROIselection($event)"
        >
          {{ "label.roi_selection" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>

    <div *ngIf="form.value.type === 'selection' && form.value.roiSelection">
      <label for="">{{ "label.roi_selection_type" | translate }}</label>
      <mat-radio-group formControlName="roiSelectionType" class="flex-column">
        <mat-radio-button
          [value]="option"
          *ngFor="let option of ['center', 'boundingBox', 'freeHand']"
          [disabled]="option === 'freeHand'"
        >
          {{ "label.roi_selection_type_" + option | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="card-footer card-actions">
    <!-- Editor buttons -->
    <button
      class="btn btn-flat-primary card-actions__action card-actions__action--grow"
      type="reset"
      (click)="cancel()"
    >
      <i class="ri-close-fill"></i>{{ "button.cancel" | translate }}
    </button>
    <button
      class="btn btn-primary card-actions__action card-actions__action--grow"
      [disabled]="form.invalid"
      type="submit"
    >
      <i class="ri-save-line"></i>{{ "button.save" | translate }}
    </button>
  </div>
</form>

<ng-template #editOptionDialog let-data>
  <ts-analysis-option-editor
    [option]="data?.option"
    (submitted)="closeDialog($event)"
  >
  </ts-analysis-option-editor>
</ng-template>
