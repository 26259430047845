import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@telespot/web-core";
import { ExitEditorGuard } from "@telespot/shared/util";
import { NAV_BAR_ITEMS } from "@telespot/shell";

import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { ThanksComponent } from "./components/thanks/thanks.component";
import { TelespotPreloadingStrategy } from "./telespot-preloading-strategy";

const routes: Routes = [
  {
    path: "me",
    loadChildren: () =>
      import("@telespot/user-dashboard/feature").then(
        (m) => m.TelespotUserDashboardFeatureModule
      ),
    canActivate: [AuthGuard],
    data: {
      allowedRoles: [
        "admin",
        "analyst",
        "analystmanager",
        "technician",
        "technicianmanager",
      ],
      title: "User",
    },
  },
  {
    path: "reports",
    loadChildren: () =>
      import("@telespot/reports/feature").then(
        (m) => m.TelespotReportsFeatureModule
      ),
    canActivate: [AuthGuard],
    data: { allowedRoles: ["admin", "analyst", "analystmanager"] },
  },
  {
    path: "analyze",
    loadChildren: () =>
      import("@telespot/analysis-refactor/feature").then(
        (m) => m.TelespotAnalysisRefactorFeatureModule
      ),
    canActivate: [AuthGuard],
    data: {
      allowedRoles: [
        "admin",
        "analyst",
        "analystmanager",
        "technician",
        "guest",
      ],
    },
  },
  {
    path: "devices",
    canLoad: [AuthGuard],
    data: { allowedRoles: ["admin"] },
    loadChildren: () =>
      import("@telespot/devices/feature").then(
        (m) => m.TelespotDevicesFeatureModule
      ),
  },
  {
    path: "organizations",
    loadChildren: () =>
      import("@telespot/dashboard/feature").then(
        (m) => m.TelespotDashboardFeatureModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: { allowedRoles: ["admin"] },
  },
  {
    path: "protocols",
    loadChildren: () =>
      import("@telespot/protocols/feature").then(
        (m) => m.TelespotProtocolsFeatureModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: { allowedRoles: ["admin"] },
  },
  {
    path: "acquire",
    loadChildren: () =>
      import("@telespot/acquisition").then((m) => m.AcquisitionFeatureModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      allowedRoles: ["admin", "technician", "technicianmanager"],
      acquisition: true,
    },
  },
  {
    path: "support",
    loadChildren: () =>
      import("@telespot/support/feature").then((m) => m.SupportFeatureModule),
    canActivate: [AuthGuard],
  },

  {
    path: "settings",
    loadChildren: () =>
      import("@telespot/settings/feature-shell").then(
        (m) => m.TelespotSettingsFeatureShellModule
      ),
  },
  {
    path: "thanks",
    component: ThanksComponent,
    canDeactivate: [ExitEditorGuard],
  },
  {
    path: "workspaces",
    loadChildren: () =>
      import("@telespot/workspaces/feature-shell").then(
        (m) => m.WorkspacesShellModule
      ),
    canActivate: [AuthGuard],
    data: { title: "Workspaces" },
  },
  {
    path: "users",
    loadChildren: () =>
      import("@telespot/users/feature").then(
        (m) => m.TelespotUsersFeatureModule
      ),
    canActivate: [AuthGuard],
    data: { allowedRoles: ["admin", "analystmanager", "technicianmanager"] },
  },
  { path: "", redirectTo: "workspaces", pathMatch: "full" },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: TelespotPreloadingStrategy,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: NAV_BAR_ITEMS,
      useValue: [
        {
          name: "title.workspaces",
          icon: "ri-file-copy-2-line",
          routerLink: "/workspaces",
          tooltip: "tooltip.workspaces",
        },
        {
          name: "title.users",
          icon: "ri-group-line",
          routerLink: "/users",
          tooltip: "tooltip.manage_users",
          roles: ["admin", "analystmanager"],
        },
        {
          name: "title.protocols",
          icon: "ri-briefcase-2-line",
          routerLink: "/protocols",
          tooltip: "tooltip.protocols",
          roles: ["admin"],
        },
        {
          name: "title.devices",
          icon: "ri-device-line",
          routerLink: "/devices",
          tooltip: "tooltip.devices",
          roles: ["admin"],
        },
        {
          name: "title.organizations",
          icon: "ri-archive-drawer-line",
          routerLink: "/organizations",
          tooltip: "title.organizations",
          roles: ["admin"],
        },
        // {
        //   name: "title.acquisition",
        //   icon: "ri-file-add-line",
        //   routerLink: "/acquire",
        //   tooltip: "tooltip.acquisition",
        //   roles: ["admin", "technician", "technicianmanager"],
        // },
        {
          name: "title.billing",
          icon: "ri-calculator-line",
          routerLink: "/billing",
          tooltip: "tooltip.billing",
          roles: ["admin"],
          disabled: true,
        },
      ],
    },
  ],
  declarations: [],
})
export class AppRoutingModule {}
