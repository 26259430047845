import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[step]',
})
export class StepDirective {
  @Input('step') stepId: number;
  @Input() fragment: string;
  @HostBinding() hidden = false;
}
