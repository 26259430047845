import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { OpenseadragonComponent } from "../openseadragon/openseadragon.component";
import { Subject } from "rxjs";
import { skipWhile, takeUntil } from "rxjs/operators";

@Component({
  selector: "ts-metric-scale-reference",
  templateUrl: "./metric-scale-reference.component.html",
  styleUrls: ["./metric-scale-reference.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricScaleReferenceComponent implements OnInit, OnDestroy {
  @Input() osd: OpenseadragonComponent;
  @Input() hspan;

  referenceValuesUm: number[] = [
    10, 20, 25, 50, 100, 200, 250, 500, 1000, 2000, 2500, 5000, 10000,
  ]; // in micromillimeters (µm)
  referenceValuesMM: number[] = [
    0.01, 0.02, 0.025, 0.05, 0.1, 0.2, 0.25, 0.5, 1, 2, 2.5, 5, 10,
  ]; // in millimeters (mm)

  constructor(private cdr: ChangeDetectorRef) {}

  private _destroy$ = new Subject<void>();
  public valueToResizeWidth = 100;
  public metricReferencePix: number;

  public metricReference = "";

  ngOnInit(): void {
    this.osd.viewerReady$
      .pipe(
        skipWhile((_) => !_),
        takeUntil(this._destroy$)
      )
      .subscribe((_) => {
        const viewportWidthPixels =
          this.osd.viewer.viewport.getContainerSize().x;
        this.updateMetricReference(viewportWidthPixels, this.osd.zoom);

        this.osd.viewer.addHandler("zoom", () => {
          const viewportWidthPixels =
            this.osd.viewer.viewport.getContainerSize().x;

          this.updateMetricReference(viewportWidthPixels, this.osd.zoom);
        });
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  updateMetricReference(viewportWidth, zoom): void {
    const divWidthMm = this.hspan / zoom / 5; //max div width 1/5 viewport
    const divWidthµm = divWidthMm * 1000; //(µm)

    let nearestReference = this.referenceValuesMM[0];
    for (const ref of this.referenceValuesMM) {
      if (
        Math.abs(divWidthMm - ref) < Math.abs(divWidthMm - nearestReference)
      ) {
        nearestReference = ref;
      }
    }

    const scaleFactor = nearestReference / divWidthMm;

    this.metricReferencePix = (scaleFactor * viewportWidth) / 5;
    this.metricReference =
      nearestReference >= 1
        ? nearestReference + " mm"
        : nearestReference * 1000 + " µm";

    this.cdr.detectChanges();
  }
}
