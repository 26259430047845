import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataTableConfig, SplDataSource, SplDataSourceConfig } from '@shared/ui';
import { DataService } from '@telespot/web-core';
import { AnalysisType, Query } from '@telespot/sdk';
import { defer, from, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import { AnalysisTypeEditorDialogComponent } from '../analysis-type-editor-dialog/analysis-type-editor-dialog.component';
import { AnalysisRowData } from './analysis-row-data';

interface FindAnalysisTypesRequest {
  sorts: any;
  filters: any;
  skip: number;
  limit: number;
}

@Component({
  selector: 'ts-analysis-types-assignment',
  templateUrl: './analysis-types-assignment.component.html',
  styleUrls: ['./analysis-types-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisTypesAssignmentComponent implements OnInit, OnDestroy, SplDataSourceConfig<AnalysisRowData> {
  private _destroy$ = new Subject<void>();

  public tableConfig: DataTableConfig<AnalysisRowData>;

  // UI
  showAnalysisTypeEditor = false;

  // I/O
  private _analysisTypes: AnalysisType[];
  private _dialogRef: MatDialogRef<any>;

  @Input()
  set analysisTypes(value: AnalysisType[]) {
    this._analysisTypes = value;
  }
  get analysisTypes(): AnalysisType[] {
    return this._analysisTypes || [];
  }
  @Output() analysisTypesChanged = new EventEmitter<AnalysisType[]>();

  selectedAnalysisTypes: string[] = [];

  analysisType: AnalysisType; // AnalysisType passed to the editor

  private previousRequest: FindAnalysisTypesRequest;
  private previousCount = 0;
  private analysisTypesCreated: string[] = [];
  private analysisTypeToInclude = false;
  public oldAvailableAnalysistypes: AnalysisType[];
  public dataSource = new SplDataSource<AnalysisRowData>(this);

  private getFindAnalysisTypeQuery(request: FindAnalysisTypesRequest): Query<AnalysisType> {
    const { filters, skip, limit } = request;
    const AnalysisTypeQuery = new Query(AnalysisType);

    if (filters) {
      for (const { constraints } of filters) {
        for (const [comparison, value] of Object.entries(constraints)) {
          //No other verifications are needed since searchbar only uses 'name' filter
          if (typeof value === 'string') {
            AnalysisTypeQuery.matches('name', new RegExp(`${value.toLowerCase()}`), 'i');
          }
        }
      }
    }
    AnalysisTypeQuery.notEqualTo('mobile', true);
    AnalysisTypeQuery.limit(limit || 100);
    AnalysisTypeQuery.skip(skip || 0);

    return AnalysisTypeQuery;
  }

  public requestNeeded(prevRequest: FindAnalysisTypesRequest): boolean {
    if (!this.previousRequest) {
      return true;
    }
    if (this.analysisTypeToInclude) {
      this.analysisTypeToInclude = false;
      return true;
    }

    if (prevRequest.filters !== this.previousRequest.filters) {
      return true;
    }
    if (prevRequest.sorts !== this.previousRequest.sorts) {
      return true;
    }

    if (prevRequest.skip !== this.previousRequest.skip) {
      return true;
    }
    if (prevRequest.limit !== this.previousRequest.limit) {
      return true;
    }

    return false;
  }

  public async updateFcn(sorts, filters, skip, limit) {
    const request = { sorts, filters, skip, limit };

    const AnalysisTypeQuery = this.getFindAnalysisTypeQuery(request);

    const isRequestNeeded = this.requestNeeded(request);
    this.previousRequest = request;

    const items = isRequestNeeded
      ? this.dataService.find(AnalysisTypeQuery)
      : Promise.resolve(this.oldAvailableAnalysistypes);
    const count = isRequestNeeded ? this.dataService.count(AnalysisTypeQuery) : Promise.resolve(this.previousCount);

    return {
      items: defer(() => items).pipe(
        map((analysisTypesFound) => {
          this.oldAvailableAnalysistypes = analysisTypesFound;
          analysisTypesFound = analysisTypesFound.filter((el) => !this.selectedAnalysisTypes.includes(el.id));

          return analysisTypesFound.map<AnalysisRowData>((el) => ({
            analysisType: el,
            id: el.id,
          }));
        })
      ),
      count: from(count).pipe(map((num) => (this.previousCount = num))),
    };
  }

  constructor(private dataService: DataService, private dialog: MatDialog) {}

  ngOnInit() {
    this.configureTable();
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  private configureTable(): void {
    this.tableConfig = {
      columns: [
        {
          name: 'available_analysisTypes',
          value: (item) => item,
        },
      ],
      showPaginator: true,
      showFilters: false,
      showSearch: true,
      showStatusIndicator: false,
      showRefreshButton: false,
      useQueryParams: false,
    };
  }

  create() {
    this.edit(new AnalysisType());
  }

  edit(analysisType: AnalysisType) {
    this.analysisType = analysisType;
    this._dialogRef =
      this._dialogRef ||
      this.dialog.open(AnalysisTypeEditorDialogComponent, {
        width: '800px',
        hasBackdrop: true,
        data: {
          analysisType,
        },
      });
    this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe((changedAnalysisType) => {
        this.analysisTypesCreated.push(analysisType.id);
        this.addAnalysisType(changedAnalysisType);
      });
  }

  async addAnalysisType(analysisType: AnalysisType) {
    if (analysisType) {
      this.selectedAnalysisTypes.push(analysisType.id);
      this.dataSource.fetch();

      if (this.analysisTypes.indexOf(analysisType) === -1) {
        this.analysisTypes.push(analysisType);
        this.analysisTypesChanged.emit(this.analysisTypes);
      }
    }
  }

  removeAnalysisType(analysisType: AnalysisType) {
    if (this.analysisTypesCreated.includes(analysisType.id)) {
      this.analysisTypesCreated = this.analysisTypesCreated.filter((id) => id !== analysisType.id);
      this.analysisTypeToInclude = true;
    }

    this.analysisTypes = this.analysisTypes.filter((at) => at !== analysisType);
    this.selectedAnalysisTypes = this.selectedAnalysisTypes.filter((id) => id !== analysisType.id);
    this.dataSource.fetch();
    this.analysisTypesChanged.emit(this.analysisTypes);
  }
}
