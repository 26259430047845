import { FeatureConfiguration, FeatureConfigurationRepository } from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";

export class ParseFeatureConfigurationRepository extends ParseBaseRepository implements FeatureConfigurationRepository {

  public async save(config: FeatureConfiguration) {
    await this.parse.Config.save(config);
  }

}
