import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  ProtocolState,
  protocolFeatureKey,
  getProtocols,
  Context,
  getLabels,
} from "./protocol.reducer";
import { StepTask } from "@telespot/sdk";

export const selectProtocolState =
  createFeatureSelector<ProtocolState>(protocolFeatureKey);

export const selectContext = createSelector(
  selectProtocolState,
  (state) => state.context
);

export const selectTaskGroups = createSelector(
  selectProtocolState,
  getProtocols
);

export const allLabels = createSelector(selectProtocolState, (state) =>
  getLabels(state)
);

export const selectTaskGroupsIds = createSelector(
  selectProtocolState,
  (state) => Object.values(state.protocols.ids ?? {})
);

export const selectTaskGroupsWithROITasks = createSelector(
  selectProtocolState,
  (state) =>
    getProtocols(state)
      .filter((at) =>
        at.tasks.some((task) => task.roiSelection && task?.options?.length)
      )
      .reduce((accTasks, group) => {
        return [...accTasks, ...(group.tasks ?? [])];
      }, [])
      .filter((task) => task.roiSelection && task?.options?.length)
);

export const selectProtocolLoading = createSelector(
  selectProtocolState,
  (state) => state.loading
);

export const selectShowPinnedOnly = createSelector(
  selectProtocolState,
  (state) => state.showPinnedOnly
);

export const selectMultipleSelection = createSelector(
  selectProtocolState,
  (state) => state.multiselectEnabled
);

export const selectLabels = createSelector(
  selectProtocolState,
  (state) => state.labels.entities
);

export const selectLabelsId = createSelector(
  selectProtocolState,
  (state) => state.labels.ids
);

export const selectLabelsArray = createSelector(selectProtocolState, (state) =>
  Object.values(state.labels.entities ?? {})
);

export const selectedLabels = createSelector(
  selectLabelsArray,
  (labels) => labels?.filter((label) => label.selected) ?? []
);

export const labelsWithThresholds = createSelector(
  selectLabelsArray,
  (labels) =>
    labels?.reduce((acc, label) => ({ ...acc, [label.uuid]: label.thrdis }), {})
);

export const customLabels = createSelector(
  selectLabelsArray,
  (labels) => labels?.filter((label) => label.custom) ?? []
);

export const selectVisibleLabels = createSelector(
  selectProtocolState,
  (state) =>
    Object.keys(state.labels.entities ?? {}).filter(
      (keyId) => state.labels.entities[keyId].visible
    )
);

export const selectTaskGroupsWithROISelection = createSelector(
  selectProtocolState,
  (state) =>
    getProtocols(state).filter((at) =>
      at.tasks.some((task) => task.roiSelection)
    )
);

export const enableMosaicView = createSelector(selectProtocolState, (state) =>
  getProtocols(state).some((at) =>
    at.tasks.some((task) => task.enableMosaicView)
  )
);

export const selectTaskGroupsForActiveContext = createSelector(
  selectTaskGroups,
  selectContext,
  selectLabels,
  (groups, context, labels) =>
    groups
      .filter((group) =>
        group.tasks.some(
          (task) => task.assetSpecific === (context === Context.ASSET)
        )
      )
      .map((group) => ({
        ...group,
        tasks: group.tasks
          .filter((task) => task.assetSpecific === (context === Context.ASSET))
          .map((task) => ({
            ...task,
            options: task.options?.map((option) => ({
              ...option,
              ...(labels[option.uuid] ?? {}),
            })),
          })),
      }))
);

export const groupPositionTasks = createSelector(
  selectTaskGroups,
  selectLabels,
  (groups, labels) =>
    groups
      .map((p) => ({
        ...p,
        tasks: p.tasks
          .filter(
            (task) => task.assetSpecific && task.type === StepTask.POSITION
          )
          .map((task) => ({
            ...task,
            options: task.options?.map((option) => ({
              ...option,
              ...(labels[option.uuid] ?? {}),
              color: option.color || "#696969",
            })),
          })),
      }))
      .filter((p) => p.tasks.length > 0)
);

export const selectSegmentationTasks = createSelector(
  selectProtocolState,
  (state) =>
    getProtocols(state)
      .filter((at) =>
        at.tasks.some(
          (task) => task.type === StepTask.SEGMENTATION && task?.options?.length
        )
      )
      .reduce((accTasks, group) => {
        return [...accTasks, ...(group.tasks ?? [])];
      }, [])
      .filter(
        (task) => task.type === StepTask.SEGMENTATION && task?.options?.length
      )
);

export const selectHasSegmentationTasks = createSelector(
  selectTaskGroupsForActiveContext,
  (taskGroups) =>
    taskGroups.some((group) =>
      group.tasks.some((task) => task.type === StepTask.SEGMENTATION)
    )
);

export const selectROISelectionTasks = createSelector(
  selectProtocolState,
  (state) =>
    getProtocols(state)
      .filter((at) =>
        at.tasks.some((task) => task.roiSelection && task?.options?.length)
      )
      .reduce((accTasks, group) => {
        return [...accTasks, ...(group.tasks ?? [])];
      }, [])
      .filter((task) => task.roiSelection && task?.options?.length)
);

export const selectHasROITasks = createSelector(
  selectTaskGroupsForActiveContext,
  (taskGroups) =>
    taskGroups.some((group) => group.tasks.some((task) => task.roiSelection))
);

export const checkTaskTypes = createSelector(
  selectHasROITasks,
  selectHasSegmentationTasks,
  (hasROITasks, hasSegmTasks) => ({ hasROITasks, hasSegmTasks })
);

export const visibleTaskGroups = createSelector(
  selectTaskGroupsForActiveContext,
  selectShowPinnedOnly,
  (pipelines, showPinnedOnly) =>
    !showPinnedOnly
      ? pipelines
      : pipelines.map((at) => ({
          ...at,
          tasks: [
            ...at.tasks
              .filter((task) => task.roiSelection)
              .map((task) => ({
                ...task,
                options: task.options?.filter((option) => option.pinned),
              }))
              .filter((task) => task.options?.length > 0),
            ...at.tasks.filter((task) => !task.roiSelection),
          ],
        }))
);

export const hasSelectedLabels = createSelector(selectLabelsArray, (labels) =>
  labels.some((label) => label.selected)
);

export const disabledLabelsForCounter = createSelector(
  selectLabelsArray,
  (labels) =>
    labels.filter((label) => label.removeFromCounter).map((label) => label.uuid)
);

export const customLabelsAvailable = createSelector(
  selectProtocolState,
  (state) =>
    getProtocols(state).some((at) =>
      at.tasks.some((task) => task.allowNewOptions)
    )
);

export const positionTaskIds = createSelector(
  groupPositionTasks,
  (positionTasks) => {
    const positionTaskIds = [];

    positionTasks.forEach((positionTask) => {
      const tasks = positionTask.tasks;

      tasks.forEach((task) => {
        const taskId = task.stepId;

        if (taskId && !positionTaskIds.includes(taskId)) {
          positionTaskIds.push(taskId);
        }
      });
    });

    return positionTaskIds;
  }
);

export const categoryLabels = createSelector(
  allLabels,
  positionTaskIds,
  (allLabels, positionTaskIds) => {
    return Array.from(
      new Set(
        allLabels
          .filter((l) => positionTaskIds.includes(l.taskId))
          .map((label) => label.category)
      )
    );
  }
);

export const labelValues = createSelector(
  allLabels,
  positionTaskIds,
  (allLabels, positionTaskIds) => {
    return Array.from(
      new Set(allLabels.filter((l) => positionTaskIds.includes(l.taskId)))
    );
  }
);
