export class Base64Serializer {
  public static toBase64(object: Record<string, unknown>): string {
    const jsonString = JSON.stringify(object);
    const buffer = Buffer.from(jsonString);

    return buffer.toString('base64');
  }

  public static fromBase64(str: string): Record<string, unknown> {
    const buffer = Buffer.from(str, 'base64');
    const jsonString = buffer.toString();

    return JSON.parse(jsonString);
  }
}
