import {
  Component,
  Input,
  OnDestroy,
  Optional,
  OnInit,
  Output,
  EventEmitter,
  Inject,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
  PipelineEntity,
  ProtocolService,
} from "@telespot/protocols/data-access";

import { Subject } from "rxjs";
import { Location } from "@angular/common";
import { take, takeUntil, tap } from "rxjs/operators";
import { PipelineTaskEditorDialogComponent } from "../pipeline-task-editor-dialog/pipeline-task-editor-dialog.component";
import {
  MethodType,
  Pipeline,
  PipelineTask,
  StepAction,
  StepTask,
} from "@telespot/sdk";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { SUPPORTED_LANGUAGES, SupportedLanguage } from "@shared/localization";
import { SnackbarAlertComponent } from "@shared/ui";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PipelineStepEditorDialogComponent } from "../pipeline-step-editor-dialog/pipeline-step-editor-dialog.component";

@Component({
  selector: "ts-pipeline-editor",
  templateUrl: "./pipeline-editor.component.html",
  styleUrls: ["./pipeline-editor.component.scss"],
})
export class PipelineEditorComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  @Input() embedInCard = true;
  @Input() pipeline: Pipeline;
  @Input() methodType: MethodType;
  @Input() redirectBack = true;
  @Output() submitted = new EventEmitter<Pipeline>();

  private _dialogRef: MatDialogRef<any>;

  selectedStepId: string;
  isNewTask = false;
  showFieldEditor = false;
  currentPipeline: PipelineEntity;
  loading = false;

  form: UntypedFormGroup;

  constructor(
    @Optional() private dialog: MatDialog,
    private route: ActivatedRoute,
    private _location: Location,
    private protocolService: ProtocolService,
    private _snackBar: MatSnackBar,
    private router: Router,
    @Inject(SUPPORTED_LANGUAGES) public supportedLanguages: SupportedLanguage[]
  ) {}

  displayNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const displayNameObject = control.value;

      if (displayNameObject && displayNameObject["en"]) {
        return null;
      }
      return { displayNameForEnRequired: true };
    };
  }

  isDisplayNameRequiredError(): boolean {
    const displayNameControl = this.form.get("displayName");

    return (
      displayNameControl.hasError("displayNameForEnRequired") &&
      (displayNameControl.dirty || displayNameControl.touched)
    );
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (params) => {
        const pipelineId = params.get("pipelineId") ?? this.pipeline?.id;

        this.currentPipeline = pipelineId
          ? await this.protocolService.getPipeline(
              pipelineId ?? this.pipeline.id,
              this.methodType?.id
            )
          : new PipelineEntity({
              id: undefined,
              name: {},
              tasks: [],
              env: "cloud",
            });

        this.setupForm();
      });
  }

  newField() {
    const newField = new PipelineTask({
      name: undefined,
      type: StepTask.TEXT,
      trigger:
        this.form.value.environment === "cloud"
          ? StepAction.ASSET_CREATED
          : StepAction.ACQUISITION_EXIT,
      generateFinding:
        this.form.value.environment === "cloud"
          ? StepAction.SAMPLE_CREATED
          : StepAction.ACQUISITION_EXIT,
    });
    this.editTask(newField, true);
  }

  editTask(task: PipelineTask, isNew?: boolean) {
    this.isNewTask = isNew;
    this.selectedStepId = task.id;
    this.showFieldEditor = true;
    const isCloud = this.form.value.environment === "cloud";
    const tasksFromSelectedStep = isNew
      ? []
      : this.currentPipeline.tasks.filter((t) => t.id === task.id);

    const tasksFromOtherSteps = isNew
      ? this.currentPipeline?.tasks || []
      : (this.currentPipeline.tasks || []).filter((t) => t.id !== task.id);

    this._dialogRef = isCloud
      ? this.dialog.open(PipelineStepEditorDialogComponent, {
          width: "720px",
          hasBackdrop: true,
          data: {
            pipelineTasks: tasksFromSelectedStep,
            tasksFromOtherSteps: tasksFromOtherSteps,
            isCloud: isCloud,
            insideMethodType: this.methodType ? true : false,
          },
        })
      : this.dialog.open(PipelineTaskEditorDialogComponent, {
          width: "780px",
          hasBackdrop: true,
          data: {
            pipelineTask: task,
            isCloud: isCloud,
            insideMethodType: this.methodType ? true : false,
            taskType: task.type,
          },
        });

    this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe((response) => {
        const tasksArray = response
          ? Array.isArray(response)
            ? response
            : [response]
          : null;
        this.addTasks(tasksArray);
      });
  }

  addTasks(tasks: PipelineTask[]) {
    if (!tasks) {
      this.showFieldEditor = false;
      return;
    }

    const tasksToRemove = [];

    this.currentPipeline.tasks
      .filter((t) => t.id === this.selectedStepId)
      .forEach((task) => {
        const index = tasks.findIndex((t) => t.name === task.name);
        if (index === -1) {
          tasksToRemove.push(task);
        }
      });

    this.currentPipeline.removeTasks(tasksToRemove);

    this.currentPipeline.addTasks(tasks);
    this.showFieldEditor = false;
    this.selectedStepId = null;
  }

  setupForm() {
    this.form = new UntypedFormGroup({
      displayName: new UntypedFormControl(this.currentPipeline.name || {}, [
        Validators.required,
        this.displayNameValidator(),
      ]),
      environment: new UntypedFormControl(this.currentPipeline.env ?? "cloud", [
        Validators.required,
      ]),
    });
  }

  async submit() {
    this.loading = true;
    const searchTerm = Object.values(this.form.value.displayName).filter(
      (v) => v
    );

    if (this.form.value.displayName["searchTerm"]?.length > 0) searchTerm.pop();

    this.currentPipeline.name = {
      ...this.form.value.displayName,
      searchTerm: searchTerm.join(),
    };

    this.currentPipeline.env = this.form.value.environment;

    let msg = "";

    try {
      this.pipeline = await this.protocolService.savePipeline(
        this.currentPipeline,
        this.methodType?.id
      );

      this.submitted.emit(this.pipeline);

      if (!this.methodType) this.router.navigateByUrl("/protocols/pipelines");
      msg = "info.saved";
    } catch (error) {
      msg = error.message;
    }

    this._snackBar.openFromComponent(SnackbarAlertComponent, {
      duration: 3000,
      data: {
        title: msg,
        icon: "ri-close-fill",
      },
    });

    this.loading = false;
  }

  cancelTaskEditing() {
    this.showFieldEditor = false;
    // FIX: changes are already applied, re-load fields / use temp. Field clone
  }

  removeTask(stepToRemove: PipelineTask) {
    const isCloud = this.form.value.environment;
    const tasksToRemove =
      isCloud && this.isLabelRelated(stepToRemove.type)
        ? [
            ...(this.currentPipeline?.tasks.filter(
              (t) => t.type === stepToRemove.type
            ) ?? []),
          ]
        : [stepToRemove];

    this.currentPipeline.removeTasks(tasksToRemove);
  }

  drop(event: CdkDragDrop<PipelineTask[]>) {
    const steps = [...this.getSteps()];
    moveItemInArray(steps, event.previousIndex, event.currentIndex);

    const remainingTasks = this.currentPipeline.tasks.filter(
      (task) =>
        !steps.find(
          (step) => step.id === task.id && step.name.name === task.name.name
        )
    );

    this.currentPipeline.tasks = [...steps, ...remainingTasks];
  }

  getSteps(): PipelineTask[] {
    if (this.currentPipeline?.env === "cloud") {
      return this.currentPipeline.tasks.reduce((acc, task) => {
        if (!this.isLabelRelated(task.type)) {
          return [...acc, task];
        }
        const stepAlreadyMapped = acc.some((step) => step.id === task.id);
        if (stepAlreadyMapped) return [...acc];
        return [...acc, task];
      }, []);
    }
    return this.currentPipeline?.tasks ?? [];
  }

  cancel() {
    this.submitted.emit(null);
    if (this.redirectBack) {
      this._location.back();
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  public isLabelRelated(type: StepTask) {
    return (
      type === StepTask.POSITION ||
      type === StepTask.ROIDETECTION ||
      type === StepTask.SEGMENTATION
    );
  }
}
