import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { MatSliderChange } from "@angular/material/slider";

@Component({
  selector: 'ts-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent {

  @Input() public minValue: number;
  @Input() public maxValue: number;
  @Input() public stepValue: number;
  @Input() public value: number;

  @Output() public valueChange = new EventEmitter<number>();

  public onSliderChange(event: MatSliderChange) {
    if (event.value === null) return;

    this.valueChange.emit(event.value);
  }

  public increaseValue() {
    if (this.value >= this.maxValue) return;

    const newValue = Number((this.value + this.stepValue).toFixed(1));

    this.valueChange.emit(newValue);
  }

  public decreaseValue() {
    if (this.value <= this.minValue) return;

    const newValue = Number((this.value - this.stepValue).toFixed(1));

    this.valueChange.emit(newValue);
  }
}
