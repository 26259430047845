<div class="main-container" *ngIf="{ organization: organization$ | async } as data">
  <div class="title">
    <span class="breadcrumbs"><a href="/organizations">Organizations</a> / {{ data.organization?.name }}</span>
    <button class="btn btn-primary" [routerLink]="'edit'" data-cy="button-edit-organization">
      <i class="ri-pencil-line"></i>
      Edit organization
    </button>
  </div>

  <div class="card">
    <div class="card-body flex-column align-items-center">
      <h1>
        {{ data.organization?.name | uppercase }}
      </h1>
      <div class="flex-row align-items-center flex-wrap justify-content-center" style="width: 100%; max-width: 400px">
        <span class="text-secondary" style="margin: 0px 4px; text-align: center">
          {{ 'core.license' | translate }}: {{ data.organization?.license?.name || ('core.unknown' | translate) }}
        </span>
        <ts-used-storage
          style="flex: 1 1 200px; margin-left: 6px"
          [total]="data.organization?.license?.maxStorage || 500"
          [used]="data.organization?.usedStorage"
        ></ts-used-storage>
      </div>
    </div>
    <nav mat-tab-nav-bar class="full-width">
      <a
        mat-tab-link
        routerLink="caseTypes"
        routerLinkActive
        #rlCaseTypes="routerLinkActive"
        [active]="rlCaseTypes.isActive"
        class="flex-row align-items-center"
      >
        <i class="ri-briefcase-2-line"></i>
        {{ 'core.casetypes' | translate | uppercase }}
      </a>

      <a
        mat-tab-link
        routerLink="users"
        routerLinkActive
        #rlMembers="routerLinkActive"
        [active]="rlMembers.isActive"
        class="flex-row align-items-center"
      >
        <i class="ri-pencil-line"></i>
        {{ 'core.members' | translate | uppercase }}
      </a>

      <a
        mat-tab-link
        routerLink="workspaces"
        routerLinkActive
        #rlWorkspaces="routerLinkActive"
        [active]="rlWorkspaces.isActive"
        class="flex-row align-items-center"
      >
        <i class="ri-file-copy-2-line"></i>
        {{ 'title.workspaces' | translate | uppercase }}
      </a>

      <a
        mat-tab-link
        routerLink="devices"
        routerLinkActive
        #rlDevices="routerLinkActive"
        [active]="rlDevices.isActive"
        class="flex-row align-items-center"
      >
        <i class="ri-mac-line"></i>
        {{ 'title.devices' | translate | uppercase }}
      </a>
    </nav>
  </div>

  <router-outlet></router-outlet>
</div>
