import { Injectable, Provider } from "@angular/core";
import {
  ANALYTICS_PROVIDER,
  AnalyticsProvider,
  AnalyticsService,
} from "@shared/analytics";
import { SecondOpinionService } from "@telespot/analysis-refactor/data-access";
import { takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: "root",
  deps: [SecondOpinionService],
})
class SecondOpinionAnalyticsProvider extends AnalyticsProvider {
  constructor(
    private secondOpinionService: SecondOpinionService,
    private analytics: AnalyticsService
  ) {
    super();
    this.secondOpinionService.events$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this.analytics.logEvent(event);
      });
  }
}

export const SECOND_OPINION_ANALYTICS: Provider = {
  provide: ANALYTICS_PROVIDER,
  useClass: SecondOpinionAnalyticsProvider,
  multi: true,
};
