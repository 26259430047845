import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PipelineTask, StepAction, StepTask, TaskOption } from "@telespot/sdk";
import { Observable, Subject } from "rxjs";
import { take, takeUntil, tap } from "rxjs/operators";
import { v4 } from "uuid";

import { ProtocolService } from "@telespot/protocols/data-access";
import { LabelLangSelectorDialogComponent } from "@telespot/shared/label-creator";
import generateId from "uid";

@Component({
  selector: "ts-pipeline-task-editor",
  templateUrl: "./pipeline-task-editor.component.html",
  styleUrls: ["./pipeline-task-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PipelineTaskEditorComponent implements OnDestroy, OnInit {
  private _destroy$ = new Subject<void>();
  private _dialogRef: MatDialogRef<any>;
  private _task: PipelineTask;

  readonly optionsFormArray = new UntypedFormArray([]);

  isLabelSuggesterVisibleForOption = false;
  isLabelSuggesterVisibleForCategory = false;

  interactionFlag = false;

  @ViewChild("editOptionDialog") _editOptionDialog: TemplateRef<any>;
  @ViewChild("selectAIModelDialog") _selectAIModelDialog: TemplateRef<any>;
  @ViewChild("optionInput") optionInput: ElementRef;
  @Input()
  set task(task: PipelineTask) {
    if (this._task !== undefined && this._task === task) return;
    this._task = task;
    this.setupForm();
  }
  get task() {
    return this._task;
  }

  @Input() isCloud = true;
  @Input() insideMethodType = false;
  @Input() type = undefined;

  @Output() submitted = new EventEmitter<PipelineTask>();

  displayName: string;
  blurTimer: any;

  public readonly pipelineTasks = Object.values(StepTask);
  public readonly stepActions = Object.values(StepAction);

  isNewField = true;
  filteredLabels$: Observable<TaskOption[]>;
  inputOptionValue$ = new Subject<string>();
  inputCategoryValue$ = new Subject<string>();

  form: UntypedFormGroup;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private dialog: MatDialog,
    private protocolService: ProtocolService
  ) {}

  ngOnDestroy() {
    this._destroy$.next();
  }

  ngOnInit(): void {
    this.setupForm();
  }

  onInputFocus() {
    clearTimeout(this.blurTimer);
    this.interactionFlag = false;
    this.isLabelSuggesterVisibleForCategory = true;
  }

  onInputBlur() {
    this.blurTimer = setTimeout(() => {
      if (!this.interactionFlag) {
        this.isLabelSuggesterVisibleForCategory = false;
      }
    }, 150);
  }

  onChipListInputFocus() {
    clearTimeout(this.blurTimer);
    this.interactionFlag = false;
    this.isLabelSuggesterVisibleForOption = true;
  }

  onChipListInputBlur() {
    this.blurTimer = setTimeout(() => {
      if (!this.interactionFlag) {
        this.isLabelSuggesterVisibleForOption = false;
      }
    }, 150);
  }

  displayNameValidator(control) {
    const displayName = control.value;

    if (displayName && displayName.uuid) {
      return null;
    } else {
      return { displayNameInvalid: true };
    }
  }

  setupForm() {
    this.optionsFormArray.clear();
    const commonControls = {
      displayName: new UntypedFormControl(
        this.task.name || { name: "" },
        this.displayNameValidator
      ),
      type: new UntypedFormControl(
        { value: this.type || undefined, disabled: this.isCloud },
        [Validators.required]
      ),
      options: this.optionsFormArray,
    };

    let additionalControls = {};

    if (this.isCloud) {
      additionalControls = {
        assetSpecific: new UntypedFormControl(
          this.task?.generateFinding === StepAction.ASSET_CREATED || false
        ),
        multiple: new UntypedFormControl(this.task.multiple || false),
      };
      this.form = new UntypedFormGroup(commonControls);
    } else {
      additionalControls = {
        trigger: new UntypedFormControl(
          this.task?.trigger || StepAction.ACQUISITION_EXIT,
          [Validators.required]
        ),
        generateFinding: new UntypedFormControl(
          this.task?.generateFinding || StepAction.ACQUISITION_EXIT,
          [Validators.required]
        ),
        counter: new UntypedFormControl(this.task?.counter || false),
      };
    }

    if (this.task?.options?.length) {
      this.task.options.forEach((op) => {
        this.createOption(op);
      });
    }

    this.form = new UntypedFormGroup({
      ...commonControls,
      ...additionalControls,
    });
  }

  createOption(value) {
    const options = this.form.get("options") as UntypedFormArray;
    options.push(
      new UntypedFormGroup({
        name: new UntypedFormControl(value?.value?.en ?? value?.name),
        uuid: new UntypedFormControl(value?.uuid ?? v4()),
        new: new UntypedFormControl(value?.new ?? false),
        value: new UntypedFormControl(
          value?.value ?? { en: value?.name ? value.name : value }
        ),
        color: new UntypedFormControl(
          value?.color ? value.color.replace(/[^,]*(?=\))/, "1") : ""
        ),
        alpha: new UntypedFormControl(value?.alpha ? value?.alpha : null),
        configId: new UntypedFormControl(value?.configId),
        thrdis: new UntypedFormControl(value?.thrdis),
      })
    );
  }

  addOption(event: MatChipInputEvent) {
    let value;
    this._dialogRef = this.dialog.open(LabelLangSelectorDialogComponent, {
      width: "400px",
      hasBackdrop: true,
      data: {
        option: event.value,
      },
    });
    this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe((labelValue) => {
        value = { name: event.value, uuid: v4(), value: labelValue, new: true };
        const input = event.input;
        if ((value.name || "").trim()) {
          this.createOption(value);
        }
        if (input) input.value = "";
      });
  }

  removeOptionAt(index: number) {
    const options = this.form.get("options") as UntypedFormArray;
    options.removeAt(index);
  }

  updateBasedOnType(event) {
    if (
      this.isCloud &&
      (event === StepTask.ROIDETECTION ||
        event === StepTask.POSITION ||
        event === StepTask.SEGMENTATION)
    ) {
      this.form.controls.assetSpecific.patchValue(true);
    }
  }

  cancel() {
    console.warn("Reverting analysis task changes not implemented");
    this.submitted.emit(null);
  }

  async submit() {
    // Reminder: disabled control values are not stored in form.value, use form.controls.key.value to access them

    this.task.type = this.form.value.type ?? this.task.type;
    this.task.name = this.form.value.displayName || "";

    if (
      this.task.type === StepTask.POSITION ||
      this.task.type === StepTask.ROIDETECTION ||
      this.task.type === StepTask.SEGMENTATION
    ) {
      this.task.options = this.form.value.options;
    }

    if (this.task.type === StepTask.CLASSIFICATION) {
      this.task.options = this.form.value.options;
      this.task.multiple = this.form.controls?.multiple?.value ?? false;
    }

    if (this.isCloud) {
      this.task.generateFinding = this.form.controls.assetSpecific.value
        ? StepAction.ASSET_CREATED
        : StepAction.SAMPLE_CREATED;
    } else {
      this.task.generateFinding = this.form.controls.generateFinding.value;
      this.task.trigger = this.form.controls.trigger.value;
      this.task.counter = this.form.controls.counter.value;
    }

    if (!this.task?.id) this.task.id = "new:" + generateId(6);
    const taskWithSavedLabels = await this.protocolService.saveLabels(
      this.task
    );

    this.submitted.emit(taskWithSavedLabels);
  }

  editOptionAt(index) {
    if (!this.insideMethodType) return;
    this._dialogRef = this.dialog.open(this._editOptionDialog, {
      width: "400px",
      hasBackdrop: true,
      data: {
        option: this.form.value.options[index],
      },
    });
    this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe((updatedOption) => {
        if (updatedOption) {
          (this.form.get("options") as UntypedFormArray).controls[
            index
          ].patchValue(updatedOption);
        }
      });
  }

  closeDialog(data) {
    this._dialogRef.close(data);
  }

  onLabelSuggesterClick(event: Event): void {
    event.stopPropagation();
    this.interactionFlag = true;
  }

  onCategorySelected(value) {
    this.form
      .get("displayName")
      .setValue({ ...value, name: value?.value?.en ?? value.name });
    this.displayName = value?.value?.en ?? value.name;
    this.isLabelSuggesterVisibleForCategory = false;
  }

  onOptionSelected(value) {
    this.createOption(value);
    this.optionInput.nativeElement.value = "";
    this.isLabelSuggesterVisibleForOption = false;
  }

  onInputChange(event: Event, option: boolean) {
    const value = (event.target as HTMLInputElement).value.trim();
    if (option) {
      this.inputOptionValue$.next(value);
    } else {
      this.inputCategoryValue$.next(value);
    }
  }

  selectAIModel() {
    this._dialogRef = this.dialog.open(this._selectAIModelDialog, {
      width: "900px",
      hasBackdrop: true,
      data: {
        isCloud: this.isCloud,
        modelSelected: this.task?.executor,
      },
    });

    this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe((result) => {
        if (result.action === "save") {
          this.task.executor = result?.model;
        }
      });
  }

  getTaskType() {
    return this.form.value.type ?? this.task.type;
  }
}
