import { Entity, SoftDeletable } from "./entity";

export interface UserAttributes extends Entity, SoftDeletable {
  email?: string;
  username: string;
  password: string;
  verified?: boolean;
  verificationToken?: string;
  profile?: any;
  preferedLanguage?: string;
}

export class User implements Entity, SoftDeletable {
  constructor(private attributes: UserAttributes) { }

  public get id(): string {
    return this.attributes.id;
  }

  public get username(): string {
    return this.attributes.username;
  }

  public get email(): string {
    return this.attributes.email;
  }

  public get password(): string {
    return this.attributes.password;
  }

  public get verified(): boolean {
    return this.attributes.verified ?? false;
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get verificationToken(): string {
    return this.attributes.verificationToken;
  }

  public get profile(): any {
    return this.attributes.profile;
  }

  public get preferedLanguage(): string {
    return this.attributes.preferedLanguage;
  }

  public get deletedAt(): Date {
    return this.attributes.deletedAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }

  public setVerificationToken(token: string): void {
    this.attributes.verificationToken = token;
    this.attributes.verified = false;
  }

  public verifyUser() {
    this.attributes.verified = true;
    this.attributes.verificationToken = undefined;
  }

  public changePassword(password: string): void {
    this.attributes.password = password;
  }


  public rename(username: string): void {
    this.attributes.username = username;
  }
}
