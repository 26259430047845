<ng-container
  *ngIf="{
    totalCount: totalCount$ | async,
    modelStats: modelStats$ | async,
    modeSelected: modeSelected$ | async
  } as data"
>
  <mat-expansion-panel
    class="custom-mat-expansion-panel custom-mat-expansion-panel--padding-10 custom-scrollbar"
    *ngIf="data?.totalCount"
  >
    <mat-expansion-panel-header>
      <mat-panel-title
        class="flex-row justify-content-between align-items-center"
      >
        <div>
          <span style="font-size: smaller"
            >{{ "label.total_rois_count" | translate | uppercase }}:</span
          >
          <b
            style="margin-left: 10px"
            data-cy="rois-total-count"
            id="total-count"
            >{{ data?.totalCount }}</b
          >
        </div>
        <div (click)="$event.stopPropagation()">
          <a
            class="panel-mode"
            [class.selected]="data?.modeSelected === panelMode.counter"
            [routerLink]="[]"
            queryParamsHandling="preserve"
            (click)="changePanelMode(panelMode.counter)"
          >
            <i class="ri-timer-line"></i
          ></a>
          <a
            class="panel-mode"
            [class.selected]="data?.modeSelected === panelMode.percentage"
            [routerLink]="[]"
            queryParamsHandling="preserve"
            (click)="changePanelMode(panelMode.percentage)"
          >
            <i class="ri-percent-line"></i
          ></a>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div
      class="flex-row flex-wrap align-items-start labels-total-progress-container flex-column align-items-start"
    >
      <div class="flex-row flex-wrap" style="margin-top: 10px">
        <ng-container *ngFor="let modelTotal of data?.modelStats">
          <span
            *ngIf="modelTotal.count"
            class="model-stats-badge"
            [style.borderColor]="getModelColor(modelTotal.labelId)"
            [style.opacity]="modelTotal.count ? 1 : 0.5"
            [ngClass]="{ disabled: modelTotal.disabled }"
          >
            <span class="model-stats-badge__label"
              >{{ getModelDisplayName(modelTotal.labelId) }}
            </span>
            <span
              class="model-stats-badge__value"
              [style.backgroundColor]="getModelColor(modelTotal.labelId)"
              [attr.data-cy]="
                'rois-count-' + getModelClassName(modelTotal.labelId)
              "
              >{{ modelTotal.count }}
              {{ data.modeSelected === panelMode.counter ? " " : "%" }}
            </span>
          </span>
        </ng-container>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>
