export enum State {
  PENDING = "pending",
  LAUNCHED = "launched",
  PROCESSING = "processing",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

export interface ProcessingStatus {
  state: State;
  cause?: string;
  errorCode?: number;
}
