import { Object as ParseObject } from 'parse';
import { Workspace } from '../workspace/workspace.model';
import { User } from '../user/user.model';

export class Member extends ParseObject {
  static className = 'Member';

  /**
   * Creates an instance of Member.
   * @memberof Member
   */
  constructor({ user, workspace }: { user?: User; workspace?: Workspace } = {}) {
    super(Member.className);
    this.user = user;
    this.workspace = workspace;
  }

  /**
   *
   *
   * @type {User}
   * @memberof Member
   */
  get user(): User {
    return this.get('user');
  }
  set user(newUser: User) {
    this.set('user', newUser);
  }

  /**
   *
   *
   * @type {Workspace}
   * @memberof Member
   */
  get workspace(): Workspace {
    return this.get('workspace');
  }
  set workspace(newWorkspace: Workspace) {
    this.set('workspace', newWorkspace);
  }
}
