import { Component, Inject, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Algorithms } from "@telespot/sdk";

@Component({
  selector: "ts-select-ai-model-dialog",
  templateUrl: "./select-ai-model-dialog.component.html",
  styleUrls: ["./select-ai-model-dialog.component.scss"],
})
export class SelectAIModelDialogComponent {
  constructor(
    @Optional()
    public dialogRef: MatDialogRef<SelectAIModelDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isCloud: boolean;
      modelSelected: Algorithms;
    } = { isCloud: true, modelSelected: undefined }
  ) {}

  close(value) {
    this.dialogRef?.close(value);
  }
}
