<div class="main-container">
  <div class="title">
    <h1>
      {{ 'title.organizations' | translate }}
    </h1>
    <button class="btn btn-primary" [routerLink]="['/organizations', 'new']">
      <i class="ri-add-circle-line"></i>
      {{ 'button.new_organization' | translate }}
    </button>
  </div>

  <div class="card">
    <table mat-table [dataSource]="dataSource" class="spotlab-table" matSort>
      <ng-container matColumnDef="organization">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'core.organization' | translate | uppercase }}
        </th>
        <td mat-cell *matCellDef="let organization">
          <img
            [src]="organization.organization?.logoUrl || 'assets/media/organization_placeholder.svg'"
            class="user-avatar--32"
            style="margin-right: 10px"
            alt="organization logo"
          />
          <span class="text-nowrap">{{ organization.organization?.name }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="license">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'core.license' | translate | uppercase }}
        </th>
        <td mat-cell *matCellDef="let organization" class="text-primary">
          <span
            [class.text-primary]="organization.organization.license?.name === 'Genius'"
            [class.text-green]="organization.organization.license?.name === 'Basic'"
            [class.text-danger]="organization.organization.license?.name === 'Magic'"
          >
            {{ organization.organization.license?.name || 'Unknown' }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="storage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'core.storage' | translate | uppercase }}
        </th>
        <td mat-cell *matCellDef="let organization">
          <ts-used-storage
            [used]="organization.organization.usedStorage || 0"
            [total]="organization.organization.license.maxStorage || 102400"
          ></ts-used-storage>
        </td>
      </ng-container>

      <ng-container matColumnDef="stats">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 140px; padding-right: 0px">
          {{ 'core.information' | translate | uppercase }}
        </th>
        <td mat-cell *matCellDef="let organization">
          <span class="text-secondary flex-row justify-content-around align-items-center">
            <span class="flex-row align-items-center justify-content-center" style="flex: 0 0 60px">
              <i class="ri-group-line"></i>
              <span style="min-width: 32px; text-align: center">{{ organization.stats.numMembers }}</span>
            </span>
            <span class="flex-row align-items-center justify-content-center" style="flex: 0 0 60px">
              <i class="ri-file-copy-2-line"></i>
              <span style="min-width: 32px; text-align: center">{{ organization.stats.numCases }}</span>
            </span>
            <span class="flex-row align-items-center justify-content-center" style="flex: 0 0 60px">
              <i class="ri-mac-line"></i>
              <span style="min-width: 32px; text-align: center">{{ organization.stats.numDevices }}</span>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="visibleColumns$ | async"></tr>
      <tr
        mat-row
        data-cy="organization-item"
        *matRowDef="let organization; columns: visibleColumns$ | async"
        [routerLink]="[organization.organization.id]"
      ></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9999" data-cy="loading-rows">
          <div class="flex-row align-items-center justify-content-center" style="margin: 0 auto">
            <i class="ri-loader-4-line spin"></i>
            {{ 'info.loading_data' | translate }}
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
