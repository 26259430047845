import type Parse from "parse";

import { ParseSubclassFactory } from "./parse-subclass.factory";
import { ObjectTopology } from "./parse.topology";

export interface ParseTokenPaginationOptions<T extends Parse.Object> {
  query: Parse.Query<T>;
  limit: number;
  lastObjectId?: string;
}

// REVIEW: convert generators to Parse.Query decorators and externalise to separate class
export abstract class ParseBaseRepository {

  protected subclasses: ParseSubclassFactory;

  constructor(
    protected readonly parse: typeof Parse,
    protected readonly options: Parse.FullOptions = { useMasterKey: true },
  ) {
    this.subclasses = ParseSubclassFactory.getInstance(parse);
  }

  /**
   * Converts a given query into an async generator used for pagination.
   *
   * @param parseQuery query to iterate over
   * @param limit number of items to return
   * @param offset number of items to skip
   * @deprecated use `getTokenPaginationGenerator`
   */
  protected async *getGeneratorFromQuery<T extends Parse.Object>(
    parseQuery: Parse.Query<T>,
    limit = 50,
    offset = 0
  ): AsyncGenerator<T[]> {
    let hasMore = true;

    let skip = offset;

    parseQuery.limit(limit);

    do {
      parseQuery.skip(skip);

      const items = await parseQuery.find(this.options);

      yield items;

      hasMore = items.length >= limit;

      skip += items.length;

    } while (hasMore);
  }

  /**
   * Converts a given query into an async generator used for pagination.
   *
   * NOTE: regardless sorting criteria, this iterator will also sort by ascending objectId
   *
   * @param param a {@link ParseTokenPaginationOptions} object
   */
  protected async *getTokenPaginationGenerator<T extends Parse.Object>({
    limit,
    query,
    lastObjectId
  }: ParseTokenPaginationOptions<T>): AsyncGenerator<T[]> {

    let nextObjectId = lastObjectId;

    query.limit(limit);
    query.ascending(ObjectTopology.ID);

    do {

      if (nextObjectId) query.greaterThan(ObjectTopology.ID, nextObjectId as any);

      const items = await query.find(this.options);

      nextObjectId = items.length >= limit ? items[items.length - 1]?.id : undefined;

      yield items;

    } while (nextObjectId);
  }
}
