<div class="card" *ngIf="caseTypes$ | async as caseTypes">
  <div class="card-header">
    <h2>
      {{ 'core.casetypes' | translate | uppercase }}
    </h2>
  </div>
  <div class="card-body card-split">
    <div class="card-split__section">
      <h3>{{ 'title.case_types' | translate | uppercase }}</h3>
      <ul class="list-group list-group--no-borders">
        <li class="list-group-item" *ngFor="let item of caseTypes" [class.text-gray]="item.available">
          {{ item.caseType.name }}

          <button mat-icon-button class="btn float-right btn-flat-primary" (click)="addCaseType(item.caseType)">
            <i class="ri-add-circle-line"></i>
          </button>
        </li>
      </ul>
    </div>
    <div class="card-split__section">
      <h3>{{ 'title.enabled_case_types' | translate | uppercase }}</h3>
      <ul class="list-group list-group--no-borders">
        <ng-container *ngFor="let item of caseTypes">
          <li class="list-group-item" *ngIf="item.available">
            {{ item.caseType.name }}
            <button mat-icon-button class="btn float-right btn-flat-danger" (click)="removeCaseType(item.caseType)">
              <i class="ri-close-circle-line"></i>
            </button>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
