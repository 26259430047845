import { Injectable } from '@angular/core';
import { Affiliate, Organization, ParseObject, Query, Role, User } from '@telespot/sdk';
import { environment } from '@telespot/shared/environment';
import { LoggerService } from '@telespot/shared/logger/feature/util';
import { BehaviorSubject, defer, from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _currentOrganization = new BehaviorSubject<Organization>(this.getCurrentOrganization());
  private readonly _organizationsB$ = new BehaviorSubject<Organization[]>([]);
  public readonly organizations$ = this._organizationsB$.asObservable();
  public readonly currentOrganization$ = this._currentOrganization.asObservable();

  get currentOrganizationValue() {
    return this._currentOrganization.value;
  }
  private readonly _currentUserB$ = new BehaviorSubject<User>(this.currentUser);
  public readonly currentUser$ = this._currentUserB$.asObservable();

  /**
   *
   *
   * @type {string}
   * @memberof AuthService
   */
  redirectUrl: string;

  constructor(private _logger: LoggerService) {
    this.updateUserRolesAndOrganizations();
  }

  get sessionToken(): string {
    const key = `Parse/${environment.api.appId}/currentUser`;
    return JSON.parse(localStorage.getItem(key))?.sessionToken;
  }

  /**
   *
   *
   * @readonly
   * @type {User}
   * @memberof AuthService
   */
  get currentUser(): User {
    const roles = JSON.parse(localStorage.getItem('currentUserRoles') || '[]');
    const affiliations = JSON.parse(localStorage.getItem('currentUserAffiliations') || '[]');

    if (User.current() && !!roles?.length && !!affiliations?.length) {
      const current = User.fromParseUser(User.current());

      current.roles = roles.map((r) => Role.fromJSON({ ...r, className: '_Role' }, false));
      current.affiliations = affiliations.map((a) => Affiliate.fromJSON({ ...a, className: 'Affiliate' }, false));

      return current;
    } else return undefined;
  }

  private getCurrentOrganization(): Organization {
    const orgString = localStorage.getItem('currentOrganization');

    if (!orgString) return null;

    const orgJSON = { ...JSON.parse(orgString), className: 'Organization' };
    return Organization.fromJSON(orgJSON, false) as Organization;
  }

  setOrganization(organization: Organization) {
    const orgString = JSON.stringify(organization.toJSON());
    localStorage.setItem('currentOrganization', orgString);
    this._currentOrganization.next(organization);
  }

  private async _getUserMetadata(parseUser: User): Promise<User> {
    let user = User.fromParseUser(parseUser);
    user.roles = await this.getUserRoles(user);
    user = await this._getUserOrganizations(user);
    localStorage.removeItem('currentUserRoles');
    localStorage.removeItem('currentUserAffiliations');
    localStorage.setItem('currentUserRoles', JSON.stringify(user.roles.map((r) => r.toJSON())));
    localStorage.setItem('currentUserAffiliations', JSON.stringify(user.affiliations.map((a) => a.toJSON())));
    this.setOrganization(
      (user.affiliations.find((u) => u.organization?.id === this.currentOrganizationValue?.id) ?? user.affiliations[0])
        .organization
    );
    this._currentUserB$.next(user);
    this._logger.info(`
    User '${user.username}' logged in with roles [${(user.roles || []).map((role) => role.getName()).join(', ')}].
    Organizations: ${user.organizationNames.join(', ')}. Active: ${this.currentOrganizationValue.name}.
    `);
    return user;
  }

  /**
   *
   *
   * @param {string} username username/email
   * @param {string} password
   * @returns {(Observable<User | any>)}
   * @memberof AuthService
   */
  async login(username: string, password: string): Promise<User> {
    const emailRegex = /.*@.*\.\w+\b/gi;
    return User.logIn(emailRegex.test(username) ? username.toLowerCase() : username, password).then((user: User) =>
      this._getUserMetadata(user)
    );
  }

  /**
   *
   *
   * @param {string} username
   * @param {string} password
   * @returns {(Observable<User | any>)}
   * @memberof AuthService
   */
  public become(token: string): Observable<User> {
    return from(User.become(token).then((user: User) => this._getUserMetadata(user)));
  }

  /**
   *
   *
   * @returns {(Observable<User | any>)}
   * @memberof AuthService
   */
  logout(): Observable<User | unknown> {
    return defer(() =>
      User.logOut().catch(() => {
        this._logger.debug(`Already logged off`);
      })
    ).pipe(
      tap(() => {
        this.cleanUserSession();
      })
    );
  }

  public cleanUserSession(): void {
    localStorage.removeItem(`Parse/${environment.api.appId}/currentUser`);
    localStorage.removeItem(`Parse/${environment.api.appId}/installationId`);
    localStorage.removeItem('currentUserRoles');
    localStorage.removeItem('currentUserAffiliations');
    localStorage.removeItem('currentOrganization');
    this._currentUserB$.next(undefined);
    this._currentOrganization.next(undefined);
    this._organizationsB$.next([]);
  }

  public getUserRoles(user: User): Promise<Role[]> {
    const query = new Query(Role).containedIn('users', [user]);
    return query.find();
  }

  private _getUserOrganizations(user: User): Promise<User> {
    const query = new Query(Affiliate).equalTo('user', user).include('organization', 'user');
    return query
      .find()
      .then(async (affiliates) => {
        const organizations = await ParseObject.fetchAllWithInclude(
          affiliates.map((affiliate) => affiliate.organization),
          'license'
        );
        affiliates.forEach((affiliate) => {
          affiliate.set(
            'organization',
            organizations.find((org) => org.id === affiliate.organization.id) ?? affiliate.organization
          );
        });
        return Promise.resolve(affiliates);
      })
      .then((affiliations: Affiliate[]) => {
        this._organizationsB$.next(
          affiliations.map((a) => a.organization).sort((org1, org2) => org1.name.localeCompare(org2.name))
        );
        user.affiliations = affiliations;
        return user;
      })
      .catch((err) => {
        throw err;
      });
  }

  public async updateUserRolesAndOrganizations(): Promise<void> {
    if (!this.currentUser) return;
    await this._getUserMetadata(this.currentUser);
  }
}
