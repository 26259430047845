import { AnalysisType } from "../analysis-type/analysis-type.model";
import { Analysis } from "../analysis/analysis.model";
import { AnalysisTask } from "./analysis-task";

export interface TAnalysisStatistics {
  [key: string]: AnalysisStats;
}

export interface AnalysisStats {
  // For selection / switcher fields
  unique?: unknown[];
  occurences?: { [key: string]: number };
  // For numeric fields
  sum?: number;
  min?: number;
  max?: number;
  // For boolean fields
  any?: boolean;
  all?: boolean;
}

export interface AnalysisSet {
  analysisType: AnalysisType;
  analysis: Analysis[];
  analysisStatistics?: TAnalysisStatistics;
}

export class AnalysisUtils {
  public static flatten<T>(nested: T[][]): T[] {
    return [].concat(...nested);
  }

  static processAnalysisStatistics(set: AnalysisSet): TAnalysisStatistics {
    const stats: TAnalysisStatistics = {};
    set.analysisType.tasks.forEach((field: AnalysisTask) => {
      const stat: AnalysisStats = {};

      switch (field.type) {
        case "boolean": {
          stat.any = (set.analysis || [])
            .map((u: Analysis) => u.data[field.name] as boolean)
            .some((v) => v);
          stat.all = (set.analysis || [])
            .map((u: Analysis) => u.data[field.name] as boolean)
            .every((v) => v);
          break;
        }
        case "switcher":
        case "selection": {
          stat.unique = [];
          stat.occurences = {};
          if (set.analysis.length) {
            set.analysis.forEach((u: Analysis) => {
              let data = u.data[field.name];
              if (!data) return;
              if (data instanceof Array) {
                data.forEach((val: string) => {
                  if (stat["unique"].indexOf(val) === -1) {
                    stat.unique.push(val);
                  }
                  if (!stat.occurences[val]) {
                    stat.occurences[val] = 0;
                  }
                  stat.occurences[val] += 1;
                });
              } else {
                if (stat["unique"].indexOf(data) === -1) {
                  stat.unique.push(data);
                }
                // TODO: find fancier solution to check not boolean type
                data = data.toString();
                if (!stat.occurences[data]) {
                  stat.occurences[data] = 0;
                }
                stat.occurences[data] += 1;
              }
            });
          }
          break;
        }
        // case 'number':{
        //   stat.sum = 0
        //   set.analysis.map(a => a.data[field.name] as number).forEach((a:number)=>stat.sum+=(a ? a: 0))
        //   break;
        // }
      }
      stats[field.name] = stat;
    });
    return stats;
  }
}
