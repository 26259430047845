<form [formGroup]="form" (submit)="submit()" (reset)="close()" data-cy="redeem-invite-dialog">
  <h2 mat-dialog-title>{{ 'button.redeem_invite_code' | translate }}</h2>
  <mat-dialog-content>
    <p>{{ 'help.redeem_invite_dialog' | translate }}</p>
    <ng-container *ngIf="inviteInfo">
      <div data-cy="invite-info-organization">
        <b>{{ 'core.organization' | translate }}</b
        >: {{ inviteInfo.organization }}
      </div>
      <div class="flex-row align-items-center" data-cy="invite-info-roles">
        <b>{{ 'core.roles' | translate }}</b
        >:
        <ts-role-badge [role]="role" *ngFor="let role of inviteInfo.initialRoles">{{ role | translate }}</ts-role-badge>
      </div>
    </ng-container>
    <mat-form-field class="full-width">
      <mat-label>{{ 'label.invite_code' | translate }}</mat-label>
      <input #input autofocus type="text" matInput formControlName="code" (ngModelChange)="hideInviteInfo()" />
      <mat-error *ngIf="form.controls['code'].errors"> {{ form.controls['code'].errors.backendError }} </mat-error>
    </mat-form-field>
    <div class="flex-row justify-content-center">
      <a role="button" class="text-primary" (click)="registerAsNewUser()">{{ 'button.redeem_as_new_user' | translate }}</a>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="reset" class="btn btn-outline-danger m-1">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="btn btn-primary m-1">{{ 'button.submit' | translate }}</button>
  </mat-dialog-actions>
</form>
