import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, OrganizationResolver } from '@telespot/web-core';

import { BillingComponent } from './components/billing/billing.component';
import { DevicesComponent } from './components/devices/devices.component';
import { OrganizationCasetypesComponent } from './components/organization-casetypes/organization-casetypes.component';
import { OrganizationDetailComponent } from './components/organization-detail/organization-detail.component';
import { OrganizationEditorComponent } from './components/organization-editor/organization-editor.component';
import { OrganizationsListComponent } from './components/organizations-list/organizations-list.component';
import { UserStatsComponent } from './components/user-stats/user-stats.component';
import { WorkspacesStatsComponent } from './components/workspaces-stats/workspaces-stats.component';

const routes: Routes = [
  { path: '', component: OrganizationsListComponent, data: { title: 'Organizations' } },
  {
    path: 'new',
    component: OrganizationEditorComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: ['admin'], title: 'New Organization' },
  },
  { path: ':organizationid/edit', component: OrganizationEditorComponent, resolve: { organization: OrganizationResolver } },
  {
    path: ':organizationid',
    component: OrganizationDetailComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: ['admin'], title: 'Organization: {{organization.name}}' },
    resolve: { organization: OrganizationResolver },
    children: [
      { path: 'caseTypes', component: OrganizationCasetypesComponent },
      { path: 'users', component: UserStatsComponent },
      { path: 'devices', component: DevicesComponent },
      { path: 'workspaces', component: WorkspacesStatsComponent },
      { path: 'billing', component: BillingComponent },
      { path: '', redirectTo: 'caseTypes', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TelespotDashboardRoutingModule {}
