<h1>
  {{ 'title.default_sample_name_editor' | translate }}
</h1>

<form [formGroup]="form" (submit)="submit()">
  <mat-form-field class="full-width" *ngFor="let lang of supportedLanguages">
    <mat-label>{{ lang.label | translate }}</mat-label>
    <input matInput type="text" [formControlName]="lang.value" />
  </mat-form-field>

  <div class="flex-row">
    <button class="btn btn-outline-danger" (click)="close()" type="button">
      <i class="ri-close-fill"></i>
      {{ 'button.cancel' | translate }}
    </button>
    <button class="btn btn-primary" type="submit" [disabled]="!form.valid">
      <i class="ri-save-line"></i>
      {{ 'button.save' | translate }}
    </button>
  </div>

  <mat-error *ngIf="form.touched && form.errors">
    {{ form.errors?.form }}
  </mat-error>
</form>
