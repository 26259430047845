import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { State, authFeatureKey } from './auth.state';

export const selectAuthState = createFeatureSelector<State>(authFeatureKey);

export const selectIsAdmin = createSelector(
  selectAuthState,
  (state: State) => state.roles.includes('admin')
);

