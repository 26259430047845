import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Resolve, Router } from '@angular/router';
import { Member, Query, User, Workspace } from '@telespot/sdk';
import { EMPTY, from } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DataService } from '../services';
import { AuthService } from '../services/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class WorkspaceResolver implements Resolve<Workspace> {
  constructor(
    private _dataService: DataService,
    private _router: Router,
    private _snack: MatSnackBar,
    private _authService: AuthService
  ) {}
  resolve(
    route: import('@angular/router').ActivatedRouteSnapshot,
    state: import('@angular/router').RouterStateSnapshot
  ): Workspace | import('rxjs').Observable<Workspace> | Promise<Workspace> {
    return from(
      this._authService.currentUser.roleNames.some((roleName) =>
        ['admin', 'analystmanager', 'technicianmanager'].includes(roleName)
      )
        ? this._dataService.get(
            route.paramMap.get('workspaceId'),
            new Query(Workspace)
              .include('caseType.methodTypes.assetType')
              .include('caseType.methodTypes.resources.resources')
          )
        : this._dataService
            .first(
              new Query(Member)
                .equalTo('workspace', Workspace.createWithoutData(route.paramMap.get('workspaceId')))
                .equalTo('user', User.createWithoutData(this._authService.currentUser.id))
                .include('workspace.caseType.methodTypes')
            )
            .then((m) => m.workspace)
    ).pipe(
      catchError(() => {
        this._snack.open('Workspace not found', null, { duration: 1000 });
        this._router.navigateByUrl('/workspaces');
        return EMPTY;
      })
    );
  }
}
