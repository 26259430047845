import { ExportedFinding } from "@telespot/domain";
import { SampleAssetTopology } from "../acquisition";

export class ParseExportedFindingMapper {
  public static toDomain(finding: Parse.Object, sampleAsset?: Parse.Object): ExportedFinding {

    const sampleJSON = sampleAsset?.get(SampleAssetTopology.SAMPLE)?.toJSON();
    const assetJSON = sampleAsset?.get(SampleAssetTopology.ASSET)?.toJSON();
    const dateOfCapture = sampleAsset?.get(SampleAssetTopology.DATE_OF_CAPTURE);
    const findingJSON = finding.toJSON();

    // flatten data
    findingJSON.asset = assetJSON;
    findingJSON.sample = sampleJSON;
    findingJSON.case = findingJSON.sample?.case;
    findingJSON.workspace = findingJSON.sample?.case?.workspace;
    findingJSON.organization = findingJSON.sample?.case?.workspace?.organization;
    findingJSON.dateOfCapture = dateOfCapture;
    findingJSON.analysis.assetId = findingJSON.analysis?.asset.objectId;

    // omit sample fields
    delete findingJSON.sample?.case;
    delete findingJSON.sample?.createdBy;
    delete findingJSON.sample?.methodType;
    delete findingJSON.sample?.organization;

    // omit case fields
    delete findingJSON.case?.organization;
    delete findingJSON.case?.workspace;

    // omit workspace fields
    delete findingJSON.workspace?.organization;

    // omit ACLs on all objects
    delete findingJSON.ACL;
    delete findingJSON.analysis?.ACL;
    delete findingJSON.sample?.ACL;
    delete findingJSON.case?.ACL;
    delete findingJSON.workspace?.ACL;
    delete findingJSON.organization?.ACL;

    // omit finding fields
    delete findingJSON.analysis?.asset;

    return findingJSON;
  }
}
