<ng-container *ngIf="{ assetsAnalysis: assetsAnalysis$ | async, data: data$ | async, comments: commentsIndex$ | async } as data">
  <div class="card">
    <div class="card-header flex-row justify-content-between align-items-center">
      <span class="spacer"></span>
      <h1>
        {{ 'title.sample_report_preview' | translate | uppercase }}
      </h1>
      <button class="btn btn-flat" (click)="close()">
        {{ 'button.close' | translate }}
        <i class="ri-close-fill"></i>
      </button>
    </div>

    <div class="card-body">
      <div style="margin-bottom: 20px;" *ngIf="data.assetsAnalysis as assetsAnalysis">
        <mat-accordion>
          <b>{{ 'label.analyzed_images' | translate }}</b>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="align-items: center;">
                <mat-progress-bar
                  mode="determinate"
                  [value]="assetsAnalysis?.hasAssetAnalysis ? assetsAnalysis?.perc : '100'"
                ></mat-progress-bar>
              </mat-panel-title>
              <mat-panel-description style="align-items: center; justify-content: flex-end;">
                {{
                  (assetsAnalysis?.hasAssetAnalysis ? assetsAnalysis?.totalAnalyzed : assetsAnalysis?.analyzed?.length) +
                    '/' +
                    assetsAnalysis?.analyzed?.length
                }}
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div
              *ngFor="let check of assetsAnalysis?.analyzed"
              class="assets"
              [class.checked]="assetsAnalysis?.hasAssetAnalysis ? check : true"
            >
              <i class="ri-image-line icon-asset"></i>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <ng-container *ngFor="let analysisTypeData of data.data">
        <ng-container *ngIf="analysisTypeData.hasAssetData">
          <h4>
            {{ analysisTypeData.analysisType?.displayName }}
          </h4>

          <!-- First always counters and rois -->
          <ng-container *ngFor="let task of analysisTypeData.data">
            <div style="margin-bottom: 20px;" *ngIf="task.data.length">
              <ng-container *ngIf="!task.isSample">
                <ng-container *ngIf="(task.task.type === 'selection' && task.task.multiple) || task.task.roiSelection">
                  <b>{{ task.task.displayName | uppercase }}</b>
                  <div *ngFor="let dataItem of task.data" class="model-row">
                    <div class="model-row__label">
                      {{ dataItem.name }}
                    </div>
                    <div class="model-row__value flex-column align-items-center">
                      <span>{{ dataItem.value }}</span>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>

          <!-- Then comments, switchers and checkers ASSETS -->
          <ng-container *ngFor="let task of analysisTypeData.data">
            <div style="margin-bottom: 20px;" *ngIf="task.data.length">
              <ng-container *ngIf="!task.isSample">
                <ng-container *ngIf="task.task.type === 'textedit'">
                  <b>{{ task.task.displayName | uppercase }}</b>
                  <ng-container *ngFor="let dataItem of task.data">
                    <div class="container-cards">
                      <button [disabled]="!data.comments" (click)="slide(-1)" mat-fab>
                        <i class="ri-arrow-left-s-line icon-asset"></i>
                      </button>
                      <div *ngFor="let comment of dataItem?.value; let index = index">
                        <div
                          class="card-comments"
                          *ngIf="comment && (index === data.comments || index === data.comments + 1)"
                        >
                          <p>
                            {{ comment?.value }}
                          </p>
                          <div class="footer">
                            <div>{{ 'label.asset' | translate }} {{ comment?.assetNumber + 1 }}</div>
                            <button (click)="showThumbnail(comment?.thumbnail)" mat-button>
                              {{ 'label.view_image' | translate | uppercase }}
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        [disabled]="data.comments === dataItem?.value?.length - 2 || dataItem?.value?.length === 1"
                        (click)="slide(1)"
                        mat-fab
                      >
                        <i class="ri-arrow-right-s-line icon-asset"></i>
                      </button>
                    </div>
                  </ng-container>
                </ng-container>

                <mat-accordion>
                  <ng-container
                    *ngIf="
                      (task.task.type === 'selection' && !task.task.multiple) ||
                      task.task.type === 'switcher' ||
                      task.task.type === 'boolean'
                    "
                  >
                    <b>{{ task.task.displayName | uppercase }}</b>
                    <div *ngFor="let dataItem of task.data">
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title style="align-items: center;">
                            <i
                              *ngIf="(task.task.type === 'selection' && !task.task.multiple) || task.task.type === 'boolean'"
                              [class.ri-checkbox-fill]="!(dataItem.value?.perc === 0)"
                              [class.ri-checkbox-blank-line]="dataItem.value?.perc === 0"
                              [class.disabled]="dataItem.value?.perc === 0"
                            ></i>
                            <i
                              *ngIf="task.task.type === 'switcher'"
                              [class.ri-radio-button-line]="!(dataItem.value?.perc === 0)"
                              [class.ri-checkbox-blank-circle-line]="dataItem.value?.perc === 0"
                              [class.disabled]="dataItem.value?.perc === 0"
                            ></i>
                            {{ dataItem.name }}
                          </mat-panel-title>
                          <mat-panel-description style="align-items: center; justify-content: flex-end;">
                            {{ dataItem.value?.perc }} % {{ 'label.samples' | translate }}
                          </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div *ngFor="let check of dataItem.value?.checks" class="assets" [class.checked]="check">
                          <i class="ri-image-line icon-asset"></i>
                        </div>
                      </mat-expansion-panel>
                    </div>
                  </ng-container>
                </mat-accordion>
              </ng-container></div></ng-container
        ></ng-container>
      </ng-container>

      <!-- Finally, SAMPLE values (without rois and counters) -->
      <ng-container *ngFor="let analysisTypeData of data.data">
        <ng-container *ngIf="analysisTypeData.hasSampleData">
          <h4>
            {{ analysisTypeData.analysisType?.displayName }}
          </h4>

          <ng-container *ngFor="let task of analysisTypeData.data">
            <div style="margin-bottom: 20px;" *ngIf="task.data.length">
              <ng-container *ngIf="task.isSample">
                <ng-container *ngIf="task.task.type === 'textedit'">
                  <b>{{ task.task.displayName | uppercase }}</b>
                  <ng-container *ngFor="let dataItem of task.data">
                    <div class="card-comments">
                      <p>
                        {{ dataItem?.value }}
                      </p>
                    </div>
                  </ng-container>
                </ng-container>

                <mat-accordion>
                  <ng-container
                    *ngIf="
                      (task.task.type === 'selection' && !task.task.multiple) ||
                      task.task.type === 'switcher' ||
                      task.task.type === 'boolean'
                    "
                  >
                    <b>{{ task.task.displayName | uppercase }}</b>
                    <div *ngFor="let dataItem of task.data">
                      <div class="model-row checkbox">
                        <i
                          *ngIf="(task.task.type === 'selection' && !task.task.multiple) || task.task.type === 'boolean'"
                          [class.ri-checkbox-fill]="dataItem.value"
                          [class.ri-checkbox-blank-line]="!dataItem.value"
                          [class.disabled]="!dataItem.value"
                        ></i>
                        <i
                          *ngIf="task.task.type === 'switcher'"
                          [class.ri-radio-button-line]="dataItem.value"
                          [class.ri-checkbox-blank-circle-line]="!dataItem.value"
                          [class.disabled]="!dataItem.value"
                        ></i>
                        <span style="align-self: center;">{{ dataItem.name }}</span>
                      </div>
                    </div>
                  </ng-container>
                </mat-accordion>
              </ng-container>
            </div></ng-container
          ></ng-container
        >
      </ng-container>
    </div>
  </div>

  <ng-template #thumbnailDialog let-data>
    <img [src]="data.thumbnail | preprocess | async" alt="asset thumbnail" />
  </ng-template>
</ng-container>
