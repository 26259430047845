export class ComparableDate {

  public static create(value: Date) {
    return new ComparableDate(value);
  }

  private constructor(public readonly value: Date) { }

  public daysSince(date: Date) {
    const diff = Math.abs(this.value.getTime() - date.getTime());
    const diffDays = Math.floor(diff / (1000 * 3600 * 24));

    return diffDays;
  }

  public isBefore(date: Date) {
    return this.value.getTime() < date.getTime();
  }
}
