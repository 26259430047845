import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
  SimpleChanges,
  OnChanges,
  TemplateRef,
  ViewChild,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'ts-virtual-scroll',
  templateUrl: './virtual-scroll.component.html',
  styleUrls: ['./virtual-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VirtualScrollComponent implements OnChanges, AfterViewInit {

  @Input() public rowTemplate: TemplateRef<unknown>;
  @Input() public itemCount = 0;
  @Input() public scrollThreshold = 0;
  @Input() public paddingNodes = 1;
  @Input() public rowHeight = 0;
  @Input() public locationRow = -1;

  @Output() public startIndexChanged = new EventEmitter<number>();
  @Output() public scrollThresholdReached = new EventEmitter<void>();
  @Output() public bottomReached = new EventEmitter<void>();

  @ViewChild("viewport") public viewportElement: ElementRef<HTMLElement>;

  @ViewChildren('rowElement') public rowElements!: QueryList<ElementRef>;

  public startNode = 0;
  public visibleNodesCount = 0;
  public offsetY = 0;
  public totalContentHeight = 0;
  public visibleItems = [];
  public rowHeights: number[] = [];  // Store heights of individual rows
  public cumulativeHeights: number[] = [];  // Store cumulative heights for binary search

  constructor(private changeDetector: ChangeDetectorRef) { }

  public ngOnChanges(changes: SimpleChanges) {
    for (const input in changes) {
      const { previousValue, currentValue } = changes[input];
      const isFirstChange = changes[input].isFirstChange();

      if (currentValue === previousValue) continue;

      switch (input) {
        case 'itemCount':
          if (!isFirstChange) this.adjustRowHeightsToItemCount();
          this.updateVisibleNodes();
          break;
        case 'locationRow':
          this.goToRow(currentValue);
          break;
        case 'rowHeight':
          if (isFirstChange) break;
          this.initializeHeights();
          break;
        default:
          break;
      }
    }
  }

  public ngAfterViewInit() {
    setTimeout(() => this.updateVisibleNodes(), 300);
  }

  public trackByIndex(index: number): number {
    return index + this.startNode;
  }

  private goToRow(index: number) {
    this.viewportElement?.nativeElement?.scrollTo(0, this.cumulativeHeights[index]);
  }

  @HostListener('window:resize')
  public onResize() {
    this.updateVisibleNodes();
  }

  public onScroll(event: Event) {
    const { scrollTop, clientHeight, scrollHeight } = (event.target as HTMLElement);

    const atBottom = scrollHeight - Math.round(scrollTop) === clientHeight;
    const scrollPosition = scrollTop + clientHeight;
    const targetThresholdDown = scrollHeight - scrollHeight * this.scrollThreshold;

    this.updateStartNode(scrollTop);
    this.updateVerticalOffset();
    this.updateVisibleNodes();

    if (this.scrollThreshold > 0 && scrollPosition >= targetThresholdDown) this.scrollThresholdReached.emit();
    if (atBottom) this.bottomReached.emit();
  }

  private updateStartNode(scrollTop: number) {
    const low = this.calculateStartNode(scrollTop);

    const newStartNode = Math.max(0, low - this.paddingNodes);

    if (this.startNode === newStartNode) return;

    this.startNode = newStartNode;

    this.startIndexChanged.emit(low); // Emit the start index change event
  }

  /**
   * Calculate offsetY based on cumulative height up to startNode
   */
  private updateVerticalOffset() {
    this.offsetY = this.startNode > 0 ? this.cumulativeHeights[this.startNode - 1] : 0;
  }

  private updateVisibleNodes() {
    this.visibleNodesCount = Math.max(0, this.calculateVisibleNodes());

    this.visibleItems = new Array(this.visibleNodesCount).fill(null);

    // Mark for change detection
    this.changeDetector.markForCheck();

    // Measure row heights and update if necessary
    this.measureRowHeights();
  }

  /* MEASUREMENTS */

  /**
   * Measure the row heights dynamically and update the cumulative heights array.
   */
  private measureRowHeights() {
    let hasChanged = false;

    for (const rowElement of this.rowElements ?? []) {
      const height = rowElement.nativeElement.offsetHeight;
      const rowIndex = Number(rowElement.nativeElement.id);

      if (this.rowHeights[rowIndex] === height) continue;

      this.rowHeights[rowIndex] = height;

      hasChanged = true;
    }

    if (!hasChanged) return;

    this.updateCumulativeHeights();
  }

  private updateCumulativeHeights() {
    this.cumulativeHeights = [];
    let cumulativeHeight = 0;

    for (let index = 0; index < this.rowHeights.length; index++) {
      cumulativeHeight += this.rowHeights[index];
      this.cumulativeHeights[index] = cumulativeHeight;
    }

    // Update the total content height
    this.totalContentHeight = cumulativeHeight;
  }

  private adjustRowHeightsToItemCount() {
    if (this.rowHeights.length > this.itemCount) {
      this.rowHeights = this.rowHeights.slice(0, this.itemCount);
      this.cumulativeHeights = this.cumulativeHeights.slice(0, this.itemCount);
    } else if (this.rowHeights.length < this.itemCount) {
      const additionalRows = this.itemCount - this.rowHeights.length;

      this.rowHeights = this.rowHeights.concat(new Array(additionalRows).fill(this.rowHeight));

      for (let i = 0; i < additionalRows; i++) {
        const lastHeight = this.cumulativeHeights[this.cumulativeHeights.length - 1];
        this.cumulativeHeights.push(lastHeight + this.rowHeight);
      }
    }

    this.totalContentHeight = this.cumulativeHeights[this.cumulativeHeights.length - 1];
  }

  private initializeHeights() {
    this.rowHeights = new Array(this.itemCount).fill(this.rowHeight);
    this.updateCumulativeHeights();
  }

  /* MATH */

  private calculateVisibleNodes(): number {
    const clientHeight = this.viewportElement?.nativeElement?.clientHeight ?? window.innerHeight;

    let totalHeight = 0;
    let visibleNodes = Math.min(this.startNode, this.paddingNodes);

    // Calculate how many nodes fit in the viewport
    for (let i = this.startNode; i < this.itemCount; i++) {
      if (Math.floor(totalHeight / clientHeight) > 0) break;

      totalHeight += this.rowHeights[i];
      visibleNodes++;
    }

    visibleNodes += Math.min(this.paddingNodes, this.itemCount - this.startNode - visibleNodes);

    return visibleNodes;
  }

  private calculateStartNode(scrollTop: number): number {
    if (!this.cumulativeHeights.length) return 0;

    let low = 0;
    let high = this.cumulativeHeights.length - 1;

    // Binary search to find the first row visible at scrollTop
    while (low < high) {
      const mid = Math.floor((low + high) / 2);
      if (this.cumulativeHeights[mid] < scrollTop) {
        low = mid + 1;
      } else {
        high = mid;
      }
    }

    return low;
  }
}
