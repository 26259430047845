import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  Label,
  RoiService,
  clearAllLabels,
  selectLabel,
  selectMultipleSelection,
  selectedLabels,
} from "@telespot/analysis-refactor/data-access";

@Component({
  selector: "ts-roi-selection-panel",
  templateUrl: "./roi-selection-panel.component.html",
  styleUrls: ["./roi-selection-panel.component.scss"],
})
export class RoiSelectionPanelComponent implements OnInit {
  public multiModelSelection$;
  public selectedLabels$;

  constructor(private _store: Store, private _roiService: RoiService) {}

  ngOnInit(): void {
    this.multiModelSelection$ = this._store.select(selectMultipleSelection);
    this.selectedLabels$ = this._store.select(selectedLabels);
  }

  public getLabelColor(selectedLabel: Label) {
    return this._roiService.getModelColor([selectedLabel.uuid], true);
  }

  /**
   * Clears all the selected labels modifying the value of the state
   */
  public clearLabels() {
    this._store.dispatch(clearAllLabels());
  }

  /**
   * Clears the selected label modifying the value of the state
   * @param selectedLabel passed as an object from the state
   */
  public clearLabel(selectedLabel: Label) {
    this._store.dispatch(
      selectLabel({
        uuid: selectedLabel.uuid,
      })
    );
  }
}
