import { createAction, props } from "@ngrx/store";
import { ISyncedItem } from "./sync.reducer";

export const syncActionError = createAction(
  "[SYNC] Action Error",
  props<{ error: string }>()
);

export const clearSyncState = createAction("[SYNC] Clear sync state");

export const clearSyncedItems = createAction("[SYNC] Clear synced items");

export const loadSyncedItems = createAction(
  "[SYNC] Load synced items in state",
  props<{
    syncedItems: ISyncedItem[];
  }>()
);

export const deleteSyncedItem = createAction(
  "[SYNC] Delete synced items from the state",
  props<{
    findingIds: string[];
  }>()
);

export const loadLabelList = createAction(
  "[SYNC] Load label tracking list for crops",
  props<{
    labelIds: string[];
  }>()
);

export const labelListLoaded = createAction(
  "[SYNC] Label tracking list for crops sorted and loaded",
  props<{
    labelIds: string[];
  }>()
);

export const updateCropPagination = createAction(
  "[SYNC] Update crop pagination",
  props<{
    nextToken: string;
    labelId: string;
  }>()
);

export const updateSyncedItemToken = createAction(
  "[SYNC] Update synced finding token for crop pagination",
  props<{
    nextToken: string;
    id: string;
  }>()
);
export const setReferenceAnalyst = createAction(
  "[SYNC] Set reference analyst for mosaic review",
  props<{
    analyst: Parse.Pointer;
  }>()
);

export const clearLabelTracking = createAction("[SYNC] Clear label tracking");
