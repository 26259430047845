import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TableFilter } from '../models/data-table-filter';

@Component({
  selector: 'ts-table-filter-badge',
  templateUrl: './table-filter-badge.component.html',
  styleUrls: ['./table-filter-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TableFilterBadgeComponent {
  @Input() filter: TableFilter;
  @Output() remove = new EventEmitter<void>();
}
