import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { DataService } from "@telespot/web-core";
import { Pipeline, Query } from "@telespot/sdk";
import { BehaviorSubject } from "rxjs";
import { DataFilterComponent, FindRequest, RowData } from "@shared/utils";
import { DataTableConfig, SplDataSource } from "@shared/ui";
import { TranslateService } from "@ngx-translate/core";

interface PipelinesRowData extends RowData {
  resource: Pipeline;
}

@Component({
  selector: "ts-pipelines",
  templateUrl: "./pipelines.component.html",
  styleUrls: ["./pipelines.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PipelinesComponent extends DataFilterComponent implements OnInit {
  private _loading$ = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this._loading$.asObservable();

  public previousRequest: FindRequest;
  public oldAvailable: Pipeline[];
  public tableName = "pipeline_list";
  public dataSource = new SplDataSource<PipelinesRowData>(this);
  public tableConfig: DataTableConfig<PipelinesRowData>;

  public klass = Pipeline.className;

  public readonly config = {
    columns: [
      {
        colName: "core.name",
        key: "pipelineName",
        sortable: true,
        value: (item) => item,
        displayName: "Names",
        name: "name",
      },
      {
        colName: "core.environment",
        key: "environment",
        value: (item) => item,
        displayName: "Environment",
        name: "environment",
      },

      {
        name: "_actions",
        displayName: "Actions",
        value: (item) => item,
      },
    ],
    routeId: "_id",
  };

  public columns = this.config.columns;

  constructor(
    private _dataService: DataService,
    private languageService: TranslateService
  ) {
    super(_dataService);
  }

  public getFindQuery(request): Query<Pipeline> {
    const { filters, skip, limit } = request;
    const pipelineQuery = new Query(Pipeline);

    if (filters) {
      for (const { constraints } of filters) {
        for (const [comparison, value] of Object.entries(constraints)) {
          if (typeof value === "string") {
            pipelineQuery.matches(
              "name.searchTerm",
              new RegExp(`${value.toLowerCase()}`),
              "i"
            );
          }
        }
      }
    }
    pipelineQuery.limit(limit || 100);
    pipelineQuery.skip(skip || 0);

    return pipelineQuery;
  }

  public delete(deletedItem: PipelinesRowData): void {
    alert("Not implemented");
  }

  getPipelineName(name: any) {
    return name[this.languageService.currentLang] ?? name["en"];
  }
}
