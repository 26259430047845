import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { viewerCtxReducer } from "../../+state/viewer-context.reducers";

@NgModule({
  imports: [
    StoreModule.forRoot({}),
    StoreModule.forFeature("viewerCtx", viewerCtxReducer),
  ],
  declarations: [],
  exports: [],
  providers: [],
})
export class RoisTestingModule {}
