import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import JSZip from 'jszip';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private _http: HttpClient) { }

  private async _getFile(url: string) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    const res = await this._http.get(url, httpOptions).toPromise();
    return res;
  }
  async createZip(files: any[], zipName: string) {
    const zip = new JSZip();
    const name = zipName + '.zip';
    await Promise.all(
      files.map(async (f) => {
        const fileData: any = await this._getFile(`${f.urlName}?download`);
        const b: any = new Blob([fileData], { type: '' + fileData.type + '' });
        zip.file(f.finalName, b);
      })
    );
    zip.generateAsync({ type: 'blob' }).then((content) => {
      if (content) {
        FileSaver.saveAs(content, name);
      }
    });
  }
}
