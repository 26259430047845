import { Object as ParseObject } from "parse";
import { IAnswer, IOption, MethodTypeConfig } from "..";

import { AnalysisType } from "../analysis-type/analysis-type.model";
import { AssetType } from "../asset-type/asset-type.model";
import { Resource } from "../resource/resource.model";
import { IAnswerResource, IAssetField } from "./method-type.asset-fields";

export class MethodType extends ParseObject {
  static className = "MethodType";

  static readonly TIRASPOT_FIELD_SECTION = "tiraspot";
  static readonly TIRASPOT_FIELD_TYPE = "tiraspot_mask";
  static readonly TIRASPOT_RESOURCE_TYPE = "mask";

  /**
   * Creates an instance of MethodType.
   * @memberof MethodType
   */
  constructor() {
    super(MethodType.className);
    this.analysisTypes = [];
  }

  /**
   *
   *
   * @type {string}
   * @memberof MethodType
   */
  get name(): string {
    return this.get("name") ?? "";
  }
  set name(value: string) {
    this.set("name", value);
  }

  /**
   *
   *
   * @type {AssetType}
   * @memberof MethodType
   */
  get assetType(): AssetType {
    return this.get("assetType");
  }
  set assetType(value: AssetType) {
    this.set("assetType", value);
  }

  set config(value: MethodTypeConfig) {
    this.set("config", value);
  }
  get config(): MethodTypeConfig {
    return this.get("config") || {};
  }

  set resources(value: Resource[]) {
    this.set("resources", value);
  }
  get resources(): Resource[] {
    return this.get("resources") || [];
  }

  set assetFields(data: IAssetField[]) {
    this.set("assetFields", data);
  }
  get assetFields(): IAssetField[] {
    return this.get("assetFields") ?? [];
  }

  set assetDataFields(data: IAssetField[]) {
    this.set("assetDataFields", data);
  }
  get assetDataFields(): IAssetField[] {
    return this.get("assetDataFields") ?? [];
  }

  get analysisTypes(): AnalysisType[] {
    return this.get("analysisTypes") || [];
  }
  set analysisTypes(analysisTypes: AnalysisType[]) {
    this.set("analysisTypes", analysisTypes);
  }

  registerAssetType(assetType: AssetType) {
    this.set("assetType", assetType);
  }

  unregisterAssetType() {
    this.set("assetType", null);
  }

  // REVIEW:  .save() fixes the 'cannot merge op' error,  but there could be a better solution
  registerAnalysisType(analysisType: AnalysisType) {
    this.addUnique("analysisTypes", analysisType);
    // this.save();
  }

  unregisterAnalysisType(analysisType: AnalysisType) {
    this.remove("analysisTypes", analysisType);
    // this.save();
  }

  get tiraspotTests(): IOption[] {
    const tiraspotAssetFields = this.assetFields.filter(
      (assetField) =>
        assetField?.section === MethodType.TIRASPOT_FIELD_SECTION &&
        assetField?.type === MethodType.TIRASPOT_FIELD_TYPE
    );
    return [].concat(...tiraspotAssetFields.map((test) => test.options));
  }

  get tiraspotTestsOptions(): Resource[] {
    //Method types with resources already migrated
    const resourceMasks = this.resources.filter(
      (resource) => resource.type === MethodType.TIRASPOT_RESOURCE_TYPE
    );
    return resourceMasks;
  }

  get possibleAnswersForAllMasks(): IAnswer[] {
    return [].concat(...this.tiraspotTests.map((test) => test.options));
  }

  get possibleResponsesForAllMasks(): IAnswerResource[] {
    //Method types with resources already migrated
    const resourceMasks = this.resources.filter(
      (resource) => resource.type === MethodType.TIRASPOT_RESOURCE_TYPE
    );
    const responses = [].concat(
      ...resourceMasks.map((resource) => resource.resources)
    );
    const responsesFiltered = responses.filter(
      (value, index, self) =>
        self
          .map((resp) => resp.metadata?.label)
          .indexOf(value.metadata?.label) == index
    );

    const testOptions = responsesFiltered.map((response) => {
      return {
        name: response.name,
        value: response.metadata.label,
        displayName: response.displayName,
      };
    });

    return testOptions;
  }
}
