<ng-container
  *ngIf="{
    mobile: showMobileLayout$ | async,
    workspace: workspace$ | async,
    numCases: numCases$ | async
  } as state"
>
  <ts-workspace-item
    *ngIf="state.mobile"
    [workspace]="state.workspace"
    [showLabels]="false"
  >
  </ts-workspace-item>

  <div class="card">
    <div
      class="card-header text-center flex-column align-items-center"
      *ngIf="!state.mobile"
    >
      <h2>
        {{ state.workspace?.name | uppercase }}
      </h2>
    </div>
    <nav mat-tab-nav-bar class="full-width space-evenly">
      <a
        mat-tab-link
        [routerLink]="['cases']"
        class="flex-column"
        routerLinkActive
        #rlCases="routerLinkActive"
        [active]="rlCases.isActive"
      >
        <div class="flex-row align-items-center" *ngIf="!state.mobile">
          <i class="ri-file-copy-2-line"></i>
          {{ state.numCases }} {{ "core.cases" | translate }}
        </div>
        <div>
          <a class="button text-primary">
            <i class="ri-file-copy-2-line" *ngIf="!state.mobile"></i>
            {{
              (state.mobile ? "core.cases" : "button.view_cases")
                | translate
                | uppercase
            }}
          </a>
        </div>
      </a>
      <ng-container *ngIfRole="['admin']">
        <a
          mat-tab-link
          [routerLink]="['edit']"
          class="flex-column"
          routerLinkActive
          #rlEdit="routerLinkActive"
          [active]="rlEdit.isActive"
        >
          <div class="flex-row align-items-center" *ngIf="!state.mobile">
            <i class="ri-briefcase-4-line"></i>
            {{ "core.case_type" | translate }}:
            {{ state.workspace?.caseType?.name }}
          </div>
          <div>
            <a class="button text-primary">
              <i class="ri-pencil-line" *ngIf="!state.mobile"></i>
              {{
                (state.mobile ? "button.edit" : "title.edit_workspace")
                  | translate
                  | uppercase
              }}
            </a>
          </div>
        </a>
        <a
          mat-tab-link
          [routerLink]="['members']"
          class="flex-column"
          routerLinkActive
          #rlMembers="routerLinkActive"
          [active]="rlMembers.isActive"
        >
          <div class="flex-row align-items-center" *ngIf="!state.mobile">
            <i class="ri-group-line"></i>
            {{ state.workspace?.numMembers }} {{ "title.members" | translate }}
          </div>
          <div>
            <a class="button text-primary">
              <i class="ri-user-settings-line" *ngIf="!state.mobile"></i>
              {{
                (state.mobile ? "core.members" : "button.manage_members")
                  | translate
                  | uppercase
              }}
            </a>
          </div>
        </a>
      </ng-container>
      <div style="display: flex">
        <button
          mat-icon-button
          *ngIf="aiStatus"
          [matTooltip]="'tooltip.' + aiStatus | translate"
          style="margin-top: auto"
        >
          <img
            src="{{ 'assets/icons/' + aiStatus + '.svg' }}"
            style="width: 80%"
          />
        </button>
      </div>
    </nav>
  </div>
</ng-container>

<router-outlet></router-outlet>
