<div class="card">
  <table mat-table [dataSource]="dataSource" class="spotlab-table" matSort>
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'core.user' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item">
        <span class="flex-row align-items-center justify-left">
          <ts-user-avatar [user]="item.user" style="margin-right: 16px"></ts-user-avatar>
          <span>{{ item.user.username }}</span>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="userNoAvatar">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'core.user' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item">
        <span class="flex-row align-items-center justify-left">
          <span>{{ item.user.username | uppercase }}</span>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="userSince">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'core.start' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item">
        <span>{{ item.affiliateSince | date: 'short' | uppercase }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'core.roles' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item">
        <span class="flex-row justify-left align-items-center">
          <ts-role-badge *ngFor="let role of item.user.roles" [role]="role.getName()">
            {{ role.getName() | translate | uppercase }}
          </ts-role-badge>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="workspaces">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'title.workspaces' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item">
        <span class="flex-row align-items-center justify-left text-gray">
          <i class="ri-file-copy-2-line"></i>
          <span>{{ item.numWorkspaces$ | async }}</span>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="visibleColumns$ | async"></tr>
    <tr mat-row *matRowDef="let user; columns: visibleColumns$ | async" [routerLink]="[]"></tr>
  </table>
</div>
