import { AnalysisState, AnalysisStateRepository, FindOneState } from "@telespot/domain";
import { SampleTopology } from "../acquisition";

import { ParseBaseRepository } from "../../parse-base.repository";

import { AnalysisStateTopology, ParseAnalysisStateMapper } from "./parse-analysis-state.mapper";
import { CaseTopology } from "../workspace";
import { AlgorithmTopology } from "../ai";

export class ParseAnalysisStateRepository extends ParseBaseRepository implements AnalysisStateRepository {

  private readonly analysisStateMapper = new ParseAnalysisStateMapper(this.parse);

  public async getAnalysisStateById(id: string): Promise<AnalysisState> {
    const parseState = await new this.parse.Query(AnalysisStateTopology.TABLE)
      .get(id, this.options);

    return parseState ? this.analysisStateMapper.toDomain(parseState) : undefined;
  }

  public async findOne(filters: FindOneState): Promise<AnalysisState> {
    const ParseCase = this.subclasses.getSubclass(CaseTopology.TABLE);
    const ParseSample = this.subclasses.getSubclass(SampleTopology.TABLE);
    const ParseAlgorithm = this.subclasses.getSubclass(AlgorithmTopology.TABLE);
    const ParseUser = this.parse.User;

    const ParseAnalystEntity = filters.analizerEntity === "user" ? ParseUser : ParseAlgorithm;
    const parseAnalystKey = filters.analizerEntity === "user" ? AnalysisStateTopology.USER : AnalysisStateTopology.ALGORITHM;

    const ParseSubjectEntity = filters.subjectEntity === "sample" ? ParseSample : ParseCase;
    const parseSubjectKey = filters.subjectEntity === "sample" ? AnalysisStateTopology.SAMPLE : AnalysisStateTopology.CASE;

    const parseState = await new this.parse.Query(AnalysisStateTopology.TABLE)
      .equalTo(parseAnalystKey, ParseAnalystEntity.createWithoutData(filters.analizerId))
      .equalTo(parseSubjectKey, ParseSubjectEntity.createWithoutData(filters.subjectId))
      .first(this.options);

    return parseState ? this.analysisStateMapper.toDomain(parseState) : undefined;
  }

  public async *forEachSampleState(...sampleIds: string[]): AsyncGenerator<AnalysisState> {
    const ParseSample = this.subclasses.getSubclass(SampleTopology.TABLE);

    const parseSamples = sampleIds.map(i => ParseSample.createWithoutData(i));

    const query = new this.parse.Query(AnalysisStateTopology.TABLE)
      .containedIn(AnalysisStateTopology.SAMPLE, parseSamples);

    const generator = this.getGeneratorFromQuery(query);

    for await (const items of generator) {
      for (const item of items) yield this.analysisStateMapper.toDomain(item);
    }
  }

  public async saveAnalysisState(analysisState: AnalysisState): Promise<string> {
    const parseState = this.analysisStateMapper.fromDomain(analysisState);

    const { id } = await parseState.save(null, this.options);

    return id;
  }

  public async *findStatesForCase(caseId: string): AsyncGenerator<AnalysisState[]> {
    const parseCase = this.subclasses
      .getSubclass(CaseTopology.TABLE)
      .createWithoutData(caseId);

    const query = new this.parse.Query(AnalysisStateTopology.TABLE)
      .equalTo(AnalysisStateTopology.CASE, parseCase);

    const generator = this.getGeneratorFromQuery(query);

    for await (const items of generator) {
      yield items.map(i => this.analysisStateMapper.toDomain(i));
    }
  }

  public async saveAllAnalysisStates(...states: AnalysisState[]): Promise<AnalysisState[]> {
    const parseStates = states.map(s => this.analysisStateMapper.fromDomain(s));

    const results = await this.parse.Object.saveAll(parseStates, this.options);

    return results.map(r => this.analysisStateMapper.toDomain(r));
  }

}
