import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HotkeysService } from '@ngneat/hotkeys';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, withLatestFrom } from 'rxjs/operators';

import { Label, selectedLabels, selectLabel, selectLabelsArray } from '../../state';

@Injectable({
  providedIn: 'root',
})
export class AnalysisHotkeysService {
  private _modelHotkeys = new Map<number, Label>();

  public getModelHotkey(option: string, task: string): number {
    return Number(
      Array.from(this._modelHotkeys.keys()).find(
        (k) => this._modelHotkeys.get(k).category === task && this._modelHotkeys.get(k).value === option
      )
    );
  }

  constructor(
    private _hotkeys: HotkeysService,
    private _store: Store,
    @Optional() private _snackBar: MatSnackBar,
    @Inject(DOCUMENT) private _document
  ) {
    this._store
      .select(selectLabelsArray)
      .pipe(distinctUntilChanged((l1, l2) => l1?.length === l2?.length))
      .subscribe((labels) => {
        this._clearModelHotkeys();
        labels?.forEach((label, index) => {
          if (index > 8) return;
          this._assignShortcut(index + 1, label);
        });
      });

    Array.from(new Array(9).keys()).forEach((k) => {
      this._hotkeys
        .addShortcut({
          keys: `meta.${k}`,
          trigger: 'keydown',
        })
        .pipe(withLatestFrom(this._store.select(selectedLabels)))
        .subscribe(([event, labels]) => {
          if (labels.length) this._assignShortcut(Number(event.key), labels[0], true);
        });
    });
  }

  private _assignShortcut(key: number, label: Label, showAlert?: boolean) {
    if (this._modelHotkeys.has(key)) this._hotkeys.removeShortcuts([`${key}`]);

    const previousHotkey = this.getModelHotkey(label.value, label.category);
    if (previousHotkey) {
      this._hotkeys.removeShortcuts([`${previousHotkey}`]);
      this._modelHotkeys.delete(previousHotkey);
    }

    this._modelHotkeys.set(key, label);
    this._hotkeys
      .addShortcut({
        keys: `${key}`,
        description: `Select model ${label.value}`,
        preventDefault: false,
      })
      .subscribe(() => {
        const activeNodeName = this._document.activeElement.nodeName.toLowerCase();
        const activeNodeType = this._document.activeElement.getAttribute('type');
        if (activeNodeName === 'textarea' || (activeNodeName === 'input' && activeNodeType === 'text')) return;
        if (label) {
          this._store.dispatch(selectLabel({ option: label.value, task: label.category }));
        }
      });
    if (showAlert) {
      this._snackBar.open(`Assigned ${key} to model ${label.value}`, null, { duration: 1000 });
    }
  }

  private _clearModelHotkeys(): void {
    const shortcuts = Array.from(this._modelHotkeys.keys());
    this._modelHotkeys.clear();
    this._hotkeys.removeShortcuts(shortcuts.map((s) => s.toString()));
  }
}
