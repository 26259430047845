import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { AuthService } from "@telespot/web-core";
import { DataTableConfig } from "@shared/ui";
import {
  applyWorkspacesFilter,
  clearCasesState,
  clearWorkspacesState,
  removeWorkspacesFilter,
  setCurrOrganization,
  workspacesChangePage,
} from "@telespot/analysis-refactor/data-access";
import { PageEvent } from "@angular/material/paginator";
import { WorkspaceRowData, columns } from "./workspaces-row-data";
import { WorkspacesDataSource } from "./workspaces-data-source";
import { Router } from "@angular/router";

@Component({
  selector: "ts-workspaces",
  templateUrl: "./workspaces.component.html",
  styleUrls: ["./workspaces.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspacesComponent implements OnInit, OnDestroy {
  constructor(
    private authService: AuthService,
    private store: Store,
    private router: Router
  ) {}

  /* DataTable Configuration Parameters */

  public dataSource = new WorkspacesDataSource(this.store);
  public tableConfig: DataTableConfig<WorkspaceRowData>;

  /* Component Lifecycle Methods */

  ngOnInit(): void {
    /* Subscribe to organization changes  */
    this.authService.currentOrganization$.subscribe((org) => {
      /* Dispatch actions to initialize state  */
      this.store.dispatch(setCurrOrganization({ organization: org }));
    });

    /* Dispatch actions to reset the state  */
    this.store.dispatch(clearCasesState());

    /* Get the configuration for the table */
    this.tableConfig = this.getTableConfig();
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearWorkspacesState());
  }

  /* UI Handlers */

  public changePage({ pageIndex, pageSize }: PageEvent) {
    this.store.dispatch(workspacesChangePage({ pageIndex, pageSize }));
  }

  public goToWorkspaceDetail(item): void {
    this.router.navigate(["/workspaces", item.workspace?.id, "cases"]);
  }

  public applyFilter(filter) {
    this.store.dispatch(applyWorkspacesFilter({ filter }));
  }

  public removeFilter(filter) {
    this.store.dispatch(removeWorkspacesFilter({ filter }));
  }

  /* Private Functions for Component Presentation */

  /**
   * Provides a configuration for the cases table.
   *
   * @param encryption a boolean indicating if the table should be configured with decryption in mind
   * @returns an instance of {@link DataTableConfig}
   */
  private getTableConfig() {
    return {
      columns,
      showPaginator: true,
      showFilters: false,
      hideHeader: true,
      showSearch: true,
      showStatusIndicator: false,
      showRefreshButton: false,
      useQueryParams: false,
    };
  }
}
