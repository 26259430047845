import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@telespot/web-core';
import { AssetType, DeviceType, Query } from '@telespot/sdk';
import { ValidateVersion } from '@telespot/shared/util';
import { defer, Observable, Subject } from 'rxjs';
import { shareReplay, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ts-device-type-editor',
  templateUrl: './device-type-editor.component.html',
  styleUrls: ['./device-type-editor.component.scss'],
})
export class DeviceTypeEditorComponent implements OnDestroy {
  formGroup: UntypedFormGroup;
  private _deviceType: DeviceType;

  private _assetTypeQuery = new Query(AssetType).select('name');
  private _destroy$ = new Subject<void>();
  public readonly assetTypes$: Observable<AssetType[]> = defer(() => this._dataService.findAll(this._assetTypeQuery)).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(private _route: ActivatedRoute, private _dataService: DataService, private _location: Location) {
    this._getDeviceType();
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  async _getDeviceType() {
    const id = this._route.snapshot.paramMap.get('id');
    if (id) {
      this._deviceType = await this._dataService.get(id, new Query(DeviceType));
    } else {
      this._deviceType = new DeviceType();
    }

    const supportingAssetTypes = id
      ? await this._dataService.find(new Query(AssetType).containsAll('deviceType', [this._deviceType]))
      : [];

    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this._deviceType.name || '', Validators.required),
      version: new UntypedFormControl(this._deviceType.version || '1.0', ValidateVersion),
      assetTypes: new UntypedFormControl(supportingAssetTypes || []),
    });

    this.assetTypes$.pipe(take(1), takeUntil(this._destroy$)).subscribe((allAssetTypes) => {
      this.formGroup.patchValue({
        assetTypes: allAssetTypes.filter((at) => supportingAssetTypes.find((a) => a.id === at.id)),
      });
    });
  }

  cancel() {
    this._location.back();
  }

  async save() {
    this._deviceType.name = this.formGroup.value.name;
    this._deviceType.version = this.formGroup.value.version;
    await this._dataService.save(this._deviceType);

    // Add to AssetTypes
    for (const assetType of this.formGroup.value.assetTypes as AssetType[]) {
      assetType.addUnique('deviceType', this._deviceType);
      await this._dataService.save(assetType);
    }

    this._location.back();
  }

  public sameId(at1: AssetType, at2: AssetType): boolean {
    return at1?.id === at2?.id;
  }
}
