import { RoiModel } from '../..';

export interface IAssetROI {
  time?: number;
  x: number;
  y: number;
  w?: number;
  h?: number;
  //REMOVE when removing its references
  assetId?: string;
}

export interface IAssetROIWithModels extends IAssetROI {
  models?: RoiModel[];
  isAIresult?: boolean;
  score?: number;
  id?: string;
  // REMOVE IN FUTURE (JUST TO PAPER)
  classAI?: string;
}

export class AssetUtils {
  static toJSON(roi: IAssetROIWithModels): { [key: string]: unknown } {
    const { x, y, w, h, time } = roi;
    return { x, y, w, h, time };
  }

  static toString(roi: IAssetROIWithModels): string {
    return `${Math.floor(roi.x)}_${Math.floor(roi.y)}_${Math.floor(roi.w)}_${Math.floor(roi.h)}${
      roi.time ? '_' + Math.floor(roi.time * 1000) + 'ms' : ''
    }`;
  }

  // getModelsList(): string {
  //   return `${this.isAIresult ? '[AI] - ' : ''}${this.models?.map(model => model.displayName).join(', ') || 'WTF'}`;
  // }

  static makePositiveCoords(roi: IAssetROI): IAssetROI {
    const { w, h } = roi;
    if (w < 0) {
      roi.x += w;
      roi.w = -w;
    }
    if (h < 0) {
      roi.y += h;
      roi.h = -h;
    }
    return { ...roi };
  }

  static makeIntegerCoords(roi: IAssetROI): IAssetROI {
    roi.x = Math.floor(roi.x);
    roi.y = Math.floor(roi.y);
    roi.w = Math.floor(roi.w);
    roi.h = Math.floor(roi.h);
    return { ...roi };
  }
}

export interface IModelPresence {
  value: boolean;
  model: RoiModel;
  viewMode?: boolean;
}

export interface IModelCount {
  value: number;
  model: RoiModel;
  viewMode?: boolean;
}
