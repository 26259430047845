import { ILoginHandler, Login } from "@telespot/use-cases";
import { ParseBaseRepository } from "../../parse-base.repository";

export class ParseLoginHandler extends ParseBaseRepository implements ILoginHandler {

  public async execute(command: Login) {
    const { username, password } = command.details;

    const loggedUser = await this.parse.User.logIn(username, password);

    return loggedUser.getSessionToken();
  }

}
