import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DataService } from "@telespot/web-core";
import { CaseType, Workspace } from "@telespot/sdk";
import { RowData } from "@shared/utils";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarAlertComponent } from "@shared/ui";

type WorkspaceAssignationStateValue = "empty" | "other" | "assigned";
interface WorkspaceAssignationState extends RowData {
  workspace: Workspace;
  state: WorkspaceAssignationStateValue;
}

interface WorkspaceRowData extends RowData {
  resource: any;
  id: string;
}

@Component({
  selector: "ts-workspace-assignation-editor",
  templateUrl: "./workspace-assignation-editor.component.html",
  styleUrls: ["./workspace-assignation-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceAssignationEditorComponent {
  resource: Workspace;
  @Input() set workspaces(workspaces: Workspace[]) {
    /*this.workspaceItems = (workspaces ?? []).map((workspace) => ({
      workspace,
      state: this._getState(workspace),
    }));
    */
  }
  @Input() caseType: CaseType;

  @Input() tableConfig;
  @Input() dataSource;

  public workspaceItems: WorkspaceAssignationState[] = [];

  constructor(
    private dataService: DataService,
    private snackBar: MatSnackBar
  ) {}

  // Workspaces linking
  onWorkspaceToggled(item: WorkspaceRowData, caseType: CaseType) {
    const workspace = Workspace.createWithoutData(
      item.resource._id
    ) as Workspace;
    workspace.caseType = caseType;

    this.dataService.save(workspace).then(
      () => {
        if (!caseType) {
          const data = {
            title: `Cleared ${item.resource.name} CaseType assignation`,
            icon: "ri-close-fill",
          };
          this.snackBar.openFromComponent(SnackbarAlertComponent, {
            duration: 4000,
            data,
          });
        } else {
          this.dataSource.fetch();
          const data = {
            title: `CaseType "${
              caseType.name
            }" assigned to workspace "${workspace.name.toUpperCase()}"`,
            icon: "ri-close-fill",
          };
          this.snackBar.openFromComponent(SnackbarAlertComponent, {
            duration: 4000,
            data,
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public _getState(workspace: Workspace): WorkspaceAssignationStateValue {
    return !workspace.caseType
      ? "empty"
      : !!this.caseType && this.caseType.id === workspace.caseType?.id
      ? "assigned"
      : "other";
  }
}
