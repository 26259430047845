import { Directive, EventEmitter, Output } from '@angular/core';
import { Asset, Sample } from '@telespot/sdk';
import { ViewerConfig } from "@telespot/shared/viewers/data-access";
import { ReplaySubject } from 'rxjs';

@Directive()
export abstract class BaseAssetViewer {
  abstract src: Sample | Asset;

  protected readonly _viewerReady = new ReplaySubject<boolean>();
  public readonly viewerReady$ = this._viewerReady.asObservable();

  @Output() itemIndexChanged = new EventEmitter<number>();

  abstract configure(conf: ViewerConfig);
}
