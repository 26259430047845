export interface ISwAppData {
  forceUpdate?: boolean;
  changelog?: string;
}

export interface INotificationPayload {
  message: string;
  actionData?: {
    action: string;
    callback?: () => void;
  };
  duration?: number;
}

export const LS_PENDING_KEY = 'sw_update_pending_notification';
