import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireAnalytics, AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '@telespot/shared/environment';

import { CookieConsentDialogComponent } from './components/cookie-consent-dialog/cookie-consent-dialog.component';
import { CookieConsentService } from './services/cookie-consent.service';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatButtonModule,
    ...(environment.firebase ? [AngularFireAnalyticsModule] : []),
  ],
  providers: [...(environment.firebase ? [AngularFireAnalytics] : [{ provide: AngularFireAnalytics, useValue: undefined }])],
  declarations: [CookieConsentDialogComponent],
})
export class CookieConsentFeatureModule {
  constructor(private dialog: MatDialog, private cookieConsentService: CookieConsentService) {
    if (!this.cookieConsentService.hasUserPreferences()) {
      this.dialog.open(CookieConsentDialogComponent, { width: '800px', maxWidth: '95vw', disableClose: true });
    }
  }
}
