import type Parse from "parse";

import { Affiliate, User, ListUserQuery, UserRepository } from "@telespot/domain";

import { ParseBaseRepository } from "../../parse-base.repository";
import { AffiliateTopology, ParseAffiliateMapper } from "../organization";

import { ParseUserMapper, UserTopology } from "./parse-user.mapper";

export class ParseUserRepository extends ParseBaseRepository implements UserRepository {

  private readonly userMapper = new ParseUserMapper(this.parse);
  private readonly affiliateMapper = new ParseAffiliateMapper(this.parse);

  public async delete(id: string): Promise<void> {
    const parseUser = this.parse.User.createWithoutData(id);

    await parseUser.destroy(this.options);
  }

  /**
   * Creates a session for a given user ID
   *
   * NOTE: server only contenxt
   *
   * @param userId string value of the user ID
   * @returns string token for the created session
   */
  public async createSession(userId: string): Promise<string> {
    const parseUser = await this.parse.User
      .createWithoutData(userId)
      .logIn(this.options);

    return parseUser.getSessionToken();
  }

  public async deleteAllSessions(userId: string): Promise<void> {
    const parseSessions = await new this.parse.Query(this.parse.Session)
      .equalTo("user", this.parse.User.createWithoutData(userId))
      .find(this.options);

    await this.parse.Session.destroyAll(parseSessions, this.options);
  }

  public async getAffiliations(userId: string): Promise<Affiliate[]> {
    const parseAffiliations = await new this.parse.Query(AffiliateTopology.TABLE)
      .equalTo(AffiliateTopology.USER, this.parse.User.createWithoutData(userId))
      .find(this.options);

    return parseAffiliations.map(pa => this.affiliateMapper.toDomain(pa));
  }

  public async getWithToken(token: string): Promise<User> {
    const parseUser = await this.parse.User.become(token);

    return parseUser ? this.userMapper.toDomain(parseUser) : undefined;
  }

  public async save(user: User): Promise<string> {
    const options: Parse.Object.SaveOptions = { ...this.options };

    if (user.preferedLanguage) options.context = { i18n: user.preferedLanguage };

    const { id } = await this.userMapper.fromDomain(user).save(null, options);

    return id;
  }

  public async deleteSession(sessionId: string): Promise<void> {
    const session = this.parse.Session.createWithoutData(sessionId);
    await session.destroy(this.options);
  }

  public async getById(id: string): Promise<User> {
    const user = await this.parse.User.createWithoutData(id).fetch(this.options);

    return user ? this.userMapper.toDomain(user) : undefined;
  }

  public async getByEmail(email: string): Promise<User> {
    const user = await new this.parse.Query(this.parse.User)
      .equalTo(UserTopology.EMAIL, email)
      .first(this.options);

    return user ? this.userMapper.toDomain(user) : undefined;
  }

  public async getByName(username: string): Promise<User> {
    const user = await new this.parse.Query(this.parse.User)
      .equalTo(UserTopology.USERNAME, username)
      .first(this.options);

    return user ? this.userMapper.toDomain(user) : undefined;
  }

  public async *list(request: ListUserQuery): AsyncGenerator<User[]> {
    const { limit, skip } = request;

    const query = new this.parse.Query(this.parse.User)
      .descending("createdAt");

    const generator = this.getGeneratorFromQuery(query, limit, skip);

    for await (const results of generator) yield results.map(r => this.userMapper.toDomain(r));
  }
}
