import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, NgModule } from '@angular/core';

@Component({
  selector: 'ts-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent {
  @Input() user: {
    username: string;
    avatar: {
      url: () => string;
    };
  };

  get initials(): string {
    let username = this.user ? this.user.username || '?' : '?';
    try {
      const usernameParts = username.split(/[\s_]/gm);
      username = usernameParts.length > 1 ? usernameParts[0][0] + usernameParts[1][0] : username;
    } catch (err) {
      //
    }
    return username[0] + (username.length > 1 ? username[1] : '');
  }

  wrongImageUrl = false;

  @HostBinding('style.border-radius') private _borderRadius = '50%';

}

@NgModule({
  imports: [CommonModule],
  declarations: [UserAvatarComponent],
  exports: [UserAvatarComponent],
})
export class UserAvatarModule { }
