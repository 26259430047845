<div class="main-container">
  <div class="title">
    <h1>{{ "title.devices" | translate }}</h1>
    <button
      class="btn btn-outline-primary"
      [routerLink]="['/protocols/deviceTypes/edit/new']"
      matTooltip="Add new device type"
    >
      <i class="ri-add-circle-line"></i>
      {{ "button.new_device_type" | translate }}
    </button>
    <button class="btn btn-primary" [routerLink]="['new']">
      <i class="ri-add-circle-line"></i>
      {{ "button.new_device" | translate }}
    </button>
  </div>

  <div class="card">
    <ts-data-table
      mat-table
      width="100%"
      [config]="tableConfig"
      [dataSource]="dataSource"
      class="spotlab-table"
      matSort
    >
      <ng-template dataTableCell="organization" let-item>
        <td>
          <img
            [src]="item.resource.organization?.logoUrl"
            [attr.alt]="item.resource.organization?.name + 'logo'"
            fallbackSrc="assets/media/organization_placeholder.svg"
            class="avatar user-avatar--32"
          />
          {{ item.resource.organization?.name || "Unknown" | translate }}
        </td>
      </ng-template>
      <ng-template dataTableCell="name" let-item>
        <td>
          {{ item.resource.name }}
        </td>
      </ng-template>
      <ng-template dataTableCell="device_type" let-item>
        <td>
          {{ item.resource.deviceType.name }}
        </td>
      </ng-template>
      <ng-template dataTableCell="version" let-item>
        <td>
          {{ item.resource.deviceType.version | uppercase }}
        </td>
      </ng-template>
      <ng-template dataTableCell="serial_number" let-item>
        <td>
          {{ item.resource.serialNumber | uppercase }}
        </td>
      </ng-template>
    </ts-data-table>
  </div>
</div>
