import { createAction, props } from "@ngrx/store";
import { Context, ProtocolSpecification } from "./protocol.reducer";
import { Sample } from "@telespot/sdk";

export const protocolActionError = createAction(
  "[PROTOCOLS] Action Error",
  props<{ error: string }>()
);

export const loadProtocol = createAction(
  "[PROTOCOLS] Load Protocol",
  props<{ sample: Sample }>()
);

export const protocolLoaded = createAction(
  "[PROTOCOLS] Protocol loaded",
  props<{ protocols: ProtocolSpecification[]; counterFilters: Array<string> }>()
);

export const setContext = createAction(
  "[PROTOCOLS] Set Context",
  props<{ context: Context }>()
);

export const selectLabel = createAction(
  "[PROTOCOLS] Select Label",
  props<{ option: string; task: string }>()
);

export const togglePinnedOptionsOnly = createAction(
  "[PROTOCOLS] Toggle Pinned Options Only"
);

export const pinTaskOption = createAction(
  "[PROTOCOLS] Pin Option",
  props<{ option: string; task: string }>()
);

export const toggleMultiSelect = createAction("[PROTOCOLS] Toggle Multiselect");

export const hideLabel = createAction(
  "[PROTOCOLS] Hide Label",
  props<{ option: string; task: string }>()
);

export const resetProtocolState = createAction("[PROTOCOLS] Reset State");

export const selectMultipleLabelIds = createAction(
  "[PROTOCOLS] Select multiple labels",
  props<{ labelIds: string[] }>()
);

export const updateOptionFromCounter = createAction(
  "[PROTOCOLS] Update label option counter contribution",
  props<{ option: string; task: string }>()
);

export const optionForCounterSaved = createAction(
  "[PROTOCOLS] Label options for counter saved",
  props<{ option: string; task: string }>()
);

export const setInitialCounterFilters = createAction(
  "[PROTOCOLS] Set initial counter filters from query params",
  props<{ counterFilters: string[]; sampleId: string }>()
);

export const addCustomLabel = createAction(
  "[PROTOCOLS] Add Custom Label",
  props<{ option: string; task: string; analysisTypeId: string }>()
);

export const clearAllLabels = createAction(
  "[PROTOCOLS] Clear All Selected Labels"
);

export const setCollapseOption = createAction(
  "[PROTOCOLS] Set collapsed task property",
  props<{ task: string; collapsed: boolean; analysisTypeId: string }>()
);
export const counterFiltersFromParamsSaved = createAction(
  "[PROTOCOLS] Counter filters from query params saved into state",
  props<{ counterFilters: string[] }>()
);
