import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard } from '@telespot/web-core';

import { SettingsPanelComponent } from './settings-panel/settings-panel.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatCheckboxModule,
    FormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: SettingsPanelComponent,
        canActivate: [AuthGuard],
        data: { allowedRoles: ['admin'], title: 'Settings' },
      },
    ]),
  ],
  declarations: [SettingsPanelComponent],
})
export class TelespotSettingsFeatureShellModule {}
