<div class="flex-row align-items-center justify-content-between flex-wrap" style="padding: 0px 10px">
  <div class="flex-row align-items-center flex-grow-1">
    <span *ngIf="{ step: currentStepId + 1, numSteps: stepsCount } as params" class="flex-shrink-0 me-2">
      {{ 'label.stepIofN' | translate: params }}
    </span>
    <div class="progress-bar-bg">
      <div class="progress-bar" [style.width]="progress + '%'"></div>
    </div>
  </div>
  <div class="button-bar align-right">
    <button
      class="btn btn-flat-primary btn-auto"
      [disabled]="disabled || currentStepId <= 0"
      [hidden]="hidden"
      (click)="previousStep()"
      data-cy="button-stepper-prev"
    >
      <i class="ri-arrow-left-s-line"></i>
      {{ 'core.previous' | translate | uppercase }}
    </button>
    <button
      class="btn btn-flat-primary btn-auto"
      [disabled]="disabled || currentStepId >= stepsCount - 1"
      [hidden]="hidden"
      (click)="nextStep()"
      data-cy="button-stepper-next"
    >
      {{ 'core.next' | translate | uppercase }}
      <i class="ri-arrow-right-s-line"></i>
    </button>
  </div>
</div>
<ng-content> </ng-content>
