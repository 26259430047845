import type Parse from "parse";

import { ProtocolAlgorithm } from "@telespot/domain";

import { AlgorithmTopology, ParseAlgorithmMapper } from "../ai";
import { ParseMapper } from "../../parse.mapper";
import { ObjectTopology } from "../../parse.topology";

import { AnalysisTypeTopology } from "./parse-analysis-type.mapper";
import { MethodTypeTopology } from "./parse-method-type.mapper";

export enum ProtocolAlgorithmTopology {
  TABLE = "ProtocolAlgorithms",
  MODEL = "model",
  ANALYSIS_TYPE = "analysisType",
  METHOD_TYPE = "methodType",
}

export class ParseProtocolAlgorithmMapper extends ParseMapper<ProtocolAlgorithm> {

  private readonly algoMapper = new ParseAlgorithmMapper(this.parse);

  public toDomain(parseObject: Parse.Object): ProtocolAlgorithm {
    return new ProtocolAlgorithm({
      id: parseObject.id,
      model: this.algoMapper.toDomain(parseObject.get(ProtocolAlgorithmTopology.MODEL)),
      methodTypeId: parseObject.get(ProtocolAlgorithmTopology.METHOD_TYPE).id,
      analysisTypeId: parseObject.get(ProtocolAlgorithmTopology.ANALYSIS_TYPE)?.id,
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
    });
  }

  public fromDomain(protoAlgo: ProtocolAlgorithm): Parse.Object {
    const analysisType = this.subclasses.getSubclass(AnalysisTypeTopology.TABLE).createWithoutData(protoAlgo.analysisTypeId)
    const methodType = this.subclasses.getSubclass(MethodTypeTopology.TABLE).createWithoutData(protoAlgo.methodTypeId)
    const algorithm = this.subclasses.getSubclass(AlgorithmTopology.TABLE).createWithoutData(protoAlgo.model.id)

    const parseObject = this.parse.Object.fromJSON({
      className: ProtocolAlgorithmTopology.TABLE,
      [ObjectTopology.ID]: protoAlgo.id,
      [ObjectTopology.CREATED_AT]: protoAlgo.createdAt,
      [ObjectTopology.UPDATED_AT]: protoAlgo.updatedAt,
    });

    parseObject.set({
      [ProtocolAlgorithmTopology.MODEL]: algorithm,
      [ProtocolAlgorithmTopology.METHOD_TYPE]: methodType,
      [ProtocolAlgorithmTopology.ANALYSIS_TYPE]: protoAlgo.analysisTypeId ? analysisType : undefined,
    })

    return parseObject;
  }
}
