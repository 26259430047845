<form [formGroup]="dialogForm" *ngIf="dialogForm">
  <h4 mat-dialog-title>You are going to share this sample.</h4>
  <div mat-dialog-content>
    <div class="flex-column">
      <div>{{ 'title.emails_alias_required' | translate | uppercase }}</div>
      <div>{{ 'info.alias_for_email' | translate }}</div>
      <div class="flex-column align-items-center">
        <div
          *ngFor="let data of guestUsersFormArray.controls; let i = index"
          formArrayName="guestUsers"
          class="flex-row justify-content-between align-items-center"
          style="width: 100%"
        >
          <ng-container [formGroup]="data">
            <mat-form-field style="margin-right: 88px">
              <input
                matInput
                style="flex: 10 0 0"
                formControlName="email"
                data-cy="email-input"
                [placeholder]="'title.emails' | translate"
                type="email"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                style="flex: 10 0 0"
                formControlName="alias"
                data-cy="alias-input"
                [placeholder]="'title.alias' | translate"
                type="text"
              />
            </mat-form-field>
            <i
              *ngIf="!(guestUsersFormArray.controls.length === 1)"
              (click)="removeGuestUser(i)"
              style="flex: 1 0 0; cursor: pointer"
              class="ri-indeterminate-circle-line"
            ></i>
          </ng-container>
        </div>
        <div
          (click)="addGuestUser()"
          style="flex: 1 0 0; align-self: flex-start; cursor: pointer"
          class="flex-row align-items-center justify-content-start add-new"
        >
          <i class="ri-add-circle-line"></i>
          <span>{{ 'ADD NEW' | translate | uppercase }}</span>
        </div>
      </div>
    </div>
    <div class="flex-column">
      <div>{{ 'title.subject_required' | translate | uppercase }}</div>
      <mat-form-field style="width: 100%">
        <textarea
          data-cy="subject-input"
          formControlName="subject"
          style="width: 100%"
          matInput
          autofocus
          type="text"
          autocomplete="off"
          placeholder="{{ 'title.subject' | translate }}"
        ></textarea>
      </mat-form-field>
      <mat-error *ngIf="dialogForm.get('subject')?.errors?.maxlength">{{
        'label.invalid_subject_error' | translate
      }}</mat-error>
    </div>
    <div class="flex-column">
      <div>{{ 'title.comments_required' | translate | uppercase }}</div>
      <mat-form-field style="width: 100%">
        <textarea
          data-cy="comments-input"
          formControlName="comments"
          style="width: 100%"
          matInput
          autofocus
          type="text"
          autocomplete="off"
          placeholder="{{ 'title.comments' | translate }}"
        ></textarea>
      </mat-form-field>
    </div>
    <ts-total-assets-selector [assets]="data.assets" (emitter)="receivedData($event)"></ts-total-assets-selector>
  </div>

  <mat-dialog-actions class="flex-row justify-content-center">
    <button mat-dialog-close class="btn btn-outline-primary" style="margin: auto">
      {{ 'button.cancel' | translate | uppercase }}
    </button>
    <button
      data-cy="accept-button"
      class="btn btn-primary"
      style="margin: auto"
      [disabled]="!((minAssetSelected$ | async) && dialogForm.valid)"
      (click)="accept()"
      color="primary"
    >
      {{ 'button.send' | translate | uppercase }}
    </button>
  </mat-dialog-actions>
</form>
