import { SampleAsset } from "./sample-asset";
import { Tag, State, ProcessingStatus, Result } from "../value-objects";

export interface ScanSize {
  shotsX: number;
  shotsY: number;
}

export enum ScanError {
  SCAN_TOO_SMALL = `Stitchings smaller than 3x3 are not supported.`,
  SCAN_INCOMPLETE = `Total number of images is less than expected.`,
  SCAN_ALREADY_COMPLETE = `Stitching of scan already completed.`,
  SCAN_RUNNING = `Stitching of scan already running.`,
}

export interface ScanParameters {
  tag: Tag;
  assetList: string[];
  sampleAsset?: SampleAsset;
  corrvigneting: boolean;
  status?: ProcessingStatus;
  scanSize: ScanSize;
}

export interface ScanValidationResult {
  isValid: boolean;
}

/**
 * Aggregate entity which represents an entire scan formed by multiple individual assets.
 *
 * // REVIEW: aggregate is overly comples. Consider using individual component entities for the use cases
 */
export class Scan {

  constructor(private attributes: ScanParameters) { }

  public get tag(): Tag {
    return this.attributes.tag;
  }

  public get scanSize(): ScanSize | null {
    return this.attributes.scanSize;
  }

  public get corrvigneting(): boolean {
    return this.attributes.corrvigneting;
  }

  public get status() {
    return this.attributes.status ?? { state: State.PENDING };
  }

  public get sampleAsset(): SampleAsset | undefined {
    return this.attributes.sampleAsset;
  }

  public get assetList(): string[] {
    return this.getDeduplicatedFileNames(this.attributes.assetList);
  }

  public validate(): Result<ScanValidationResult, ScanError> {
    let error: ScanError;

    switch (true) {
      case this.attributes.tag.size < 9:
        error = ScanError.SCAN_TOO_SMALL;
        break;
      case this.assetList.length < this.attributes.tag.size:
        error = ScanError.SCAN_INCOMPLETE;
        break;
      default:
        error = undefined;
        break;
    }

    return { data: { isValid: !error }, error };
  }

  public markAsFailed(cause: string, errorCode: number) {
    this.attributes.status = {
      state: State.FAILED,
      cause,
      errorCode,
    }
  }

  public markAs(state: State) {
    this.attributes.status = { state };
  }

  /**
   * List of deduplicated filenames
   */
  private getDeduplicatedFileNames(assetList: string[]) {
    const positiveLookbehindRegexMagic = /(?<=_[0-9]{3}-)/;

    const uniqueTimes: Set<string> = new Set();
    const deduplicatedFilesNames = [];

    for (const name of assetList) {
      const components = name.split(positiveLookbehindRegexMagic);
      const captureTime = components[0];

      if (uniqueTimes.has(captureTime)) continue;

      uniqueTimes.add(captureTime);
      deduplicatedFilesNames.push(name);
    }

    return deduplicatedFilesNames;
  }
}
