import { Inject, Injectable, Provider } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';

import { ANALYTICS_CONFIG, AnalyticsConfig, EVENT_PAGE_TITLE, EVENT_PAGE_TITLE_PAYLOAD } from '../config/analytics-config';
import { ANALYTICS_PROVIDER, AnalyticsProvider } from '../config/analytics-provider';
import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root',
})
export class TitleAnalyticsProvider extends AnalyticsProvider {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private title: Title,
    @Inject(ANALYTICS_CONFIG) private config: AnalyticsConfig
  ) {
    super();
    this.router.events
      .pipe(
        filter((event) => this.analyticsService.isEnabled && event instanceof NavigationEnd),
        map(() => this.getAggregatedRouteData(this.activatedRoute.root.snapshot)),
        map((data) => [data['title'] as string, this.resolveTitle(data)]),
        takeUntil(this.destroy$)
      )
      .subscribe(async ([title, finalTitle]) => {
        if (title) {
          const redactedTitle = this.resolveTitle({ title }, '***');
          await this.analyticsService.setCurrentScreen(redactedTitle);
          await this.analyticsService.logEvent(EVENT_PAGE_TITLE, {
            [EVENT_PAGE_TITLE_PAYLOAD]: redactedTitle,
          });
          await this.analyticsService.logEvent(`${EVENT_PAGE_TITLE}:${redactedTitle}`);
        }
        if (this.config?.change_tab_title) {
          this.title.setTitle(finalTitle);
        }
      });
  }

  private getAggregatedRouteData(snapshot: ActivatedRouteSnapshot): { [key: string]: string | number } {
    let data = {};
    let deepestChild = snapshot.firstChild;
    while (deepestChild?.firstChild) {
      deepestChild = deepestChild.firstChild;
      data = { ...data, ...deepestChild.data };
    }
    return data;
  }

  private resolveTitle(data: { [key: string]: unknown }, placeholder: string = ''): string | undefined {
    const title: string = data['title'] as string;
    if (!title) return undefined;

    const vars = /{{.+?}}/.exec(title);
    const values = vars
      ? vars.reduce(
          (vars, cur) => [
            ...vars,
            {
              replace: [`${cur}`],
              with: cur
                .replace(/({|})+/g, '')
                .split('.')
                .reduce((part, cur) => part?.[cur], data),
            },
          ],
          []
        )
      : [];
    const finalTitle = values.reduce((title: string, cur) => title.replace(cur.replace, cur.with ?? placeholder), title);
    return finalTitle;
  }
}

export const TITLE_ANALYTICS_PROVIDER: Provider = {
  provide: ANALYTICS_PROVIDER,
  useClass: TitleAnalyticsProvider,
  multi: true,
};
