import { Component, OnInit } from '@angular/core';
import { HeaderFooterService } from '@shared/ui';

@Component({
  selector: 'ts-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss'],
})
export class ThanksComponent implements OnInit {
  userThanked: string;

  constructor(private headerFooterService: HeaderFooterService) {}

  ngOnInit() {
    this.headerFooterService.toggleLogo(true);
    this.userThanked = localStorage.getItem('userThanked');
  }

  canDeactivate() {
    localStorage.removeItem('userThanked');
    return true;
  }
}
