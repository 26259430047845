<div osdOverlay [position]="position" units="px" id="ruler">
  <span
    #moveArea
    id="moveArea"
    mat-button
    style="display: inline-block; position: relative"
  >
    <div
      class="ruler-text"
      [style.fontSize.px]="rulerSizePix * 0.3"
      [style.height.px]="rulerSizePix * 0.2"
      *ngIf="rulerOption !== 'none'"
      style="margin-bottom: 15px"
    >
      {{ rulerSizeUmText }}
    </div>

    <ng-container [ngSwitch]="rulerOption">
      <ng-container *ngSwitchCase="'circle'">
        <svg [style.width.px]="rulerSizePix" [style.height.px]="rulerSizePix">
          <circle
            cx="50%"
            cy="50%"
            r="47.5%"
            fill="transparent"
            stroke="rgb(33, 33, 33)"
            stroke-width="5%"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'line'">
        <svg [style.width.px]="rulerSizePix" [style.height.px]="rulerSizePix">
          >
          <line
            x1="0%"
            y1="50%"
            x2="100%"
            y2="50%"
            stroke="#212121"
            stroke-width="5%"
          ></line>
          <line
            x1="0%"
            y1="60%"
            x2="0%"
            y2="40%"
            stroke="#212121"
            stroke-width="5%"
          ></line>
          <line
            x1="100%"
            y1="60%"
            x2="100%"
            y2="40%"
            stroke="#212121"
            stroke-width="5%"
          ></line>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'cross'">
        <svg [style.width.px]="rulerSizePix" [style.height.px]="rulerSizePix">
          <line
            x1="0%"
            y1="50%"
            x2="100%"
            y2="50%"
            stroke="#212121"
            stroke-width="5%"
          ></line>
          <line
            x1="0%"
            y1="60%"
            x2="0%"
            y2="40%"
            stroke="#212121"
            stroke-width="5%"
          ></line>
          <line
            x1="100%"
            y1="60%"
            x2="100%"
            y2="40%"
            stroke="#212121"
            stroke-width="5%"
          ></line>
          <line
            y1="0%"
            x1="50%"
            y2="100%"
            x2="50%"
            stroke="#212121"
            stroke-width="5%"
          ></line>
          <line
            y1="0%"
            x1="60%"
            y2="0%"
            x2="40%"
            stroke="#212121"
            stroke-width="5%"
          ></line>
          <line
            y1="100%"
            x1="60%"
            y2="100%"
            x2="40%"
            stroke="#212121"
            stroke-width="5%"
          ></line>
        </svg>
      </ng-container>
      <ng-container *ngSwitchDefault> </ng-container>
    </ng-container>
  </span>
</div>
