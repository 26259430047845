import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DataService } from '@telespot/web-core';
import { Asset, IAssetROI, IAssetROIWithModels, RoiModel, Sample } from '@telespot/sdk';
import { environment } from '@telespot/shared/environment';
import { LoggerService } from '@telespot/shared/logger/feature/util';
import { BehaviorSubject, forkJoin, merge, Observable } from 'rxjs';
import { distinctUntilChanged, endWith, map, startWith, take, takeWhile, tap } from 'rxjs/operators';

import { nonMaxSuppression } from '../../utils/non-max-suppression';
import { SampleAnalysisService } from '../sample-analysis/sample-analysis.service';
import { IRoiProvider } from './ai-providers/i-ai-roi-provider';
import { AI_ROI_PROVIDER } from './ai-roi-provider';
import { defaultAnalyzeConfig, IAnalyzeConfig } from './i-analyze-config.interface';
import { IAssetTile, IAssetTileResult } from './i-asset-tile.interface';

export interface AIProcess {
  ai_model_name: string;
  asset: Asset;
  results$: Observable<IAssetROI[]>;
  progress$: Observable<number>;
}

@Injectable({
  providedIn: 'any',
})
export class AiAnalysisService {
  private _asset: Asset;
  private _activeProcessesB$ = new BehaviorSubject<AIProcess[]>([]);
  get activeProcesses$() {
    return this._activeProcessesB$.asObservable();
  }
  private _tiles: IAssetTile[];
  private _sectorProcessingB$ = new BehaviorSubject<boolean>(false);
  private _analyzedSectors: Array<{ x: number; y: number }> = [];
  private _prioritizationStatusB$ = new BehaviorSubject<'idle' | 'inprogress'>('idle');
  constructor(
    private _http: HttpClient,
    private _sampleAnalysisService: SampleAnalysisService,
    private _dataService: DataService,
    @Inject(AI_ROI_PROVIDER) private _aiRoiProvider: IRoiProvider,
    private _logger: LoggerService
  ) {}

  private _getMaxNativeZoom(width: number, height: number, tileSize: number): number {
    let level = 1;
    const max_dimension = Math.max(width, height);
    let dimension = max_dimension;
    while (dimension > tileSize) {
      dimension = max_dimension / Math.pow(2, level);
      level++;
    }
    return level;
  }

  private _getAssetTiles(asset: Asset): Promise<IAssetTile[]> {
    return this._http
      .get(asset.infoFile)
      .pipe(
        map((response) => {
          const tiles: IAssetTile[] = [];
          this._logger.debug('Received asset info file', response);
          const info = response;
          const maxNativeZoom = this._getMaxNativeZoom(info['width'], info['height'], info['tileSize']);
          if (maxNativeZoom !== info['maxNativeZoom']) {
            throw new Error([maxNativeZoom, info['maxNativeZoom']].toString());
          }
          for (let x = 0; x < Math.ceil(info['width'] / info['tileSize']); x++) {
            for (let y = 0; y < Math.ceil(info['height'] / info['tileSize']); y++) {
              tiles.push({
                url: `${asset.tiles}/${maxNativeZoom - 1}/${y}/${x}${info['extension'] || '.png'}`,
                name: `${asset.tilePath}/${maxNativeZoom - 1}/${y}/${x}${info['extension'] || '.png'}`,
                asset: asset.id,
                x: x,
                y: y,
                z: maxNativeZoom,
                tileSize: info['tileSize'],
              });
            }
          }
          return tiles;
        })
      )
      .toPromise();
  }

  private _analyzeTiles(
    tiles: IAssetTile[] = [],
    config: IAnalyzeConfig = defaultAnalyzeConfig,
    ordered: boolean = false
  ): Observable<IAssetTileResult> {
    if (!tiles || tiles.length === 0) return;
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    config = Object.assign(defaultAnalyzeConfig, config);
    const requests = tiles.map((tile) => {
      return this._http
        .post(environment.external_links.ai.analyze, Object.assign({}, config, { image: tile }), { headers: headers })
        .pipe(map((response) => JSON.parse(response['_body'])));
    });
    return ordered ? forkJoin(requests) : merge(...requests);
  }

  async requestModelAnalysis(model: RoiModel, asset?: Asset, useModel?: string): Promise<AIProcess> {
    if (asset === undefined) {
      asset = await this._sampleAnalysisService.selectedAsset$.pipe(take(1)).toPromise();
    }
    if (!asset) {
      throw new Error('No asset specified for AI analysis');
    }

    if (!model) {
      throw new Error('AI model not specified');
    }

    // TODO: handle no models
    const modelName = useModel ? model.ai_models.find((m) => m === useModel) : model.ai_models[0];
    if (!modelName) {
      throw new Error(`No AI model named ${modelName}`);
    }

    const process = this._activeProcessesB$.value.find((p) => p.ai_model_name === modelName);
    if (process) {
      console.warn(`Found existing process for model ${model}`);
      return process;
    } else {
      this._logger.debug(`Getting AI results for ${model}...`);
      if (!this._tiles && asset) {
        await this.setAssetForAnalysis(asset);
      }
      this._tiles = await this._getAssetTiles(asset);
      const numPatches = this._tiles.length;
      const results$ = this._aiRoiProvider.analyzeTiles(this._tiles, model);

      let totalProgress = 0;

      const removeOverlappingROIs = false;
      const conflictingROIs: IAssetROI[] = [];

      const newAIprocess: AIProcess = {
        ai_model_name: modelName,
        asset,
        results$: results$.pipe(
          map((result) => result.rois),
          tap((rois) =>
            rois.forEach((roi) => {
              roi.assetId = this._asset?.id;
              roi.models = [model];
            })
          ),
          map((rois) => {
            if (!removeOverlappingROIs) return rois;
            const tileSize = this._tiles[0].tileSize;
            conflictingROIs.push(
              ...rois.filter(
                (roi) =>
                  Math.floor(roi.x / tileSize) !== Math.floor((roi.x + roi.w) / tileSize) ||
                  Math.floor(roi.y / tileSize) !== Math.floor((roi.y + roi.h) / tileSize)
              )
            );
            return nonMaxSuppression(rois.filter((roi) => conflictingROIs.indexOf(roi) === -1)).results;
          }),
          removeOverlappingROIs ? endWith(nonMaxSuppression(conflictingROIs).results) : tap(console.log)
        ),
        progress$: results$.pipe(
          startWith([]),
          map((_) => (totalProgress += 100 / (numPatches + 1 + (removeOverlappingROIs ? 1 : 0)))),
          takeWhile((c) => c <= 100)
        ),
      };
      newAIprocess.progress$.subscribe({
        complete: () => {
          this._logger.debug(`AI process completed`);
          this._activeProcessesB$.next(this._activeProcessesB$.value.filter((p) => p !== newAIprocess));
        },
      });

      this._activeProcessesB$.next([...this._activeProcessesB$.value, newAIprocess]);
      return newAIprocess;
    }
  }

  async setAssetForAnalysis(asset: Asset): Promise<void> {
    this._logger.debug(`Selecting asset ${asset?.id} for AI analysis`);
    this._resetAnalyzedSectors();
    if (asset && (this._asset === undefined || this._asset.id !== asset.id)) {
      this._activeProcessesB$.next([]);
      this._asset = asset;
    }
  }

  // "Live" analysis prototype

  get sectorProcessing$() {
    return this._sectorProcessingB$.asObservable().pipe(distinctUntilChanged());
  }
  private _resetAnalyzedSectors() {
    this._analyzedSectors = [];
    this._sectorProcessingB$.next(false);
  }

  analyzeSector({ x, y, data, model }: { x: number; y: number; data: string; model: string }) {
    this._analyzedSectors.push({ x, y });
    this._sectorProcessingB$.next(true);
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this._http
      .post(
        environment.external_links.ai.analyze,
        // Object.assign({model: this.modelName}, defaultAnalyzeConfig,{image:tile}),
        {
          threshold: 0.1,
          instances: [
            {
              image: data,
            },
          ],
          model: model || 'malaria-256-inception', // TODO: replace placeholder
        },
        { headers: headers }
      )
      .pipe(
        tap(this._logger.debug),
        map((response) => {
          const rois: IAssetROIWithModels[] = [];
          response['predictions'].forEach((tilePredictions) =>
            rois.push(
              ...(tilePredictions as Array<any>).map((r) => {
                return {
                  x: r.x,
                  y: r.y,
                  w: r.w,
                  h: r.h,
                  assetId: this._asset?.id,
                };
              })
            )
          );
          return rois;
        })
      );
  }

  isSectorAnalyzed(x, y) {
    return this._analyzedSectors.findIndex((sector) => sector.x === x && sector.y === y) !== -1;
  }

  get prioritizationStatus$() {
    return this._prioritizationStatusB$.asObservable();
  }
  prioritizeSample(sample: Sample): Observable<TPrioritizePayload> {
    return new Observable<TPrioritizePayload>((observer) => {
      this._prioritizationStatusB$.next('inprogress');
      observer.next({ status: 'started' });
      this._mockAIPriorityImplementation(sample).then((results) => {
        observer.next(results);
        observer.complete();
        this._prioritizationStatusB$.next('idle');
      });
    });
  }

  private async _mockAIPriorityImplementation(sample: Sample): Promise<TPrioritizePayload> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const mockOrderResponse = Array.from(sample.assets.keys())
          .map((index) => ({ index, priority: Math.random() * 100 }))
          .sort((a, b) => a.priority - b.priority)
          .map((i) => i.index);
        resolve({ status: 'inprogress', order: mockOrderResponse });
      }, 2000)
    );
  }

  // private async _cloudAIPriorityImplementation(sample: Sample): Promise<TPrioritizePayload> {
  //   const response = await CloudFunctions.PrioritizeAI(sample);

  //   this._logger.debug(`UUID: ${response.workflow_uuid}`);

  //   if (!sample) return;
  //   const q = new Query(Analysis).equalTo('sample', sample.toPointer()).equalTo('workflow_uuid', response.workflow_uuid);

  //   const assetROIs: { asset: Asset; rois: IAssetROIWithModels[] }[] = await new Promise(async (resolve, reject) => {
  //     const responses = sample.assets.map((asset) => ({
  //       asset,
  //       rois: undefined,
  //     }));
  //     const s = await this._dataService.subscribe(q, {
  //       close: () => this._logger.debug(`updated`),
  //     });
  //     s.on('create', (value: Analysis) => {
  //       const regions = value.data.rois['parasite']
  //         .filter((roi) => roi.score > 0.7)
  //         .map((roi) => ({ x: roi.x, y: roi.y, w: roi.w, h: roi.h, assetId: this._asset.id }));

  //       responses.find((r) => r.asset.id === value['asset'].id).rois = value.data.rois['parasite'];
  //       if (!responses.some((r) => r.rois === undefined)) {
  //         s.unsubscribe();
  //         resolve(responses);
  //       }
  //     });
  //   });
  //   const order = assetROIs.map((result) => result.rois.length).sort((el1, el2) => el1 - el2);
  //   this._logger.debug(`Prioritizing ended`, order);
  //   return Promise.resolve({ status: 'inprogress', order, assetROIs });
  // }
}

interface TPrioritizePayload {
  status: 'started' | 'inprogress' | 'complete';
  order?: number[];
  assetROIs?: {
    asset: Asset;
    rois: IAssetROIWithModels[];
  }[];
}
