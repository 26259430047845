<div class="main-container">
  <div class="title">
    <span class="breadcrumbs"
      ><a href="protocols/caseTypes">{{ 'core.casetypes' | translate }}</a> / {{ (caseType$ | async)?.name }}</span
    >
    <button class="btn btn-primary" [routerLink]="['/protocols/caseTypes', 'edit', (caseType$ | async)?.id]">
      <i class="ri-pencil-line"></i>
      {{ 'button.edit' | translate }}
    </button>
  </div>

  <ng-container *ngIf="caseType$ | async as caseType; else loading">
    <mat-expansion-panel expanded class="custom-mat-expansion-panel">
      <mat-expansion-panel-header collapsedHeight="70px" expandedHeight="70px">
        <mat-panel-title>
          <i class="ri-briefcase-4-line text-primary"></i>
          {{ caseType?.name | uppercase }}
        </mat-panel-title>
        <mat-panel-description>
          <!-- Type your name and age -->
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="card">
        <h4>Case type description</h4>
        {{ caseType?.description || 'No description' }}
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel expanded class="custom-mat-expansion-panel">
      <mat-expansion-panel-header collapsedHeight="70px" expandedHeight="70px">
        <mat-panel-title [routerLink]="['/protocols/caseTypes', 'edit', caseType?.id]" fragment="fields">
          <i class="ri-price-tag-3-fill text-primary"></i>
          {{ 'title.casetype_name' | translate | uppercase }}
        </mat-panel-title>
        <mat-panel-description>
          <!-- Type your name and age -->
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="card">
        <h4>Case type fields</h4>
        <div *ngFor="let field of caseType?.fields" class="flex-row align-items-center">
          <ng-container [ngSwitch]="field.type">
            <i *ngSwitchCase="'case_name'" class="ri-price-tag-3-fill"></i>
            <i *ngSwitchCase="'boolean'" class="ri-checkbox-line"></i>
            <i *ngSwitchCase="'date'" class="ri-calendar-2-line"></i>
            <i *ngSwitchCase="'textedit'" class="ri-pencil-line"></i>
            <i *ngSwitchCase="'number'" class="ri-pencil-line"></i>
            <i *ngSwitchCase="'switcher'" class="ri-list-unordered"></i>
            <i *ngSwitchCase="'selection'" class="ri-list-unordered"></i>
            <i *ngSwitchCase="'location'" class="ri-focus-2-line"></i>
            <i *ngSwitchCase="'label'" class="ri-price-tag-3-fill"></i>
          </ng-container>
          {{ field.displayName }}
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel expanded class="custom-mat-expansion-panel">
      <mat-expansion-panel-header collapsedHeight="70px" expandedHeight="70px">
        <mat-panel-title [routerLink]="['/protocols/caseTypes', 'edit', caseType?.id]" fragment="methodTypes">
          <i class="ri-camera-2-line text-primary"></i>
          {{ 'title.case_type_method_types' | translate | uppercase }}
        </mat-panel-title>
        <mat-panel-description>
          <!-- Type your name and age -->
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="card">
        <section *ngFor="let methodType of caseType?.methodTypes">
          <h4>{{ methodType.name }}</h4>
          <h5>Asset type: {{ methodType.assetType.name }}</h5>
          <ul>
            <li *ngFor="let deviceType of methodType.assetType.deviceTypes" class="flex-row align-items-center">
              <div>
                <i class="ri-device-line"></i>
                {{ deviceType.name }}
              </div>
            </li>
          </ul>
          <h5>Analysis types:</h5>
          <ul>
            <li *ngFor="let analysisType of methodType.analysisTypes" class="block">
              <div>
                {{ analysisType.displayName }}
                <ul>
                  <li *ngFor="let task of analysisType.tasks">
                    {{ task.displayName }}
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel expanded class="custom-mat-expansion-panel">
      <mat-expansion-panel-header collapsedHeight="70px" expandedHeight="70px">
        <mat-panel-title [routerLink]="['/protocols/caseTypes', 'edit', caseType?.id]" fragment="workspaces">
          <i class="ri-file-copy-2-linetext-primary"></i>
          {{ 'title.assigned_workspaces' | translate | uppercase }}
        </mat-panel-title>
        <mat-panel-description>
          <!-- Type your name and age -->
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="card">
        <div *ngFor="let workspace of workspaces$ | async" class="flex-row align-items-center">
          <i class="ri-file-copy-2-line"></i> {{ workspace.name }}
        </div>
      </div>
    </mat-expansion-panel>
  </ng-container>

  <ng-template #loading> Loading... </ng-template>
</div>
