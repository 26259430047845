<ng-container *ngIf="config">
  <ng-container
    *ngIf="{
      config: config,
      filters: filters$ | async,
      visibleColumns: visibleColumns$ | async,
      loading: dataSource?.loading$ | async,
      mobile: mobile$ | async,
      length: dataSource?.numPages$ | async
    } as state"
  >
    <ng-container>
      <div class="table-toolbar flex-row align-items-center p-2">
        <ts-table-decryption
          *ngIf="config.encryption"
          [isAdmin]="isAdmin"
          [dataSource]="dataSource"
          [isEncrypted]="isEncrypted"
          (decryptCases)="decryptCases.emit($event)"
        ></ts-table-decryption>
        <ts-table-filters *ngIf="config.showFilters"></ts-table-filters>
        <button
          *ngIf="config.showRefreshButton"
          mat-icon-button
          class="text-primary"
          (click)="dataSource.fetch()"
          matTooltip="Refresh"
        >
          <i class="ri-refresh-line"></i>
        </button>
        <ts-data-table-search
          [searchTerm]="{ term: 'name', displayName: 'name' }"
          class="flex-grow-1"
          data-cy="table-search"
          *ngIf="config.showSearch"
        ></ts-data-table-search>
        <mat-paginator
          *ngIf="config.showPaginator"
          [pageSizeOptions]="pageSizes"
          [pageSize]="dataSource?.pageSize$ | async"
          [pageIndex]="dataSource?.pageIndex$ | async"
          [hidePageSize]="state.mobile"
          (page)="updatePagination($event)"
          [length]="state.length"
        ></mat-paginator>
      </div>
    </ng-container>

    <ng-content select="[dataTableToolbar]"></ng-content>

    <ng-container *ngIf="activeFilters$ | async as filters">
      <div class="pinned-toolbar p-2" *ngIf="filters.length" @collapse>
        <ts-table-filter-badge
          [filter]="filter"
          *ngFor="let filter of filters"
          (remove)="removeFilter(filter)"
        ></ts-table-filter-badge>
      </div>
    </ng-container>

    <div class="table-container">
      <table
        mat-table
        #table
        [dataSource]="dataSource"
        matSort
        width="100%"
        class="spotlab-table"
        (matSortChange)="updateSort($event)"
      >
        <ng-container matColumnDef="__state">
          <th mat-header-cell *matHeaderCellDef class="state-header-cell"></th>
          <td
            mat-cell
            style="padding: 0"
            *matCellDef="let row"
            height="56"
            class="align-self-stretch"
          >
            <span
              class="status-cell-container align-self-stretch h-100"
              *ngIf="config.statusClass(row) as statusClass"
            >
              <div class="status-indicator-container {{ statusClass }}">
                <div class="status-indicator"></div>
              </div>
            </span>
          </td>
        </ng-container>

        <ng-container
          matColumnDef="{{ column.name }}"
          *ngFor="let column of config.columns; let last = last"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [disabled]="!column.sortable"
            class="align-items-center"
          >
            <ng-container
              *ngIf="
                getHeaderTemplate(column.name, state.mobile) as headerTpl;
                else defaultHeaderTemplate
              "
              ><ng-container *ngTemplateOutlet="headerTpl"></ng-container>
            </ng-container>
            <ng-template #defaultHeaderTemplate>
              <span class="spotlab-table__header">{{
                column.displayName | translate | uppercase
              }}</span>
            </ng-template>
            <ng-container *ngIf="column.filters"
              ><button
                mat-icon-button
                [matMenuTriggerFor]="filterMenu"
                [matMenuTriggerRestoreFocus]="false"
                (click)="$event.stopPropagation()"
                data-cy="data-table-column-filters-menu-trigger"
              >
                <i
                  class="ri-filter-3-fill"
                  [class.text-primary]="columnHasActiveFilters(column) | async"
                ></i>
              </button>
              <mat-menu #filterMenu>
                <ng-container *ngFor="let filter of state.filters">
                  <ng-container *ngIf="filter.column === column.name">
                    <ng-container
                      *ngIf="
                        filter.constraints.containedIn?.length;
                        else simpleFilter
                      "
                    >
                      <section class="p-2">
                        {{ filter.displayName | uppercase }}
                        <div class="flex-column">
                          <mat-checkbox
                            *ngFor="
                              let option of filter.constraints.containedIn
                            "
                            [checked]="filter.active && option.active"
                            (change)="toggleFilterOption(filter, option)"
                          >
                            {{ option.displayName || option.value | translate }}
                          </mat-checkbox>
                        </div>
                      </section>
                    </ng-container>
                    <ng-template #simpleFilter>
                      <button
                        mat-menu-item
                        (click)="toggleFilter(filter)"
                        [class.bg-primary]="filter.active"
                        [class.text-white]="filter.active"
                        [role]="'menuitemcheckbox'"
                      >
                        {{ filter.displayName }}
                      </button>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </mat-menu>
            </ng-container>
          </th>
          <ng-container *matCellDef="let row">
            <ng-container *ngIf="column.value(row) as readRowValue">
              <td
                mat-cell
                *ngIf="
                  isObservable(readRowValue)
                    ? (readRowValue | async)
                    : readRowValue as rowValue
                "
                [class.pinnable]="column.pinnable"
              >
                <ng-container>
                  <ng-container
                    *ngIf="getCellTemplate(column.name) || default as cellTpl"
                  >
                    <ng-container
                      *ngTemplateOutlet="
                        cellTpl;
                        context: { $implicit: rowValue, mobile: state.mobile }
                      "
                    ></ng-container>
                  </ng-container>
                </ng-container>
                <ng-template #default let-value>
                  <ng-container [ngSwitch]="column.type">
                    <ng-container *ngSwitchCase="'date'">
                      {{ value | date : "short" }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{ value }}
                    </ng-container>
                  </ng-container>
                </ng-template>
                <span
                  (click)="
                    column.pinnable
                      ? selectCell(column, rowValue, $event)
                      : null
                  "
                  class="pin-button"
                  role="button"
                  *ngIf="!state.mobile && column.pinnable"
                  [matTooltip]="
                    column.pinnable
                      ? ('tooltip.filter_cell_value' | translate)
                      : ''
                  "
                ></span>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container matColumnDef="__menu" stickyEnd width="26px">
          <th mat-header-cell *matHeaderCellDef>
            <button
              data-cy="data-table-columns-menu-trigger"
              mat-icon-button
              (click)="
                showColumnVisibilityOverlay = !showColumnVisibilityOverlay
              "
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin"
            >
              <i class="ri-layout-column-line"></i>
            </button>
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="trigger"
              [cdkConnectedOverlayOpen]="showColumnVisibilityOverlay"
              [cdkConnectedOverlayFlexibleDimensions]="true"
              [cdkConnectedOverlayHasBackdrop]="true"
              (overlayOutsideClick)="
                showColumnVisibilityOverlay = false; $event.preventDefault()
              "
            >
              <div class="flex-column columns-menu-overlay">
                <mat-checkbox
                  *ngFor="let column of config.columns"
                  [checked]="!column.hidden"
                  (change)="updateColumnVisibility(column, $event)"
                  class="text-capitalize"
                >
                  {{ column.displayName || column.name | translate }}
                </mat-checkbox>
              </div>
            </ng-template>
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            style="padding: 0px; text-align: center"
          >
            <ng-container
              *ngIf="getCellTemplate('__menu') as cellTpl; else defaultMenu"
            >
              <ng-container
                *ngTemplateOutlet="cellTpl; context: { $implicit: row }"
              ></ng-container>
            </ng-container>
            <ng-template #defaultMenu>
              <button
                mat-icon-button
                style="height: 100%; width: 20px"
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()"
              >
                <i class="ri-more-line"></i>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  (click)="removeItem.emit(row)"
                  data-cy="menu-remove"
                  class="flex-row align-items-center text-danger"
                >
                  <i class="ri-delete-bin-6-line"></i>
                  <a>{{ "core.remove" | translate | uppercase }}</a>
                </button>
                <button
                  mat-menu-item
                  (click)="migrateItem.emit(row)"
                  data-cy="menu-migrate"
                  class="flex-row align-items-center text-primary"
                >
                  <i class="ri-folder-transfer-line"></i>
                  <a>{{ "core.migrate" | translate | uppercase }}</a>
                </button>
                <button
                  mat-menu-item
                  (click)="copyItem.emit(row)"
                  data-cy="menu-migrate"
                  class="flex-row align-items-center text-primary"
                >
                  <i class="ri-file-copy-2-line"></i>
                  <a>{{ "core.copy" | translate | uppercase }}</a>
                </button>
              </mat-menu>
            </ng-template>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="state.visibleColumns"></tr>
        <tr
          height="56"
          mat-row
          *matRowDef="let row; columns: state.visibleColumns"
          [routerLink]="(config.url && config?.url(row)) || []"
          [queryParams]="{
            pageIndex: dataSource?.pageIndex$ | async,
            pageSize: dataSource?.pageSize$ | async
          }"
          class="{{ config.rowClass ? config.rowClass(row) : undefined }}"
          (click)="selectItem.emit(row)"
          data-cy="case-row"
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell align-center" colspan="99999">
            <ng-container *ngIf="state.loading; else noData">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container>
            <ng-template #noData>
              <ng-container
                *ngIf="
                  state.config.encryption && searchTerm && activeFilters$
                    | async
                "
              >
                <div>
                  <span
                    data-cy="no-results"
                    style="color: #f7931d; font-weight: 700"
                    >{{
                      "placeholder.no_data_exact_term"
                        | translate : { term: searchTerm }
                    }}</span
                  >
                </div>
                <div>
                  <span
                    data-cy="use-exact-term"
                    style="color: #808284; font-weight: 300"
                    >{{
                      "label.encrypted_casename_use_exact_term" | translate
                    }}</span
                  >
                </div>
              </ng-container>
              <ng-template *ngIf="!state.config.encryption">
                <span data-cy="no-results">No data</span></ng-template
              >
            </ng-template>
          </td>
        </tr>
      </table>
    </div>

    <ng-content></ng-content>
  </ng-container>
</ng-container>
