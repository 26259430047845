import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgModule,
  Output,
  TrackByFunction,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DataTableModule } from '@shared/ui';
import { IOrganizationData, TelespotModule, USER_ORGANIZATIONS, userOrganizationsDataProvider } from '@telespot/web-core';
import { Organization } from '@telespot/sdk';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ts-organization-selector',
  templateUrl: './organization-selector.component.html',
  styleUrls: ['./organization-selector.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationSelectorComponent {
  constructor(@Inject(USER_ORGANIZATIONS) private userOrganizations, private _breakpoint: BreakpointObserver) {}

  @Input() currentUser;
  @Output() selectOrganization = new EventEmitter<Organization>();

  public readonly dataSource$: Observable<IOrganizationData[]> = this.userOrganizations;

  displayedColumns$: Observable<string[]> = this._breakpoint
    .observe([Breakpoints.XSmall, Breakpoints.HandsetPortrait])
    .pipe(
      map((match) =>
        match.matches
          ? ['logo', 'organization_name', 'new_cases']
          : ['logo', 'organization_name', 'new_cases', 'last_case', 'action']
      )
    );

  // TODO:
  public dataTableConfig: unknown;
  public dataTableSource: unknown;

  select(item: IOrganizationData): void {
    this.selectOrganization.emit(item.organization);
  }

  trackByOrg: TrackByFunction<IOrganizationData> = (index: number, item: IOrganizationData) => item.organization.id;
}

@NgModule({
  declarations: [OrganizationSelectorComponent],
  imports: [CommonModule, MatTableModule, RouterModule, TranslateModule, MatButtonModule, TelespotModule, DataTableModule],
  exports: [OrganizationSelectorComponent],
  providers: [userOrganizationsDataProvider],
})
export class OrganizationSelectorModule {}
