export enum SessionStatus {
  ACTIVE = "active",
  EXPIRED = "expired",
  ANY = "any",
}

export interface SessionItem {
  userId: string;
  token: string;
  sessionId: string;
  isExpired: boolean;
  device: string;
  /**
   * Number of days since creation
   */
  created: number;
  /**
   * Number of days until expiration
   */
  expiresIn: number;
  /**
   * True if its the currently used session
   */
  isCurrent: boolean;
}
