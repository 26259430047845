<ng-container *ngFor="let methodGroup of methodGroups">
  <ng-container *ngFor="let samplePacket of methodGroup.samples">
    <div class="method-cards" *ngIf="currentContext(samplePacket)">
      <!-- Only if fav assets -->
      <div class="card" *ngIf="context === scenario.asset">
        <div class="card-body sample-card">
          <mat-card>
            <img
              mat-card-image
              [src]="
                (samplePacket.selectedAsset?.thumbnail | preprocess | async) ||
                (samplePacket.sample.referenceAsset?.thumbnail | preprocess | async) ||
                '../../../../assets/media/image_placeholder.svg'
              "
              alt=""
              style="object-fit: cover; max-height: 256px"
            />
            <mat-card-footer style="padding: 0px 12px 4px 12px">
              <span>{{ samplePacket.sample.name }}</span>
            </mat-card-footer>
          </mat-card>
          <div class="assets-grid">
            <div
              *ngFor="let asset of samplePacket.assetResults; let assetIndex = index"
              class="relevantAsset"
              data-bs-toggle="tooltip"
              title="{{ 'tooltip.view_asset_i_results' | translate: assetIndex + 1 }}"
              (click)="toggleVisibleAsset(samplePacket, asset.asset)"
              [class.selectedAsset]="samplePacket.selectedAsset === asset.asset"
            ></div>
          </div>
        </div>
      </div>
      <!-- Finishes fav assets grid -->

      <div class="card" style="justify-items: start">
        <div
          class="card-header"
          *ngIf="{ index: samplePacket.selectedAssetIndex + 1, name: samplePacket.sample.name } as indexParams"
        >
          <ng-container *ngIf="context === scenario.counter; else noCounter">
            <h4>
              {{ 'label.total_rois_count' | translate | uppercase }}
            </h4>
          </ng-container>
          <ng-template #noCounter>
            <h4>
              {{ (context === scenario.asset ? 'label.asset_i_results' : 'label.sample_results') | translate: indexParams }}
            </h4>
          </ng-template>
        </div>
        <ng-container *ngFor="let sampleGroup of filterSampleGroup(samplePacket); let id = index">
          <hr style="width: 50%; margin: 0px auto" *ngIf="hasAssetTasks(sampleGroup.analysisType) && id > 0" />
          <mat-expansion-panel
            *ngIf="hasAssetTasks(sampleGroup.analysisType)"
            [expanded]="true"
            class="custom-mat-expansion-panel"
            style="margin: 0; padding: 0px 16px"
          >
            <mat-expansion-panel-header style="padding: 0px">
              <mat-panel-title style="width: 30%" class="text-primary">
                <!-- <i class="fa" [ngClass]="{'ri-film-line':sampleGroup.methodType.assetType.type==='video','ri-image-line':sampleGroup.methodType.assetType.type==='image'}"></i> -->
                <h4>
                  {{ sampleGroup.analysisType.name | uppercase }}
                </h4>
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
              <div *ngFor="let task of filterContextTasks(sampleGroup.analysisType.tasks)">
                <ng-container *ngFor="let analysis of sampleGroup.userResults; let taskResultIndex = index">
                  <div class="text-primary" *ngIf="taskResultIndex === 0">
                    {{ task.displayName | uppercase }}
                  </div>
                  <div *ngIf="analysis.data[task.name] as data">
                    <div class="flex-row" style="justify-content: left; align-items: center">
                      <div class="flex-column align-items-start">
                        <span style="width: 180px">
                          <i class="ri-user-line"></i>
                          {{ analysis.createdBy?.username }}
                        </span>
                        <span class="badge badge-pill" style="margin: 6px 0px" *ngIf="context === scenario.counter">
                          {{ 'label.total' | translate | uppercase }}: {{ data['total'].counter }} ({{ data['total'].perc }}
                          %)
                        </span>
                      </div>
                      <p style="margin: 0">
                        <ng-container *ngIf="task.type === 'selection'; else noSelection">
                          <ng-container *ngIf="task.multiple || task.roiSelection; else notMultiple">
                            <ng-container *ngIf="context === scenario.counter; else sampleAsset">
                              <span
                                *ngFor="let option of task.allOptions"
                                class="badge badge-pill"
                                style="margin: 0px 6px"
                                [class.badge-primary]="data[option.name]"
                              >
                                {{ option.displayName }}: {{ data[option.name].counter }} ({{ data[option.name].perc }} %)
                              </span>
                            </ng-container>
                            <ng-template #sampleAsset>
                              <span
                                *ngFor="let option of task.allOptions"
                                class="badge badge-pill"
                                style="margin: 0px 6px"
                                [class.badge-primary]="data[option.name]?.length > 0"
                              >
                                {{ option.displayName }}: {{ data[option.name]?.length }}
                              </span>
                            </ng-template>
                          </ng-container>
                          <ng-template #notMultiple>
                            <span
                              *ngFor="let option of task.allOptions"
                              class="badge badge-pill"
                              style="margin: 0px 6px"
                              [class.badge-secondary]="data[option.name]"
                            >
                              {{ option.displayName }}
                            </span>
                          </ng-template>
                        </ng-container>
                        <ng-template #noSelection>
                          <ng-container *ngIf="task.type === 'boolean'; else raw">
                            {{ (data ? 'core.yes' : 'core.no') | translate }}
                          </ng-container>
                          <ng-template #raw>
                            {{ data }}
                          </ng-template>
                        </ng-template>
                      </p>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
