import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TelespotModule } from "@telespot/web-core"

import * as fromAuth from './state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TelespotModule,
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducers),
    EffectsModule.forFeature([fromAuth.AuthEffects])
  ]
})
export class TelespotAuthDataAccessModule { }
