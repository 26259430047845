import { createAction, props } from "@ngrx/store";
import { Organization, Workspace } from "@telespot/sdk";

export const clearWorkspacesState = createAction(
  "[Workspaces] Clear Workspaces State"
);

export const workspacesActionError = createAction(
  "[Workspaces] Action Error",
  props<{ error: string }>()
);

export const setCurrOrganization = createAction(
  "[Workspaces] Set Current Organization",
  props<{ organization: Organization }>()
);

export const workspacesChangePage = createAction(
  "[Workspaces] Change Page",
  props<{ pageIndex: number; pageSize: number }>()
);

export const workspacesListed = createAction(
  "[Workspaces] Workspaces Listed",
  props<{ workspacesList: Workspace[] }>()
);

export const workspacesCounted = createAction(
  "[Workspaces] Workspaces Counted",
  props<{ count: number }>()
);

export const applyWorkspacesFilter = createAction(
  "[Workspaces] Apply Workspaces Filter",
  props<{ filter: any }>()
);

export const removeWorkspacesFilter = createAction(
  "[Workspaces] Remove Workspaces Filter",
  props<{ filter: any }>()
);
