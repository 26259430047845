<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  [matTooltip]="'tooltip.change_language' | translate"
  data-cy="language-selector"
>
  <img class="flag" [src]="selectedLanguage?.flag" alt="{{ selectedLanguage?.label | translate }} flag" />
  <span *ngIf="showLabel">
    {{ selectedLanguage.label | translate | uppercase }}
  </span>
</button>
<mat-menu #menu>
  <button mat-menu-item (click)="selectLanguage(languageItem)" *ngFor="let languageItem of languages">
    <img class="flag" [src]="languageItem.flag" alt="{{ languageItem?.label }} flag" />
    {{ languageItem?.label | translate | uppercase }}
  </button>
</mat-menu>
