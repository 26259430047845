<div class="flex-column align-items-center">
  <h3>{{ 'label.export_dialog_title' | translate }}</h3>
  <div class="flex-column align-items-center">
    <button class="btn btn-outline-primary" (click)="selectCSV()">
      <i class="ri-file-excel-line"></i>
      CSV
    </button>
    <button class="btn btn-outline-primary" (click)="selectPDF()">
      <i class="ri-file-pdf-line"></i>
      PDF
    </button>
    <a role="button" [routerLink]="[]" (click)="cancel()">
      {{ 'button.cancel' | translate | uppercase }}
    </a>
  </div>
</div>
