import { Store } from "@ngrx/store";
import { Sample } from "@telespot/sdk";
import { filter } from "rxjs/operators";
import {
  markItemAsViewed,
  numAssets,
  sampleReady,
  viewedAssets,
} from "../+state";

export class SampleAnalysisProgressTracker {
  public scannedAssets: string[];
  public numAssets: number;
  public activeSample: Sample;

  constructor(private store: Store) {
    this.store.select(numAssets).subscribe((n) => (this.numAssets = n));
    this.store
      .select(sampleReady)
      .pipe(filter((s) => s !== undefined))
      .subscribe((sample) => {
        this.activeSample = sample;
        this.loadProgress(sample);
      });
    this.store
      .select(viewedAssets)
      .subscribe((viewedIds) => (this.scannedAssets = viewedIds));
  }

  get percentage(): number {
    return Math.floor((this.scannedAssets.length / this.numAssets) * 100) ?? 0;
  }

  /**
   * Loads current sample progress state from localStorage
   *
   * @memberof SampleAnalysisProgressTracker
   */
  loadProgress(sample: Sample) {
    const scannedInfo = JSON.parse(
      localStorage.getItem(`sample_${sample ? sample.id : undefined}`)
    ) || {
      scannedAssets: [],
    };
    scannedInfo.scannedAssets.map((id) =>
      this.store.dispatch(markItemAsViewed({ assetId: id }))
    );
  }

  /**
   * Saves current sample progress state to localStorage
   *
   * @memberof SampleAnalysisProgressTracker
   */
  saveProgress() {
    localStorage.setItem(
      `sample_${this.activeSample.id}`,
      JSON.stringify({ scannedAssets: this.scannedAssets })
    );
  }
}
