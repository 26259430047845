import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AuthService, DataService } from '@telespot/web-core';
import { Device, Query } from '@telespot/sdk';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ts-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit, OnDestroy {
  private _destroyS$ = new Subject<void>();
  visibleColumns$: Observable<string[]> = of(['device', 'version', 'name', 'serialNumber', 'purchaseDate']);
  dataSource: MatTableDataSource<Device> = new MatTableDataSource([]);
  loaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private _route: ActivatedRoute, private _dataService: DataService, private _authService: AuthService) {}

  ngOnInit() {
    if (!this._route.parent) return;
    this._route.parent.data.pipe(takeUntil(this._destroyS$)).subscribe((data) => {
      const organization = data.organization;
      this.loaded.next(false);
      if (organization) {
        this._dataService
          .find(new Query(Device).equalTo('organization', organization).include(['deviceType']))
          .then((devices) => {
            this.dataSource.data = devices;
            this.loaded.next(true);
          });
      }
    });
  }

  ngOnDestroy() {
    this._destroyS$.next();
  }
}
