import { environment } from "@telespot/shared/environment";
import { Recorder, RecordEvent, Variables } from "../models/recorder";

import smartlookClient from 'smartlook-client'

export class Smartlook implements Recorder {

  constructor() {
    if (environment.recorder.enable) { smartlookClient.init(environment.recorder.apiKey); }
  }

  public consent(allow: boolean = false) {
    // TODO: describe in cookie consent
    smartlookClient.consentAPI(allow ? "API concent granted" : false);
    smartlookClient.consentIP(allow ? "IP concent granted" : false);
    smartlookClient.consentForms(allow ? "Forms concent granted" : false);

    if (!allow) smartlookClient.anonymize();
  }

  public identify(uid: string, vars: Variables) {
    smartlookClient.identify(uid, vars as any);
  }

  public sendCustomEvent(vars: RecordEvent) {
    smartlookClient.track(vars.topic, vars as any);
  }

  public stop(): void {
    smartlookClient.pause();
  }

  public start(): void {
    smartlookClient.resume();
  }

  public disable(): void {
    smartlookClient.disable();
  }
}
