<div class="slider-wrapper">
  <button
    (click)="decreaseValue()"
    class="slider-button ri-zoom-out-line"
  ></button>
  <mat-slider
    [min]="minValue"
    [max]="maxValue"
    [step]="stepValue"
    [(ngModel)]="value"
    (input)="onSliderChange($event)"
    tickInterval="1"
  ></mat-slider>
  <button
    (click)="increaseValue()"
    class="slider-button ri-zoom-in-line"
  ></button>
</div>
