import { Inject, Injectable, OnDestroy, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { CURRENT_USER, DataService, FeaturesService } from "@telespot/web-core";
import {
  AnalysisState,
  Query,
  Sample,
  SecondOpinion,
  User,
} from "@telespot/sdk";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import { from, Observable, Subject } from "rxjs";
import { map, shareReplay, switchMap, takeUntil } from "rxjs/operators";
import { IAnalystData } from "../../+state/rois.state";

import { selectActiveSample } from "../../+state";
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: "root",
})
export class AnalysisReviewService implements OnDestroy {
  private _currentUser: User;
  private _destroy$ = new Subject<void>();
  readonly availableSecondOpinions$: Observable<IAnalystData[]> = this._store
    .select(selectActiveSample)
    .pipe(
      switchMap((sample) =>
        from(
          this.dataService.find(
            new Query(SecondOpinion)
              .equalTo("originalSample", sample)
              .equalTo(
                "createdBy",
                User.createWithoutData(this._currentUser?.id)
              )
              .include(["sample", "analysisTypes"])
          )
        )
      ),
      switchMap((secondOpinions) =>
        from(
          this.dataService.find(
            new Query(AnalysisState)
              .containedIn(
                "sample",
                secondOpinions.map((secondOpinion) => secondOpinion.sample)
              )
              .include(["user", "sample"])
          )
        ).pipe(
          map((states) =>
            states.map((analysisState) => ({
              analysisState,
              selected: false,
              secondOpinion: secondOpinions.find(
                (sOp) => sOp.sample.id === analysisState.sample.id
              ),
            }))
          )
        )
      ),
      shareReplay(1)
    );

  public navigateToUserAnalysis(isOwn: boolean, state: IAnalystData): void {
    this._router.navigate([], {
      queryParams: { historyId: isOwn ? undefined : state.analysisState.id },
      queryParamsHandling: "merge",
    });
  }

  public getUserAnalysisState(sample: Sample) {
    const analysisStateQuery = new Query(AnalysisState)
      .equalTo("sample", sample)
      .equalTo("user", this._currentUser);
    return from(
      this.dataService
        .first(analysisStateQuery)
        .then((analysisState) => analysisState.id)
    );
  }
  public getAnalysisStatesFromSample(sample: Sample) {
    let analysisStateQuery = new Query(AnalysisState);
    if (!this.featureService.canUse("analysisReview")) {
      throw new Error(`Analysis review feature is not available`);
    }
    if (this._currentUser.roleNames.includes("analystmanager")) {
      if (this.featureService.canUse("analysisReview", "manager_review")) {
        // Analystmanagers can view everyone's analysis
        analysisStateQuery
          .equalTo("sample", sample)
          .include(["user", "algorithm"]);
      } else {
        // Analystmanagers can only view own analysis
        analysisStateQuery
          .equalTo("sample", sample)
          .equalTo("user", this._currentUser)
          .include(["user", "algorithm"]);
      }
    } else {
      // Non-analystmanagers can view own and reference analysis only
      if (this.featureService.canUse("analysisReview", "reference_only")) {
        analysisStateQuery = Query.or(
          ...[
            new Query(AnalysisState)
              .equalTo("sample", sample)
              .equalTo("user", this._currentUser),
            new Query(AnalysisState)
              .equalTo("sample", sample)
              .exists("data.reference"),
          ]
        ).include(["user", "algorithm"]);
      } else {
        // Non-analystmanagers can view everyone's analysis
        analysisStateQuery
          .equalTo("sample", sample)
          .include(["user", "algorithm"]);
      }
    }
    return from(
      this.dataService.find(analysisStateQuery).then((analysisState) => ({
        analysisStates: analysisState,
        currentUserId: this._currentUser?.id,
      }))
    );
  }

  public async markAsReferenceAnalysis(
    analysisState: AnalysisState
  ): Promise<void> {
    if (!analysisState.sample) {
      throw new Error(
        `Unable to set object as reference analysis. Currently only [sample] analysisStates are supported. Make sure the input analysisState.sample is defined.`
      );
    }
    analysisState.data = { ...analysisState.data, reference: true };
    await this.dataService.save(analysisState);
  }

  public async unmarkAsReferenceAnalysis(
    analysisState: AnalysisState
  ): Promise<void> {
    if (!analysisState) return;
    analysisState.data = { ...analysisState.data, reference: undefined };
    await this.dataService.save(analysisState);
  }

  constructor(
    private dataService: DataService,
    private featureService: FeaturesService,
    private _router: Router,
    private _store: Store,
    @Optional() private loggerService: LoggerService,
    @Inject(CURRENT_USER) private currentUser$: Observable<User>
  ) {
    this.currentUser$.pipe(takeUntil(this._destroy$)).subscribe((user) => {
      this._currentUser = user;
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }
}
