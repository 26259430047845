import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
} from "@angular/core";
import { UserAvatarModule } from "@shared/ui";
import { User } from "@telespot/sdk";

@Component({
  selector: "ts-cell-created-by",
  template: ` <div
    style="line-height: 32px"
    class="flex-row align-items-center justify-content-start"
    [class.justify-content-center]="!showDate"
  >
    <ts-user-avatar [user]="createdBy.user"></ts-user-avatar>
    <span *ngIf="showDate" style="margin-left: 10px">{{
      createdBy.date | date : "dd/MM/yyyy hh:mm a"
    }}</span>
  </div>`,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatedByCellComponent {
  @Input() createdBy: {
    user: User;
    date: Date;
  };
  @Input() showDate = true;
}

@NgModule({
  imports: [CommonModule, UserAvatarModule],
  declarations: [CreatedByCellComponent],
  exports: [CreatedByCellComponent],
})
export class CreatedByCellModule {}
