import { Object as ParseObject } from 'parse';

/**
 * Represents an AI model that may be provided to the AI endpoints.
 *
 * @export
 * @class AIModel
 * @extends {ParseObject}
 */
export class AIModel extends ParseObject {
  static className = 'AIModel';

  constructor(name: string, modelName: string) {
    super(AIModel.className);
    this.set('name', name);
    this.set('modelName', modelName);
  }

  /**
   * Descriptive name for the model
   *
   * @readonly
   * @type {string}
   * @memberof AIModel
   */
  get name(): string {
    return this.get('name');
  }

  /**
   * Model name provided to the endpoint
   *
   * @readonly
   * @type {string}
   * @memberof AIModel
   */
  get modelName(): string {
    return this.get('modelName');
  }
}
