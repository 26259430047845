import { Injectable, Optional } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { RecorderService } from '@telespot/shared/session-recorder';
import { CookieService } from 'ngx-cookie-service';

import { cookiesData, ICookiesGroup } from '../config';

const COOKIES_DURATION_DAYS = 30;
@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  private _cookieGroups: ICookiesGroup[];

  getEnabled(_key: string): boolean {
    return JSON.parse(localStorage.getItem(_key));
  }
  setFullstoryEnabled(accepted: boolean) {
    this.recorderService.setConsent(accepted);
  }
  setGoogleAnalyticsEnabled(accepted: boolean) {
    this.fireAnalytics?.setAnalyticsCollectionEnabled(accepted);
  }

  hasUserPreferences(): boolean {
    const cookie = this.cookies.get('user_cookie_preferences_set');
    return !!cookie?.length;
  }

  saveUserPreferences(): void {
    this.cookies.set('user_cookie_preferences_set', 'true', COOKIES_DURATION_DAYS);
  }

  public get cookieGroups(): ICookiesGroup[] {
    return this._cookieGroups;
  }

  constructor(
    @Optional() private fireAnalytics: AngularFireAnalytics,
    private recorderService: RecorderService,
    private cookies: CookieService
  ) {
    this._cookieGroups = cookiesData.map((group) => ({
      ...group,
      items: group.items.map((item) => ({ ...item, accepted: this.getEnabled(item._key) ?? item.default })),
    }));
    this._cookieGroups.forEach(
      (cookieGroup) => (cookieGroup.allAccepted = cookieGroup.items.every((cookieItem) => cookieItem.accepted))
    );
  }
}
