import { Cloud } from "parse";

import { Affiliate, Algorithms, Organization, User } from "../api";
import { Asset } from "../api/asset/asset.model";
import { Case } from "../api/case/case.model";
import { AssetStateName } from "../api/data/asset-state-name";
import { StateName } from "../api/data/workflow-state";
import { ILicenseInfo } from "../api/license/i-license-info";
import { Report } from "../api/report/report.model";
import { Sample } from "../api/sample/sample.model";
import { aiState } from "../interfaces/ai-state.interface";
import { IInviteUserData } from "./models";
import { StepTask } from "../api/data/task-type";

/**
 * Class containing static wrapper functions for Telespot cloud functions
 *
 * @export
 * @class CloudFunctions
 */
export class CloudFunctions {
  /**
   * Sets the state of a specific object
   *
   * @static
   * @param {(Asset | Case | Report)} item
   * @param {(AssetStateName | StateName)} state
   * @returns
   * @memberof CloudFunctions
   */

  static async delete({ className, id }) {
    await Cloud.run("delete", {
      entity: className,
      id: id,
    });
  }
  static async copyFindingsFromSample(
    sampleId: string,
    currUserId: string,
    createdBy: Parse.Pointer
  ): Promise<{
    findingsInfo: {
      uuid: string;
      version: number;
      assetId: string;
      type: StepTask;
    }[];
    enableMosaicView: boolean;
  }> {
    return await Cloud.run("copyFindings", { sampleId, currUserId, createdBy });
  }

  static async checkIfMosaicIsEnabled(sampleId: string): Promise<{
    enableMosaicView: boolean;
  }> {
    return await Cloud.run("checkIfMosaic", { sampleId });
  }

  static async moveCases(workspaceId: string, caseId: string) {
    return await Cloud.run("moveCases", { workspaceId, caseId });
  }

  static async copyCases(workspaceId: string, caseId: string) {
    return await Cloud.run("copyCases", { workspaceId, caseId });
  }

  static async requestAIState(level: string, id: string): Promise<aiState> {
    return await Cloud.run("aiState", { level, id });
  }

  static async decryptCases(cases: string[], namesOnly: boolean) {
    const decryptedCasesJSON = await Cloud.run("decryptCases", {
      cases,
      namesOnly,
    });
    return decryptedCasesJSON.map((obj) => {
      return Case.fromJSON({
        ...obj,
        className: "Case",
      }) as Case;
    });
  }

  static GetRefStripData(requestRefStripData) {
    return Cloud.run("getRefStripData", {
      sampleId: requestRefStripData.sampleId,
      createdBy: requestRefStripData.createdBy,
      itemsToFetch: requestRefStripData.itemsToFetch,
    });
  }
  static GetSampleAnalsysStats(
    sample: Sample,
    createdBy: Parse.Pointer,
    excludeRois = false,
    filterRois = false
  ) {
    return Cloud.run("getSampleAnalsysStats", {
      sampleId: sample.id,
      createdBy: createdBy,
      excludeRois,
      filterRois,
    });
  }
  static SetState(
    item: Asset | Case | Report | Sample,
    state: AssetStateName | StateName,
    user: User
  ) {
    return Cloud.run("setState", {
      object: {
        id: item.id,
        className: item.className,
      },
      state,
      user: user.toPointer(),
    });
  }

  static SendResetPasswordEmail(email: string) {
    return Cloud.run("sendResetPasswordEmail", {
      email,
    });
  }

  static SendVerificationEmail(email: string) {
    return Cloud.run("sendVerificationEmail", {
      email,
    });
  }

  static ResetPassword(id: string, token: string, password: string) {
    return Cloud.run("resetPassword", {
      id,
      token,
      password,
    });
  }

  static VerifyUser(
    id: string,
    token: string,
    password: string,
    username: string
  ) {
    return Cloud.run("verifyUser", {
      id,
      token,
      password,
      username,
    });
  }

  static PrioritizeAI(
    sample: Sample,
    { workflow }: { workflow?: string } = {}
  ) {
    return Cloud.run("analyseSample", {
      ...{ sample: sample.toPointer() },
      ...(workflow
        ? { workflow: { objectId: workflow, className: "Workflow" } }
        : {}),
    });
  }

  static SetLicense(
    organization: Organization,
    license: ILicenseInfo,
    force = true
  ) {
    return Cloud.run("setLicense", {
      organization: organization.toPointer(),
      license,
      force,
    });
  }

  static InviteUser({
    user,
    organization,
    inviteCode,
  }: IInviteUserData): Promise<Affiliate>;
  static InviteUser(
    user: User | IInviteUserData,
    organization?: Organization
  ): Promise<Affiliate>;
  static InviteUser(
    data: User | IInviteUserData,
    organization?: Organization
  ): Promise<Affiliate> {
    const org = (data as IInviteUserData)?.organization ?? organization;
    const user = (data as IInviteUserData)?.user ?? (data as User);
    const inviteCode = (data as IInviteUserData)?.inviteCode ?? undefined;
    return Cloud.run("inviteUser", {
      user: user.isNew() ? user.toJSON() : user.toPointer(),
      organization: org?.toPointer(),
      inviteCode,
    });
  }

  static RedeemInvite({
    user,
    inviteCode,
  }: Pick<IInviteUserData, "user" | "inviteCode">): Promise<Affiliate> {
    return Cloud.run("redeemInvite", {
      user: user?.toPointer(),
      inviteCode,
    });
  }

  static DeleteSecondOpinion({ analysisStateId }: { analysisStateId: string }) {
    return Cloud.run("deleteSecondOpinion", { analysisStateId });
  }

  static ResendSecondOpinion({
    caseId,
    analysisStateId,
  }: {
    caseId: string;
    analysisStateId: string;
  }) {
    return Cloud.run("resendSecondOpinion", { analysisStateId, caseId });
  }

  static RequestSecondOpinion({
    guestUsers,
    sampleId,
    comments,
    assets,
    caseId,
    subject,
  }: {
    subject: string;
    guestUsers?: { email: string; alias: string }[];
    sampleId?: string;
    comments: string;
    assets: string[];
    caseId: string;
  }) {
    return Cloud.run("requestSecondOpinion", {
      sampleId,
      guestUsers,
      comments,
      assets,
      caseId,
      subject,
    });
  }

  static StitchSample({ sampleId, tag }: { sampleId: string; tag: string }) {
    return Cloud.run("stitchSample", {
      sampleId,
      tag,
    });
  }
}
