import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { HotkeysModule } from "@ngneat/hotkeys";
import { TranslateModule } from "@ngx-translate/core";
import {
  BadgeModule,
  GridViewModule,
  ImageModule,
  ImagePlaceholderModule,
  ScrollDetectorDirectiveModule,
  SnackbarAlertComponentModule,
  TableViewModule,
  VirtualScrollModule,
  DROP_LIST_SERVICE,
  SliderModule,
} from "@shared/ui";
import { ViewersFeatureModule } from "@shared/viewers/feature";
import {
  DragDropService,
  TelespotAnalysisRefactorDataAccessModule,
  TiraspotModule,
} from "@telespot/analysis-refactor/data-access";
import {
  CaseDataModule,
  CaseInfoModule,
  LabelContextMenuModule,
  NewTagTriggerModule,
  SecondOpinionSelectorComponent,
  CropDetailModule,
} from "@telespot/analysis-refactor/ui";
import { TelespotModule } from "@telespot/web-core";
import {
  PreprocessFilePipeModule,
  SecureHrefDirectiveModule,
} from "@telespot/shared/util";
import { TelespotSharedViewersUiModule } from "@telespot/shared/viewers/ui";
import { AssetsSelectorComponentModule } from "@telespot/ui";

import { AnalysisRefactorRoutingModule } from "./analysis-refactor-routing.module";
import { AiAnalysisPanelComponent } from "./components/ai-analysis-panel/ai-analysis-panel.component";
import { AcquisitionDataComponent } from "./components/acquisition-data/acquisition-data.component";
import { AnalysisFormComponent } from "./components/analysis-form/analysis-form.component";
import { AnalysisInputComponent } from "./components/analysis-input/analysis-input.component";
import { AnalystSelectorComponent } from "./components/analyst-selector/analyst-selector.component";
import { CaseAnalysisComponent } from "./components/case-analysis/case-analysis.component";
import { DownloadAssetsDialogComponent } from "./components/dialogs/download-assets-dialog/download-assets-dialog.component";
import { NewClassDialogComponent } from "./components/dialogs/new-class-dialog/new-class-dialog.component";
import { UnsavedChangesDialogComponent } from "./components/dialogs/unsaved-changes-dialog/unsaved-changes-dialog.component";
import { SecondOpinionDialogComponent } from "./components/dialogs/second-opinion-dialog/second-opinion-dialog.component";
import { MobileAnalysisPanelComponent } from "./components/mobile-analysis-panel/mobile-analysis-panel.component";
import { RoiModelStatsComponent } from "./components/roi-model-stats/roi-model-stats.component";
import { RoiSelectionComponent } from "./components/roi-selection/roi-selection.component";
import { SampleAnalysisPanelComponent } from "./components/sample-analysis-panel/sample-analysis-panel.component";
import { SampleAnalysisPreviewComponent } from "./components/sample-analysis-preview/sample-analysis-preview.component";
import { SampleAnalysisComponent } from "./components/sample-analysis/sample-analysis.component";
import { SampleCardComponentModule } from "./components/sample-card/sample-card.component";
import { SampleReportPreviewComponent } from "./components/sample-report-preview/sample-report-preview.component";
import { ViewerToolsComponent } from "./components/tools/viewer-tools/viewer-tools.component";
import { SampleAnalysisToolsComponent } from "./components/sample-analysis-tools/sample-analysis-tools.component";
import { AiAnalysisSelectionPanelComponent } from "./components/ai-analysis-selection-panel/ai-analysis-selection-panel.component";
import { SegmentationSelectionComponent } from "./components/segmentation-selection/segmentation-selection.component";
import { AssetFiltersComponent } from "./components/asset-filters/asset-filters.component";
import { RoiSelectionPanelComponent } from "./components/roi-selection-panel/roi-selection-panel.component";
import { LabelCreatorModule } from "@telespot/shared/label-creator";
import { SampleAnalysisMosaicsComponent } from "./components/sample-analysis-mosaics/sample-analysis-mosaics.component";
import { SampleAnalysisPanelMosaicsComponent } from "./components/sample-analysis-panel-mosaics/sample-analysis-panel-mosaics.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SampleAnalysisMosaicsSelectorComponent } from "./components/sample-analysis-mosaics-selector/sample-analysis-mosaics-selector.component";
import { ImageFiltersComponent } from "./components/image-filters/image-filters.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";

@NgModule({
  declarations: [
    SampleAnalysisComponent,
    AnalysisInputComponent,
    AcquisitionDataComponent,
    AnalysisFormComponent,
    CaseAnalysisComponent,
    NewClassDialogComponent,
    SampleAnalysisPanelComponent,
    AiAnalysisPanelComponent,
    SecondOpinionDialogComponent,
    AnalystSelectorComponent,
    MobileAnalysisPanelComponent,
    SecondOpinionSelectorComponent,
    SampleReportPreviewComponent,
    RoiModelStatsComponent,
    ViewerToolsComponent,
    RoiSelectionComponent,
    UnsavedChangesDialogComponent,
    DownloadAssetsDialogComponent,
    SampleAnalysisPreviewComponent,
    SampleAnalysisToolsComponent,
    AiAnalysisSelectionPanelComponent,
    SegmentationSelectionComponent,
    AssetFiltersComponent,
    RoiSelectionPanelComponent,
    SampleAnalysisMosaicsComponent,
    SampleAnalysisPanelMosaicsComponent,
    SampleAnalysisMosaicsSelectorComponent,
    ImageFiltersComponent,
  ],
  imports: [
    CommonModule,
    TelespotModule,
    ViewersFeatureModule,
    TranslateModule,
    ReactiveFormsModule,
    AnalysisRefactorRoutingModule,
    HotkeysModule,
    TiraspotModule,
    SampleCardComponentModule,
    SnackbarAlertComponentModule,
    TelespotAnalysisRefactorDataAccessModule,
    CaseDataModule,
    CaseInfoModule,
    NewTagTriggerModule,
    TelespotSharedViewersUiModule,
    MatDialogModule,
    MatRadioModule,
    MatTooltipModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatCheckboxModule,
    PreprocessFilePipeModule,
    FormsModule,
    MatFormFieldModule,
    MatListModule,
    MatButtonModule,
    MatToolbarModule,
    AssetsSelectorComponentModule,
    MatTabsModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    SecureHrefDirectiveModule,
    MatMenuModule,
    LabelCreatorModule,
    DragDropModule,
    MatAutocompleteModule,
    ScrollDetectorDirectiveModule,
    LabelContextMenuModule,
    CropDetailModule,
    BadgeModule,
    ImagePlaceholderModule,
    TableViewModule,
    GridViewModule,
    ImageModule,
    VirtualScrollModule,
    SliderModule
  ],
  providers: [
    { provide: DROP_LIST_SERVICE, useClass: DragDropService }
  ]
})
export class TelespotAnalysisRefactorFeatureModule { }
