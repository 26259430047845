<mat-dialog-content>
  <div class="flex-row break-line">
    {{ "label.copy_sample_findings" | translate }}
  </div>

  <div
    class="analysts-container"
    *ngIf="{
      analysts: data.availableAnalysts | async,
      currUser: data.currUser
    } as state"
  >
    <div>
      <h4 style="margin-bottom: 10px; margin-top: 20px">
        {{ "title.users" | translate }}
      </h4>

      <mat-radio-group [(ngModel)]="selectedAnalyst">
        <mat-radio-button [value]="state.currUser" class="analyst-radio">
          {{ "core.me" | translate }}
        </mat-radio-button>
        <ng-container *ngFor="let analyst of state.analysts">
          <mat-radio-button
            *ngIf="analyst.entity !== 'algorithm'"
            [value]="analyst"
            class="analyst-radio"
          >
            {{
              analyst.id === state.currUser.id
                ? ("core.me" | translate)
                : analyst.name
            }}
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>
    </div>

    <div *ngIf="availableAnalysts(state.analysts, 'algorithm')">
      <h4 style="margin-bottom: 10px; margin-top: 20px">
        {{ "title.ai_models" | translate }}
      </h4>
      <mat-radio-group [(ngModel)]="selectedAnalyst">
        <ng-container *ngFor="let analyst of state.analysts">
          <mat-radio-button
            *ngIf="analyst.entity === 'algorithm'"
            [value]="analyst"
            class="analyst-radio"
          >
            {{ analyst.name }}
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="custom-dialog-actions justify-content-around">
  <button
    #btnCancel
    data-cy="dialog-button-cancel"
    mat-dialog-close
    class="btn btn-flat-primary"
    focused
    (click)="cancel()"
  >
    {{ "button.cancel" | translate | uppercase }}
  </button>
  <button
    #btnAccept
    data-cy="dialog-button-accept"
    class="btn btn-primary"
    (click)="confirm()"
  >
    {{ "button.accept" | translate | uppercase }}
  </button>
</mat-dialog-actions>
