import { CaseItem } from "@telespot/domain";
import { IListCasesHandler, ListCases } from "@telespot/use-cases";

import { CaseTopology, ParseCaseMapper, WorkspaceTopology } from "../../repositories";
import { ObjectTopology } from "../../parse.topology";
import { ParseBaseRepository } from "../../parse-base.repository";

/**
 * @group Workspaces
 * @category Handlers
 */
export class ListCasesHandler extends ParseBaseRepository implements IListCasesHandler {

  public async execute(command: ListCases): Promise<CaseItem[]> {
    const { limit, nextToken, workspaceId } = command.details;

    const workspace = this.parse.Object.extend(WorkspaceTopology.TABLE)
      .createWithoutData(workspaceId);

    const casesQuery = new this.parse.Query(CaseTopology.TABLE)
      .equalTo(CaseTopology.WORKSPACE, workspace)
      .ascending(ObjectTopology.ID)
      .limit(limit);

    if (nextToken) casesQuery.greaterThan(ObjectTopology.ID, nextToken.id)

    const cases = await casesQuery.find(this.options);

    return cases.map(ParseCaseMapper.toCaseItem);
  }

}
