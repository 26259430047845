import { createFeatureSelector, createSelector } from "@ngrx/store";

import { AnalysisState, getAnalysis } from "./analysis.reducer";

export const analysisFeatureKey = "analysis";

export const selectAnalysisFeature =
  createFeatureSelector<AnalysisState>(analysisFeatureKey);

export const selectHasUnsavedChanges = createSelector(
  selectAnalysisFeature,
  (state) => state.lastEditedTimestamp > state.lastSavedTimestamp
);

export const selectUnsyncedAnalysis = createSelector(
  selectAnalysisFeature,
  (state) => getAnalysis(state).filter((analysis) => !analysis.synced)
);

export const selectAnalysis = createSelector(
  selectAnalysisFeature,
  getAnalysis
);
export const assetIdsFromAnalysis = (user: Parse.Pointer) =>
  createSelector(selectAnalysis, (analysis) =>
    analysis
      .filter(
        (a) =>
          a?.assetId &&
          a?.createdBy.className === user?.className &&
          a?.createdBy.objectId === user?.objectId
      )
      .map((a) => a.assetId)
  );

export const selectLoadingAnalysis = createSelector(
  selectAnalysisFeature,
  (state) => state.loading
);

export const selectRois = createSelector(selectAnalysisFeature, (state) =>
  Object.values(state.rois.entities || {})
);

export const selectMode = createSelector(
  selectAnalysisFeature,
  (state) => state.mode
);

export const selectmaskLoading = createSelector(
  selectAnalysisFeature,
  (state) => state.maskLoading
);

export const selectSyncingAnalysis = createSelector(
  selectAnalysisFeature,
  (state) => state.syncing
);

