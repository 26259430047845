export class NumberedVersion {
  public static create(value: number): NumberedVersion {
    if (typeof value !== "number") {
      throw new Error(`Version must be a number`);
    }
    if (value < 0) {
      throw new Error(`Version can not be less then 0`);
    }

    return new NumberedVersion(value);
  }

  private constructor(public readonly value: number) { }
}
