<div class="card">
  <table mat-table [dataSource]="dataSource" class="spotlab-table" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'core.name' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let workspace">
        {{ workspace.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="caseType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'core.case_type' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let workspace">
        {{ workspace.caseType?.name || '[' + ('core.unspecified' | translate) + ']' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="storage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'core.storage' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let workspace">
        {{ workspace.usedStorage | storage }}
      </td>
    </ng-container>
    <ng-container matColumnDef="stats">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let workspace">
        <div class="flex-row align-items-center">
          <span class="flex-row align-items-center justify-left text-gray" style="flex: 1 1 50%">
            <i class="ri-group-line"></i>
            <span>{{ workspace.numMembers }}</span>
          </span>
          <span class="flex-row align-items-center justify-left text-gray" style="flex: 1 1 50%">
            <i class="ri-briefcase-4-line"></i>
            <span>{{ workspace.numCases }}</span>
          </span>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns$ | async"></tr>
    <tr
      mat-row
      *matRowDef="let workspace; columns: visibleColumns$ | async"
      [routerLink]="['/workspaces', workspace.id]"
    ></tr>
  </table>
</div>
