<ng-container *ngIf="layout === 'full'; else mobileTemplate">
  <mat-expansion-panel
    class="custom-mat-expansion-panel roi-type-expansion-panel"
    [expanded]="!task.analysisTask.collapsed"
    (opened)="onTaskClosed(task.analysisTask.name, false)"
    (closed)="onTaskClosed(task.analysisTask.name, true)"
    style="padding-top: 0px"
  >
    <mat-expansion-panel-header
      class="roi-expansion-panel-header"
      expandedHeight="32px"
      collapsedHeight="32px"
    >
      {{
        task.analysisTask.allowNewOptions
          ? ("label.custom_labels" | translate)
          : task.analysisTask.name
      }}
    </mat-expansion-panel-header>
    <div
      *ngFor="let item of task.analysisTask.options"
      class="flex-row align-items-center"
    >
      <ng-container *ngIf="multipleSelection$ | async; else singleMode">
        <mat-checkbox
          [disabled]="isOptionDisabled(task.pipelineId, true)"
          [checked]="isOptionSelected(item, true)"
          (click)="selectOption(item)"
          class="no-margin"
          [attr.data-cy]="'button-select-model-' + item.className"
        >
          {{ item.name }}
        </mat-checkbox>
      </ng-container>
      <ng-template #singleMode>
        <mat-radio-button
          class="no-margin"
          [disabled]="isOptionDisabled(task.pipelineId, false)"
          [checked]="isOptionSelected(item, false)"
          [value]="item"
          (click)="selectOption(item)"
          [attr.data-cy]="'button-select-model-' + item.className"
        >
          {{ item.name }}
        </mat-radio-button>
      </ng-template>

      <ng-container *ngIf="getModelShortcut(item) as index">
        <span
          style="flex-grow: 1; padding: 0px 8px"
          [matTooltip]="
            'tooltip.model_hotkey' | translate : { h: index, m: item.name }
          "
        >
          <em>[{{ index }}]</em>
          <ng-container *ngIf="{ key: index } as keyValue"> </ng-container>
        </span>
      </ng-container>

      <span class="flex-row align-items-center" style="margin-left: auto">
        <button
          mat-icon-button
          class="btn"
          style="height: auto"
          (click)="updateLabelsForCounter(item.uuid)"
          [matTooltip]="
            item.removeFromCounter
              ? ('tooltip.add_to_counter' | translate)
              : ('tooltip.remove_from_counter' | translate)
          "
          aria-label="Add to counter"
        >
          <i
            [class.ri-increase-decrease-fill]="!item.removeFromCounter"
            [class.ri-increase-decrease-line]="item.removeFromCounter"
          >
          </i>
        </button>
        <button
          mat-icon-button
          class="btn"
          style="height: auto"
          (click)="setPinnedOption(item)"
          [matTooltip]="'tooltip.pin_unpin_model' | translate"
          aria-label="Toggle pin"
        >
          <i
            [class.ri-pushpin-line]="!item.pinned"
            [class.ri-pushpin-fill]="item.pinned"
            [class.color-accent]="item.pinned"
          >
          </i>
        </button>

        <button
          mat-icon-button
          class="btn"
          style="height: auto"
          (click)="toggleOptionVisibility(item)"
          aria-label="Show/hide"
          [matTooltip]="'tooltip.view_hide_model' | translate"
        >
          <i
            class="{{ item.visible ? 'ri-eye-line' : 'ri-eye-close-line' }}"
          ></i>
        </button>
      </span>
      <span
        class="roi-badge"
        [style.background-color]="getModelColor(item.uuid)"
        [attr.data-cy]="'badge-rois-' + item.className"
      >
        {{ getModelCount(item.uuid) | async }}
      </span>
    </div>
    <ng-container *ngIf="task.analysisTask.allowNewOptions">
      <button
        mat-icon-button
        style="width: auto"
        aria-label="Add new label"
        [matTooltip]="'tooltip.add_new_label' | translate"
        (click)="openNewClassDialog()"
      >
        <i class="ri-add-circle-line"></i>
      </button>
      <label> {{ "tooltip.add_new_label" | translate }} </label>
    </ng-container>
  </mat-expansion-panel>
</ng-container>

<ng-template #mobileTemplate>
  <div class="flex-column align-items-center mobile-roi-selection">
    <label for="">
      {{ task.analysisTask.name }}
    </label>
    <div class="flex-row align-items-center">
      <span
        class="flex-column align-items-center"
        style="margin: 0px 4px"
        role="button"
        [attr.aria-label]="item.name"
        *ngFor="let item of task.analysisTask.options"
        [matTooltip]="item.name"
        (click)="selectOption(item)"
        [attr.data-cy]="'button-select-model-' + item.className"
      >
        <span
          class="roi-badge roi-badge-mobile align-items-center"
          [style.backgroundColor]="getModelColor(item.uuid)"
          [class.selected]="isOptionSelected(item, false)"
        >
          <span [attr.data-cy]="'badge-rois-' + item.className">{{
            getModelCount(item.uuid) | async
          }}</span>
        </span>
        <span>
          {{ item.name }}
        </span>
      </span>
      <button
        mat-button
        class="roi-badge roi-badge-mobile roi-badge-mobile--add mat-icon-button align-self-start"
        tsNewTagTrigger="task"
        aria-label="Add new label"
        *ngIf="task.analysisTask.allowNewOptions"
      >
        <i class="ri-add-line"></i>
      </button>
    </div>
  </div>
</ng-template>
