import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IMethodSampleATUser, ReportContext } from '@telespot/reports/data-access';
import { Analysis, AnalysisTask, AnalysisType, Asset } from '@telespot/sdk';

@Component({
  selector: 'ts-results-panel',
  templateUrl: './results-panel.component.html',
  styleUrls: ['./results-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsPanelComponent {
  @Input()
  methodGroups: IMethodSampleATUser[];

  @Input()
  context: ReportContext;

  scenario: typeof ReportContext = ReportContext;

  public currentContext(samplePacket) {
    return this.context === ReportContext.asset
      ? samplePacket.assetResults.length
      : this.context === ReportContext.sample
        ? samplePacket.results.length
        : samplePacket.counters.length;
  }

  public filterSampleGroup(samplePacket) {
    return this.context === ReportContext.asset
      ? samplePacket.assetResults[samplePacket.assetResults.findIndex((_sp) => _sp.asset === samplePacket.selectedAsset)]
        ?.results
      : this.context === ReportContext.sample
        ? samplePacket.results
        : samplePacket.counters;
  }

  public filterVisibleAnalysis(allAnalysis: Analysis[], task: AnalysisTask, asset?: Asset): Analysis[] {
    const isAssetSpecific = !!asset;
    return allAnalysis.filter(
      (analysis) =>
        analysis.asset?.id === asset?.id && analysis.data?.[task.name] && !!task.assetSpecific === isAssetSpecific
    );
  }
  public toggleVisibleAsset(samplePacket, asset: Asset) {
    samplePacket.selectedAsset = asset;
    samplePacket.selectedAssetIndex = samplePacket.assetResults.findIndex((_sp) => _sp.asset === asset);
  }

  public hasAssetTasks(analysisType: AnalysisType): boolean {
    return this.context === ReportContext.asset ? analysisType.tasks.some((_task) => !!_task.assetSpecific) : true;
  }

  public filterContextTasks(tasks) {
    return this.context === ReportContext.counter
      ? tasks.filter((_t) => _t.roiSelection && _t.assetSpecific)
      : this.context === ReportContext.asset
        ? tasks.filter((_t) => _t.assetSpecific)
        : tasks.filter((_t) => !_t.assetSpecific);
  }
}
