<div class="main-container">
  <ts-stepper>
    <div class="card padded" step>
      <div class="card-header">
        <h2>{{ "title.case_data" | translate | uppercase }}</h2>
      </div>
      <div class="card-body padded">
        <ts-case-fields-form
          [case]="case"
          [formErrorMatcher]="formErrorMatcher"
          (formValidityChange)="onCaseFormValidityChange($event)"
          #caseForm
        ></ts-case-fields-form>
      </div>
    </div>
    <div step>
      <ts-samples-form
        [case]="case"
        [methodGroups]="methodGroups"
        [devices]="devices"
        (addSample)="addSample($event)"
        (editSample)="editSample($event)"
        (deleteSample)="deleteSample($event[0], $event[1])"
        #samplesForm
      >
      </ts-samples-form>
    </div>
    <div class="button-bar align-right flex-align-center">
      <button
        class="btn btn-outline-primary btn-outline--white-bg"
        (click)="cancel()"
        data-cy="button-cancel-case"
      >
        <i class="ri-close-fill"></i>
        {{ "button.cancel" | translate | uppercase }}
      </button>
      <button
        class="btn btn-primary"
        (click)="submit()"
        data-cy="button-submit-case"
        [disabled]="!formValid"
      >
        <i class="ri-save-line"></i>
        {{ "button.submit_case" | translate | uppercase }}
      </button>
    </div>
  </ts-stepper>
</div>
