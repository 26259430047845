import { AuthService, DataService } from '../../services';
import { MockDataService } from './mock-data.service';
import { MockAuthService } from './mock.auth-service';

export const MOCK_AUTH_SERVICES = [
  {
    provide: AuthService,
    useClass: MockAuthService,
  },
];

export const MOCK_DATA_SERVICE = [
  {
    provide: DataService,
    useClass: MockDataService,
  },
];
