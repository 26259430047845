<div
  #osdDiv
  class="osd-container {{ cursorClass$ | async }}"
  [class.osd-container--ready]="state.ready"
  (contextmenu)="(false)"
  *ngIf="{ ready: viewerReady$ | async } as state"
>
  <ng-content></ng-content>
  <ng-container *ngIf="state.ready" [ngTemplateOutlet]="extras"></ng-container>

  <div #resizableBoxPortal></div>
</div>
