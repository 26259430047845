import { ErrorHandler, Injectable, Injector, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@telespot/shared/environment';
import { LoggerService } from '@telespot/shared/logger/feature/util';
import { of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class APIErrorHandler implements ErrorHandler, OnDestroy {
  private router: Router;
  private _destroy$ = new Subject<void>();
  constructor(private injector: Injector, private zone: NgZone, private _logger: LoggerService) {}

  handleError(error: Error): void {
    if (!this.router) this.router = this.injector.get(Router);
    if (environment.production) {
      this._logger.error(new Error(error['code'] !== undefined ? `Backend error: ${error['']}` : `Error: ${error.message}`));
    } else {
      this._logger.error(error);
    }
    if (error['code'] === 209 || /invalid session/i.test(error.message)) {
      this._logOutUser();
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  private _logOutUser(): void {
    const _authService = this.injector.get(AuthService);
    _authService?.cleanUserSession();
    const translateService = this.injector.get(TranslateService);
    const message$ = translateService
      ? translateService.get('error.session_expired')
      : of('Session expired: please login again!');
    message$.pipe(takeUntil(this._destroy$)).subscribe((errorMessage) => {
      if (confirm(errorMessage)) this.zone.run(() => this.router.navigate(['logout']));
    });
  }
}
