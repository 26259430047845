import { Injectable } from '@angular/core';
import { Affiliate, ILicenseInfo, Organization, Role, User } from '@telespot/sdk';
import { environment } from '@telespot/shared/environment';
import { BehaviorSubject, from, Observable, of } from 'rxjs';

export const userAdmin = new User();
userAdmin.username = 'admin';
userAdmin.password = 'password';

const USERS: User[] = [userAdmin];

const ROLES = {
  admin: Role.fromJSON({ name: 'admin', className: '_Role' }, false),
  analyst: Role.fromJSON({ name: 'analyst', className: '_Role' }, false),
  analystmanager: Role.fromJSON({ name: 'analystmanager', className: '_Role' }, false),
  technician: Role.fromJSON({ name: 'technician', className: '_Role' }, false),
  technicianmanager: Role.fromJSON({ name: 'technicianmanager', className: '_Role' }, false),
} as const;

export const licenseGenius: ILicenseInfo = {
  name: 'Genius',
  maxStorage: 1000,
  maxUsers: 100,
  maxWorkspaces: 10,
  features: {
    ai: true,
    second_opinion: false,
  },
};

const ORGANIZATIONS: Organization[] = [
  new Organization({ name: 'Test organization', license: licenseGenius }) as Organization,
];

const SESSION_TOKEN = 'r:7b91e8823f456c12c317bf1befaf6b42';

@Injectable()
export class MockAuthService {
  private readonly _currentOrganization = new BehaviorSubject<Organization>(this.getCurrentOrganization());
  get currentOrganization$() {
    return this._currentOrganization.asObservable();
  }
  get currentOrganizationValue() {
    return this._currentOrganization.value;
  }
  private readonly _currentUser = new BehaviorSubject<User>(undefined);
  readonly currentUser$ = this._currentUser.asObservable();

  /**
   *
   *
   * @type {string}
   * @memberof AuthService
   */
  redirectUrl: string;

  get sessionToken(): string {
    return this.currentUser ? SESSION_TOKEN : undefined;
  }

  /**
   *
   *
   * @readonly
   * @type {User}
   * @memberof AuthService
   */
  get currentUser(): User {
    return this._currentUser.value;
  }

  private getCurrentOrganization(): Organization {
    const orgString = localStorage.getItem('currentOrganization');

    if (!orgString) return null;

    const orgJSON = { ...JSON.parse(orgString), className: 'Organization' };
    return Organization.fromJSON(orgJSON, false) as Organization;
  }

  setOrganization(organization: Organization) {
    const orgString = JSON.stringify(organization.toJSON());
    console.log('selected organization', orgString);
    localStorage.setItem('currentOrganization', orgString);
    this._currentOrganization.next(organization);
  }

  private async _getUserMetadata(parseUser: User): Promise<User> {
    let user = parseUser;
    user = await this.getRolesFor(user).toPromise();
    user = await this.getOrganizationFor(user).toPromise();
    // localStorage.removeItem('currentUserRoles');
    // localStorage.removeItem('currentUserAffiliations');
    // localStorage.removeItem('currentOrganization');
    // localStorage.setItem(
    //   'currentUserRoles',
    //   JSON.stringify(user.roles.map(r => r.toJSON()))
    // );
    // localStorage.setItem(
    //   'currentUserAffiliations',
    //   JSON.stringify(user.affiliations.map(a => a.toJSON()))
    // );
    try {
      this.setOrganization(user.affiliations?.[0].organization);
    } catch (err) {
      throw new Error('error.user_without_organizations');
    }
    console.log(`login:`, user);
    return user;
  }

  /**
   *
   *
   * @param {string} username
   * @param {string} password
   * @returns {(Observable<User | any>)}
   * @memberof AuthService
   */
  async login(username: string, password: string): Promise<User | any> {
    let loggedInUser: User;
    if (password === 'password') {
      loggedInUser = USERS.find((user) => user.username === username);
      if (!loggedInUser) {
        loggedInUser = new User();
        loggedInUser.set('username', username);
      }
    } else {
      return Promise.reject(new Error(JSON.stringify({ code: 101 })));
    }
    return this._getUserMetadata(loggedInUser).then((user) => {
      this._currentUser.next(user);
    });
  }

  /**
   *
   *
   * @param {string} username
   * @param {string} password
   * @returns {(Observable<User | any>)}
   * @memberof AuthService
   */
  become(token: string): Observable<User | any> {
    return of(User.fromJSON({ className: '_User', username: `user_${token}` }));
  }

  /**
   *
   *
   * @returns {(Observable<User | any>)}
   * @memberof AuthService
   */
  logout(): Observable<User | any> {
    return from(
      new Promise((resolve, reject) => {
        this._currentOrganization.next(undefined);
        this._currentUser.next(undefined);
        resolve(undefined);
      })
    );
  }

  getRolesFor(user: User): Observable<User> {
    if (user) {
      user.roles = Object.keys(ROLES)
        .map((roleName) => (user.username.startsWith(roleName) ? ROLES[roleName] : null))
        .filter((role) => role !== null);
    }
    return of(user);
  }

  getOrganizationFor(user: User): Observable<User> {
    if (user) {
      user.affiliations = ORGANIZATIONS.map((org) => new Affiliate(user, org));
    }
    return of(user);
  }

  cleanUserSession() {
    localStorage.removeItem(`Parse/${environment.api.appId}/currentUser`);
  }
}
