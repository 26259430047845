import type Parse from "parse";

import { QueryLevel, QueryContext } from "@telespot/domain";
import { CaseTopology, WorkspaceTopology } from "../workspace";
import { SampleTopology } from "./parse-sample.mapper";
import { CaseTypeTopology, MethodTypeTopology } from "../protocols";
import { OrganizationTopology } from "../organization";
import { ObjectTopology } from "../../parse.topology";
import { ParseSubclassFactory } from "../../parse-subclass.factory";

export abstract class QueryLevelStrategy {

  protected readonly subclasses: ParseSubclassFactory;
  /**
   * Query builder strategy that helps build a {@link Sample} query
   * @param ids list of strings used for query matching
   */
  constructor(protected readonly parse: typeof Parse, protected ids: string[]) {
    this.subclasses = ParseSubclassFactory.getInstance(this.parse);
  }

  /**
   * Builds and returns a Query for the {@link Sample} table
   */
  public abstract createQuery(): Parse.Query<Parse.Object>;
}

/**
 * Helper type for the {@link QueryLevelStrategy} constructor definition.
 */
export type LevelQueryStrategyClass = new (...args: unknown[]) => QueryLevelStrategy;

/* STRATEGY IMPLEMENTATIONS */

export class OrganizationLevelStrategy extends QueryLevelStrategy {
  public createQuery(): Parse.Query<Parse.Object> {
    const ParseOrganization = this.subclasses.getSubclass(OrganizationTopology.TABLE)

    const organizations = this.ids.map((id) =>
      ParseOrganization.createWithoutData(id)
    );
    const workspaceQuery = new this.parse.Query(WorkspaceTopology.TABLE)
      .containedIn(WorkspaceTopology.ORGANIZATION, organizations);

    const queryCase = new this.parse.Query(CaseTopology.TABLE)
      .matchesQuery(CaseTopology.WORKSPACE, workspaceQuery);

    return new this.parse.Query(SampleTopology.TABLE)
      .matchesQuery(SampleTopology.CASE, queryCase);
  }
}

export class WorkspaceLevelStrategy extends QueryLevelStrategy {
  public createQuery(): Parse.Query<Parse.Object> {
    const ParseWorkspace = this.subclasses.getSubclass(WorkspaceTopology.TABLE);

    const workspaces = this.ids.map((id) => ParseWorkspace.createWithoutData(id));

    const caseQuery = new this.parse.Query(CaseTopology.TABLE)
      .containedIn(CaseTopology.WORKSPACE, workspaces);

    return new this.parse.Query(SampleTopology.TABLE)
      .matchesQuery(SampleTopology.CASE, caseQuery);
  }
}

export class CaseLevelStrategy extends QueryLevelStrategy {
  // FIX: check if case exists, since deleted cases cause mapping errors
  public createQuery(): Parse.Query<Parse.Object> {
    const ParseCase = this.subclasses.getSubclass(CaseTopology.TABLE);

    const cases = this.ids.map((id) => ParseCase.createWithoutData(id));

    return new this.parse.Query(SampleTopology.TABLE)
      .containedIn(SampleTopology.CASE, cases);
  }
}

export class CaseTypeLevelStrategy extends QueryLevelStrategy {
  public createQuery(): Parse.Query<Parse.Object> {
    const ParseCaseType = this.subclasses.getSubclass(CaseTypeTopology.TABLE);

    const caseTypes = this.ids.map((id) => ParseCaseType.createWithoutData(id));

    const caseQuery = new this.parse.Query(CaseTopology.TABLE)
      .containedIn(CaseTopology.CASE_TYPE, caseTypes);

    return new this.parse.Query(SampleTopology.TABLE)
      .matchesQuery(SampleTopology.CASE, caseQuery);
  }
}

export class MethodTypeLevelStrategy extends QueryLevelStrategy {
  public createQuery(): Parse.Query<Parse.Object> {
    const ParseMethodType = this.subclasses.getSubclass(MethodTypeTopology.TABLE);

    const methodTypes = this.ids.map((id) => ParseMethodType.createWithoutData(id));

    return new this.parse.Query(SampleTopology.TABLE)
      .containedIn(SampleTopology.METHOD_TYPE, methodTypes);
  }
}

export class SampleLevelStrategy extends QueryLevelStrategy {
  public createQuery(): Parse.Query<Parse.Object> {
    return new this.parse.Query(SampleTopology.TABLE)
      .containedIn(ObjectTopology.ID, this.ids);
  }
}

/**
 * Provides a list of predefined strategies and helps resolve the strategy base off a given {@link QueryLevel}
 */
export class QueryLevelStrategyInjector {
  public static instance = new QueryLevelStrategyInjector();

  private readonly strategies: Map<QueryLevel, LevelQueryStrategyClass>;

  private constructor() {
    this.strategies = new Map<QueryLevel, LevelQueryStrategyClass>()
      .set(QueryLevel.ORGANIZATION, OrganizationLevelStrategy)
      .set(QueryLevel.WORKSPACE, WorkspaceLevelStrategy)
      .set(QueryLevel.CASE, CaseLevelStrategy)
      .set(QueryLevel.SAMPLE, SampleLevelStrategy)
      .set(QueryLevel.CASE_TYPE, CaseTypeLevelStrategy)
      .set(QueryLevel.METHOD_TYPE, MethodTypeLevelStrategy);
  }

  /**
   * Provides a query builder type for a specific query level
   *
   * @param level a valid {@link QueryLevel} used to resolve the strategy
   * @returns a {@link LevelQueryStrategyClass} implmentation
   * @throws Error when an unsupported level is provided
   */
  public resolve(level: QueryLevel): LevelQueryStrategyClass {
    if (!this.strategies.has(level))
      throw new Error(`Unsupported type ${level}`);

    return this.strategies.get(level);
  }
}

/**
 * Facade class for building Sample Queries
 */
export class SampleQueryBuilder {

  private level: QueryLevel;
  private ids: string[];

  constructor(private readonly parse: typeof Parse) { }

  /**
   * Builds a query using a provided {@link QueryLevelStrategy}.
   * @param strategy the {@link QueryLevelStrategy} instance to use
   * @returns an executable Query<{@link Sample}>
   */
  public static buildWithStrategy(strategy: QueryLevelStrategy) {
    return strategy.createQuery();
  }

  public withContext(context: QueryContext) {
    this.level = context.level;
    this.ids = context.ids;
    return this;
  }

  public withLevel(level: QueryLevel) {
    this.level = level;
    return this;
  }

  public withIds(ids: string[]) {
    this.ids = ids;
    return this;
  }

  /**
   * Builds a query from a provided {@link QueryContext}.
   * @param context the {@link QueryContext} to build the query from
   * @returns an executable Query<{@link Sample}>
   */
  public build() {
    const StrategType = QueryLevelStrategyInjector.instance.resolve(this.level);
    const strategy = new StrategType(this.parse, this.ids);

    return strategy.createQuery();
  }
}
