import { AnalysisType } from "./analysis-type";
import { Sample } from "./sample";
import { User } from "./user";
import { Entity } from "./entity";

export interface SecondOpinionAttributes extends Entity {
  createdBy: User;
  originalSample: Sample;
  sample: Sample;
  sendTo: User[];
  analysisTypes: AnalysisType[];
}

export class SecondOpinion implements Entity {
  constructor(private attributes: SecondOpinionAttributes) { }

  public get id(): string {
    return this.attributes.id;
  }

  public get createdBy(): User {
    return this.attributes.createdBy;
  }

  public get originalSample(): Sample {
    return this.attributes.originalSample;
  }

  public get sample(): Sample {
    return this.attributes.sample;
  }

  public get sendTo(): User[] {
    return this.attributes.sendTo;
  }

  public get analysisTypes(): AnalysisType[] {
    return this.attributes.analysisTypes;
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }

  removeReceiver(id: string) {
    this.attributes.sendTo = this.attributes.sendTo.filter(user => user.id !== id)
  }
}
