import { Object as ParseObject } from 'parse';

export class DeviceType extends ParseObject {
  static className = 'DeviceType';

  /**
   * Creates an instance of DeviceType.
   * @memberof DeviceType
   */
  constructor({ name, version }: { name?: string; version?: string } = {}) {
    super(DeviceType.className);
    this.name = name;
    this.version = version;
  }

  /**
   *
   *
   * @type {string}
   * @memberof DeviceType
   */
  get name(): string {
    return this.get('name') ?? '';
  }
  set name(newName: string) {
    this.set('name', newName);
  }

  get version(): string {
    return this.get('version');
  }
  set version(newName: string) {
    this.set('version', newName);
  }
}
