import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@shared/ui';
import { AuthService, DataService } from '@telespot/web-core';
import { Affiliate, Query } from '@telespot/sdk';
import { combineLatest, from, Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ts-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersComponent implements OnDestroy {
  private userDeleted: Subject<void> = new Subject();
  affiliates$: Observable<{ affiliate: Affiliate; roles$: Observable<string[]> }[]> = combineLatest([
    this.authService.currentOrganization$,
    this.userDeleted.pipe(startWith([])),
  ]).pipe(
    map(([org]) => new Query(Affiliate).exists('user').include(['user']).equalTo('organization', org)),
    switchMap(async (orgQuery) => {
      const count = await this.dataService.count(orgQuery);
      return (await this.dataService.find(orgQuery.limit(count))).filter((affiliate) => !!affiliate.user);
    }),
    map((affiliates) =>
      affiliates.map((affiliate) => ({
        affiliate,
        roles$: from(this.authService.getUserRoles(affiliate.user)).pipe(
          map((roles) => roles.map((role) => role.getName()))
        ),
      }))
    )
  );

  private _destroy$ = new Subject<void>();
  private _dialogRef: MatDialogRef<unknown>;
  constructor(private authService: AuthService, protected dataService: DataService, private dialog: MatDialog) {}

  ngOnDestroy() {
    this._destroy$.next();
  }

  public async delete(affiliate: Affiliate) {
    this._dialogRef =
      this._dialogRef ||
      this.dialog.open(ConfirmationDialogComponent, {
        hasBackdrop: true,
        width: '400px',
        data: {
          title: 'info.remove_user_prompt',
          text: 'info.remove_user_prompt.description',
          acceptButtonText: 'button.delete',
          cancelButtonText: 'button.cancel',
          defaultAction: 'cancel',
        },
      });
    this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe(async (answer) => {
        if (answer === 'accept') {
          await this.dataService.delete(affiliate);
          this.userDeleted.next();
        }
      });
  }
}
