import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { WorkspaceResolver } from "@telespot/web-core";
import { ExitEditorGuard } from "@telespot/shared/util";

import { CaseAcquisitionComponent } from "./components/case-acquisition/case-acquisition.component";

const routes: Routes = [
  {
    path: "new/:workspaceId",
    component: CaseAcquisitionComponent,
    canDeactivate: [ExitEditorGuard],
    resolve: {
      workspace: WorkspaceResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AcquisitionRoutingModule {}
