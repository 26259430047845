import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ts-data-table ng-template[dataTableCell]',
})
export class DataTableCellTemplateDirective {
  @Input() dataTableCell: string;
  constructor(public tpl: TemplateRef<unknown>) {}
}
