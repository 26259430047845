import { CommonModule } from "@angular/common";
import { Component, Input, NgModule, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CasesService } from "@telespot/analysis-refactor/data-access";
import { Workspace } from "@telespot/sdk";
import { TruncatePipeModule } from "@telespot/shared/util";

@Component({
  selector: "ts-workspace-item",
  templateUrl: "./workspace-item.component.html",
  styleUrls: ["./workspace-item.component.scss"],
})
export class WorkspaceItemComponent implements OnInit {
  @Input() workspace: Workspace;
  @Input() showLabels = true;

  /* Observables */

  public numCases$;

  constructor(private caseService: CasesService) {}

  /* Component Lifecycle Methods */

  ngOnInit(): void {
    this.numCases$ = this.caseService.count({ workspace: this.workspace });
  }
}

@NgModule({
  imports: [CommonModule, TruncatePipeModule, TranslateModule],
  declarations: [WorkspaceItemComponent],
  exports: [WorkspaceItemComponent],
})
export class WorkspaceItemComponentModule {}
