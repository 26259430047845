import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Optional, Injectable } from '@angular/core';

@Injectable()
export class PaginatorIntl extends MatPaginatorIntl {
  constructor(@Optional() translateService: TranslateService) {
    super();
    if (translateService) {
      this.itemsPerPageLabel = translateService.instant('label.itemsPerPageLabel');
      this.nextPageLabel = translateService.instant('label.nextPageLabel');
      this.previousPageLabel = translateService.instant('label.previousPageLabel');
      translateService.onLangChange.subscribe(_ => {
        this.itemsPerPageLabel = translateService.instant('label.itemsPerPageLabel');
        this.nextPageLabel = translateService.instant('label.nextPageLabel');
        this.previousPageLabel = translateService.instant('label.previousPageLabel');
      });
    } else {
      this.itemsPerPageLabel = 'Items per page';
      this.nextPageLabel = 'Next page';
      this.previousPageLabel = 'Previous page';
    }
  }

  getRangeLabel = function(page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0 of ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' / ' + length;
  };
}
