import { Component, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ts-gdpr-notice-dialog',
  templateUrl: './gdpr-notice-dialog.component.html',
  styleUrls: ['./gdpr-notice-dialog.component.scss'],
})
export class GdprNoticeDialogComponent { }

@NgModule({
  imports: [TranslateModule],
  declarations: [GdprNoticeDialogComponent],
})
export class GdprNoticeDialogModule { }
