import { Component, Inject, OnInit, Optional } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SUPPORTED_LANGUAGES, SupportedLanguage } from "@shared/localization";

@Component({
  selector: "ts-label-lang-selector-dialog",
  templateUrl: "./label-lang-selector-dialog.component.html",
  styleUrls: ["./label-lang-selector-dialog.component.scss"],
})
export class LabelLangSelectorDialogComponent implements OnInit {
  selectedLanguage: string;
  form: UntypedFormGroup;

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<LabelLangSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    @Inject(SUPPORTED_LANGUAGES) public supportedLanguages: SupportedLanguage[]
  ) {}

  optionNameValidator(control) {
    const optionName = control.value;

    if (
      optionName &&
      optionName.en !== undefined &&
      optionName.en !== null &&
      optionName.en !== ""
    ) {
      return null;
    } else {
      return { optionNameInvalid: true };
    }
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      optionName: new UntypedFormControl(
        { en: this.data.option, es: "", pt: "", fr: "" } || {},
        this.optionNameValidator
      ),
    });
  }
  submit() {
    this.dialogRef?.close(this.form.value.optionName);
  }

  close() {
    this.dialogRef?.close();
  }
}
