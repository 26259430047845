import { Component, ElementRef, Optional, ViewChild } from "@angular/core";

import { TaskOption } from "@telespot/sdk";
import { Observable, Subject } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "ts-new-class-dialog",
  templateUrl: "./new-class-dialog.component.html",
  styleUrls: ["./new-class-dialog.component.scss"],
})
export class NewClassDialogComponent {
  value: string;
  isLabelSuggesterVisible = true;

  inputValue$ = new Subject<string>();
  filteredLabels$: Observable<TaskOption[]>;

  @ViewChild("inputElement") inputElement: ElementRef;

  constructor(
    @Optional() private dialogRef: MatDialogRef<NewClassDialogComponent>
  ) {}

  onInputChange(event: Event) {
    const value = (event.target as HTMLInputElement).value.trim();
    this.inputValue$.next(value);
    this.isLabelSuggesterVisible = true;
  }

  onLabelSuggesterClick(event: Event): void {
    event.stopPropagation();
  }

  onCategorySelected(value) {
    this.inputElement.nativeElement.value = value.name;
    this.value = value;
    this.isLabelSuggesterVisible = false;
  }

  submit() {
    this.dialogRef?.close(this.value);
  }

  cancel() {
    this.dialogRef?.close();
  }
}
