<ng-container *ngIf="layout === 'full'; else mobileTemplate">
  <label for="">
    {{ task.name }}
  </label>
  <div *ngFor="let item of task.options" class="flex-row align-items-center">
    <mat-radio-button
      class="no-margin"
      [disabled]="isViewMode"
      [checked]="isOptionSelected(item)"
      [value]="item"
      (click)="selectOption(item)"
      [attr.data-cy]="'button-select-model-' + item.className"
    >
      {{ item.name }}
    </mat-radio-button>
    <ng-container *ngIf="getModelShortcut(item) as index">
      <span
        style="flex-grow: 1; padding: 0px 8px"
        [matTooltip]="
          'tooltip.model_hotkey' | translate : { h: index, m: item.name }
        "
      >
        <em>[{{ index }}]</em>
        <ng-container *ngIf="{ key: index } as keyValue"> </ng-container>
      </span>
    </ng-container>
    <span class="flex-row align-items-center" style="margin-left: auto">
      <button
        mat-icon-button
        class="btn"
        style="height: auto"
        (click)="setPinnedOption(item)"
        [matTooltip]="'tooltip.pin_unpin_model' | translate"
        aria-label="Toggle pin"
      >
        <i
          [class.ri-pushpin-line]="!item.pinned"
          [class.ri-pushpin-fill]="item.pinned"
          [class.color-accent]="item.pinned"
        >
        </i>
      </button>
    </span>
    <span
      class="segm-badge"
      [style.background-color]="getModelColor(item.uuid)"
      [attr.data-cy]="'badge-segm-class-' + item.className"
    >
    </span>
  </div>
</ng-container>
<ng-template #mobileTemplate></ng-template>
