<div class="user-avatar-component">
  <ng-container *ngTemplateOutlet="avatarContent; context: { $implicit: user }"></ng-container>
</div>

<!-- Content template -->
<ng-template #avatarContent let-user>
  <ng-container *ngIf="user; else noUser">
    <img
      #img
      [src]="user.avatar?.url()"
      alt="user avatar"
      *ngIf="user.avatar?.url() && !wrongImageUrl; else noImage"
      (error)="wrongImageUrl = true"
    />
    <ng-template #noImage>
      <span> {{ initials | uppercase }} </span>
    </ng-template>
  </ng-container>
  <ng-template #noUser>
    <ng-content></ng-content>
  </ng-template>
</ng-template>
