import { Pipeline } from "@telespot/domain";
import { ParseMapper } from "../../parse.mapper";

export enum PipelineTopology {
  TABLE = "Pipeline",
  NAME = "name",
  ENV = "env",
}

export class ParsePipelineMapper extends ParseMapper<Pipeline> {
  public toDomain(parseObject: Parse.Object): Pipeline {
    return new Pipeline({
      id: parseObject.id,
      name: parseObject.get(PipelineTopology.NAME),
      env: parseObject.get(PipelineTopology.ENV),
    });
  }

  public fromDomain(pipeline: Pipeline) {
    const ParsePipeline = this.subclasses.getSubclass(PipelineTopology.TABLE);

    const parseObject = pipeline.id
      ? ParsePipeline.createWithoutData(pipeline.id)
      : new ParsePipeline();

    parseObject.set(PipelineTopology.NAME, pipeline.name);
    parseObject.set(PipelineTopology.ENV, pipeline.env);

    return parseObject;
  }
}
