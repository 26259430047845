import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { environment } from '@telespot/shared/environment';
import { LoggerService } from '@telespot/shared/logger/feature/util';

import { Recorder, RecordEvent } from '../../models/recorder';

export const RECORDER_TOKEN = new InjectionToken("Recorder");

@Injectable({
  providedIn: 'any',
})
export class RecorderService {
  constructor(@Optional() private logger: LoggerService, @Optional() @Inject(RECORDER_TOKEN) private recorder: Recorder) {
    this.logger?.debug(`Recorder service is ${environment.recorder.enable ? 'ENABLED' : 'DISABLED'}`);
  }

  public sendCustomEvent(event: RecordEvent) {
    if (!this.consented) {
      this.logger?.warn(`RecorderService.sendCustomEvent() failed: Concent has denied`);
      return;
    }

    this.recorder?.sendCustomEvent(event);
  }

  public setConsent(allow: boolean): void {
    this.consented = allow;
    this.logger?.debug(`Setting concent to ${this.consented ? 'ALLOW' : 'DENY'}`);
    this.recorder?.consent(this.consented);
  }

  public get hasConcent(): boolean {
    return this.consented;
  }

  public identify(id: string, data: { [key: string]: unknown }): void {
    const isSpotlabMember = /@spotlab.(org|ai)/i.test(data?.email as string);

    if (!this.consented) {
      this.logger?.warn(`RecorderService.identify() failed: Concent has denied`);
      return;
    }

    if (environment.recorder.disable_spotlab_users && data?.email && isSpotlabMember) {
      this.logger?.warn(`Recorder recording paused for Spotlab users`);
      this.recorder?.disable();
      return;
    }

    this.recorder?.identify(id, data);
  }

  private get consented(): boolean {
    return JSON.parse(localStorage.getItem('recording_identification_enabled'));
  }

  private set consented(value: boolean) {
    localStorage.setItem('recording_identification_enabled', value.toString());
  }

  public stopRecording() {
    this.recorder?.stop();
  }

  public startRecording() {
    this.recorder?.start();
  }
}
