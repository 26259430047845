import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { TAnalysisDataJSON } from "@telespot/sdk";
import generateId from "uid";

import * as AnalysisActions from "./analysis.actions";
import { Label } from "../protocol";

const ID_LENGTH = 10;

export interface IAnalysis {
  readonly id: string;
  synced: boolean;
  readonly assetId: string | undefined;
  readonly createdBy: Parse.Pointer;
  readonly sampleId: string;
  data: TAnalysisDataJSON;
  readonly isSampleAnalysis: boolean;
  analysisTypeId: string;
}

export function labelsToAnalysisLabelFormat(
  labels: Label[],
  analysisArray: IAnalysis[]
): AnalysisLabel[] {
  const analysisLabels = labels.reduce<AnalysisLabel[]>((acc, label) => {
    const analysisIdForLabel = analysisArray.find(
      (analysis) => analysis.analysisTypeId === label.analysisTypeId
    )?.id;

    return !analysisIdForLabel ? acc : [
      ...acc,
      {
        analysisId: analysisIdForLabel,
        labels: [`category:${label.category}/value:${label.value}`],
      },
    ];
  }, []);

  return analysisLabels.reduce((acc, currLabel) => {
    const index = acc.findIndex(
      (item) => item?.analysisId === currLabel?.analysisId
    );
    if (index !== -1) {
      acc[index] = {
        analysisId: acc[index]?.analysisId,
        labels: Array.from(
          new Set([...acc[index].labels, ...currLabel.labels])
        ),
      };
      return [...acc];
    }
    return [...acc, currLabel];
  }, []);
}

export function getAnalysis(state: AnalysisState) {
  return Object.values(state.analysis.entities ?? {});
}

export function getUnsyncedAnalysis(
  entityStates: EntityState<IAnalysis>
): IAnalysis[] {
  return Object.values(entityStates.entities).filter((a) => !a.synced);
}

export function findAnalysis(
  state: AnalysisState,
  filter: (analysis: IAnalysis) => boolean
) {
  return Object.values(state.analysis.entities ?? {}).find(filter);
}

export function findROI(state: AnalysisState, filter: (roi: ROI) => boolean) {
  return Object.values(state.rois.entities ?? {}).find(filter);
}

export function getROIsInsideRegion(
  rois: (ROI | POI)[],
  bounds,
  activeAnalysisIds: string[]
) {
  return rois?.filter(
    (roi) =>
      getLabelAnalysisIds([roi]).every((analysisId) =>
        activeAnalysisIds.includes(analysisId)
      ) &&
      roi.x >= bounds.x &&
      (!("w" in roi)
        ? roi.x <= bounds.x + bounds.w
        : roi.x + (roi as ROI).w <= bounds.x + bounds.w) &&
      roi.y >= bounds.y &&
      (!("h" in roi)
        ? roi.y <= bounds.y + bounds.h
        : roi.y + (roi as ROI).h <= bounds.y + bounds.h)
  );
}

export function getLabelAnalysisIds(rois: (ROI | POI)[]) {
  return rois?.reduce(
    (acc, roi) => [
      ...acc,
      ...(roi?.labels?.reduce((acc, label) => [...acc, label.analysisId], []) ||
        []),
    ],
    []
  );
}

export function getActiveCopiedAnalysis(activeAnalysis: IAnalysis[]) {
  const currentAnalysisIds = activeAnalysis.map((_analysis) => {
    if (_analysis?.id.startsWith("copy:"))
      return _analysis.id.substring(_analysis.id.indexOf("replace:"));
    return _analysis.id;
  });
  return activeAnalysis.filter(
    (a) => !currentAnalysisIds.includes("replace:" + a.id)
  );
}
export function hasCopy(allAnalysis: IAnalysis[], analysisId) {
  return allAnalysis.some((a) => a.id.includes("replace:" + analysisId));
}
export function roisDeduplication(rois: ROI[]) {
  const cleanedRois: ROI[] = rois?.reduce((u, r) => {
    const index = u.findIndex(
      (ur) => ur.x === r.x && ur.y === r.y && ur?.w === r?.w && ur?.h === r?.h
    );
    if (index === -1) return [...u, ...[r]];

    const labelGroupIndex = u[index].labels.findIndex(
      (labelGroup) => labelGroup.analysisId === r.labels[0].analysisId
    );

    if (labelGroupIndex === -1) {
      u[index] = {
        ...u[index],
        labels: [...u[index].labels, r.labels[0]],
      };
    } else {
      u[index] = {
        ...u[index],
        labels: [
          ...u[index].labels.filter(
            (label, index) => index !== labelGroupIndex
          ),
          {
            ...u[index].labels[labelGroupIndex],
            labels: Array.from(
              new Set([
                ...u[index].labels[labelGroupIndex].labels,
                ...r.labels[0].labels,
              ])
            ),
          },
        ],
      };
    }

    return [...u];
  }, []);

  return cleanedRois;
}

export const analysisAdapter = createEntityAdapter<IAnalysis>({
  selectId: (analysis) => analysis.id,
});

export const roiAdapter = createEntityAdapter<ROI | POI>({
  selectId: (xois) => xois.id,
});

export interface POI {
  id: string;
  x: number;
  y: number;
  selected: boolean;
  labels: AnalysisLabel[];
  time?: number;
  //FIX: DELETE THIS
  isAIresult?: boolean;
}

export interface AnalysisLabel {
  analysisId: string;
  labels: string[];
}

export interface ROI extends POI {
  w: number;
  h: number;
}

export enum Mode {
  ANALYSIS = "analysis",
  NAVIGATION = "navigation",
  REVIEW = "review",
}

export interface AnalysisState {
  rois: EntityState<ROI | POI>;
  analysis: EntityState<IAnalysis>;
  lastEditedTimestamp: number;
  lastSavedTimestamp: number;
  loading: boolean;
  maskLoading: boolean;
  syncing: boolean;
  error: string | null;
  mode: Mode;
}

export const initialAnalysisState: AnalysisState = {
  rois: roiAdapter.getInitialState(),
  analysis: analysisAdapter.getInitialState(),
  lastEditedTimestamp: Date.now(),
  lastSavedTimestamp: Date.now(),
  loading: false,
  maskLoading: false,
  syncing: false,
  error: null,
  mode: Mode.ANALYSIS,
};

export const analysisReducer = createReducer(
  initialAnalysisState,

  on(AnalysisActions.analysisActionError, (state, { error }) => ({
    ...state,
    loading: false,
    syncing: false,
    error,
  })),

  on(AnalysisActions.switchMode, (state, { mode }) => {
    if (mode === Mode.ANALYSIS) {
      const analysisCopies = Object.values(state.analysis.entities)
        .filter((analysis) => analysis.id.startsWith("copy:"))
        .map((analysis) => analysis.id);
      const roisCopies = Object.values(state.rois.entities)
        .filter((roi) => roi.id.startsWith("copy:"))
        .map((roi) => roi.id);
      return {
        ...state,
        mode,
        analysis: analysisAdapter.removeMany(analysisCopies, state.analysis),
        rois: roiAdapter.removeMany(roisCopies, state.rois),
      };
    }
    return {
      ...state,
      mode,
    };
  }),

  on(AnalysisActions.setROIs, (state, { rois }) => {
    const allAnalysis = getAnalysis(state);
    const roisToAdd = rois.map((roi) => ({
      ...roi,
      selected: false,
      isAIresult: false,
      id: roi.labels.some((label) => label.analysisId.startsWith("copy:"))
        ? "copy:" + generateId(6)
        : generateId(6),
    }));

    const updateAnalyses = getLabelAnalysisIds(roisToAdd).map((analysisId) => ({
      id: analysisId,
      changes: { synced: false },
    }));

    return {
      ...state,
      rois: { ...roiAdapter.addMany(roisToAdd, state?.rois) },
      analysis: analysisAdapter.updateMany(updateAnalyses, state.analysis),
      lastEditedTimestamp: Date.now(),
    };
  }),
  on(AnalysisActions.setSelectedROIs, (state, { rois, replace }) => {
    let selectedROIs: (ROI | POI)[] =
      rois?.map((roi) => ({ ...roi, selected: true })) ?? [];

    if (replace) {
      const currentSelectedROIs = state.rois.ids
        .map((roiId) => state.rois?.entities[roiId])
        .filter((roi) => roi.selected)
        .map((roi) => ({ ...roi, selected: false }));
      selectedROIs = selectedROIs?.concat(currentSelectedROIs);
    }
    return {
      ...state,
      rois: { ...roiAdapter.upsertMany(selectedROIs, state?.rois) },
    };
  }),
  on(AnalysisActions.removeROIs, (state, { rois, selectedROIs }) => {
    const roisToRemove = selectedROIs
      ? selectedROIs.map((roi) => roi.id)
      : rois?.map((roi) => roi.id);
    let updateAnalyses = selectedROIs
      ? getLabelAnalysisIds(selectedROIs).map((analysisId) => ({
        id: analysisId,
        changes: { synced: false },
      }))
      : getLabelAnalysisIds(rois).map((analysisId) => ({
        id: analysisId,
        changes: { synced: false },
      }));

    updateAnalyses = [
      ...new Map(updateAnalyses.map((item) => [item["id"], item])).values(),
    ];

    return {
      ...state,
      rois: {
        ...roiAdapter.removeMany(roisToRemove, state?.rois),
      },
      analysis: analysisAdapter.updateMany(updateAnalyses, state.analysis),
      lastEditedTimestamp: Date.now(),
    };
  }),
  on(AnalysisActions.updateROI, (state, { roi, changes }) => ({
    ...state,
    rois: { ...roiAdapter.updateOne({ id: roi?.id, changes }, state?.rois) },
    analysis: analysisAdapter.updateMany(
      roi
        ? getLabelAnalysisIds([roi])?.map((analysisId) => ({
          id: analysisId,
          changes: { synced: false },
        }))
        : [],
      state.analysis
    ),
    lastEditedTimestamp: Date.now(),
  })),
  on(
    AnalysisActions.updateROILabels,
    (state, { label, replacePrevLabels, authUserId }) => {
      const selectedROIs = Object.values(state?.rois?.entities ?? {})?.filter(
        (roi) => roi?.selected
      );
      const allAnalysis = getAnalysis(state);

      const newLabelInfo = {
        analysisId: allAnalysis?.find(
          (analysis) =>
            analysis.analysisTypeId === label?.analysisTypeId &&
            analysis?.assetId === label?.activeAssetId &&
            analysis.createdBy.className === "_User" &&
            analysis.createdBy.objectId === authUserId &&
            !hasCopy(allAnalysis, analysis.id)
        )?.id,
        labels: label?.labels,
      };
      const updatedRois = selectedROIs.map((roi) => ({
        ...roi,
        labels: replacePrevLabels
          ? [newLabelInfo]
          : [
            ...roi.labels.filter(
              (lab) => newLabelInfo?.analysisId !== lab.analysisId
            ),
            ...roi.labels
              .filter((lab) => newLabelInfo?.analysisId === lab.analysisId)
              .map((lab) => ({
                ...lab,
                labels: lab.labels.includes(newLabelInfo.labels[0])
                  ? [
                    ...lab.labels.filter(
                      (l) => l !== newLabelInfo.labels[0]
                    ),
                  ]
                  : [...lab.labels, ...newLabelInfo.labels],
              })),
          ],
      }));
      return {
        ...state,
        rois: { ...roiAdapter.upsertMany(updatedRois, state?.rois) },
        analysis: analysisAdapter.updateMany(
          selectedROIs
            ? getLabelAnalysisIds(selectedROIs)?.map((analysisId) => ({
              id: analysisId,
              changes: { synced: false },
            }))
            : [],
          state.analysis
        ),
        lastEditedTimestamp: selectedROIs.length
          ? Date.now()
          : state.lastEditedTimestamp,
      };
    }
  ),
  on(AnalysisActions.exitAnalysis, (state) => ({
    ...initialAnalysisState,
    lastEditedTimestamp: state.lastSavedTimestamp,
    lastSavedTimestamp: state.lastSavedTimestamp,
  })),

  on(AnalysisActions.updateAnalysis, (state, { analysis }) => {
    const previousAnalysisVersion = findAnalysis(
      state,
      (a) => a.id === analysis.id
    );

    const hasNotChanged =
      JSON.stringify(previousAnalysisVersion) === JSON.stringify(analysis);

    if (hasNotChanged) return { ...state };

    return {
      ...state,
      analysis: analysisAdapter.updateOne(
        { id: analysis.id, changes: { ...analysis, synced: false } },
        state.analysis
      ),
      lastEditedTimestamp: Date.now(),
    };
  }),

  on(AnalysisActions.loadAssetAnalysis, (state) => ({
    ...state,
    loading: true,
  })),

  on(AnalysisActions.assetAnalysisLoaded, (state, { analysis, rois }) => ({
    ...state,
    analysis: analysisAdapter.upsertMany(analysis, state.analysis),
    rois: roiAdapter.addMany(roisDeduplication(rois), state.rois),
    loading: false,
  })),

  on(AnalysisActions.sampleAnalysisLoaded, (state, { analysis }) => ({
    ...state,
    analysis: analysis
      ? analysisAdapter.upsertMany(analysis, state.analysis)
      : state.analysis,
    loading: false,
  })),

  on(
    AnalysisActions.createAnalysis,
    (state, { assetId, sampleId, createdBy, analysisTypeId, data }) => {
      const newAnalysis = {
        assetId,
        sampleId,
        createdBy,
        id: `new:${generateId(ID_LENGTH)}`,
        synced: false,
        data: data ?? {},
        isSampleAnalysis: assetId === undefined, // FIXME: duplication -> use defered state
        analysisTypeId,
      };

      return {
        ...state,
        analysis: analysisAdapter.addOne(newAnalysis, state.analysis),
        lastEditedTimestamp: Date.now(),
        loading: false,
      };
    }
  ),
  on(
    AnalysisActions.createAnalysisFromROIs,
    (state, { analysesRequest, selectedLabels, rois }) => {
      const newAnalyses: IAnalysis[] = analysesRequest.map((analysisData) => ({
        assetId: analysisData.assetId,
        sampleId: analysisData.sampleId,
        createdBy: analysisData.createdBy,
        id: `new:${generateId(ID_LENGTH)}`,
        synced: false,
        data: {},
        isSampleAnalysis: analysisData.assetId === undefined,
        analysisTypeId: analysisData.analysisTypeId,
      }));
      const selectedLabelsArray = labelsToAnalysisLabelFormat(
        selectedLabels,
        newAnalyses
      );

      const roisToAdd = rois.map((roi) => ({
        ...roi,
        selected: false,
        isAIresult: false,
        id: generateId(6),
        labels: selectedLabelsArray,
      }));

      return {
        ...state,
        analysis:
          newAnalyses.length > 0
            ? analysisAdapter.addMany(newAnalyses, state.analysis)
            : state.analysis,
        rois: roiAdapter.addMany(roisToAdd, state.rois),
        lastEditedTimestamp: Date.now(),
        loading: false,
      };
    }
  ),
  on(
    AnalysisActions.analysisCopied,
    (state, { authUser, activeAnalysisIds }) => {
      const analysis = Object.values(state.analysis.entities);
      const newAnalysis: IAnalysis[] = [];
      const analysisCopies: IAnalysis[] = activeAnalysisIds.map(
        (activeAnalysisId, index) => {
          const analysisToCopy = analysis.find(
            (analysis) => analysis.id === activeAnalysisId
          );
          const analysisToReplace = analysis.find(
            (analysis) =>
              analysis.createdBy.objectId === authUser.objectId &&
              analysisToCopy.analysisTypeId === analysis.analysisTypeId &&
              analysisToCopy.assetId === analysis.assetId
          );
          if (!analysisToReplace) {
            newAnalysis.push({
              assetId: analysisToCopy.assetId,
              sampleId: analysisToCopy.sampleId,
              createdBy: authUser,
              id: `new:${generateId(ID_LENGTH)}`,
              synced: false,
              data: {},
              isSampleAnalysis: false,
              analysisTypeId: analysisToCopy.analysisTypeId,
            });
          }

          return {
            ...analysisToCopy,
            id: `copy:${analysisToCopy.id}/replace:${analysisToReplace?.id ?? newAnalysis[index].id
              }`,
            synced: false,
            createdBy: authUser,
            data: (() => {
              const { mask_filename, ...rest } = analysisToCopy.data;
              return rest;
            })(),
          };
        }
      );

      const allRois = Object.values(state.rois.entities);

      const analysisRois = allRois.filter((roi) =>
        roi.labels.some((label) => activeAnalysisIds.includes(label.analysisId))
      );

      const roiCopies = analysisRois.map((roi) => ({
        ...roi,
        id: `copy:${roi.id}`,
        labels: roi.labels
          .filter((label) => activeAnalysisIds.includes(label.analysisId))
          .map((label) => ({
            ...label,
            analysisId: analysisCopies.find((analysis) =>
              analysis.id.startsWith(`copy:${label.analysisId}`)
            ).id,
          })),
      }));

      return {
        ...state,
        mode: Mode.REVIEW,
        rois: roiAdapter.addMany(roiCopies, state.rois),
        analysis: analysisAdapter.addMany(
          [...analysisCopies, ...newAnalysis],
          state?.analysis
        ),
        lastEditedTimestamp: Date.now(),
      };
    }
  ),
  on(AnalysisActions.loadSampleAnalysis, (state) => ({
    ...state,
    loading: true,
  })),

  on(AnalysisActions.syncAnalysis, (state) => ({ ...state, syncing: true })),

  on(AnalysisActions.analysisSynced, (state, { idChanges }) => {
    const unsyncedAnalysis = getUnsyncedAnalysis(state.analysis);
    let currentRois: EntityState<ROI | POI> = state.rois;

    const newAnalysis = unsyncedAnalysis.filter((an) =>
      state?.mode === Mode.REVIEW
        ? an.id.startsWith("copy:") || an.id.includes("new:")
        : an.id.includes("new:")
    );

    let newAnalysisIds = newAnalysis.map((an) => an.id);

    if (state?.mode === Mode.REVIEW) {
      const originalIds = newAnalysisIds.map((id) =>
        id.startsWith("copy") ? id.substring(id.indexOf("replace:") + 8) : id
      );
      newAnalysisIds = [...newAnalysisIds, ...originalIds];

      const roisToRemoveIds = Object.values(currentRois.entities ?? {})
        .filter((roi) =>
          roi.labels.some((label) => originalIds.includes(label.analysisId))
        )
        .map((roi) => roi.id);
      const copyRois = Object.values(currentRois.entities ?? {}).filter((roi) =>
        roi.id.startsWith("copy:")
      );

      currentRois = roiAdapter.removeMany(
        [...roisToRemoveIds, ...copyRois.map((roi) => roi.id)],
        state.rois
      );

      const createdRois: (ROI | POI)[] = copyRois.map((roi) => ({
        ...roi,
        id: generateId(6),
      }));

      currentRois = roiAdapter.addMany(createdRois, currentRois);
    }
    const analysisWithoutNewIds = analysisAdapter.removeMany(
      newAnalysisIds,
      state.analysis
    );

    const createdAnalysis = newAnalysis
      .map((an) => {
        const change = idChanges.find((change) => change.previous === an.id);
        return {
          ...an,
          id: change?.current,
          data: {
            ...an.data,
            ...(change?.maskFileName && { maskFileName: change?.maskFileName }),
          },
        };
      })
      .filter((a) => a.id);

    const analysisWithUpdatedIds = analysisAdapter.addMany(
      createdAnalysis,
      analysisWithoutNewIds
    );

    const updatedAnalysisMarkedAsSynced = getUnsyncedAnalysis(
      analysisWithUpdatedIds
    ).map((an) => ({ ...an, synced: true }));

    const roisToUpdate = Object.values(currentRois.entities ?? {}).filter(
      (roi) =>
        roi.labels.some((label) => newAnalysisIds.includes(label.analysisId))
    );

    const updatedRois = roisToUpdate.map((roi) => ({
      ...roi,
      labels: [
        ...roi.labels.filter(
          (label) => !newAnalysisIds.includes(label.analysisId)
        ),
        ...roi.labels
          .filter((label) => newAnalysisIds.includes(label.analysisId))
          .map((label) => ({
            ...label,
            analysisId: idChanges.find(
              (change) => change.previous === label.analysisId
            ).current,
          })),
      ],
    }));

    return {
      ...state,
      syncing: false,
      analysis: analysisAdapter.upsertMany(
        updatedAnalysisMarkedAsSynced,
        analysisWithUpdatedIds
      ),
      rois: roiAdapter.upsertMany(updatedRois, currentRois),
      lastSavedTimestamp: Date.now(),
      error: null,
    };
  }),
  on(
    AnalysisActions.analysisDiscarded,
    (state, { analysisDiscardedIds, hasROI }) => {
      const roisIds = Object.values(state.rois.entities ?? {})
        .filter((roi) =>
          getLabelAnalysisIds([roi]).every((analysisId) =>
            analysisDiscardedIds.includes(analysisId)
          )
        )
        .map((roi) => roi.id);

      return {
        ...state,
        analysis: analysisAdapter.removeMany(
          analysisDiscardedIds,
          state.analysis
        ),
        rois: roiAdapter.removeMany(roisIds, state.rois),
        lastEditedTimestamp: Date.now(),
      };
    }
  ),
  on(AnalysisActions.discardAnalysisChange, (state, { analysis }) => {
    const previousAnalysisVersion = findAnalysis(
      state,
      (a) => a.id === analysis.id
    );

    const hasNotChanged =
      JSON.stringify(previousAnalysisVersion) === JSON.stringify(analysis);

    if (hasNotChanged) return { ...state };

    return {
      ...state,
      analysis: analysisAdapter.updateOne(
        { id: analysis.id, changes: { ...analysis, synced: true } },
        state.analysis
      ),
      lastEditedTimestamp: Date.now(),
    };
  }),
  on(AnalysisActions.discardRemoveROIs, (state, { rois }) => ({
    ...state,
    rois: rois ? { ...roiAdapter.addMany(rois, state?.rois) } : state.rois,
    lastEditedTimestamp: Date.now(),
  })),
  on(AnalysisActions.discardUpdateROI, (state, { rois }) => ({
    ...state,
    rois: rois ? { ...roiAdapter.upsertMany(rois, state?.rois) } : state.rois,
    lastEditedTimestamp: Date.now(),
  })),
  on(AnalysisActions.discardSetROIs, (state, { xois }) => {
    const currentROI = xois.map((xoi) => {
      return findROI(
        state,
        (r) =>
          r.x === xoi.x &&
          r.y === xoi.y &&
          r?.w === xoi?.w &&
          r?.h === xoi?.h &&
          r?.time === xoi?.time &&
          JSON.stringify(r.labels) === JSON.stringify(xoi.labels)
      );
    });

    return {
      ...state,
      rois: roiAdapter.removeMany(
        currentROI?.map((roi) => roi?.id),
        state.rois
      ),
      lastEditedTimestamp: Date.now(),
    };
  }),
  on(AnalysisActions.discardSync, (state) => ({
    ...state,
    analysis: analysisAdapter.updateMany(
      getUnsyncedAnalysis(state.analysis).map((a) => ({
        id: a.id,
        changes: { synced: true },
      })) ?? [],
      state.analysis
    ),
    lastEditedTimestamp: state.lastSavedTimestamp,
  })),
  on(AnalysisActions.analysisUnsynced, (state, { analysis }) => {
    return {
      ...state,
      analysis: analysisAdapter.updateOne(
        { id: analysis.id, changes: { ...analysis, synced: false } },
        state.analysis
      ),
      lastEditedTimestamp: Date.now(),
    };
  }),
  on(
    AnalysisActions.segmAnalysisCreated,
    (state, { assetId, sampleId, createdBy, analysisTypeId }) => {
      const newAnalysis = {
        assetId,
        sampleId,
        createdBy,
        id: `new:${generateId(ID_LENGTH)}`,
        synced: false,
        data: {},
        isSampleAnalysis: false,
        analysisTypeId,
      };
      return {
        ...state,
        analysis: analysisAdapter.addOne(newAnalysis, state.analysis),
        lastEditedTimestamp: Date.now(),
      };
    }
  ),
  on(AnalysisActions.loadingMask, (state, { loading }) => ({
    ...state,
    maskLoading: loading,
  })),
  on(AnalysisActions.updateLoading, (state, { loading }) => ({
    ...state,
    loading,
  }))
);
