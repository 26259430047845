<div class="container">
  <div class="header">
    <ng-container
      *ngTemplateOutlet="
        headerTemplate;
        context: {
          $implicit: getSectionContainingRow(currentLocationRow),
          isSticky: true
        }
      "
    ></ng-container>
  </div>

  <ts-virtual-scroll
    class="scroll-content"
    [(locationRow)]="locationRow"
    [paddingNodes]="paddingNodes"
    [rowTemplate]="scrollRow"
    [rowHeight]="rowHeight"
    [itemCount]="numberOfRows"
    [scrollThreshold]="scrollThreshold"
    (startIndexChanged)="currentLocationRow = $event"
    (bottomReached)="bottomReached.emit()"
    (scrollThresholdReached)="scrollThresholdReached.emit()"
  >
    <ng-template #scrollRow let-row>
      <ng-container *ngIf="isSectionRow(row); else dataRow">
        <div class="fixed">
          <ng-container
            *ngTemplateOutlet="
              headerTemplate;
              context: { $implicit: getSectionIndexForRow(row) }
            "
          ></ng-container>
        </div>
      </ng-container>

      <ng-template #dataRow>
        <ng-container *ngIf="getIndexPathForRow(row) as indexPath">
          <div class="flexed" [style.height]="rowHeight">
            <div class="fixed">
              <ng-container
                *ngTemplateOutlet="
                  rowNumeralTemplate;
                  context: { $implicit: indexPath }
                "
              ></ng-container>
            </div>
            <ng-container
              *ngTemplateOutlet="rowTemplate; context: { $implicit: indexPath }"
            ></ng-container>
          </div>
        </ng-container>
      </ng-template>
    </ng-template>
  </ts-virtual-scroll>
</div>
