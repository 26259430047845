<div mat-dialog-title>
  <h1 class="flex-row align-items-center justify-content-between">
    {{ 'title.analysis_preview' | translate | uppercase }}
    <button mat-icon-button matDialogClose>
      <i class="ri-close-fill"></i>
    </button>
  </h1>
</div>
<mat-dialog-content data-cy="sample-analysis-preview-dialog">
  <div
    class="card2"
    *ngIf="{ sample: sample$ | async, assets: assets$ | async, assetPOCTdata: assetPOCTdata$ | async } as data"
  >
    <h4>{{ data?.sample?.name | uppercase }}</h4>
    <div class="flex-row justify-content-between align-center">
      <span class="flex-row justify-content-start align-items-center">
        <i class="ri-image-line"></i>
        {{ data?.sample?.numAssets }}
      </span>
      <span
        class="flex-row justify-content-end align-items-center download-icon"
        (click)="downloadFile(data?.sample, data?.assets)"
        style="cursor: pointer"
      >
        <i class="ri-folder-zip-fill"></i>
      </span>
    </div>

    <ng-container *ngFor="let item of data.assetPOCTdata">
      <div
        class="card-body flex-row justify-content-start align-center flex-wrap"
        [class.ai-warning]="item.disagreement"
        [class.corrected]="!!item.correctionAnalysis?.length"
      >
        <div
          (click)="showThumbnail(item.sampleAsset?.asset)"
          class="flex-column justify-content-center align-center img-container"
          matTooltip="{{ 'core.view' | translate }}"
        >
          <div class="img-container__overlay flex-column justify-content-center">
            <i class="ri-zoom-in-line"></i>
          </div>
          <img [src]="item.sampleAsset?.asset?.thumbnail | preprocess | async" alt="asset thumbnail" />
        </div>
        <div class="flex-grow-1 results-container mw-100">
          <div class="tiraspot-result-row">
            <span class="tiraspot-result-row__title" style="flex: 0 1 200px" id="poct-type-label">{{
              'label.tiraspot_test_type' | translate
            }}</span>
            <span class="tiraspot-result-row__result" aria-labelledby="poct-type-label">{{
              item.sampleAsset?.asset?.data?.tiraspot?.poct | translate | uppercase
            }}</span>
          </div>
          <hr />
          <div class="tiraspot-result-row">
            <label class="tiraspot-result-row__title" style="flex: 0 1 200px" id="user-result-label">{{
              'label.user_result' | translate
            }}</label>
            <span class="tiraspot-result-row__result" aria-labelledby="user-result-label">
              <span class="poct-result poct-result--{{ result }}" *ngFor="let result of item.userResults">{{
                result | translate | uppercase
              }}</span>
            </span>
          </div>
          <hr />
          <div class="tiraspot-result-row" *ngIf="aiEnabled$ | async">
            <label class="tiraspot-result-row__title" style="flex: 0 1 200px" id="ai-result-label">{{
              'label.AI_result' | translate
            }}</label>
            <span class="tiraspot-result-row__result" aria-labelledby="ai-result-label">
              <span class="poct-result poct-result--{{ result }}" *ngFor="let result of item.AIResults">{{
                result | translate | uppercase
              }}</span>
            </span>
          </div>
          <ng-container *ngIf="!!item.correctionAnalysis?.length">
            <ng-container *ngFor="let analysis of item.correctionAnalysis; let analysisIndex = index">
              <hr />
              <div class="tiraspot-result-row">
                <label class="tiraspot-result-row__title" style="flex: 0 1 200px" id="corrected-result-label">{{
                  'label.correction' | translate
                }}</label>
                <span class="tiraspot-result-row__result" aria-labelledby="corrected-result-label">
                  <span class="poct-result poct-result--{{ result }}" *ngFor="let result of analysis.correctedResults">{{
                    result | translate | uppercase
                  }}</span>
                </span>
                <span class="tiraspot-result-row__user">{{ analysis.analysis.createdBy?.username }}</span>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIfRole="['analyst', 'analystmanager']">
            <div class="flex-row align-items-center mt-2 justify-content-center" *ngIf="item.disagreement">
              <button
                class="btn btn-outline-primary btn-correction"
                (click)="chooseResult(item, 'ai')"
                data-cy="btn-choose-ai-result"
              >
                {{ 'button.choose_AI_result' | translate | uppercase }}
              </button>
              <button
                class="btn btn-outline-primary btn-correction"
                (click)="chooseResult(item, 'user')"
                data-cy="btn-choose-user-result"
              >
                {{ 'button.choose_user_result' | translate | uppercase }}
              </button>
              <button
                class="btn btn-outline-primary btn-correction"
                (click)="chooseResult(item, 'unsure')"
                data-cy="btn-choose-unsure-result"
              >
                {{ 'button.choose_unsure' | translate | uppercase }}
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="{ currentIndex: sampleIndex$ | async } as data">
  <button
    class="btn"
    style="margin: auto"
    (click)="nextSample(-1)"
    [class.btn-outline-primary]="data.currentIndex - 1 < 0"
    [class.btn-primary]="!(data.currentIndex - 1 < 0)"
    data-cy="button-previous-sample"
    [disabled]="data.currentIndex <= 0"
  >
    <i class="ri-arrow-left-s-line"></i>
    {{ 'Previous Sample' | translate | uppercase }}
  </button>
  <button
    class="btn"
    style="margin: auto"
    (click)="nextSample(1)"
    data-cy="button-next-sample"
    [class.btn-outline-primary]="data.currentIndex + 1 >= totalSamples"
    [class.btn-primary]="!(data.currentIndex + 1 >= totalSamples)"
    [disabled]="data.currentIndex + 1 >= totalSamples"
  >
    {{ 'Next Sample' | translate | uppercase }}
    <i class="ri-arrow-right-s-line"></i>
  </button>
</mat-dialog-actions>

<ng-template #thumbnailDialog let-data>
  <div class="flex-column h-100">
    <ts-openseadragon class="flex-grow-1" style="width: 100%; height: 100%" [src]="data.asset" #viewer> </ts-openseadragon>
    <!-- <img [src]="data.asset.thumbnail | preprocess | async" alt="asset thumbnail" /> -->
    <div class="flex-row align-items-center justify-content-around">
      <button mat-icon-button (click)="viewer.zoomOut()" [matTooltip]="'tooltip.zoom_out' | translate">
        <i class="ri-zoom-out-line"></i>
      </button>
      <button mat-icon-button (click)="viewer.zoomIn()" [matTooltip]="'tooltip.zoom_in' | translate">
        <i class="ri-zoom-in-line"></i>
      </button>
      <button mat-icon-button (click)="viewer.rotate(-90)" [matTooltip]="'tooltip.rotate_left' | translate">
        <i class="ri-anticlockwise-line"></i>
      </button>
      <button mat-icon-button (click)="viewer.rotate(90)" [matTooltip]="'tooltip.rotate_right' | translate">
        <i class="ri-clockwise-line"></i>
      </button>
    </div>
  </div>
</ng-template>
