export const TIRASPOT_COLUMNS = [
  {
    name: 'username',
    displayName: 'core.identifier',
    type: 'text',
    filtering: false,
    sorting: true,
    filters: {},
  },
  {
    name: 'created',
    displayName: 'Created',
    type: 'timestamp',
    filtering: true,
    sorting: false,
    filters: {
      name: 'date',
      displayName: 'Date',
      type: 'filter',
      multiple: false,
      options: [
        {
          name: 'newer_than_1_month',
          displayName: '< 1 months',
          type: '<',
          value: {
            newerThan: 1,
            olderThan: null,
          },
          queryParam: 'newerThan',
        },
        {
          name: 'range_1_3_months',
          displayName: '1 to 3 months',
          type: 'range',
          value: { olderThan: 1, newerThan: 3 },
          queryParam: ['oltherThan', 'newerThan'],
        },
        {
          name: 'range_3_6_months',
          displayName: '3 to 6 months',
          type: 'range',
          value: { olderThan: 3, newerThan: 6 },
          queryParam: ['oltherThan', 'newerThan'],
        },
        {
          name: 'older_than_6_months',
          displayName: '> 6 months',
          type: '>',
          value: { olderThan: 6, newerThan: null },
          queryParam: 'oltherThan',
        },
      ],
    },
  },
  {
    name: 'test',
    displayName: 'Test',
    type: 'selection',
    filtering: true,
    sorting: false,
    filters: {
      name: 'test',
      displayName: 'Test',
      type: 'filter',
      multiple: true,
      options: [],
    },
  },
  {
    name: 'user_results',
    displayName: 'User Results',
    type: 'selection',
    filtering: true,
    sorting: false,
    filters: {
      name: 'user_results',
      displayName: 'User Results',
      type: 'filter',
      multiple: true,
      options: [],
    },
  },
  {
    name: 'tiraspot_result',
    displayName: 'Result',
    type: 'selection',
    filtering: true,
    sorting: false,
    filters: {
      name: 'tiraspot_result',
      displayName: 'Result',
      type: 'filter',
      multiple: true,
      options: [],
    },
  },
  {
    name: 'ai_results',
    displayName: 'AI',
    type: 'selection',
    filtering: true,
    sorting: false,
    filters: {
      name: 'ai_results',
      displayName: 'AI Results',
      type: 'filter',
      multiple: true,
      options: [],
    },
  },
];
