import * as models from "./api";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Parse = require("parse");

/* Cloud functions */
export * from "./cloud/cloud-functions";

/* API services */
export * from "./services";

/* Models */
export * from "./api";

/* Utilities */
export * from "./util";

/* Data */
export * from "./api/data/analysis-utils.model";
export * from "./api/data/analysis-data";
export * from "./api/data/analysis-task";
export * from "./api/data/analysis-protocol-task";
export * from "./api/data/asset-roi";
export * from "./api/data/asset-state-name";
export * from "./api/data/asset-status";
export * from "./api/data/i-field-type";
export * from "./api/data/report-details";
export * from "./api/data/task-type";
export * from "./api/data/workflow-state";
export * from "./api/license/i-license-info";
export * from "./api/data/analysisType-utils";
export * from "./api/data/pipeline-task";
export * from "./api/data/special-chars";
export * from "./api/data/label-utils";

/* Core */
export * from "./api/core/keyboard-event";

export {
  Role,
  Installation,
  Query,
  Object as ParseObject,
  File as ParseFile,
  ACL,
} from "parse";

export function configure({
  serverURL,
  appId,
  javascriptKey,
  masterKey,
  liveQueryServerURL,
}: {
  serverURL: string;
  appId: string;
  javascriptKey: string;
  masterKey?: string;
  liveQueryServerURL?: string;
}) {
  Object.keys(models).forEach((k) =>
    Parse.Object.registerSubclass(models[k].className, models[k])
  );
  Parse.initialize(appId, javascriptKey, masterKey);
  Parse.serverURL = serverURL;
  Parse.liveQueryServerURL = liveQueryServerURL;
}

export function fromJSON(
  className: string,
  conditions: { [key: string]: unknown }
): Parse.Query {
  return Parse.Query.fromJSON(className, conditions);
}

export * from "./interfaces";
