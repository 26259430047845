import { Role, RoleRepository } from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";
import { ParseRoleMapper } from "./parse-role.mapper";

export class ParseRoleRepository extends ParseBaseRepository implements RoleRepository {

  private readonly mapper = new ParseRoleMapper(this.parse);

  public async getUserRoles(userId: string): Promise<Role[]> {
    const parseRoles = await new this.parse.Query(this.parse.Role)
      .containedIn('users', [this.parse.User.createWithoutData(userId)])
      .find(this.options);

    return parseRoles.map(pr => this.mapper.toDomain(pr));
  }

  public async list(): Promise<Role[]> {
    const parseRoles = await new this.parse.Query(this.parse.Role).find(this.options);
    return parseRoles.map(pr => this.mapper.toDomain(pr));
  }

  public async save(roles: Role[]): Promise<void> {
    const parseRoles = roles.map(pr => this.mapper.fromDomain(pr));
    await this.parse.Role.saveAll(parseRoles, this.options);
  }

}
