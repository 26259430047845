<div *ngIf="{ users: sampleSecondOpinions$ | async, none: none$ | async } as sampleSecondOpinions; else fetching">
  <ng-container *ngIf="loaded$ | async; else fetching">
    <ng-container *ngIf="sampleSecondOpinions.users?.length; else noOpinions">
      <label>{{ 'label.select_second_user' | translate }}</label>
      <mat-radio-group class="radio-group flex-column">
        <mat-radio-button
          *ngFor="let sampleSecondOpinion of sampleSecondOpinions.users; let index = index"
          [routerLink]="[]"
          [queryParams]="radio.disabled ? {} : { historyId: sampleSecondOpinion?.analysisState.id }"
          [attr.data-cy]="'second-user-' + index"
          [value]="sampleSecondOpinion"
          #radio
          [disabled]="
            sampleSecondOpinion.analysisState.state === 'inProgress' || sampleSecondOpinion.analysisState.state === 'pending'
          "
          [checked]="sampleSecondOpinion.selected"
          class="second-opinion-item"
        >
          <div class="second-opinion-item__user flex-row align-items-center justify-content-between">
            {{ sampleSecondOpinion.analysisState.user?.profile?.alias }}
            <div
              [attr.data-cy]="'second-analysis-state-' + index"
              [class.analyzed]="sampleSecondOpinion.analysisState.state === 'analyzed'"
              [class.in-progress]="sampleSecondOpinion.analysisState.state === 'inProgress'"
              [class.pending]="sampleSecondOpinion.analysisState.state === 'pending'"
            >
              {{ sampleSecondOpinion.analysisState.state | uppercase }}
            </div>
          </div>
          <div class="second-opinion-item__task">
            {{ sampleSecondOpinion.secondOpinion.analysisTypes[0]?.displayName | uppercase }}
          </div>
        </mat-radio-button>
      </mat-radio-group>
      <mat-radio-button
        style="margin-top: 12px"
        [routerLink]="[]"
        [queryParams]="{ historyId: undefined }"
        data-cy="second-opinion-back-analysis"
        [disabled]="sampleSecondOpinions.none"
        value="none"
        [checked]="sampleSecondOpinions.none"
      >
        {{ 'title.back_to_analysis' | translate | uppercase }}
      </mat-radio-button>
    </ng-container>
    <ng-template #noOpinions> {{ 'info.no_second_opinions' | translate }}... </ng-template>
  </ng-container>
</div>
<ng-template #fetching> {{ 'info.fetching' | translate }}... </ng-template>
