<div class="card" *ngIf="!showMethodEditor">
  <div class="card-header flex-row justify-content-between align-items-center">
    <h3>{{ 'title.case_type_method_types' | translate | uppercase }}</h3>
    <span class="button-bar float-right">
      <button class="btn btn-outline-primary" (click)="create()" data-cy="button-add-method-type">
        <i class="ri-add-circle-line"></i>
        {{ 'button.add_new' | translate | uppercase }}
      </button>
    </span>
  </div>

  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <mat-select
        placeholder="{{ 'core.method_types' | translate }}"
        [(value)]="selectedMethods"
        name="selectedMethods"
        multiple
        [compareWith]="compareIds"
        data-cy="select-method-types"
      >
        <mat-option *ngFor="let method of existingMethods$ | async" [value]="method">
          <ng-container [ngSwitch]="method?.assetType?.type">
            <i class="ri-camera-2-line" *ngSwitchCase="'image'"></i>
            <i class="ri-film-line" *ngSwitchCase="'video'"></i>
          </ng-container>
          <span>{{ method.name }}</span>
        </mat-option>
      </mat-select>
    </li>
  </ul>

  <ul id="method-types-list" class="list-group list-group-flush" cdkDropList (cdkDropListDropped)="drop($event)">
    <li class="list-group-item" *ngFor="let method of selectedMethods" cdkDrag>
      <label class="flex-row align-items-center">
        <ng-container [ngSwitch]="method?.assetType?.type">
          <i class="ri-camera-2-line" *ngSwitchCase="'image'"></i>
          <i class="ri-film-line" *ngSwitchCase="'video'"></i>
        </ng-container>
        <span style="padding-left: 12px">{{ method.name | uppercase }}</span>
      </label>
      <span class="button-bar float-right stretch">
        <button
          mat-icon-button
          class="btn btn-flat-primary"
          (click)="edit(method)"
          data-bs-toggle="tooltip"
          [title]="'Edit' | translate"
        >
          <i class="ri-pencil-line"></i>
        </button>
        <button
          mat-icon-button
          class="btn btn-flat-danger"
          (click)="removeMethod(method)"
          data-bs-toggle="tooltip"
          [title]="'Remove' | translate"
        >
          <i class="ri-delete-bin-6-line"></i>
        </button>
      </span>
    </li>
  </ul>
</div>

<ts-method-type-editor *ngIf="showMethodEditor" [method]="method" (saved)="onSavedMethod($event)"> </ts-method-type-editor>
