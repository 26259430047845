<div style="max-width: 80vw; width: 600px">
  <div class="flex flex-row justify-content-center" *ngIf="loading$ | async; else formTpl">
    <i class="ri ri-loader-2-line spin"></i>
  </div>
  <ng-template #formTpl>
    <form [formGroup]="data.form">
      <mat-form-field class="full-width" *ngIf="enableCustomCode">
        <mat-label> {{ 'label.invite_code' | translate }} </mat-label>
        <input matInput type="string" formControlName="code" />
        <mat-hint> {{ 'hint.invite_code' | translate }}</mat-hint>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label> {{ 'core.uses' | translate }} </mat-label>
        <input matInput type="number" formControlName="uses" />
        <mat-hint> {{ 'hint.invite_user_limit' | translate }}</mat-hint>
      </mat-form-field>
      <ng-container *ngIf="{ workspaces: data.workspaces$ | async } as state">
        <mat-form-field class="full-width">
          <mat-label> {{ 'label.invite_initial_workspaces' | translate }}</mat-label>
          <mat-select multiple formControlName="initialWorkspaces">
            <mat-option *ngFor="let workspace of state.workspaces" [value]="workspace">
              {{ workspace.name }}
            </mat-option>
          </mat-select>
          <mat-hint> {{ 'hint.invite_initial_workspaces' | translate }}</mat-hint>
        </mat-form-field>
      </ng-container>
      <label>{{ 'label.invite_initial_roles' | translate }}</label>
      <div class="flex-row align-items-center flex-wrap justify-content-center" style="max-width: 100%">
        <button
          class="btn m-1"
          *ngFor="let role of data.roles$ | async"
          [class.btn-outline-primary]="!data.form.controls['initialRoles'].value.includes(role)"
          [class.btn-primary]="data.form.controls['initialRoles'].value.includes(role)"
          (click)="toggleRole(data.form.controls['initialRoles'], role)"
          data-cy="button-toggle-invite-role"
        >
          {{ role.getName() | translate }}
        </button>
      </div>
      <mat-dialog-actions class="justify-content-center">
        <button class="btn btn-outline-danger m-2" type="cancel" mat-dialog-close data-cy="button-cancel-invite">
          {{ 'button.cancel' | translate }}
        </button>
        <button class="btn btn-primary m-2" type="submit" [mat-dialog-close]="data.form.value" data-cy="button-save-invite">
          {{ 'button.create_invite_code' | translate }}
        </button>
      </mat-dialog-actions>
    </form>
  </ng-template>
</div>
