export interface IBox {
  x: number;
  y: number;
  w?: number;
  h?: number;
  score?: number;
}

export function nonMaxSuppression<T extends IBox>(
  boxes: T[],
  returnDiscarded: boolean = false
): { results: T[]; discarded?: T[] } {
  const results = boxes?.[0]?.['score'] ? boxes.sort((b1, b2) => b2.score - b1.score) : [...boxes];
  let hsIndex = 0;
  const discarded: T[] = returnDiscarded ? [] : undefined;
  while (hsIndex < results.length - 1) {
    const refBox = results[hsIndex];
    let overlapIndex = 1;
    while (hsIndex + overlapIndex < results.length) {
      const overlappingBox = results[hsIndex + overlapIndex];
      if (_intersection(refBox, overlappingBox) >= 0.5) {
        if (returnDiscarded) discarded.push(overlappingBox);
        results.splice(hsIndex + overlapIndex, 1);
      } else {
        overlapIndex++;
      }
    }
    hsIndex++;
  }
  return {
    results,
    discarded,
  };
}

function _intersection<T extends IBox>(b1: T, b2: T): number {
  const intersectionArea =
    Math.abs(Math.min(b1.x + b1.w, b2.x + b2.w) - Math.max(b1.x, b2.x)) *
    Math.abs(Math.min(b1.y + b1.h, b2.y + b2.h) - Math.max(b1.y, b2.y));
  return intersectionArea / (Math.abs(b2.w * b2.h) + Math.abs(b1.w * b1.h) - intersectionArea);
}
