import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { InviteCodeResolver, LogoutResolver, UserResolver } from '@telespot/web-core';
import { NavigateBackDirectiveModule } from '@telespot/shared/util';
import { GdprNoticeDialogModule } from '@telespot/ui';

import { LoginComponent } from './components/login/login.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { RegisterComponent } from './components/register/register.component';
import { UserRegisterComponent } from './components/user-register/user-register.component';
import { InviteCodeGuard } from './guards/invite-code.guard';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'login',
        component: LoginComponent,
        resolve: { r: LogoutResolver },
        runGuardsAndResolvers: 'always',
        data: { title: 'Telespot login' },
      },
      {
        path: 'logout',
        redirectTo: 'login',
      },
      {
        path: 'verify',
        resolve: { userVerificationData: UserResolver },
        component: RegisterComponent,
        data: { title: 'User verification' },
      },
      {
        path: 'register',
        component: UserRegisterComponent,
        resolve: { inviteCode: InviteCodeResolver, r: LogoutResolver },
        data: { title: 'Telespot registration' },
        canActivate: [InviteCodeGuard],
      },
      {
        path: 'reset',
        component: RegisterComponent,
        resolve: {
          passwordResetData: UserResolver,
        },
      },
      {
        path: 'password-reset',
        component: PasswordResetComponent,
        data: {
          title: 'Reset password',
        },
      },
      {
        // See https://w3c.github.io/webappsec-change-password-url/
        path: '.well-known/change-password',
        redirectTo: '/password-reset',
      },
    ]),
    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    NavigateBackDirectiveModule,
    GdprNoticeDialogModule,
  ],
  declarations: [LoginComponent, UserRegisterComponent, RegisterComponent, PasswordResetComponent],
})
export class TelespotAuthFeatureModule {}
