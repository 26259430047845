import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

import { SplDataSource } from "../../../models/spl-data-source";

@Component({
  selector: "ts-table-decryption",
  templateUrl: "./table-decryption.component.html",
  styleUrls: ["./table-decryption.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableDecryptionComponent<T> implements OnInit {
  @Input() isAdmin = false;
  @Input() dataSource: SplDataSource<T>;
  @Input() isEncrypted = false;
  @Output() decryptCases = new EventEmitter<boolean>();

  ngOnInit(): void {
    if (this.isEncrypted) {
      const icon = document.getElementById("iconBtn");
      if (icon.classList.value === "ri-lock-line") {
        icon.classList.remove("ri-lock-line");
        icon.classList.add("ri-lock-unlock-line");
      }
    }
  }

  public async encryptDecrypt(clicked: boolean) {
    if ((!this.isEncrypted && clicked) || (this.isEncrypted && !clicked)) {
      this.decryptCases.emit(true);
    } else {
      this.decryptCases.emit(false);
    }
  }

  public changeIcon() {
    const icon = document.getElementById("iconBtn");

    if (icon.classList.value === "ri-lock-line") {
      icon.classList.remove("ri-lock-line");
      icon.classList.add("ri-lock-unlock-line");
    } else {
      icon.classList.remove("ri-lock-unlock-line");
      icon.classList.add("ri-lock-line");
    }
  }
}
