<div class="header" data-cy="confirmation-dialog-title">
  <h3 mat-dialog-title>{{ data?.title | translate : data?.title_data }}</h3>
</div>
<mat-dialog-content
  *ngIf="{ progress: progress$ | async, text: text$ | async } as state"
>
  <mat-progress-bar
    [value]="state.progress"
    *ngIf="state.progress"
  ></mat-progress-bar>
  <div class="flex-row align-items-center overflow-hidden">
    <i
      class="ri-refresh-line spin"
      *ngIf="data?.showLoadingSpinner"
      style="margin: auto; flex: 0 1 auto"
    ></i>
    <div style="flex: 1 1 auto; margin-left: 6px" *ngIf="!!state.text">
      {{ state.text | translate : data?.params }}
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  class="custom-dialog-actions justify-content-around"
  *ngIf="
    data.cancelButtonText || data.dontSaveButtonText || data.acceptButtonText
  "
>
  <button
    #btnCancel
    data-cy="dialog-button-cancel"
    mat-dialog-close
    class="btn m-2 flex-grow-1"
    focused
    [class.btn-outline-danger]="data.defaultAction !== 'cancel'"
    [class.btn-danger]="data.defaultAction && data.defaultAction === 'cancel'"
    (click)="cancel()"
  >
    {{ data?.cancelButtonText | translate | uppercase }}
  </button>
  <button
    #btnDiscard
    data-cy="dialog-button-discard"
    mat-dialog-close
    class="btn m-2 flex-grow-1"
    [class.btn-outline-primary]="data.defaultAction !== 'discard'"
    [class.btn-primary]="data.defaultAction && data.defaultAction === 'discard'"
    *ngIf="data?.dontSaveButtonText"
    (click)="discard()"
  >
    {{ data?.dontSaveButtonText | translate | uppercase }}
  </button>
  <button
    #btnAccept
    data-cy="dialog-button-accept"
    mat-dialog-close
    class="btn m-2 flex-grow-1"
    [class.btn-outline-primary]="data.defaultAction !== 'accept'"
    [class.btn-primary]="data.defaultAction && data.defaultAction === 'accept'"
    *ngIf="data?.acceptButtonText"
    (click)="accept()"
    color="primary"
  >
    {{ data?.acceptButtonText | translate | uppercase }}
  </button>
</mat-dialog-actions>
