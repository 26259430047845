import { Component } from "@angular/core";
import {
  AnalysisReviewService,
  IAnalystData,
  SampleAnalysisService,
} from "@telespot/analysis-refactor/data-access";
import { combineLatest, Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";

@Component({
  selector: "ts-second-opinion-selector",
  templateUrl: "./second-opinion-selector.component.html",
  styleUrls: ["./second-opinion-selector.component.scss"],
  providers: [AnalysisReviewService],
})
export class SecondOpinionSelectorComponent {
  loaded$ = new Subject<boolean>();

  sampleSecondOpinions$: Observable<IAnalystData[]> = combineLatest([
    this._analysisReviewService.availableSecondOpinions$,
    this._sampleAnalysisService.selectedAnalysisState$,
  ]).pipe(
    tap(() => this.loaded$.next(false)),
    map(([_samples, _analysisState]) => {
      return _samples.map((data) => ({
        analysisState: data.analysisState,
        selected: _analysisState?.id === data.analysisState.id,
        secondOpinion: data.secondOpinion,
      }));
    }),
    tap(() => this.loaded$.next(true))
  );
  none$ = this._sampleAnalysisService.isViewMode$.pipe(
    map((_isViewMode) => !_isViewMode)
  );

  constructor(
    private _sampleAnalysisService: SampleAnalysisService,
    private _analysisReviewService: AnalysisReviewService
  ) {}
}
