import type Parse from "parse";

import { Member } from "@telespot/domain";
import { ParseUserMapper } from "../accounts";
import { ParseMapper } from "../../parse.mapper";
import { WorkspaceTopology } from "./parse-workspace.mapper";
import { ObjectTopology } from "../../parse.topology";

export enum MemberTopology {
  TABLE = "Member",
  USER = "user",
  WORKSPACE = "workspace",
}

export class ParseMemberMapper extends ParseMapper<Member> {

  private readonly userMapper = new ParseUserMapper(this.parse);

  public toDomain(parseObject: Parse.Object): Member {
    return new Member({
      id: parseObject.id,
      user: this.userMapper.toDomain(parseObject.get(MemberTopology.USER)),
      workspaceId: parseObject.get(MemberTopology.WORKSPACE).id,
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
    });
  }

  public fromDomain(member: Member): Parse.Object {
    const user = this.parse.User.createWithoutData(member.user.id);

    const workspace = this.subclasses
      .getSubclass(WorkspaceTopology.TABLE)
      .createWithoutData(member.workspaceId);

    const parseObject = this.parse.Object.fromJSON({
      className: MemberTopology.TABLE,
      [ObjectTopology.ID]: member.id,
      [ObjectTopology.CREATED_AT]: member.createdAt,
      [ObjectTopology.UPDATED_AT]: member.updatedAt,
    });

    parseObject.set({
      [MemberTopology.USER]: user,
      [MemberTopology.WORKSPACE]: workspace,
    });

    return parseObject;
  }
}
