import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { ICookieItem, ICookiesGroup } from '../../config';
import { CookieConsentService } from '../../services/cookie-consent.service';

@Component({
  selector: 'ts-cookie-consent-dialog',
  templateUrl: './cookie-consent-dialog.component.html',
  styleUrls: ['./cookie-consent-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('marker', [
      state(
        'true',
        style({
          transform: 'rotate(0deg)',
        })
      ),
      state(
        'false',
        style({
          transform: 'rotate(90deg)',
        })
      ),
      transition('false <=> true', animate('0.2s')),
    ]),
    trigger('collapse', [
      transition(':enter', [
        style({
          height: '0px',
          overflow: 'hidden',
        }),
        animate('0.2s'),
      ]),
      transition(':leave', [
        animate('0.2s'),
        style({
          height: '0px',
          overflow: 'hidden',
        }),
      ]),
    ]),
  ],
})
export class CookieConsentDialogComponent {
  items = this.cookiesService.cookieGroups;

  customize: boolean = JSON.parse(localStorage.getItem('experiments_customize_cookies') ?? 'false');

  customized: boolean = this.cookiesService.hasUserPreferences();
  constructor(private cookiesService: CookieConsentService, private dialog: MatDialogRef<any>) {}

  rejectAll() {
    this.items.forEach((item) => this.toggleGroup(false, item));
    this.submit();
  }

  acceptAll() {
    this.items.forEach((item) => this.toggleGroup(true, item));
    this.submit();
  }

  toggleGroup(change: MatSlideToggleChange | boolean, group: ICookiesGroup) {
    const checked = change instanceof MatSlideToggleChange ? change.checked : change;
    group.allAccepted = group.allAccepted || checked;
    group.items = [...group.items.map((item) => ({ ...item, accepted: checked }))];
    this.customized = true;
  }

  toggle(change: MatSlideToggleChange, group: ICookiesGroup, item: ICookieItem) {
    item.accepted = change.checked;
    group.allAccepted = group.allAccepted && change.checked;
    this.customized = true;
  }

  submit() {
    for (const group of this.items) {
      for (const item of group.items) {
        localStorage.setItem(item._key, item.accepted.toString());
        switch (item.type) {
          case 'google_analytics':
            this.cookiesService.setGoogleAnalyticsEnabled(item.accepted);
            break;
          case 'fullstory':
            this.cookiesService.setFullstoryEnabled(item.accepted);
            break;
        }
      }
    }
    this.cookiesService.saveUserPreferences();
    this.dialog.close();
  }
}
