import type Parse from "parse";

import { AnalysisType } from "@telespot/domain";
import { ParseMapper } from "../../parse.mapper";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

export enum AnalysisTypeTopology {
  TABLE = "AnalysisType",
  NAME = "name",
  DETAILS = "details",
  DESCRIPTION = "description",
  TASKS = "tasks",
  MOBILE = "mobile",
}

export class ParseAnalysisTypeMapper extends ParseMapper<AnalysisType> {

  public toDomain(parseObject: Parse.Object): AnalysisType {
    return new AnalysisType({
      id: parseObject.id,
      name: parseObject.get(AnalysisTypeTopology.NAME),
      details: parseObject.get(AnalysisTypeTopology.DETAILS),
      description: parseObject.get(AnalysisTypeTopology.DESCRIPTION),
      tasks: parseObject.get(AnalysisTypeTopology.TASKS),
      mobile: parseObject.get(AnalysisTypeTopology.MOBILE),
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
      deletedAt: parseObject.get(SoftDeletableTopology.DELETED_AT),
    });
  }

  public fromDomain(analysisType: AnalysisType): Parse.Object {
    const parseObject = this.parse.Object.fromJSON({
      className: AnalysisTypeTopology.TABLE,
      [ObjectTopology.ID]: analysisType.id,
      [ObjectTopology.CREATED_AT]: analysisType.createdAt,
      [ObjectTopology.UPDATED_AT]: analysisType.updatedAt,
    });

    parseObject.set({
      [SoftDeletableTopology.DELETED_AT]: analysisType.deletedAt,
      [AnalysisTypeTopology.NAME]: analysisType.name,
      [AnalysisTypeTopology.DETAILS]: analysisType.details,
      [AnalysisTypeTopology.TASKS]: analysisType.tasks,
      [AnalysisTypeTopology.MOBILE]: analysisType.mobile,
      [AnalysisTypeTopology.DESCRIPTION]: analysisType.description,
    })

    return parseObject;
  }
}
