import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@telespot/shared/environment';
import { LoggerService } from '@telespot/shared/logger/feature/util';

interface IUploadResponse {
  file: string;
  links: {
    upload: {
      href: string;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class FileUploaderService {
  constructor(private _http: HttpClient, private _logger: LoggerService) {}

  async upload(file: File, endpoint: 'files' | 'support-files'): Promise<string> {
    this._logger.info(`[uploader-service] Uploading file ${file?.name} to /${endpoint}...`);
    try {
      const response = await this._http
        .post<IUploadResponse>(`${environment.api.url}/${endpoint}/`, { file: file.name })
        .toPromise();
      try {
        await this._http.put(response.links.upload.href, file).toPromise();
        return Promise.resolve(response.file);
      } catch (initError) {
        this._logger.fatal(`[uploader-service] Could not upload file: ${initError.message}`, initError);
        throw initError;
      }
    } catch (uploadError) {
      this._logger.fatal(`[uploader-service] Could not initiate upload: ${uploadError.message}`, uploadError);
      throw uploadError;
    }
  }
}
