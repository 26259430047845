import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { AnalysisTask } from "@telespot/sdk";
import { Subject } from "rxjs";

@Component({
  selector: "ts-analysis-input",
  templateUrl: "./analysis-input.component.html",
  styleUrls: ["./analysis-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisInputComponent implements OnDestroy {
  viewAll = true;

  private _task: AnalysisTask;
  @Input() set analysisTask(analysisTask: AnalysisTask) {
    this._task = analysisTask;
  }
  get analysisTask() {
    return this._task;
  }

  private _form: UntypedFormGroup;
  @Input()
  set form(form: UntypedFormGroup) {
    this._form = form;
    this.changeDetector.detectChanges();
  }
  get form() {
    return this._form;
  }

  @Input() mobile: boolean;

  constructor(private changeDetector: ChangeDetectorRef) {}

  private _destroy$ = new Subject<void>();
  ngOnDestroy() {
    this._destroy$.next();
  }

  toggleViewAllROIs() {
    this.viewAll = !this.viewAll;
  }
}
