<div
  class="full-width mobile-analysis-panel"
  *ngIf="{
    groups: tasksGroups$ | async,
    mode: mode$ | async,
    context: context$ | async
  } as state"
>
  <mat-toolbar>
    <button
      mat-icon-button
      [color]="state.mode === 'other' ? 'primary' : undefined"
      (click)="setMode('other')"
      data-cy="tab-nonroi"
    >
      <i class="ri-text"></i>
    </button>
    <button
      data-cy="tab-roi"
      mat-icon-button
      [color]="state.mode === 'rois' ? 'primary' : undefined"
      (click)="setMode('rois')"
      [disabled]="state.context === 'sample'"
    >
      <i class="ri-dashboard-line"></i>
    </button>
    <ng-container *ngIf="state.mode === 'rois'">
      <span class="vd"></span>
      <ts-viewer-tools [mobile]="true"></ts-viewer-tools>
    </ng-container>

    <span class="spacer"></span>

    <button
      mat-icon-button
      *ngIf="state.mode === 'rois'"
      (click)="erase()"
      data-cy="tool-delete-rois"
    >
      <i class="ri-eraser-line"></i>
    </button>
    <button
      mat-icon-button
      color="primary"
      (click)="close()"
      data-cy="tool-close-panel"
    >
      <i class="ri-check-line"></i>
    </button>
  </mat-toolbar>
  <mat-divider></mat-divider>
  <div class="mobile-analysis-content" *ngIf="state.context">
    <div
      [class.mobile-tag-selector-bar]="state.mode === 'rois'"
      [class.mobile-other-panel]="state.mode === 'other'"
    >
      <ts-analysis-form
        *ngFor="let group of state.groups"
        [findings]="getActiveFindings(group.id) | async"
        [analysis]="getActiveAnalysis(group.id) | async"
        [direction]="state.mode === 'rois' ? 'horizontal' : 'vertical'"
        [mobile]="state.mode === 'rois'"
        [taskFilter]="taskFilter"
        [tasks]="group.tasks"
      ></ts-analysis-form>
    </div>
  </div>
</div>
