export interface IAnalyzeConfig {
  model: string;
  detectionStep?: number;
  patchSize?: number;
  downsample?: number;
  batchSize?: number;
  threshold?: number;
  instances: {
    image: string;
  }[];
}

export const defaultAnalyzeConfig: IAnalyzeConfig = {
  model: 'malaria-3l-ps32',
  // detectionStep: 32,
  // patchSize: 64,
  // downsample: 2,
  // batchSize: 50,
  instances: [],
  threshold: 0.1
};
