import { ReportDetails } from '../data/report-details';
import { Object as ParseObject } from 'parse';
import { Case } from '../case/case.model';
import { User } from '../user/user.model';
import { IWorkflowState } from '../data/workflow-state';

export class Report extends ParseObject {
  static className = 'Report';

  constructor() {
    super(Report.className);
    this.data = {};
  }

  set case(_case: Case) {
    this.set('case', _case);
  }
  get case(): Case {
    return this.get('case');
  }

  get createdBy(): User {
    return this.get('createdBy');
  }

  get currentState(): IWorkflowState {
    return this.get('state');
  }

  get data(): ReportDetails {
    return this.get('data') || {};
  }

  set data(data: ReportDetails) {
    this.set('data', data);
  }
}
