import { environment } from "@telespot/shared/environment";
import { Object as ParseObject } from "parse";

import { CaseType } from "../case-type/case-type.model";
import { Organization } from "../organization/organization.model";
import { User } from "../user/user.model";

interface IWorkspaceConfig {
  environment?: "tiraspot";
}

export class Workspace extends ParseObject {
  static className = "Workspace";

  /**
   * Creates an instance of Workspace.
   * @memberof Workspace
   */
  constructor({
    name,
    caseType,
    organization,
  }: { name?: string; caseType?: CaseType; organization?: Organization } = {}) {
    super(Workspace.className);
    this.name = name;
    this.caseType = caseType;
    this.organization = organization;
  }

  /**
   *
   *
   * @type {string}
   * @memberof Workspace
   */
  get name(): string {
    return this.get("name");
  }
  set name(newName: string) {
    this.set("name", newName);
  }

  /**
   *
   *
   * @type {CaseType}
   * @memberof Workspace
   */
  get caseType(): CaseType {
    return this.get("caseType");
  }
  set caseType(newCaseType: CaseType) {
    this.set("caseType", newCaseType);
  }

  /**
   *
   *
   * @readonly
   * @type {number}
   * @memberof Workspace
   */
  get numMembers(): number {
    return this.get("numMembers");
  }

  /**
   *
   *
   * @readonly
   * @type {number}
   * @memberof Workspace
   */
  get numCases(): number {
    return this.get("numCases");
  }

  /**
   *
   *
   * @readonly
   * @type {User}
   * @memberof Workspace
   */
  get createdBy(): User {
    return this.get("createdBy");
  }

  /**
   *
   *
   * @readonly
   * @type {Organization}
   * @memberof Workspace
   */
  get organization(): Organization {
    return this.get("organization");
  }
  set organization(newOrganization: Organization) {
    this.set("organization", newOrganization);
  }

  get usedStorage(): number {
    return this.get("usedStorage") || 0;
  }

  get downloadUrl(): string {
    return `${environment.api.url}/export/workspace/${this.id}`;
  }

  get config(): IWorkspaceConfig {
    return this.get("config");
  }

  set config(config: Partial<IWorkspaceConfig>) {
    this.set("config", { ...this.config, ...config });
  }

  /** ACTIONS OVER THE WORKSPACES */

  public updateCases(numCases: number): Workspace {
    this.set("numCases", numCases);

    return this;
  }
}
