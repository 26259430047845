<div class="main-container">
  <div
    class="compatibility-warning"
    *ngIf="existingCaseCount !== 0"
    data-cy="case-type-inuse"
  >
    {{ "warning.compatibility" | translate : { caseCount: existingCaseCount } }}
  </div>
  <ng-container *ngIf="form">
    <ts-stepper [hidden]="secondaryEditorVisible">
      <div step fragment="description">
        <div class="card">
          <div class="card-header">
            <h5>{{ "input.case_type_description" | translate | uppercase }}</h5>
          </div>
          <div class="card-body" style="padding-top: 30px" *ngIf="form">
            <form
              #caseForm="ngForm"
              id="caseTypeForm"
              [formGroup]="form"
              (ngSubmit)="saveCaseType()"
            >
              <mat-form-field class="full-width">
                <input
                  matInput
                  autofocus
                  placeholder="{{
                    'input.case_type_name' | translate | uppercase
                  }}"
                  formControlName="name"
                  name="name"
                />
                <mat-error *ngIf="form.controls['name'].invalid">{{
                  form.controls["name"].getError("invalidName")
                }}</mat-error>
              </mat-form-field>

              <mat-form-field class="full-width">
                <textarea
                  matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  placeholder="{{
                    'help.casetype_description' | translate | uppercase
                  }}"
                  formControlName="description"
                  name="description"
                ></textarea>
              </mat-form-field>
            </form>
          </div>
        </div>
      </div>
      <div step fragment="fields">
        <ts-field-types
          [caseFields]="form.value.fields"
          *ngIf="!showFieldEditor"
          (caseFieldsOrderChanged)="updateCaseFieldsOrder($event)"
          (caseFieldsSelectedForEditing)="
            selectedFieldType = $event;
            showFieldEditor = true;
            secondaryEditorVisible = true
          "
        ></ts-field-types>
        <!-- Editor component -->
        <ng-container *ngIf="showFieldEditor">
          <ts-field-type-editor
            [ngModel]="selectedFieldType"
            [fieldCollection]="form.controls['fields'].value"
            (ngModelChange)="addField($event); secondaryEditorVisible = false"
            (cancelled)="
              selectedFieldType = undefined;
              showFieldEditor = false;
              secondaryEditorVisible = false
            "
          >
          </ts-field-type-editor>
        </ng-container>
      </div>
      <div step fragment="methodTypes">
        <ts-method-types-assignment
          [methods]="form.value.methodTypes"
          (methodOrderChanged)="updateCaseMethodsList($event)"
          (methodEditorToggle)="updateVisualizer($event)"
        >
        </ts-method-types-assignment>
      </div>
      <div step fragment="workspaces">
        <ts-workspace-assignation-editor
          [workspaces]="workspaces$ | async"
          [caseType]="caseType"
          [tableConfig]="tableConfig"
          [dataSource]="dataSource"
        >
        </ts-workspace-assignation-editor>
      </div>
      <div class="button-bar align-right" *ngIf="!secondaryEditorVisible">
        <button
          class="btn btn-outline-primary btn-outline--white-bg"
          (click)="cancel()"
          data-cy="button-cancel-case-type"
        >
          <i class="ri-close-fill"></i>
          {{ "button.cancel" | translate | uppercase }}
        </button>
        <button
          class="btn btn-primary"
          (click)="saveCaseType()"
          [disabled]="!form.dirty"
          data-cy="button-save-case-type"
        >
          <i class="ri-save-line"></i>
          {{ "button.save_case_type" | translate | uppercase }}
        </button>
      </div>
      <div
        *ngIf="form.errors?.server"
        class="text-danger flex-row align-items-center p-2"
      >
        <i class="ri ri-close-circle-line"></i>
        {{ "label.server_error" | translate }}: "{{ form.errors.server }}"
      </div>
    </ts-stepper>
  </ng-container>
</div>
