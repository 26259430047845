import type Parse from "parse";

import { DeviceType } from "@telespot/domain";

import { ParseMapper } from "../../parse.mapper";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

export enum DeviceTypeTopology {
  TABLE = "DeviceType",
  NAME = "name",
}

export class ParseDeviceTypeMapper extends ParseMapper<DeviceType> {

  public toDomain(object: Parse.Object): DeviceType {
    return new DeviceType({
      id: object.id,
      name: object.get(DeviceTypeTopology.NAME),
      createdAt: object.get(ObjectTopology.CREATED_AT),
      updatedAt: object.get(ObjectTopology.UPDATED_AT),
      deletedAt: object.get(SoftDeletableTopology.DELETED_AT),
    });
  }

  public fromDomain(domain: DeviceType): Parse.Object {
    const parseObject = this.parse.Object.fromJSON({
      className: DeviceTypeTopology.TABLE,
      [ObjectTopology.ID]: domain.id,
      [ObjectTopology.CREATED_AT]: domain.createdAt,
      [ObjectTopology.UPDATED_AT]: domain.updatedAt,
      [SoftDeletableTopology.DELETED_AT]: domain.deletedAt,
    })

    parseObject.set({
      [DeviceTypeTopology.NAME]: domain.name,
    });

    return parseObject;
  }
}
