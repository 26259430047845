import { SessionItem, SessionStatus } from "@telespot/domain";
import { IListSessionsHandler, ListSessions } from "@telespot/use-cases";

import { UserTopology, SessionTopology, ParseSessionMapper } from "../../repositories";
import { ParseBaseRepository } from "../../parse-base.repository";
import { ObjectTopology } from "../../parse.topology";

/**
 * @group Authentication & Authorization
 * @category Handlers
 */
export class ListSessionsHandler extends ParseBaseRepository implements IListSessionsHandler {

  public async execute(command: ListSessions): Promise<SessionItem[]> {

    const { user, status, limit, nextToken } = command.details;

    const currentTime = new Date();

    const usernameQuery = new this.parse.Query(this.parse.User).equalTo(UserTopology.USERNAME, user);
    const userEmailQuery = new this.parse.Query(this.parse.User).equalTo(UserTopology.EMAIL, user);
    const userQuery = this.parse.Query.or(usernameQuery, userEmailQuery);

    const sessionQuery = new this.parse.Query(this.parse.Session)
      .matchesQuery(SessionTopology.USER, userQuery)
      .descending(ObjectTopology.CREATED_AT)
      .ascending(ObjectTopology.ID)
      .limit(limit);

    if (nextToken) sessionQuery.greaterThan(ObjectTopology.ID, nextToken.sessionId);

    switch (status) {
      case SessionStatus.ACTIVE:
        sessionQuery.greaterThanOrEqualTo("expiresAt", currentTime);
        break;
      case SessionStatus.EXPIRED:
        sessionQuery.lessThan("expiresAt", currentTime);
        break;
      case SessionStatus.ANY:
        break;
      default:
        throw new Error(`Unknow status provided: ${status}`);
    }

    const parseSessions = await sessionQuery.find(this.options);

    return parseSessions.map(ParseSessionMapper.toView);
  }

}
