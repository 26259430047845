import { AnalyzerEntity } from "./analysis";
import { Entity, SoftDeletable } from "./entity";
import { StepTask } from "./pipeline-step";

export interface TextSpecification {
  value: string;
}

export interface ClassificationSpecification {
  options: {
    [key: string]: number;
  };
}

export interface ROISpecification {
  x0: number;
  y0: number;
  x1?: number;
  y1?: number;
  labels: { [key: string]: number };
}
export interface IFindingData {
  content:
    | TextSpecification[]
    | ClassificationSpecification[]
    | ROISpecification[];
}
export interface FindingParameters extends Entity, SoftDeletable {
  creatorId: string;
  creatorEntity: AnalyzerEntity;
  analysisId: string;
  pipelineStepId: string;
  data: IFindingData;
  type: StepTask;
  version: number;
  originId: string;
  pipelineId: string;
  assetId: string;
  uuid: string;
}

export class Finding implements Entity, SoftDeletable {
  constructor(private attributes: FindingParameters) {}

  public get id(): string | undefined {
    return this.attributes.id;
  }

  public get creatorId(): string {
    return this.attributes.creatorId;
  }

  public get creatorEntity(): AnalyzerEntity {
    return this.attributes.creatorEntity;
  }

  public get analysisId(): string {
    return this.attributes.analysisId;
  }

  public get pipelineStepId(): string {
    return this.attributes.pipelineStepId;
  }

  public get data(): IFindingData {
    return this.attributes.data;
  }

  public get type(): StepTask {
    return this.attributes.type;
  }

  public get version(): number {
    return this.attributes.version;
  }

  public get originId(): string {
    return this.attributes.originId;
  }

  public get assetId(): string {
    return this.attributes.assetId;
  }

  public get pipelineId(): string {
    return this.attributes.pipelineId;
  }

  public get uuid(): string {
    return this.attributes.uuid;
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }

  public get deletedAt(): Date {
    return this.attributes.deletedAt;
  }

  public updateAnalysisId(value: string): Finding {
    this.attributes.analysisId = value;
    return this;
  }
}
