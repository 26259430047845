import { Component, Input, OnDestroy, AfterViewInit } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  AnalysisHotkeysService,
  hideLabel,
  pinTaskOption,
  updateOptionFromCounter,
  RoiService,
  selectedLabels,
  selectLabel,
  selectMultipleSelection,
  selectROILabelCountFromActiveROIs,
  setCollapseOption,
  addCustomLabel,
} from "@telespot/analysis-refactor/data-access";
import {
  AnalysisProtocolTask,
  StepTask,
  TAnalysisModelItem,
  TRoiSelectionType,
  TaskOption,
} from "@telespot/sdk";
import {
  TOsdActiveAction,
  ViewerService,
} from "@telespot/shared/viewers/data-access";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NewClassDialogComponent } from "../dialogs/new-class-dialog/new-class-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "ts-roi-selection",
  templateUrl: "./roi-selection.component.html",
  styleUrls: ["./roi-selection.component.scss"],
})
export class RoiSelectionComponent implements OnDestroy, AfterViewInit {
  @Input() isViewMode: boolean;
  @Input() task: { analysisTask: AnalysisProtocolTask; pipelineId: string };
  @Input() layout: "full" | "mobile" = "full";

  public readonly multipleSelection$ = this.store.select(
    selectMultipleSelection
  );

  public readonly selectedLabels$ = this.store.select(selectedLabels);
  public readonly selectedROIS$ = this._roiService.selectedRois$;

  private _destroy$ = new Subject<void>();
  private _selectedLabels;
  private _selectedROIs;
  private initialRender = true;

  constructor(
    private store: Store,
    private _hotkeysService: AnalysisHotkeysService,
    private _roiService: RoiService,
    private _viewerService: ViewerService,
    private dialog: MatDialog
  ) {
    this.selectedLabels$
      .pipe(takeUntil(this._destroy$))
      .subscribe((selectedLabels) => (this._selectedLabels = selectedLabels));
    this.selectedROIS$
      .pipe(takeUntil(this._destroy$))
      .subscribe((selectedRois) => (this._selectedROIs = selectedRois));
  }

  ngAfterViewInit(): void {
    this.initialRender = false;
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  public selectOption(option: TaskOption) {
    if (this.isViewMode) return;
    this.store.dispatch(selectLabel({ uuid: option.uuid }));
    if (
      this._viewerService.currentViewerMode !== TOsdActiveAction.drawing &&
      this._selectedROIs.length === 0
    ) {
      this._viewerService.toggleViewerMode(TOsdActiveAction.drawing);
      this._viewerService.toggleDrawingMode(
        this.task.analysisTask.type === StepTask.POSITION
          ? TRoiSelectionType.center
          : TRoiSelectionType.boundingBox
      );
    }
  }

  public setPinnedOption(option: TaskOption) {
    this.store.dispatch(pinTaskOption({ uuid: option.uuid }));
  }

  public updateLabelsForCounter(labelId: string) {
    this.store.dispatch(
      updateOptionFromCounter({
        labelId,
      })
    );
  }

  public toggleOptionVisibility(option: TaskOption) {
    this.store.dispatch(hideLabel({ uuid: option.uuid }));
  }

  public getModelShortcut(option: TAnalysisModelItem): number {
    return this._hotkeysService.getModelHotkey(
      option.name,
      this.task.analysisTask.name
    );
  }
  public getModelColor(labelId: string) {
    return this._roiService.getModelColor([labelId], true);
  }

  public getModelCount(labelId: string) {
    return this.store.select(selectROILabelCountFromActiveROIs(labelId));
  }

  public isOptionSelected(
    option: TaskOption,
    multipleSelectionEnabled: boolean
  ) {
    return multipleSelectionEnabled
      ? this._selectedLabels.some((label) => label.uuid === option.uuid)
      : this._selectedLabels[0]?.uuid === option.uuid;
  }

  public isOptionDisabled(pipelineId, multipleMode) {
    if (!multipleMode) return this.isViewMode;
    const incompatibleCombination = this._selectedLabels.some(
      (label) => label.pipelineId !== pipelineId
    );
    return this.isViewMode || incompatibleCombination;
  }

  public onTaskClosed(task: string, collapsed: boolean) {
    if (!this.initialRender) {
      this.store.dispatch(
        setCollapseOption({
          task,
          collapsed,
          pipelineId: this.task.pipelineId,
        })
      );
    }
  }
  openNewClassDialog() {
    const dialogRef = this.dialog.open(NewClassDialogComponent, {
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          addCustomLabel({
            customLabel: {
              value: result,
              taskId: this.task.analysisTask.stepId,
              pipelineId: this.task.pipelineId,
            },
          })
        );
      }
    });
  }
}
