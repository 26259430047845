import { RetryDecider } from "@telespot/core";

export enum ErrorTypes {
  CONNECTION_ERROR = "XMLHttpRequest failed",
  TIMEOUT_ERROR = "Request timeout",
  CONNECTION_RESET = "ECONNRESET",
}

export class ParseRetryDecider implements RetryDecider {
  public retryable(error: Error): boolean {
    switch (true) {
      case error.message.includes(ErrorTypes.CONNECTION_ERROR): return true;
      case error.message.includes(ErrorTypes.TIMEOUT_ERROR): return true;
      case error.message.includes(ErrorTypes.CONNECTION_RESET): return true;
      default: return false;
    }
  }
}
