export interface IParseObjectField {
  details: ICaseFieldDetails;
  name: string;
  required?: boolean;
  type: TFieldTypeName;
  encrypted?: boolean;
}

export enum TFieldTypeName {
  label = 'label',
  textedit = 'textedit',
  number = 'number',
  boolean = 'boolean',
  switcher = 'switcher',
  selection = 'selection',
  date = 'date',
  location = 'location',
  case_name = 'case_name',
  sectionHeader = 'sectionHeader'
}

export interface ICaseFieldDetails {
  display_name?: string;
  options?: string[];
  placeholder?: string;
  min?: Date | number;
  max?: Date | number;
  multiple?: boolean;
  multiline?: boolean;
  max_lines?: number;
  max_length?: number;
  decimals?: number;
  default?: Date | string | number;
  show_map?: boolean;
  range?: boolean;
  units?: string;
}

export interface IField {
  name: string;
  required: boolean;
  type: TFieldTypeName;
  encrypted?: boolean;
  details: ICaseFieldDetails;
}
