import { Injectable } from "@angular/core";
import { Dictionary } from "@ngrx/entity";
import { DataService, FindingFileService } from "@telespot/web-core";
import {
  Algorithms,
  Analysis,
  AnalysisState,
  AnalysisUtils,
  Asset,
  Case,
  Query,
  Sample,
  User,
} from "@telespot/sdk";
import { from, Observable } from "rxjs";
import { Change, IAnalysis, Label, Mode, POI, ROI } from "../../state";
import { AnalysisMapper } from "./analysis.mapper";

@Injectable({
  providedIn: "any",
})
export class AnalysisService {
  constructor(
    private dataService: DataService,
    private _findingFileService: FindingFileService
  ) {}

  public async saveAnalysis(
    analysis: IAnalysis[],
    rois: (ROI | POI)[],
    labels: Dictionary<Label>,
    mode: Mode,
    hasSegmentationTasks: boolean
  ): Promise<Change<string>[]> {
    const copyPrefix = "copy:";
    const replacePrefix = "replace:";
    const analysingState = "inProgress";

    const isCopy = (an) => an.id.startsWith(copyPrefix);

    if (mode === Mode.REVIEW) {
      const analysisUnsyncedIds = analysis.map((an) =>
        isCopy(an) ? an.id.substring(an.id.indexOf(replacePrefix)) : an.id
      );
      analysis = analysis.filter(
        (an) => !analysisUnsyncedIds.includes(replacePrefix + an.id)
      );
    }

    const getRois = (analysisId) =>
      rois.filter((roi: ROI | POI) =>
        roi.labels.map((label) => label.analysisId).includes(analysisId)
      );

    const parseAnalysis = analysis.map((an) =>
      AnalysisMapper.fromStateAnalysis(an, getRois(an.id), labels)
    );

    let remoteAnalysis = await Analysis.saveAll(parseAnalysis);

    if (hasSegmentationTasks) {
      const modifiedAnalysis = await Promise.all(
        this.uploadMask(remoteAnalysis, analysis)
      );
      remoteAnalysis = await Analysis.saveAll(modifiedAnalysis);
    }

    const changes = remoteAnalysis
      .map((ra, i) => ({
        previous: analysis[i].id,
        current: ra.id,
        assetId: ra?.asset?.id,
        maskFileName: ra?.data?.mask_fileName as string,
      }))
      .filter((change) => change.current !== change.previous);

    const analysisCasesIds = parseAnalysis.map((an) => an.sample?.case?.id);

    const uniqueCaseIds = Array.from(new Set(analysisCasesIds));

    const uniqueCasePointers = uniqueCaseIds.map((id) =>
      Case.createWithoutData(id)
    );

    const existingAnalysisStates = await new Query(AnalysisState)
      .equalTo("user", User.current())
      .containedIn("case", uniqueCasePointers)
      .find();

    if (!existingAnalysisStates.length) {
      const newAnalysisStates = uniqueCasePointers.map(
        (c) => new AnalysisState(User.current(), c, analysingState)
      );
      await AnalysisState.saveAll(newAnalysisStates);
    } else {
      existingAnalysisStates.forEach((as) => (as.state = analysingState));
      await AnalysisState.saveAll(existingAnalysisStates);
    }

    return changes;
  }

  public loadSampleAnalysis({ createdBy, sampleId }): Observable<IAnalysis[]> {
    const sample = Sample.createWithoutData(sampleId);
    const createdByKey =
      createdBy.className === "_User" ? "createdBy" : "algorithm";
    const createdType = createdBy.className === "_User" ? User : Algorithms;
    const createdByObject = createdType.createWithoutData(createdBy?.objectId);

    const analysisQuery = new Query(Analysis)
      .equalTo("sample", sample)
      .equalTo(createdByKey, createdByObject)
      .exists(createdByKey)
      .exists("analysisType")
      .include(["analysisType"])
      .descending("createdAt")
      .doesNotExist("asset");

    return from(
      this.dataService
        .find(analysisQuery)
        // Filter analysis with same analysis type, since they are ordered by creation, only the latest will be mapped
        .then((analysis) =>
          analysis.filter(
            (an, i, array) =>
              array.findIndex(
                (v) => v.analysisType.id === an.analysisType.id
              ) === i
          )
        )
        .then((analysis) =>
          analysis.map((an) => AnalysisMapper.toStateAnalysis(an))
        )
    );
  }

  public loadAssetAnalysis({ assetId, createdBy, sampleId }): Observable<{
    analysis: IAnalysis[];
    rois: ROI[];
    customLabels: Label[];
  }> {
    const sample = Sample.createWithoutData(sampleId);
    const createdByKey =
      createdBy.className === "_User" ? "createdBy" : "algorithm";
    const createdType = createdBy.className === "_User" ? User : Algorithms;
    const createdByObject = createdType.createWithoutData(createdBy?.objectId);
    const asset = Asset.createWithoutData(assetId);

    const analysisQuery = new Query(Analysis)
      .equalTo("sample", sample)
      .equalTo(createdByKey, createdByObject)
      .exists(createdByKey)
      .exists("analysisType")
      .include(["analysisType"])
      .descending("updatedAt")
      .equalTo("asset", asset);

    return from(
      this.dataService
        .find(analysisQuery)
        // Filter analysis with same analysis type, since they are ordered by creation, only the latest will be mapped
        .then((analysis) =>
          analysis.filter(
            (an, i, array) =>
              array.findIndex(
                (v) => v.analysisType.id === an.analysisType.id
              ) === i
          )
        )
        .then((analysis) => ({
          analysis: analysis.map((an) => AnalysisMapper.toStateAnalysis(an)),
          rois: AnalysisUtils.flatten(
            analysis.map((an) => [
              ...AnalysisMapper.extractProtocolROIs(an),
              ...AnalysisMapper.extractCustomROIs(an),
            ])
          ),
          customLabels: AnalysisUtils.flatten(
            analysis.map((an) => AnalysisMapper.getCustomLabels(an))
          ),
        }))
    );
  }

  public uploadMask(savedAnalysis: Analysis[], unsyncedAnalysis: IAnalysis[]) {
    //Filters only assetAnalysis, previously it has been checked if there was segmentationTasks
    const allAnalysis = savedAnalysis
      .filter((an) => an.asset)
      .map(async (an, i) => {
        let maskFileName: string;
        const src = localStorage.getItem(`localMask/${unsyncedAnalysis[i].id}`);
        const imageBlob = this._findingFileService.dataURItoBlob(src);

        if (unsyncedAnalysis[i].id.includes("new:")) {
          const file = new File([imageBlob], "mask.png", {
            type: "image/png",
          });
          maskFileName = await this._findingFileService.upload(
            file,
            an.id,
            "files/findings"
          );
          localStorage.setItem(`localMask/${an.id}`, src);
          localStorage.removeItem(unsyncedAnalysis[i].id);
        } else {
          const file = new File(
            [imageBlob],
            unsyncedAnalysis[i].data.mask_filename as string,
            {
              type: "image/png",
            }
          );
          maskFileName = await this._findingFileService.updateFinding(
            file,
            an.id,
            "files/findings"
          );
        }
        an.data = { ...an.data, mask_filename: maskFileName };

        return an;
      });

    return allAnalysis;
  }
}
