import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import { Algorithms, PipelineTask, StepAction, StepTask } from "@telespot/sdk";
import { PipelineTaskEditorDialogComponent } from "../pipeline-task-editor-dialog/pipeline-task-editor-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { take, takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { SelectAIModelDialogComponent } from "../select-ai-model-dialog/select-ai-model-dialog.component";

@Component({
  selector: "ts-pipeline-step-editor",
  templateUrl: "./pipeline-step-editor.component.html",
  styleUrls: ["./pipeline-step-editor.component.scss"],
})
export class PipelineStepEditorComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  private _dialogRef: MatDialogRef<any>;
  private _tasks: PipelineTask[];

  @Input()
  set tasks(tasks: PipelineTask[]) {
    if (this._tasks !== undefined) return;
    this._tasks = tasks;
    this.setupForm();
  }
  get tasks() {
    return this._tasks;
  }

  @Input() isCloud = true;
  @Input() insideMethodType = false;
  @Input() tasksFromOtherSteps = [];

  @Output() submitted = new EventEmitter<PipelineTask[]>();

  public aiModelAssigned: Algorithms;

  public readonly taskTypes = Object.values(StepTask);
  selectedTask: PipelineTask;
  isNewTask = false;
  showFieldEditor = false;

  form: UntypedFormGroup;

  constructor(@Optional() private dialog: MatDialog) {}

  ngOnDestroy() {
    this._destroy$.next();
  }

  ngOnInit() {
    this.setupForm();
  }

  tasksValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value?.length >= 1) {
        return null;
      }
      return { tasksInvalid: true };
    };
  }

  tasksValidatorError(): boolean {
    const tasksControl = this.form.get("tasks");
    return tasksControl.hasError("tasksInvalid");
  }

  setupForm() {
    this.form = new UntypedFormGroup({
      tasks: new UntypedFormControl(this.tasks || [], [this.tasksValidator()]),
      type: new UntypedFormControl(this.tasks[0]?.type || StepTask.TEXT, [
        Validators.required,
      ]),
      allowAddingNewClasses: new UntypedFormControl(
        this.tasks[0]?.allowNewOptions || false
      ),
      enableMosaicView: new UntypedFormControl(
        this.tasks[0]?.enableMosaicView || false
      ),
    });

    if (this.tasks.length > 0) {
      const aiTask = this.tasks.find((t) => t?.executor);
      if (aiTask) this.aiModelAssigned = aiTask.executor;
    }
  }

  async submit() {
    const tasks = this.form.value.tasks;
    tasks.forEach((t) => {
      t.allowNewOptions = this.form.value.allowAddingNewClasses;
      t.type = this.form.value.type;
      t.enableMosaicView = this.form.value.enableMosaicView;
    });

    this.submitted.emit(tasks);
  }

  cancel() {
    this.submitted.emit(null);
  }

  editTask(task: PipelineTask, isNew?: boolean) {
    this.isNewTask = isNew;
    this.selectedTask = task;
    this.showFieldEditor = true;

    this._dialogRef = this.dialog.open(PipelineTaskEditorDialogComponent, {
      width: "780px",
      hasBackdrop: true,
      data: {
        pipelineTask: task,
        taskType: this.form.value.type ?? task.type,
        isCloud: this.isCloud,
        insideMethodType: this.insideMethodType,
      },
    });

    this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe((response) => {
        this.addTask(response);
      });
  }

  addTask(task: PipelineTask) {
    if (!task) {
      this.showFieldEditor = false;
      return;
    }
    const currentTasks = this.form.value.tasks || [];
    if (
      currentTasks.findIndex(
        (existingTask) => existingTask?.name === task?.name
      ) === -1
    ) {
      currentTasks.push(task);
    }
    this.form.controls.tasks.patchValue(currentTasks);

    this.tasks = currentTasks;
    this.showFieldEditor = false;
    this.selectedTask = null;
  }

  newField() {
    const newField = new PipelineTask({
      id: this.tasks[0]?.id,
      name: undefined,
      type: this.form.value.type,
      trigger: this.isCloud
        ? StepAction.ASSET_CREATED
        : StepAction.ACQUISITION_EXIT,
      generateFinding: this.isCloud
        ? StepAction.SAMPLE_CREATED
        : StepAction.ACQUISITION_EXIT,
    });

    this.editTask(newField, true);
  }

  drop(event: CdkDragDrop<PipelineTask[]>) {
    const tasks = [...this.form.value.tasks];
    moveItemInArray(tasks, event.previousIndex, event.currentIndex);
    this.form.patchValue({
      tasks: tasks,
    });
    this.tasks = tasks;
  }

  selectAIModel() {
    const dialogRef = this.dialog.open(SelectAIModelDialogComponent, {
      width: "900px",
      data: {
        isCloud: true,
        modelSelected: this.aiModelAssigned,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === "save") {
        this.aiModelAssigned = result?.model;
        this.tasks.forEach((t) => (t.executor = result?.model));
      }
    });
  }

  removeTask(taskToRemove: PipelineTask) {
    const tasksUpdated = this.form.value.tasks.filter(
      (task) => task.name.name !== taskToRemove.name.name
    );
    this.form.controls.tasks.patchValue(tasksUpdated);
    this.tasks = tasksUpdated;
  }

  canAddNewTask() {
    const noLabelType =
      this.form.value.type === StepTask.TEXT ||
      this.form.value.type === StepTask.CLASSIFICATION;
    if (noLabelType && this.form.value.tasks.length > 0) {
      return false;
    }
    return true;
  }

  isTypeDisabled(type: string) {
    if (this.form.value.tasks?.length === 0) {
      //If step has no tasks created only disables roidetection or position types if there is already another step with that type
      if (type !== StepTask.POSITION && type !== StepTask.ROIDETECTION)
        return false;
      if (this.tasksFromOtherSteps.some((t) => t.type === type)) return true;
      return false;
    }

    //If step has tasks created
    if (
      this.form.value.type === StepTask.TEXT ||
      this.form.value.type === StepTask.OCR
    ) {
      //Type can be changed between TEXT and OCR
      if (type === StepTask.TEXT || type === StepTask.OCR) {
        return false;
      }
      return true;
    }

    //If step tasks were defined as classification there is no compatible type to change to
    if (this.form.value.type === StepTask.CLASSIFICATION) {
      return true;
    }

    //If tasks have type related to labeling disabled types that have already a step defined
    if (
      type === StepTask.ROIDETECTION ||
      type === StepTask.POSITION ||
      type === StepTask.SEGMENTATION
    ) {
      return this.tasksFromOtherSteps.some((t) => t.type === type)
        ? true
        : false;
    }
    //Disabled type not compatible to labeling
    return true;
  }
}
