import { CollectionViewer } from "@angular/cdk/collections";
import { Store } from "@ngrx/store";
import { SplDataSource } from "@shared/ui";
import { caseSelectors } from "@telespot/analysis-refactor/data-access";
import { AdaptaspotRowData } from "./adaptaspot-row-data";
import { Observable } from "rxjs";

export class AdaptaspotCaseListDataSource extends SplDataSource<AdaptaspotRowData> {
  constructor(private store: Store) {
    super({ updateFcn: null });
  }

  get pageIndex$(): Observable<number> {
    return this.store.select(caseSelectors.selectPageIndex);
  }

  get pageSize$(): Observable<number> {
    return this.store.select(caseSelectors.selectPageSize);
  }

  connect(_: CollectionViewer) {
    this.activeFilters$ = this.store.select(caseSelectors.selectFilters);
    this.loading$ = this.store.select(caseSelectors.selectLoadingCases);
    this.numPages$ = this.store.select(caseSelectors.selectTotalCaseCount);
    return this.store.select(caseSelectors.selectCaseList);
  }

  disconnect(_: CollectionViewer): void {
    return;
  }
}
