<p>
  {{
    data.imageFormat
      ? ("label.select_images_download" | translate)
      : ("label.select_videos_download" | translate)
  }}:
</p>
<ts-total-assets-selector
  [assets]="data.assets"
  (emitter)="receivedData($event)"
  [imageFormat]="data.imageFormat"
></ts-total-assets-selector>
<mat-dialog-actions>
  <button mat-dialog-close class="btn btn-outline-primary" style="margin: auto">
    {{ "button.cancel" | translate | uppercase }}
  </button>
  <button
    class="btn btn-primary"
    style="margin: auto"
    [disabled]="(minAssetSelected$ | async) === false"
    (click)="downloadAssets()"
    color="primary"
    data-cy="download-zip-button"
  >
    {{ "button.download" | translate | uppercase }}
  </button>
</mat-dialog-actions>
