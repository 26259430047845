<div
  class="flex-column"
  style="align-content: center; justify-content: center; align-items: center; width: 100%; height: 100%"
>
  <div style="flex-grow: 0; text-align: center">
    <h1>
      {{ 'info.page_not_found' | translate }}
    </h1>
  </div>
</div>
