import { Organization, Affiliate, Invite, OrganizationRepository } from "@telespot/domain";

import { ParseBaseRepository } from "../../parse-base.repository";
import { UserTopology } from "../accounts";

import { AffiliateTopology, ParseAffiliateMapper } from "./parse-affiliate.mapper";
import { ParseOrganizationMapper, OrganizationTopology } from "./parse-organization.mapper";
import { InviteTopology, ParseInviteMapper } from "./parse-invite.mapper";

export class ParseOrganizationRepository extends ParseBaseRepository implements OrganizationRepository {

  private readonly orgMapper = new ParseOrganizationMapper(this.parse);
  private readonly affiliateMapper = new ParseAffiliateMapper(this.parse);
  private readonly inviteMapper = new ParseInviteMapper(this.parse);

  public async save(organization: Organization): Promise<string> {
    const parseOrganization = this.orgMapper.fromDomain(organization);

    const { id } = await parseOrganization.save(null, this.options);

    return id;
  }

  public async findAffiliateInOrganizationByName(organizationId: string, username: string): Promise<Affiliate> {
    const ParseOrganization = this.subclasses.getSubclass(OrganizationTopology.TABLE);

    const userQuery = new this.parse.Query(this.parse.User)
      .equalTo(UserTopology.USERNAME, username);

    const affiliationQuery = new this.parse.Query(AffiliateTopology.TABLE)
      .matchesQuery(AffiliateTopology.USER, userQuery)
      .equalTo(AffiliateTopology.ORGANIZATION, ParseOrganization.createWithoutData(organizationId))
      .include(AffiliateTopology.USER);

    const affiliate = await affiliationQuery.first(this.options);

    return affiliate ? this.affiliateMapper.toDomain(affiliate) : undefined;
  }

  public async deleteInvite(inviteId: string): Promise<void> {
    const ParseInvite = this.subclasses.getSubclass(InviteTopology.TABLE);

    const parseInvite = ParseInvite.createWithoutData(inviteId);

    await parseInvite.destroy(this.options);
  }

  public async getInvite(inviteCode: string): Promise<Invite> {
    const parseInvite = await new this.parse.Query(InviteTopology.TABLE)
      .equalTo(InviteTopology.CODE, inviteCode)
      .include(InviteTopology.ROLES)
      .first(this.options);

    return parseInvite ? this.inviteMapper.toDomain(parseInvite) : undefined;
  }

  public async saveInvite(invite: Invite): Promise<string> {
    const parseInvite = this.inviteMapper.fromDomain(invite);

    const { id } = await parseInvite.save(null, this.options);

    return id;
  }

  public async findAffiliateInOrganization(organizationId: string, userId: string): Promise<Affiliate> {
    const ParseOrganization = this.subclasses.getSubclass(OrganizationTopology.TABLE);

    const affQuery = new this.parse.Query(AffiliateTopology.TABLE)
      .equalTo(AffiliateTopology.USER, this.parse.User.createWithoutData(userId))
      .equalTo(AffiliateTopology.ORGANIZATION, ParseOrganization.createWithoutData(organizationId))
      .include(AffiliateTopology.USER);

    const affiliate = await affQuery.first(this.options);

    return affiliate ? this.affiliateMapper.toDomain(affiliate) : undefined;
  }

  public async getById(id: string): Promise<Organization> {
    const query = new this.parse.Query(OrganizationTopology.TABLE);

    const organization = await query.get(id, this.options);

    const memberCount = await new this.parse.Query(AffiliateTopology.TABLE)
      .equalTo(AffiliateTopology.ORGANIZATION, organization)
      .count(this.options);

    return this.orgMapper.toDomain(organization, memberCount);
  }

  public async findAffiliateInOrganizationByEmail(organizationId: string, email: string): Promise<Affiliate> {
    const organization = this.subclasses
      .getSubclass(OrganizationTopology.TABLE)
      .createWithoutData(organizationId);

    const userQuery = new this.parse.Query(this.parse.User)
      .equalTo(UserTopology.EMAIL, email.toLowerCase());

    const affiliationQuery = new this.parse.Query(AffiliateTopology.TABLE)
      .matchesQuery(AffiliateTopology.USER, userQuery)
      .equalTo(AffiliateTopology.ORGANIZATION, organization)
      .include(AffiliateTopology.USER);

    const affiliate = await affiliationQuery.first(this.options);

    return affiliate ? this.affiliateMapper.toDomain(affiliate) : undefined;
  }

  public async saveAffiliate(affiliate: Affiliate): Promise<void> {
    await this.affiliateMapper
      .fromDomain(affiliate)
      .save(null, this.options);
  }

}
