import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { ISampleState } from "@telespot/analysis-refactor/data-access";
import { TelespotModule } from "@telespot/web-core";
import { Sample } from "@telespot/sdk";
import { PreprocessFilePipeModule } from "@telespot/shared/util";
import { MatMenuModule } from "@angular/material/menu";

@Component({
  selector: "ts-sample-card",
  templateUrl: "./sample-card.component.html",
  styleUrls: ["./sample-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleCardComponent {
  @Input() sampleItem: ISampleState;
  @Input() tiraspot = false;

  @Output()
  edit: EventEmitter<ISampleState> = new EventEmitter(true);

  isHovering = false;

  public isStitchingSample(sample: Sample): boolean {
    return sample?.device?.deviceType?.name.startsWith(`micra`);
  }

  public hovering(hover: boolean): void {
    this.isHovering = hover;
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatExpansionModule,
    TranslateModule,
    MatTooltipModule,
    TelespotModule,
    PreprocessFilePipeModule,
    MatMenuModule,
  ],
  declarations: [SampleCardComponent],
  exports: [SampleCardComponent],
})
export class SampleCardComponentModule {}
