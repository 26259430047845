import { Injectable } from '@angular/core';
import { AuthService, DataService } from '@telespot/web-core';
import { Invite, Member, Query, Role, Workspace } from '@telespot/sdk';
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InvitesService {
  private _fetchB$ = new BehaviorSubject<null>(null);

  public get invites$(): Observable<Invite[]> {
    return combineLatest([this._authService.currentOrganization$, this._fetchB$]).pipe(
      switchMap(([organization, _]) => from(this._dataService.find(new Query(Invite).equalTo('organization', organization))))
    );
  }

  public get workpaces$(): Observable<Workspace[]> {
    return combineLatest([this._authService.currentUser$, this._authService.currentOrganization$]).pipe(
      switchMap(([user, organization]) => {
        return user.roleNames.some((ur) => ['admin', 'analystmanager'].includes(ur))
          ? from(this._dataService.find(new Query(Workspace).equalTo('organization', organization)))
          : from(
              this._dataService
                .find(new Query(Member).equalTo('user', user).include('workspace'))
                .then((members) =>
                  members.map((member) => member.workspace).filter((m) => m.organization?.id === organization?.id)
                )
            );
      })
    );
  }

  constructor(private _dataService: DataService, private _authService: AuthService) {}

  public async removeInvite(invite: Invite): Promise<void> {
    await this._dataService.delete(invite);
    this._fetchB$.next(null);
  }

  public async getInvite(inviteCode: string): Promise<Invite> {
    return this._dataService.first(
      new Query(Invite).equalTo('code', inviteCode).include('organization').select('organization.name', 'initialRoles')
    );
  }

  public async createInvite({
    code,
    uses,
    initialWorkspaces,
    initialRoles,
  }: {
    code: string;
    uses?: number;
    initialWorkspaces?: Workspace[];
    initialRoles?: Role[];
  }): Promise<Invite> {
    const invite = new Invite({
      code,
      organization: this._authService.currentOrganizationValue,
      uses,
      initialWorkspaces,
      initialRoles,
    });
    const savedInvite: Invite = await (this._dataService.save(invite) as Promise<Invite>);
    this._fetchB$.next(null);
    return savedInvite;
  }
}
