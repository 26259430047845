<ng-container *ngIf="printable; else styledTemplate">
  <ng-container *ngIf="case; noCase">
    <div *ngFor="let field of case?.caseType.fields" class="row data-field">
      <div class="col-md-4" *ngIf="field.type !== 'label'">
        <b>{{ field.displayName }}:</b>
      </div>
      <div class="col-md-8">
        <!-- Missing data fields -->
        <div
          *ngIf="
            !case.data[field.name] &&
              field.type !== 'label' &&
              field.type !== 'boolean' &&
              field.type !== 'sectionHeader' &&
              field.type !== 'case_name';
            else fieldData
          "
        >
          <i>{{ "core.unspecified" | translate }}</i>
        </div>
        <!-- Data fields -->
        <ng-template #fieldData>
          <ng-container [ngSwitch]="field.type">
            <div *ngSwitchCase="'selection'">
              <div *ngIf="field.details.multiple">
                <span
                  class="badge badge-light"
                  *ngFor="let item of case.data[field.name]"
                  >{{ item }}</span
                >
              </div>
              <div *ngIf="!field.details.multiple">
                <i>{{ case.data[field.name] }}</i>
              </div>
            </div>
            <div *ngSwitchCase="'date'">
              <i>{{
                (case.data[field.name] | validateDate | date : "short") ||
                  "(Invalid date format)"
              }}</i>
            </div>
            <div *ngSwitchCase="'boolean'">
              <i>{{
                (case.data[field.name] ? "core.yes" : "core.no") | translate
              }}</i>
            </div>
            <div *ngSwitchCase="'location'">
              <a [href]="getLocationURL(case.data[field.name])">{{
                "button.map" | translate
              }}</a>
            </div>
            <div *ngSwitchCase="'number'">
              <i
                >{{ case.data[field.name] }} <b>{{ field.details.units }}</b></i
              >
            </div>
            <div *ngSwitchCase="'case_name'">
              <i>{{ case.name }}</i>
            </div>
            <div *ngSwitchDefault>
              <div
                class="multiline-text"
                style="font-style: italic"
                *ngIf="field.details.multiline; else singleLine"
                [innerHTML]="case.data[field.name]"
              ></div>
              <ng-template #singleLine>
                <i>{{ case.data[field.name] }}</i>
              </ng-template>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <ng-template #noCase> No case data </ng-template>
</ng-container>

<ng-template #styledTemplate>
  <ng-container *ngIf="case">
    <!-- EDIT/ENCRYPTION BUTTONS -->
    <span class="tool-btn">
      <ng-container *ngIfRole="['!admin']">
        <ng-container *ngIf="!createdByUser && !editMode">
          <button mat-icon-button class="btn btn-flat-primary">
            <i
              [ngClass]="decrypted ? 'ri-lock-unlock-line' : 'ri-lock-line'"
              *ngIf="this.wsEncrypted"
              (click)="decryptCase()"
              class="icon-lock"
              style="margin-left: 10px"
            ></i>
          </button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="allowedToEdit()">
        <ng-container *ngIf="!editMode">
          <span class="tool-btn">
            <button
              mat-icon-button
              class="btn btn-flat-primary"
              matTooltip="tooltip.edit_case_data"
              (click)="setEditMode()"
            >
              <i class="ri-pencil-line"></i>
            </button>
          </span>
        </ng-container>
        <ng-container *ngIf="editMode">
          <span class="tool-btn">
            <button
              mat-icon-button
              class="btn btn-outline-danger border-0"
              style="background-color: transparent"
              (click)="discardChanges()"
            >
              <i class="ri-close-fill"></i>
            </button>
            <button
              mat-icon-button
              class="btn btn-flat-primary"
              matTooltip="tooltip.save_case_data"
              [disabled]="caseDataForm.invalid"
              (click)="save()"
            >
              <i class="ri-save-2-line"></i>
            </button>
          </span>
        </ng-container>
      </ng-container>
    </span>
    <!-- CASE FORM -->
    <form class="scroll-form" [formGroup]="caseDataForm" *ngIf="caseDataForm">
      <ng-container *ngFor="let section of dataSections">
        <ng-container *ngIf="section.fields.length > 0">
          <div
            class="card-body padded"
            formGroupName="{{ section.sectionName }}"
          >
            <!-- SECTION TITTLE -->
            <div class="card-section-title">
              <i class="ri-article-line"></i>
              <span>{{ section.sectionName | uppercase }}</span>
            </div>
            <!-- SECTION CONTENT -->
            <div
              class="card-section-content"
              *ngFor="let field of section.fields"
            >
              <!-- CONTENT LABELS -->
              <ng-container
                *ngIf="field.fieldType.type !== 'case_name'; else noLabel"
              >
                <label [for]="field.fieldType.name">{{
                  field.fieldType.displayName ??
                    field.fieldType.details.display_name
                }}</label>
              </ng-container>
              <!-- FIELD VALIDATION CHECK WARNING -->
              <ng-container *ngIf="caseDataForm.invalid">
                <div
                  *ngIf="
                    caseDataForm
                      .get(section.sectionName + '.' + field.fieldType.name)
                      .hasError('required')
                  "
                  class="error-message"
                >
                  {{ "error.field_required" | translate }}
                </div>
                <div
                  *ngIf="
                    caseDataForm
                      .get(section.sectionName + '.' + field.fieldType.name)
                      .hasError('min')
                  "
                  class="error-message"
                >
                  {{ "error.min_limit_exceeded" | translate }}
                  {{ field.fieldType.details.min }}.
                </div>
                <div
                  *ngIf="
                    caseDataForm
                      .get(section.sectionName + '.' + field.fieldType.name)
                      .hasError('max')
                  "
                  class="error-message"
                >
                  {{ "error.max_limit_exceeded" | translate }}
                  {{ field.fieldType.details.max }}.
                </div>
              </ng-container>
              <ng-template #noLabel>
                <label for="case-name-value"></label
              ></ng-template>
              <!-- CONTENT VALUES -->
              <ng-container
                *ngIf="
                  !decrypted &&
                    !createdByUser &&
                    field.fieldType.encrypted &&
                    field.fieldType.type !== 'case_name';
                  else noEncryption
                "
              >
                <div>
                  {{ "******" }}
                </div>
              </ng-container>
              <ng-template #noEncryption [ngSwitch]="field.fieldType.type">
                <!-- FIELD SWITCH -->
                <ng-container *ngSwitchCase="'case_name'">
                  <input
                    *ngIf="field.data || editMode; else noValue"
                    [type]="field.fieldType.type"
                    [class.custom]="editMode"
                    [formControlName]="field.fieldType.name"
                    [class.disabled]="!editMode"
                  />
                  <ng-template #noValue>
                    <i>{{ "info.no_value" | translate }}</i>
                  </ng-template>
                </ng-container>

                <ng-container *ngSwitchCase="'textedit'">
                  <input
                    *ngIf="field.data || editMode; else noValue"
                    [type]="field.fieldType.type"
                    [class.custom]="editMode"
                    [formControlName]="field.fieldType.name"
                    [class.disabled]="!editMode"
                  />
                  <ng-template #noValue>
                    <i>{{ "info.no_value" | translate }}</i>
                  </ng-template>
                </ng-container>

                <ng-container *ngSwitchCase="'label'">
                  <input
                    *ngIf="field.data || editMode; else noValue"
                    [type]="field.fieldType.type"
                    [class.custom]="editMode"
                    [formControlName]="field.fieldType.name"
                    [class.disabled]="!editMode"
                  />
                  <ng-template #noValue>
                    <i>{{ "info.no_value" | translate }}</i>
                  </ng-template>
                </ng-container>

                <ng-container *ngSwitchCase="'number'">
                  <input
                    *ngIf="field.data || editMode; else noValue"
                    [type]="field.fieldType.type"
                    [formControlName]="field.fieldType.name"
                    [class.custom]="editMode"
                    [class.disabled]="!editMode"
                  />
                  <b
                    *ngIf="
                      field.fieldType.type === 'number' &&
                      field.fieldType.details.units
                    "
                    >{{ field.details.units }}</b
                  >
                  <ng-template #noValue>
                    <i>{{ "info.no_value" | translate }}</i>
                  </ng-template>
                </ng-container>

                <ng-container *ngSwitchCase="'switcher'">
                  <select
                    *ngIf="field.data || editMode; else noValue"
                    [formControlName]="field.fieldType.name"
                    style="display: inline-flex"
                    [class.disabled]="!editMode"
                  >
                    <option
                      *ngFor="let option of field.fieldType.details.options"
                      [value]="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                  <ng-template #noValue>
                    <i>{{ "info.no_value" | translate }}</i>
                  </ng-template>
                </ng-container>

                <ng-container *ngSwitchCase="'boolean'">
                  <select
                    *ngIf="field.data || editMode; else noValue"
                    [formControlName]="field.fieldType.name"
                    [class.custom]="editMode"
                    [class.disabled]="!editMode"
                  >
                    <option
                      *ngFor="let option of [true, false]"
                      [value]="option"
                    >
                      {{ (option ? "core.yes" : "core.no") | translate }}
                    </option>
                  </select>
                  <ng-template #noValue>
                    <i>{{ "info.no_value" | translate }}</i>
                  </ng-template>
                </ng-container>

                <div *ngSwitchCase="'selection'">
                  <ng-container
                    *ngIf="field.fieldType.details.multiple; else single"
                  >
                    <div [formGroupName]="field.fieldType.name">
                      <div formArrayName="options">
                        <div
                          *ngFor="
                            let option of field.fieldType.details.options;
                            let i = index
                          "
                        >
                          <input type="checkbox" [formControlName]="i" />
                          {{ option }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #single>
                    <select
                      *ngIf="field.data || editMode; else noValue"
                      [formControlName]="field.fieldType.name"
                      [class.custom]="editMode"
                      [class.disabled]="!editMode"
                    >
                      <option
                        *ngFor="let option of field.fieldType.details.options"
                        [value]="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                    <ng-template #noValue>
                      <i>{{ "info.no_value" | translate }}</i>
                    </ng-template>
                  </ng-template>
                </div>

                <ng-container *ngSwitchCase="'date'">
                  <input
                    *ngIf="field.data || editMode; else noValue"
                    [type]="field.fieldType.type"
                    [class.custom]="editMode"
                    [formControlName]="field.fieldType.name"
                    [class.disabled]="!editMode"
                  />
                  <ng-template #noValue>
                    <i>{{ "info.no_value" | translate }}</i>
                  </ng-template>
                </ng-container>

                <ng-container *ngSwitchCase="'location'">
                  <ng-container *ngIf="!editMode; else editionActive">
                    <a
                      *ngIf="field.data; else noValue"
                      [href]="getLocationURL(field.data)"
                      target="_blank"
                      style="padding: 0"
                      >{{ "button.map" | translate }}</a
                    >
                    <ng-template #noValue>
                      <i>{{ "info.no_value" | translate }}</i>
                    </ng-template>
                  </ng-container>
                  <ng-template #editionActive>
                    <button
                      class="btn btn-outline-primary"
                      (click)="
                        getLocation(section.sectionName, field.fieldType)
                      "
                    >
                      <i class="ri-compass-line"></i>
                      {{ "label.get_location" | translate }}
                    </button>
                  </ng-template>
                </ng-container>
              </ng-template>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </ng-container>
</ng-template>
