import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Affiliate, CloudFunctions, User } from "@telespot/sdk";
import { GdprNoticeDialogComponent } from "@telespot/ui";
import { BehaviorSubject, Subject } from "rxjs";

@Component({
  selector: "ts-user-register",
  templateUrl: "./user-register.component.html",
  styleUrls: ["./user-register.component.scss"],
})
export class UserRegisterComponent implements OnInit {
  public form: UntypedFormGroup;

  private _affiliate$ = new Subject<Affiliate>();
  public readonly affiliate$ = this._affiliate$.asObservable();

  private _submitting$ = new BehaviorSubject<boolean>(false);
  public readonly submitting$ = this._submitting$.asObservable();

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.form = new UntypedFormGroup({
      inviteCode: new UntypedFormControl(
        this.route.snapshot.data["inviteCode"],
        [Validators.required]
      ),
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.email,
      ]),
      accept_gdpr: new UntypedFormControl(null, [Validators.requiredTrue]),
    });
  }

  public async submit() {
    this._submitting$.next(true);
    const user = new User();
    user.username = this.form.value["email"];
    user.email = this.form.value["email"];

    try {
      const affiliate = await CloudFunctions.InviteUser({
        user,
        inviteCode: this.form.value["inviteCode"],
      });

      this._affiliate$.next(affiliate);
    } catch (error) {
      this.form.get("inviteCode").setErrors(
        {
          invalid: error.message,
        },
        { emitEvent: true }
      );
    }
    this._submitting$.next(false);
  }

  showPrivacyNotice() {
    this.dialog.open(GdprNoticeDialogComponent, {
      disableClose: false,
      maxWidth: "90vw",
      width: "600px",
    });
  }
}
