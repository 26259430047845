import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataTableConfig, SplDataSource, SplDataSourceConfig } from '@shared/ui';
import { DataService } from '@telespot/web-core';
import { Query, Resource } from '@telespot/sdk';
import { defer, from, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { ResourceEditorDialogComponent } from '../resource-editor-dialog/resource-editor-dialog.component';
import { ResourcesRowData } from '../resources-assignment/resources-row-data';

interface FindResourcesRequest {
  sorts: any;
  filters: any;
  skip: number;
  limit: number;
}

@Component({
  selector: 'ts-mask-results-assignment',
  templateUrl: './mask-results-assignment.component.html',
  styleUrls: ['./mask-results-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaskResultsAssignmentComponent implements OnInit, OnDestroy, SplDataSourceConfig<ResourcesRowData> {
  private _destroy$ = new Subject<void>();

  public tableConfig: DataTableConfig<ResourcesRowData>;

  // I/O
  private _resources: Resource[];
  @Input()
  set resources(value: Resource[]) {
    this._resources = value;
  }
  get resources(): Resource[] {
    return this._resources || [];
  }
  @Output() resultsChanged = new EventEmitter<Resource[]>();

  selectedResults: string[] = [];

  resultResource: Resource; // resource passed to the editor
  private _dialogRef: MatDialogRef<any>;

  private previousRequest: FindResourcesRequest;
  private previousCount = 0;
  private resultsCreated: string[] = [];
  private resultToCreate: Resource;
  private resultToInclude = false;
  public oldAvailableResults: Resource[];
  public dataSource = new SplDataSource<ResourcesRowData>(this);

  private getFindResultQuery(request: FindResourcesRequest): Query<Resource> {
    const { filters, limit, skip } = request;

    const ResultsQuery = new Query(Resource).equalTo('type', 'response');

    if (filters) {
      for (const { constraints } of filters) {
        for (const [comparison, value] of Object.entries(constraints)) {
          //No other verifications are needed since searchbar only uses 'name' filter
          if (typeof value === 'string') {
            ResultsQuery.matches('name', new RegExp(`${value.toLowerCase()}`), 'i');
          }
        }
      }
    }

    ResultsQuery.limit(limit || 100);
    ResultsQuery.skip(skip || 0);

    return ResultsQuery;
  }

  public requestNeeded(prevRequest: FindResourcesRequest): boolean {
    if (!this.previousRequest) {
      return true;
    }

    if (this.resultToInclude) {
      this.resultToInclude = false;
      return true;
    }

    if (prevRequest.filters !== this.previousRequest.filters) {
      return true;
    }
    if (prevRequest.sorts !== this.previousRequest.sorts) {
      return true;
    }

    if (prevRequest.skip !== this.previousRequest.skip) {
      return true;
    }
    if (prevRequest.limit !== this.previousRequest.limit) {
      return true;
    }

    return false;
  }

  public async updateFcn(sorts, filters, skip, limit) {
    const request = { sorts, filters, skip, limit };

    const resultQuery = this.getFindResultQuery(request);

    const isRequestNeeded = this.requestNeeded(request);

    this.previousRequest = request;

    const items = isRequestNeeded ? this.dataService.find(resultQuery) : Promise.resolve(this.oldAvailableResults);
    const count = isRequestNeeded ? this.dataService.count(resultQuery) : Promise.resolve(this.previousCount);

    return {
      items: defer(() => items).pipe(
        map((resultsFound) => {
          this.oldAvailableResults = resultsFound;
          resultsFound = resultsFound.filter((el) => !this.selectedResults.includes(el.id));

          return resultsFound.map<ResourcesRowData>((el) => ({
            resource: el,
            id: el.id,
          }));
        })
      ),
      count: from(count).pipe(map((num) => (this.previousCount = num))),
    };
  }

  constructor(private dataService: DataService, private dialog: MatDialog) {}

  ngOnInit() {
    this.configureTable();
    this.selectedResults = this.resources.map((elem) => elem.id);
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  private configureTable(): void {
    this.tableConfig = {
      columns: [
        {
          name: 'available_results',
          value: (item) => item,
        },
      ],
      showPaginator: true,
      showFilters: false,
      showSearch: true,
      showStatusIndicator: false,
      showRefreshButton: false,
      useQueryParams: false,
    };
  }

  create() {
    this.resultToCreate = new Resource();
    this.resultToCreate.type = 'response';
    this.edit(this.resultToCreate);
  }

  edit(resource: Resource) {
    this.resultResource = resource;
    this._dialogRef =
      this._dialogRef ||
      this.dialog.open(ResourceEditorDialogComponent, {
        width: '800px',
        hasBackdrop: true,
        data: resource,
      });
    this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe((changedResult) => {
        this.resultsCreated.push(resource.id);
        this.addResult(changedResult);
      });
  }

  async addResult(resource: Resource) {
    if (resource) {
      this.selectedResults.push(resource.id);
      this.dataSource.fetch();

      const filteredRes = this.resources.filter((res) => res.id === resource.id);
      if (filteredRes.length === 0) {
        this.resources = this.resources.concat([resource]);
      }
      this.resultsChanged.emit(this.resources);
    }
  }

  removeResult(resource: Resource) {
    if (this.resultsCreated.includes(resource.id)) {
      this.resultsCreated = this.resultsCreated.filter((id) => id !== resource.id);
      this.resultToInclude = true;
    }
    this.resources = this.resources.filter((at) => at !== resource);
    this.selectedResults = this.selectedResults.filter((id) => id !== resource.id);
    this.dataSource.fetch();
    this.resultsChanged.emit(this.resources);
  }
}
