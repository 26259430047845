import { EventEmitter, Injectable } from "@angular/core";

import { FindingFileService } from "@telespot/web-core";

export interface SaveLocalStorageDetails {
  save: boolean;
  id: string;
}
@Injectable({
  providedIn: "root",
})
export class MaskViewerService {
  saveMaskOnLocalStorage = new EventEmitter<SaveLocalStorageDetails>();

  constructor(private _findingFileService: FindingFileService) {}

  public async fetchSegmentationMask(finding) {
    const maskFromStorage = localStorage.getItem(`localMask/${finding.id}`);

    if (finding.synced && maskFromStorage === null) {
      if (finding.data === undefined) return;
      const blob = await this._findingFileService.getFinding(
        finding.data as string,
        finding.analysisId,
        "files/findings"
      );
      const src = URL.createObjectURL(blob);
      localStorage.setItem(`localMask/${finding.id}`, src);
      return src;
    }

    if (finding.id.startsWith("copy:") && maskFromStorage === null) {
      const key = finding.id.substring(
        finding.id.indexOf(":") + 1,
        finding.id.indexOf("/")
      );
      const copiedMask = localStorage.getItem(`localMask/${key}`);
      localStorage.setItem(`localMask/${finding.id}`, copiedMask);
      return copiedMask;
    }

    return maskFromStorage;
  }

  public saveMaskOnStorageIfNeeded(
    hasSegmentationTasks: boolean,
    activeFinding,
    isAuthUser: boolean
  ) {
    if (hasSegmentationTasks && activeFinding !== undefined && isAuthUser) {
      this.saveMaskOnLocalStorage.emit({
        save: false,
        id: activeFinding?.id,
      });
    }
  }
}
