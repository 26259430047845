<div class="card" *ngIf="!fieldEditorVisible">
  <div class="card-header flex-row align-items-center justify-content-between">
    <h5>{{ 'title.case_data' | translate | uppercase }}</h5>
    <button class="btn btn-outline-primary float-right" (click)="create()" data-cy="button-new-field">
      <i class="ri-add-circle-line"></i>
      {{ 'button.add_new' | translate | uppercase }}
    </button>
  </div>
  <ul class="list-group list-group-flush">
    <mat-list
      *ngIf="caseFields"
      cdkDropList
      [cdkDropListData]="caseFields"
      (cdkDropListDropped)="processDrag($event)"
      #fieldsList
    >
      <div *ngFor="let field of caseFields; let id = index; trackBy: trackFcn" cdkDrag [id]="id" [cdkDragData]="field">
        <div *ngIf="field.type === 'sectionHeader'; else fieldItem">
          <mat-divider></mat-divider>
          <div class="flex-row" style="padding-left: 10px; padding-right: 14px; padding-top: 8px">
            <h2 mat-subheader>
              {{ field.displayName | uppercase }}
            </h2>
            <span class="button-bar float-right">
              <button mat-icon-button class="btn btn-flat-primary" (click)="edit(field)">
                <i class="ri-pencil-line"></i>
              </button>
              <button mat-icon-button class="btn btn-flat-danger" (click)="remove(field)">
                <i class="ri-delete-bin-6-line"></i>
              </button>
            </span>
          </div>
        </div>
        <ng-template #fieldItem>
          <mat-list-item data-cy="field-row">
            <span [ngSwitch]="field.type" class="field-icon">
              <i *ngSwitchCase="'case_name'" class="ri-price-tag-3-fill"></i>
              <i *ngSwitchCase="'boolean'" class="ri-check-line"></i>
              <i *ngSwitchCase="'date'" class="ri-calendar-2-line"></i>
              <i *ngSwitchCase="'textedit'" class="ri-pencil-line"></i>
              <i *ngSwitchCase="'number'" class="ri-hashtag"></i>
              <i *ngSwitchCase="'switcher'" class="ri-list-unordered"></i>
              <i *ngSwitchCase="'selection'" class="ri-list-unordered"></i>
              <i *ngSwitchCase="'location'" class="ri-focus-2-line"></i>
              <i *ngSwitchCase="'label'" class="ri-price-tag-3-fill"></i>
            </span>
            <span class="field-name"
              >{{ field?.displayName | uppercase }}
              <i
                class="ri-spy-line text-gray"
                *ngIf="field.encrypted"
                matTooltip="{{ 'tooltip.encrypted_field' | translate }}"
              ></i>
            </span>

            <span class="button-bar float-right stretch">
              <button mat-icon-button class="btn btn-flat-primary" (click)="edit(field)" data-cy="button-edit-field">
                <i class="ri-pencil-line"></i>
              </button>
              <button
                mat-icon-button
                class="btn btn-flat-danger"
                [class.disabled]="field.type === 'case_name'"
                [matTooltip]="field.type === 'case_name' ? 'The case ID cannot be removed' : 'Remove this field'"
                (click)="remove(field)"
                data-cy="button-delete-field"
              >
                <i class="ri-delete-bin-6-line"></i>
              </button>
            </span>
          </mat-list-item>
        </ng-template>
      </div>
    </mat-list>

    <li *ngIf="!caseFields?.length" class="list-group-item justify-content-between">
      <label class="col-form-label"
        ><i>{{ 'No_case_fields_found' | translate }}</i></label
      >
    </li>
  </ul>
</div>
