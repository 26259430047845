import { TaskOption } from "./pipeline-task";
import { StepTask } from "./task-type";

export interface TAnalysisProtocolTask {
  name: string;
  stepId: string;
  type: StepTask;
  options?: TaskOption[];
  allowNewOptions?: boolean;
  enableMosaicView?: boolean;
  multiple?: boolean;
  roiSelection?: boolean;
  assetSpecific?: boolean;
  collapsed: boolean;
}

export class AnalysisProtocolTask implements TAnalysisProtocolTask {
  name: string;
  stepId: string;
  type: StepTask;
  options?: TaskOption[];
  allowNewOptions?: boolean;
  enableMosaicView?: boolean;
  multiple?: boolean;
  roiSelection?: boolean;
  assetSpecific: boolean;
  collapsed: boolean;

  constructor(data: TAnalysisProtocolTask) {
    this.name = data.name;
    this.stepId = data.stepId;
    this.type = data.type;
    this.options = data.options;
    this.allowNewOptions = data.allowNewOptions ?? true;
    this.enableMosaicView = data.enableMosaicView ?? false;
    this.multiple = data.multiple ?? false;
    this.roiSelection = data.roiSelection;
    this.assetSpecific = data.assetSpecific ?? false;
    this.collapsed = data.collapsed ?? false;
  }
}
