import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Workspace } from '@telespot/sdk';

import {
  CasesListAdaptaspotComponent,
} from '../cases-list/adaptaspot/cases-list-adaptaspot/cases-list-adaptaspot.component';
import { CasesListComponent } from '../cases-list/cases-list.component';
import { CasesListTiraspotComponent } from '../cases-list/tiraspot/cases-list-tiraspot/cases-list-tiraspot.component';

@Component({
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CasesListLoaderComponent implements OnInit {
  constructor(private cfr: ComponentFactoryResolver, private vcr: ViewContainerRef, private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (
      JSON.parse(localStorage.getItem('experiments.cases_list') ?? 'true') ||
      this.route.snapshot.queryParamMap.get('beta')
    ) {
      const workspace: Workspace = this.route.parent.snapshot.data['workspace'];
      if (workspace.config?.environment === 'tiraspot') {
        this.vcr.createComponent(this.cfr.resolveComponentFactory(CasesListTiraspotComponent));
      } else {
        this.vcr.createComponent(this.cfr.resolveComponentFactory(CasesListAdaptaspotComponent));
      }
    } else {
      this.vcr.createComponent(this.cfr.resolveComponentFactory(CasesListComponent));
    }
  }
}
