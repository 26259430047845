import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderFooterService {
  private _showHeader: BehaviorSubject<boolean> = new BehaviorSubject(true);
  get $showHeader() {
    return this._showHeader.asObservable();
  }

  private _showFooter: BehaviorSubject<boolean> = new BehaviorSubject(true);
  get $showFooter() {
    return this._showFooter.asObservable();
  }

  private _showLogo: BehaviorSubject<boolean> = new BehaviorSubject(false);
  get $showLogo() {
    return this._showLogo.asObservable();
  }

  toggleLogo(show: boolean = !this._showLogo.value) {
    this._showLogo.next(show);
  }

  toggleHeader(show: boolean = !this._showHeader.value) {
    this._showHeader.next(show);
  }

  toggleFooter(show: boolean = !this._showFooter.value) {
    this._showFooter.next(show);
  }

  toggleHeaderAndFooter(show: boolean = true) {
    this._showFooter.next(show);
    this._showHeader.next(show);
  }
}
