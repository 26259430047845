<div class="login-form" *ngIf="resetPasswordForm">
  <form [formGroup]="resetPasswordForm" (submit)="save()" autocomplete="off">
    <mat-form-field *ngIf="!mode" class="full-width">
      <input
        matInput
        autocomplete="off"
        data-lpignore="true"
        placeholder="{{ 'core.email_address' | translate | uppercase }}"
        formControlName="email"
      />
    </mat-form-field>

    <mat-form-field *ngIf="mode === 'verify'" class="full-width">
      <mat-label>
        {{ 'core.username' | translate }}
      </mat-label>
      <input matInput autocomplete="off" data-lpignore="true" formControlName="username" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>
        {{ 'label.new_password' | translate }}
      </mat-label>
      <input matInput autocomplete="off" type="password" formControlName="password" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>
        {{ 'label.repeat_password' | translate }}
      </mat-label>
      <input matInput [errorStateMatcher]="matcher" autocomplete="off" type="password" formControlName="password2" />
      <mat-error *ngIf="resetPasswordForm.errors?.password">
        {{ 'error.passwords_dont_match' | translate }}
      </mat-error>
    </mat-form-field>

    <button class="btn btn-primary btn-block" type="submit" [disabled]="!resetPasswordForm.valid" data-cy="button-submit">
      {{ (isExisting ? 'button.submit' : 'button.register') | translate | uppercase }}
    </button>
  </form>
  <div class="backend-error flex-column align-items-center" *ngIf="backendError$ | async as backendError">
    <h4 class="text-danger text-center">{{ 'label.server_error' | translate }}: "{{ backendError }}"</h4>
    <a routerLink="/login" [queryParams]="{ reset: true }">Request password reset again</a>
  </div>
</div>
