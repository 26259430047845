import { IEnvironment } from './i-environment';

export const environment: IEnvironment = {
  production: false,
  api: {
    url: 'https://api.stage.telespot.org/v1',
    appId: 'WB0vIskNlTWvNDy7NCbhLpfbQTGdeds2',
    jsKey: 'jsKey',
    liveQueryServerURL: 'ws://alb-compute-stage.stage.telespot.org/v1/parse',
    wsURL: 'wss://ws.stage.telespot.org/v1',
  },
  logging: {
    level: 1,
  },
  serverLogging: {
    url: 'https://jmntgtwug1.execute-api.eu-west-1.amazonaws.com/live/logs',
    apiKey: 'cWPoWneweB4AEPrBwTg4Ta609FML9Yc23tMDHKJj',
    appId: '4e0cf95b-c961-425b-95d1-edf8be50d3d2',
    level: 4,
  },
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  VERSION: require('../../../../../../../package.json').version,
  external_links: {
    support: {
      en: 'https://docs.google.com/forms/d/e/1FAIpQLSe8eHPZHdW_VhNE2k0aOYfuGNbbijFKYAQdmF6H_zJOIYzntw/viewform?usp=pp_url&entry.54953920=__username__&entry.263402721=Digital+product+(app+or+web+platform)&entry.329953787=TeleSpot&entry.303001118=__OS__&entry.9952778=__browser__',
      es: 'https://docs.google.com/forms/d/e/1FAIpQLSd2JXeMHTQs9kuearhLC_9GzSC6ockfViJFHqm8Bxsi50_UHw/viewform?usp=pp_url&entry.2078446507=__username__&entry.1176919279=Producto+digital+(aplicaci%C3%B3n+o+plataforma+web)&entry.515917864=Plataforma+TeleSpot&entry.128607970=__OS__&entry.962894644=__browser__',
      pt: 'https://docs.google.com/forms/d/e/1FAIpQLSdnIpt9r2KKdPGxud5v97PJ-aeXScIyL-Hg64c_qMc_G8D6ZA/viewform?usp=pp_url&entry.1795727691=__username__&entry.1576041462=Produto+digital+(aplica%C3%A7%C3%A3o+ou+plataforma)&entry.1885793429=Plataforma+TeleSpot&entry.1057982736=__OS__&entry.1691540948=__browser__',
    },
    ai: {
      analyze: 'https://api.stage.telespot.org/v1/ai/invocations',
    },
  },
  enable_logs: true,
  show_development_watermark: true,
  recorder: {
    apiKey: "null",
    enable: false,
    loggingLevel: 3,
  },
};
