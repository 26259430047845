import type Parse from "parse";

import { Asset } from "@telespot/domain";

import { ParseMapper } from "../../parse.mapper";
import { OrganizationTopology } from "../organization";
import { AssetTypeTopology, MethodTypeTopology } from "../protocols";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

export enum AssetTopology {
  TABLE = "Asset",
  ORGANIZATION = "organization",
  CREATED_BY = "createdBy",
  ASSET_FILE = "assetFile",
  ASSET_TYPE = "assetType",
  DATA = "data",
  STATE = "state",
  METHOD_TYPE = "methodType",
  APP_VERSION = "appVersion",
  FAVORITE = "fav",
  DEVICE = "device",
  STITCHING_STATE = "stitchingState",
  AI_STATE = "aiState",
}

export class ParseAssetMapper extends ParseMapper<Asset> {

  public toDomain(parseObject: Parse.Object): Asset {
    return new Asset({
      id: parseObject.id,
      organizationId: parseObject.get(AssetTopology.ORGANIZATION)?.id,
      createdById: parseObject.get(AssetTopology.CREATED_BY)?.id,
      assetFile: parseObject.get(AssetTopology.ASSET_FILE),
      assetTypeId: parseObject.get(AssetTopology.ASSET_TYPE)?.id,
      data: parseObject.get(AssetTopology.DATA),
      viewState: parseObject.get(AssetTopology.STATE),
      methodTypeId: parseObject.get(AssetTopology.METHOD_TYPE)?.id,
      appVersion: parseObject.get(AssetTopology.APP_VERSION),
      stitchingState: parseObject.get(AssetTopology.STITCHING_STATE),
      deletedAt: parseObject.get(SoftDeletableTopology.DELETED_AT),
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
      aiStatus: parseObject.get(AssetTopology.AI_STATE),
    });
  }

  public fromDomain(asset: Asset): Parse.Object {
    const parseOrganization = asset.organizationId ? this.subclasses
      .getSubclass(OrganizationTopology.TABLE)
      .createWithoutData(asset.organizationId) : undefined;

    const parseAssetType = asset.assetTypeId ? this.subclasses
      .getSubclass(AssetTypeTopology.TABLE)
      .createWithoutData(asset.assetTypeId) : undefined;

    const parseMethodType = asset.methodTypeId ? this.subclasses
      .getSubclass(MethodTypeTopology.TABLE)
      .createWithoutData(asset.methodTypeId) : undefined;

    const parseCreatedBy = asset.createdById ? this.parse.User.createWithoutData(asset.createdById) : undefined;

    const parseObject = this.parse.Object.fromJSON({
      className: AssetTopology.TABLE,
      [ObjectTopology.ID]: asset.id,
      [ObjectTopology.CREATED_AT]: asset.createdAt,
      [ObjectTopology.UPDATED_AT]: asset.updatedAt,
    })

    parseObject.set({
      [AssetTopology.ORGANIZATION]: parseOrganization,
      [AssetTopology.CREATED_BY]: parseCreatedBy,
      [AssetTopology.ASSET_TYPE]: parseAssetType,
      [AssetTopology.METHOD_TYPE]: parseMethodType,
      [AssetTopology.ASSET_FILE]: asset.assetFile,
      [AssetTopology.APP_VERSION]: asset.appVersion,
      [AssetTopology.DATA]: asset.data,
      [AssetTopology.STATE]: asset.viewState,
      [AssetTopology.STITCHING_STATE]: asset.stitchingState,
      [AssetTopology.AI_STATE]: asset.aiStatus,
      [SoftDeletableTopology.DELETED_AT]: asset.deletedAt,
    })

    return parseObject;
  }
}
