<ng-container *ngIf="data?.hasRelevantTasks && data?.userResults.length">
  <table id="global">
    <!-- In case fav assets -->
    <tr *ngIf="context === scenario.asset">
      <th style="text-align: center" colspan="100%" scope="colgroup">
        <img class="thumbnail" [src]="asset?.thumbnail | preprocess | async" alt="" />
      </th>
    </tr>

    <!-- Analysis type header -->
    <tr>
      <th class="center" style="background-color: rgb(243, 243, 243)" colspan="100%" scope="colgroup">
        {{ data.analysisType.displayName | uppercase }}
      </th>
    </tr>

    <!-- User column headers -->
    <tr>
      <th id="analysis_type_0" scope="col">
        {{ changeRows ? '' : ('core.username' | translate) }}
      </th>
      <ng-container *ngIf="data.userResults; else blank">
        <th
          id="user_col_{{ userId }}"
          scope="col"
          *ngFor="let analysis of changeRows ? data.analysisType.tasks : data.userResults; let userId = index"
          style="text-align: center"
        >
          {{ (changeRows ? analysis.displayName : analysis.createdBy?.username) | uppercase }}
        </th>
      </ng-container>
      <ng-template #blank>
        <th scope="col" id="blank_header"></th>
      </ng-template>
    </tr>

    <!-- In case of counters: number/perct -->
    <tr *ngIf="context === scenario.counter">
      <th id="analysis_type_0" scope="col">
        {{ 'label.total_rois_count' | translate }}
      </th>
      <ng-container *ngIf="data.userResults; else blank">
        <th
          id="user_col_{{ userId }}"
          scope="col"
          *ngFor="let analysis of data.userResults; let userId = index"
          style="text-align: center"
        >
          <table style="border: none;" cellspacing="0" cellpadding="0">
            <tr>
              <td style="width: 50%;  border: none;">Nº</td>
              <td style="width: 50%;  border: none; border-left: 1px solid gray;">%</td>
            </tr>
          </table>
        </th>
      </ng-container>
      <ng-template #blank>
        <th scope="col" id="blank_header"></th>
      </ng-template>
    </tr>

    <!-- One row per user -->

    <ng-container *ngIf="changeRows; else tasks">
      <ng-container *ngFor="let user of data.userResults; let taskId = index">
        <ng-container>
          <colgroup>
            <col width="130" />
            <col *ngFor="let a of relevantTasks" />
          </colgroup>
          <ng-container>
            <tr>
              <th id="task_row_{{ taskId }}" scope="row" headers="analysis_type_0">
                {{ user.createdBy.username }}
              </th>
              <ng-container *ngIf="data.userResults.length; else noData">
                <td
                  headers="task_row_{{ taskId }} user_col_{{ userId }}"
                  *ngFor="let task of relevantTasks; let userId = index"
                  style="text-align: center"
                >
                  <ng-container [ngSwitch]="task.type">
                    <ng-container *ngSwitchCase="'boolean'">
                      {{ (user.data[task.name] ? 'core.yes' : 'core.no') | translate | uppercase }}
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                      {{ user.data[task.name] }}
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- One row per task -->
    <ng-template #tasks>
      <ng-container *ngFor="let task of relevantTasks; let taskId = index">
        <ng-container>
          <colgroup>
            <col width="130" />
            <col *ngFor="let a of data.userResults" />
          </colgroup>
          <!-- Model-type tasks layout -->
          <ng-container *ngIf="task.type === 'selection'; else noClasses">
            <tr class="model-section-header">
              <th id="task_row_{{ taskId }}" scope="row" headers="analysis_type_0">
                {{ task.displayName }}
              </th>
              <th *ngFor="let userCol of data.userResults"></th>
            </tr>
            <tr *ngFor="let model of task.options; let lastIndex = last" class="model-row">
              <th>
                {{ model.displayName }}
              </th>
              <td *ngFor="let analysis of data.userResults" style="text-align: center">
                <ng-container *ngIf="task.roiSelection; else noRoiSelection" class="center">
                  <ng-container *ngIf="context === scenario.counter; else noCounter">
                    <table style="border: none;" cellspacing="0" cellpadding="0">
                      <tr>
                        <td style="width: 50%;  border: none;">
                          {{ (analysis.data[task.name] || {})[model.name].counter || 0 }}
                        </td>
                        <td style="width: 50%;  border: none; border-left: 1px solid gray;">
                          {{ (analysis.data[task.name] || {})[model.name].perc || 0 }} %
                        </td>
                      </tr>
                    </table>
                  </ng-container>
                  <ng-template #noCounter>
                    {{ ((analysis.data[task.name] || {})[model.name] || []).length }}
                  </ng-template>
                </ng-container>
                <ng-template #noRoiSelection>
                  <ng-container *ngIf="!task.multiple; else counter">
                    <ng-container *ngIf="(analysis.data[task.name] || {})[model.name] === true; else NO">
                      <i class="ri-checkbox-line"></i>
                    </ng-container>
                    <ng-template #NO>
                      <i class="ri-checkbox-blank-line"></i>
                    </ng-template>
                  </ng-container>
                  <ng-template #counter>
                    {{ (analysis.data[task.name] || {})[model.name] || 'N/A' }}
                  </ng-template>
                </ng-template>
              </td>
            </tr>
            <tr *ngIf="context === scenario.counter">
              <th>
                {{ 'label.total' | translate | uppercase }}
              </th>
              <td *ngFor="let analysis of data.userResults" style="text-align: center">
                <table style="border: none;" cellspacing="0" cellpadding="0">
                  <tr>
                    <td style="width: 50%;  border: none;">
                      {{ (analysis.data[task.name] || {})['total'].counter || 0 }}
                    </td>
                    <td style="width: 50%;  border: none; border-left: 1px solid gray;">
                      {{ (analysis.data[task.name] || {})['total'].perc || 0 }} %
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </ng-container>
          <!-- Other types layout -->
          <ng-template #noClasses>
            <tr>
              <th id="task_row_{{ taskId }}" scope="row" headers="analysis_type_0">
                {{ task.displayName }}
              </th>
              <ng-container *ngIf="data.userResults.length; else noData">
                <td
                  headers="task_row_{{ taskId }} user_col_{{ userId }}"
                  *ngFor="let analysis of data.userResults; let userId = index"
                  style="text-align: center"
                >
                  <ng-container [ngSwitch]="task.type">
                    <ng-container *ngSwitchCase="'boolean'">
                      {{ (analysis.data[task.name] ? 'core.yes' : 'core.no') | translate | uppercase }}
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                      {{ analysis.data[task.name] }}
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-template>
  </table>
</ng-container>

<!-- No data cell template -->
<ng-template #noData>
  <td headers="blank_header">
    {{ 'label.no_data' | translate }}
  </td>
</ng-template>
