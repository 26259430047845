<h3 class="flex-row align-items-center justify-content-between">
  {{ 'title.cookies_consent' | translate }}

  <button mat-icon-button matDialogClose><i class="ri-close-line"></i></button>
</h3>

<p data-cy="cookies-consent-description">
  {{ 'dialogs.cookies.description' | translate }}
</p>

<ng-container *ngFor="let group of items">
  <h4 class="flex-row align-items-center justify-content-between">
    <label class="flex-row align-items-center" id="group_{{ group.title }}"
      ><button
        *ngIf="customize"
        mat-icon-button
        class="nofocus text-primary"
        (click)="group.collapsed = !group.collapsed"
        [attr.data-cy]="'toggle-collapse-' + group._type"
      >
        <i class="ri-arrow-right-s-line" [@marker]="group.collapsed ? true : false"></i></button
      >{{ group.title | translate }}</label
    >
    <mat-slide-toggle
      aria-labelledby="group_{{ group.title }}"
      [checked]="group.allAccepted"
      (change)="toggleGroup($event, group)"
      [disabled]="group.needed"
    ></mat-slide-toggle>
  </h4>
  <p *ngIf="!!group.description">{{ group.description | translate }}</p>
  <div @collapse *ngIf="!group.collapsed">
    <ng-container *ngFor="let item of group.items">
      <div class="flex-row align-items-center justify-content-between">
        <label id="label_{{ item.title }}" class="flex-row align-items-center">
          {{ item.title | translate }}

          <i
            class="ri-information-line ms-2"
            *ngIf="item.description"
            [matTooltip]="item.description | translate"
          ></i> </label
        ><mat-slide-toggle
          [checked]="item.accepted"
          aria-label="{{ item.title }}"
          (change)="toggle($event, group, item)"
        ></mat-slide-toggle>
      </div>
    </ng-container>
  </div>
</ng-container>

<div mat-dialog-actions class="flex-row justify-content-center justify-content-lg-end">
  <button class="btn btn-flat-primary" (click)="rejectAll()" data-cy="button-reject-all-cookies">
    {{ 'button.reject_all' | translate | uppercase }}
  </button>

  <ng-container *ngIf="!customized; else acceptSelectedTpl">
    <button class="btn btn-primary" (click)="acceptAll()" data-cy="button-accept-all-cookies">
      {{ 'button.accept_all' | translate | uppercase }}
    </button>
  </ng-container>

  <ng-template #acceptSelectedTpl>
    <button class="btn btn-primary" (click)="submit()" data-cy="button-accept-selected-cookies">
      {{ 'button.accept_selected' | translate | uppercase }}
    </button>
  </ng-template>
</div>
