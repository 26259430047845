import { createAction, props } from "@ngrx/store";
import { Label } from "../protocol/protocol.reducer";
import { IAssetROI } from "@telespot/sdk";
import { AnalysisLabel, IAnalysis, Mode, POI, ROI } from "./analysis.reducer";

export interface NewXOI {
  x: number;
  y: number;
  w?: number;
  h?: number;
  time?: number;
  labels: AnalysisLabel[];
}

export interface LabelInfo {
  activeAssetId: string;
  analysisTypeId: string;
  labels: string[];
}

export interface AnalysisRequest {
  createdBy: Parse.Pointer;
  sampleId: string;
  assetId?: string;
  analysisTypeId: string;
}

export interface Change<T> {
  previous: T;
  current: T;
  assetId?: string;
  maskFileName?: string;
}

export const analysisActionError = createAction(
  "[ANALYSIS] Action Error",
  props<{ error: string }>()
);

export const loadAssetAnalysis = createAction(
  "[ANALYSIS] Load Asset Analysis",
  props<{
    assetId: string;
    createdBy: Parse.Pointer;
    sampleId: string;
  }>()
);

export const assetAnalysisLoaded = createAction(
  "[ANALYSIS] Asset Analysis Loaded",
  props<{
    analysis: IAnalysis[];
    rois: ROI[];
    customLabels: Label[];
  }>()
);

export const updateAnalysis = createAction(
  "[ANALYSIS] Update Analysis",
  props<{ analysis: IAnalysis }>()
);

export const loadSampleAnalysis = createAction(
  "[ANALYSIS] Load Sample Analysis",
  props<{
    sampleId: string;
    createdBy: Parse.Pointer;
  }>()
);

export const createAnalysis = createAction(
  "[ANALYSIS] Create Analysis",
  props<{
    data?: any;
    createdBy: Parse.Pointer;
    sampleId: string;
    assetId?: string;
    analysisTypeId: string;
  }>()
);

export const copyAnalysis = createAction(
  "[ANALYSIS] Copy Analysis",
  props<{
    authUser: Parse.Pointer;
  }>()
);
export const analysisCopied = createAction(
  "[ANALYSIS] Analysis copied",
  props<{
    authUser: Parse.Pointer;
    activeAnalysisIds: string[];
  }>()
);

export const createAnalysisFromROIs = createAction(
  "[ANALYSIS] Create new Analysis when creating a ROI",
  props<{
    analysesRequest: AnalysisRequest[];
    selectedLabels: Label[];
    rois: IAssetROI[];
  }>()
);

export const createSegmAnalysis = createAction(
  "[ANALYSIS] Create new segmentation Analysis"
);

export const segmAnalysisCreated = createAction(
  "[ANALYSIS] New segmentation Analysis created",
  props<{
    assetId: string;
    sampleId: string;
    createdBy: Parse.Pointer;
    analysisTypeId: string;
  }>()
);

export const sampleAnalysisLoaded = createAction(
  "[ANALYSIS] Sample Analysis Loaded",
  props<{ analysis: IAnalysis[] }>()
);
export const preSyncAnalysis = createAction("[ANALYSIS] Pre-Sync Changes");
export const syncAnalysis = createAction("[ANALYSIS] Sync Changes");
export const analysisSynced = createAction(
  "[ANALYSIS] Changes Synced",
  props<{ idChanges: Change<string>[] }>()
);
export const analysisUnsynced = createAction(
  "[ANALYSIS] Mark Analysis as unsynced",
  props<{ analysis: IAnalysis }>()
);

export const exitAnalysis = createAction("[ANALYSIS] Exit Analysis");

export const setROIs = createAction(
  "[ROIS] Set ROI",
  props<{ rois: NewXOI[] }>()
);
export const removeROIs = createAction(
  "[ROIS] Remove ROIs",
  props<{ rois?: ROI[]; selectedROIs?: (ROI | POI)[] }>()
);
export const setSelectedROIs = createAction(
  "[ROIS] Select rois",
  props<{ rois: (ROI | POI)[]; replace?: boolean }>()
);
export const selectROIsFromRegion = createAction(
  "[ROIS] Select rois from region",
  props<{ bounds?: IAssetROI; activeAnalysisIds?: string[] }>()
);
export const updateROI = createAction(
  "[ROIS] Update rois",
  props<{ roi: ROI; changes?: Partial<ROI> }>()
);
export const updateROILabels = createAction(
  "[ROIS] Update Label",
  props<{ label: LabelInfo; replacePrevLabels: boolean; authUserId: string }>()
);
export const switchMode = createAction(
  "[ANALYSIS] Switch Mode",
  props<{ mode: Mode }>()
);

export const discardAnalysis = createAction(
  "[ANALYSIS] Discard Analysis",
  props<{ analysisDiscarded: AnalysisRequest[]; hasROI: boolean }>()
);

export const analysisDiscarded = createAction(
  "[ANALYSIS] Analysis discarded",
  props<{ analysisDiscardedIds: string[]; hasROI: boolean }>()
);
export const discardAnalysisChange = createAction(
  "[ANALYSIS] Discard change from Analysis",
  props<{ analysis: IAnalysis }>()
);
export const discardRemoveROIs = createAction(
  "[ROIS] Discard remove ROIs change",
  props<{ rois: ROI[] }>()
);
export const discardUpdateROI = createAction(
  "[ROIS] Discard update ROI change",
  props<{ rois: ROI[] }>()
);
export const discardSetROIs = createAction(
  "[ROIS] Discard set ROIs",
  props<{ xois: NewXOI[] }>()
);
export const discardSync = createAction(
  "[ROIS] Sync analysis after discarding changes"
);
export const setReviewCounters = createAction(
  "[ROIS] Set label counters for review mode",
  props<{
    newROIs: (ROI | POI)[];
    oldROIs: (ROI | POI)[];
    authUser: Parse.Pointer;
  }>()
);

export const loadingMask = createAction(
  "[ROIS] Set loadingMask value",
  props<{
    loading: boolean;
  }>()
);

export const updateLoading = createAction(
  "[ROIS] Set loading value",
  props<{
    loading: boolean;
  }>()
);
