import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationDialogComponent, ConfirmDialogConfig } from "@shared/ui";
import {
  TiraspotService,
  TiraspotUtils,
} from "@telespot/analysis-refactor/data-access";
import { AuthService, DataService } from "@telespot/web-core";
import {
  AnalysisState,
  Asset,
  Case,
  MethodType,
  Query,
  Sample,
  SampleAsset,
  StateName,
  Workspace,
} from "@telespot/sdk";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  Subject,
} from "rxjs";
import { map, startWith, take, takeUntil, tap } from "rxjs/operators";

import { TIRASPOT_COLUMNS } from "../../data/tiraspot-filters";

interface ITiraspotData {
  sampleAsset: SampleAsset;
  warning: "corrected" | "disagreement" | undefined;
}

interface IAdaptaspotData {
  case: Case;
  globalState: string;
  userState: string;
  userStates: AnalysisState[];
}

@Component({
  selector: "ts-cases-list",
  templateUrl: "./cases-list.component.html",
  styleUrls: ["./cases-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CasesListComponent implements OnInit, OnDestroy {
  private _tiraspot: boolean;
  public get tiraspot(): boolean {
    return this._tiraspot;
  }

  private _workspace: Workspace;
  private _filterInit = false;
  private _dialogRef: MatDialogRef<any>;

  public showWarnings = true;

  tiraspotColumnsToDisplay = [
    "username",
    "dateOfCapture",
    "test",
    // 'user_results',
    // 'ai_results',
    "tiraspot_result",
    "move_columns",
  ];
  adaptaspotColumnsToDisplay = [
    "currentStateIndicator",
    "currentState",
    "name",
    "createdAt" /* , 'createdBy' */,
    "actions",
    "menu",
  ];

  public get encrypted(): boolean {
    return !!this._workspace?.caseType?.fields.find(
      (f) => f.type === "case_name" && f.encrypted
    );
  }

  private _columnsToShow = [
    { name: "username", checked: true, displayName: "core.identifier" },
    { name: "dateOfCapture", checked: true, displayName: "Created" },
    { name: "test", checked: true, displayName: "Test" },
    // { name: 'user_results', checked: false, displayName: 'User Results' },
    // { name: 'ai_results', checked: false, displayName: 'AI' },
    { name: "tiraspot_result", checked: true, displayName: "Final result" },
  ];
  get columnsToShow() {
    return this._columnsToShow;
  }
  private _columnsToDisplay$ = new BehaviorSubject<Array<string>>([]);
  public readonly columnsToDisplay$ = this._columnsToDisplay$.asObservable();

  pageSizeOptions: number[] = [10, 20];

  @ViewChild(MatSort) sort: MatSort;

  private _cases$ = new BehaviorSubject<IAdaptaspotData[] | ITiraspotData[]>(
    []
  );
  get cases$() {
    return this._cases$.asObservable();
  }
  // TODO: replace with valid count
  numCases$: Observable<number>;
  protected workspaceId: string;

  showDateRangePicker = false;
  showColumns = false;
  showFilters = {};

  pageIndex = 0;
  pageSize = 10;
  order: {
    by: string;
    dir: "asc" | "desc";
  } = { by: "createdAt", dir: "desc" };
  filter = "";
  tiraspotUserResultQueryTerm: string[] = [];
  tiraspotTestQueryTerm: string[] = [];
  tiraspotAIResultQueryTerm: string[] = [];
  tiraspotFinalResultQueryTerm: string[] = [];

  _dateRange = "any";
  _newerThan: number;
  _olderThan: number;

  private queryParams: any;
  private _destroy$ = new Subject<void>();
  public readonly mobile$ = this.breakpointObserver
    .observe([Breakpoints.Handset])
    .pipe(map((_) => _.matches));
  public warningAI = TiraspotUtils.warningAI;

  public tiraspotIAEnabled$: Observable<boolean>;

  constructor(
    protected dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private _logger: LoggerService,
    private _tiraspotService: TiraspotService
  ) {
    this._workspace = this.route.snapshot.parent.data.workspace;
    this.tiraspotIAEnabled$ = this._tiraspotService?.isAIenabled(
      this._workspace.caseType
    );
    this._tiraspot =
      this.route.snapshot.parent.data.workspace?.config?.environment ===
      "tiraspot";
    combineLatest([
      this.breakpointObserver.observe(["(min-width: 600px)"]),
      this._tiraspotService.isAIenabled(this._workspace.caseType),
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([result, tiraspotAIenabled]) => {
        const finalColumns = result.matches
          ? this._tiraspot
            ? this.tiraspotColumnsToDisplay.filter(
                (column) => tiraspotAIenabled || column !== "ai_results"
              )
            : this.adaptaspotColumnsToDisplay
          : this._tiraspot
          ? this.tiraspotColumnsToDisplay.filter(
              (column) => tiraspotAIenabled || column !== "ai_results"
            )
          : [
              "currentStateIndicator",
              "label_mobile",
              "name",
              "createdAt_mobile",
              "actions",
            ];
        this._columnsToDisplay$.next(finalColumns);
      });
    if (this._tiraspot) {
      this.showFilters = TIRASPOT_COLUMNS.reduce((acc, current) => {
        if (current.filtering) {
          acc[current.filters?.name] = false;
        }
        return acc;
      }, {});
    }
  }

  ngOnInit() {
    if (!this.route.parent) return;
    combineLatest([
      this.route.parent.paramMap,
      this.route.parent.queryParamMap.pipe(
        map((queryParams) => {
          this.queryParams = queryParams;
          return {
            page: Number(queryParams.get("page")),
            pageSize:
              Number(queryParams.get("pageSize")) ||
              Math.max(...this.pageSizeOptions),
            olderThan: Number(queryParams.get("olderThan")),
            newerThan: Number(queryParams.get("newerThan")),
            filter: queryParams.get("filter") ?? "",
            user_result: queryParams.getAll("user_result") ?? [],
            final_result: queryParams.getAll("final_result") ?? [],
            test: queryParams.getAll("test") ?? "",
            ai_result: queryParams.get("ai_result"),
          };
        }),
        startWith({
          page: 0,
          pageSize: Math.max(...this.pageSizeOptions),
          olderThan: undefined,
          newerThan: undefined,
          filter: undefined,
          user_result: undefined,
          final_result: undefined,
          test: undefined,
          ai_result: undefined,
        })
      ),
      this.tiraspotIAEnabled$,
    ])
      .pipe(
        map(([params, queryParams, enabledAI]) => {
          const workspaceId = params.get("workspaceId");
          const page = queryParams.page;
          const pageSize = queryParams.pageSize;
          const maxPageSize = Math.max(...this.pageSizeOptions);
          if (pageSize > maxPageSize) {
            this.router.navigate([], {
              queryParams: {
                page: page !== undefined ? page : this.pageIndex,
                pageSize: maxPageSize,
              },
            });
            return null;
          }
          return {
            workspaceId,
            page: page !== undefined ? page : this.pageIndex,
            pageSize: Math.min(pageSize, Math.max(...this.pageSizeOptions)),
            olderThan: queryParams.olderThan,
            newerThan: queryParams.newerThan,
            filter: queryParams.filter,
            test: queryParams.test,
            user_result: enabledAI
              ? queryParams.user_result
              : queryParams.final_result,
            ai_result: queryParams.ai_result,
            final_result: enabledAI ? queryParams.final_result : [],
          };
        })
      )
      // .pipe(skip(1)) // WTF
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (params) => {
        if (params) {
          this.pageSize = params.pageSize;
          this.pageIndex = params.page;
          this.workspaceId = params.workspaceId;
          this._newerThan = params.newerThan;
          this._olderThan = params.olderThan;
          this.filter = params.filter;
          this.tiraspotTestQueryTerm = params.test ?? [];
          this.tiraspotUserResultQueryTerm = params.user_result ?? [];
          this.tiraspotAIResultQueryTerm = params.ai_result ?? [];
          this.tiraspotFinalResultQueryTerm = params.final_result ?? [];

          await this.getCases();
          await this._initTiraspotTests();
        }
      });
  }

  private async _initTiraspotTests() {
    if (!this.workspaceId || this._filterInit) return;

    const workspace = await this.dataService.get(
      this.workspaceId,
      new Query(Workspace).include(["caseType.methodTypes"])
    );

    const answerFilterOptions = TiraspotUtils.getAnswerFilterOptionsFrom(
      workspace?.caseType
    );
    const testFilterOptions = TiraspotUtils.getTestFilterOptionsFrom(
      workspace?.caseType
    );

    TIRASPOT_COLUMNS.find(
      (tc) => tc.name === "tiraspot_result"
    ).filters.options = answerFilterOptions as any[];
    TIRASPOT_COLUMNS.find((tc) => tc.name === "test").filters.options =
      testFilterOptions as any[];

    this._filterInit = true;
  }

  public updateColumns(event) {
    let newColumns;
    const columnSelected = event.source.id;
    if (event.source.checked) {
      const columnIndex = this._columnsToShow
        .map((c) => c.name)
        .indexOf(columnSelected);
      newColumns = this._columnsToDisplay$.value;
      newColumns.splice(columnIndex, 0, columnSelected);
    } else {
      newColumns = this._columnsToDisplay$.value.filter(
        (c) => c !== columnSelected
      );
    }
    this._columnsToDisplay$.next(newColumns);
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  async getCases() {
    let query;
    if (this._tiraspot) {
      const items: ITiraspotData[] = await this.getTiraspotItems();
      this._cases$.next(items);
    } else {
      const items: IAdaptaspotData[] = await this.getAdaptaspotCases();
      this._cases$.next(items);
    }
  }

  private _addQueryConstraints(query: Query): void {
    // Date range filter
    const today = new Date();
    this._olderThan &&
      query.lessThan(
        "createdAt",
        new Date(
          today.getFullYear(),
          today.getMonth() - this._olderThan,
          today.getDay()
        )
      );
    this._newerThan &&
      query.greaterThan(
        "createdAt",
        new Date(
          today.getFullYear(),
          today.getMonth() - this._newerThan,
          today.getDay()
        )
      );

    switch (this.order.dir) {
      case "desc":
        query.descending(this.order.by);
        break;
      case "asc":
        query.ascending(this.order.by);
        break;
    }
  }

  private _updateCountObservable(query: Query): void {
    this.numCases$ = from(this.dataService.count(query));
  }

  private async getAdaptaspotCases(): Promise<IAdaptaspotData[]> {
    const query = new Query(Case)
      .equalTo("workspace", Workspace.createWithoutData(this.workspaceId))
      .include(["createdBy"])
      .skip(this.pageIndex * this.pageSize)
      .limit(this.pageSize);
    if (this.filter?.length) {
      if (this.encrypted) {
        // Encrypted case name search
        query.equalTo(
          "organization",
          this.authService.currentOrganizationValue
        );
        query.equalTo("name", this.filter);
      } else {
        query.contains("name", this.filter);
      }
    }
    this._addQueryConstraints(query);
    this._updateCountObservable(query);
    const cases = await this.dataService.find(query);

    const statesQuery = new Query(AnalysisState)
      .containedIn("case", cases)
      .exists("case")
      .include(["user"]);
    const statesCount = await this.dataService.count(statesQuery);
    const states = await this.dataService.find(statesQuery.limit(statesCount));
    return cases.map((_case) => {
      const userState =
        states.find(
          (s) =>
            s.case.id === _case.id &&
            s.user?.id === this.authService.currentUser?.id
        )?.state ?? "pending";
      return {
        case: _case,
        globalState:
          (_case.currentState?.state ?? userState) === "analyzed"
            ? "closed"
            : userState,
        userState: userState,
        userStates: states.filter((s) => s.case.id === _case.id),
      };
    });
  }

  private async getTiraspotItems(): Promise<ITiraspotData[]> {
    const casesQuery = new Query(Case).equalTo(
      "workspace",
      Workspace.createWithoutData(this.workspaceId)
    );
    if (this.filter !== undefined && this.filter !== "")
      casesQuery.contains("name", this.filter);
    const queries: Query<Asset>[] = [];
    const sampleQuery = new Query(Sample);
    if (this._workspace.caseType.hasEncryptedIdentifier) {
      const matchingCases = await this.dataService.findAll(casesQuery);
      sampleQuery.containedIn("case", matchingCases);
    } else {
      sampleQuery.matchesQuery("case", casesQuery);
    }

    const matchFilters: Query<Asset>[] = [];
    if (this.tiraspotAIResultQueryTerm?.length) {
      matchFilters.push(
        new Query(Asset).containedIn(
          "data.tiraspot.ml_result.ml_output_label",
          this.tiraspotAIResultQueryTerm
        )
      );
    }
    if (this.tiraspotTestQueryTerm?.length) {
      matchFilters.push(
        new Query(Asset).containedIn(
          "data.tiraspot.poct",
          this.tiraspotTestQueryTerm
        )
      );
    }
    if (this.tiraspotUserResultQueryTerm?.length) {
      matchFilters.push(
        new Query(Asset).containedIn(
          "data.tiraspot.result",
          Array.isArray(this.tiraspotUserResultQueryTerm)
            ? this.tiraspotUserResultQueryTerm
            : [this.tiraspotUserResultQueryTerm]
        )
      );
    }

    if (this.tiraspotFinalResultQueryTerm?.length) {
      queries.push(
        new Query(Asset).containedIn(
          "data.tiraspot.final_POCT_result",
          this.tiraspotFinalResultQueryTerm
        ),
        new Query(Asset)
          .equalTo("data.tiraspot.final_POCT_result", "")
          .containedIn(
            "data.tiraspot.result",
            this.tiraspotFinalResultQueryTerm
          ),
        new Query(Asset)
          .doesNotExist("data.tiraspot.final_POCT_result")
          .containedIn(
            "data.tiraspot.result",
            this.tiraspotFinalResultQueryTerm
          )
      );
      matchFilters.push(Query.or(...queries));
    }

    const sampleAssetQuery = new Query(SampleAsset)
      .matchesQuery("sample", sampleQuery)
      .include(["asset.createdBy", "asset.case.createdBy"])
      .select("asset", "dateOfCapture")
      .skip(this.pageIndex * this.pageSize)
      .limit(this.pageSize);

    if (matchFilters.length) {
      sampleAssetQuery.matchesQuery("asset", Query.and(...matchFilters));
    }

    this._addQueryConstraints(sampleAssetQuery);
    this._updateCountObservable(sampleAssetQuery);
    const sampleAssets = await this.dataService.find(sampleAssetQuery);

    return sampleAssets.map((sampleAsset) => {
      const item: ITiraspotData = {
        sampleAsset,
        warning: this.warningAI(sampleAsset)
          ? (sampleAsset.asset.data?.tiraspot?.final_POCT_result ?? "") === ""
            ? "disagreement"
            : "corrected"
          : undefined,
      };
      return item;
    });
  }

  changePage(event) {
    const { params } = this.queryParams;
    const queryParams = {
      ...params,
      page: event.pageIndex,
      pageSize: event.pageSize,
    };
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: "merge",
    });
  }

  sortCases(event) {
    this.order = {
      by: event.active,
      dir: event.direction,
    };
    this.getCases();
  }

  getRoute(_row: IAdaptaspotData | ITiraspotData): string[] {
    const caseId =
      (_row as IAdaptaspotData)?.case?.id ??
      (_row as ITiraspotData).sampleAsset?.sample?.case?.id;
    switch (
      (_row as IAdaptaspotData)?.case?.currentState?.state ||
      (_row as ITiraspotData).sampleAsset?.sample?.case?.currentState?.state
    ) {
      case StateName.pending:
      case StateName.read:
      case StateName.rewiew:
      case StateName.viewing:
        return ["/analyze", "cases", caseId];
      case StateName.closed:
      case StateName.analyzed:
        return this.authService.currentUser.roleNames.some(
          (roleName) => roleName === "admin" || roleName === "analystmanager"
        )
          ? ["/reports", "cases", caseId]
          : null;
      default:
        this._tiraspot ??
          this._logger.error(
            `Unable to retrieve appropriate route for case "${
              (_row as IAdaptaspotData)?.case?.name
            }" with state "${
              (_row as IAdaptaspotData).case?.currentState?.state
            }"`
          );
        return null;
    }
  }

  // Case actions
  removeCase(_case) {
    const dialogConfig: ConfirmDialogConfig = {
      title: `Remove case "${_case.name}"?`,
      text: `This action cannot be undone`,
      acceptButtonText: "core.remove",
      cancelButtonText: "button.cancel",
    };
    this._dialogRef =
      this._dialogRef ||
      this.dialog.open(ConfirmationDialogComponent, { data: dialogConfig });
    this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe(async (answer) => {
        switch (answer) {
          case "accept":
            try {
              await this.dataService.softDelete(_case);
              this._logger.info(`Case removed`);
              this.getCases();
            } catch (err) {
              this._logger.error(`Error deleting case`, err.message);
            }
            break;
          case "cancel":
            break;
        }
      });
  }

  getColumn(value: string) {
    return TIRASPOT_COLUMNS.find((tc) => tc.name === value);
  }

  public getTiraspotAnswer(
    asset: ITiraspotData,
    type: "ai" | "user" | "final"
  ): string[] {
    switch (type) {
      case "ai":
        return TiraspotUtils.getTiraspotAnswer(
          asset.sampleAsset.asset.data?.tiraspot?.ml_result?.ml_output_label
        );
      case "user":
        return TiraspotUtils.getTiraspotAnswer(
          asset.sampleAsset.asset.data?.tiraspot?.result
        );
      case "final": {
        const final_POCT_result =
          asset.sampleAsset.asset.data?.tiraspot?.final_POCT_result;
        return TiraspotUtils.getTiraspotAnswer(
          (final_POCT_result?.length ? final_POCT_result : undefined) ??
            asset.sampleAsset.asset?.data?.tiraspot?.result
        );
      }
    }
  }

  public getTiraspotAnswerStyle(answer: string) {
    return `poct-result--${TiraspotUtils.getTiraspotAnswerStyle(answer).style}`;
  }

  public getTiraspotAnswerStyleConfig(answer: string) {
    return TiraspotUtils.getTiraspotAnswerStyle(answer);
  }

  public toggleFilter(filter: string): void {
    const newConfig = Object.keys(this.showFilters).reduce(
      (show, current) => ({
        ...show,
        [current]: filter === current && !this.showFilters[current],
      }),
      {}
    );
    this.showFilters = {
      ...newConfig,
      ...(!(filter in newConfig) ? { [filter]: true } : {}),
    };
  }
}
