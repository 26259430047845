import type Parse from "parse";

import { SampleAsset } from "@telespot/domain";

import { ParseAssetMapper } from "./parse-asset.mapper";
import { ParseMapper } from "../../parse.mapper";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

import { SampleTopology } from "./parse-sample.mapper";

export enum SampleAssetTopology {
  TABLE = "SampleAsset",
  SAMPLE = "sample",
  ASSET = "asset",
  INDEX = "index",
  DATE_OF_CAPTURE = "dateOfCapture",
  TAG = "tag",
  UUID = "uuid",
}

export class ParseSampleAssetMapper extends ParseMapper<SampleAsset> {

  private readonly assetMapper = new ParseAssetMapper(this.parse);

  public toDomain(parseObject: Parse.Object): SampleAsset {

    const parseAsset = parseObject.get(SampleAssetTopology.ASSET);

    const dateOfCapture = parseObject.get(SampleAssetTopology.DATE_OF_CAPTURE);

    return new SampleAsset({
      id: parseObject.id,
      sampleId: parseObject.get(SampleAssetTopology.SAMPLE)?.id,
      asset: this.assetMapper.toDomain(parseAsset),
      deletedAt: parseObject.get(SoftDeletableTopology.DELETED_AT),
      index: parseObject.get(SampleAssetTopology.INDEX),
      dateOfCapture: dateOfCapture ? new Date(dateOfCapture) : undefined,
      tag: parseObject.get(SampleAssetTopology.TAG),
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
    });
  }

  public fromDomain(sampleAsset: SampleAsset): Parse.Object {
    const parseSample = this.subclasses
      .getSubclass(SampleTopology.TABLE)
      .createWithoutData(sampleAsset.sampleId);

    const parseObject = this.parse.Object.fromJSON({
      className: SampleAssetTopology.TABLE,
      [ObjectTopology.ID]: sampleAsset.id,
      [ObjectTopology.CREATED_AT]: sampleAsset.createdAt,
      [ObjectTopology.UPDATED_AT]: sampleAsset.updatedAt,
    })

    parseObject.set({
      [SoftDeletableTopology.DELETED_AT]: sampleAsset.deletedAt,
      [SampleAssetTopology.SAMPLE]: parseSample,
      [SampleAssetTopology.ASSET]: this.assetMapper.fromDomain(
        sampleAsset.asset
      ),
      [SampleAssetTopology.INDEX]: sampleAsset.index,
      [SampleAssetTopology.TAG]: sampleAsset.tag,
      [SampleAssetTopology.DATE_OF_CAPTURE]: sampleAsset.dateOfCapture,
      [SampleAssetTopology.UUID]: sampleAsset.uuid,
    });

    return parseObject;
  }
}
