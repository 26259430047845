import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@telespot/web-core';
import { AssetType, DeviceType, Query } from '@telespot/sdk';
import { defer, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ts-asset-type-editor',
  templateUrl: './asset-type-editor.component.html',
  styleUrls: ['./asset-type-editor.component.scss'],
})
export class AssetTypeEditorComponent implements OnInit, OnDestroy {
  assetType: AssetType;

  private _deviceTypesQuery = new Query(DeviceType).select('name');
  deviceTypes$: Observable<DeviceType[]> = defer(() => this.dataService.findAll(this._deviceTypesQuery));

  form: UntypedFormGroup;

  constructor(private dataService: DataService, private route: ActivatedRoute, private location: Location) {}

  private _destroy$ = new Subject<void>();

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this._destroy$)).subscribe(async (params) => {
      const id = params.get('id');
      if (id) {
        this.assetType = await this.dataService.get(id, new Query(AssetType).include(['deviceTypes']));
      } else {
        this.assetType = new AssetType();
      }
      this.deviceTypes$.pipe(take(1), takeUntil(this._destroy$)).subscribe((_) => {
        this.setupForm();
      });
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  setupForm() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.assetType.name || ''),
      version: new UntypedFormControl(this.assetType.version || ''),
      description: new UntypedFormControl(this.assetType.description || ''),
      type: new UntypedFormControl(this.assetType.type || ''),
      device_types: new UntypedFormControl(this.assetType.deviceTypes || []),
    });
  }

  async save() {
    this.assetType.name = this.form.value['name'];
    this.assetType.type = this.form.value['type'];
    this.assetType.version = this.form.value['version'];
    this.assetType.description = this.form.value['description'];
    this.assetType.setDeviceTypes(this.form.value['device_types']);
    await this.dataService.save(this.assetType);
    this.location.back();
  }

  cancel() {
    if (!this.assetType.isNew()) {
      this.assetType.revert();
    }
    this.location.back();
  }

  sameId(item1, item2) {
    return item1.id === item2.id;
  }
}
