import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  ConfirmationDialogComponent,
  DataTableConfig,
  SplDataSource,
} from "@shared/ui";
import { DataService } from "@telespot/web-core";
import { Case, CaseType, Query } from "@telespot/sdk";
import { BehaviorSubject, defer, Observable, Subject } from "rxjs";
import { map, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  DataFilterComponent,
  FindRequest,
  IColumn,
  RowData,
} from "@shared/utils";

interface CaseTypesRowData extends RowData {
  resource: CaseType;
  id: string;
}

@Component({
  selector: "ts-case-types",
  templateUrl: "./case-types.component.html",
  styleUrls: ["./case-types.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseTypesComponent
  extends DataFilterComponent
  implements OnInit, OnDestroy
{
  private refresh$ = new BehaviorSubject<void>(undefined);

  /**
   * list of CaseTypes
   *
   * @type {CaseType[]}
   * @memberof CaseTypesComponent
   */
  caseTypes$: Observable<CaseType[]> = this.refresh$.pipe(
    switchMap(() =>
      defer(() => this.dataService.findAll(new Query(CaseType))).pipe(
        map((ct) =>
          ct.sort((a, b) =>
            a.name?.toLowerCase().localeCompare(b.name?.toLowerCase())
          )
        )
      )
    )
  );

  private _destroy$ = new Subject<void>();
  private _dialogRef: MatDialogRef<any>;

  public previousRequest: FindRequest;
  public caseTypeDeleted = false;
  public dataSource = new SplDataSource<CaseTypesRowData>(this);
  public tableConfig: DataTableConfig<CaseTypesRowData>;
  public previousCount = 0;
  public oldAvailable: CaseType[];
  public tableName = "casetypes_list";
  public columns: IColumn[] = [
    {
      name: this.tableName,
      value: (item) => item,
    },
  ];
  public klass = CaseType.className;

  constructor(
    public dataService: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    super(dataService);
  }

  ngOnInit() {
    this.configureTable({
      hideHeader: true,
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  /**
   * Removes a CaseType from the list
   *
   * @param {CaseType} caseType
   * @memberof CaseTypesComponent
   */
  async removeCaseType(caseType: CaseType): Promise<void> {
    const someCase = await this.dataService.first(
      new Query(Case).equalTo("caseType", caseType)
    );
    if (someCase) {
      this._dialogRef =
        this._dialogRef ||
        this.dialog.open(ConfirmationDialogComponent, {
          data: {
            title:
              "There exist cases that use this casetype. You cannot remove this casetype",
            acceptButtonText: "Ok",
          },
        });
      this._dialogRef
        .afterClosed()
        .pipe(
          take(1),
          tap((_) => (this._dialogRef = undefined)),
          takeUntil(this._destroy$)
        )
        .subscribe();
      return;
    } else {
      this._dialogRef =
        this._dialogRef ||
        this.dialog.open(ConfirmationDialogComponent, {
          data: {
            title: `Are you sure you want to remove method "${caseType.name}"?`,
            cancelButtonText: "Cancel",
            acceptButtonText: "Ok",
          },
        });
      this._dialogRef
        .afterClosed()
        .pipe(
          take(1),
          tap((_) => (this._dialogRef = undefined)),
          takeUntil(this._destroy$)
        )
        .subscribe(async (ans) => {
          if (ans === "accept") {
            try {
              await this.dataService.softDelete(caseType);
              // TODO: REVIEW: this is kind of redundant, it may be better to optimistically update the list
              this.refresh$.next();
            } catch (error) {
              console.error(error);
              this.snackBar.open(error, null, { duration: 2000 });
            }
          }
        });
    }
  }
}
