import { RDT, ResourceTypes } from "@telespot/domain";
import { IListRDTsHandler, ListRDTs } from "@telespot/use-cases";

import { ResourceTopology, ParseRDTsMapper } from "../../repositories";
import { ParseBaseRepository } from "../../parse-base.repository";
import { ObjectTopology } from "../../parse.topology";

/**
 * Default implementation of {@link IListRDTsHandler} interface.
 *
 * @group Protocols
 * @category Handlers
 */
export class ListRDTsHandler extends ParseBaseRepository implements IListRDTsHandler {

  public async execute(command: ListRDTs): Promise<RDT[]> {

    const { limit, nextToken } = command.details;

    const resourceQuery = new this.parse.Query(ResourceTopology.TABLE)
      .equalTo(ResourceTopology.TYPE, ResourceTypes.mask)
      .include(ResourceTopology.RESOURCES)
      .ascending(ObjectTopology.ID)
      .limit(limit);

    if (nextToken) resourceQuery.greaterThan(ObjectTopology.ID, nextToken.id)

    const rdts = await resourceQuery.find(this.options);

    return rdts.map(ParseRDTsMapper.toView);
  }

}
