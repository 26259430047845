<div class="asset-filters" *ngIf="assetFilters$ | async as filters">
  <div class="filter-div">
    <i
      class="ri-shape-line badge-analyzed"
      data-bs-toggle="tooltip"
      [matTooltip]="'tooltip.analyzed_images_filter' | translate"
      (click)="updateFilter('analyzed')"
      [ngStyle]="{ color: filters.analyzed ? '' : '#808285' }"
    >
    </i>
    <i
      class="badge-fav"
      data-bs-toggle="tooltip"
      [matTooltip]="'tooltip.acquisition_favourite_filter' | translate"
      [ngClass]="{ 'ri-star-line': !filters.fav, 'ri-star-fill': filters.fav }"
      (click)="updateFilter('fav')"
    >
    </i>
    <i
      class="badge-fav"
      data-bs-toggle="tooltip"
      [matTooltip]="'tooltip.analysis_favourite_filter' | translate"
      (click)="updateFilter('favAnalysis')"
      [ngClass]="{
        'ri-heart-3-line': !filters.favAnalysis,
        'ri-heart-3-fill': filters.favAnalysis
      }"
    >
    </i>
    <i
      class="badge-large"
      data-bs-toggle="tooltip"
      [matTooltip]="'tooltip.analysis_large_objects_filter' | translate"
      (click)="updateFilter('largeObjects')"
      [ngClass]="{
        'ri-shapes-line': !filters.largeObjects,
        'ri-shapes-fill': filters.largeObjects
      }"
    >
    </i>
  </div>
</div>
