import { ParseObject } from '../';

export function getUniquePropertyValues<S extends ParseObject>(items: ParseObject[] = [], field: string): S[] {
  if (!items || items.length === 0) return [];
  if (!items[0].has(field)) {
    throw new Error(`Property ${field} does not exist on this kind of object`);
  }
  return items.reduce((uniqueValues, currentAnalysis) => {
    const value = currentAnalysis.get(field) as S;
    if (uniqueValues.findIndex((v) => v.id === value.id) === -1) {
      uniqueValues.push(value);
    }
    return uniqueValues;
  }, [] as S[]);
}
