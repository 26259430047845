<div class="scroll-container">
  <form [formGroup]="form" *ngIf="form" (submit)="submit()" (reset)="cancel()">
    <div class="card-body">
      <mat-form-field style="width: 100%">
        <input
          matInput
          type="text"
          placeholder="{{ 'label.display_name' | translate | uppercase }}"
          [value]="form.get('displayName').value?.name"
          (input)="onInputChange($event, false)"
          (focus)="onInputFocus()"
          (blur)="onInputBlur()"
        />

        <ts-label-suggester
          *ngIf="isLabelSuggesterVisibleForCategory"
          [inputValue]="inputCategoryValue$"
          (filteredLabels$)="filteredLabels$ = $event"
          (click)="onLabelSuggesterClick($event)"
          (optionSelected)="onCategorySelected($event)"
          class="custom-autocomplete"
        ></ts-label-suggester>

        <mat-error
          *ngIf="form.get('displayName').hasError('displayNameInvalid')"
        >
          {{ "info.invalid_task_display_name" | translate }}
        </mat-error>
      </mat-form-field>

      <mat-label class="field-label">{{
        "core.type" | translate | uppercase
      }}</mat-label>
      <mat-form-field class="full-width" [floatLabel]="'never'">
        <mat-select
          formControlName="type"
          placeholder="'type'"
          (valueChange)="updateBasedOnType($event)"
        >
          <mat-option *ngFor="let option of pipelineTasks" [value]="option">{{
            option
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container
        *ngIf="
          getTaskType() === 'classification' ||
          getTaskType() === 'roidetection' ||
          getTaskType() === 'position' ||
          getTaskType() === 'segmentation'
        "
      >
        <mat-label class="field-label" for="">
          {{ "label.options" | translate | uppercase }}
        </mat-label>
        <mat-form-field style="margin-right: 4px; width: 100%">
          <div style="display: flex; flex-wrap: wrap; width: 100%">
            <mat-chip-list #chipList style="width: 100%; flex-grow: 1">
              <mat-chip
                *ngFor="let option of optionsFormArray.controls; let i = index"
                [selectable]="true"
                [removable]="true"
                [color]="option?.get('name').errors ? 'warn' : 'primary'"
                [selected]="option?.get('name').errors"
                (removed)="removeOptionAt(i)"
                (click)="editOptionAt(i)"
              >
                <p style="margin: 0" [style.color]="option?.get('color').value">
                  {{ option?.get("name").value }}
                </p>
                <mat-icon
                  matChipRemove
                  fontSet="remixicon"
                  fontIcon="ri-close-line"
                ></mat-icon>
              </mat-chip>
              <input
                #optionInput
                placeholder="Add new option"
                style="width: 100%"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addOption($event)"
                (input)="onInputChange($event, true)"
                (focus)="onChipListInputFocus()"
                (blur)="onChipListInputBlur()"
              />
            </mat-chip-list>

            <ts-label-suggester
              *ngIf="isLabelSuggesterVisibleForOption"
              [inputValue]="inputOptionValue$"
              (filteredLabels$)="filteredLabels$ = $event"
              (optionSelected)="onOptionSelected($event)"
              (click)="onLabelSuggesterClick($event)"
              style="margin-top: 8px; width: 100%"
            ></ts-label-suggester>
          </div>
        </mat-form-field>
      </ng-container>

      <div *ngIf="!insideMethodType">
        <span class="warning-info">
          {{ "warning.options_color_cannot_be_defined" | translate }}</span
        >
      </div>

      <div *ngIf="isCloud">
        <ng-container>
          <mat-checkbox
            formControlName="assetSpecific"
            [matTooltip]="'tooltip.asset_specific' | translate"
          >
            {{ "check.asset_specific" | translate }}
          </mat-checkbox>
        </ng-container>
      </div>
    </div>

    <div *ngIf="isCloud">
      <ng-container *ngIf="getTaskType() === 'classification'">
        <mat-checkbox
          formControlName="multiple"
          [matTooltip]="'tooltip.analysis_selection_multiple' | translate"
        >
          {{ "input.multiple" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>

    <ng-container *ngIf="!isCloud">
      <div>
        <mat-label class="field-label">TRIGGER</mat-label>
        <mat-form-field class="full-width" [floatLabel]="'never'">
          <mat-select formControlName="trigger">
            <mat-option *ngFor="let option of stepActions" [value]="option">{{
              option
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-label class="field-label">GENERATE FINDING</mat-label>
        <mat-form-field class="full-width" [floatLabel]="'never'">
          <mat-select formControlName="generateFinding">
            <mat-option *ngFor="let option of stepActions" [value]="option">{{
              option
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container
        *ngIf="
          getTaskType() === 'roidetection' ||
          getTaskType() === 'position' ||
          getTaskType() === 'segmentation'
        "
      >
        <mat-checkbox
          formControlName="counter"
        >
          {{ "input.counter" | translate }}
        </mat-checkbox>
      </ng-container>

      <div>
        <mat-label class="field-label">{{
          "label.model_ai" | translate | uppercase
        }}</mat-label>
        <div>
          <span class="model-selected-label" *ngIf="task.executor"
            >{{ "info.model_selected" | translate }}
          </span>
          <span class="model-selected-name" *ngIf="task.executor">
            {{ task.executor?.name }}
          </span>
        </div>
        <div>
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="selectAIModel()"
          >
            Select Model
          </button>
        </div>
      </div>
    </ng-container>

    <div class="card-footer card-actions">
      <!-- Editor buttons -->
      <button
        class="btn btn-flat-primary card-actions__action card-actions__action--grow"
        type="reset"
        (click)="cancel()"
      >
        <i class="ri-close-fill"></i>{{ "button.cancel" | translate }}
      </button>
      <button
        class="btn btn-primary card-actions__action card-actions__action--grow"
        [disabled]="form.invalid"
        type="submit"
      >
        <i class="ri-save-line"></i>{{ "button.save" | translate }}
      </button>
    </div>
  </form>

  <ng-template #editOptionDialog let-data>
    <ts-task-option-editor
      [option]="data?.option"
      [isCloud]="isCloud"
      (submitted)="closeDialog($event)"
    >
    </ts-task-option-editor>
  </ng-template>

  <ng-template #selectAIModelDialog let-data>
    <ts-ai-available-models
      [modelSelected]="task.executor"
      [isCloud]="isCloud"
      (submitted)="closeDialog($event)"
    >
    </ts-ai-available-models>
  </ng-template>
</div>
