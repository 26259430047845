import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserEditorComponent } from './components/user-editor/user-editor.component';
import { UsersComponent } from './components/users/users.component';

const routes: Routes = [
  { path: 'new', component: UserEditorComponent, data: { title: 'New user' } },
  { path: ':id/edit', component: UserEditorComponent },
  {
    path: '',
    component: UsersComponent,
    data: { title: 'Users' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
