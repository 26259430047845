import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { StepDirective } from './components/stepper/step.directive';
import { StepperComponent } from './components/stepper/stepper.component';

@NgModule({
  declarations: [StepperComponent, StepDirective],
  imports: [CommonModule, TranslateModule],
  exports: [StepperComponent, StepDirective],
})
export class StepperModule {}
