import { Injectable } from "@angular/core";
import { CloudFunctions } from "@telespot/sdk";
import { Subject } from "rxjs";

interface SecondOpinionRequestPayload {
  /**
   * Array of requested users
   *
   * @type {{alias: string, email: string}[]}
   * @memberof SecondOpinionRequestPayload
   */
  guestUsers: { alias: string; email: string }[];
  sampleId: string;
  /**
   * Array of asset IDs
   *
   * @type {string[]}
   * @memberof SecondOpinionRequestPayload
   */
  assets: string[];
  subject: string;
  comments: string;
  caseId: string;
}

@Injectable({
  providedIn: "root",
})
export class SecondOpinionService {
  private readonly _events$ = new Subject<string>();
  public readonly events$ = this._events$.asObservable();

  public async submitSecondOpinionRequest(
    request: SecondOpinionRequestPayload
  ): Promise<void> {
    return CloudFunctions.RequestSecondOpinion(request).then(() =>
      this._events$.next("second_opinion_requested")
    );
  }
}
