import { Object as ParseObject } from "parse";
import { ModelWithROIs, RoiModel } from "../../interfaces";

import { Analysis } from "../analysis/analysis.model";
import { AnalysisTask } from "../data/analysis-task";
import { AnalysisUtils } from "../data/analysis-utils.model";

interface IAnalysisTypeDetails {
  display_name?: string;
}

/**
 * Describes a collection of Analysis tasks to be performed over a {@link Sample } or {@link Asset}, depending on the task type.
 *
 * @export
 * @class AnalysisType
 * @extends {ParseObject}
 */
export class AnalysisType extends ParseObject {
  static className = "AnalysisType";

  constructor(
    {
      tasks,
      name,
      displayName,
    }: { tasks?: AnalysisTask[]; name?: string; displayName?: string } = {
      tasks: [],
    }
  ) {
    super(AnalysisType.className);
    this.tasks = tasks;
    this.name = name;
    this.displayName = displayName;
  }

  /**
   * Internal name
   *
   * @type {string}
   * @memberof AnalysisType
   */
  get name(): string {
    return this.get("name") ?? "";
  }
  set name(value: string) {
    this.set("name", value);
  }

  /**
   * Additional AnalysisType metadata
   *
   * @type {IAnalysisTypeDetails}
   * @memberof AnalysisType
   */
  get details(): IAnalysisTypeDetails {
    return this.get("details") || {};
  }
  set details(data: IAnalysisTypeDetails) {
    this.set("details", data);
  }

  /**
   * Description for the AnalysisType
   *
   * @memberof AnalysisType
   */
  set description(value: string) {
    this.set("description", value);
  }
  get description(): string {
    return this.get("description");
  }

  /**
   * Display name for the UI
   *
   * @type {string}
   * @memberof AnalysisType
   */
  get displayName(): string {
    return this.details?.display_name || this.name;
  }
  set displayName(display_name: string) {
    this.details = {
      ...this.details,
      display_name,
    };
  }

  /**
   * Array of {@link AnalysisTask}
   *
   * @type {AnalysisTask[]}
   * @memberof AnalysisType
   */
  get tasks(): AnalysisTask[] {
    return (this.get("tasks") || []).map((f) => new AnalysisTask(f));
  }
  set tasks(data: AnalysisTask[]) {
    this.set(
      "tasks",
      (data || []).map((d) => d.toJSON())
    );
  }

  get mobile(): boolean {
    return this.get("mobile") || {};
  }
  set mobile(value: boolean) {
    this.set("mobile", value);
  }

  private _customTasks: AnalysisTask[];
  // get customTasks(): AnalysisTask[] { return this._customTasks || this.tasks.map(t=>new AnalysisTask(t))}
  set customTasks(tasks: AnalysisTask[]) {
    this._customTasks = tasks;
  }

  getCustomTasks(): AnalysisTask[] {
    return this._customTasks || this.tasks.map((t) => new AnalysisTask(t));
  }

  updateTasksWithCustomData(analysis: Analysis): AnalysisType {
    this.customTasks = (this.getCustomTasks() || []).map((t: AnalysisTask) => {
      if (t.type === "selection") {
        if (analysis.data[t.name]) {
          const userOptions = Object.keys(analysis.data[t.name]).filter(
            (k) => (t.options || []).map((o) => o.name).indexOf(k) === -1
          );
          (userOptions || []).forEach((k) => {
            t.allOptions.push({
              name: k,
              displayName: k,
            });
          });
        }
      }
      return t;
    });
    return this;
  }

  // TODO: review tasks casting, add/remove code...this doesn't belong in the model!

  addField(task: AnalysisTask): boolean {
    const tasks = this.tasks;
    const index = tasks.findIndex((t) => t.name === task.name);
    if (index === -1) {
      if (tasks.find((f: AnalysisTask) => f.name === task.name)) {
        throw new Error(`Duplicated identifier "${task.name}"`);
        return false;
      }
      tasks.push(task);
      this.tasks = tasks;
      return true;
    } else {
      tasks[index] = task;
      this.tasks = tasks;
      return true;
    }
  }

  removeField(field: AnalysisTask) {
    const tasks = this.tasks.filter((f: AnalysisTask) => f.name !== field.name);
    this.tasks = tasks;
  }

  getModels(): RoiModel[] {
    const nestedTaskModels = this.tasks.map((task) => task.getModels());
    const taskModels: RoiModel[] = AnalysisUtils.flatten(nestedTaskModels);
    return taskModels;
  }
}
