import { Component, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { Store } from "@ngrx/store";
import {
  AnalysisReviewService,
  analysisState,
  availableAIAnalysts$,
  IAnalystData,
  Mode,
  selectedAnalyst$,
  selectHasUnsavedChanges,
  selectMode,
  switchMode,
} from "@telespot/analysis-refactor/data-access";
import { Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, take, takeUntil } from "rxjs/operators";
import { UnsavedChangesDialogComponent } from "../dialogs/unsaved-changes-dialog/unsaved-changes-dialog.component";
import {
  TOsdActiveAction,
  ViewerService,
} from "@telespot/shared/viewers/data-access";

@Component({
  selector: "ts-ai-analysis-selection-panel",
  templateUrl: "./ai-analysis-selection-panel.component.html",
  styleUrls: ["./ai-analysis-selection-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiAnalysisSelectionPanelComponent {
  public readonly aiAlgorithms$: Observable<IAnalystData[]> =
    this._store.select(availableAIAnalysts$);
  public readonly hasUnsavedData$ = this._store.select(selectHasUnsavedChanges);
  public readonly aiAlgorithmSelected$ = this._store.select(
    selectedAnalyst$(true)
  );
  public readonly currentMode$ = this._store.select(selectMode);

  private _destroy$ = new Subject();
  private hasUnsavedData: boolean;
  private currentMode: Mode;

  constructor(
    private analysisReviewService: AnalysisReviewService,
    private _store: Store,
    private _dialog: MatDialog,
    private _viewerService: ViewerService
  ) {
    this.hasUnsavedData$
      .pipe(takeUntil(this._destroy$))
      .subscribe((hasUnsavedData) => (this.hasUnsavedData = hasUnsavedData));

    this.currentMode$
      .pipe(takeUntil(this._destroy$))
      .subscribe((mode) => (this.currentMode = mode));
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  changeUser(event: MatSelectChange) {
    if (this.hasUnsavedData && this.currentMode === Mode.REVIEW) {
      this._dialog
        .open(UnsavedChangesDialogComponent, { data: { username: "ai" } })
        .afterClosed()
        .subscribe((discard) => {
          if (!discard) return;
          this.analysisReviewService.navigateToUserAnalysis(false, event.value);
          this._viewerService.toggleViewerMode(TOsdActiveAction.idle);
          // deactivate review mode
          this._store
            .select(analysisState)
            .pipe(
              filter(
                (analysisState) =>
                  analysisState.id === event.value.analysisState.id
              ),
              take(1),
              distinctUntilChanged()
            )
            .subscribe((_) =>
              this._store.dispatch(switchMode({ mode: Mode.ANALYSIS }))
            );
        });
    } else {
      this.analysisReviewService.navigateToUserAnalysis(false, event.value);
      this._viewerService.toggleViewerMode(TOsdActiveAction.idle);
    }
  }
}
