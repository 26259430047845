import { Assignment, Algorithm } from "./algorithm";
import { Entity } from "./entity";

export enum ProtocolAlgorithmExceptions {
  INVALID_MODEL = "model is required",
  INVALID_PROTOCOL = "methodType is required",
}

export interface ModelAssignment extends Assignment {
  model: Algorithm;
}

export interface ProtocolAlgorithmAttributes extends Entity {
  model: Algorithm;
  methodTypeId: string;
  analysisTypeId?: string;
}

export class ProtocolAlgorithm {
  constructor(private attributes: ProtocolAlgorithmAttributes) {
    if (!attributes) {
      return;
    }

    if (!attributes.model) {
      throw new Error(ProtocolAlgorithmExceptions.INVALID_MODEL);
    }
    if (!attributes.methodTypeId) {
      throw new Error(ProtocolAlgorithmExceptions.INVALID_PROTOCOL);
    }
  }

  public get id(): string {
    return this.attributes.id;
  }

  public get model(): Algorithm {
    return this.attributes.model;
  }

  public get methodTypeId(): string {
    return this.attributes.methodTypeId;
  }

  public get analysisTypeId(): string | undefined {
    return this.attributes.analysisTypeId;
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }
}
