import { Injectable, OnDestroy } from "@angular/core";
import { CaseAnalysisService } from "@telespot/analysis-refactor/data-access";
import { DataService } from "@telespot/web-core";
import {
  Analysis,
  AnalysisState,
  AnalysisType,
  Asset,
  Case,
  MethodType,
  Query,
  Sample,
  SampleAsset,
  User,
} from "@telespot/sdk";
import { BehaviorSubject, Subject } from "rxjs";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";

export enum ReportContext {
  sample,
  asset,
  counter,
}

@Injectable({
  providedIn: "root",
})
export class ReportGeneratorService implements OnDestroy {
  private _reportCase$ = new BehaviorSubject<IMethodSampleATUser[]>([]);
  private _rawUserAnalysis = new BehaviorSubject<User[]>([]);
  private _destroy$ = new Subject<void>();

  public readonly case$ = this.caseService.case$;
  public readonly reportCase$ = this._reportCase$.asObservable();
  public readonly rawUserAnalysis$ = this._rawUserAnalysis.asObservable();

  constructor(
    private dataService: DataService,
    private caseService: CaseAnalysisService
  ) {
    this.case$
      .pipe(distinctUntilChanged(), takeUntil(this._destroy$))
      .subscribe((_case) => this.init(_case));
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  private init(_case: Case) {
    this._reportCase$.next([]);
    this._rawUserAnalysis.next([]);
    this._createCaseReport(_case);
  }

  // TODO: memoize results
  private async _createCaseReport(_case: Case): Promise<void> {
    // Get samples
    const sampleQuery = new Query(Sample)
      .equalTo("case", Case.createWithoutData(_case.id))
      .include("methodType.analysisTypes")
      .include("device.deviceType")
      .include("createdBy");
    const samples = await this.dataService.find(sampleQuery);

    const analysisStateQuery = new Query(AnalysisState).containedIn(
      "sample",
      samples
    );
    const analysisStates = (
      await this.dataService.find(analysisStateQuery)
    ).filter((_at) => !!_at.favAssets);

    // Get asset marked as fav during analysis
    samples.forEach(async (s) => {
      const nonRepeatedSampleAssets = [];
      analysisStates
        .filter((_at) => _at.sample.id === s.id)
        .forEach((_at) =>
          _at.favAssets.forEach((_fa) =>
            !nonRepeatedSampleAssets.includes(_fa)
              ? nonRepeatedSampleAssets.push(_fa)
              : nonRepeatedSampleAssets
          )
        );
      const favAssets = await new Query(SampleAsset)
        .equalTo("sample", s.toPointer())
        .ascending(["index", "dateOfCapture"])
        .include("asset")
        .find();
      if (favAssets) {
        s.assets = favAssets
          // .filter(sa => nonRepeatedSampleAssets.includes(sa.asset.id)) // TODO: review!!!
          .map((sa) => sa.asset);
      }
    });

    // Get analysis

    // Pending: include second opinion
    const analysisQuery = new Query(Analysis)
      .matchesQuery("sample", sampleQuery)
      .include("createdBy");
    const count = await this.dataService.count(analysisQuery);

    const analysis = await this.dataService.find(analysisQuery.limit(count));
    this._rawUserAnalysis.next(this._analysisUsers(analysis));

    this._reportCase$.next(
      _case.caseType.methodTypes.map((methodType) => ({
        methodType,
        samples: samples
          .filter((s) => s.methodType.id === methodType.id && s.assets?.length)
          .map((s) => {
            return {
              sample: s,
              results: methodType.analysisTypes
                .filter((_at) => _at.tasks.some((t) => !t.assetSpecific))
                .map((analysisType) => ({
                  analysisType,
                  hasRelevantTasks: analysisType.tasks.some(
                    (t) => !t.assetSpecific
                  ),
                  userResults: analysis.filter(
                    (a) =>
                      a.sample.methodType.id === methodType.id &&
                      a.sample.id === s.id &&
                      a.analysisType.id === analysisType.id &&
                      !a.asset &&
                      Object.keys(a.data).length
                  ),
                })),
              counters: methodType.analysisTypes
                .filter((_at) => _at.tasks.some((_t) => _t.roiSelection))
                .map((analysisType) => {
                  const roiTasks = analysisType.tasks?.filter(
                    (_t) => _t.roiSelection && _t.assetSpecific
                  );
                  const allAnalysis = analysis.filter(
                    (a) =>
                      a.sample.methodType.id === methodType.id &&
                      a.sample.id === s.id &&
                      a.analysisType.id === analysisType.id &&
                      !!a.asset &&
                      !!a.asset
                  );
                  const userResults = this._analysisUsers(allAnalysis).map(
                    (_user) => ({
                      createdBy: _user,
                      data: roiTasks.reduce((finalData, task) => {
                        finalData[task.name] = task.options.reduce(
                          (data, option, index) => {
                            data[option.name] = allAnalysis.reduce(
                              (total, analysis) => {
                                if (analysis.createdBy?.id !== _user?.id)
                                  return total;
                                total.counter =
                                  total.counter +
                                  (analysis.data?.[task.name]?.[option.name]
                                    ?.length ?? 0);
                                return total;
                              },
                              { counter: 0, perc: 0 }
                            );
                            if (task.options.length - 1 === index) {
                              data["total"] = Object.keys(data).reduce(
                                (total, key) => {
                                  total.counter =
                                    total.counter + data[key].counter;
                                  return total;
                                },
                                { counter: 0, perc: 100 }
                              );
                              data = Object.keys(data).reduce(
                                (options, name) => {
                                  options[name].perc = Math.round(
                                    (data[name].counter /
                                      data["total"].counter || 0) * 100
                                  );
                                  return options;
                                },
                                data
                              );
                            }
                            return data;
                          },
                          {}
                        );
                        return finalData;
                      }, {}),
                    })
                  );
                  return {
                    analysisType,
                    hasRelevantTasks: !!roiTasks.length,
                    userResults,
                  };
                }),
              assetResults: s.assets.map((asset: Asset) => ({
                asset,
                results: methodType.analysisTypes.map((analysisType) => ({
                  analysisType,
                  hasRelevantTasks: analysisType.tasks.some(
                    (t) => t.assetSpecific
                  ),
                  userResults: analysis.filter(
                    (a) =>
                      a.asset?.id === asset.id &&
                      a.analysisType.id === analysisType.id
                  ),
                })),
              })),
              selectedAsset: s.assets[0],
              selectedAssetIndex: 0,
            };
          }),
      }))
    );
    return Promise.resolve();
  }

  private _analysisUsers(allAnalysis: Analysis[]): User[] {
    const users = [];
    allAnalysis.forEach((_an, i) => {
      if (!Object.keys(_an.data).length) return;
      if (i === 0) users.push(_an.createdBy);
      if (
        !users.some((_user) => _user?.id === _an.createdBy?.id) &&
        _an.createdBy
      )
        users.push(_an.createdBy);
    });
    return users;
  }
}

export interface AnalysisResults {
  methodType: MethodType;
  samples: TSampleAnalysisResult[];
}

export interface TSampleAnalysisResult {
  sample: Sample;
  highlightedAssets: Asset[];
  analysisResults: {
    analysisType: AnalysisType;
    // Filter by sample / asset > user, etc
    analysis: Analysis[];
  }[];
  selectedAsset?: Asset;
  selectedAssetIndex?: number;
}

export interface IMethodSampleATUser {
  methodType: MethodType;
  samples: {
    sample: Sample;
    results: IResultsGroup[];
    counters?: {
      analysisType: AnalysisType;
      hasRelevantTasks: boolean;
      userResults: Partial<Analysis>[];
    }[];
    assetResults: {
      asset: Asset;
      results: IResultsGroup[];
    }[];
    selectedAsset?: Asset;
    selectedAssetIndex?: number;
  }[];
}

export interface IResultsGroup {
  analysisType: AnalysisType;
  hasRelevantTasks: boolean;
  userResults: Analysis[];
}

export interface IAnalysisResult {
  customizedAnalysisType: AnalysisType;
  analysis: Analysis;
}
