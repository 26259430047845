<div
  class="main-container"
  *ngIf="{
    case: case$ | async,
    isTiraspot: isTiraspot$ | async,
    userState: userState$ | async,
    mobileLayout: showMobileLayout | async,
    sampleGroups: sampleGroups$ | async
  } as state"
>
  <div class="title" *ngIf="state.case">
    <!-- <h1>{{ 'info.analyzing_case' | translate | uppercase}}</h1> -->
    <span
      class="breadcrumbs flex-row flex-align-center"
      style="justify-content: flex-start; max-width: 100%"
    >
      <a routerLink="/workspaces">
        <ng-container *ngIf="!state.mobileLayout; else workspaceIcon">
          {{ "title.workspaces" | translate }}
        </ng-container>
        <ng-template #workspaceIcon>
          <i class="ri-file-copy-2-line"></i>
        </ng-template>
      </a>
      <a
        [routerLink]="['/workspaces', currentWorkspace?.objectId, 'cases']"
        [queryParams]="{
          page: this._lastPagination$.pageIndex,
          pageSize: this._lastPagination$.pageSize
        }"
        >{{ currentWorkspace?.name }}</a
      >
      <a>{{
        !this.wsEncrypted || createdByUser
          ? currentCase?.name
          : this.decrypted
          ? currentCase?.name
          : currentCase.objectId
      }}</a>
    </span>
    <ng-container *ngIf="!state.isTiraspot">
      <ng-container *ngIfRole="['analyst', 'analystmanager']; else userGoBack">
        <ng-container *ngIf="state.userState">
          <ng-container
            *ngIf="state.userState.state === 'analyzed'; else submissionButtons"
          >
            <span
              *ngIf="{
                date: state.userState.updatedAt | date : 'short'
              } as formattedDate"
            >
              {{ "label.analysis_submitted_on_X" | translate : formattedDate }}
            </span>
            <button
              mat-icon-button
              class="btn btn-primary"
              (click)="editUserState()"
              data-cy="button-review-case"
              tsAnalytics="review_case_analysis"
            >
              <i class="ri-restart-line"></i>
            </button>
          </ng-container>
          <ng-template #submissionButtons>
            <button
              mat-icon-button
              class="btn btn-outline-primary btn-square"
              *ngIfRole="['analyst']"
              [matTooltip]="'tooltip.save_analysis' | translate"
              (click)="submitAnalysis({ finishAnalysis: false })"
              data-cy="button-save-analysis"
              tsAnalytics="save_case_analysis"
            >
              <i class="ri-save-line"></i>
            </button>
            <button
              class="btn btn-primary"
              (click)="submitAnalysis({ finishAnalysis: true })"
              data-cy="button-submit-analysis"
              [matTooltip]="'button.finish' | translate"
              tsAnalytics="submit_case_analysis"
            >
              <i class="ri-send-plane-2-line"></i>
              {{ "button.submit_analysis" | translate | uppercase }}
            </button>
          </ng-template>
        </ng-container>
      </ng-container>
      <ng-template #userGoBack>
        <span class="flex-row align-items-center">
          <i class="ri-arrow-left-s-line"></i>
          <a [routerLink]="['/workspaces', state.case?.workspace?.id]">{{
            "button.back" | translate | uppercase
          }}</a>
        </span>
      </ng-template>
      <button
        mat-icon-button
        class="btn btn-outline-primary btn-square"
        *ngIfRole="['admin', 'analystmanager']"
        (click)="submitAnalysis({ finishAnalysis: true, closeCase: true })"
        data-cy="button-close-case"
        [matTooltip]="'button.submit_analysis_and_close' | translate"
        tsAnalytics="close_case_analysis"
      >
        <i class="ri-check-line"></i>
      </button>
    </ng-container>
  </div>

  <ng-container *ngIf="state.mobileLayout; else desktopTemplate">
    <mat-tab-group
      mat-stretch-tabs
      class="example-stretched-tabs mat-elevation-z4 section-tabs"
      backgroundColor="transparent"
    >
      <mat-tab #tabInfo="matTab">
        <ng-template mat-tab-label>
          <i
            class="ri-file-copy-2-line"
            [style.marginRight.px]="4"
            [class.text-primary]="tabInfo.isActive"
          ></i>
          <span [class.text-primary]="tabInfo.isActive">{{
            "core.info" | translate | uppercase
          }}</span>
        </ng-template>
        <ng-container [ngTemplateOutlet]="caseData"></ng-container>
      </mat-tab>
      <mat-tab #tabData="matTab">
        <ng-template mat-tab-label>
          <i
            class="ri-group-line"
            [style.marginRight.px]="4"
            [class.text-primary]="tabData.isActive"
          ></i>
          <span [class.text-primary]="tabData.isActive">{{
            "core.data" | translate | uppercase
          }}</span>
        </ng-template>
        <ng-container [ngTemplateOutlet]="caseInfo"></ng-container>
      </mat-tab>
      <mat-tab #tabSamples="matTab">
        <ng-template mat-tab-label>
          <i
            class="ri-image-line"
            [style.marginRight.px]="4"
            [class.text-primary]="tabSamples.isActive"
          ></i>
          <span [class.text-primary]="tabSamples.isActive">{{
            "core.samples" | translate | uppercase
          }}</span>
        </ng-template>
        <ng-container
          [ngTemplateOutlet]="caseSamples"
          [ngTemplateOutletContext]="{ $implicit: state }"
        ></ng-container>
      </mat-tab>
    </mat-tab-group>
  </ng-container>

  <ng-template #desktopTemplate>
    <div class="case-info flex-row flex-wrap">
      <!-- Left: Case data -->
      <ng-container [ngTemplateOutlet]="caseData"></ng-container>
      <!-- Right: Case summary & second opinions -->
      <ng-container [ngTemplateOutlet]="caseInfo"></ng-container>
    </div>
  </ng-template>

  <ng-container
    [ngTemplateOutlet]="caseSamples"
    [ngTemplateOutletContext]="{ $implicit: state }"
    *ngIf="!state.mobileLayout"
  ></ng-container>

  <ng-template #caseData>
    <ts-case-data
      class="card flex-grow-1 case-data-container"
      [case]="state.case"
    ></ts-case-data>
  </ng-template>
  <ng-template #caseInfo>
    <ts-case-info class="card flex-grow-1"> </ts-case-info>
  </ng-template>

  <ng-template #caseSamples let-samples>
    <mat-accordion
      class="spotlab m-2"
      *ngIf="(loadingSamples$ | async) === false"
    >
      <mat-expansion-panel
        *ngFor="let sampleGroup of samples.sampleGroups; let i = index"
        [expanded]="i === 0 && samples.sampleGroups?.length === 1"
        class="custom-mat-expansion-panel"
      >
        <mat-expansion-panel-header class="flex-row align-items-center">
          <mat-panel-title class="flex-row align-items-center">
            <i
              [ngClass]="{
                'ri-film-line':
                  sampleGroup.methodType.assetType?.type === 'video',
                'ri-image-line':
                  sampleGroup.methodType.assetType?.type === 'image'
              }"
            ></i>
            {{
              ("title.samples" | translate) +
                " [" +
                sampleGroup.methodType.name +
                "]" | uppercase
            }}
          </mat-panel-title>
          <mat-panel-description>
            <ng-container *ngIf="sampleGroup.samples?.length > 0">
              {{ sampleGroup.samples?.length }} {{ "core.samples" | translate }}
            </ng-container>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <div class="samples-collection">
            <ng-container
              *ngFor="let sampleItem of sampleGroup.samples; let i = index"
            >
              <ts-sample-card
                [sampleItem]="sampleItem"
                [tiraspot]="samples.isTiraspot"
                (click)="
                  sampleItem.sample.numAssets &&
                    goToAnalysis(sampleItem.sample, sampleGroup.samples)
                "
                (edit)="editSample($event)"
              >
                <button
                  *ngIf="sampleItem.sample.numAssets"
                  class="btn btn-outline-primary"
                  [class.btn-outline-secondary]="!sampleItem.sample.numAssets"
                  [disabled]="!sampleItem.sample.numAssets"
                  (click)="
                    samples.isTiraspot
                      ? showAnalysisPreview(
                          sampleItem.sample,
                          sampleGroup.samples
                        )
                      : showReportPreview(sampleItem.sample);
                    $event.stopPropagation()
                  "
                  tsAnalytics="preview_sample_report"
                >
                  {{
                    (samples.isTiraspot ? "Review" : "Report preview")
                      | uppercase
                  }}
                </button>
                <button
                  *ngIf="!samples.isTiraspot"
                  class="btn btn-outline-primary with-margin"
                  (click)="
                    sampleItem.sample.numAssets &&
                      reviewSample(sampleItem.sample);
                    $event.stopPropagation()
                  "
                >
                  {{ "button.review" | translate | uppercase }}
                </button>
              </ts-sample-card>
            </ng-container>
            <ng-container
              *ngIf="
                state.case.organization.license.features.acquisition_available
                  .enabled
              "
            >
              <div
                *ngIfRole="['analystmanager', 'technicianmanager', 'admin']"
                class="card sample-card sample-card--placeholder align-items-center flex-row"
              >
                <button
                  class="btn btn-flat-primary"
                  (click)="addSample(sampleGroup)"
                  data-cy="button-new-sample"
                >
                  <i class="ri-add-circle-line"></i>
                  {{ "button.add_sample" | translate | uppercase }}
                </button>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>

    <ng-container *ngIf="loadingSamples$ | async">
      <div class="card" data-cy="loading-samples">
        <div class="card-body">
          {{ "info.loading_samples" | translate }}...
          <mat-progress-bar mode="query"></mat-progress-bar>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
