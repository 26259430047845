<div class="card">
  <ts-data-table
    [config]="tableConfig"
    [dataSource]="dataSource"
    [isAdmin]="isAdmin"
    [isEncrypted]="decrypt"
    (decryptCases)="handleDecryptionEvent($event)"
  >
    <div dataTableToolbar>
      <button
        *ngIf="true"
        (click)="toggleAIWarnings()"
        class="btn btn-flat-primary flex-row align-items-center warning-icon text-danger"
        style="cursor: pointer; width: auto"
      >
        <i
          [class.ri-spam-line]="!showWarnings"
          [class.ri-spam-fill]="showWarnings"
        ></i>
        {{
          (showWarnings
            ? "button.hide_tiraspot_warnings"
            : "button.show_tiraspot_warnings"
          )
            | translate
            | uppercase
        }}
      </button>
    </div>
    <ng-template dataTableCell="created_at" let-created_at let-mobile="mobile">
      <ts-cell-created-by
        [createdBy]="created_at"
        matTooltip="{{
          'label.acquired_by_x_on_y'
            | translate
              : {
                  user: created_at.user.username || ('unknown' | translate),
                  date: created_at.date | date : 'dd/MM/yyyy hh:mm a'
                }
        }}"
        [showDate]="!mobile"
      ></ts-cell-created-by>
    </ng-template>

    <ng-template dataTableCell="result" let-results>
      <span
        *ngFor="let result of results"
        [ngClass]="['poct-result', getTiraspotAnswerStyle(result), 'margin']"
        [ngStyle]="getTiraspotAnswerStyleConfig(result)"
        data-cy="poct-user-result"
      >
        {{ result | translate | uppercase }}
      </span>
    </ng-template>

    <ng-template dataTableCell="__menu" let-item>
      <ng-container [ngSwitch]="item.warning" *ngIf="showWarnings">
        <ng-container *ngSwitchCase="'disagreement'">
          <i
            class="text-danger ri-spam-line"
            [matTooltip]="'tooltip.tiraspot_user_AI_disagreement' | translate"
            data-cy="user-ai-disagreement-badge"
          ></i>
        </ng-container>
        <ng-container *ngSwitchCase="'corrected'">
          <i
            class="text-green ri-checkbox-circle-fill"
            [matTooltip]="'tooltip.tiraspot_user_AI_corrected' | translate"
            data-cy="user-ai-correction-badge"
          ></i>
        </ng-container>
      </ng-container>
    </ng-template>
  </ts-data-table>
</div>
