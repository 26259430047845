import { Store } from "@ngrx/store";
import { SplDataSource } from "@shared/ui";
import { WorkspaceRowData } from "./workspaces-row-data";
import {
  selectLoadingWorkspaces,
  selectTotalWorkspacesCount,
  selectWorkspacesList,
  selectWorkspacesPageIndex,
  selectWorkspacesPageSize,
} from "@telespot/analysis-refactor/data-access";
import { CollectionViewer } from "@angular/cdk/collections";
import { Observable } from "rxjs";

export class WorkspacesDataSource extends SplDataSource<WorkspaceRowData> {
  constructor(private store: Store) {
    super({ updateFcn: null });
  }

  get pageIndex$(): Observable<number> {
    return this.store.select(selectWorkspacesPageIndex);
  }

  get pageSize$(): Observable<number> {
    return this.store.select(selectWorkspacesPageSize);
  }

  connect(_: CollectionViewer) {
    this.numPages$ = this.store.select(selectTotalWorkspacesCount);
    this.loading$ = this.store.select(selectLoadingWorkspaces);
    return this.store.select(selectWorkspacesList);
  }

  disconnect(_: CollectionViewer): void {
    return;
  }
}
