import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { changeCropLabels, dragCrop, POI, ROI } from "../../state";
import { Store } from "@ngrx/store";
import { TaskOption } from "@telespot/sdk";
import { CropInfo } from "@telespot/web-core";
import { IDropListService } from "@shared/ui";

@Injectable({
  providedIn: "root",
})
export class DragDropService implements IDropListService {
  private dropListIds = new Set<string>();

  private _dropListIdsSubject$ = new Subject<string[]>();
  private _selectedCrops$ = new BehaviorSubject<CropInfo[]>([]);
  private _selectedCropsSelector$ = new BehaviorSubject<(ROI | POI)[]>([]);

  public dropListIdsObservable$ = this._dropListIdsSubject$.asObservable();
  public readonly selectedCrops$ = this._selectedCrops$.asObservable();
  public readonly selectedCropsSelector$ =
    this._selectedCropsSelector$.asObservable();

  constructor(private _store: Store) { }

  clearAll(): void {
    throw new Error("Method not implemented.");
  }

  addDropListId(id: string): void {
    this.dropListIds.add(id);
    this._dropListIdsSubject$.next(this.getConnectedDropLists());
  }

  removeDropListId(id: string): void {
    this.dropListIds.delete(id);
    this._dropListIdsSubject$.next(this.getConnectedDropLists());
  }

  removeDropList(list: string[]): void {
    list.forEach((id) => this.dropListIds.delete(id));
    this._dropListIdsSubject$.next(this.getConnectedDropLists());
  }

  getConnectedDropLists(): string[] {
    return Array.from(this.dropListIds);
  }

  // REVIEW: Unify grid and selector components state
  addSelectedCrop(cropInfo: CropInfo): void {
    this._selectedCrops$.next([...this._selectedCrops$.value, cropInfo]);
  }

  addSelectedCropSelector(roiInfo: ROI | POI): void {
    this._selectedCropsSelector$.next([
      ...this._selectedCropsSelector$.value,
      roiInfo,
    ]);
  }

  removeSelectedCrop(cropInfo: CropInfo): void {
    const filtered = this._selectedCrops$.value.filter(
      (crop) => crop !== cropInfo
    );
    this._selectedCrops$.next([...filtered]);
  }

  removeSelectedCropSelector(roiInfo: ROI | POI): void {
    const filtered = this._selectedCropsSelector$.value.filter(
      (crop) => crop !== roiInfo
    );
    this._selectedCropsSelector$.next([...filtered]);
  }

  cleanSelectedCrops(): void {
    this._selectedCrops$.next([]);
  }

  cleanSelectedCropsSelector(): void {
    this._selectedCropsSelector$.next([]);
  }

  onDrop(
    origin: string[],
    dest: string[],
    originOptions: TaskOption[],
    isOriginUnlabeled: boolean,
    isDestUnlabeled: boolean,
    crops: CropInfo[] | (ROI | POI)[]
  ) {
    if (isDestUnlabeled) {
      this.replaceLabelsOnDrop(origin, [], crops);
      return;
    }

    if (isOriginUnlabeled) {
      this.replaceLabelsOnDrop([], dest, crops);
      return;
    }

    const replaceLabel = originOptions.some((opt) => opt.uuid === dest[0]);

    if (replaceLabel) {
      this.replaceLabelsOnDrop(origin, dest, crops);
      return;
    } else {
      this.addLabelsOnDrop(crops, dest[0]);
      return;
    }
  }

  replaceLabelsOnDrop(
    previousLabels: string[],
    newLabels: string[],
    crops: CropInfo[] | (ROI | POI)[]
  ): void {
    crops.forEach((draggedCropInfo) => {
      const roiID: string = draggedCropInfo.roiID || draggedCropInfo.id;
      this._store.dispatch(
        dragCrop({
          roiId: roiID,
          previousLabels,
          newLabels,
        })
      );
    });
  }

  addLabelsOnDrop(crops: CropInfo[] | (ROI | POI)[], dest: string): void {
    crops.forEach((draggedCropInfo) => {
      const roiID: string = draggedCropInfo.roiID || draggedCropInfo.id;
      const findingId: string =
        draggedCropInfo.findingId || draggedCropInfo.labels[0].findingId;
      this._store.dispatch(
        changeCropLabels({
          roiId: roiID,
          findingId,
          newLabel: dest,
        })
      );
    });
  }
}
