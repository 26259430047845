import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ts-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  activeFragment: string;

  sections$: Observable<string[]> = this._translateService
    .getTranslation(this._translateService.currentLang ?? this._translateService.getDefaultLang())
    .pipe(map((translation) => Object.keys(translation.FAQS)));

  constructor(private _translateService: TranslateService) {}
}
