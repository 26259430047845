import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  RoiService,
  disabledLabelsForCounter,
} from "@telespot/analysis-refactor/data-access";
import { BehaviorSubject, combineLatest } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

export enum PanelMode {
  counter = "counter",
  percentage = "percentage",
}

@Component({
  selector: "ts-roi-model-stats",
  templateUrl: "./roi-model-stats.component.html",
  styleUrls: ["./roi-model-stats.component.scss"],
})
export class RoiModelStatsComponent {
  panelMode: typeof PanelMode = PanelMode;
  private _modeSelected$ = new BehaviorSubject<PanelMode>(PanelMode.counter);
  public readonly modeSelected$ = this._modeSelected$
    .asObservable()
    .pipe(distinctUntilChanged());
  public totalCount$ = this._roiService.totalCount$;
  public disabledLabels$ = this._store.select(disabledLabelsForCounter);

  modelStats$ = combineLatest([
    this._roiService.loadedStats$,
    this.totalCount$,
    this.modeSelected$,
    this.disabledLabels$,
  ]).pipe(
    map(([_labelCount, _totalCount, _modeSelected, disabledLabels]) => {
      return _modeSelected === PanelMode.counter
        ? _labelCount?.map((_r) => {
            const disabled = disabledLabels.includes(_r.labelId);
            return {
              ..._r,
              count: disabled ? "-" : _r.rois,
              disabled: disabled,
            };
          })
        : _labelCount?.map((_r) => {
            const disabled = disabledLabels.includes(_r.labelId);
            return {
              ..._r,
              disabled: disabled,
              count: disabledLabels.includes(_r.labelId)
                ? "-"
                : Math.round((_r.rois / _totalCount) * 100),
            };
          });
    })
  );

  constructor(private _roiService: RoiService, private _store: Store) {}

  public getModelColor(labelId: string) {
    return this._roiService.getModelColor(labelId ? [labelId] : [], true);
  }

  public getModelDisplayName(labelId: string) {
    return this._roiService.getLabelName(labelId ? [labelId] : []);
  }

  public getModelClassName(labelId: string) {
    return labelId ? labelId.substring(labelId.indexOf("value:") + 6) : "";
  }

  public changePanelMode(mode: PanelMode) {
    this._modeSelected$.next(mode);
  }
}
