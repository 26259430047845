import { Injectable, OnDestroy } from "@angular/core";
import { Subject, from } from "rxjs";
import { DataService } from "@telespot/web-core";
import {
  Query,
  PipelineStep,
  Label,
  MethodType,
  LabelConfig,
  Pipeline,
  SPECIAL_CHARS,
  PipelineTask,
} from "@telespot/sdk";
import { ProtocolMapper } from "./protocol.mapper";
import { PipelineEntity } from "../models/pipeline.entity";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class ProtocolService implements OnDestroy {
  constructor(
    private dataService: DataService,
    private translateService: TranslateService
  ) {
    this.currentLang =
      localStorage.getItem("user_language") ||
      this.translateService.currentLang;
  }

  private _destroy$ = new Subject<void>();
  public currentLang;

  public async getPipeline(pipelineId: string, methodTypeId: string) {
    let labelConfig = [];

    const pipeline = await new Query(Pipeline)
      .equalTo("objectId", pipelineId)
      .first();

    const pipelineSteps = await new Query(PipelineStep)
      .equalTo("pipeline", pipeline)
      .include("executor")
      .ascending("order")
      .find();

    const labelUuids = ProtocolMapper.getLabelUuids(pipelineSteps);
    const labels = await this.fetchLabelsInBatches(labelUuids, 100);

    if (methodTypeId) {
      labelConfig = await new Query(LabelConfig)
        .containedIn("label", labelUuids)
        .equalTo("methodType", MethodType.createWithoutData(methodTypeId))
        .find();
    }

    const filteredLabels = ProtocolMapper.filterLabels(
      labels,
      this.currentLang
    );

    return ProtocolMapper.toPipelineEntity(
      pipeline,
      pipelineSteps,
      filteredLabels,
      labelConfig
    );
  }

  public async fetchLabelsInBatches(labelUuids, batchSize = 100) {
    let skip = 0;
    const allLabels = [];

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const labelsBatch = await new Query(Label)
        .containedIn("uuid", labelUuids)
        .limit(batchSize)
        .skip(skip)
        .ascending("createdAt")
        .find();

      if (labelsBatch.length === 0) {
        break;
      }

      allLabels.push(...labelsBatch);

      skip += batchSize;
    }
    return allLabels;
  }

  public getMatchingLabels(input: string) {
    const validString = input.replace(
      new RegExp(`[${SPECIAL_CHARS.join("\\")}]`, "g"),
      "\\$&"
    );

    const regex = new RegExp(validString, "i");
    const labelsQuery = new Query(Label).matches("value", regex);
    return from(labelsQuery.find());
  }

  public async savePipeline(pipeline: PipelineEntity, methodTypeId) {
    const methodType = MethodType.createWithoutData(methodTypeId) as MethodType;

    //Save pipeline details and create new one if new
    const parsePipeline = pipeline.id
      ? (Pipeline.createWithoutData(pipeline.id) as Pipeline)
      : new Pipeline();

    parsePipeline.name = pipeline.name;
    parsePipeline.env = pipeline.env;
    const savedPipeline = await parsePipeline.save();

    const currentPipelineSteps = pipeline.id
      ? await new Query(PipelineStep).equalTo("pipeline", savedPipeline).find()
      : [];

    const pipelineAttributes = {
      id: savedPipeline.id,
      name: pipeline.name,
      tasks: pipeline.tasks,
      env: pipeline.env,
    };

    const newPipeline = new PipelineEntity(pipelineAttributes);

    const pipelineSteps = ProtocolMapper.toPipelineSteps(newPipeline);

    const stepsToDelete = ProtocolMapper.getStepsToDelete(
      currentPipelineSteps,
      pipelineSteps
    );

    await Promise.all(stepsToDelete.map((s) => this.dataService.softDelete(s)));

    await PipelineStep.saveAll(pipelineSteps);

    if (methodTypeId) {
      const labelConfigArr = ProtocolMapper.buildLabelConfig(
        newPipeline,
        methodType
      );

      await LabelConfig.saveAll(labelConfigArr);
    }

    return savedPipeline;
  }

  public async saveLabels(task: PipelineTask) {
    const allNewLabels: Label[] = [];

    if (task.name.new) {
      Object.keys(task?.name?.value || {}).forEach((optionValue) => {
        if (task.name.value[optionValue].length < 1) return;
        task.name.new = false;
        const newLabel = new Label();
        newLabel.lang = optionValue;
        newLabel.uuid = task.name.uuid;
        newLabel.value = task.name.value[optionValue];
        allNewLabels.push(newLabel);
      });
    }

    (task.options || [])
      .filter((o) => o.new)
      .forEach((o) => {
        Object.keys(o?.value || {}).forEach((optionValue) => {
          if (o.value[optionValue].length < 1) return;
          o.new = false;
          const newLabel = new Label();
          newLabel.lang = optionValue;
          newLabel.uuid = o.uuid;
          newLabel.value = o.value[optionValue];
          allNewLabels.push(newLabel);
        });
      });

    await Label.saveAll(allNewLabels);

    return task;
  }

  public async getLocaleFromLabel(uuid: string) {
    return await new Query(Label).equalTo("uuid", uuid).find();
  }

  ngOnDestroy() {
    this._destroy$.next();
  }
}
