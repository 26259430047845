import {
  ChangeDetectionStrategy,
  Component,
  Host,
  Inject,
  Input,
} from "@angular/core";
import { ROI } from "@telespot/analysis-refactor/data-access";

import { OsdRoisDirective } from "../../directives/osd-rois.directive";

@Component({
  selector: "ts-osd-roi",
  templateUrl: "./osd-roi.component.html",
  styleUrls: ["./osd-roi.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OsdRoiComponent {
  @Input() roi: ROI;
  @Input() index: number;

  public hover = true;

  constructor(
    @Host() @Inject(OsdRoisDirective) private _osdROI: OsdRoisDirective
  ) {}

  get selected(): boolean {
    return this._osdROI.isSelected(this.roi);
  }

  get color(): string {
    return this._osdROI.getModelColor(this.getAllLabels, true);
  }

  get semitransparentColor(): string {
    return this._osdROI.getModelColor(this.getAllLabels);
  }

  get modelsList(): string {
    return this._osdROI.getLabelName(this.getAllLabels);
  }

  handleOverlayClick(event, roi: ROI) {
    this._osdROI.handleOverlayClick(event, roi);
  }

  onMouseEnter() {
    this.hover = true;
  }

  onMouseLeave() {
    this.hover = false;
  }

  get getAllLabels(): string[] {
    return this.roi.labels.reduce(
      (acc, currLabel) => [...acc, ...Object.keys(currLabel.labels)],
      []
    );
  }
}
