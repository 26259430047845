import type Parse from "parse";

import { Analysis } from "@telespot/domain";
import { AnalysisTypeTopology, ParseAnalysisTypeMapper, PipelineTopology } from "../protocols";
import { ParseMapper } from "../../parse.mapper";
import { AssetTopology, SampleTopology } from "../acquisition";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

export enum AnalysisTopology {
  TABLE = "Analysis",
  SAMPLE = "sample",
  ASSET = "asset",
  CREATED_BY = "createdBy",
  ASSET_STATUS = "assetStatus",
  PIPELINE = "pipeline",
  UUID = "uuid",
  DATA = "data",
  TYPE = "analysisType"
}

export class ParseAnalysisMapper extends ParseMapper<Analysis> {
  private readonly analysisTypeMapper = new ParseAnalysisTypeMapper(this.parse);

  public fromDomain(analysis: Analysis): Parse.Object {
    const ParseSample = this.subclasses.getSubclass(SampleTopology.TABLE);
    const ParseAsset = this.subclasses.getSubclass(AssetTopology.TABLE);
    const ParsePipeline = this.subclasses.getSubclass(PipelineTopology.TABLE);
    const ParseAnalysisType = this.subclasses.getSubclass(AnalysisTypeTopology.TABLE);

    const parseObject = this.parse.Object.fromJSON({
      className: AnalysisTopology.TABLE,
      [ObjectTopology.ID]: analysis.id,
      [ObjectTopology.CREATED_AT]: analysis.createdAt,
      [ObjectTopology.UPDATED_AT]: analysis.updatedAt,
    });

    parseObject.set({
      [SoftDeletableTopology.DELETED_AT]: analysis.deletedAt,
      [AnalysisTopology.SAMPLE]: analysis.sampleId
        ? ParseSample.createWithoutData(analysis.sampleId)
        : undefined,
      [AnalysisTopology.ASSET_STATUS]: analysis.assetStatus,
      [AnalysisTopology.CREATED_BY]: analysis.creatorIs("user")
        ? this.parse.User.createWithoutData(analysis.creatorId)
        : undefined,
      [AnalysisTopology.ASSET]: analysis.assetId
        ? ParseAsset.createWithoutData(analysis.assetId)
        : undefined,
      [AnalysisTopology.PIPELINE]: analysis.pipelineId
        ? ParsePipeline.createWithoutData(analysis.pipelineId)
        : undefined,
      [AnalysisTopology.UUID]: analysis.uuid,
      [AnalysisTopology.DATA]: analysis.data,
      [AnalysisTopology.TYPE]: analysis.analysisType
        ? ParseAnalysisType.createWithoutData(analysis.analysisType.id)
        : undefined,
    });

    return parseObject;
  }

  public toDomain(object: Parse.Object): Analysis {
    const parseCreatedBy = object.get(AnalysisTopology.CREATED_BY);
    return new Analysis({
      id: object.id,
      sampleId: object.get(AnalysisTopology.SAMPLE).id,
      assetId: object.get(AnalysisTopology.ASSET)?.id,
      creatorEntity: object.get(AnalysisTopology.CREATED_BY)
        ? "user"
        : "algorithm",
      creatorId: object.get(AnalysisTopology.CREATED_BY)
        ? parseCreatedBy.id
        : undefined,
      assetStatus: object.get(AnalysisTopology.ASSET_STATUS),
      pipelineId: object.get(AnalysisTopology.PIPELINE)?.id,
      uuid: object.get(AnalysisTopology.UUID),
      deletedAt: object.get(SoftDeletableTopology.DELETED_AT),
      createdAt: object.get(ObjectTopology.CREATED_AT),
      updatedAt: object.get(ObjectTopology.UPDATED_AT),
      data: object.get(AnalysisTopology.DATA),
    });
  }
}
