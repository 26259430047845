<div class="card">
  <div class="card-body2 scrollable-table">
    <ts-data-table
      mat-table
      width="100%"
      [config]="tableConfig"
      [dataSource]="dataSource"
      class="spotlab-table"
      (selectItem)="onRowClicked($event)"
      matSort
    >
      <ng-template dataTableCell="name" let-item>
        <td [class.selected]="modelSelected?.id === item.id">
          {{ item.resource.name }}
        </td>
      </ng-template>
      <ng-template dataTableCell="description" let-item>
        <td [class.selected]="modelSelected?.id === item.id">
          {{ item.resource.description }}
        </td>
      </ng-template>

      <ng-template dataTableCell="version" let-item>
        <td [class.selected]="modelSelected?.id === item.id">
          {{ item.resource.version }}
        </td>
      </ng-template>

      <ng-template dataTableCell="regulated" let-item>
        <td [class.selected]="modelSelected?.id === item.id">
          {{
            item.resource.regulated
              ? ("core.yes" | translate | uppercase)
              : ("core.no" | translate | uppercase)
          }}
        </td>
      </ng-template>
    </ts-data-table>
  </div>
  <div class="card-footer card-actions">
    <!-- Editor buttons -->
    <button
      class="btn btn-outline-primary card-actions__action card-actions__action--grow"
      type="reset"
      (click)="cancel()"
    >
      <i class="ri-close-fill"></i>{{ "button.cancel" | translate }}
    </button>
    <button
      class="btn btn-primary card-actions__action card-actions__action--grow"
      type="submit"
      (click)="save()"
    >
      <i class="ri-save-line"></i>{{ "button.save" | translate }}
    </button>
  </div>
</div>
