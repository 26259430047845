import { DOCUMENT } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HeaderFooterService } from "@shared/ui";
import { CaseAnalysisService } from "@telespot/analysis-refactor/data-access";
import {
  IMethodSampleATUser,
  ReportContext,
  ReportGeneratorService,
} from "@telespot/reports/data-access";
import { Case, CloudFunctions, StateName } from "@telespot/sdk";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import { AuthService } from "@telespot/web-core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Component({
  selector: "ts-printable-report",
  templateUrl: "./printable-report.component.html",
  styleUrls: ["./printable-report.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CaseAnalysisService, ReportGeneratorService],
})
export class PrintableReportComponent implements OnInit, OnDestroy {
  context: typeof ReportContext = ReportContext;

  case$: Observable<Case> = this.reportGeneratorService.case$.pipe(
    tap((_case) => this.loading.next(false))
  );
  loading = new BehaviorSubject(false);

  dataByUser$: Observable<IMethodSampleATUser[]> =
    this.reportGeneratorService.reportCase$;

  public readonly allAnalysts$ =
    this.reportGeneratorService.rawUserAnalysis$.pipe(
      map((users) => users.map((u) => u.username).join(", "))
    );

  constructor(
    private caseAnalysisService: CaseAnalysisService,
    private reportGeneratorService: ReportGeneratorService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private headerFooterService: HeaderFooterService,
    @Inject(DOCUMENT) private _document: Document,
    private _logger: LoggerService,
    private _authService: AuthService
  ) {
    this.caseAnalysisService.setCase(this.route.snapshot.data["case"]);
  }

  ngOnInit() {
    this.loading.next(true);
  }

  ngOnDestroy() {
    // Make sure height is restored
    try {
      this.renderer.setStyle(
        this._document.getElementById("app-div"),
        "height",
        "100vh"
      );
    } catch (err) {
      //
    }
  }

  openPrintDialog() {
    try {
      this.headerFooterService.toggleHeaderAndFooter(false);
      this.renderer.setStyle(
        this._document.getElementById("app-div"),
        "height",
        "auto"
      );
      setTimeout(() => {
        this.renderer.setStyle(
          this._document.getElementById("printButton"),
          "visibility",
          "hidden"
        );
        this.renderer.setStyle(
          this._document.getElementById("reOpenButton"),
          "visibility",
          "hidden"
        );
        window.print();
        this.renderer.setStyle(
          this._document.getElementById("printButton"),
          "visibility",
          "visible"
        );
        this.renderer.setStyle(
          this._document.getElementById("reOpenButton"),
          "visibility",
          "visible"
        );
        this.headerFooterService.toggleHeaderAndFooter(true);
      }, 200);
    } catch (err) {
      this._logger.fatal(`Error printing report`, err.message);
    }
  }

  async reopenCase(_case: Case) {
    await CloudFunctions.SetState(
      _case,
      StateName.pending,
      this._authService.currentUser
    );
    this.router.navigate(["workspaces", _case.workspace.id, "cases"]);
  }
}
