import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  Inject,
  NgModule,
  Optional,
  ViewChild,
  OnInit,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { TranslateModule } from "@ngx-translate/core";
import { Workspace } from "@telespot/sdk";
import { Observable, of } from "rxjs";

export interface CompatibleWSDialogData {
  compatibleWorkspaces: Observable<Workspace[]>;
  loading: Observable<boolean>;
  acceptMessage: string;
}

@Component({
  selector: "ts-compatible-ws-dialog",
  templateUrl: "./compatible-ws-dialog.component.html",
  styleUrls: ["./compatible-ws-dialog.component.scss"],
})
export class CompatibleWSDialogComponent implements OnInit {
  public selectedWorkspaceId: string;
  public hoveredWorkspaceId: string;
  public acceptMessage: string;

  constructor(
    @Optional() public dialogRef: MatDialogRef<CompatibleWSDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: CompatibleWSDialogData = {
      compatibleWorkspaces: of([]),
      loading: of(false),
      acceptMessage: "button.accept",
    }
  ) { }

  ngOnInit() {
    this.acceptMessage = this.data.acceptMessage;
  }

  cancel() {
    this.dialogRef?.close("cancel");
  }

  accept(selectedWorkspaceId) {
    this.dialogRef?.close(selectedWorkspaceId);
  }

  onMouseEnter(id) {
    console.log(id);
  }
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatProgressBarModule,
    FormsModule,
  ],
  declarations: [CompatibleWSDialogComponent],
  exports: [CompatibleWSDialogComponent],
})
export class CompatibleWSDialogComponentModule { }
