import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@telespot/web-core';
import { CaseType, Organization, Query, Workspace } from '@telespot/sdk';
import { BehaviorSubject, combineLatest, defer, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

interface CaseTypeWithAvailability {
  caseType: CaseType;
  available?: boolean;
}
@Component({
  selector: 'ts-organization-casetypes',
  templateUrl: './organization-casetypes.component.html',
  styleUrls: ['./organization-casetypes.component.scss'],
})
export class OrganizationCasetypesComponent {
  private allCaseTypes$ = defer(() => this._dataService.find(new Query(CaseType)));
  private fetch$ = new BehaviorSubject<null>(null);

  private usedCases$ = this.fetch$.pipe(
    switchMap(() =>
      from(
        this._dataService.find(
          new Query(Workspace).equalTo('organization', this._route.parent.snapshot.data.organization).select('caseType')
        )
      ).pipe(map((workspaces) => workspaces.map((workspace) => workspace.caseType)))
    )
  );

  caseTypes$: Observable<CaseTypeWithAvailability[]> = combineLatest([this.allCaseTypes$, this.usedCases$]).pipe(
    map(([all, used]) => all.map((caseType) => ({ caseType, available: used.some((u) => u.id === caseType.id) })))
  );

  private _organization: Organization;

  constructor(private _dataService: DataService, private _route: ActivatedRoute) {
    this._organization = this._route.parent.snapshot.data.organization;
  }

  removeCaseType(caseType: CaseType) {
    alert('This client already has Workspaces making use of this CaseType');
  }

  addCaseType(caseType: CaseType) {
    if (!this._organization) return;
    const sampleWorkspace = new Workspace();
    sampleWorkspace.organization = this._organization;
    sampleWorkspace.caseType = caseType;
    sampleWorkspace.name = `${caseType.name} - demo`;
    this._dataService.save(sampleWorkspace).then(() => this.fetch$.next(null));
  }
}
