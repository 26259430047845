export const ROI_COLORS = [
  "#838600",
  "#ab1eb8",
  "#0032e1",
  "#009621",
  "#e07700",
  "#12ece0",
  "#ecbc12",
  "#e07d8a",
  "#a49395",
  "#58c8d3",
];
export const DEFAULT_ROI_COLOR = "#ff4646";
export const FUZZY_COLOR = "#1e1e1e";
export const NO_TAGS_COLOR = "#b4b4b4";
