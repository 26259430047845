import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import {
  AnalysisProtocolTask,
  AnalysisUtils,
  StepTask,
  TaskOption,
} from "@telespot/sdk";
import * as ProtocolActions from "./protocol.actions";

export const protocolFeatureKey = "protocol";

export enum Context {
  SAMPLE = "sample",
  ASSET = "asset",
}

export interface ProtocolSpecification {
  id: string;
  name: string;
  tasks: AnalysisProtocolTask[];
  mobile: boolean;
}

export function getProtocols(state: ProtocolState): ProtocolSpecification[] {
  return Object.values(state.protocols.entities ?? {});
}

export function getLabels(state: ProtocolState): Label[] {
  return Object.values(state.labels.entities ?? {});
}

export function getAllPossibleCounterFilters(counterFilters: string[]) {
  const sortedFilters = counterFilters.sort();
  const counterFiltersResult = [];
  const generateFilterCombination = (
    currentFilter: string[],
    startIndex: number,
    remainingLength: number
  ) => {
    if (remainingLength === 0) {
      counterFiltersResult.push(currentFilter.join("/"));
      return;
    }

    for (let i = startIndex; i <= sortedFilters.length - remainingLength; i++) {
      const newFilter = [...currentFilter, sortedFilters[i]];
      generateFilterCombination(newFilter, i + 1, remainingLength - 1);
    }
  };

  for (let length = 2; length <= sortedFilters.length; length++) {
    generateFilterCombination([], 0, length);
  }
  return counterFiltersResult;
}

const protocolAdapter = createEntityAdapter<ProtocolSpecification>({
  selectId: (protocol) => protocol.id,
});

export const labelAdapter = createEntityAdapter<Label>({
  selectId: ({ uuid }) => uuid,
});

// TODO: rename interface
export interface SelectedLabels {
  [key: string]: string[];
}

export interface Label {
  category: string;
  value: string;
  color?: string;
  pinned: boolean;
  removeFromCounter: boolean;
  selected: boolean;
  visible: boolean;
  taskId: string;
  pipelineId: string;
  thrdis?: number;
  uuid: string;
  custom?: boolean;
}

export interface ProtocolState {
  protocols: EntityState<ProtocolSpecification>;
  loading: boolean;
  context: Context;
  multiselectEnabled: boolean;
  showPinnedOnly: boolean;
  labels: EntityState<Label>;
  error?: string;
}

export const initialProtocolState: ProtocolState = {
  protocols: protocolAdapter.getInitialState(),
  loading: false,
  context: Context.ASSET,
  multiselectEnabled: false,
  showPinnedOnly: false,
  labels: labelAdapter.getInitialState(),
};

export const protocolReducer = createReducer(
  initialProtocolState,
  on(ProtocolActions.resetProtocolState, () => initialProtocolState),
  on(ProtocolActions.protocolActionError, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProtocolActions.toggleMultiSelect, (state) => {
    const firstSelectedLabelIndex = Object.values(
      state.labels.entities ?? {}
    ).findIndex((label) => label?.selected);
    const firstSelectedLabelId = state.labels.ids[firstSelectedLabelIndex];
    return {
      ...state,
      multiselectEnabled: !state.multiselectEnabled,
      labels: state.multiselectEnabled
        ? labelAdapter.updateMany(
            state.labels.ids.map((id) => ({
              id,
              changes: { selected: id === firstSelectedLabelId },
            })),
            state.labels
          )
        : state?.labels,
    };
  }),
  on(ProtocolActions.selectLabel, (state, { uuid }) => {
    const label = state.labels.entities[uuid];

    const { ids, entities } = state.labels;

    const labelChanges = {
      id: uuid,
      changes: { selected: !label.selected },
    };

    const disableRemainingChanges = (ids as string[])
      .filter((id) => id !== uuid)
      .map((id) => ({
        id,
        changes: { ...entities[id], selected: false },
      }));

    const updatedLabels = state.multiselectEnabled
      ? labelAdapter.updateOne(labelChanges, state.labels)
      : labelAdapter.updateMany(
          [...disableRemainingChanges, labelChanges],
          state.labels
        );

    return {
      ...state,
      labels: updatedLabels,
    };
  }),
  on(ProtocolActions.selectMultipleLabelIds, (state, { labelIds }) => {
    const { ids, entities } = state.labels;

    const labelChanges = labelIds.map((id) => ({
      id,
      changes: { selected: true },
    }));

    const disableRemainingChanges = (ids as string[])
      .filter((id) => !labelIds.includes(id))
      .map((id) => ({
        id,
        changes: { ...entities[id], selected: false },
      }));

    const updatedLabels = labelAdapter.updateMany(
      [...disableRemainingChanges, ...labelChanges],
      state.labels
    );

    return {
      ...state,
      labels: updatedLabels,
      multiselectEnabled:
        labelIds.length > 1 ? true : state?.multiselectEnabled,
    };
  }),
  on(ProtocolActions.loadProtocol, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    ProtocolActions.protocolLoaded,
    (state, { protocols, counterFilters }) => ({
      ...state,
      protocols: protocolAdapter.addMany(protocols, state.protocols),
      labels: labelAdapter.upsertMany(
        AnalysisUtils.flatten(
          protocols.map((taskGroup) =>
            AnalysisUtils.flatten(
              taskGroup.tasks
                .filter(
                  (task) =>
                    task.type !== StepTask.TEXT && task.type !== StepTask.OCR
                )
                .filter(
                  (task) => task.options !== undefined && task.options !== null
                )
                .map((task) =>
                  task.options.map((option) => ({
                    uuid: option.uuid,
                    value: option.name,
                    category: task.name,
                    color: option?.color,
                    alpha: option?.alpha,
                    thrdis: option?.thrdis,
                    selected: false,
                    visible: true,
                    pinned: false,
                    removeFromCounter: counterFilters.includes(option.uuid),
                    taskId: task.stepId,
                    pipelineId: taskGroup.id,
                  }))
                )
            )
          )
        ),
        state.labels
      ),
      loading: false,
    })
  ),
  on(ProtocolActions.setContext, (state, { context }) => ({
    ...state,
    context,
  })),
  on(ProtocolActions.togglePinnedOptionsOnly, (state) => ({
    ...state,
    showPinnedOnly: !state.showPinnedOnly,
  })),
  on(ProtocolActions.pinTaskOption, (state, { uuid }) => {
    const label = state.labels.entities[uuid];

    return {
      ...state,
      labels: labelAdapter.updateOne(
        {
          id: uuid,
          changes: {
            pinned: !label.pinned,
          },
        },
        state.labels
      ),
    };
  }),
  on(ProtocolActions.hideLabel, (state, { uuid }) => {
    const label = state.labels.entities[uuid];

    return {
      ...state,
      labels: labelAdapter.updateOne(
        {
          id: uuid,
          changes: {
            visible: !label.visible,
          },
        },
        state.labels
      ),
    };
  }),
  on(ProtocolActions.customLabelAdded, (state, { customLabel }) => {
    const protocolTasks = [
      ...(state.protocols.entities[customLabel.pipelineId].tasks ?? []),
    ];
    const taskToModify = protocolTasks.find((t) => t.allowNewOptions);

    const newLabel = {
      uuid: customLabel.value.uuid,
      value: customLabel.value.name,
      category: null,
      taskId: customLabel.taskId,
      pipelineId: customLabel.pipelineId,
      pinned: false,
      visible: true,
      removeFromCounter: false,
      selected: false,
      custom: true,
    };

    const modifiedTasks = protocolTasks.map((t) => {
      if (t.name === taskToModify.name) {
        return {
          ...t,
          options: [
            ...(taskToModify.options as TaskOption[]),
            {
              name: newLabel.value,
              uuid: newLabel.uuid,
              value: customLabel.value.value,
            },
          ],
        };
      }
      return t;
    });

    return {
      ...state,
      labels: labelAdapter.addOne(newLabel, state.labels),
      protocols: protocolAdapter.updateOne(
        {
          id: customLabel.pipelineId,
          changes: {
            tasks: modifiedTasks,
          },
        },
        state.protocols
      ),
    };
  }),
  on(ProtocolActions.loadCustomLabels, (state, { customLabels }) => {
    const uniquePipelineIds = new Set<string>();

    customLabels.forEach((customLabel) => {
      uniquePipelineIds.add(customLabel.pipelineId);
    });
    const pipelineIds = Array.from(uniquePipelineIds);

    const protocols = pipelineIds.map((id) => state.protocols.entities[id]);

    const newLabels = customLabels.map((l) => ({
      uuid: l.uuid,
      value: l.value,
      category: null,
      taskId: l.taskId,
      pipelineId: l.pipelineId,
      thrdis: 0,
      pinned: false,
      visible: true,
      removeFromCounter: false,
      selected: false,
      custom: true,
    }));

    const updatedProtocol = protocols.map((protocol) => {
      const updatedTasks = protocol.tasks.reduce((accumulator, task) => {
        if (!task.allowNewOptions) {
          return [...accumulator, task];
        } else {
          const newOptions = newLabels
            .filter((l) => l.pipelineId === protocol.id)
            .map((l) => ({
              name: l.value,
              uuid: l.uuid,
              value: undefined,
            }));
          return [
            ...accumulator,
            { ...task, options: [...(task?.options || []), ...newOptions] },
          ];
        }
      }, []);

      return {
        id: protocol.id,
        changes: {
          tasks: updatedTasks,
        },
      };
    });

    return {
      ...state,
      labels: labelAdapter.addMany(newLabels, state.labels),
      protocols: protocolAdapter.updateMany(updatedProtocol, state.protocols),
    };
  }),
  on(
    ProtocolActions.setCollapseOption,
    (state, { task, collapsed, pipelineId }) => {
      const protocolTasks = [
        ...(state.protocols.entities[pipelineId].tasks ?? []),
      ];
      const modifiedTasks = protocolTasks.map((t) => {
        if (t.name === task) {
          return { ...t, collapsed };
        }
        return t;
      });
      return {
        ...state,
        protocols: protocolAdapter.updateOne(
          {
            id: pipelineId,

            changes: {
              tasks: modifiedTasks,
            },
          },
          state.protocols
        ),
      };
    }
  ),
  on(ProtocolActions.clearAllLabels, (state) => {
    return {
      ...state,
      labels: labelAdapter.updateMany(
        state.labels.ids.map((id) => ({
          id,
          changes: { selected: false },
        })),
        state.labels
      ),
    };
  }),
  on(ProtocolActions.optionForCounterSaved, (state, { labelId }) => {
    const label = state.labels.entities[labelId];

    return {
      ...state,
      labels: labelAdapter.updateOne(
        {
          id: labelId,
          changes: {
            removeFromCounter: !label.removeFromCounter,
          },
        },
        state.labels
      ),
    };
  }),
  on(
    ProtocolActions.counterFiltersFromParamsSaved,
    (state, { counterFilters }) => {
      const { ids, entities } = state.labels;
      const labelChanges = counterFilters.map((id) => ({
        id,
        changes: { removeFromCounter: true },
      }));

      const disableRemainingChanges = (ids as string[])
        .filter((id) => !counterFilters.includes(id))
        .map((id) => ({
          id,
          changes: { ...entities[id], removeFromCounter: false },
        }));

      const updatedLabels = labelAdapter.updateMany(
        [...disableRemainingChanges, ...labelChanges],
        state.labels
      );

      return {
        ...state,
        labels: updatedLabels,
      };
    }
  )
);
