import { createFeatureSelector, createSelector } from "@ngrx/store";
import { WorkspacesState } from "./workspaces.reducer";

/* Workspaces State Configurations */
export const workspacesFeatureKey = "workspaces";

export const getWorkspaceState =
  createFeatureSelector<WorkspacesState>(workspacesFeatureKey);

/* Helper Functions */
export const getCurrentOrganization = (state: WorkspacesState) =>
  state.currOrganization ? state.currOrganization : undefined;

/* Workspaces State Selectors */
export const selectTotalWorkspacesCount = createSelector(
  getWorkspaceState,
  (state) => state.totalWorkspaces
);

export const selectWorkspacesPageSize = createSelector(
  getWorkspaceState,
  (state) => state.pageSize
);

export const selectWorkspacesPageIndex = createSelector(
  getWorkspaceState,
  (state) => state.pageIndex
);

export const selectLoadingWorkspaces = createSelector(
  getWorkspaceState,
  (state) => state?.loading
);

export const selectCurrOrganization = createSelector(
  getWorkspaceState,
  (state) => ({
    organization: getCurrentOrganization(state),
    limit: state.pageSize,
    filters: Object.values(state.filters.entities),
  })
);

export const selectWorkspacesPaginationandFilters = createSelector(
  getWorkspaceState,
  (state) => ({
    organization: getCurrentOrganization(state),
    limit: state.pageSize,
    skip: state.pageSize * state.pageIndex,
    filters: Object.values(state.filters.entities),
  })
);

export const selectWorkspacesList = createSelector(
  getWorkspaceState,
  (state) => {
    const row = Object.values(state.workspacesList.entities).map((item) => ({
      workspace: item,
    }));
    return row;
  }
);
