<ng-container *ngIf="fields$ | async as fields">
  <div class="card padded" *ngIf="form">
    <form (ngSubmit)="save()" [formGroup]="form">
      <div class="card-header flex-row flex-align-center justify-content-between">
        <h4 style="margin-bottom: 0px">{{ 'title.data_field_editor' | translate | uppercase }}</h4>
        <span class="button-bar">
          <button type="button" class="btn btn-outline-primary" (click)="cancel()" data-cy="button-cancel-field-type">
            <i class="ri-close-fill"></i>{{ 'button.cancel' | translate | uppercase }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            [disabled]="!form.valid || !form.dirty"
            data-cy="button-save-field-type"
          >
            <i class="ri-save-line"></i>{{ 'button.save' | translate | uppercase }}
          </button>
        </span>
      </div>

      <!-- Basic field configuration -->
      <div class="card-body">
        <ng-container *ngIf="isNew; else fieldTypeInfo">
          <mat-form-field class="full-width">
            <mat-label for="baseFieldType">{{ 'label.field_type' | translate }}</mat-label>
            <mat-select autofocus formControlName="type" name="baseFieldType" id="baseFieldType">
              <ng-container *ngFor="let group of fields">
                <mat-optgroup *ngIf="group.fieldType.length" [label]="group.type" [disabled]="false">
                  <mat-option
                    *ngFor="let fieldType of group.fieldType"
                    [value]="fieldType"
                    [disabled]="fieldType.type === 'case_name' && hasCaseName()"
                  >
                    {{ fieldType.name }}
                  </mat-option>
                </mat-optgroup>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-template #fieldTypeInfo>
          <mat-form-field class="full-width">
            <mat-label for="baseFieldType">{{ 'label.field_type' | translate }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ 'core.fieldType.' + form.controls['type'].value?.type | translate }}"
              disabled
              id="baseFieldType"
            />
          </mat-form-field>
        </ng-template>

        <div class="full-width" *ngIf="form.controls['type'].value?.type !== 'label'">
          <mat-checkbox id="required" class="example-margin" formControlName="required" name="required">{{
            'check.required' | translate
          }}</mat-checkbox>
        </div>

        <div class="full-width" *ngIf="form.controls['type'].value?.type !== 'label'">
          <mat-checkbox id="encrypted" class="example-margin" formControlName="encrypted" name="encrypted">{{
            'check.encrypted' | translate
          }}</mat-checkbox>
        </div>
      </div>

      <!-- Additional field configuration -->
      <div class="card-body" *ngIf="field">
        <b>{{ 'title.field_details' | translate | uppercase }}</b>
        <mat-form-field class="full-width">
          <mat-label for="fieldDisplayName">* {{ 'label.display_name' | translate }}</mat-label>
          <input
            matInput
            autofocus
            placeholder=""
            required
            formControlName="display_name"
            name="displayName"
            id="name"
            (focus)="fieldNameInfo.hidden = false"
            (blur)="fieldNameInfo.hidden = false"
          />
          <mat-error *ngIf="form.controls['display_name'].enabled && !form.controls['display_name'].valid"
            >You must specify a name for the field</mat-error
          >
          <mat-hint #fieldNameInfo hidden>
            {{ 'label.internal_field_name' | translate }}: <b>{{ form.controls['name'].value }}</b>
            {{ !isNew ? '(fixed)' : '' }}
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="card-body">
        <!-- Numeric configuration -->
        <ng-container *ngIf="form.controls['type'].value?.type === 'number'">
          <h5>{{ 'label.numeric_field_options' | translate }}</h5>
          <mat-form-field class="full-width">
            <mat-label for="">{{ 'Decimals' | translate }}</mat-label>
            <input matInput placeholder="" formControlName="decimals" name="decimals" type="number" id="decimals" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label for="">{{ 'Min' | translate }}</mat-label>
            <input matInput placeholder="" formControlName="min" name="min" type="number" id="min" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label for="">{{ 'Max' | translate }}</mat-label>
            <input matInput placeholder="" formControlName="max" name="max" type="number" id="max" />
          </mat-form-field>
        </ng-container>

        <!-- Options (selection/switcher) -->
        <ng-container
          *ngIf="form.controls['type'].value?.type === 'selection' || form.controls['type'].value?.type === 'switcher'"
        >
          <mat-form-field class="full-width">
            <mat-label for="options">{{ 'label.options' | translate }}</mat-label>
            <mat-chip-list #chipList>
              <mat-chip
                *ngFor="let option of form.controls.options.value"
                [selectable]="true"
                [removable]="true"
                (removed)="removeOption(option)"
                (click)="editOption(option)"
              >
                {{ option }}
                <mat-icon matChipRemove fontSet="remixicon" fontIcon="ri-close-line"></mat-icon>
              </mat-chip>
              <input
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addOption($event)"
              />
            </mat-chip-list>
          </mat-form-field>
        </ng-container>

        <!-- Options (textedit) -->
        <ng-container *ngIf="form.controls['type'].value?.type === 'textedit'">
          <div class="full-width">
            <mat-checkbox id="multiline" class="example-margin" formControlName="multiline" name="multiline">{{
              'Multi-line input' | translate
            }}</mat-checkbox>
          </div>
        </ng-container>

        <!-- Multiple selection checkbox -->
        <ng-container *ngIf="form.controls['type'].value?.type === 'selection'">
          <div class="full-width">
            <mat-checkbox id="multiple" class="example-margin" formControlName="multiple" name="multiple">{{
              'Allow multiple selection' | translate
            }}</mat-checkbox>
          </div>
        </ng-container>

        <!-- Placeholder text for textedit -->
        <ng-container
          *ngIf="form.controls['type'].value?.type === 'textedit' || form.controls['type'].value?.type === 'case_name'"
        >
          <mat-form-field class="full-width">
            <label for="placeholder">{{ 'Placeholder text' | translate }}</label>
            <input matInput placeholder="" formControlName="placeholder" name="placeholder" id="placeholder" />
          </mat-form-field>
        </ng-container>

        <!-- Default text for labels -->
        <ng-container *ngIf="form.controls['type'].value?.type === 'label'">
          <mat-form-field class="full-width">
            <mat-label for="default">{{ 'Label text' | translate }}</mat-label>
            <input matInput placeholder="" formControlName="default" name="default" id="default" />
          </mat-form-field>
        </ng-container>

        <!-- Boolean default value -->
        <ng-container *ngIf="form.controls['type'].value?.type === 'boolean'">
          <div class="full-width">
            <mat-checkbox id="default" class="example-margin" formControlName="default" name="default">{{
              'Default value' | translate
            }}</mat-checkbox>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
</ng-container>
