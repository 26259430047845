export class SemanticVersion {
  public static create(value: string): SemanticVersion {
    const semverRegex = /[0-9]+\.[0-9]+\.[0-9]+/;

    if (!semverRegex.test(value))
      throw new Error(
        "InvalidVersionFormat: Provided version does not conform to server format"
      );

    return new SemanticVersion(value);
  }

  private constructor(private _value: string) {}

  public get value(): string {
    return this._value;
  }
}
