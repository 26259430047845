import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { TableViewComponent } from "./table-view.component";
import { VirtualScrollModule } from "../virtual-scroll";

@NgModule({
  declarations: [TableViewComponent],
  imports: [CommonModule, VirtualScrollModule],
  exports: [TableViewComponent],
})
export class TableViewModule { }
