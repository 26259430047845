import { Scan, ScanSize, State, Tag } from "@telespot/domain";
import { ParseMapper } from "../../parse.mapper";
import { AssetTopology } from "./parse-asset.mapper";
import { DeviceTypeTopology } from "./parse-device-type.mapper";
import { DeviceTopology } from "./parse-device.mapper";
import { ParseSampleAssetMapper, SampleAssetTopology } from "./parse-sample-asset.mapper";


export class ParseScanMapper extends ParseMapper<Scan> {

  private readonly saMapper = new ParseSampleAssetMapper(this.parse);

  public toDomain(parseSampleAsset: Parse.Object, assets: Parse.Object[] = []): Scan {
    const vignetingAppNameRegex = /adapta/gi;
    const fileExtensionRegex = /\.(jpg|png|jpeg|bmp|svg)$/;

    const acquisitionDevice = assets[0]?.get(AssetTopology.DEVICE)?.get(DeviceTopology.TYPE)?.get(DeviceTypeTopology.NAME);
    const stitchingState = parseSampleAsset?.get(SampleAssetTopology.ASSET).get(AssetTopology.STITCHING_STATE);
    const stitchingAssetFilename = parseSampleAsset?.get(SampleAssetTopology.ASSET)?.get(AssetTopology.ASSET_FILE);

    const tag = Tag.create(stitchingAssetFilename.replace(fileExtensionRegex, ""));
    const status = stitchingState ?? { state: State.PENDING };
    const corrvigneting = !vignetingAppNameRegex.test(acquisitionDevice)
    const scanSize = assets[0] ? this.getScanShots(assets[0]) : null;
    const assetList = assets.map(asset => asset.get(AssetTopology.ASSET_FILE));
    const sampleAsset = this.saMapper.toDomain(parseSampleAsset);


    return new Scan({
      sampleAsset,
      assetList, tag, corrvigneting, scanSize,
      status,
    });
  }

  public fromDomain(scan: Scan): Parse.Object {
    const parseSampleAsset = this.saMapper.fromDomain(scan.sampleAsset);

    parseSampleAsset.get(SampleAssetTopology.ASSET).set(AssetTopology.STITCHING_STATE, scan.status);

    return parseSampleAsset;
  }

  private getScanShots(asset: Parse.Object): ScanSize | null {
    const inInvalid =
      isNaN(Number(asset.get(AssetTopology.DATA).shotsX)) || isNaN(Number(asset.get(AssetTopology.DATA).shotsY));

    return inInvalid
      ? null
      : { shotsX: asset.get(AssetTopology.DATA).shotsX, shotsY: asset.get(AssetTopology.DATA).shotsY };
  }
}
