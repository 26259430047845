import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService, DataService } from "@telespot/web-core";
import {
  Case,
  CaseType,
  FieldType,
  IField,
  MethodType,
  Query,
  TFieldTypeName,
  Workspace,
} from "@telespot/sdk";
import { from, Observable, of, Subject } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import {
  DataFilterComponent,
  FindRequest,
  IColumn,
  RowData,
} from "@shared/utils";
import { DataTableConfig, SplDataSource } from "@shared/ui";

class CaseTypeNameValidator {
  static caseName(
    caseType: CaseType,
    dataService: DataService
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      // return of(null);
      return control.touched
        ? from(
            dataService.first(
              new Query(CaseType)
                .equalTo("name", control.value)
                .notEqualTo("objectId", caseType?.id)
            )
          ).pipe(
            map((existingCaseType) =>
              existingCaseType
                ? { invalidName: "A CaseType with that name already exists" }
                : null
            ),
            catchError((err) =>
              of({ invalidName: `Name validation failed: ${err.message}` })
            )
          )
        : of(null);
    };
  }
}

interface WorkspaceRowData extends RowData {
  resource: any;
  id: string;
}

@Component({
  selector: "ts-case-type-editor",
  templateUrl: "./case-type-editor.component.html",
  styleUrls: ["./case-type-editor.component.scss"],
})
export class CaseTypeEditorComponent
  extends DataFilterComponent
  implements OnInit, OnDestroy
{
  form: UntypedFormGroup;
  private _destroy$ = new Subject<void>();

  // UI settings
  showFieldEditor = false;
  secondaryEditorVisible = false;
  compatibilityWarning: string;
  existingCaseCount = 0;
  // Data
  caseType: CaseType;
  workspaces$: Observable<Workspace[]> =
    this.authService.currentOrganization$.pipe(
      distinctUntilChanged(),
      switchMap((organization) => {
        return organization
          ? from(
              this.dataService.find(
                new Query(Workspace)
                  .include(["caseType"])
                  .equalTo("organization", organization)
              )
            )
          : of([]);
      }),
      takeUntil(this._destroy$),
      shareReplay()
    );

  selectedFieldType: FieldType;

  public previousRequest: FindRequest;
  public oldAvailable: Workspace[];
  public tableName = "workspaces_list";
  public dataSource = new SplDataSource<WorkspaceRowData>(this);
  public tableConfig: DataTableConfig<WorkspaceRowData>;

  public klass = Workspace.className;
  public columns: IColumn[] = [
    {
      name: this.tableName,
      value: (item) => item,
      include: ["caseType"],
    },
  ];

  public url(item: any) {
    return null;
  }

  constructor(
    private authService: AuthService,
    public dataService: DataService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(dataService);
  }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get("id");

    this.caseType = await this.getCaseType(id);

    this._buildForm();

    this.configureTable({
      hideHeader: true,
      showSearch: false,
    });
  }

  private _buildForm() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.caseType.name || "", {
        updateOn: "blur",
        validators: [Validators.required],
        asyncValidators: [
          CaseTypeNameValidator.caseName(this.caseType, this.dataService),
        ],
      }),
      description: new UntypedFormControl(this.caseType.description || ""),
      fields: new UntypedFormControl(
        this.caseType.isNew()
          ? [this._getCaseIDField()]
          : [...this.caseType.fields]
      ),
      methodTypes: new UntypedFormControl([...this.caseType.methodTypes] || []),
    });
  }

  async getCaseType(id?: string): Promise<CaseType> {
    let caseType: CaseType;
    if (id) {
      caseType = await this.dataService.get(
        id,
        new Query(CaseType).include([
          "methodTypes.assetType",
          "methodTypes.analysisTypes",
        ])
      );
      // Check if it is already in use
      this.existingCaseCount = await this.dataService.count(
        new Query(Case).equalTo("caseType", caseType.toPointer())
      );

      // if (existingCaseCount !== 0) {
      //   this.compatibilityWarning = `This CaseType is already in use in ${existingCaseCount} cases`;
      // }
    }
    return caseType ?? new CaseType();
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  // Event handlers
  updateCaseFieldsOrder(fieldTypes: FieldType[]) {
    this.form.controls["fields"].patchValue([...fieldTypes]);
    this.form.markAsDirty();
  }

  updateVisualizer(showMethodEditor: boolean) {
    this.secondaryEditorVisible = showMethodEditor;
  }

  updateCaseMethodsList(methodTypes: MethodType[]) {
    this.form.controls["methodTypes"].patchValue([...methodTypes]);
    this.form.markAsDirty();
  }

  addField(caseFieldData: IField) {
    const fields = [...this.form.value.fields];
    const existingField =
      this.selectedFieldType?.name === caseFieldData.name
        ? this.selectedFieldType
        : undefined;
    if (existingField) {
      existingField.name = caseFieldData.name;
      existingField.type = caseFieldData.type;
      existingField.required = caseFieldData.required;
      existingField.details = caseFieldData.details;
      existingField.encrypted = caseFieldData.encrypted;
      // this.form.value.fields.updateField(existingField);
    } else {
      fields.push(new FieldType(caseFieldData));
    }
    this.form.controls["fields"].patchValue(fields);
    this.form.markAsDirty();
    this.showFieldEditor = false;
  }

  async saveCaseType() {
    this.caseType.name = this.form.value.name;
    this.caseType.description = this.form.value.description;
    this.caseType.fields = this.form.value.fields;
    const removedMethodTypes = this.caseType.methodTypes.filter(
      (methodType) =>
        !this.form.value.methodTypes.some(
          (selectedMethodType) => selectedMethodType?.id === methodType?.id
        )
    );
    if (removedMethodTypes.length) {
      this.caseType.removeAll("methodTypes", removedMethodTypes);
      await this.dataService.save(this.caseType);
    }
    this.caseType.addAllUnique("methodTypes", this.form.value.methodTypes);

    return this.dataService
      .save(this.caseType)
      .then(() => {
        this.router.navigateByUrl("/protocols/caseTypes");
      })
      .catch((err) => {
        this.form.setErrors({ server: err.message });
        throw err;
      });
  }

  cancel() {
    if (this.form.dirty) {
      if (!confirm("Revert CaseType changes?")) {
        return;
      }
    }
    this.router.navigateByUrl("/protocols/caseTypes");
  }

  private _getCaseIDField(): FieldType {
    return new FieldType({
      name: FieldType.generateValidFieldName("", TFieldTypeName.case_name),
      required: true,
      type: TFieldTypeName.case_name,
      details: {
        display_name: "Case identifier",
      },
    });
  }
}
