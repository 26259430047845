<ng-container *ngIf="user">
  <div class="main-container" *ngIf="{ workspaces: workspaces$ | async } as state">
    <div class="title">
      <h1>
        {{ (user.isNew() ? 'button.new_user' : 'title.edit_user') | translate | uppercase }}
        {{ (!user.isNew() ? ': ' + user.username : '') | uppercase }}
      </h1>
    </div>

    <!-- Title -->
    <div class="card">
      <div class="card-body align-center">
        <img
          [src]="user.avatar?.url()"
          *ngIf="user.avatar?.url()"
          alt="user avatar"
          class="user-avatar user-avatar--100"
          width
        />
      </div>
      <ng-container *ngIf="user.isNew()">
        <div class="card-body" *ngIfRole="['admin']">
          <mat-checkbox
            [(ngModel)]="registerWithoutEmail"
            (ngModelChange)="updateRegisterWithoutEmail($event)"
            class="text-danger"
            data-cy="check-register-userpassword"
          >
            Register without e-mail (admins only)
          </mat-checkbox>
        </div>
      </ng-container>
      <form [formGroup]="userForm" *ngIf="userForm">
        <div class="card-body">
          <ng-container *ngIf="user.isNew()">
            <ng-container *ngIf="!registerWithoutEmail; else usernameInput">
              <mat-form-field class="full-width">
                <input
                  matInput
                  autofocus
                  id="user-editor-email-input"
                  type="email"
                  autocomplete="off"
                  placeholder="{{ 'label.email' | translate | uppercase }}"
                  formControlName="email"
                  data-cy="input-email"
                  (ngModelChange)="updateEmail($event)"
                />
              </mat-form-field>
              <mat-form-field class="full-width">
                <input
                  matInput
                  autofocus
                  id="user-editor-username-input"
                  type="text"
                  autocomplete="off"
                  placeholder="{{ 'label.username' | translate | uppercase }}"
                  formControlName="username"
                  data-cy="input-username"
                />
              </mat-form-field>
            </ng-container>
            <ng-template #usernameInput>
              <mat-form-field class="full-width">
                <input
                  matInput
                  autofocus
                  id="user-editor-username-input"
                  type="text"
                  autocomplete="off"
                  placeholder="{{ 'label.username' | translate | uppercase }}"
                  formControlName="username"
                  data-cy="input-username"
                />
              </mat-form-field>
              <mat-form-field class="full-width">
                <input
                  matInput
                  id="user-editor-password-input"
                  type="password"
                  autocomplete="off"
                  placeholder="{{ 'label.password' | translate | uppercase }}"
                  formControlName="password"
                  data-cy="input-password"
                />
              </mat-form-field>
            </ng-template>
          </ng-container>
          <div>
            <label for="">{{ 'core.role' | translate | uppercase }}</label>

            <div *ngFor="let role of activeRoles$ | async">
              <mat-slide-toggle
                [checked]="role.active"
                (change)="toggleRole(role)"
                [attr.data-cy]="'toggle-role-' + role.role.getName()"
                [class.text-warning]="!role.active && !role.enabled"
              >
                {{ role.role.getName() | translate }}
              </mat-slide-toggle>
            </div>
          </div>
          <div *ngIf="demotions$ | async" class="text-warning" data-cy="role-revoke-warning">
            {{ 'warning.user_role_revoking' | translate }}
          </div>
          <mat-error *ngIf="!rolesControl.valid && rolesControl.errors">
            {{ rolesControl.errors['error'] | translate }}
          </mat-error>
        </div>
        <div class="card-body">
          <div id="workspaces-label" class="text-uppercase">
            {{ 'title.workspaces' | translate }} ({{ 'core.optional' | translate }})
          </div>
          <mat-form-field class="full-width" floatLabel="never">
            <mat-select
              aria-label="{{ 'title.workspaces' | translate }}"
              formControlName="workspaces"
              multiple
              [compareWith]="compareFcn"
              data-cy="select-workspace"
            >
              <mat-checkbox
                class="m-3"
                (change)="toggleAllWorkspaces($event, state.workspaces)"
                [indeterminate]="selectedWorkspacesCount > 0 && selectedWorkspacesCount !== state.workspaces?.length"
                [checked]="selectedWorkspacesCount === state.workspaces?.length"
                >{{ 'core.all' | translate | uppercase }}</mat-checkbox
              >
              <mat-divider></mat-divider>
              <mat-option *ngFor="let workspace of state.workspaces" [value]="workspace">{{ workspace.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="card-body">
          <div *ngIf="userForm && userForm.errors">
            {{ userForm.errors['responseError'] }}
          </div>
        </div>
        <div class="card-footer card-actions no-hr">
          <button
            type="button"
            class="btn btn-outline-primary card-actions__action"
            (click)="cancel()"
            data-cy="button-cancel-user"
          >
            <i class="ri-close-fill"></i>
            {{ 'button.cancel' | translate | uppercase }}
          </button>
          <ng-container *ngIf="creatingUser; else noNew">
            <button
              type="button"
              class="btn btn-primary card-actions__action"
              [disabled]="!userForm.touched || userForm.invalid"
              (click)="save()"
              data-cy="button-save-new-user"
            >
              <i class="ri-save-line"></i>
              {{ 'button.create' | translate | uppercase }}
            </button>
          </ng-container>
          <ng-template #noNew>
            <button
              type="button"
              class="btn btn-primary card-actions__action"
              [disabled]="!userForm.touched || userForm.invalid"
              (click)="save()"
              data-cy="button-save-user"
            >
              <i class="ri-save-line"></i>
              {{ 'button.save' | translate | uppercase }}
            </button>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<ng-template #savingTemplate let-data>
  <ng-container *ngIf="creatingUser; else editingUser">
    <h4 class="flex-row align-items-center" data-cy="creating-user-dialog-title">
      <i class="ri-refresh-line spin"></i>
      {{ 'dialog.creating_user' | translate }}
    </h4>
  </ng-container>
  <ng-template #editingUser>
    <h4 class="flex-row align-items-center" data-cy="saving-user-dialog-title">
      <i class="ri-refresh-line spin"></i>
      {{ 'dialog.saving_user' | translate }}
    </h4>
  </ng-template>

  <div *ngIf="data.message$ | async as message">
    {{ message | translate }}
  </div>
</ng-template>
