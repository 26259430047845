import { Object as ParseObject, Role } from 'parse';
import uid from 'uid';

import { Organization, Workspace } from '..';

export class Invite extends ParseObject {
  static className = 'Invite';

  constructor({
    code,
    organization,
    uses,
    initialWorkspaces,
    initialRoles,
  }: {
    code?: string;
    organization?: Organization;
    uses?: number;
    initialWorkspaces?: Workspace[];
    initialRoles?: Role[];
  } = {}) {
    super(Invite.className);
    this.code = code ?? uid(6).toUpperCase();
    this.organization = organization;
    this.set('uses', uses);
    this.initialRoles = initialRoles;
    this.initialWorkspaces = initialWorkspaces;
  }

  set code(value: string) {
    this.set('code', value);
  }
  get code(): string {
    return this.get('code');
  }

  set organization(value: Organization) {
    this.set('organization', value);
  }
  get organization(): Organization {
    return this.get('organization');
  }

  set initialWorkspaces(value: Workspace[]) {
    this.set('initialWorkspaces', value);
  }
  get initialWorkspaces(): Workspace[] {
    return this.get('initialWorkspaces') ?? [];
  }

  set initialRoles(value: Role[]) {
    this.set('initialRoles', value);
  }
  get initialRoles(): Role[] {
    return this.get('initialRoles') ?? [];
  }

  get uses(): number {
    return this.get('uses');
  }
}
