import {
  Action,
  MetaReducer,
  createReducer,
  on,
} from '@ngrx/store';

import * as Actions from './auth.actions';

import { State } from './auth.state';

const initialState: State = {
  roles: [],
}

const authReducer = createReducer<State>(
  initialState,
  on(Actions.rolesFetched, (state, { roles }) => ({ ...state, roles }))
);

export function reducers(state: State | undefined, action: Action) {
  return authReducer(state, action);
}


export const metaReducers: MetaReducer<State>[] = [];
