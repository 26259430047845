import type Parse from "parse";
import { ParseSubclassFactory } from "./parse-subclass.factory";

export abstract class ParseMapper<T> {

  protected subclasses: ParseSubclassFactory;

  constructor(protected readonly parse: typeof Parse) {
    this.subclasses = ParseSubclassFactory.getInstance(parse);
  }
}
