export interface AssetTypeAttributes {
  id?: string;
}

export class AssetType {
  constructor(private attributes: AssetTypeAttributes) {}

  public get id() {
    return this.attributes.id;
  }
}
