import { createSelector } from "@ngrx/store";
import { AnalysisUtils } from "@telespot/sdk";
import { User } from "parse";
import {
  analysisState,
  selectActiveSample,
  selectAsset,
} from "../+state/rois.selectors";
import {
  getActiveCopiedAnalysis,
  getLabelAnalysisIds,
  labelsToAnalysisLabelFormat,
  Mode,
} from "./analysis/analysis.reducer";
import {
  selectAnalysis,
  selectMode,
  selectRois,
} from "./analysis/analysis.selectors";
import {
  Context,
  getAllPossibleCounterFilters,
} from "./protocol/protocol.reducer";
import {
  disabledLabelsForCounter,
  selectContext,
  selectedLabels,
  selectTaskGroupsForActiveContext,
  selectVisibleLabels,
} from "./protocol/protocol.selectors";
import {
  SampleCounterState,
  selectActiveLabelCountReview,
  selectActiveMultiLabelReviewCount,
  selectActiveReviewCount,
  selectActiveSingleLabelReviewCount,
  selectSampleCounterFeature,
} from "./sample-counters";

export const selectCurrentAnalyst = createSelector(analysisState, (history) =>
  (history?.user ?? history?.algorithm ?? User.current())?.toPointer()
);

export const isAnalystCurrentUser = createSelector(
  selectCurrentAnalyst,
  (currentAnalyst) =>
    currentAnalyst.className === User.current().className &&
    currentAnalyst.objectId === User.current().id
);

export const selectAnalysisForActiveTaskGroups = createSelector(
  selectTaskGroupsForActiveContext,
  selectAnalysis,
  (taskGroups, analysis) =>
    analysis.filter((an) =>
      taskGroups.map((tg) => tg.id).includes(an.analysisTypeId)
    )
);

export const selectActiveAnalysis = createSelector(
  selectAnalysisForActiveTaskGroups,
  selectCurrentAnalyst,
  selectContext,
  selectActiveSample,
  selectAsset,
  selectMode,
  (analysis, analyst, context, sample, asset, mode) => {
    let activeAnalysis = analysis.filter(
      (an) =>
        an.createdBy.className === analyst?.className &&
        an.createdBy.objectId === analyst?.objectId &&
        an.sampleId === sample?.id &&
        an.assetId === (context === Context.ASSET ? asset?.id : undefined)
    );
    if (mode === Mode.REVIEW)
      activeAnalysis = getActiveCopiedAnalysis(activeAnalysis);
    return activeAnalysis;
  }
);

export const selectActiveAssetContextAnalysis = createSelector(
  selectAnalysis,
  selectCurrentAnalyst,
  selectActiveSample,
  selectAsset,
  selectMode,
  (analysis, analyst, sample, asset, mode) => {
    let activeAnalysis = analysis.filter(
      (an) =>
        an.createdBy.className === analyst?.className &&
        an.createdBy.objectId === analyst?.objectId &&
        an.sampleId === sample?.id &&
        an.assetId === asset?.id &&
        an.isSampleAnalysis === false
    );
    if (mode === Mode.REVIEW)
      activeAnalysis = getActiveCopiedAnalysis(activeAnalysis);

    return {
      analysisRequest: {
        createdBy: analyst,
        sampleId: sample?.id,
        assetId: asset?.id,
      },
      analysisArray: activeAnalysis,
    };
  }
);
export const hasActiveAssetAnalysis = createSelector(
  selectActiveAssetContextAnalysis,
  (activeAssetAnalysis) => activeAssetAnalysis.analysisArray.length > 0
);
export const hasCopyAnalysis = createSelector(
  selectActiveAssetContextAnalysis,
  selectAnalysis,
  (activeAssetAnalysis, allAnalysis) =>
    activeAssetAnalysis.analysisArray.length > 0 &&
    !allAnalysis.some((analysis) =>
      analysis?.id?.startsWith(
        "copy:" + activeAssetAnalysis.analysisArray[0].id
      )
    )
);

export const selectActiveAnalysisForTaskGroup = (taskGroupId: string) =>
  createSelector(selectActiveAnalysis, (analysis) =>
    analysis.find((an) => an.analysisTypeId === taskGroupId)
  );

export const activeAnalysisIds = createSelector(
  selectActiveAssetContextAnalysis,
  (activeAnalysisInfo) =>
    activeAnalysisInfo.analysisArray.map((analysis) => analysis.id)
);

export const selectActiveROIS = createSelector(
  selectRois,
  activeAnalysisIds,
  (rois, activeAnalysisIds) =>
    rois.filter((roi) =>
      getLabelAnalysisIds([roi]).every((analysisId) =>
        activeAnalysisIds.includes(analysisId)
      )
    )
);

export const selectAssetAnalysisFromUser = (user: Parse.Pointer) =>
  createSelector(selectAnalysis, selectAsset, (analysis, asset) =>
    analysis
      .filter(
        (a) =>
          a.assetId === asset.id &&
          a.createdBy.className === user?.className &&
          a.createdBy.objectId === user?.objectId
      )
      .map((a) => a.id)
  );
export const selectAssetROIsFromUser = (user: Parse.Pointer) =>
  createSelector(
    selectRois,
    selectAssetAnalysisFromUser(user),
    (rois, analysisIds) =>
      rois.filter((roi) =>
        getLabelAnalysisIds([roi]).every((analysisId) =>
          analysisIds.includes(analysisId)
        )
      )
  );
export const selectROILabelCount = (labelId: string) =>
  createSelector(selectActiveROIS, (rois) => {
    const roiLabels: string[] = AnalysisUtils.flatten(
      (rois || []).map((roi) =>
        roi.labels.reduce((acc, currLabel) => [...acc, ...currLabel.labels], [])
      )
    );
    return roiLabels.filter((label) => label === labelId).length;
  });

export const selectSelectedROIS = createSelector(selectActiveROIS, (rois) =>
  rois?.filter((roi) => roi?.selected)
);

export const selectVisibleROIS = createSelector(
  selectActiveROIS,
  selectVisibleLabels,
  (rois, visibleLabels) =>
    rois.filter((roi) => {
      const roiLabels = roi.labels?.reduce(
        (acc, label) => [...acc, ...label.labels],
        []
      );
      return (roiLabels ?? []).some((label) => visibleLabels.includes(label));
    })
);

export const selectedLabelsWithAnalysisReq = createSelector(
  selectActiveAssetContextAnalysis,
  selectedLabels,
  (activeAnalysisInfo, labels) => {
    if (activeAnalysisInfo.analysisArray.length === 0) {
      const analysisTypes: string[] = Array.from(
        new Set(labels.map((label) => label.analysisTypeId))
      );
      const analysesRequest = analysisTypes.map((at) => ({
        ...activeAnalysisInfo.analysisRequest,
        analysisTypeId: at,
      }));
      return { selectedLabels: labels, analysesRequest, analysisLabels: [] };
    }

    return {
      analysisLabels: labelsToAnalysisLabelFormat(
        labels,
        activeAnalysisInfo.analysisArray
      ),
      analysesRequest: [],
      selectedLabels: [],
    };
  }
);

export const selectActiveCount = createSelector(
  selectSampleCounterFeature,
  analysisState,
  (state: SampleCounterState, analysisState) => {
    const labelCountEntity = Object.values(state?.labelCount.entities).find(
      (labelCount) => labelCount.analysisStateId === analysisState?.id
    );
    return labelCountEntity ? labelCountEntity : undefined;
  }
);

export const labelCount = createSelector(
  selectMode,
  selectActiveCount,
  selectActiveLabelCountReview,
  (mode, countEntity, reviewLabelCount) => {
    return mode === Mode.REVIEW &&
      countEntity?.analysisStateId === reviewLabelCount?.analysisStateId
      ? reviewLabelCount.labelCount
      : countEntity?.labelCount ?? [];
  }
);

export const multiLabelCount = createSelector(
  selectMode,
  selectActiveCount,
  selectActiveLabelCountReview,
  selectActiveMultiLabelReviewCount,
  (mode, countEntity, reviewLabelCount, multiLabelReviewCount) => {
    return mode === Mode.REVIEW &&
      countEntity?.analysisStateId === reviewLabelCount?.analysisStateId
      ? multiLabelReviewCount
      : countEntity?.multiLabelCount;
  }
);

export const singleLabelCount = createSelector(
  selectMode,
  selectActiveCount,
  selectActiveLabelCountReview,
  selectActiveSingleLabelReviewCount,
  (mode, countEntity, reviewLabelCount, singleLabelReviewCount) => {
    return mode === Mode.REVIEW &&
      countEntity?.analysisStateId === reviewLabelCount?.analysisStateId
      ? singleLabelReviewCount
      : countEntity?.singleLabelCount;
  }
);

export const labelsToSubstract = createSelector(
  multiLabelCount,
  singleLabelCount,
  disabledLabelsForCounter,
  (multiLabelDetail, singleLabelDetail, labelsToFilter) => {
    if (labelsToFilter.length < 1) return 0;
    if (labelsToFilter.length === 1) {
      const label = Object.keys(singleLabelDetail || {}).find((label) =>
        label.includes(labelsToFilter[0])
      );
      return label ? singleLabelDetail[label] : 0;
    } else {
      let roisToSubstract = 0;
      Object.keys(singleLabelDetail || {}).forEach((label) => {
        if (labelsToFilter.includes(label)) {
          roisToSubstract += singleLabelDetail[label];
        }
      });
      const allFilters = getAllPossibleCounterFilters(labelsToFilter);
      Object.keys(multiLabelDetail || {}).forEach((label) => {
        if (allFilters.includes(label)) {
          roisToSubstract += multiLabelDetail[label];
        }
      });
      return roisToSubstract;
    }
  }
);
export const totalCount = createSelector(
  selectMode,
  selectActiveCount,
  selectActiveReviewCount,
  selectActiveLabelCountReview,
  labelsToSubstract,
  (mode, countEntity, reviewCount, reviewLabelCount, substractLabels) => {
    return mode === Mode.REVIEW &&
      countEntity?.analysisStateId === reviewLabelCount?.analysisStateId
      ? (reviewCount ?? 0) - substractLabels
      : (countEntity?.totalCount ?? 0) - substractLabels;
  }
);
