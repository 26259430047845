import { Component, Inject, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PipelineTask } from "@telespot/sdk";

@Component({
  selector: "ts-pipeline-task-editor-dialog",
  templateUrl: "./pipeline-task-editor-dialog.component.html",
  styleUrls: ["./pipeline-task-editor-dialog.component.scss"],
})
export class PipelineTaskEditorDialogComponent {
  constructor(
    @Optional()
    public dialogRef: MatDialogRef<PipelineTaskEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      pipelineTask: PipelineTask;
      taskType: string;
      isCloud: boolean;
      insideMethodType: boolean;
    } = {
      pipelineTask: undefined,
      taskType: undefined,
      isCloud: true,
      insideMethodType: false,
    }
  ) {}

  close(value) {
    this.dialogRef?.close(value);
  }
}
