import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_ID, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AnalyticsFeatureModule } from '@shared/analytics';
import { CookieConsentFeatureModule } from '@shared/cookie-consent';
import { TelespotAnalyticsModule } from '@telespot/analytics';
import { TelespotAuthFeatureModule } from '@telespot/auth/feature';
import { TelespotModule } from '@telespot/web-core';
import { environment } from '@telespot/shared/environment';
import { TelespotShellModule } from '@telespot/shell';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ThanksComponent } from './components/thanks/thanks.component';
import { SharedModule } from './shared/shared.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/');
}

@NgModule({
  imports: [
    SharedModule,
    TelespotShellModule,
    BrowserModule,
    BrowserAnimationsModule,
    TelespotModule.forRoot({
      environment: environment,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      useDefaultLang: true,
    }),
    TelespotAuthFeatureModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.serviceWorker }),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot(),
    CookieConsentFeatureModule,
    ...(environment.firebase ? [AnalyticsFeatureModule, TelespotAnalyticsModule] : []),
    EffectsModule.forRoot(),
  ],
  declarations: [AppComponent, PageNotFoundComponent, ThanksComponent],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Inject(PLATFORM_ID) private platformId: unknown, @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ? 'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
