import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../services/auth/auth.service';
import { FeaturesService, TFeatureName } from '../services/features/features.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngIfRole]',
})
export class NgIfRoleDirective implements OnDestroy, OnInit, OnChanges {
  private _requiredFeatures: string[] = [];
  private _destroy$ = new Subject<void>();

  @Input() ngIfRole: string[] = ['admin'];
  @Input() ngIfRoleElse: TemplateRef<unknown>;
  @Input()
  set ngIfRoleFeatures(features: string | string[]) {
    this._requiredFeatures = features instanceof Array ? features : [features];
  }

  private currentTemplate: TemplateRef<unknown>;

  constructor(
    private _templateRef: TemplateRef<unknown>,
    private _viewContainer: ViewContainerRef,
    private _authService: AuthService,
    private _featureService: FeaturesService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this._authService.currentUser$,
      ...(this._requiredFeatures?.length ? [this._authService.currentOrganization$] : []),
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.updateView(this._getValue());
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ngIfRole || changes.ngIfRoleElse || changes.ngIfRoleFeatures) {
      this.updateView(this._getValue());
    }
  }

  private updateView(showNow?: boolean) {
    const template = showNow ? this._templateRef : this.ngIfRoleElse;
    if (this.currentTemplate !== undefined && this.currentTemplate === template) {
      // Skip re-renders when the template doesn't change
      return;
    }
    this.currentTemplate = template;
    this._viewContainer.clear();
    if (showNow) {
      this._viewContainer.createEmbeddedView(template);
    } else if (template !== undefined) {
      this._viewContainer.createEmbeddedView(template);
    }
  }

  private _getValue(): boolean {
    return this.hasUserPermissions() && this.hasOrganizationFeatureAccess();
  }

  private hasUserPermissions(): boolean {
    const restrinctedRoles = [];

    this.ngIfRole?.map((role) => {
      if (role.indexOf('!') !== -1) restrinctedRoles.push(role.substring(role.indexOf('!') + 1));
    });

    if (restrinctedRoles.length > 0)
      return (
        this._authService.currentUser &&
        (!this.ngIfRole || !(restrinctedRoles.findIndex((r) => this._authService.currentUser.roleNames.includes(r)) !== -1))
      );
    return (
      this._authService.currentUser &&
      (!this.ngIfRole || this.ngIfRole.findIndex((r) => this._authService.currentUser.roleNames.includes(r)) !== -1)
    );
  }

  private hasOrganizationFeatureAccess(): boolean {
    return this._requiredFeatures.every((featureName) => this._featureService.canUse(featureName as TFeatureName));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }
}
