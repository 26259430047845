import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { MasksPluginDirective } from "./directives/masks-plugin/masks-plugin.directive";
import { MetricScalePluginDirective } from "./directives/metric-scale-plugin/metric-scale-plugin.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [MasksPluginDirective, MetricScalePluginDirective],
  exports: [MasksPluginDirective, MetricScalePluginDirective],
})
export class TelespotSharedViewersUiModule {}
