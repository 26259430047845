import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import {
  CaseAnalysisService,
  ISecondOpinion,
} from "@telespot/analysis-refactor/data-access";
import { DataService, TelespotModule } from "@telespot/web-core";
import {
  Analysis,
  AnalysisState,
  Case,
  CloudFunctions,
  Query,
  SecondOpinion,
  User,
} from "@telespot/sdk";
import { BehaviorSubject, defer, Observable, Subject } from "rxjs";
import { distinctUntilChanged, map, takeUntil } from "rxjs/operators";

import {
  SecondOpinionReportsDialogComponent,
  SecondOpinionReportsDialogModule,
} from "../second-opinion-reports-dialog/second-opinion-reports-dialog.component";

@Component({
  selector: "ts-case-info",
  templateUrl: "./case-info.component.html",
  styleUrls: ["./case-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseInfoComponent implements OnInit, OnDestroy {
  analyzed$: Observable<AnalysisState[]>;
  analysing$: Observable<AnalysisState[]>;
  private _loading = new BehaviorSubject<boolean>(false);
  loading$ = this._loading.asObservable();

  private _secondOpinionsAnalysis$: Observable<ISecondOpinion[]>;
  get secondOpinionsAnalysis() {
    return this._secondOpinionsAnalysis$;
  }

  readonly currentCase$: Observable<Case> =
    this._caseAnalysisService.case$.pipe(distinctUntilChanged());
  private _destroy$ = new Subject<void>();
  private _dialogRef: MatDialogRef<unknown>;

  constructor(
    private _dataService: DataService,
    private _caseAnalysisService: CaseAnalysisService,
    private _dialog: MatDialog
  ) {}

  async resendSecondOpinion({
    analysisStateId,
    caseId,
  }: {
    analysisStateId: string;
    caseId: string;
  }) {
    // Resend second opinion with cloud function without creating anything new
    await CloudFunctions.ResendSecondOpinion({
      analysisStateId,
      caseId,
    });
    this._caseAnalysisService.fecthSecondOptions();
  }

  async deleteSecondOpinion({ analysisStateId }: { analysisStateId: string }) {
    // Delete second opinion
    await CloudFunctions.DeleteSecondOpinion({ analysisStateId });
    this._caseAnalysisService.fecthSecondOptions();
  }

  ngOnInit() {
    this._loading.next(true);
    this.analyzed$ = this._caseAnalysisService.allUsersStates$.pipe(
      map((states) => states.filter((as) => as.state === "analyzed"))
    );
    this.analysing$ = this._caseAnalysisService.allUsersStates$.pipe(
      map((states) => states.filter((as) => as.state === "inProgress"))
    );
    this._secondOpinionsAnalysis$ =
      this._caseAnalysisService.secondOpinions$.pipe(
        distinctUntilChanged(),
        map((secOps: SecondOpinion[]) => {
          return secOps
            .reduce(
              (c, d) =>
                d.sentTo
                  .reduce(
                    (a, b) =>
                      a.concat({
                        sample: d.sample,
                        sentTo: b,
                        origSampleId: d.originalSample.id,
                        analysisType: d.analysisTypes[0],
                      }),
                    []
                  )
                  .concat(c),
              []
            )
            .map((_sOp) => ({
              user: _sOp.sentTo as User,
              origSampleId: _sOp.origSampleId,
              caseId: _sOp.sample.case?.id,
              analysisType: _sOp.analysisType,
              state$: defer(() =>
                this._dataService.first(
                  new Query(AnalysisState)
                    .equalTo("sample", _sOp.sample.toPointer())
                    .equalTo("user", _sOp.sentTo.toPointer())
                )
              ).pipe(map((analysisState) => analysisState)),
              analysis$: defer(() =>
                this._dataService.first(
                  new Query(Analysis)
                    .equalTo("sample", _sOp.sample.toPointer())
                    .equalTo("createdBy", _sOp.sentTo.toPointer())
                    .equalTo("analysisType", _sOp.analysisType.toPointer())
                    .equalTo("asset", undefined)
                )
              ).pipe(
                map(
                  (analysis) =>
                    Object.keys(analysis.data).map(
                      (k) => analysis.data[k]
                    )?.[0] as string
                )
              ),
            }));
        }),
        takeUntil(this._destroy$)
      );
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  public showReports({
    reports,
    caseName,
  }: {
    reports: ISecondOpinion[];
    caseName: string;
  }) {
    this._dialogRef =
      this._dialogRef ||
      this._dialog.open(SecondOpinionReportsDialogComponent, {
        hasBackdrop: true,
        disableClose: true,
        width: "800px",
        height: "700px",
        data: {
          secondOpinions: reports,
          caseName,
        },
      });
    this._dialogRef
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._dialogRef = undefined;
      });
  }
}

@NgModule({
  declarations: [CaseInfoComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatTooltipModule,
    MatMenuModule,
    RouterModule,
    SecondOpinionReportsDialogModule,
    TelespotModule,
  ],
  exports: [CaseInfoComponent],
  providers: [],
})
export class CaseInfoModule {}
