/* eslint-disable @angular-eslint/directive-selector */
import {
  ComponentFactoryResolver,
  Directive,
  Host,
  OnDestroy,
  OnInit,
  Renderer2,
  Self,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ROI,
  RoiService,
  SampleAnalysisService,
} from "@telespot/analysis-refactor/data-access";
import { AssetUtils, IAssetROI } from "@telespot/sdk";
import { TOsdActiveAction } from "@telespot/shared/viewers/data-access";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { VideoViewerComponent } from "../components/video-viewer/video-viewer.component";
import { RoisDirective } from "./rois.base.directive";

@Directive({
  selector: "ts-video-viewer:[appVideoRois]",
  exportAs: "videoROIs",
})
export class VideoRoisDirective
  extends RoisDirective<VideoViewerComponent>
  implements OnInit, OnDestroy
{
  protected _roiElements: ROI[] = [];
  private _destroy: Subject<void> = new Subject();
  public rois$ = this._roiService.microVisibleROIs$;

  private getAllLabels(roi: ROI): string[] {
    return roi
      ? roi.labels.reduce(
          (acc, currLabel) => [...acc, ...Object.keys(currLabel.labels)],
          []
        )
      : undefined;
  }

  getColor(roi: ROI, opaque?: boolean): string {
    return this.getModelColor(this.getAllLabels(roi), opaque);
  }

  getModelsList(roi: ROI): string {
    //REVIEW
    // roi.isAIresult
    //   ? `[AI] - ${[...roi.models?.map((model) => model.displayName), roi.classAI].join(', ') || '(no tags)'}`:
    return `${
      this.getAllLabels(roi)
        ?.map((label) => label.substring(label.indexOf("value:") + 6))
        .join(",") || "(no tags)"
    }`;
  }
  // addROIs(model: RoiModel, rois: IAssetROIWithModels[], animate?: boolean): void {
  addROIs(rois: IAssetROI[]): void {
    try {
      this._roiService.addROIs(rois);
    } catch (err) {
      this.snackbar.open(err.message, null, { duration: 800 });
    }
    // Nothing to do here until bookmarks logic is reviewed
  }

  constructor(
    @Host() @Self() protected viewerComponent: VideoViewerComponent,
    protected renderer: Renderer2,
    protected resolver: ComponentFactoryResolver,
    protected snackbar: MatSnackBar,
    protected _roiService: RoiService,
    protected _sampleAnalysisService: SampleAnalysisService
  ) {
    super(
      viewerComponent,
      renderer,
      resolver,
      snackbar,
      _roiService,
      _sampleAnalysisService
    );

    this.viewerComponent.bookmarkClicked
      .pipe(takeUntil(this._destroy))
      .subscribe((roi) => {
        // FIXNOW: viewer component should know about the selected Asset
        this.addROIs([roi]);
      });

    this.viewerComponent.bookmarkAltClicked
      .pipe(takeUntil(this._destroy))
      .subscribe(($event) => {
        this.removeROIs([$event]);
      });
  }

  ngOnInit() {
    console.log(`appVideoRois attached`);
    this._setupEventHandlers();
    this.rois$
      .pipe(takeUntil(this._destroy))
      .subscribe((rois) => (this._roiElements = rois as ROI[]));
  }

  ngOnDestroy(): void {
    this._destroy.next();
  }

  protected _removeEventHandlers() {
    return;
  }

  protected _setupEventHandlers() {
    console.log(
      `Directive setting event handlers for host`,
      this.viewerComponent.canvas
    );

    this.viewerComponent.canvas.nativeElement.addEventListener(
      "mousedown",
      this._mouseDownHandler.bind(this)
    );
    this.viewerComponent.canvas.nativeElement.addEventListener(
      "mouseup",
      this._stopPan
    );

    this.viewerComponent.frameDrawn$
      .pipe(takeUntil(this._destroy))
      .subscribe((_) => {
        if (!this.viewerComponent.ctx) return;
        this._roiElements
          .filter(
            (roi) =>
              Math.abs(roi.time - this.viewerComponent.videoCurrentTime) < 1
          )
          .forEach((roi) => {
            this.viewerComponent.ctx.strokeStyle = this.getModelColor(
              this.getAllLabels(roi),
              true
            );
            this.viewerComponent.ctx.strokeRect(
              roi.x,
              roi.y,
              roi?.w - 1,
              roi?.h - 1
            );

            this.viewerComponent.ctx.stroke();
          });

        if (this._tempROI) {
          this.viewerComponent.ctx.strokeStyle = "white";
          this.viewerComponent.ctx.strokeRect(
            this._tempROI.x,
            this._tempROI.y,
            this._tempROI.w,
            this._tempROI.h
          ),
            this.viewerComponent.ctx.stroke();
        }
      });
  }

  private _getImagePosition = (event) => {
    const tform = this.viewerComponent.canvasTransform;
    return {
      x: ((event.offsetX - tform[4]) * 1) / tform[0],
      y: ((event.offsetY - tform[5]) * 1) / tform[0],
    };
  };

  private _mouseDownHandler = (event) => {
    if (event.shiftKey) {
      if (this._activeMode !== TOsdActiveAction.drawing) {
        // Initialize ROI
        const pos = this._getImagePosition(event);
        this._tempROI = {
          x: pos.x,
          y: pos.y,
          w: 0,
          h: 0,
          time: this.viewerComponent.video.nativeElement.currentTime,
        };
      }
      this._activeMode = TOsdActiveAction.drawing;
      this.viewerComponent.canvas.nativeElement.addEventListener(
        "mousemove",
        this._mouseMoveHandler
      );
      this.viewerComponent.video.nativeElement.pause();
      console.log(`ROI updated to ${AssetUtils.toString(this._tempROI)}`);
    }
  };

  private _mouseMoveHandler = (event) => {
    if (this.activeMode === TOsdActiveAction.drawing) {
      const pos = this._getImagePosition(event);
      this._tempROI.w = pos.x - this._tempROI.x;
      this._tempROI.h = pos.y - this._tempROI.y;
    }
  };

  private _stopPan = (event) => {
    if (this._tempROI) {
      AssetUtils.makeIntegerCoords(this._tempROI);
      const time2 = this.viewerComponent.video.nativeElement.currentTime;
      console.log(`ROI FINISHED: ${AssetUtils.toString(this._tempROI)}`);
      this.addROIs([this._tempROI]);
      this._tempROI = undefined;
    }
    this._activeMode = TOsdActiveAction.idle;
    this.viewerComponent.canvas.nativeElement.removeEventListener(
      "mousemove",
      this._mouseMoveHandler
    );
  };

  processBookMarkDrag(roi: ROI, event) {
    const timeDelta =
      (event.distance.x /
        this.viewerComponent.trackBar.nativeElement.clientWidth) *
      this.viewerComponent.videoDuration;
    const time = (roi.time || 0) + timeDelta;
    this._roiService.updateROItime({ roi, time });
    this.viewerComponent.gotoBookmark(null, { ...roi, time });
  }
}
