import { Inject, Injectable, Optional } from '@angular/core';
import { AngularFireAnalytics, COLLECTION_ENABLED } from '@angular/fire/analytics';
import { LoggerService } from '@telespot/shared/logger/feature/util';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private _enabled = false;

  constructor(
    private firebase: AngularFireAnalytics,
    @Inject(COLLECTION_ENABLED) private collection_enabled: boolean,
    @Optional() private logger: LoggerService
  ) {
    this.setEnabled(this.collection_enabled);
    this.logger?.debug(`Analytics service is ${this.isEnabled ? 'ENABLED' : 'DISABLED'}`);
  }

  public get isEnabled(): boolean {
    return this._enabled;
  }

  public setEnabled(enabled: boolean): void {
    this._enabled = enabled;
    this.firebase.setAnalyticsCollectionEnabled(enabled);
  }

  public setCurrentScreen(title: string): Promise<void> {
    if (!this.isEnabled) {
      this.logger?.warn(`setCurrentScreen '${title}' failed: Firebase analytics are currently disabled`);
      return;
    }
    return this.firebase.setCurrentScreen(title);
  }

  public logEvent(event: string, additional?: { [key: string]: unknown }): Promise<void> {
    if (!this.isEnabled) {
      this.logger?.warn(`logEvent '${event}' failed: Firebase analytics are currently disabled`);
      return;
    }
    return this.firebase.logEvent(event, additional);
  }

  public setUserProperties(props: { [key: string]: unknown }): Promise<void> {
    if (!this.isEnabled) {
      this.logger?.warn(`Firebase analytics are currently disabled`);
      return;
    }
    return this.firebase.setUserProperties(props);
  }
}
