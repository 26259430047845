<div class="main-container">
  <div class="title">
    <h1>{{ 'title.organization_editor' | translate }}</h1>
  </div>

  <form [formGroup]="formGroup" *ngIf="formGroup">
    <div class="card padded">
      <div class="card-body" style="display: inline-flex; flex-direction: column">
        <mat-form-field>
          <input type="text" matInput required name="name" [formControlName]="'name'" data-cy="input-organization-name" />
          <mat-label>{{ 'label.organization_name' | translate | uppercase }}</mat-label>
        </mat-form-field>

        <div class="card flex-column align-items-center" style="width: 176px; padding: 10px; margin: 10px auto">
          <img
            id="img-logo"
            [src]="logoImgSource || '../../../../assets/media/image_placeholder.svg'"
            alt="organization logo"
          />
          <span style="align-self: center; margin-top: 12px">
            <ng-container *ngIf="logoImgSource; else addLogo">
              <label for="logoInput" class="text-primary flex-row flex-align-center interactive-label">
                <i class="ri-pencil-line" style="margin-right: 6px"></i>
                <span>{{ 'button.edit' | translate }}</span>
              </label>
              <label class="text-danger flex-row flex-align-center interactive-label">
                <i class="ri-delete-bin-6-line" style="margin-right: 6px"></i>
                <span>{{ 'button.remove' | translate }}</span>
              </label>
            </ng-container>
            <ng-template #addLogo>
              <label for="logoInput" class="text-primary flex-row flex-align-center interactive-label">
                <i class="ri-add-circle-line" style="margin-right: 6px"></i>
                <span>Add logo</span>
              </label>
            </ng-template>
          </span>
          <input
            id="logoInput"
            hidden
            type="file"
            #logoInput
            hidden
            (change)="fileChange($event)"
            class="form-control"
            name="logo"
            [formControlName]="'logo'"
            placeholder="[image url]"
          />
        </div>
      </div>

      <div class="card-body">
        <h3>
          {{ 'core.license' | translate }}
        </h3>
        <div>
          <ng-container *ngFor="let license of licenses$ | async">
            <button
              class="btn btn-outline-primary"
              [class.active]="license === selectedLicense"
              (click)="selectLicense(license)"
              style="margin: 0px 4px"
              data-cy="license-selector"
            >
              {{ license.name }}
            </button>
          </ng-container>
        </div>
      </div>
      <div class="card-body">
        <div formGroupName="license">
          <h3>License limits</h3>
          <div
            class="infline-form"
            style="align-content: space-between; display: flex; flex-direction: row; align-items: center"
            *ngFor="let limit of ['maxUsers', 'maxWorkspaces', 'maxStorage']"
          >
            <label style="margin-right: 10px; min-width: 200px; flex-basis: 200px" for="input+{{ limit }}">
              {{ 'label.' + limit | translate }}
            </label>
            <mat-form-field style="width: 100px; align-self: flex-end">
              <input
                id="input+{{ limit }}"
                type="number"
                matInput
                [name]="limit"
                min="-1"
                [formControlName]="limit"
                style="text-align: center"
              />
              <button
                mat-icon-button
                class="btn btn-flat-primary"
                matPrefix
                [disabled]="false"
                (click)="incrementLimit(limit, -1)"
              >
                <i class="ri-subtract-fill text-primary"></i>
              </button>
              <button
                mat-icon-button
                class="btn btn-flat-primary"
                matSuffix
                [disabled]="false"
                (click)="incrementLimit(limit, 1)"
              >
                <i class="ri-add-circle-line text-primary"></i>
              </button>
              <mat-hint *ngIf="formGroup.get(['license', limit]).value === -1">
                {{ 'core.unlimited' | translate }}
              </mat-hint>
            </mat-form-field>
          </div>

          <h3>{{ 'label.features' | translate }}</h3>

          <div formGroupName="features">
            <div *ngFor="let feature of features" class="feature">
              <div [formGroupName]="feature.name">
                <div>
                  <mat-checkbox formControlName="enabled">
                    {{ feature.displayName | translate }}
                  </mat-checkbox>
                  <div class="feature__description">
                    {{ feature.description | translate }}
                  </div>
                </div>
                <div *ngFor="let subFeature of feature.children" class="ps-4">
                  <mat-checkbox [formControlName]="subFeature.name">
                    {{ subFeature.displayName | translate }}
                  </mat-checkbox>
                  <div class="feature__description">
                    {{ subFeature.description | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h3>{{ 'core.other' | translate }}</h3>
          <mat-form-field class="full-width">
            <mat-label>{{ 'label.default_language' | translate }}</mat-label>
            <mat-select [formControlName]="'language'">
              <mat-option [value]="language.value" *ngFor="let language of languages">
                {{ language?.label | translate | titlecase }}
              </mat-option>
            </mat-select>
            <mat-hint>{{ 'tooltip.organization_language' | translate }}</mat-hint>
          </mat-form-field>

          <h3>Environments (experimental)</h3>
          <mat-checkbox formControlName="tiraspot">Tiraspot</mat-checkbox>
        </div>
      </div>
      <div class="card-footer card-actions">
        <button
          class="btn btn-outline-primary card-actions__action"
          data-cy="button-cancel-organization"
          style="flex-grow: 0"
          (click)="cancel()"
        >
          {{ 'button.cancel' | translate | uppercase }}
        </button>
        <button
          class="btn btn-primary card-actions__action"
          style="flex-grow: 0"
          (click)="submit()"
          data-cy="button-save-organization"
          [disabled]="!formGroup.valid"
        >
          {{ 'button.save' | translate | uppercase }}
        </button>
      </div>
    </div>
  </form>
</div>
