import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { Organization, Workspace } from "@telespot/sdk";
import {
  applyWorkspacesFilter,
  clearWorkspacesState,
  removeWorkspacesFilter,
  setCurrOrganization,
  workspacesActionError,
  workspacesChangePage,
  workspacesCounted,
  workspacesListed,
} from "./workspaces.actions";
import { FilterConfig } from "@shared/ui";

export interface WorkspacesState {
  currOrganization: Organization;
  workspacesList: EntityState<Workspace>;
  totalWorkspaces: number;
  pageIndex: number;
  pageSize: number;
  filters: EntityState<FilterConfig>;
  loading: boolean;
  error: string | null;
}

const worskpacesAdapter = createEntityAdapter<Workspace>();
const filtersAdapter = createEntityAdapter<FilterConfig>({
  selectId: (filter) => filter.name,
});

export const initialWorkspacesState: WorkspacesState = {
  currOrganization: undefined,
  workspacesList: worskpacesAdapter.getInitialState(),
  totalWorkspaces: 0,
  pageIndex: 0,
  pageSize: 10,
  filters: filtersAdapter.getInitialState(),
  loading: true,
  error: null,
};

export const workspacesReducer = createReducer(
  initialWorkspacesState,
  on(clearWorkspacesState, () => ({
    ...initialWorkspacesState,
  })),
  on(workspacesActionError, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(setCurrOrganization, (state, { organization }) => ({
    ...state,
    currOrganization: organization,
    workspacesList: worskpacesAdapter.removeAll(state.workspacesList),
    pageIndex: 0,
    pageSize: 10,
    loading: true,
    error: null,
  })),
  on(workspacesChangePage, (state, { pageIndex, pageSize }) => ({
    ...state,
    loading: true,
    workspacesList: worskpacesAdapter.removeAll(state.workspacesList),
    pageIndex,
    pageSize,
  })),
  on(applyWorkspacesFilter, (state, { filter: filters }) => ({
    ...state,
    filters: filtersAdapter.upsertOne(filters, state.filters),
    loading: true,
    workspacesList: worskpacesAdapter.removeAll(state.workspacesList),
    error: null,
    pageIndex: 0,
  })),
  on(removeWorkspacesFilter, (state, { filter: filters }) => ({
    ...state,
    filters: filtersAdapter.removeOne(filters.name, state.filters),
    loading: true,
    workspacesList: worskpacesAdapter.removeAll(state.workspacesList),
    error: null,
    pageIndex: 0,
  })),
  on(workspacesListed, (state, { workspacesList }) => ({
    ...state,
    loading: false,
    workspacesList: worskpacesAdapter.setAll(
      workspacesList,
      state.workspacesList
    ),
  })),
  on(workspacesCounted, (state, { count }) => ({
    ...state,
    totalWorkspaces: count,
  }))
);
