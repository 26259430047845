<div class="card no-padding shadow-hard m-0">
  <table
    mat-table
    [dataSource]="dataSource$"
    [trackBy]="trackByOrg"
    style="width: 100%"
    class="responsive"
  >
    <ng-container matColumnDef="logo">
      <th mat-header-cell *matHeaderCellDef></th>
      <td
        mat-cell
        *matCellDef="let item"
        [class.selected]="item.selected"
        class="ps-0"
      >
        <span class="flex-row align-items-stretch h-100">
          <span class="marker"></span>
          <img
            class="user-avatar user-avatar--no-radius user-avatar--60 p-2"
            [src]="
              item.organization?.logoUrl ||
              'assets/media/organization_placeholder.svg'
            "
            fallbackSrc="assets/media/organization_placeholder.svg"
          />
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="organization_name">
      <th mat-header-cell *matHeaderCellDef>
        <b>{{ "core.organization" | translate | uppercase }}</b>
      </th>
      <td mat-cell *matCellDef="let item" class="organization-name">
        {{ item.organization?.name | uppercase }}
      </td>
    </ng-container>

    <ng-container matColumnDef="new_cases">
      <th mat-header-cell *matHeaderCellDef>
        <b>{{ "label.new_cases" | translate | uppercase }}</b>
      </th>
      <td mat-cell *matCellDef="let item">
        <span
          class="new-cases flex-row align-items-center"
          [class.new-cases--none]="!state.newCases"
          *ngIf="{ newCases: item.new_cases | async } as state"
        >
          <span class="new-cases__indicator"><span class="circle"></span></span>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="last_case">
      <th mat-header-cell *matHeaderCellDef>
        <b>{{ "label.last_analyzed_case_name" | translate | uppercase }}</b>
      </th>
      <td mat-cell *matCellDef="let item">
        <a
          (click)="$event.stopPropagation()"
          [routerLink]="['/analyze', 'cases', lastCase?.case.id]"
          *ngIf="item.last_case | async as lastCase"
          >{{
            !lastCase?.case?.workspace?.caseType?.hasEncryptedIdentifier ||
            lastCase?.case?.createdBy.id === currentUser.id
              ? lastCase?.case.name
              : lastCase?.case.id
          }}</a
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item">
        <button
          *ngIf="item.last_case | async as lastCase"
          mat-icon-button
          class="text-primary"
          [routerLink]="['/analyze', 'cases', lastCase.case.id]"
          (click)="$event.stopPropagation()"
        >
          <i class="ri-eye-line"></i>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns$ | async"
      style="max-height: 56px"
      (click)="select(row)"
      [routerLink]="['/workspaces']"
    ></tr>
  </table>
</div>

<ts-data-table
  *ngIf="dataTableConfig"
  [config]="dataTableConfig"
  [dataSource]="dataTableConfig"
  (selectItem)="select($event)"
>
  <ng-template [dataTableCell]="'logo'" let-logo>
    <img
      class="user-avatar user-avatar--no-radius user-avatar--60 p-2"
      [src]="logo || 'assets/media/organization_placeholder.svg'"
      fallbackSrc="assets/media/organization_placeholder.svg"
    />
  </ng-template>
  <ng-template [dataTableCell]="'name'" let-organizationName>
    {{ organizationName | uppercase }}
  </ng-template>
  <ng-template [dataTableCell]="'new_cases'" let-newCases$>
    <ng-container>
      <span
        class="new-cases flex-row align-items-center"
        [class.new-cases--none]="!state.newCases"
        *ngIf="{ newCases: newCases$ | async } as state"
      >
        <span class="new-cases__indicator"><span class="circle"></span></span>
      </span>
    </ng-container>
  </ng-template>
  <ng-template [dataTableCell]="'last_case'" let-lastCase>
    <ng-container *ngIf="lastCase | async as case">{{
      case?.case.name
    }}</ng-container>
  </ng-template>
  <ng-template [dataTableCell]="'last_case_action'" let-lastCase>
    <ng-container *ngIf="lastCase | async as case">
      <button
        mat-icon-button
        class="text-primary"
        [routerLink]="['/analyze', 'cases', case.case.id]"
        (click)="$event.stopPropagation()"
      >
        <i class="ri-eye-line"></i>
      </button>
    </ng-container>
  </ng-template>
</ts-data-table>
