import { Workspace } from "@telespot/sdk";

export interface WorkspaceRowData {
  workspace: Workspace;
}

export const columns = [
  {
    name: "workspaces_list",
    value: (item) => item,
    select: ["name", "caseType.name", "numCases", "numMembers"],
    include: ["caseType"],
    queryFilters: [],
  },
];
