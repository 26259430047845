import { Entity } from "./entity";
import { User } from "./user";

export interface MemberAttributes extends Entity {
  user: User;
  workspaceId: string;
}

// REVIEW: remove user dependency and represent relevant user data here
export class Member {

  constructor(private attributes: MemberAttributes) { }

  public get id(): string {
    return this.attributes.id;
  }

  public get user(): User {
    return this.attributes.user;
  }

  public get workspaceId(): string {
    return this.attributes.workspaceId;
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }
}
