import { CommonModule, Location } from '@angular/common';
import { Directive, HostBinding, HostListener, NgModule } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[navigateBack]:not([routerLink]):not([href]),button[navigateBack]:not([routerLink]):not([href])',
})
export class NavigateBackDirective {
  // This is only so that anchors are displayed as valid links
  @HostBinding('attr.href') href = '';

  @HostListener('click') onClick() {
    this.location.back();
  }

  constructor(private location: Location) {}
}

@NgModule({
  declarations: [NavigateBackDirective],
  imports: [CommonModule],
  exports: [NavigateBackDirective],
})
export class NavigateBackDirectiveModule {}
