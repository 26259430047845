import { Injectable } from "@angular/core";
import {
  AnalysisState,
  Case,
  Query,
  Workspace,
  User,
  CloudFunctions,
} from "@telespot/sdk";
import { CaseMapper } from "./cases.mapper";

export interface ListCasesOptions {
  workspace: Workspace;
  sorts?;
  filters?;
  skip?;
  limit?;
}

@Injectable({
  providedIn: "root",
})
export class CasesService {
  public async listCompatibleWorkspaces(workspace: Workspace) {
    const workspacesQuery = new Query(Workspace)
      .equalTo("organization", workspace.organization)
      .equalTo("caseType", workspace.caseType)
      .notEqualTo("objectId", workspace.id);

    return workspacesQuery.find();
  }

  public async delete(id: string) {
    return CloudFunctions.delete({ id, className: Case.className });
  }

  public async move(id: string, workspaceId: string) {
    // TODO: manage numCases decrement and increments
    // const caseWS = <Workspace>item.case.get("workspace");
    // caseWS.updateCases(caseWS.get("numCases") - 1);
    // caseWS.save();
    return CloudFunctions.moveCases(workspaceId, id);
  }

  public async copy(id: string, workspaceId: string) {
    return CloudFunctions.copyCases(workspaceId, id);
  }

  public async list(options: ListCasesOptions) {
    const query = this.mapOptionsToQuery(options);

    const itemsPromise = query.find();
    const statesPromise = this.getStates(options);

    const [cases, states] = await Promise.all([itemsPromise, statesPromise]);

    const casesWithState = cases.map(CaseMapper.toAdaptaspotRow(states));

    return casesWithState;
  }

  public async count(options: ListCasesOptions) {
    const query = this.mapOptionsToQuery(options);
    return query.count();
  }

  public async getStates(options: ListCasesOptions) {
    const casesQuery = this.mapOptionsToQuery(options);

    const caseStatesQuery = new Query(AnalysisState)
      .exists("case")
      .exists("user")
      .matchesQuery("case", casesQuery)
      .include(["user"])
      .doesNotExist("asset");

    return caseStatesQuery.findAll();
  }

  private mapOptionsToQuery(options: ListCasesOptions) {
    const { workspace, sorts, filters, skip, limit } = options;

    const query = new Query(Case)
      .equalTo("workspace", workspace)
      .include("createdBy")
      .descending("createdAt");

    if (sorts) {
      Object.keys(sorts).forEach((sortKey) => {
        switch (sorts[sortKey]) {
          case "asc":
            query.ascending(sortKey);
            break;
          case "desc":
            query.descending(sortKey);
            break;
        }
      });
    }
    if (filters) {
      for (const filter of filters) {
        const nameConstraints = { ...filter.constraints };

        switch (filter.name) {
          case "state":
            query.matchesKeyInQuery(
              "objectId",
              "case.objectId",
              new Query(AnalysisState)
                .equalTo("state", filter.constraints.equalTo)
                .equalTo("user", User.current())
                .exists("case")
                .equalTo("case.workspace", workspace)
            );
            break;
          case "name":
            if (workspace.caseType.hasEncryptedIdentifier) {
              nameConstraints.equalTo =
                nameConstraints.contains ?? nameConstraints.equalTo;
              delete nameConstraints.contains;
            }
            if (nameConstraints.equalTo) {
              query.equalTo("organization", workspace.organization);
              query.equalTo("name", nameConstraints.equalTo);
            } else if (nameConstraints.contains) {
              const value = nameConstraints.contains as string;
              query.matches("name", new RegExp(`${value.toLowerCase()}`), "i");
            }
            break;
          default:
            if (filter.constraints?.contains) {
              query.contains(
                filter.name,
                filter.constraints.contains as string
              );
            }
            if (filter.constraints?.equalTo) {
              query.equalTo("organization", workspace.organization);
              query.equalTo(filter.name, filter.constraints.equalTo as string);
            }
            if (filter.constraints?.after) {
              query.greaterThanOrEqualTo(filter.name, filter.constraints.after);
            }
            if (filter.constraints?.before) {
              query.lessThan(filter.name, filter.constraints.before);
            }
        }
      }
    }
    if (limit) {
      query.limit(limit);
    }
    if (skip) {
      query.skip(skip);
    }

    return query;
  }
}
