import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import generateId from "uid";

import * as AnalysisActions from "./analysis.actions";
import { Label } from "../protocol";
import { v4 } from "uuid";
import { RoiMapper } from "../../services/roi-service/roi.mapper";

const ID_LENGTH = 10;

export interface IAnalysis {
  readonly id: string;
  synced: boolean;
  readonly assetId: string | undefined;
  readonly createdBy: Parse.Pointer;
  readonly sampleId: string;
  readonly isSampleAnalysis: boolean;
  pipelineId: string;
  fetchedPartially: boolean;
}

export interface IFinding {
  readonly id: string;
  readonly createdBy: Parse.Pointer;
  readonly version: number;
  readonly analysisId: string;
  readonly assetId: string;
  data: string | string[];
  readonly synced: boolean;
  taskId: string;
  uuid: string;
}

export function labelsToAnalysisLabelFormat(
  labels: Label[],
  analysisArray: IAnalysis[],
  findings: IFinding[]
): AnalysisLabel[] {
  return labels.reduce<AnalysisLabel[]>((acc, label) => {
    const analysisIdForLabel = analysisArray.find(
      (analysis) => analysis.pipelineId === label.pipelineId
    )?.id;

    const findingId = findings.find(
      (finding) =>
        finding.analysisId === analysisIdForLabel &&
        label.taskId === finding?.taskId
    )?.id;

    if (!analysisIdForLabel) {
      return acc;
    }

    const existingLabel = acc.find(
      (item) =>
        item.analysisId === analysisIdForLabel && item.findingId === findingId
    );

    if (existingLabel) {
      existingLabel.labels = { ...existingLabel.labels, [label.uuid]: 1 };
    } else {
      acc.push({
        analysisId: analysisIdForLabel,
        findingId: findingId,
        labels: { [label.uuid]: 1 },
      });
    }

    return acc;
  }, []);
}

export function getAnalysis(state: AnalysisState) {
  return Object.values(state.analysis.entities ?? {});
}

export function getFindings(state: AnalysisState) {
  return Object.values(state.findings.entities ?? {});
}

export function getRois(state: AnalysisState) {
  return Object.values(state.rois.entities ?? {});
}

export function getUnsyncedAnalysis(
  entityStates: EntityState<IAnalysis>
): IAnalysis[] {
  return Object.values(entityStates.entities).filter((a) => !a.synced);
}

export function getUnsyncedFindings(
  entityStates: EntityState<IFinding>
): IFinding[] {
  return Object.values(entityStates.entities).filter((f) => !f.synced);
}

export function findAnalysis(
  state: AnalysisState,
  filter: (analysis: IAnalysis) => boolean
) {
  return Object.values(state.analysis.entities ?? {}).find(filter);
}

export function findFinding(
  state: AnalysisState,
  filter: (findings: IFinding) => boolean
) {
  return Object.values(state.findings.entities ?? {}).find(filter);
}

export function findROI(state: AnalysisState, filter: (roi: ROI) => boolean) {
  return Object.values(state.rois.entities ?? {}).find(filter);
}

export function getROIsInsideRegion(
  rois: (ROI | POI)[],
  bounds,
  activeAnalysisIds: string[]
) {
  return rois?.filter(
    (roi) =>
      getLabelAnalysisIds([roi]).every((analysisId) =>
        activeAnalysisIds.includes(analysisId)
      ) &&
      roi.x >= bounds.x &&
      (!("w" in roi)
        ? roi.x <= bounds.x + bounds.w
        : roi.x + (roi as ROI).w <= bounds.x + bounds.w) &&
      roi.y >= bounds.y &&
      (!("h" in roi)
        ? roi.y <= bounds.y + bounds.h
        : roi.y + (roi as ROI).h <= bounds.y + bounds.h)
  );
}

export function roiExists(roi, rois: (ROI | POI)[]) {
  return rois.find(
    (r) =>
      r.x === roi.x &&
      r.y === roi.y &&
      (r as ROI)?.w === roi.w &&
      (r as ROI).h === roi.h &&
      JSON.stringify(roi.labels) === JSON.stringify(roi.labels)
  );
}

export function getLabelAnalysisIds(rois: (ROI | POI)[]) {
  const uniqueAnalysisIds = new Set<string>();

  rois?.forEach((roi) => {
    roi?.labels?.forEach((label) => {
      uniqueAnalysisIds.add(label.analysisId);
    });
  });

  return Array.from(uniqueAnalysisIds);
}

export function getLabelFindingIds(rois: (ROI | POI)[]) {
  const uniqueFindingIds = new Set<string>();

  rois?.forEach((roi) => {
    roi?.labels?.forEach((label) => {
      uniqueFindingIds.add(label.findingId);
    });
  });

  return Array.from(uniqueFindingIds);
}

export function getCustomLabels(rois: (ROI | POI)[], protocolLabels: Label[]) {
  const uniqueLabelFindingPairs = new Set<string>();

  rois?.forEach((roi) => {
    roi?.labels?.forEach((label) => {
      Object.keys(label.labels).forEach((l) => {
        const pair = {
          labelId: l,
          findingId: label.findingId,
        };

        if (!protocolLabels.some((protoLabel) => protoLabel.uuid === l)) {
          uniqueLabelFindingPairs.add(JSON.stringify(pair));
        }
      });
    });
  });

  const uniquePairsArray = Array.from(uniqueLabelFindingPairs).map((pairStr) =>
    JSON.parse(pairStr)
  );

  return uniquePairsArray;
}

export function filterROIsWithThresholds(rois, labelsWithThresholds) {
  return rois
    .map((roi) => {
      // Remove labels if score < threshold
      const filteredLabelsArray = roi.labels.map(
        ({ analysisId, findingId, labels }) => {
          const roiLabels = Object.entries(labels || {});

          //Unlabeled rois must be visible
          if (roiLabels.length === 0) return { analysisId, findingId, labels };

          const filteredLabels = roiLabels.reduce((acc, [label, value]) => {
            if (value >= labelsWithThresholds[label]) acc[label] = value;
            return acc;
          }, {});

          return Object.keys(filteredLabels).length > 0
            ? { analysisId, findingId, labels: filteredLabels }
            : null;
        }
      );

      const validLabelsArray = filteredLabelsArray.filter(Boolean) ?? [];

      return validLabelsArray.length > 0
        ? { ...roi, labels: validLabelsArray }
        : null;
    })
    .filter(Boolean);
}

export function getUniqueLabels(rois): string[] {
  const uniqueLabels = new Set<string>();
  rois.forEach((roi) => {
    roi.labels.forEach((labelGroup) => {
      Object.keys(labelGroup.labels).forEach((l) => {
        uniqueLabels.add(l);
      });
    });
  });

  return Array.from(uniqueLabels);
}

export function getSyncUpdates(entities) {
  return (entities || []).map((item) => ({
    id: item,
    changes: { synced: false },
  }));
}

export function getActiveCopiedAnalysis(activeAnalysis: IAnalysis[]) {
  const currentAnalysisIds = activeAnalysis.map((_analysis) => {
    if (_analysis?.id.startsWith("copy:"))
      return _analysis.id.substring(_analysis.id.indexOf("replace:"));
    return _analysis.id;
  });
  return activeAnalysis.filter(
    (a) => !currentAnalysisIds.includes("replace:" + a.id)
  );
}
export function hasCopy(allAnalysis: IAnalysis[], analysisId) {
  return allAnalysis.some((a) => a.id.includes("replace:" + analysisId));
}
export function roisDeduplication(rois: ROI[]) {
  const cleanedRois: ROI[] = rois?.reduce((u, r) => {
    const index = u.findIndex(
      (ur) => ur.x === r.x && ur.y === r.y && ur?.w === r?.w && ur?.h === r?.h
    );
    if (index === -1) return [...u, ...[r]];

    const labelGroupIndex = u[index].labels.findIndex(
      (labelGroup) => labelGroup.analysisId === r.labels[0].analysisId
    );

    if (labelGroupIndex === -1) {
      u[index] = {
        ...u[index],
        labels: [...u[index].labels, r.labels[0]],
      };
    } else {
      u[index] = {
        ...u[index],
        labels: [
          ...u[index].labels.filter(
            (label, index) => index !== labelGroupIndex
          ),
          {
            ...u[index].labels[labelGroupIndex],
            labels: {
              ...u[index].labels[labelGroupIndex].labels,
              ...r.labels[0].labels,
            },
          },
        ],
      };
    }

    return [...u];
  }, []);

  return cleanedRois;
}

export const analysisAdapter = createEntityAdapter<IAnalysis>({
  selectId: (analysis) => analysis.id,
});

export const roiAdapter = createEntityAdapter<ROI | POI>({
  selectId: (xoi) => xoi.id,
});

export const findingAdapter = createEntityAdapter<IFinding>({
  selectId: (finding) => finding.id,
});

export interface POI {
  id: string;
  x: number;
  y: number;
  selected: boolean;
  labels: AnalysisLabel[];
  time?: number;
  //FIX: DELETE THIS
  isAIresult?: boolean;
  cropFileName?: string;
}

export type LabelDetail = { [key: string]: number };
export interface AnalysisLabel {
  analysisId: string;
  findingId: string;
  labels: LabelDetail;
}

export interface ROI extends POI {
  w: number;
  h: number;
}

export enum Mode {
  ANALYSIS = "analysis",
  NAVIGATION = "navigation",
  REVIEW = "review",
}

export interface AnalysisState {
  rois: EntityState<ROI | POI>;
  analysis: EntityState<IAnalysis>;
  findings: EntityState<IFinding>;
  lastEditedTimestamp: number;
  lastSavedTimestamp: number;
  loading: boolean;
  maskLoading: boolean;
  mosaicsLoading: boolean;
  syncing: boolean;
  error: string | null;
  mode: Mode;
  assetsAnalysisFetched: string[];
}

export const initialAnalysisState: AnalysisState = {
  rois: roiAdapter.getInitialState(),
  analysis: analysisAdapter.getInitialState(),
  findings: findingAdapter.getInitialState(),
  lastEditedTimestamp: Date.now(),
  lastSavedTimestamp: Date.now(),
  loading: false,
  maskLoading: false,
  mosaicsLoading: undefined,
  syncing: false,
  error: null,
  mode: Mode.ANALYSIS,
  assetsAnalysisFetched: [],
};

export const analysisReducer = createReducer(
  initialAnalysisState,
  on(AnalysisActions.analysisActionError, (state, { error }) => ({
    ...state,
    loading: false,
    syncing: false,
    error,
  })),
  on(AnalysisActions.switchMode, (state, { mode }) => {
    if (mode === Mode.ANALYSIS) {
      const analysisCopies = getAnalysis(state)
        .filter((analysis) => analysis.id.startsWith("copy:"))
        .map((analysis) => analysis.id);
      const findingsCopies = getFindings(state)
        .filter((finding) => finding.id.startsWith("copy:"))
        .map((finding) => finding.id);
      const roisCopies = getRois(state)
        .filter((roi) => roi.id.startsWith("copy:"))
        .map((roi) => roi.id);

      return {
        ...state,
        mode,
        findings: findingAdapter.removeMany(findingsCopies, state.findings),
        analysis: analysisAdapter.removeMany(analysisCopies, state.analysis),
        rois: roiAdapter.removeMany(roisCopies, state.rois),
      };
    }
    return {
      ...state,
      mode,
    };
  }),
  on(AnalysisActions.setROIs, (state, { rois, createdBy }) => {
    const newRois = rois.filter((roi) => !roiExists(roi, getRois(state)));

    const roisToAdd = newRois.map((roi) => {
      const id = RoiMapper.getId(
        { x: roi.x, y: roi.y, w: roi.w, h: roi.h },
        createdBy.objectId
      );
      return {
        ...roi,
        selected: false,
        isAIresult: false,
        id: roi.labels.some((label) => label.analysisId.startsWith("copy:"))
          ? `copy:${id}`
          : id,
      };
    });

    const updateAnalyses = getSyncUpdates(getLabelAnalysisIds(roisToAdd));
    const updateFindings = getSyncUpdates(getLabelFindingIds(roisToAdd));
    return {
      ...state,
      rois: { ...roiAdapter.addMany(roisToAdd, state?.rois) },
      analysis: analysisAdapter.updateMany(updateAnalyses, state.analysis),
      findings: findingAdapter.updateMany(updateFindings, state.findings),
      lastEditedTimestamp: Date.now(),
    };
  }),
  on(AnalysisActions.setSelectedROIs, (state, { rois, replace }) => {
    let selectedROIs: (ROI | POI)[] =
      rois?.map((roi) => ({ ...roi, selected: true })) ?? [];

    if (replace) {
      const currentSelectedROIs = state.rois.ids
        .map((roiId) => state.rois?.entities[roiId])
        .filter((roi) => roi.selected)
        .map((roi) => ({ ...roi, selected: false }));
      selectedROIs = selectedROIs?.concat(currentSelectedROIs);
    }
    return {
      ...state,
      rois: { ...roiAdapter.upsertMany(selectedROIs, state?.rois) },
    };
  }),
  on(AnalysisActions.removeROIs, (state, { rois, selectedROIs }) => {
    const roisList = selectedROIs ?? rois;
    if (!roisList.length) return { ...state };
    const roisToRemove = roisList.map((roi) => roi.id);

    const analysisIds = getLabelAnalysisIds(roisList);
    const updateAnalyses = getSyncUpdates(analysisIds);

    const updateFindings = getSyncUpdates(getLabelFindingIds(roisList));

    return {
      ...state,
      rois: {
        ...roiAdapter.removeMany(roisToRemove, state?.rois),
      },
      findings: findingAdapter.updateMany(updateFindings, state.findings),
      analysis: analysisAdapter.updateMany(updateAnalyses, state.analysis),
      lastEditedTimestamp: Date.now(),
    };
  }),
  on(AnalysisActions.updateROI, (state, { roi, changes }) => ({
    ...state,
    rois: {
      ...roiAdapter.updateOne(
        {
          id: roi?.id,
          changes: {
            ...changes,
            selected:
              getRois(state).find((i) => roi.id === i.id)?.selected ??
              changes.selected,
          },
        },
        state?.rois
      ),
    },
    analysis: analysisAdapter.updateMany(
      roi ? getSyncUpdates(getLabelAnalysisIds([roi])) : [],
      state.analysis
    ),
    findings: findingAdapter.updateMany(
      roi ? getSyncUpdates(getLabelFindingIds([roi])) : [],
      state.findings
    ),
    lastEditedTimestamp: Date.now(),
  })),
  on(
    AnalysisActions.updateROILabels,
    (state, { label, replacePrevLabels, authUserId }) => {
      const selectedROIs = Object.values(state?.rois?.entities ?? {})?.filter(
        (roi) => roi?.selected
      );
      if (selectedROIs.length === 0) return { ...state };

      const allAnalysis = getAnalysis(state);
      const analysis = allAnalysis?.find(
        (analysis) =>
          analysis.pipelineId === label?.pipelineId &&
          analysis?.assetId === label?.activeAssetId &&
          analysis.createdBy.className === "_User" &&
          analysis.createdBy.objectId === authUserId &&
          !hasCopy(allAnalysis, analysis.id)
      );
      const analysisId = analysis?.id;

      const finding = findFinding(
        state,
        (f) => f.analysisId === analysisId && f.taskId === label?.taskId
      ) ?? {
        id: `new:${generateId(ID_LENGTH)}`,
        analysisId: analysisId,
        assetId: analysis.assetId,
        createdBy: analysis.createdBy,
        data: undefined,
        uuid: v4(),
        version: 1,
        synced: false,
        taskId: label?.taskId,
      };

      const newLabelInfo = {
        analysisId,
        findingId: finding?.id,
        labels: {
          ...label?.labels?.reduce((acc, l) => ({ ...acc, [l]: 1 }), {}),
        },
      };

      const updatedRois = selectedROIs
        .map((roi) => ({
          ...roi,
          labels: replacePrevLabels
            ? [newLabelInfo]
            : [
                ...roi.labels.filter(
                  (lab) => newLabelInfo?.analysisId !== lab.analysisId
                ),
                ...roi.labels.filter(
                  (lab) =>
                    newLabelInfo?.analysisId === lab.analysisId &&
                    newLabelInfo?.findingId !== lab.findingId
                ),
                ...roi.labels
                  .filter(
                    (lab) =>
                      newLabelInfo?.analysisId === lab.analysisId &&
                      newLabelInfo?.findingId === lab.findingId
                  )
                  .map((lab) => ({
                    ...lab,
                    labels: Object.keys(lab.labels).includes(
                      Object.keys(newLabelInfo.labels)[0]
                    )
                      ? {
                          ...Object.keys(lab.labels).reduce((acc, l) => {
                            return {
                              ...acc,
                              ...(l !== Object.keys(newLabelInfo.labels)[0]
                                ? { [l]: lab.labels[l] }
                                : []),
                            };
                          }, {}),
                        }
                      : { ...lab.labels, ...newLabelInfo.labels },
                  })),
              ],
        }))
        .map((roi) => {
          const addLabel = !roi.labels.some(
            (l) => l.findingId === newLabelInfo?.findingId
          );
          if (addLabel)
            return { ...roi, labels: [...roi.labels, newLabelInfo] };
          return roi;
        });

      return {
        ...state,
        findings: finding?.id.startsWith("new:")
          ? findingAdapter.addOne(finding, state.findings)
          : findingAdapter.updateOne(
              { id: finding.id, changes: { ...finding, synced: false } },
              state.findings
            ),
        rois: { ...roiAdapter.upsertMany(updatedRois, state?.rois) },
        analysis: analysisAdapter.updateMany(
          selectedROIs ? getSyncUpdates(getLabelAnalysisIds(selectedROIs)) : [],
          state.analysis
        ),
        lastEditedTimestamp: selectedROIs.length
          ? Date.now()
          : state.lastEditedTimestamp,
      };
    }
  ),
  on(AnalysisActions.exitAnalysis, (state) => ({
    ...initialAnalysisState,
    lastEditedTimestamp: state.lastSavedTimestamp,
    lastSavedTimestamp: state.lastSavedTimestamp,
  })),
  on(AnalysisActions.updateAnalysis, (state, { findings }) => {
    const findingsChanges = [];
    const newFindings = [];
    let activeAnalysis;

    (findings || []).forEach((finding) => {
      const currFinding = findFinding(state, (f) => f.id === finding.id);
      activeAnalysis = findAnalysis(
        state,
        (an) => an.id === finding.analysisId
      );

      if (!currFinding) {
        newFindings.push({
          id: `new:${generateId(ID_LENGTH)}`,
          analysisId: finding.analysisId,
          createdBy: activeAnalysis?.createdBy,
          data: finding?.value,
          uuid: v4(),
          synced: false,
          taskId: finding?.taskId,
        });
        return;
      }

      findingsChanges.push({
        id: currFinding.id,
        changes: {
          ...currFinding,
          data: finding?.value,
          synced: false,
        },
      });
    });

    const findingsIncludingNews = findingAdapter.addMany(
      newFindings,
      state.findings
    );

    return {
      ...state,
      findings: findingAdapter.updateMany(
        findingsChanges,
        findingsIncludingNews
      ),
      analysis: analysisAdapter.updateOne(
        {
          id: activeAnalysis?.id,
          changes: { ...activeAnalysis, synced: false },
        },
        state.analysis
      ),
      lastEditedTimestamp: Date.now(),
    };
  }),
  on(AnalysisActions.loadAssetAnalysis, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    AnalysisActions.assetAnalysisLoaded,
    (state, { analysis, findings, rois }) => ({
      ...state,
      analysis: analysisAdapter.upsertMany(analysis, state.analysis),
      findings: findingAdapter.upsertMany(findings, state.findings),
      rois: roiAdapter.addMany(roisDeduplication(rois), state.rois),
      loading: false,
      lastEditedTimestamp: analysis.some((a) => !a.synced)
        ? Date.now()
        : state.lastEditedTimestamp,
    })
  ),
  on(AnalysisActions.sampleAnalysisLoaded, (state, { analysis, findings }) => ({
    ...state,
    analysis: analysis
      ? analysisAdapter.upsertMany(analysis, state.analysis)
      : state.analysis,
    findings: findings
      ? findingAdapter.upsertMany(findings, state.findings)
      : state.findings,
    loading: false,
  })),
  on(
    AnalysisActions.createAnalysis,
    (state, { assetId, sampleId, createdBy, pipelineId, data }) => {
      const existingAnalysis = findAnalysis(
        state,
        (a) =>
          a.assetId === assetId &&
          a.sampleId === sampleId &&
          JSON.stringify(a.createdBy) === JSON.stringify(createdBy) &&
          a.pipelineId === pipelineId
      );

      if (existingAnalysis) return { ...state };

      const newAnalysis = {
        assetId,
        sampleId,
        createdBy,
        id: `new:${generateId(ID_LENGTH)}`,
        synced: false,
        data: {},
        isSampleAnalysis: assetId === undefined, // FIXME: duplication -> use defered state
        pipelineId,
        fetchedPartially: false,
      };

      const newFindings = (data || []).map((f) => {
        return {
          id: `new:${generateId(ID_LENGTH)}`,
          analysisId: newAnalysis.id,
          version: 1,
          data: f?.value,
          synced: false,
          uuid: v4(),
          taskId: f?.taskId,
          createdBy,
        };
      });

      return {
        ...state,
        analysis: analysisAdapter.addOne(newAnalysis, state.analysis),
        findings: findingAdapter.upsertMany(newFindings, state.findings),
        lastEditedTimestamp: Date.now(),
        loading: false,
      };
    }
  ),
  on(
    AnalysisActions.createAnalysisFromROIs,
    (state, { analysesRequest, selectedLabels, rois, currentUser }) => {
      const newAnalyses: IAnalysis[] = analysesRequest.map((analysisData) => ({
        assetId: analysisData.assetId,
        sampleId: analysisData.sampleId,
        createdBy: analysisData.createdBy,
        id: `new:${generateId(ID_LENGTH)}`,
        synced: false,
        data: {},
        isSampleAnalysis: analysisData.assetId === undefined,
        pipelineId: analysisData.pipelineId,
        fetchedPartially: false,
      }));

      const uniqueTaskIds = new Set<string>();
      const filteredLabels = selectedLabels.filter((label) => {
        if (!uniqueTaskIds.has(label.taskId)) {
          uniqueTaskIds.add(label.taskId);
          return true;
        }
        return false;
      });

      const newFindings = (filteredLabels || []).map((label) => {
        const analysisForLabel = newAnalyses.find(
          (analysis) => analysis.pipelineId === label.pipelineId
        );
        const createdBy = analysisForLabel.createdBy;

        return {
          id: `new:${generateId(ID_LENGTH)}`,
          analysisId: analysisForLabel.id,
          assetId: analysisForLabel.assetId,
          data: undefined,
          synced: false,
          uuid: v4(),
          version: 1,
          createdBy: createdBy,
          taskId: label.taskId,
        };
      });

      const selectedLabelsArray = labelsToAnalysisLabelFormat(
        selectedLabels,
        newAnalyses,
        newFindings
      );

      const roisToAdd = rois.map((roi) => ({
        ...roi,
        selected: false,
        isAIresult: false,
        id: RoiMapper.getId(
          { x: roi.x, y: roi.y, w: roi.w, h: roi.h },
          currentUser.objectId
        ),
        labels: selectedLabelsArray,
      }));

      return {
        ...state,
        analysis:
          newAnalyses.length > 0
            ? analysisAdapter.addMany(newAnalyses, state.analysis)
            : state.analysis,
        findings:
          newFindings.length > 0
            ? findingAdapter.addMany(newFindings, state.findings)
            : state.findings,
        rois: roiAdapter.addMany(roisToAdd, state.rois),
        lastEditedTimestamp: Date.now(),
        loading: false,
      };
    }
  ),
  on(
    AnalysisActions.createFindingsFromROIs,
    (state, { analysis, selectedLabels, rois, currentUser }) => {
      const uniqueTaskIds = new Set<string>();
      const filteredLabels = selectedLabels.filter((label) => {
        if (!uniqueTaskIds.has(label.taskId)) {
          uniqueTaskIds.add(label.taskId);
          return true;
        }
        return false;
      });

      const findings = (filteredLabels || []).map((label) => {
        const analysisForLabel = analysis.find(
          (analysis) => analysis.pipelineId === label.pipelineId
        );
        const finding = findFinding(
          state,
          (f) =>
            f?.analysisId === analysisForLabel.id && f?.taskId === label.taskId
        );

        return {
          id: finding?.id ?? `new:${generateId(ID_LENGTH)}`,
          analysisId: analysisForLabel.id,
          assetId: analysisForLabel.assetId,
          data: undefined,
          synced: false,
          uuid: finding ? finding.uuid : v4(),
          version: finding?.version ?? 1,
          createdBy: analysisForLabel.createdBy,
          taskId: label.taskId,
        };
      });

      const selectedLabelsArray = labelsToAnalysisLabelFormat(
        selectedLabels,
        analysis,
        findings
      );

      const roisToAdd = rois.map((roi) => ({
        ...roi,
        selected: false,
        isAIresult: false,
        id: RoiMapper.getId(
          { x: roi.x, y: roi.y, w: roi.w, h: roi.h },
          currentUser.objectId
        ),
        labels: selectedLabelsArray,
      }));

      const existingFindings = findings.filter((f) => !f.id.startsWith("new:"));
      const newFindings = findings.filter((f) => f.id.startsWith("new:"));

      const updatedFindings = findingAdapter.updateMany(
        getSyncUpdates(existingFindings),
        state.findings
      );

      const updateAnalyses = getSyncUpdates(analysis);

      return {
        ...state,
        findings:
          newFindings.length > 0
            ? findingAdapter.addMany(newFindings, updatedFindings)
            : updatedFindings,
        analysis: analysisAdapter.updateMany(updateAnalyses, state.analysis),
        rois: roiAdapter.addMany(roisToAdd, state.rois),
        lastEditedTimestamp: Date.now(),
        loading: false,
      };
    }
  ),
  on(
    AnalysisActions.analysisCopied,
    (state, { authUser, activeAnalysisIds, newROIs }) => {
      const analysis = getAnalysis(state);
      const findings = getFindings(state);
      const newAnalysis: IAnalysis[] = [];
      const newFindings: IFinding[] = [];
      const findingsCopies: IFinding[] = [];

      const analysisCopies: IAnalysis[] = activeAnalysisIds.map(
        (activeAnalysisId, index) => {
          const analysisToCopy = analysis.find(
            (analysis) => analysis.id === activeAnalysisId
          );

          const analysisToReplace = analysis.find(
            (analysis) =>
              analysis.createdBy.objectId === authUser.objectId &&
              analysisToCopy.pipelineId === analysis.pipelineId &&
              analysisToCopy.assetId === analysis.assetId
          );

          const findingsToCopy = getFindings(state).filter(
            (f) => f.analysisId === activeAnalysisId
          );

          if (!analysisToReplace) {
            newAnalysis.push({
              assetId: analysisToCopy.assetId,
              sampleId: analysisToCopy.sampleId,
              createdBy: authUser,
              id: `new:${generateId(ID_LENGTH)}`,
              synced: false,
              isSampleAnalysis: false,
              pipelineId: analysisToCopy.pipelineId,
              fetchedPartially: false,
            });
          }

          const analysisCopyId = `copy:${analysisToCopy.id}/replace:${
            analysisToReplace?.id ?? newAnalysis[index].id
          }`;

          findingsToCopy.forEach((f, i) => {
            let findingToReplace = findings.find(
              (finding) =>
                finding.createdBy.objectId === authUser.objectId &&
                f.taskId === finding.taskId &&
                f.assetId === finding.assetId
            );

            if (!findingToReplace) {
              const newFinding = {
                createdBy: authUser,
                id: `new:${generateId(ID_LENGTH)}`,
                synced: false,
                data: undefined,
                taskId: f.taskId,
                uuid: v4(),
                version: 1,
                analysisId: analysisCopyId,
                assetId: analysisToCopy.assetId,
              };

              newFindings.push(newFinding);

              findingToReplace = newFinding;
            }
            findingsCopies.push({
              ...f,
              id: `copy:${f.id}/replace:${findingToReplace?.id}`,
              createdBy: authUser,
              synced: false,
              data: f?.data,
              uuid: findingToReplace?.uuid,
              version: findingToReplace?.version,
              analysisId: analysisCopyId,
            });
          });
          return {
            ...analysisToCopy,
            id: analysisCopyId,
            synced: false,
            createdBy: authUser,
          };
        }
      );

      const roiCopies = newROIs.map((roi) => ({
        ...roi,
        id: `copy:${roi.id}`,
        labels: roi.labels
          .filter((label) => activeAnalysisIds.includes(label.analysisId))
          .map((label) => ({
            ...label,
            analysisId: analysisCopies.find((analysis) =>
              analysis.id.startsWith(`copy:${label.analysisId}`)
            ).id,
            findingId: findingsCopies.find((finding) =>
              finding.id.startsWith(`copy:${label.findingId}`)
            )?.id,
          })),
      }));

      return {
        ...state,
        mode: Mode.REVIEW,
        rois: roiAdapter.addMany(roiCopies, state.rois),
        analysis: analysisAdapter.addMany(
          [...analysisCopies, ...newAnalysis],
          state?.analysis
        ),
        findings: findingAdapter.addMany(
          [...findingsCopies, ...newFindings],
          state?.findings
        ),
        lastEditedTimestamp: Date.now(),
      };
    }
  ),
  on(AnalysisActions.loadSampleAnalysis, (state) => ({
    ...state,
    loading: true,
  })),
  on(AnalysisActions.syncAnalysis, (state) => ({
    ...state,
    syncing: true,
  })),
  on(AnalysisActions.analysisSynced, (state, { idChanges, currentUser }) => {
    const unsyncedAnalysis = getUnsyncedAnalysis(state.analysis);
    let currentRois: EntityState<ROI | POI> = state.rois;
    let findingsToDelete = [];

    const newAnalysis = unsyncedAnalysis.filter((an) =>
      state?.mode === Mode.REVIEW
        ? an.id.startsWith("copy:") || an.id.includes("new:")
        : an.id.includes("new:")
    );

    const newAnalysisIds = newAnalysis.map((an) => an.id);

    let analysisAfterSaved = analysisAdapter.removeMany(
      newAnalysisIds,
      state.analysis
    );

    if (state?.mode === Mode.REVIEW) {
      const originalIds = unsyncedAnalysis
        .filter((an) => an.id.startsWith("copy"))
        .map((an) => {
          return an.id.substring(an.id.indexOf("replace:") + 8);
        });

      const newOriginalIds = originalIds.filter((id) => id.includes("new:"));

      analysisAfterSaved = analysisAdapter.removeMany(
        newOriginalIds,
        analysisAfterSaved
      );

      const roisToRemoveIds = Object.values(currentRois.entities ?? {})
        .filter((roi) =>
          roi.labels.some((label) => originalIds.includes(label.analysisId))
        )
        .map((roi) => roi.id);

      const copyRois = Object.values(currentRois.entities ?? {}).filter((roi) =>
        roi.id.startsWith("copy:")
      );

      currentRois = roiAdapter.removeMany(
        [...roisToRemoveIds, ...copyRois.map((roi) => roi.id)],
        state.rois
      );

      const createdRois: (ROI | POI)[] = copyRois.map((roi) => ({
        ...roi,
        id: RoiMapper.getId(
          {
            x: roi.x,
            y: roi.y,
            w: (roi as ROI).w,
            h: (roi as ROI).h,
          },
          currentUser.objectId
        ),
      }));

      currentRois = roiAdapter.addMany(createdRois, currentRois);

      findingsToDelete = getFindings(state)
        .filter((f) => originalIds.includes(f.analysisId))
        ?.map((f) => f.id);
    }

    const createdAnalysis = newAnalysis
      .map((an) => {
        const change = idChanges.find((change) => change.previous === an.id);
        return {
          ...an,
          id: change?.current,
          synced: true,
        };
      })
      .filter((a) => a.id);

    const analysisWithUpdatedIds = analysisAdapter.addMany(
      createdAnalysis,
      analysisAfterSaved
    );

    const updatedAnalysisMarkedAsSynced = getUnsyncedAnalysis(
      analysisWithUpdatedIds
    ).map((an) => ({ ...an, synced: true }));

    const roisToUpdate = Object.values(currentRois.entities ?? {}).filter(
      (roi) =>
        roi.labels.some((label) => newAnalysisIds.includes(label.analysisId))
    );

    const updatedRois = roisToUpdate.map((roi) => ({
      ...roi,
      labels: [
        ...roi.labels.filter(
          (label) => !newAnalysisIds.includes(label.analysisId)
        ),
        ...roi.labels
          .filter((label) => newAnalysisIds.includes(label.analysisId))
          .map((label) => ({
            ...label,
            analysisId:
              idChanges.find((change) => change.previous === label.analysisId)
                ?.current ??
              label.analysisId.substring(
                label.analysisId.indexOf("replace:") + 8
              ),
          })),
      ],
    }));

    return {
      ...state,
      analysis: analysisAdapter.addMany(
        updatedAnalysisMarkedAsSynced,
        analysisWithUpdatedIds
      ),
      rois: roiAdapter.upsertMany(updatedRois, currentRois),
      findings: findingAdapter.removeMany(findingsToDelete, state.findings),
      lastSavedTimestamp: Date.now(),
      error: null,
    };
  }),
  on(AnalysisActions.findingsSynced, (state, { idChanges, visibleRois }) => {
    const unsyncedFindings = getUnsyncedFindings(state.findings);

    const notSavedFindingIds = unsyncedFindings
      .filter((f) => !idChanges.find((change) => change.previous === f.id))
      .map((f) => f.id);

    const savedFindings = unsyncedFindings.filter((f) =>
      idChanges.find((change) => change.previous === f.id)
    );

    const savedFindingIds = savedFindings.map((f) => f.id);

    const findingsAfterDiscarding = findingAdapter.removeMany(
      notSavedFindingIds.concat(...savedFindingIds),
      state.findings
    );

    const createdFindings = savedFindings
      .map((f) => {
        const change = idChanges.find((change) => change.previous === f.id);
        return {
          ...f,
          id: change?.current,
          analysisId: change?.analysisId,
          data: change?.maskFileName ? change.maskFileName : f.data,
          synced: true,
          version: change.version,
        };
      })
      .filter((a) => a.id);

    const roisFromNotSavedFindings = getRois(state).filter((roi) =>
      roi.labels.some((label) => notSavedFindingIds.includes(label.findingId))
    );

    const visibleRoisIds = visibleRois.map((roi) => roi.id);
    const notVisibleRoisFromNotSavedFindings = getRois(state)
      .filter((roi) =>
        roi.labels.some((label) => savedFindingIds.includes(label.findingId))
      )
      .filter((roi) => !visibleRoisIds.includes(roi.id))
      .map((roi) => roi.id);

    const roisToDelete = roisFromNotSavedFindings
      .map((roi) => ({
        ...roi,
        labels: [
          ...roi.labels.filter(
            (label) => !notSavedFindingIds.includes(label.findingId)
          ),
        ],
      }))
      .filter((roi) => roi.labels.length < 1)
      .map((roi) => roi.id);

    const roisAfterDeletion = roiAdapter.removeMany(
      roisToDelete.concat(notVisibleRoisFromNotSavedFindings),
      state.rois
    );

    const roisToUpdate = getRois(state)
      .filter((roi) =>
        roi.labels.some((label) => savedFindingIds.includes(label.findingId))
      )
      .filter((roi) => visibleRoisIds.includes(roi.id));

    const updatedRois = roisToUpdate.map((roi) => ({
      ...roi,
      labels: [
        ...roi.labels.filter(
          (label) => !savedFindingIds.includes(label.findingId)
        ),
        ...roi.labels
          .filter((label) => savedFindingIds.includes(label.findingId))
          .map((label) => ({
            ...label,
            analysisId:
              idChanges.find((change) => change.previous === label.findingId)
                .analysisId ?? label.analysisId,
            findingId:
              idChanges.find((change) => change.previous === label.findingId)
                .current ?? label.findingId,
          })),
      ],
    }));

    return {
      ...state,
      findings: findingAdapter.addMany(
        createdFindings,
        findingsAfterDiscarding
      ),
      rois: roiAdapter.upsertMany(updatedRois, roisAfterDeletion),
      lastSavedTimestamp: Date.now(),
      error: null,
    };
  }),
  on(
    AnalysisActions.analysisDiscarded,
    (state, { analysisDiscardedIds, findingsDiscardedIds }) => {
      const roisIds = getRois(state)
        .filter((roi) =>
          getLabelAnalysisIds([roi]).every((analysisId) =>
            analysisDiscardedIds.includes(analysisId)
          )
        )
        .map((roi) => roi.id);

      return {
        ...state,
        analysis: analysisAdapter.removeMany(
          analysisDiscardedIds,
          state.analysis
        ),
        findings: findingAdapter.removeMany(
          findingsDiscardedIds,
          state.findings
        ),
        rois: roiAdapter.removeMany(roisIds, state.rois),
        lastEditedTimestamp: Date.now(),
      };
    }
  ),
  on(AnalysisActions.discardAnalysisChange, (state, { details }) => {
    const previousAnalysisVersion = findAnalysis(
      state,
      (a) => a.id === details.analysisId
    );
    const findingsToRemove = [];

    details.newFindingsData.forEach((finding) => {
      const findingToDelete = findFinding(
        state,
        (f) =>
          f.taskId === finding.taskId && f.analysisId === finding.analysisId
      );
      if (findingToDelete) findingsToRemove.push(findingToDelete);
    });

    const removedFindings = findingAdapter.removeMany(
      findingsToRemove.map((f) => f.id),
      state.findings
    );

    const previousVersions = details.updatedFindings.map((finding) =>
      findFinding(state, (f) => f.id === finding.id)
    );
    const findingsChanged = details.updatedFindings.filter((finding, i) => {
      if (typeof finding.data === "string") {
        return finding.data === previousVersions[i].data;
      }
      const hasChanged =
        JSON.stringify(finding.data.sort()) ===
        JSON.stringify((previousVersions[i].data as string[]).sort());
      return hasChanged;
    });

    return {
      ...state,
      analysis: analysisAdapter.updateOne(
        {
          id: details.analysisId,
          changes: { ...previousAnalysisVersion, synced: true },
        },
        state.analysis
      ),
      findings: findingAdapter.updateMany(
        findingsChanged.map((f) => ({
          id: f.id,
          changes: { ...f, synced: false },
        })),
        removedFindings
      ),
      lastEditedTimestamp: Date.now(),
    };
  }),
  on(AnalysisActions.discardRemoveROIs, (state, { rois }) => ({
    ...state,
    rois: rois ? { ...roiAdapter.addMany(rois, state?.rois) } : state.rois,
    lastEditedTimestamp: Date.now(),
  })),
  on(AnalysisActions.discardUpdateROI, (state, { rois }) => ({
    ...state,
    rois: rois ? { ...roiAdapter.upsertMany(rois, state?.rois) } : state.rois,
    lastEditedTimestamp: Date.now(),
  })),
  on(AnalysisActions.discardSetROIs, (state, { xois }) => {
    const currentROI = xois.map((xoi) => {
      return findROI(
        state,
        (r) =>
          r.x === xoi.x &&
          r.y === xoi.y &&
          r?.w === xoi?.w &&
          r?.h === xoi?.h &&
          r?.time === xoi?.time &&
          JSON.stringify(r.labels) === JSON.stringify(xoi.labels)
      );
    });

    return {
      ...state,
      rois: roiAdapter.removeMany(
        currentROI?.map((roi) => roi?.id),
        state.rois
      ),
      lastEditedTimestamp: Date.now(),
    };
  }),
  on(AnalysisActions.discardFindingsFromROIs, (state, { details, rois }) => {
    const findingsToDiscard = [];
    const findingsUpdated = [];
    const roisToDelete = [];

    (details || []).forEach((item) => {
      const finding = getFindings(state).find(
        (f) =>
          f?.analysisId === item.id &&
          JSON.stringify(f?.createdBy === item.createdBy) &&
          f?.taskId === item.taskId
      );
      if (!finding) return;

      rois.forEach((r) => {
        const roi = findROI(
          state,
          (roi) =>
            r.x === roi.x &&
            r.y === roi.y &&
            r?.w === roi?.w &&
            r?.h === roi?.h &&
            r?.time === roi?.time &&
            roi.labels.some(
              (label) =>
                label.analysisId === finding.analysisId &&
                label.findingId === finding.id
            )
        );
        if (!roi) return;
        roisToDelete.push(roi.id);
      });
      if (finding.id.includes("new:")) findingsToDiscard.push(finding);
      else {
        findingsUpdated.push(finding);
      }
    });

    return {
      ...state,
      findings: findingAdapter.removeMany(findingsToDiscard, state.findings),
      rois: roiAdapter.removeMany(roisToDelete, state.rois),
      lastEditedTimestamp: Date.now(),
    };
  }),
  on(AnalysisActions.discardSync, (state, { mosaicMode }) => {
    if (mosaicMode) {
      const findingsToDeleteIds =
        getUnsyncedFindings(state.findings).map((f) => f.id) ?? [];
      const roisIdsToDelete =
        getRois(state)
          .filter((roi) =>
            roi.labels.some((label) =>
              findingsToDeleteIds.includes(label.findingId)
            )
          )
          .map((f) => f.id) ?? [];

      return {
        ...state,
        analysis: analysisAdapter.removeMany(
          getUnsyncedAnalysis(state.analysis).map((a) => a.id) ?? [],
          state.analysis
        ),
        findings: findingAdapter.removeMany(
          findingsToDeleteIds,
          state.findings
        ),
        rois: roiAdapter.removeMany(roisIdsToDelete, state.rois),
        lastEditedTimestamp: state.lastSavedTimestamp,
      };
    }

    return {
      ...state,
      analysis: analysisAdapter.updateMany(
        getUnsyncedAnalysis(state.analysis).map((a) => ({
          id: a.id,
          changes: { synced: true },
        })) ?? [],
        state.analysis
      ),
      findings: findingAdapter.updateMany(
        getUnsyncedFindings(state.findings).map((f) => ({
          id: f.id,
          changes: { synced: true },
        })) ?? [],
        state.findings
      ),
      lastEditedTimestamp: state.lastSavedTimestamp,
    };
  }),
  on(AnalysisActions.findingsUnsynced, (state, { finding }) => {
    const analysis = findAnalysis(state, (an) => an.id === finding.analysisId);
    return {
      ...state,
      analysis: analysisAdapter.updateOne(
        { id: analysis.id, changes: { ...analysis, synced: false } },
        state.analysis
      ),
      findings: findingAdapter.updateOne(
        { id: finding.id, changes: { ...finding, synced: false } },
        state.findings
      ),
      lastEditedTimestamp: Date.now(),
    };
  }),
  on(
    AnalysisActions.segmAnalysisCreated,
    (state, { assetId, sampleId, createdBy, pipelineId, taskId }) => {
      const newAnalysis = {
        assetId,
        sampleId,
        createdBy,
        id: `new:${generateId(ID_LENGTH)}`,
        synced: false,
        data: {},
        isSampleAnalysis: false,
        pipelineId,
        fetchedPartially: false,
      };
      const newFinding = {
        id: `new:${generateId(ID_LENGTH)}`,
        analysisId: newAnalysis.id,
        assetId: newAnalysis.assetId,
        createdBy: newAnalysis.createdBy,
        data: undefined,
        uuid: v4(),
        synced: false,
        version: 1,
        taskId: taskId,
      };

      return {
        ...state,
        analysis: analysisAdapter.addOne(newAnalysis, state.analysis),
        findings: findingAdapter.addOne(newFinding, state.findings),
        lastEditedTimestamp: Date.now(),
      };
    }
  ),
  on(AnalysisActions.loadingMask, (state, { loading }) => ({
    ...state,
    maskLoading: loading,
  })),
  on(AnalysisActions.updateLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(AnalysisActions.deleteUnlabeledRois, (state, { unlabeledRois }) => {
    const unlabeledRoisToDelete = unlabeledRois
      .filter((roi) =>
        roi.labels.every((l) => Object.keys(l.labels).length === 0)
      )
      .map((roi) => roi.id);

    const unlabeledRoisToUpdate = unlabeledRois
      .filter((roi) => !unlabeledRoisToDelete.includes(roi.id))
      .map((roi) => ({
        ...roi,
        labels: roi.labels.filter((l) => Object.keys(l.labels).length !== 0),
      }));

    const roisAfterRemoval = roiAdapter.removeMany(
      unlabeledRoisToDelete,
      state.rois
    );

    return {
      ...state,
      rois: roiAdapter.upsertMany(unlabeledRoisToUpdate, roisAfterRemoval),
    };
  }),
  on(AnalysisActions.updateAssetsProcessed, (state, { assetsProcessed }) => {
    return {
      ...state,
      assetsAnalysisFetched: Array.from(
        new Set([...state.assetsAnalysisFetched, ...assetsProcessed])
      ),
    };
  }),
  on(AnalysisActions.setMosaicLoading, (state, { loading }) => {
    return {
      ...state,
      mosaicsLoading: loading,
    };
  }),
  on(AnalysisActions.loadMosaic, (state) => {
    return {
      ...state,
      mosaicsLoading: true,
    };
  }),
  on(AnalysisActions.loadMore, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(AnalysisActions.clearData, (state) => {
    return {
      ...state,
      rois: initialAnalysisState.rois,
      analysis: initialAnalysisState.analysis,
      findings: initialAnalysisState.findings,
      assetsAnalysisFetched: initialAnalysisState.assetsAnalysisFetched,
    };
  }),
  on(AnalysisActions.deleteObjects, (state, { findingUuids }) => {
    const findingsToDelete = getFindings(state).filter((f) =>
      findingUuids.includes(f.uuid)
    );
    const findingsToDeleteIds = findingsToDelete.map((f) => f.id);
    const analysisToDelete = findingsToDelete.map((f) => f.analysisId);
    const roisToDelete = getRois(state)
      .filter((roi) =>
        roi.labels.every((l) => findingsToDeleteIds.includes(l.findingId))
      )
      .map((roi) => roi.id);

    const roisUpdateChanges = getRois(state)
      .filter(
        (roi) =>
          roi.labels.some((l) => findingsToDeleteIds.includes(l.findingId)) &&
          !roi.labels.every((l) => findingsToDeleteIds.includes(l.findingId))
      )
      .map((roi) => ({
        ...roi,
        labels: [
          ...roi.labels.filter((l) =>
            findingsToDeleteIds.includes(l.findingId)
          ),
        ],
      }))
      .map((roi) => ({
        id: roi.id,
        changes: { labels: roi.labels },
      }));

    const roisUpdated = roiAdapter.updateMany(roisUpdateChanges, state.rois);

    return {
      ...state,
      rois: roiAdapter.removeMany(roisToDelete, roisUpdated),
      analysis: analysisAdapter.removeMany(analysisToDelete, state.analysis),
      findings: findingAdapter.removeMany(findingsToDeleteIds, state.findings),
    };
  }),
  on(AnalysisActions.findingsSyncedSaved, (state) => {
    return {
      ...state,
      syncing: false,
    };
  }),
  on(AnalysisActions.mosaicCropsLoaded, (state, { roiChanges }) => {
    return {
      ...state,
      loading: false,
      rois: roiAdapter.updateMany(roiChanges, state.rois),
    };
  })
);
