import { Entity, SoftDeletable } from "./entity";

export interface WorkspaceItem {
  id: string;
  name: string;
}

export interface WorkspaceAttributes extends Entity, SoftDeletable {
  name: string;
  organizationId: string;
  caseTypeId: string;
  createdById: string;
  config: any;
  numCases: number;
  numMembers: number;
  usedStorage: number;
}

export enum RegulatoryLabel {
  RUO = "RUO",
  CE_IVD = "CE IVD",
  GP = "GP",
}

export class Workspace {
  constructor(private attributes: WorkspaceAttributes) { }

  public get id(): string {
    return this.attributes.id;
  }

  public get name(): string {
    return this.attributes.name;
  }

  public get organizationId(): string {
    return this.attributes.organizationId;
  }

  public get caseTypeId(): string {
    return this.attributes.caseTypeId;
  }

  public get createdById(): string {
    return this.attributes.createdById;
  }

  public get config(): any {
    return this.attributes.config ?? {};
  }

  public get deletedAt(): Date | undefined {
    return this.attributes.deletedAt;
  }

  public get updatedAt(): Date | undefined {
    return this.attributes.updatedAt;
  }

  public get createdAt(): Date | undefined {
    return this.attributes.createdAt;
  }

  /**
   * @deprecated to be removed, use count to fetch this property
   */
  public get numCases() {
    return this.attributes.numCases ?? 0;
  }

  /**
   * @deprecated to be removed, use count to fetch this property
   */
  public get numMembers() {
    return this.attributes.numMembers ?? 0;
  }

  public get usedStorage(): number {
    return this.attributes.usedStorage ?? 0;
  }

  /**
   * @deprecated used to change numMembers in after save events
   */
  public addMember() {
    this.attributes.numMembers++;
  }

  /**
   * @deprecated used to change numMembers in after save events
   */
  public removeMember() {
    this.attributes.numMembers--;
  }

  /**
   * @deprecated used to change numCases in after save events
   */
  public removeCase() {
    this.attributes.numCases--;
  }

  /**
   * @deprecated used to change numCases in after delete events
   */
  public addCase() {
    this.attributes.numCases++;
  }

}
