import { Entity, SoftDeletable } from "./entity";

export interface DeviceTypeAttributes extends Entity, SoftDeletable {
  name: string;
}

export class DeviceType implements Entity, SoftDeletable {
  constructor(private attributes: DeviceTypeAttributes) { }

  public get id(): string {
    return this.attributes.id;
  }

  public get name(): string {
    return this.attributes.name;
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }

  public get deletedAt(): Date {
    return this.attributes.deletedAt;
  }
}
