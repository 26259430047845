import { PipelineTask } from "@telespot/sdk";

export enum PipelineExceptions {
  INVALID_PIPELINE = "Invalid pipeline id",
  INSUFFICIENT_STEPS = "Pipeline steps not provided",
}

export interface PipelineAttributes {
  id: string;
  name: { [key: string]: string };
  tasks: Array<PipelineTask>;
  env: "cloud" | "edge";
}

export class PipelineEntity {
  constructor(private attributes: PipelineAttributes) {
    if (!attributes) {
      return;
    }
  }

  public get id(): string {
    return this.attributes.id;
  }

  set name(value: { [key: string]: string }) {
    this.attributes.name = value;
  }
  get name(): { [key: string]: string } {
    return this.attributes.name ?? {};
  }

  public get tasks(): Array<PipelineTask> {
    return this.attributes.tasks;
  }

  public set tasks(tasks: Array<PipelineTask>) {
    this.attributes.tasks = tasks;
  }

  public get env(): "cloud" | "edge" {
    return this.attributes.env;
  }

  public set env(value: "cloud" | "edge") {
    this.attributes.env = value;
  }

  removeTasks(tasksToRemove: PipelineTask[]) {
    const tasks = this.tasks.filter(
      (f) => !tasksToRemove.some((t) => f.name === t.name)
    );
    this.tasks = tasks;
  }

  addTasks(tasksToAdd: PipelineTask[]) {
    const tasks = this.tasks.slice();
    let hasDuplicates = false;

    tasksToAdd.forEach((task) => {
      const index = tasks.findIndex((t) => t.name === task.name);
      if (index === -1) {
        if (tasks.find((f: PipelineTask) => f.name === task.name)) {
          hasDuplicates = true;
          return;
        }
        tasks.push(task);
      } else {
        tasks[index] = task;
      }
    });

    if (hasDuplicates) {
      throw new Error(`One or more tasks have duplicated identifiers`);
    }

    this.tasks = tasks;
  }
}
