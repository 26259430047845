import { TileSourceOptions } from "openseadragon";

import { IAssetMask } from "./asset-mask";

export interface IViewerConfig {
  width?: number;
  height?: number;
  maxNativeZoom?: number;
  tileSize?: number;
}

export interface IAssetInfo {
  width: number;
  height: number;
  tileSize: number;
  name: string;
  maxNativeZoom: number;
  ext: string;
}

export interface IAssetInfoWithOverlays {
  tilesUrl: string;
  tilesData: IAssetInfo;
  overlaysData: IAssetMask[];
}

export class ViewerConfig {
  width: number;
  height: number;
  extension: string;
  name: string;
  maxNativeZoom: number;
  minZoom: number;
  maxZoom: number;
  tileSize: number;

  gridXnum: number;
  gridYnum: number;

  tileUrl: string;

  overlays: IAssetMask[];

  // TODO: refactor
  constructor(data?: {
    tileUrl: string;
    name: string;
    width: number;
    height: number;
    tileSize: number;
    maxNativeZoom: number;
    extension?: string;
    overlays?: IAssetMask[];
  }) {
    this.tileUrl = data?.tileUrl;
    this.width = data?.width;
    this.height = data?.height;
    this.tileSize = data?.tileSize;
    this.extension = data?.extension ?? ".png";
    this.maxNativeZoom = data?.maxNativeZoom;
    this.overlays = data?.overlays;
    this.name = "";
    this.minZoom = 0;
    this.maxZoom = 5.5;
  }

  static FromAsset(asset: IAssetInfoWithOverlays): ViewerConfig {
    return new ViewerConfig({
      overlays: asset.overlaysData,
      tileUrl: asset.tilesUrl,
      extension: `.${asset.tilesData.ext}`,
      ...asset.tilesData,
    }).configureGrid();
  }

  configureGrid(): ViewerConfig {
    this.gridXnum = Math.ceil(Math.sqrt(1));
    this.gridYnum = Math.ceil(1 / this.gridXnum);
    return this;
  }

  getTileUrlGrid(zoom?: number, x?: number, y?: number): string {
    const suffix: string =
      zoom !== undefined
        ? "/" +
          zoom.toString() +
          "/" +
          y.toString() +
          "/" +
          x.toString() +
          this.extension
        : "/{z}/{y}/{x}" + this.extension;
    return `${this.tileUrl}${suffix}`;
  }

  toOSDTileConfig(): TileSourceOptions {
    return {
      width: this.width,
      height: this.height,
      tileSize: this.tileSize,
      tileWidth: this.tileSize,
      tileHeight: this.tileSize,
      minLevel: 1,
      maxLevel: this.maxNativeZoom,
      getTileUrl: (l, x, y) => this.getTileUrlGrid(l - 1, x, y),
    };
  }
}

export enum ImageFilterType {
  CONTRAST = "contrast",
  SATURATION = "saturation",
  BRIGHTNESS = "brightness",
  NEGATIVE = "negative",
}

export interface ImageFilter {
  filter: ImageFilterType;
  value: number;
}
