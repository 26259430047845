import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  Algorithms,
  Analysis,
  AnalysisTask,
  AnalysisTypeUtils,
  IAssetROI,
  IAssetROIWithModels,
  RoiModel,
  Sample,
  User,
} from "@telespot/sdk";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  acceptAIROIs,
  analysisState,
  clearROIState,
  registerModels,
  selectModels,
  updateSampleAnalysisState,
} from "../../+state";

import { RoiModelUtils } from "../../models/roi-model-utils";
import {
  ROI,
  selectActiveROIS,
  setSelectedROIs,
  setROIs,
  removeROIs,
  updateROI,
  POI,
  Label,
  selectSelectedROIS,
  activeAnalysisIds,
  selectedLabelsWithAnalysisReq,
  AnalysisLabel,
  AnalysisRequest,
  createAnalysisFromROIs,
  selectROISelectionTasks,
  selectVisibleROIS,
  selectROIsFromRegion,
  totalCount,
  labelCount,
  loadStats,
} from "../../state";

@Injectable({
  providedIn: "root",
})
export class RoiService implements OnDestroy {
  private _destroy$ = new Subject<void>();
  private analysisLabelsAndRequest: {
    analysisLabels: AnalysisLabel[];
    selectedLabels: Label[];
    analysesRequest: AnalysisRequest[];
  };
  private ROIActiveTasks: AnalysisTask[];
  private analysisTypeUtils = new AnalysisTypeUtils();
  private selectedRois: (ROI | POI)[];
  private activeAnalysisIds: string[];

  private _models: RoiModel[] = [];
  private _defaultROISize = 100;
  private _selectModelEvent$ = new Subject<void>();

  constructor(private _store: Store<{ rois: any }>) {
    this._store
      .select(selectROISelectionTasks)
      .pipe(takeUntil(this._destroy$))
      .subscribe((tasks) => (this.ROIActiveTasks = tasks));
    this._store
      .select(selectSelectedROIS)
      .pipe(takeUntil(this._destroy$))
      .subscribe((rois) => (this.selectedRois = rois));
    this._store
      .select(activeAnalysisIds)
      .pipe(takeUntil(this._destroy$))
      .subscribe((ids) => (this.activeAnalysisIds = ids));
    this._store
      .select(selectedLabelsWithAnalysisReq)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (analysisLabelsAndReq) =>
          (this.analysisLabelsAndRequest = analysisLabelsAndReq)
      );
  }

  public readonly selectedModelsEvent$ = this._selectModelEvent$.asObservable();

  /**
   * ROIs for active asset
   *
   * @memberof RoiService
   */
  public readonly assetROIs$ = this._store.select(selectActiveROIS);
  public readonly visibleROIs$ = this._store.select(selectVisibleROIS);
  public readonly totalCount$ = this._store.select(totalCount);

  public readonly selectedRois$ = this._store.select(selectSelectedROIS);

  public readonly loadedStats$ = this._store.select(labelCount);
  public readonly analysisState$ = this._store.select(analysisState);

  // METHODS

  ngOnDestroy() {
    this._destroy$.next();
  }

  registerModels(
    analysis: Analysis,
    models: RoiModel[],
    replace: boolean = false
  ) {
    if (analysis) {
      const modelwithRois = [...models];
      models = analysis.registerModels(modelwithRois);
    }
    this._store.dispatch(registerModels({ models, replace }));
  }

  selectModels(models: RoiModel | RoiModel[], replace: boolean = false) {
    const modelsArray =
      models instanceof Array ? models : [models].filter((m) => !!m);
    if (modelsArray.length) {
      this._selectModelEvent$.next();
    }
    this._store.dispatch(selectModels({ models: modelsArray, replace }));
  }

  clearModels() {
    this._store.dispatch(clearROIState());
  }

  addROIs(rois: IAssetROI[], models?: RoiModel[]) {
    const newAnalysis =
      this.analysisLabelsAndRequest.analysesRequest.length !== 0;
    if (
      this.analysisLabelsAndRequest.analysisLabels.length < 1 &&
      this.analysisLabelsAndRequest.selectedLabels.length < 1
    )
      return;

    if (newAnalysis) {
      this._store.dispatch(
        createAnalysisFromROIs({
          analysesRequest: this.analysisLabelsAndRequest.analysesRequest,
          selectedLabels: this.analysisLabelsAndRequest.selectedLabels,
          rois,
        })
      );
    } else {
      const roisToAdd = rois.map((roi) => ({
        x: roi.x,
        y: roi.y,
        w: roi?.w,
        h: roi?.h,
        time: roi?.time,
        labels: this.analysisLabelsAndRequest.analysisLabels,
      }));
      this._store.dispatch(setROIs({ rois: roisToAdd }));
    }
  }

  removeSelectedROIs() {
    this._store.dispatch(removeROIs({ selectedROIs: this.selectedRois }));
  }

  removeROIs(rois: ROI[]) {
    this._store.dispatch(removeROIs({ rois: rois || [] }));
  }

  selectROIs(rois: ROI | ROI[], replace: boolean = false): ROI[] {
    const newRois: ROI[] = rois ? (rois instanceof Array ? rois : [rois]) : [];
    this._store.dispatch(setSelectedROIs({ rois: newRois, replace }));
    return newRois; // TODO WHY
  }

  selectROIsInsideRegion(bounds: IAssetROI) {
    this._store.dispatch(
      selectROIsFromRegion({
        bounds,
        activeAnalysisIds: this.activeAnalysisIds,
      })
    );
  }

  public updateROIposition({
    roi,
    position,
  }: {
    roi: ROI;
    position: Partial<ROI>;
  }): void {
    this._store.dispatch(updateROI({ roi, changes: position }));
  }

  public updateROItime({ roi, time }: { roi: ROI; time: number }): void {
    this._store.dispatch(updateROI({ roi, changes: { time } }));
  }

  public acceptAIROIs(rois: IAssetROIWithModels[]) {
    this._store.dispatch(acceptAIROIs({ rois }));
  }

  public loadStats(
    sample: Sample,
    createdBy: User | Algorithms,
    analysisStateId: string
  ) {
    this._store.dispatch(loadStats({ sample, createdBy, analysisStateId }));
  }

  public updateAnalysisState(historyId: string, sample: string) {
    this._store.dispatch(updateSampleAnalysisState({ historyId, sample }));
  }

  /**
   * Retrieves the assigned color for the specified model(s)
   *
   * @param {(SelectedLabels)} labels
   * @param {boolean} [opaque=false]
   * @returns
   * @memberof RoiService
   */

  getModelColor(labels: string[], opaque: boolean = false) {
    return this.analysisTypeUtils.getLabelColor(
      labels,
      opaque,
      this.ROIActiveTasks
    );
  }

  getModelDisplayName(labels: string[]) {
    return this.analysisTypeUtils.getModelDisplayName(
      labels,
      this.ROIActiveTasks
    );
  }

  getLabelName(labels: string[]) {
    return labels
      .map((label) => label.substring(label.indexOf("value:") + 6))
      .join(",");
  }

  getModelIndex(model: RoiModel): number {
    return this._models?.findIndex((rm) => RoiModelUtils.equalTo(rm, model));
  }

  public updateRoiSize(newSize: number) {
    this._defaultROISize = newSize;
  }

  public get defaultROISize(): number {
    return this._defaultROISize;
  }

  public getLabelCategoryAndValue(label: string) {
    const labelCategory = label.substring(
      label.indexOf("category:") + 9,
      label.indexOf("/")
    );
    const labelValue = label.substring(label.indexOf("value:") + 6);
    return { labelCategory, labelValue };
  }
}
