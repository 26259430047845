import { Object as ParseObject } from "parse";
import { Algorithms } from "../algorithms/algorithms.model";
import { AnalysisType } from "../analysis-type/analysis-type.model";
import { MethodType } from "../method-type/method-type.model";
import { Pipeline } from "../pipeline/pipeline.model";

/**
 * Represents AI algorithms to protocols (Methodtype, AnalysisType) mapping.
 *
 * @export
 * @class ProtocolAlgorithms
 * @extends {ParseObject}
 */

export enum ProtocolAlgorithmFields {
  TABLE = "ProtocolAlgorithms",
  MODEL = "model",
  ANALYSIS_TYPE = "analysisType",
  METHOD_TYPE = "methodType",
  PIPELINE = "pipeline",
}
export interface AnalysisTypeTask {
  taskName: string;
  optionName: string;
}

export class ProtocolAlgorithms extends ParseObject {
  static className = "ProtocolAlgorithms";

  constructor() {
    super(ProtocolAlgorithms.className);
  }

  get model(): Algorithms {
    return this.get(ProtocolAlgorithmFields.MODEL);
  }

  set model(algorithm: Algorithms) {
    this.set(ProtocolAlgorithmFields.MODEL, algorithm);
  }

  get methodType(): MethodType {
    return this.get(ProtocolAlgorithmFields.METHOD_TYPE);
  }

  set methodType(methodType: MethodType) {
    this.set(ProtocolAlgorithmFields.METHOD_TYPE, methodType);
  }

  get analysisType(): AnalysisType | undefined {
    return this.get(ProtocolAlgorithmFields.ANALYSIS_TYPE);
  }

  set analysisType(analysisType: AnalysisType) {
    this.set(ProtocolAlgorithmFields.ANALYSIS_TYPE, analysisType);
  }

  get pipeline(): Pipeline {
    return this.get(ProtocolAlgorithmFields.PIPELINE);
  }

  set pipeline(pipeline: Pipeline) {
    this.set(ProtocolAlgorithmFields.PIPELINE, pipeline);
  }
}
