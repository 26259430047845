import { Algorithms } from "../algorithms/algorithms.model";
import { StepAction, StepTask } from "./task-type";

export interface TPipelineTask {
  id?: string;
  name: TaskOption;
  type?: StepTask;
  options?: TaskOption[];
  allowNewOptions?: boolean;
  multiple?: boolean;
  counter?: boolean;
  trigger: StepAction;
  generateFinding: StepAction;
  executor?: Algorithms;
}

export interface TaskOption {
  name: string;
  uuid: string;
  color?: string;
  new?: boolean;
  configId?: string;
  thrdis?: number;
  alpha?: number;
  value: { [key: string]: string };
}

export enum PipelineEnvironment {
  CLOUD = "cloud",
  EDGE = "edge",
}

export class PipelineTask implements TPipelineTask {
  id?: string;
  name: TaskOption;
  type?: StepTask;
  options?: TaskOption[];
  allowNewOptions?: boolean;
  enableMosaicView?: boolean;
  multiple?: boolean;
  counter?: boolean;
  required?: boolean;
  executor?: Algorithms;
  trigger: StepAction;
  generateFinding: StepAction;

  constructor(data: TPipelineTask) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
    this.multiple = data.multiple;
    this.counter = data.counter;
    this.options = data.options;
    this.executor = data.executor;
    this.trigger = data.trigger;
    this.generateFinding = data.generateFinding;
  }
}
