import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input, OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'ts-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent implements OnChanges, OnDestroy {

  @Input() public placeholderTemplate: TemplateRef<unknown>;
  @Input() public url: string;

  public loading = true;
  public safeUrl: SafeUrl;

  private image: HTMLImageElement;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  public ngOnDestroy(): void {
    if (this.image) this.image.onload = null;
  }

  public ngOnChanges({ url }: SimpleChanges): void {
    if (!url.currentValue || url.currentValue === url.previousValue) return;

    this.loadImage(url.currentValue);
  }

  private renderImage() {
    this.loading = false;
    this.cdr.markForCheck();
  }

  private loadImage(url: string) {
    if (!this.image) this.image = new Image();

    this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);

    this.image.src = url;

    if (this.image.complete) {
      this.renderImage();
    } else {
      this.loading = true;
      this.image.onload = () => this.renderImage();
    }
  }
}
