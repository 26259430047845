import { Object as ParseObject } from "parse";
import { StepTask, StepAction } from "../data/task-type";
import { Pipeline } from "../pipeline/pipeline.model";
import { Algorithms } from "../algorithms/algorithms.model";

export enum PipelineStepFields {
  TABLE = "PipelineStep",
  TASK = "task",
  PARAMS = "params",
  ENV = "env",
  ORDER = "order",
  GENERATEFINDING = "generateFinding",
  EXECUTOR = "executor",
  TRIGGER = "trigger",
  PIPELINE = "pipeline",
}

export class PipelineStep extends ParseObject {
  static className = PipelineStepFields.TABLE;

  constructor() {
    super(PipelineStep.className);
  }

  get task(): StepTask {
    return this.get(PipelineStepFields.TASK);
  }

  set task(value: StepTask) {
    this.set(PipelineStepFields.TASK, value);
  }

  get params(): any {
    return this.get(PipelineStepFields.PARAMS);
  }

  set params(value: any) {
    this.set(PipelineStepFields.PARAMS, value);
  }

  get generateFinding(): StepAction {
    return this.get(PipelineStepFields.GENERATEFINDING);
  }

  set generateFinding(value: StepAction) {
    this.set(PipelineStepFields.GENERATEFINDING, value);
  }

  get trigger(): StepAction {
    return this.get(PipelineStepFields.TRIGGER);
  }

  set trigger(value: StepAction) {
    this.set(PipelineStepFields.TRIGGER, value);
  }

  get order(): number {
    return this.get(PipelineStepFields.ORDER);
  }

  set order(value: number) {
    this.set(PipelineStepFields.ORDER, value);
  }

  get pipeline(): Pipeline {
    return this.get(PipelineStepFields.PIPELINE);
  }

  set pipeline(value: Pipeline) {
    this.set(PipelineStepFields.PIPELINE, value);
  }

  public get executor(): Algorithms {
    return this.get(PipelineStepFields.EXECUTOR);
  }

  set executor(value: Algorithms) {
    this.set(PipelineStepFields.EXECUTOR, value);
  }

  changeExecutor(value: Algorithms) {
    if (!value) {
      this.removeExecutor();
      return;
    }
    this.set(PipelineStepFields.EXECUTOR, value);
  }

  removeExecutor() {
    this.unset(PipelineStepFields.EXECUTOR);
  }
}
