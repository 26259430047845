import { Object as ParseObject } from 'parse';

import { Organization } from '../organization/organization.model';
import { User } from '../user/user.model';

/**
 * Represents the relation of pertenece between a Telespot user and an Organization
 *
 * @export
 * @class Affiliate
 * @extends {ParseObject}
 */
export class Affiliate extends ParseObject {
  static className = 'Affiliate';

  /**
   * Creates an instance of Affiliate.
   * @memberof Affiliate
   */
  constructor(user: User, organization: Organization) {
    super(Affiliate.className);
    this.set('user', user);
    this.set('organization', organization);
  }

  /**
   *  Pointer to the user
   *
   * @type {User}
   * @memberof Affiliate
   */
  get user(): User {
    return this.get('user');
  }

  /**
   * Pointer to the {Organization}
   *
   * @type {Organization}
   * @memberof Affiliate
   */
  get organization(): Organization {
    return this.get('organization');
  }
}
