<div class="card">
  <form [formGroup]="methodForm" #form="ngForm" *ngIf="methodForm">
    <div
      class="card-header flex-row justify-content-between align-items-center"
    >
      <h3>{{ "title.method_editor" | translate | uppercase }}</h3>
      <span class="button-bar">
        <button
          class="btn btn-outline-primary"
          (click)="cancel()"
          data-cy="button-cancel-method-type"
          [routerLink]="redirectUrl"
        >
          <i class="ri-close-fill"></i>
          {{ "button.cancel" | translate | uppercase }}
        </button>
        <button
          class="btn btn-outline-primary"
          (click)="submit()"
          [disabled]="methodForm.invalid || !form.dirty"
          data-cy="button-save-method-type"
          [routerLink]="redirectUrl"
        >
          <i class="ri-save-line"></i>
          {{ "button.save" | translate | uppercase }}
        </button>
      </span>
    </div>

    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <mat-form-field class="full-width">
          <mat-label for="type">{{ "Name" | translate }}</mat-label>
          <input
            matInput
            autofocus
            placeholder=""
            required
            name="name"
            formControlName="name"
            id="name"
          />
        </mat-form-field>
      </li>
      <li class="list-group-item">
        <div style="width: 100%">
          <h4>{{ "title.acquisition" | translate | uppercase }}</h4>
          <div>
            <mat-form-field class="full-width">
              <mat-label for="assetType">{{
                "core.assettype" | translate
              }}</mat-label>
              <mat-select
                class="full-width"
                placeholder="{{ 'core.assettype' | translate }}"
                [compareWith]="sameId"
                formControlName="assetType"
                name="assetType"
                (selectionChange)="evaluateAvailableDevices()"
              >
                <mat-option
                  *ngFor="let assetType of availableAssetTypes$ | async"
                  [value]="assetType"
                >
                  {{ assetType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <h5>{{ "label.asset_type.device_types" | translate }}:</h5>
            <mat-chip-list>
              <mat-chip
                style="margin-left: 4px"
                *ngFor="let deviceType of availableDevices$ | async"
                matTooltip="Your organization owns {{
                  deviceType.devices.length
                }} compatible devices"
                [color]="deviceType.devices.length > 0 ? 'primary' : undefined"
                >{{ deviceType.deviceType.name }}
                {{ deviceType.deviceType.version }} ({{
                  deviceType.devices.length
                }})</mat-chip
              >
            </mat-chip-list>
            <mat-form-field
              id="defaultNames"
              class="full-width"
              style="margin-right: 4px; flex-grow: 1; margin-top: 10px"
            >
              <mat-label for="defaultNames">
                {{ "Predefined sample names" | translate }}
              </mat-label>
              <mat-chip-list #chipList style="width: 100%">
                <mat-chip
                  *ngFor="let defaultSampleName of defaultSamples"
                  [selectable]="true"
                  (click)="editDefaultSampleName(defaultSampleName)"
                  [removable]="true"
                  (removed)="removeDefaultSampleName(defaultSampleName)"
                >
                  {{
                    (defaultSampleName?.name)["en"] ||
                      (defaultSampleName?.name)["es"]
                  }}
                  <mat-icon
                    matChipRemove
                    fontSet="remixicon"
                    fontIcon="ri-close-line"
                  ></mat-icon>
                </mat-chip>
                <input
                  data-cy="predefined-samples"
                  [placeholder]="'help.default_sample_names' | translate"
                  style="width: 100%"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="addDefaultSampleName($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class="full-width">
          <h4>
            {{ "core.resources" | translate | uppercase }}
          </h4>
          <ts-resources-assignment
            [resources]="methodForm?.controls.resources.value"
            (resourcesChanged)="updateResources($event)"
          ></ts-resources-assignment>
        </div>
      </li>

      <li class="list-group-item">
        <ts-asset-fields
          class="full-width"
          [assetFields]="methodForm?.controls.assetFields.value"
          [resources]="methodForm?.controls.resources.value"
          (saved)="updateAssetFields($event)"
        ></ts-asset-fields>
      </li>

      <li class="list-group-item">
        <div class="full-width">
          <h4>
            {{ "core.analysis" | translate | uppercase }}
          </h4>
          <ts-analysis-types-assignment
            [analysisTypes]="methodForm?.controls.analysisTypes.value"
            (analysisTypesChanged)="updateAnalysisTypes($event)"
          >
          </ts-analysis-types-assignment>
        </div>
      </li>

      <li class="list-group-item">
        <div class="full-width">
          <h4>
            {{ "pipelines" | translate | uppercase }}
          </h4>
          <ts-pipelines-assignment
            [pipelines]="getPipelinesFromProtocol()"
            [methodType]="method"
            (pipelinesChanged)="updatePipelines($event)"
          >
          </ts-pipelines-assignment>
        </div>
      </li>
    </ul>
  </form>
</div>
