import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CASES_FEATURE_KEY, State } from "./cases.reducer";
import { CaseType, Workspace } from "@telespot/sdk";
import { TableFilter } from "@shared/ui";

export const findDecryptedCase = (state: State, id) =>
  state.decryptedCases.find((dc) => dc.objectId === id);
export const identifierNotEncrypted = (state: State) =>
  !(CaseType.fromJSON(state.currWorkspace.caseType) as CaseType)
    .hasEncryptedIdentifier;
export const getCurrentWorkspace = (state: State) =>
  state.currWorkspace
    ? (Workspace.fromJSON({
        ...state.currWorkspace,
        className: "Workspace",
      }) as Workspace)
    : undefined;

export const getCasesState = createFeatureSelector<State>(CASES_FEATURE_KEY);

export const selectdecrypted = createSelector(getCasesState, (state: State) => {
  return state?.decrypted;
});

export const selectDecryptedCases = createSelector(
  getCasesState,
  (state: State) => {
    return state?.decryptedCases;
  }
);

export const selectUndecryptedCases = createSelector(
  getCasesState,
  (state: State) => {
    return state?.cases.filter(
      (c) => !state?.decryptedCases.find((dc) => dc.objectId === c.objectId)
    );
  }
);

export const selectCases = createSelector(getCasesState, (state: State) => {
  return state?.decrypted ? state?.decryptedCases : state.cases;
});

export const currDecryptedCase = (id: string) =>
  createSelector(getCasesState, (state: State) => {
    return state?.decryptedCases.find((c) => c.objectId === id);
  });

export const getCurrentCase = (id: string) =>
  createSelector(getCasesState, (state: State) => {
    return state?.decrypted
      ? state?.decryptedCases.find((c) => c.objectId === id)
      : state?.cases.find((c) => c.objectId === id);
  });

export const getCurrentCaseTypeFields = (id: string) =>
  createSelector(getCasesState, (state: State) => {
    return state?.cases.find((c) => c.objectId === id)?.caseType.fields;
  });

export const selectWSEncrypted = createSelector(
  getCasesState,
  (state: State) => {
    return state?.currWorkspace?.caseType.fields.some(
      (field) => field.encrypted
    );
  }
);

export const selectIsCaseEncrypted = createSelector(
  getCasesState,
  (state: State) => {
    return state?.currWorkspace?.caseType.fields.some(
      (field) => field.type === "case_name" && field.encrypted
    );
  }
);

export const selectCurrentWorkspace = createSelector(
  getCasesState,
  getCurrentWorkspace
);

export const selectNumCases = createSelector(getCasesState, (state: State) => {
  return state?.currWorkspace?.numCases;
});

export const selectCaseError = createSelector(getCasesState, (state: State) => {
  return state?.error;
});

export const selectCurrCaseAndWorkspace = (id: string) =>
  createSelector(
    selectCurrentWorkspace,
    getCurrentCase(id),
    (workspace, _case) => ({ workspace, _case })
  );

export const selectTotalCaseCount = createSelector(
  getCasesState,
  (state: State) => state?.totalCases
);
export const selectTotalWorkspaceCases = createSelector(
  getCasesState,
  (state: State) => state?.totalWorkspaceCases
);

export const selectCaseList = createSelector(getCasesState, (state) => {
  const tpmvalue = state.caseList.map((item) => ({
    ...item,
    case: {
      ...item.case,
      name:
        identifierNotEncrypted(state) ||
        state.currentUserId === item.case.createdBy?.objectId
          ? item.case.name
          : state.decrypted
          ? findDecryptedCase(state, item.case.objectId)?.name ||
            item.case.objectId
          : item.case.objectId,
    },
  }));

  return tpmvalue;
});

export const selectMovingCase = createSelector(
  getCasesState,
  (state: State) => state?.movingCases
);
export const selectCopyingCase = createSelector(
  getCasesState,
  (state: State) => state?.copyingCase
);
export const selectDeletingCase = createSelector(
  getCasesState,
  (state: State) => state?.deletingCase
);
export const selectDecryptingCase = createSelector(
  getCasesState,
  (state: State) =>
    state?.decryptingCases &&
    getCurrentWorkspace(state)?.caseType?.hasEncryptedIdentifier &&
    state.caseList.length
);
export const selectCopyingSampleFindings = createSelector(
  getCasesState,
  (state: State) => ({
    copying: state?.copyingFindings,
    enableMosaicView: state?.enableMosaicView,
  })
);
export const selectCompatibleWorkspaces = createSelector(
  getCasesState,
  (state: State) => state?.compatibleWorkspaces
);

export const sampleAnalysts = (sampleId: string) =>
  createSelector(
    getCasesState,
    (state: State) =>
      state?.samplesAnalysts.find((details) => details.sampleId === sampleId)
        ?.analysts
  );

export const selectWorkspaceFilterPaginationAndSorts = createSelector(
  getCasesState,
  (state: State) => ({
    workspace: getCurrentWorkspace(state),
    limit: state.pageSize,
    skip: state.pageSize * state.pageIndex,
    sorts: state.sorts,
    filters: Object.values(state.filters.entities),
  })
);

export const selectWorkspaceAndFilters = createSelector(
  getCasesState,
  (state: State) => ({
    workspace: getCurrentWorkspace(state),
    filters: Object.values(state.filters.entities),
  })
);

export const selectLoadingCases = createSelector(
  getCasesState,
  (state: State) => state?.loading
);

export const selectPageSize = createSelector(
  getCasesState,
  (state: State) => state.pageSize
);
export const selectPageIndex = createSelector(
  getCasesState,
  (state: State) => state.pageIndex
);
export const selectFilters = createSelector(getCasesState, (state: State) =>
  Object.values(state.filters.entities).map((config) => new TableFilter(config))
);
export const selectLoadingCompatibleWorkspaces = createSelector(
  getCasesState,
  (state: State) => state?.loadingCompatibleWorkspaces
);
export const currentUserId = createSelector(
  getCasesState,
  (state: State) => state.currentUserId
);
