import { Pipe, PipeTransform } from '@angular/core';

/**
 * Limits the length of a string to a value, and appends ".." at the end
 *
 * @export
 * @class TruncatePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args: number = 100): string {
    return value?.length > args ? value.slice(0, args) + '...' : value;
  }
}
