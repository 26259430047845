import { Sample, SampleRepository } from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";
import { CaseTopology } from "../workspace";
import { ParseSampleMapper, SampleTopology } from "./parse-sample.mapper";
import { SampleAssetTopology } from "./parse-sample-asset.mapper";
import { AssetTopology } from "./parse-asset.mapper";
import { ObjectTopology } from "../../parse.topology";

export class ParseSampleRepository extends ParseBaseRepository implements SampleRepository {
  private readonly sampleMapper = new ParseSampleMapper(this.parse);

  public async *findContainingAsset(assetId: string): AsyncGenerator<Sample[]> {
    const parseAsset = this.subclasses
      .getSubclass(AssetTopology.TABLE)
      .createWithoutData(assetId);

    const sampleAssetQuery = new this.parse.Query(SampleAssetTopology.TABLE)
      .equalTo(SampleAssetTopology.ASSET, parseAsset);

    const query = new this.parse.Query(SampleTopology.TABLE)
      .matchesKeyInQuery(
        ObjectTopology.ID,
        `${SampleAssetTopology.SAMPLE}.${ObjectTopology.ID}`,
        sampleAssetQuery
      );

    const iterator = this.getTokenPaginationGenerator({ limit: 100, query, lastObjectId: undefined });

    for await (const batch of iterator) yield batch.map((i) => this.sampleMapper.toDomain(i));
  }

  public async getByUUID(uuid: string): Promise<Sample> {
    const parseSample = await new this.parse.Query(SampleTopology.TABLE)
      .equalTo(SampleTopology.UUID, uuid)
      .first(this.options);

    return parseSample ? this.sampleMapper.toDomain(parseSample) : undefined;
  }

  public async saveAll(...samples: Sample[]): Promise<Sample[]> {
    const parseSamples = samples.map((s) => this.sampleMapper.fromDomain(s));

    const results = await this.parse.Object.saveAll(parseSamples, this.options);

    return results.map((r) => this.sampleMapper.toDomain(r));
  }

  public async *findInCase(caseId: string): AsyncGenerator<Sample[]> {
    const parseCase = this.subclasses
      .getSubclass(CaseTopology.TABLE)
      .createWithoutData(caseId);

    const query = new this.parse.Query(SampleTopology.TABLE).equalTo(
      SampleTopology.CASE,
      parseCase
    );

    const batches = this.getGeneratorFromQuery(query);

    for await (const batch of batches) {
      yield batch.map((i) => this.sampleMapper.toDomain(i));
    }
  }

  public async delete(id: string): Promise<void> {
    const parseSample = this.subclasses
      .getSubclass(SampleTopology.TABLE)
      .createWithoutData(id);

    await parseSample.destroy(this.options);
  }

  public async save(sample: Sample): Promise<string> {
    const parseSample = this.sampleMapper.fromDomain(sample);

    const { id } = await parseSample.save(null, this.options);

    return id;
  }

  public async getById(id: string): Promise<Sample> {
    const parseSample = await new this.parse.Query(SampleTopology.TABLE).get(
      id,
      this.options
    );

    return this.sampleMapper.toDomain(parseSample);
  }
}
