<div class="container">
  <div class="message-block">
    <div class="analysis">
      <i class="ri-checkbox-circle-fill icon"></i>
      <h1>{{ 'title.analysis_sent' | translate | uppercase }}</h1>
    </div>
    <div>
      <p data-cy="second-opinion-thanks-title" *ngIf="{ username: userThanked } as params">
        {{ 'title.analysis_sent_message' | translate: params }}
      </p>
    </div>
  </div>
  <div>
    <a style="color: #808284" href="https://spotlab.org" target="_blank">{{
      'title.discover_telespot' | translate | uppercase
    }}</a>
  </div>
</div>
