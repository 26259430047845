import type Parse from "parse";

import {
  ExportedAsset,
  ExportedAssetRepository,
  FindAssetQuery,
} from "@telespot/domain";
import { ParseExportedAssetMapper } from "./parse-exported-asset.mapper";
import { ParseBaseRepository } from "../../parse-base.repository";
import {
  SampleQueryBuilder,
  SampleAssetTopology,
  AssetTopology,
  SampleTopology,
} from "../acquisition";
import { UserTopology } from "../accounts";
import { AnalysisStateTopology } from "../analysis";

export class ParseExporedAssetRepository
  extends ParseBaseRepository
  implements ExportedAssetRepository {
  public count(query: FindAssetQuery): Promise<number> {
    return this.mapToParseQuery(query).count(this.options);
  }

  public async *find(query: FindAssetQuery): AsyncGenerator<ExportedAsset[]> {
    const { limit, skip } = query;

    const includeFields = [
      "sample.case.workspace.organization",
      "asset.device.deviceType",
      "asset.createdBy",
      "asset.methodType.analysisTypes",
    ];

    const parseQuery = this.mapToParseQuery(query).include(includeFields);

    const sampleAssetGenerator = this.getGeneratorFromQuery(
      parseQuery,
      limit,
      skip
    );

    for await (const sampleAssets of sampleAssetGenerator) {
      const uniqCases = [
        ...new Set(
          sampleAssets.map((item) => item.get("sample").get("case").id)
        ),
      ];

      const analysisStateQuery = new this.parse.Query(
        AnalysisStateTopology.TABLE
      ).containedIn(AnalysisStateTopology.CASE, uniqCases);

      const analysisStateGenerator = this.getGeneratorFromQuery(
        analysisStateQuery,
        limit,
        skip
      );

      const aStateArray = [];
      for await (const analysisStates of analysisStateGenerator) {
        analysisStates.map((aState) => aStateArray.push(aState));
      }

      const domainData = [];
      for (const sAsset of sampleAssets) {
        const extraObj = {};
        for (const aState of aStateArray) {
          if (sAsset.get("sample").get("case").id === aState.get("case").id) {
            extraObj[aState.get("user").id] = aState.get("state");
          }
        }
        domainData.push(ParseExportedAssetMapper.toDomain(sAsset, extraObj));
      }
      yield domainData;
    }
  }

  public mapToParseQuery(query: FindAssetQuery): Parse.Query {
    const { level, ids, createdBy, scansOnly } = query;

    const sampleQuery = new SampleQueryBuilder(this.parse)
      .withLevel(level)
      .withIds(ids)
      .build();

    if (createdBy)
      sampleQuery.matchesQuery(
        SampleTopology.CREATED_BY,
        new this.parse.Query(this.parse.User).equalTo(
          UserTopology.USERNAME,
          createdBy
        )
      );

    const sampleAssetQuery = new this.parse.Query(SampleAssetTopology.TABLE)
      .matchesQuery(SampleAssetTopology.SAMPLE, sampleQuery)
      .exists(SampleAssetTopology.ASSET);

    const assetQuery = new this.parse.Query(AssetTopology.TABLE).startsWith(
      AssetTopology.ASSET_FILE,
      "scan-"
    );

    if (createdBy)
      assetQuery.matchesQuery(
        AssetTopology.CREATED_BY,
        new this.parse.Query(this.parse.User).equalTo(
          UserTopology.USERNAME,
          createdBy
        )
      );

    const scanSampleAssetQuery = new this.parse.Query(
      SampleAssetTopology.TABLE
    ).matchesQuery(SampleAssetTopology.ASSET, assetQuery);

    const parseQuery = scansOnly
      ? this.parse.Query.and(sampleAssetQuery, scanSampleAssetQuery)
      : sampleAssetQuery;

    return parseQuery.addDescending("createdAt");
  }
}
