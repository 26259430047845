import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateModule } from '@ngx-translate/core';

import { SupportedLanguage } from './models/supported-language';
import { PaginatorIntl } from './providers/paginator-intl/paginator-intl';
import { SUPPORTED_LANGUAGES } from './providers/supported-languages.token';

@NgModule({
  imports: [CommonModule, TranslateModule],
  providers: [{ provide: MatPaginatorIntl, useClass: PaginatorIntl }],
  exports: [TranslateModule],
})
export class SharedLocalizationModule {
  static forRoot({ languages }: { languages: SupportedLanguage[] }): ModuleWithProviders<SharedLocalizationModule> {
    return {
      ngModule: SharedLocalizationModule,
      providers: [
        {
          provide: SUPPORTED_LANGUAGES,
          useValue: languages,
        },
      ],
    };
  }
}
