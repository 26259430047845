import { Object as ParseObject } from 'parse';

import { Asset, Workflow, Algorithms } from '..';
import { AnalysisStateData } from '../../interfaces/analysis-state-data';
import { Case } from '../case/case.model';
import { Sample } from '../sample/sample.model';
import { User } from '../user/user.model';

/**
 * Provides analysis state data for a {@link User} and a specific {@link Case} or {@link Sample}
 *
 * @export
 * @class AnalysisState
 * @extends {ParseObject}
 */
export class AnalysisState extends ParseObject {
  static className = 'AnalysisState';

  constructor(user: User, obj: Case | Sample, state: 'analyzed' | 'read' | 'inProgress') {
    super(AnalysisState.className);
    if (obj instanceof Case) {
      this.case = obj;
    } else if (obj instanceof Sample) {
      this.sample = obj;
    }
    this.user = user;
    this.state = state;
  }

  /**
   * The {@link User} linked to this state
   *
   * @memberof AnalysisState
   */
  set user(value: User) {
    this.set('user', value);
  }
  get user(): User {
    return this.get('user');
  }

  /**
   * The {@link Case} linked to this state.
   *
   * @memberof AnalysisState
   */
  set case(value: Case) {
    this.set('case', value);
  }
  get case(): Case {
    return this.get('case');
  }

  /**
   * The {@link Sample} linked to this state.
   *
   * @memberof AnalysisState
   */
  set sample(value: Sample) {
    this.set('sample', value);
  }
  get sample(): Sample {
    return this.get('sample');
  }

  /**
   * State value, i.e. 'pending', 'inprogress', 'analyzed'...
   *
   * @memberof AnalysisState
   */
  set state(value: string) {
    this.set('state', value);
  }
  get state(): string {
    return this.get('state');
  }

  /**
   * User-set pointer to the {@link Asset} currenly being analyzed
   *
   * @memberof AnalysisState
   */
  set assetBookmark(value: Asset) {
    this.set('assetBookmark', value);
  }
  get assetBookmark(): Asset {
    return this.get('assetBookmark');
  }

  /**
   * Array of {@link Asset} id's marked as relevant by the user
   *
   * @type {string[]}
   * @memberof AnalysisState
   */
  get favAssets(): string[] {
    return this.get('favAssets');
  }
  set favAssets(value: string[]) {
    this.set('favAssets', value);
  }

  /**
   * Additional data ({@link AnalysisStateData})
   *
   * @memberof AnalysisState
   */
  set data(value: AnalysisStateData) {
    this.set('data', value);
  }
  get data(): AnalysisStateData {
    return this.get('data');
  }

  get workflow(): Workflow {
    return this.get('workflow');
  }

  get algorithm(): Algorithms {
    return this.get('algorithm');
  }
}
