import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { OpenseadragonComponent } from "../openseadragon/openseadragon.component";
import { IAssetROI } from "@telespot/sdk";
import { skipWhile, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MouseTracker } from "openseadragon";
import { TRulerOption } from "@telespot/shared/viewers/data-access";

@Component({
  selector: "ts-ruler-layer",
  templateUrl: "./ruler-layer.component.html",
  styleUrls: ["./ruler-layer.component.scss"],
})
export class RulerComponent implements OnInit {
  @Input() osd: OpenseadragonComponent;
  @Input() rulerOption: TRulerOption;

  @ViewChild("moveArea", { static: true }) moveArea;

  private _rulerSizeUm: number;
  private _hspan: number;
  @Input() set rulerSizeUm(size: number) {
    this._rulerSizeUm = size;
    this.updateRulerSize();
  }
  get rulerSizeUm() {
    return this._rulerSizeUm;
  }

  @Input() set hspan(value: number) {
    this._hspan = value;
    this.updateRulerSize();
  }
  get hspan() {
    return this._hspan;
  }

  public _position: IAssetROI;
  public rulerSizePix = 21;
  public rulerSizeUmText = "";

  private _destroy$: Subject<void> = new Subject<void>();
  constructor(private cdr: ChangeDetectorRef) {}

  get position() {
    return { ...this._position };
  }

  set position(position: IAssetROI) {
    if (position === null) position = { x: 0, y: 0, w: 0, h: 0 };
    this._position = { ...position };
  }

  ngOnInit(): void {
    const viewerCenter = this.osd.viewer.viewport.getCenter();
    const coordCenter = this.osd.viewer.world
      .getItemAt(0)
      .viewportToImageCoordinates(viewerCenter);
    this._position = {
      x: coordCenter.x,
      y: coordCenter.y,
      w: 1,
      h: 1,
    };

    this.osd.viewerReady$
      .pipe(
        skipWhile((_) => !_),
        takeUntil(this._destroy$)
      )
      .subscribe((_) => {
        this.updateRulerSize();

        this.osd.viewer.addHandler("zoom", () => {
          this.updateRulerSize();
        });
      });

    new MouseTracker({
      element: this.moveArea.nativeElement,
      dragHandler: (e) => this.update(e),
    }).setTracking(true);
  }

  private update(e) {
    const overlay = this.osd.viewer.getOverlayById("ruler");
    if (overlay) {
      let bounds = overlay.getBounds(this.osd.viewer.viewport);
      const offset = this.osd.viewer.viewport.deltaPointsFromPixelsNoRotate(
        e["delta"]
      );
      bounds = bounds.translate(offset);
      this.osd.viewer.updateOverlay("ruler", bounds);
      const imgRect = this.osd.viewer.world
        .getItemAt(0)
        .viewportToImageRectangle(bounds);

      this.position = {
        x: imgRect.x,
        y: imgRect.y,
        w: imgRect.width,
        h: imgRect.height,
      };
    }
  }

  public updateRulerMeasure() {
    //TODO:To be used when zooming inside ruler element
    const viewportWidthPixels = this.osd.viewer.viewport.getContainerSize().x;
    const sizeRatio = this.rulerSizePix / viewportWidthPixels;
    const rulerMm = (this.hspan / this.osd.zoom) * sizeRatio;
    const rulerum = Math.round(rulerMm * 1000);

    this.rulerSizeUmText = rulerum + " µm";
    // this.updateRulerSize();

    this.cdr.detectChanges();
  }

  public updateRulerSize() {
    //Calculate ruler size in pixels based on specified um size
    const rulerMm = this.rulerSizeUm / 1000;
    this.rulerSizeUmText = this.rulerSizeUm + " µm";

    const sizeRatio = rulerMm / this.hspan;
    const viewportWidthPixels = this.osd.viewer.viewport.getContainerSize().x;
    const rulerSize = sizeRatio * viewportWidthPixels * this.osd.zoom;

    //update icon element size reecaling
    this.rulerSizePix = rulerSize;
  }
}
