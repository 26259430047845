<div
  class="analysis-input-container"
  *ngIf="analysisTask && form"
  [formGroup]="form"
>
  <label
    *ngIf="!mobile"
    [attr.for]="analysisTask.name"
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <h5
      [attr.data-cy]="'analysis_task_name_' + analysisTask?.name"
      *ngIf="analysisTask.type !== 'boolean'"
      class="flex-row align-items-center"
    >
      {{ analysisTask.name }}
    </h5>
  </label>
  <div [ngSwitch]="analysisTask.type">
    <mat-form-field *ngSwitchCase="'text'" style="width: 100%">
      <textarea
        matInput
        [attr.aria-label]="analysisTask.name"
        [id]="analysisTask.name"
        cols="30"
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        [formControlName]="analysisTask.name"
        [attr.data-cy]="'analysis_text_' + analysisTask.name"
      ></textarea>
    </mat-form-field>
    <ng-container *ngSwitchCase="'classification'">
      <ng-container *ngIf="analysisTask.multiple; else switcher">
        <div
          [formGroupName]="analysisTask.name"
          class="flex-row align-items-center justify-content-between"
        >
          <div>
            <div *ngFor="let option of analysisTask.options">
              <input [formControlName]="option.uuid" type="checkbox" />
              {{ option.name }}
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #switcher>
        <mat-radio-group
          [formControlName]="analysisTask.name"
          [id]="analysisTask.name"
          [attr.data-cy]="'analysis_switcher_' + analysisTask.name"
        >
          <mat-radio-button
            *ngFor="let option of analysisTask.options"
            [value]="option.uuid"
            [attr.aria-label]="option.name"
            >{{ option.name }}</mat-radio-button
          >
        </mat-radio-group>
      </ng-template>
    </ng-container>

    <div *ngSwitchDefault>Unknown type: {{ analysisTask.type }}</div>
  </div>
</div>
