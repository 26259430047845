<header>
  <ts-nav-bar
    [hidden]="(showHeader$ | async) === false"
    [isDevVersion]="isDevBuild"
    [sidenav]="snav"
    [showLogo]="showLogo$ | async"
  ></ts-nav-bar>
</header>

<mat-sidenav-container
  style="flex-grow: 1; display: flex; flex-direction: column"
>
  <mat-sidenav
    #snav
    mode="over"
    position="end"
    style="left: 10%"
    [class.main-div-mt]="showHeader$ | async"
    fixedTopGap="56"
  >
    <ts-side-nav *ngIf="snav.opened"></ts-side-nav>
  </mat-sidenav>

  <mat-sidenav-content
    class="sidenav-content"
    style="flex-grow: 1; display: flex; flex-direction: column"
  >
    <div
      id="main-div"
      [class.main-div-mt]="showHeader$ | async"
      [class.watermarked]="isDevBuild"
    >
      <div style="flex-grow: 1">
        <router-outlet
          style="flex-grow: 1"
          (activate)="snav.close()"
        ></router-outlet>
      </div>
      <footer>
        <ts-footer
          id="footer"
          [hidden]="(showFooter$ | async) === false"
        ></ts-footer>
      </footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
