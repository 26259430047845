<ng-container *ngIf="form">
  <form [formGroup]="form" (submit)="submit()">
    <mat-form-field class="full-width">
      <input
        matInput
        type="text"
        placeholder="Option name"
        formControlName="name"
      />
    </mat-form-field>
    <mat-error *ngIf="form.get('name')?.invalid">{{
      "label.invalid_character_error" | translate
    }}</mat-error>
    <mat-form-field class="full-width">
      <input
        matInput
        type="text"
        placeholder="Option displayName"
        formControlName="displayName"
      />
    </mat-form-field>
    <p>ROI color</p>
    <ts-color-picker formControlName="color"></ts-color-picker>
  </form>
  <div class="flex-column align-items-center">
    <button
      class="btn btn-outline-primary"
      (click)="cancel()"
      style="margin: 4px"
    >
      <i class="ri-close-fill"></i>
      {{ "button.cancel" | translate }}
    </button>
    <button
      class="btn btn-primary"
      [disabled]="form.invalid"
      (click)="submit()"
      style="margin: 4px"
    >
      <i class="ri-save-line"></i>
      {{ "button.save" | translate }}
    </button>
  </div>
</ng-container>
