<div class="card padded" *ngFor="let methodGroup of methodGroups">
  <div class="card-header flex-row flex-align-center justify-content-between">
    {{ "title.samples" | translate | uppercase }} ({{
      methodGroup.methodType.name | uppercase
    }})
  </div>
  <div class="card-body padded">
    <div class="flex-row align-items-center flex-wrap">
      <ng-container>
        <div
          class="card sample-card shadow-hard"
          *ngFor="let sampleData of methodGroup.samples"
        >
          <div class="flex-row align-items-center justify-content-between">
            <span class="sample-card__name">{{
              sampleData.sample.name || "Unnamed sample"
            }}</span>
            <span class="sample-card__files flex-row align-items-center">
              <i class="ri-image-2-line"></i>
              {{ sampleData.files?.length }}
            </span>
          </div>
          <div class="flex-row align-items-center justify-content-between">
            <div class="sample-card__device text-primary">
              {{ sampleData.sample.device?.name }}
            </div>
            <div class="sample-card__actions--disabled">
              <button
                class="btn btn-flat-primary btn-auto"
                type="button"
                (click)="edit(sampleData)"
                data-cy="button-edit-sample"
              >
                <i class="ri-pencil-line"></i>
              </button>
              <button
                class="btn btn-flat-danger btn-auto"
                type="button"
                (click)="delete(methodGroup, sampleData)"
                data-cy="button-delete-sample"
              >
                <i class="ri-delete-bin-6-line"></i>
              </button>
            </div>
          </div>
        </div>
        <div
          class="card sample-card sample-card--placeholder align-items-center flex-row"
        >
          <button
            class="btn btn-flat-primary"
            (click)="add(methodGroup)"
            data-cy="button-new-sample"
          >
            <i class="ri-add-circle-line"></i>
            {{ "button.add_new" | translate | uppercase }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
