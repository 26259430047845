import { User } from 'parse';

export enum StateName {
  // TODO: update documentation (state refers also to assets and reports, not only cases)
  /**
   * A new case that hasn't been opened for analysis
   */
  pending = 'pending',
  /**
   * The case has been opened by some user but its analysis hasn't been assigned to that user yet
   */
  read = 'read',
  /**
   * The case has been assigned to a user who's responsible for analyzing it
   */
  inProgress = 'inProgress',
  /**
   * The case has been analyzed but its report hasn't been submitted yet
   */
  analyzed = 'analyzed',
  /**
   * The case has been analyzed and reported
   */
  closed = 'closed',
  /**
   * The case has been re-opened for review
   */
  rewiew = 'review',
  /**
   * [Deprecated] The case is being viewed
   */
  viewing = 'viewing'
}

export interface IWorkflowState {
  state: StateName;
  user?: User;
  createdAt?: Date;
}
