import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { DataService } from '@telespot/web-core';
import { FieldType, IField, Query, TaskTypeName, TFieldTypeName } from '@telespot/sdk';
import { defer, merge, Observable, Subject } from 'rxjs';
import { map, share, skipWhile, take, takeUntil } from 'rxjs/operators';

import { FieldTypeEditorDialogComponent } from '../field-type-editor-dialog/field-type-editor-dialog.component';

@Component({
  selector: 'ts-field-type-editor',
  templateUrl: './field-type-editor.component.html',
  styleUrls: ['./field-type-editor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldTypeEditorComponent),
      multi: true,
    },
  ],
})
export class FieldTypeEditorComponent implements OnDestroy, ControlValueAccessor {
  public form: UntypedFormGroup;
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  private readonly _destroy$ = new Subject<void>();

  field: FieldType;

  isNew = false;

  public readonly fields$: Observable<{ type: string; fieldType: FieldType[] }[]> = defer(() =>
    this.dataService.find(new Query(FieldType))
  ).pipe(
    map((fields) =>
      Object.keys(TFieldTypeName).map((type) => ({
        type,
        fieldType: [
          ...(this.form.value.type.type === type ? [this.form.value.type] : []),
          ...fields.filter((f) => f.type === type.toString()),
        ],
      }))
    ),
    share()
  );

  @Input() fieldCollection: FieldType[];
  @Output() cancelled = new EventEmitter();

  private _dialogRef: MatDialogRef<any>;
  private _onChange;
  private _onTouched;

  constructor(private dataService: DataService, private _dialog: MatDialog) {}

  ngOnDestroy() {
    this._destroy$.next();
  }

  private _buildForm() {
    if (!this.field) {
      this.field = new FieldType();
      this.isNew = true;
    }
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.field.name, [Validators.required]),
      display_name: new UntypedFormControl(this.field.displayName || '', [Validators.required]),
      type: new UntypedFormControl(this.field, [Validators.required]),
      required: new UntypedFormControl(this.field.required ?? false),
      decimals: new UntypedFormControl(this.field.details.decimals),
      min: new UntypedFormControl(this.field.details.min),
      max: new UntypedFormControl(this.field.details.max),
      multiline: new UntypedFormControl(this.field.details.multiline),
      multiple: new UntypedFormControl(this.field.details.multiple),
      placeholder: new UntypedFormControl(this.field.details.placeholder),
      default: new UntypedFormControl(this.field.details.default),
      options: new UntypedFormControl(this.field.options || []),
      encrypted: new UntypedFormControl(this.field.encrypted ?? false),
    });

    merge(this.form.controls['display_name'].valueChanges, this.form.controls['type'].valueChanges)
      .pipe(
        skipWhile((_) => !this.isNew),
        takeUntil(this._destroy$)
      )
      .subscribe((_) => {
        this.form.controls['name'].setValue(
          FieldType.generateValidFieldName(this.form.controls['display_name'].value, this.form.controls['type'].value)
        );
      });

    this.form.controls['type'].valueChanges.pipe(takeUntil(this._destroy$)).subscribe((fieldPreset: FieldType) => {
      this.form.patchValue({
        required: fieldPreset.required || fieldPreset.type === 'case_name',
        display_name: fieldPreset.displayName ?? fieldPreset.name,
        ...fieldPreset.details,
      });
    });
  }

  private _getValue(): IField {
    const { name, required, type, encrypted, ...details } = this.form.value;
    return {
      name,
      required,
      type: type instanceof FieldType ? type.type : type,
      encrypted,
      details,
    };
  }

  updateBaseField(selection: MatSelectChange | TaskTypeName) {
    if (!selection) {
      return;
    }
    // this.form.controls['type'].patchValue(selection instanceof MatSelectChange ? selection.value.type : selection);
  }

  // Selection-type options

  addOption(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value ?? '';
    if (value.trim()) {
      this.form.patchValue({
        // TODO: validate option names (avoid $, !, etc...)
        options: [...this.form.value.options, value.trim()],
      });
      this._onTouched?.();
      this.form.markAsDirty();
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeOption(option): void {
    this.form.patchValue({
      options: this.form.value.options.filter((existingOption) => existingOption !== option),
    });
    this._onTouched?.();
    this.form.markAsDirty();
  }

  editOption(option) {
    const index = this.form.value.options.indexOf(option);
    this._dialogRef =
      this._dialogRef ||
      this._dialog.open(FieldTypeEditorDialogComponent, {
        width: '360px',
        hasBackdrop: true,
        data: {
          option: this.form.value.options[index],
          existingOptions: this.form.value.options.filter((o) => o !== this.form.value.options[index]),
        },
      });
    this._dialogRef
      .afterClosed()
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe((updatedOption) => {
        this._dialogRef = undefined;
        if (updatedOption) {
          const options = [...this.form.value.options];
          options[index] = updatedOption;
          this.form.patchValue({
            options,
          });
          this.form.markAsDirty();
          this._onTouched?.();
        }
      });
  }

  // Form

  writeValue(field: FieldType): void {
    if (field) {
      this.field = field;
      this.isNew = false;
    }
    this._buildForm();
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  save() {
    this._onChange(this._getValue());
  }

  cancel() {
    this.cancelled.emit();
  }

  public hasCaseName(): boolean {
    return this.fieldCollection.some((field) => field.type === 'case_name');
  }
}
