import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "@telespot/web-core";
import {
  AnalysisType,
  AssetType,
  Device,
  DeviceType,
  MethodType,
  Pipeline,
  ProtocolPipeline,
  Query,
  Resource,
} from "@telespot/sdk";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import { combineLatest, defer, Observable, Subject } from "rxjs";
import { map, take, takeUntil, tap } from "rxjs/operators";

import { DefaultSampleNameEditorDialogComponent } from "../default-sample-name-editor-dialog/default-sample-name-editor-dialog.component";

interface TdefaultSampleItem {
  name:
    | string
    | {
        [key: string]: string;
      };
  required?: boolean;
}

export interface MethodTypeSavedEvent {
  methodType: MethodType;
  isNew?: boolean;
}

@Component({
  selector: "ts-method-type-editor",
  templateUrl: "./method-type-editor.component.html",
  styleUrls: ["./method-type-editor.component.css"],
})
export class MethodTypeEditorComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  // UI
  showAssetFieldsEditor = false;
  private _dialogRef: MatDialogRef<unknown>;
  redirectUrl: string;

  // I/O
  @Input()
  set method(method: MethodType) {
    if (this._method !== undefined && method === this._method) return;
    this._method = method;
    this._buildForm();
  }
  get method() {
    return this._method;
  }
  @Output() saved = new EventEmitter<MethodTypeSavedEvent>();
  methodForm: UntypedFormGroup;

  originalProtocolPipelines: ProtocolPipeline[];
  protocolPipelines: ProtocolPipeline[];

  // Data
  private _method: MethodType;
  availableAssetTypes$: Observable<AssetType[]>;
  availableDevices$: Observable<
    { deviceType: DeviceType; devices: Device[] }[]
  >;
  private _organizationDevices$: Observable<Device[]>;
  private _assetType: Subject<AssetType> = new Subject<AssetType>();

  defaultSamples: TdefaultSampleItem[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  private _buildForm() {
    this.methodForm = new UntypedFormGroup({
      name: new UntypedFormControl(
        this._method.name || "New Method",
        Validators.required
      ),
      assetType: new UntypedFormControl(
        this._method.assetType,
        Validators.required
      ),
      analysisTypes: new UntypedFormControl(this._method.analysisTypes || []),
      resources: new UntypedFormControl(this._method.resources),
      assetFields: new UntypedFormControl(this._method.assetDataFields ?? []),
    });
  }

  addDefaultSampleName(event: MatChipInputEvent) {
    const value = event.value ?? "";
    const input = event.input;
    if (value.trim()) {
      const defaultSampleItem = {
        name: {
          en: value.trim(),
        },
      };
      this.defaultSamples.push(defaultSampleItem);
    }
    if (input) input.value = "";
    this.methodForm.markAsDirty();
  }
  removeDefaultSampleName(name: TdefaultSampleItem) {
    this.defaultSamples = this.defaultSamples.filter(
      (sampleName) => sampleName !== name
    );
    this.methodForm.markAsDirty();
  }

  editDefaultSampleName(defaultSampleNameItem: TdefaultSampleItem) {
    this._dialogRef =
      this._dialogRef ||
      this.dialog.open(DefaultSampleNameEditorDialogComponent, {
        width: "400px",
        data: {
          name:
            defaultSampleNameItem.name instanceof Object
              ? { ...defaultSampleNameItem.name }
              : { en: defaultSampleNameItem.name },
        },
        hasBackdrop: true,
      });
    this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe((res) => {
        if (res) {
          defaultSampleNameItem.name = res;
          this.methodForm.markAsDirty();
        }
      });
  }

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private _logger: LoggerService
  ) {}

  sameId = (methodType1: MethodType, methodType2: MethodType) =>
    methodType1?.id === methodType2?.id;

  ngOnInit() {
    if (this._method?.config)
      this.defaultSamples = this._method.config["defaultSamples"] || [];
    this.evaluateAvailableDevices();
    this.route.paramMap
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (params) => {
        const id = params.get("methodTypeId");
        if (id) {
          this.method = await this.dataService.get(
            id,
            new Query(MethodType).include([
              "analysisTypes",
              "resources.resources",
            ])
          );

          this.redirectUrl = "/protocols/methodTypes";
        } else {
          this.method = this.method ?? new MethodType();

          if (this.method.isNew()) {
            this._logger.debug(`New methodType`);
            if (
              this.route.snapshot.routeConfig.path.includes(
                "methodTypes/edit/new"
              )
            )
              this.redirectUrl = "/protocols/methodTypes";
          }
        }

        this.originalProtocolPipelines = this.method.id
          ? (await this.dataService.find(
              new Query(ProtocolPipeline)
                .include("pipeline")
                .equalTo("methodType", this.method)
            )) ?? []
          : [];

        this.protocolPipelines = this.originalProtocolPipelines;
        this.defaultSamples = this.method.config.defaultSamples || [];
        this.evaluateAvailableDevices();
        this._buildForm();
      });

    this._organizationDevices$ = defer(() =>
      this.dataService.find(new Query(Device).include(["deviceType"]))
    ).pipe(tap((_) => this.evaluateAvailableDevices()));

    this.availableAssetTypes$ = defer(() =>
      this.dataService.find(new Query(AssetType).include(["deviceTypes"]))
    );

    if (!this._method) {
      this._method = new MethodType();
    }

    this.availableDevices$ = combineLatest([
      this._organizationDevices$,
      this._assetType.asObservable(),
    ]).pipe(
      map(([devices, assetType]) => {
        return assetType
          ? assetType.deviceTypes
              ?.filter(
                (deviceType) => deviceType !== undefined && deviceType.name
              )
              .map((deviceType) => ({
                deviceType,
                devices: devices.filter(
                  (device) => device.deviceType?.id === deviceType.id
                ),
              }))
          : [];
      })
    );
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  toggleAssetFields() {
    this.showAssetFieldsEditor = !this.showAssetFieldsEditor;
  }

  submit() {
    this._method.name = this.methodForm.value.name;
    this._method.assetType = this.methodForm.value.assetType;
    this._method.assetDataFields = this.methodForm.value.assetFields;
    this._method.config = Object.assign(this._method.config || {}, {
      ...{
        defaultSamples: this.defaultSamples.map((sampleName) => {
          return sampleName;
        }),
      },
    });

    const isNew = this._method.isNew();

    const protocolPipelinesToDelete = this.originalProtocolPipelines.filter(
      (o) => !this.protocolPipelines.some((p) => o.id === p.id)
    );
    let protocolPipelinesToCreate = this.protocolPipelines.filter((p) =>
      p.isNew()
    );

    this.dataService.save(this._method).then(async (method: MethodType) => {
      if (isNew)
        protocolPipelinesToCreate = protocolPipelinesToCreate.map((p) => {
          p.methodType = method;
          return p;
        });

      const created = await Promise.all(
        protocolPipelinesToCreate.map(
          async (p) => await this.dataService.save(p)
        )
      );
      const deleted = await Promise.all(
        protocolPipelinesToDelete.map(
          async (p) => await this.dataService.delete(p)
        )
      );
      this.saved.emit({ methodType: method, isNew });
    });
  }

  cancel() {
    this._method.revert();
    this.saved.emit(null);
  }

  evaluateAvailableDevices() {
    if (this.methodForm)
      this._assetType.next(this.methodForm.controls.assetType.value);
  }

  updateAnalysisTypes(analysisTypes: AnalysisType[]) {
    this._method.analysisTypes = analysisTypes;
    this.methodForm.markAsDirty();
  }

  updatePipelines(pipelines: Pipeline[]) {
    const assignedPipelinesIds = pipelines.map((p) => p.id);
    this.protocolPipelines = this.protocolPipelines.filter((p) =>
      assignedPipelinesIds.includes(p.pipeline.id)
    );
    const newPipelineAssignments = pipelines.filter(
      (p) => !this.protocolPipelines.map((p) => p.pipeline.id).includes(p.id)
    );
    const newProtocolPipelines = newPipelineAssignments.map((p) => {
      const protopipeline = new ProtocolPipeline();
      protopipeline.methodType = MethodType.createWithoutData(
        this._method.id
      ) as MethodType;
      protopipeline.pipeline = p;
      return protopipeline;
    });

    this.protocolPipelines = [
      ...this.protocolPipelines,
      ...newProtocolPipelines,
    ];
    this.methodForm.markAsDirty();
  }

  async updateResources(resources: Resource[]) {
    this._method.resources = await Promise.all(
      resources.map((resource) => this.getResources(resource))
    );
    this.methodForm.markAsDirty();
  }

  async getResources(resource: Resource) {
    return await this.dataService.get(
      resource.id,
      new Query(Resource).include(["resources"])
    );
  }

  updateAssetFields(assetFields: any[]) {
    this.methodForm.patchValue(
      {
        assetFields,
      },
      { emitEvent: true }
    );
    this.methodForm.markAsDirty();
  }

  getPipelinesFromProtocol() {
    return (this.protocolPipelines || []).map((p) => p.pipeline);
  }
}
