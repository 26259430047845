<div class="card">
  <div class="card-body">
    <div class="text-primary">
      {{ "button.manage_users" | translate | uppercase }}
    </div>
  </div>
  <div
    class="card-body card-split responsive"
    *ngIf="{
      available: availableUsers$ | async,
      members: members$ | async
    } as state"
  >
    <div class="card-split__section padded">
      <h2>{{ "title.available_users" | translate | uppercase }}</h2>
      <ng-container>
        <ul
          *ngIf="
            (showMobileLayout$ | async) === false;
            else mobileLayoutNotMember
          "
          class="list-group list-group--no-borders"
        >
          <li
            class="list-group-item"
            *ngFor="let user of state.available"
            data-cy="not-member-row"
          >
            {{ user?.username }}

            <button
              mat-icon-button
              class="btn float-right btn-flat-primary"
              (click)="addMember(user)"
            >
              <i class="ri-add-circle-line"></i>
            </button>
          </li>
        </ul>
        <ng-template #mobileLayoutNotMember>
          <mat-form-field
            class="light-shadow full-width mt-2"
            appearance="none"
          >
            <mat-label
              class="flex-row align-items-center justify-content-between padding-h-20"
            >
              {{ "tooltip.select_members" | translate }}
              <mat-icon
                [inline]="true"
                class="right-primary-icon"
                fontSet="remixicon"
                fontIcon="ri-arrow-drop-down-line icon"
              ></mat-icon>
            </mat-label>
            <mat-select
              id=""
              name="manageMembers"
              disableOptionCentering
              class="mx-2 hideArrow"
              (valueChange)="addMember($event); select.value = null"
              #select="matSelect"
              [disabled]="!state.available?.length"
            >
              <mat-option *ngFor="let user of state.available" [value]="user">
                <span class="flex-row justify-content-between">
                  {{ user?.username }}
                  <i class="ri-add-circle-line text-primary"></i>
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>
      </ng-container>
    </div>
    <div class="card-split__section padded">
      <h2>{{ "title.current_users" | translate | uppercase }}</h2>
      <ul class="list-group list-group--no-borders">
        <li
          class="list-group-item"
          *ngFor="let member of state.members"
          data-cy="member-row"
        >
          {{ member.user?.username }}
          <button
            mat-icon-button
            class="btn float-right btn-flat-danger"
            (click)="removeMember(member)"
          >
            <i class="ri-close-circle-line"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
