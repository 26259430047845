import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSliderModule } from "@angular/material/slider";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { LanguageInputComponentModule } from "@shared/localization";
import {
  DataTableModule,
  ReactiveSelectDirectiveModule,
  StepperModule,
} from "@shared/ui";
import { TelespotModule } from "@telespot/web-core";
import { TelespotProtocolsUiModule } from "@telespot/protocols/ui";
import { ColorPickerModule } from "@telespot/shared/color-picker";
import { LabelCreatorModule } from "@telespot/shared/label-creator";

import { AnalysisTypesComponent } from "./components/analysis-types/analysis-types.component";
import { AiWorkflowsComponent } from "./components/ai-workflows/ai-workflows.component";
import { AnalysisOptionEditorComponent } from "./components/analysis-option-editor/analysis-option-editor.component";
import { AnalysisTaskEditorDialogComponent } from "./components/analysis-task-editor-dialog/analysis-task-editor-dialog.component";
import { AnalysisTaskEditorComponent } from "./components/analysis-task-editor/analysis-task-editor.component";
import { AnalysisTypeEditorDialogComponent } from "./components/analysis-type-editor-dialog/analysis-type-editor-dialog.component";
import { AnalysisTypeEditorComponent } from "./components/analysis-type-editor/analysis-type-editor.component";
import { AnalysisTypesAssignmentComponent } from "./components/analysis-types-assignment/analysis-types-assignment.component";
import { AssetTypeEditorComponent } from "./components/asset-type-editor/asset-type-editor.component";
import { AssetTypesComponent } from "./components/asset-types/asset-types.component";
import { CaseTypeDetailComponent } from "./components/case-type-detail/case-type-detail.component";
import { CaseTypeEditorComponent } from "./components/case-type-editor/case-type-editor.component";
import { CaseTypesComponent } from "./components/case-types/case-types.component";
import { DefaultSampleNameEditorDialogComponent } from "./components/default-sample-name-editor-dialog/default-sample-name-editor-dialog.component";
import { DeviceTypeEditorComponent } from "./components/device-type-editor/device-type-editor.component";
import { DeviceTypesComponent } from "./components/device-types/device-types.component";
import { FieldTypeEditorDialogComponent } from "./components/field-type-editor-dialog/field-type-editor-dialog.component";
import { FieldTypeEditorComponent } from "./components/field-type-editor/field-type-editor.component";
import { FieldTypesComponent } from "./components/field-types/field-types.component";
import { MethodTypeEditorComponent } from "./components/method-type-editor/method-type-editor.component";
import { MethodTypesAssignmentComponent } from "./components/method-types-assignment/method-types-assignment.component";
import { MethodTypesComponent } from "./components/method-types/method-types.component";
import { ResourceEditorDialogComponent } from "./components/resource-editor-dialog/resource-editor-dialog.component";
import { ResourceEditorComponent } from "./components/resource-editor/resource-editor.component";
import { ResourcesAssignmentComponent } from "./components/resources-assignment/resources-assignment.component";
import { ResourcesComponent } from "./components/resources/resources.component";

import { WorkspaceAssignationEditorComponent } from "./components/workspace-assignation-editor/workspace-assignation-editor.component";
import { ResourceEditorPageComponent } from "./resource-editor-page/resource-editor-page.component";
import { TelespotProtocolsFeatureRoutingModule } from "./telespot-protocols-feature-routing.module";
import { TypesPageComponent } from "./types-page/types-page.component";
import { MaskResultsAssignmentComponent } from "./components/mask-results-assignment/mask-results-assignment.component";
import { PipelineEditorComponent } from "./components/pipeline-editor/pipeline-editor.component";
import { PipelineTaskEditorDialogComponent } from "./components/pipeline-task-editor-dialog/pipeline-task-editor-dialog.component";
// import { LabelSuggesterComponent } from "./components/label-suggester/label-suggester.component";
import { PipelineTaskEditorComponent } from "./components/pipeline-task-editor/pipeline-task-editor.component";
import { TaskOptionEditorComponent } from "./components/task-option-editor/task-option-editor.component";
// import { LabelLangSelectorDialogComponent } from "./components/label-lang-selector-dialog/label-lang-selector-dialog.component";
import { PipelinesAssignmentComponent } from "./components/pipelines-assignment/pipelines-assignment.component";
import { PipelineEditorDialogComponent } from "./components/pipeline-editor-dialog/pipeline-editor-dialog.component";
import { PipelinesComponent } from "./components/pipelines/pipelines.component";
import { AIAvailableModelsComponent } from "./components/ai-available-models/ai-available-models.component";
import { SelectAIModelDialogComponent } from "./components/select-ai-model-dialog/select-ai-model-dialog.component";
import { PipelineStepEditorComponent } from "./components/pipeline-step-editor/pipeline-step-editor.component";
import { PipelineStepEditorDialogComponent } from "./components/pipeline-step-editor-dialog/pipeline-step-editor-dialog.component";

@NgModule({
  declarations: [
    CaseTypesComponent,
    CaseTypeEditorComponent,
    FieldTypesComponent,
    FieldTypeEditorComponent,
    MethodTypeEditorComponent,
    MethodTypesAssignmentComponent,
    AnalysisTypesAssignmentComponent,
    AnalysisTypeEditorComponent,
    AnalysisTaskEditorComponent,
    WorkspaceAssignationEditorComponent,
    CaseTypeDetailComponent,
    AssetTypeEditorComponent,
    AssetTypesComponent,
    DefaultSampleNameEditorDialogComponent,
    AnalysisTaskEditorDialogComponent,
    AnalysisTypeEditorDialogComponent,
    AnalysisOptionEditorComponent,
    TypesPageComponent,
    AnalysisTypesComponent,
    AiWorkflowsComponent,
    DeviceTypeEditorComponent,
    DeviceTypesComponent,
    FieldTypeEditorDialogComponent,
    ResourcesAssignmentComponent,
    ResourceEditorDialogComponent,
    ResourcesComponent,
    ResourceEditorComponent,
    MethodTypesComponent,
    ResourceEditorPageComponent,
    MaskResultsAssignmentComponent,
    PipelineEditorComponent,
    PipelineTaskEditorDialogComponent,
    PipelineTaskEditorComponent,
    // LabelSuggesterComponent,
    TaskOptionEditorComponent,
    // LabelLangSelectorDialogComponent,
    PipelinesAssignmentComponent,
    PipelineEditorDialogComponent,
    PipelinesComponent,
    AIAvailableModelsComponent,
    SelectAIModelDialogComponent,
    PipelineStepEditorComponent,
    PipelineStepEditorDialogComponent,
  ],
  imports: [
    CommonModule,
    TelespotModule,
    TranslateModule,
    FormsModule,
    MatStepperModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    TelespotProtocolsFeatureRoutingModule,
    ColorPickerModule,
    LabelCreatorModule,
    MatTabsModule,
    MatMenuModule,
    ReactiveFormsModule,
    DragDropModule,
    MatTableModule,
    MatSortModule,
    MatChipsModule,
    MatTooltipModule,
    MatListModule,
    MatExpansionModule,
    StepperModule,
    MatRadioModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    LanguageInputComponentModule,
    TelespotProtocolsUiModule,
    DataTableModule,
    ReactiveSelectDirectiveModule,
    MatSliderModule,
  ],
})
export class TelespotProtocolsFeatureModule {}
