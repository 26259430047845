import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Host,
  Input,
  NgModule,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { DataTableComponent } from '../data-table/data-table.component';
import { TableFilter } from '../models/data-table-filter';

@Component({
  selector: 'ts-data-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSearchComponent<T> implements OnDestroy, AfterViewInit {
  private _destroy$ = new Subject<void>();

  @ViewChild('tableSearchBar', { read: ElementRef }) private searchBar: ElementRef<HTMLInputElement>;

  @Input() searchTerm: { term: string; displayName?: string } = { term: 'name', displayName: 'name' };

  constructor(@Host() private dataTable: DataTableComponent<T>) { }

  get config() {
    return this.dataTable.config;
  }

  public currentSearch$ = this.dataTable.dataSource.activeFilters$.pipe(
    map((filters) => filters.find((filter) => filter.name === this.searchTerm.term)?.constraints ?? {}),
    map((constraints) => constraints?.equalTo ?? constraints?.contains ?? '')
  );

  ngAfterViewInit() {
    fromEvent(this.searchBar.nativeElement, 'keyup')
      .pipe(
        debounceTime(300),
        map(() => this.searchBar.nativeElement.value),
        distinctUntilChanged(),
        takeUntil(this._destroy$)
      )
      .subscribe((term) => {

        const searchFilter = new TableFilter({
          name: this.searchTerm.term,
          displayName: (item) =>
            `${item.name} ${item.constraints?.contains ? `: "*${item.constraints?.contains}*"` : `: "${item.constraints?.equalTo}"`
            }`,
          constraints: {
            contains: term,
          },
        })

        if (term.length) {
          this.dataTable.applyFilter(searchFilter);
        } else {
          this.dataTable.removeFilter(searchFilter);
        }
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
  }
}

@NgModule({
  imports: [CommonModule, MatFormFieldModule, MatInputModule, TranslateModule],
  declarations: [TableSearchComponent],
  exports: [TableSearchComponent],
})
export class TableSearchModule { }
