import { Entity } from "./entity";
import { User } from "./user";

export interface AffiliateAttributes extends Entity {
  user: User;
  organizationId: string;
  language: string; // REVIEW type only supported localizations
}

// REVIEW: remove user dependency and represent relevant user data here
export class Affiliate implements Entity {
  constructor(public attributes: AffiliateAttributes) { }

  public get id(): string {
    return this.attributes.id;
  }

  public get user(): User {
    return this.attributes.user;
  }

  public get organizationId(): string {
    return this.attributes.organizationId;
  }

  public get language(): string {
    // return this.attributes.organization.license?.language ?? "en";
    return this.attributes.language;
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }
}
