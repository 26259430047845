import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { roisReducer } from '../../+state/rois.reducers';

@NgModule({
  imports: [StoreModule.forRoot({}), StoreModule.forFeature('rois', roisReducer)],
  declarations: [],
  exports: [],
  providers: [],
})
export class RoisTestingModule {}
