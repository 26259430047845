import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ts-data-table ng-template[dataTableHeader]',
})
export class DataTableHeaderTemplateDirective {
  @Input() dataTableHeader: string;
  @Input() dataTableHeaderLayout: 'all' | 'mobile' | 'desktop';
  constructor(public tpl: TemplateRef<unknown>) {}
}
