import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UserDashboardShellModule } from './components/user-dashboard-shell/user-dashboard-shell.component';
import { UserDashboardRoutingModule } from './user-dashboard-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, UserDashboardRoutingModule, UserDashboardShellModule],
})
export class TelespotUserDashboardFeatureModule {}
