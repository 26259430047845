import {
  ClassificationSpecification,
  Finding,
  IFindingData,
  Label,
  StepTask,
} from "../entities";

export class FindingService {
  public static exists(list: Finding[], finding: Finding): Finding {
    return list.find(
      (f) =>
        f.analysisId === finding.analysisId &&
        f.creatorId === finding.creatorId &&
        f.creatorEntity === finding.creatorEntity &&
        f.pipelineStepId === finding.pipelineStepId
    );
  }

  public static isFromAnalysisDuplicated(
    list: Finding[],
    finding: Finding
  ): Finding {
    return list.find(
      (f) =>
        f.analysisId !== finding.analysisId &&
        f.assetId === finding.assetId &&
        f.creatorId === finding.creatorId &&
        f.creatorEntity === finding.creatorEntity &&
        f.pipelineStepId === finding.pipelineStepId
    );
  }

  public static getAIRois(rois) {
    //Get only the maximum probability label for AI rois
    return rois.map((roi) => {
      let labels;
      const values = Object.values((roi.labels as any[]) || {});

      if (!values.length) labels = {};
      const maxProb = Math.max(...values);
      const indices = values.reduce((acc, val, index) => {
        if (val === maxProb) {
          acc.push(index);
        }
        return acc;
      }, []);
      if (indices.length > 0) {
        labels = indices.reduce((acc, index) => {
          acc[Object.keys(roi.labels)[index]] = maxProb;
          return acc;
        }, {});
      } else {
        labels = {};
      }
      return { ...roi, labels };
    });
  }

  public static xoisNormalized(xois) {
    return xois.every((xoi) => {
      return (
        xoi.x0 >= 0 &&
        xoi.x0 <= 1 &&
        xoi.x1 >= 0 &&
        xoi.x1 <= 1 &&
        xoi.y0 >= 0 &&
        xoi.y0 <= 1 &&
        xoi.y1 >= 0 &&
        xoi.y1 <= 1 &&
        Object.values(xoi.labels as any[]).every((pr) => pr >= 0 && pr <= 1)
      );
    });
  }

  public static getAllLabelUuids(data: IFindingData, type: StepTask) {
    if (type === StepTask.TEXT || type === StepTask.OCR) return [];

    if (type === StepTask.CLASSIFICATION) {
      return Object.keys(
        (data?.content[0] as ClassificationSpecification)?.options
      );
    }

    const uuids = [];
    (data?.content || []).forEach((c) => {
      uuids.push(...(Object.keys(c?.labels) || []));
    });

    return Array.from(new Set(uuids));
  }

  public static async isFindingDataValid(
    type: StepTask,
    data: IFindingData,
    iterator: AsyncGenerator<Label[]>,
    labelUuids: string[]
  ) {
    const existingLabels = [];

    for await (const labels of iterator) {
      existingLabels.push(...labels);
    }

    if (labelUuids.length !== existingLabels.length) return false;

    //Check that all coords are normalized and probabilities are correct
    if (type === StepTask.POSITION || type === StepTask.ROIDETECTION) {
      const allXois = (data.content as any).map((roi) => roi);
      return this.xoisNormalized(allXois);
    }

    return true;
  }
}
