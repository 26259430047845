import { AfterContentInit, Component, ContentChildren, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { StepDirective } from './step.directive';

@Component({
  selector: 'ts-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements AfterContentInit, OnDestroy {
  currentStepId = 0;
  stepsCount = 4;

  get progress() {
    return Math.floor(((this.currentStepId + 1) / this.stepsCount) * 100);
  }

  @Input() disabled = false;
  @Input() hidden = false;
  @ContentChildren(StepDirective) steps: StepDirective[] = [];

  constructor(private _route: ActivatedRoute, private _router: Router) { }

  private _destroy$ = new Subject<void>();
  ngOnDestroy() {
    this._destroy$.next();
  }

  ngAfterContentInit(): void {
    this.stepsCount = this.steps.length;
    this._update();
    this._route.fragment.pipe(distinctUntilChanged(), takeUntil(this._destroy$)).subscribe((fragment) => {
      const selectStep = (Array.from(this.steps) || []).findIndex((step) => step.fragment === fragment);
      if (selectStep !== -1) {
        this.setStep(selectStep);
      }
    });
  }

  public nextStep() {
    this.setStep(Math.min(this.currentStepId + 1, this.stepsCount));
  }

  public previousStep() {
    this.setStep(Math.max(this.currentStepId - 1, 0));
  }

  setStep(index: number) {
    if (this.currentStepId === index) return;
    this.currentStepId = index;

    this._update();
  }

  private _update() {
    this.steps.forEach((s, index) => {
      s.hidden = index !== this.currentStepId;
    });
    const fragment = Array.from(this.steps)?.[this.currentStepId]?.fragment;
    if (fragment) {
      this._router.navigate([], {
        fragment,
      });
    }
  }
}
