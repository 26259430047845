<div class="main-container">
  <div class="title">
    <h1>
      {{ 'title.device_editor' | translate }}
    </h1>
  </div>
  <form class="example-form" [formGroup]="formGroup" (submit)="save()" *ngIf="formGroup">
    <div class="card">
      <div class="card-body" style="display: flex; flex-direction: column">
        <mat-form-field class="example-full-width">
          <input
            autofocus
            matInput
            placeholder="{{ 'label.device_name' | translate }}"
            [formControl]="formGroup.controls['name']"
          />
          <mat-hint *ngIf="!formGroup.dirty">{{ 'hint.device_name' | translate }}</mat-hint>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input
            matInput
            placeholder="{{ 'label.serial_number' | translate }}"
            [formControl]="formGroup.controls['serialNumber']"
          />
          <mat-hint *ngIf="validateSerialNumbers && !formGroup.dirty">The serial number should have 6 digits</mat-hint>
          <mat-error
            *ngIf="validateSerialNumbers && formGroup.invalid && formGroup.controls['serialNumber'].errors?.validSerial"
          >
            Please enter a valid serial number (it should have 6 digits)
          </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input
            type="text"
            placeholder="{{ 'core.device_type' | translate }}"
            required
            aria-label="Assignee"
            matInput
            [formControlName]="'deviceType'"
            [matAutocomplete]="deviceTypeAutoComplete"
          />
          <mat-autocomplete #deviceTypeAutoComplete="matAutocomplete" [displayWith]="deviceTypeName">
            <mat-option *ngFor="let deviceType of deviceTypes$ | async" [value]="deviceType">
              <span style="min-width: 150px">{{ deviceType.name }}</span> - {{ deviceType.version }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input
            type="text"
            placeholder="{{ 'core.organization' | translate }}"
            required
            aria-label="Organization"
            matInput
            [formControlName]="'organization'"
            [matAutocomplete]="organizationAutoComplete"
          />
          <mat-autocomplete #organizationAutoComplete="matAutocomplete" [displayWith]="organizationName">
            <mat-option *ngFor="let organization of organizations$ | async" [value]="organization">
              {{ organization.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="card-footer card-actions">
        <button class="btn btn-outline-primary btn-outline--white-bg card-actions__action" type="reset" (click)="cancel()">
          <i class="ri-close-fill"></i>
          {{ 'button.cancel' | translate | uppercase }}
        </button>
        <button class="btn btn-primary card-actions__action" [disabled]="!formGroup.valid" type="submit">
          <i class="ri-save-line"></i>
          {{ 'button.submit' | translate | uppercase }}
        </button>
      </div>
    </div>
  </form>
</div>
