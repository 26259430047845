import { Object as ParseObject } from 'parse';

import { AnalysisType } from '../analysis-type/analysis-type.model';
import { Sample } from '../sample/sample.model';
import { User } from '../user/user.model';

export class SecondOpinion extends ParseObject {
  static className = 'SecondOpinion';

  constructor({
    sentTo,
    sample,
    originalSample,
    analysisTypes
  }: { sentTo?: User[]; sample?: Sample; originalSample?: Sample; analysisTypes?: AnalysisType[] } = {}) {
    super(SecondOpinion.className);
    this.set('sentTo', sentTo);
    this.set('sample', sample);
    this.set('originalSample', originalSample);
    this.set('analysisTypes', analysisTypes);
  }

  get createdBy(): User {
    return this.get('createdBy');
  }

  get sentTo(): User[] {
    return this.get('sentTo');
  }

  get sample(): Sample {
    return this.get('sample');
  }

  get originalSample(): Sample {
    return this.get('originalSample');
  }

  get analysisTypes(): AnalysisType[] {
    return this.get('analysisTypes');
  }
}
