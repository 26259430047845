import { Object as ParseObject } from 'parse';

import { ICaseFieldDetails, IField, IParseObjectField, TFieldTypeName } from '../..';

export class FieldType extends ParseObject {
  static className = 'FieldType';

  static fromParseObjectJSON(data: IParseObjectField): FieldType {
    const fieldType = new FieldType();
    fieldType.name = data.name;
    fieldType.type = data.type;
    fieldType.required = data.required || false;
    fieldType.details = data.details || {};
    fieldType.encrypted = data.encrypted;
    return fieldType;
  }

  constructor(data?: IField) {
    super(FieldType.className);
    this.name = data?.name;
    this.type = data?.type;
    this.required = data?.required ?? false;
    this.details = data?.details ?? {
      display_name: 'New field',
    };
    this.encrypted = data?.encrypted;
  }

  get name(): string {
    return this.get('name') ?? '';
  }

  set name(value: string) {
    this.set('name', value);
  }

  get encrypted(): boolean {
    return this.get('encrypted');
  }

  set encrypted(value: boolean) {
    this.set('encrypted', value);
  }

  get type(): TFieldTypeName {
    return this.get('type');
  }

  set type(value: TFieldTypeName) {
    this.set('type', value);
  }

  get details(): ICaseFieldDetails {
    const details: ICaseFieldDetails = this.get('details') ?? {};
    if (details) {
      return details;
    } else {
      return {
        display_name: '?',
      };
    }
  }

  set details(value: ICaseFieldDetails) {
    this.set('details', value);
  }

  get options(): string[] {
    const options = this.details.options;
    // TODO: unnecessary check?
    if (options !== null) {
      return options;
    } else {
      return [];
    }
  }

  set options(value: string[]) {
    this.attributes.details.options = value;
  }

  get required(): boolean {
    return this.get('required');
  }

  set required(value: boolean) {
    this.set('required', value);
  }

  set displayName(value: string) {
    this.details.display_name = value;
  }
  get displayName(): string {
    return this.details.display_name;
  }

  static generateValidFieldName(displayName: string, type: TFieldTypeName): string {
    if (displayName === undefined) throw new Error('displayName must be defined');
    return type === 'case_name'
      ? 'case_identifier'
      : displayName
      ? displayName
          .trim()
          .toLowerCase()
          .replace(/[\s?¡¿!*:,.-/()[\]]+/g, '_')
          .replace(/[àáâä]/gi, 'a')
          .replace(/[èéêë]/gi, 'e')
          .replace(/[ìíîï]/gi, 'i')
          .replace(/[òóôö]/gi, 'o')
          .replace(/[ùúûü]/gi, 'u')
      : this.name || undefined;
  }

  toParseObjectJSON(): IParseObjectField {
    if (!this.name) {
      throw new Error('name is not defined');
    }
    return {
      type: this.type,
      name: this.name,
      details: this.details,
      required: this.required,
      encrypted: this.encrypted,
    };
  }
}
