import { Entity, SoftDeletable } from "./entity";

export interface RDT {
  id: string;
  responses: number;
  name: string;
  type: string;
  label: string;
}

export interface ResourceAttributes extends Entity, SoftDeletable {
  name: string;
  type: ResourceTypes;
  resources: Resource[];
  version: string;
  description: string;
  value: string;
  metadata?: ResourceMetadata;
  displayName?: any; // TODO: model
}

export enum ResourceTypes {
  mask = "mask",
  image = "image",
  response = "response",
  tflite = "tflite",
  tflite_config = "tflite_config",
}

export interface ResourceMetadata {
  [key: string]: any;
  label: string;
}

export class Resource {
  constructor(private attributes: ResourceAttributes) { }

  public get id(): string {
    return this.attributes.id;
  }

  public get name(): string {
    return this.attributes.name;
  }

  public get type(): ResourceTypes {
    return this.attributes.type;
  }

  public set type(value: ResourceTypes) {
    this.attributes.type = value;
  }

  public get resources(): Resource[] {
    return this.attributes.resources;
  }

  public set resources(value: Resource[]) {
    this.attributes.resources = value;
  }

  public get version(): string {
    return this.attributes.version;
  }

  public get description(): string {
    return this.attributes.description;
  }

  public get value(): string {
    return this.attributes.value;
  }

  public get metadata(): ResourceMetadata {
    return this.attributes.metadata;
  }

  public set metadata(value: ResourceMetadata) {
    this.attributes.metadata = value;
  }

  public get displayName(): string {
    return this.attributes.displayName;
  }

  public set displayName(value: string) {
    this.attributes.displayName = value;
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }

  public get deletedAt(): Date {
    return this.attributes.deletedAt;
  }

  public addResources(resources: Resource | Resource[]) {
    if (this.type === ResourceTypes.response) {
      throw new Error(
        "Can't add resources to a response type! " + JSON.stringify(this)
      );
    }

    if (!Array.isArray(resources)) {
      resources = [resources];
    }

    for (const resource of resources) {
      if (!this.resources.some((res) => res.id === resource.id)) {
        this.resources.push(resource);
      }
    }
  }

  public modifyMissingResourceFields(mask: any, type: string): Resource {
    const metadata = {
      ...(this.metadata || {}),
      label: mask.value,
    };
    this.type = ResourceTypes[type];
    this.displayName = mask.display_name;
    this.metadata = metadata; // correct all udefined values

    return this;
  }
}
