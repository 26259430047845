<mat-toolbar
  class="my-nav telespot"
  *ngIf="{ mobile: mobile$ | async, user: currentUser$ | async, organization: currentOrganization$ | async } as state"
>
  <span class="navbar-brand">
    <ts-nav-bar-organization></ts-nav-bar-organization>
  </span>

  <span *ngIf="showLogo" class="navbar-brand" [matTooltip]="version">
    <img id="logo" alt="Spotlab logo" src="assets/media/logo/spotlab_logo.svg" />
  </span>

  <button mat-icon-button data-cy="side-menu-toggler" (click)="sidenav.toggle()" class="ms-auto" *ngIf="state.mobile">
    <i class="ri-menu-line"></i>
  </button>

  <ng-container *ngIf="!state.mobile">
    <nav class="flex-row align-items-center" style="width: 100%">
      <ng-container *ngIf="state.user">
        <ng-container *ngFor="let link of links">
          <ng-container
            *ngIfRole="link.roles; features: link.name === 'title.acquisition' ? 'acquisition' : []"
            [ngTemplateOutlet]="linkItemTemplate"
            [ngTemplateOutletContext]="{ $implicit: link }"
          ></ng-container>

          <ng-template #linkItemTemplate let-link>
            <span class="nav-item" routerLinkActive="active-navlink">
              <a
                class="nav-link flex-column align-items-center justify-content-center"
                [class.disabled]="link.disabled"
                [routerLink]="link.routerLink"
                [matTooltip]="link.tooltip | translate"
              >
                <i class="{{ link.icon }}" *ngIf="useIcons"></i>
                <span class="nav-link__title">{{ link.name | translate }}</span>
              </a>
            </span>
          </ng-template>
        </ng-container>
      </ng-container>

      <span class="ms-auto"></span>

      <span *ngIf="state.user" routerLinkActive="active-navlink" class="nav-item ms-auto nav-user">
        <a
          class="nav-link flex-column align-items-center justify-content-center nav-link-user"
          routerLink="me"
          style="font-size: medium"
        >
          <b>{{ state.user.username | uppercase }}</b>
        </a>
      </span>
      <span class="nav-item language-select-trigger">
        <ts-language-selector></ts-language-selector>
      </span>
      <span class="nav-item nav-logout" *ngIf="state.user">
        <a
          class="nav-link flex-column align-items-center justify-content-center nav-link-logout"
          [routerLink]="['logout']"
          matTooltip="{{ 'button.logout' | translate }}"
        >
          <i class="ri-logout-box-line"></i>
        </a>
      </span>
      <span *ngIf="!state.user" class="nav-item nav-login">
        <a class="nav-link flex-column align-items-center justify-content-center" [routerLink]="['login']">{{
          'button.login' | translate | uppercase
        }}</a>
      </span>
    </nav>
  </ng-container>
</mat-toolbar>
