import { MatDialogConfig } from "@angular/material/dialog";

import { ConfirmDialogConfig } from "./confirm-dialog-config";

export const PRESETS: { [key: string]: MatDialogConfig<ConfirmDialogConfig> } =
  {
    DIALOG_EXIT_WITHOUT_SAVING: {
      data: {
        title: "",
        text: "info.exit_without_saving_prompt",
        cancelButtonText: "button.cancel",
        acceptButtonText: "button.save",
        dontSaveButtonText: "button.discard_changes",
        defaultAction: "accept",
      },
      disableClose: true,
      hasBackdrop: true,
    } as const,
    DIALOG_EXIT_WITHOUT_SAVING_NO_SAVE: {
      data: {
        text: "info.exit_without_saving_prompt",
        cancelButtonText: "button.cancel",
        dontSaveButtonText: "button.exit_without_save",
      },
      disableClose: true,
      hasBackdrop: true,
    } as const,
    DIALOG_CHANGE_ANALYSIS_MODE_WITHOUT_SAVING: {
      data: {
        title: "",
        text: "info.change_mode_without_saving_prompt",
        cancelButtonText: "button.cancel",
        acceptButtonText: "button.save",
        dontSaveButtonText: "button.continue_without_saving",
        defaultAction: "accept",
      },
      disableClose: true,
      hasBackdrop: true,
    } as const,
    DIALOG_CHANGE_ANALYSIS_MODE_WITHOUT_SAVING_AND_UNLABELED: {
      data: {
        title: "",
        text: "info.change_mode_without_saving_with_unlabeled_regions",
        cancelButtonText: "button.cancel",
        acceptButtonText: "button.save",
        dontSaveButtonText: "button.continue_without_saving",
        defaultAction: "accept",
      },
      disableClose: true,
      hasBackdrop: true,
    } as const,
    DIALOG_UNLABELED_ROIS: {
      data: {
        title: "",
        text: "info.change_mode_with_unlabeled_regions",
        cancelButtonText: "button.cancel",
        acceptButtonText: "button.continue",
        defaultAction: "accept",
      },
      disableClose: true,
      hasBackdrop: true,
    } as const,
    DIALOG_REVIEW_SAMPLE: {
      data: {
        title: "",
        text: "info.exit_mosaic_mode",
        cancelButtonText: "button.exit",
        acceptButtonText: "button.review",
        defaultAction: "accept",
      },
      disableClose: true,
      hasBackdrop: true,
    } as const,
    DIALOG_SAVING_SECOND_OPINION: {
      data: {
        title: "",
        text: "info.saving_dialog_second_opinion",
        cancelButtonText: "button.cancel",
        acceptButtonText: "button.send",
        defaultAction: "accept",
      },
      disableClose: true,
      hasBackdrop: true,
    } as const,
    DIALOG_SAVE_EMPTY_CASE: {
      data: {
        title: "info.new_case",
        text: "info.saving_case_without_assets",
        acceptButtonText: "button.continue",
        cancelButtonText: "button.cancel",
      },
      disableClose: true,
      hasBackdrop: true,
    } as const,
    DIALOG_REMOVE_UNSAVED_SAMPLE: {
      data: {
        title: "dialog.remove_unsaved_sample",
        acceptButtonText: "button.delete",
        cancelButtonText: "button.cancel",
      },
      disableClose: true,
      hasBackdrop: true,
    } as const,
    DIALOG_SAVE_ASSET_DATA: {
      data: {
        title: "",
        text: "info.save_asset_data",
        cancelButtonText: "button.cancel",
        acceptButtonText: "button.accept",
        defaultAction: "accept",
      },
      disableClose: true,
      hasBackdrop: true,
    } as const,
  } as const;
