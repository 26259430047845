import { Component, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ts-export-report-dialog',
  templateUrl: './export-report-dialog.component.html',
  styleUrls: ['./export-report-dialog.component.scss'],
})
export class ExportReportDialogComponent {
  constructor(@Optional() private dialogRef: MatDialogRef<ExportReportDialogComponent>) {}

  selectCSV() {
    this.dialogRef?.close('csv');
  }

  selectPDF() {
    this.dialogRef?.close('pdf');
  }

  cancel() {
    this.dialogRef?.close();
  }
}
