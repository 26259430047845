import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  DataTableConfig,
  FilterConfig,
  SplDataSource,
  TABLE_FILTERS,
} from "@shared/ui";
import {
  TiraspotUtils,
  decrypt,
  decryptCases,
  loadCases,
  selectDecryptedCases,
  selectdecrypted,
  setCurrWorkspace,
} from "@telespot/analysis-refactor/data-access";
import { AuthService, DataService } from "@telespot/web-core";
import {
  Asset,
  Case,
  Query,
  Sample,
  SampleAsset,
  Workspace,
} from "@telespot/sdk";
import { defer } from "rxjs";
import { switchMap } from "rxjs/operators";

import { TiraspotRowData } from "./tiraspot-row-data";

@Component({
  selector: "ts-cases-list-tiraspot",
  templateUrl: "./cases-list-tiraspot.component.html",
  styleUrls: ["./cases-list-tiraspot.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CasesListTiraspotComponent implements OnInit {
  private _workspace: Workspace;

  public isAdmin: boolean;
  public decrypt: boolean;
  public decryptedCases: any[] = [];

  public tableConfig: DataTableConfig<TiraspotRowData>;

  public dataSource = new SplDataSource<TiraspotRowData>({
    updateFcn: async (sorts, filters: FilterConfig[], skip, limit) => {
      const casesQuery = new Query(Case)
        .equalTo("workspace", this._workspace)
        .select("name");
      // Case name filtering
      const caseNameFilter = filters.find((f) => f.name === "name");
      if (caseNameFilter) {
        // Transform query for encrypted workspaces
        if (this._workspace.caseType.hasEncryptedIdentifier) {
          caseNameFilter.constraints.equalTo =
            caseNameFilter.constraints.contains ??
            caseNameFilter.constraints.equalTo;
        }

        if (caseNameFilter.constraints.equalTo) {
          casesQuery.equalTo(
            "organization",
            this.authService.currentOrganizationValue
          );
          casesQuery.equalTo("name", caseNameFilter.constraints.equalTo);
        } else if (caseNameFilter.constraints.contains) {
          casesQuery.contains(
            "name",
            caseNameFilter.constraints.contains as string
          );
        }
      }

      const caseAcquisitionFilter = filters.find(
        (filter) => filter.name === "createdAt"
      );
      if (caseAcquisitionFilter) {
        if (caseAcquisitionFilter.constraints.before) {
          casesQuery.lessThan(
            "createdAt",
            caseAcquisitionFilter.constraints.before
          );
        }
        if (caseAcquisitionFilter.constraints.after) {
          casesQuery.greaterThanOrEqualTo(
            "createdAt",
            caseAcquisitionFilter.constraints.after
          );
        }
      }

      const sampleQuery = new Query(Sample);
      if (this._workspace.caseType.hasEncryptedIdentifier && caseNameFilter) {
        const matchingCases = await this.dataService.findAll(casesQuery);
        sampleQuery.containedIn("case", matchingCases);
      } else {
        sampleQuery.matchesQuery("case", casesQuery);
      }

      const sampleAssetQuery = new Query(SampleAsset)
        .include(["sample.case.createdBy", "asset"])
        .descending("dateOfCapture")
        .matchesQuery("sample", sampleQuery);

      if (limit) {
        sampleAssetQuery.limit(limit);
      }
      if (skip) {
        sampleAssetQuery.skip(skip);
      }

      // Apply SampleAsset/Asset filters
      const testFilter = filters.find((filter) => filter.name === "test");
      const matchQueries: Query<Asset>[] = [];
      if (testFilter) {
        if (testFilter.constraints.equalTo) {
          matchQueries.push(
            new Query(Asset).equalTo(
              "data.tiraspot.poct",
              testFilter.constraints.equalTo
            )
          );
        }
        if (testFilter.constraints.containedIn?.length) {
          matchQueries.push(
            new Query(Asset).containedIn(
              "data.tiraspot.poct",
              testFilter.constraints.containedIn
                .filter((option) => option.active)
                .map((option) => option.value)
            )
          );
        }
      }

      const resultFilter = filters.find((filter) => filter.name === "result");
      if (resultFilter) {
        const results: string[] = resultFilter.constraints.containedIn
          .filter((option) => option.active)
          .map((option) => option.value)
          .reduce<string[]>(
            (validValues, value) => [
              ...validValues,
              ...(value instanceof Array ? value : [value]),
            ],
            []
          );
        const queries: Query<Asset>[] = [];
        const disagreementFilter = resultFilter.constraints?.containedIn.find(
          (option) => option.active && option.value === "disagreement"
        );
        if (disagreementFilter) {
          queries.push(
            new Query(Asset)
              .exists("data.tiraspot.ml_result.ml_output_label")
              .doesNotExist("data.tiraspot.final_POCT_result"),
            new Query(Asset)
              .exists("data.tiraspot.ml_result.ml_output_label")
              .equalTo("data.tiraspot.final_POCT_result", "")
          );
        }
        queries.push(
          new Query(Asset).containedIn(
            "data.tiraspot.final_POCT_result",
            results
          ),
          new Query(Asset)
            .equalTo("data.tiraspot.final_POCT_result", "")
            .containedIn("data.tiraspot.result", results),
          new Query(Asset)
            .doesNotExist("data.tiraspot.final_POCT_result")
            .containedIn("data.tiraspot.result", results)
        );
        matchQueries.push(Query.or(...queries));
      }
      if (matchQueries.length) {
        sampleAssetQuery.matchesQuery("asset", Query.and(...matchQueries));
      }

      for (const sortKey in sorts) {
        if (sortKey === "createdAt") {
          if (sorts[sortKey] === "asc") {
            sampleAssetQuery.ascending("createdAt");
          }
          if (sorts[sortKey] === "desc") {
            sampleAssetQuery.descending("createdAt");
          }
        }
      }

      const items = defer(() => this.dataService.find(sampleAssetQuery)).pipe(
        switchMap(async (sampleAssets) => {
          const cases = sampleAssets?.map((item) => {
            return item?.sample?.case;
          });

          this.store.dispatch(
            loadCases({
              cases: cases?.map((c) => c.toJSON()),
              currentUserId: this.authService.currentUser.id,
            })
          );
          return sampleAssets.map<TiraspotRowData>((sampleAsset) => ({
            sampleAsset,
            createdBy: sampleAsset?.sample?.case.createdBy,
            warning: TiraspotUtils.warningAI(sampleAsset)
              ? (sampleAsset.asset.data?.tiraspot?.final_POCT_result ?? "") ===
                ""
                ? "disagreement"
                : "corrected"
              : undefined,
          }));
        })
      );

      return {
        items,
        count: defer(() => this.dataService.count(sampleAssetQuery)),
      };
    },
  });

  showWarnings = true;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private store: Store
  ) {}

  public getTiraspotAnswerStyle(answer: string) {
    return `poct-result--${TiraspotUtils.getTiraspotAnswerStyle(answer).style}`;
  }

  public getTiraspotAnswerStyleConfig(answer: string) {
    return TiraspotUtils.getTiraspotAnswerStyle(answer);
  }

  async ngOnInit(): Promise<void> {
    this._workspace = this.route.parent.snapshot.data.workspace;
    this.store.dispatch(
      setCurrWorkspace({
        id: this._workspace.id,
      })
    );
    this.store.select(selectdecrypted).subscribe((decrypt) => {
      this.decrypt = decrypt;
    });

    this.store.select(selectDecryptedCases).subscribe((cases) => {
      this.decryptedCases = cases;
    });
    this.configureTable();
    this.isAdmin = await this.checkUserRole();
  }

  private configureTable(): void {
    const pocts = TiraspotUtils.getTestFilterOptionsFrom(
      this._workspace.caseType
    );
    const results: any[] = TiraspotUtils.getAnswerFilterOptionsFrom(
      this._workspace.caseType
    );

    this.tableConfig = {
      columns: [
        {
          name: "name",
          displayName: "core.identifier",
          value: (item) =>
            !this._workspace.caseType.hasEncryptedIdentifier ||
            this.authService.currentUser.id ===
              item.sampleAsset.sample?.case.createdBy?.id
              ? item.sampleAsset.sample?.case?.name
              : this.decrypt
              ? this.decryptedCases?.find(
                  (decryptedcase) =>
                    item.sampleAsset.sample?.case.id === decryptedcase.objectId
                )?.name || item.sampleAsset.sample?.case?.id
              : item.sampleAsset.sample?.case?.id,
          pinnable: true,
        },
        {
          name: "created_at",
          displayName: "created_at",
          value: (item) => ({
            user: item.createdBy,
            date: item.sampleAsset.sample?.case?.createdAt,
          }),
          filters: [
            {
              name: "createdAt",
              displayName: "< 1m",
              constraints: TABLE_FILTERS.GetRelativeDataRange({
                afterMonths: 1,
              }),
            },
            {
              name: "createdAt",
              displayName: "1 to 3 months",
              constraints: TABLE_FILTERS.GetRelativeDataRange({
                afterMonths: 3,
                beforeMonths: 1,
              }),
            },
            {
              name: "createdAt",
              displayName: "3 to 6 months",
              constraints: TABLE_FILTERS.GetRelativeDataRange({
                afterMonths: 6,
                beforeMonths: 3,
              }),
            },
            {
              name: "createdAt",
              displayName: "> 6 months",
              constraints: TABLE_FILTERS.GetRelativeDataRange({
                beforeMonths: 6,
              }),
            },
          ],
          sortKey: "createdAt",
          sortable: true,
        },
        {
          name: "test",
          displayName: "Test",
          value: (item) => item.sampleAsset.asset.data?.tiraspot?.["poct"],
          filters: [
            {
              name: "test",
              displayName: (filter) =>
                `Test: [${filter.constraints?.containedIn
                  ?.filter((option) => option.active)
                  .map((option) => option.displayName)
                  .join(", ")}]`,
              constraints: {
                containedIn: pocts.map((value) => ({
                  value,
                  displayName: value,
                })),
              },
            },
          ],
          pinnable: true,
        },
        {
          name: "result",
          displayName: "Result",
          value: (item) =>
            TiraspotUtils.getTiraspotAnswer(
              (item.sampleAsset.asset.data?.tiraspot?.final_POCT_result?.length
                ? item.sampleAsset.asset.data?.tiraspot?.final_POCT_result
                : undefined) ?? item.sampleAsset.asset?.data?.tiraspot?.result
            ),
          filters: [
            {
              name: "result",
              displayName: "Result",
              constraints: {
                containedIn: results,
              },
            },
          ],
        },
      ],
      showPaginator: true,
      showSearch: true,
      showFilters: true,
      encryption: this._workspace.caseType.hasEncryptedIdentifier,
      rowClass: (item) =>
        this.showWarnings
          ? { corrected: "mat-row--primary", disagreement: "mat-row--warn" }[
              item.warning
            ]
          : undefined,
      showMenu: true,
      url: (item) => ["/analyze", "cases", item.sampleAsset.sample?.case?.id],
      useQueryParams: true,
    };
  }

  toggleAIWarnings() {
    this.showWarnings = !this.showWarnings;
    this.configureTable();
  }

  private async checkUserRole(): Promise<boolean> {
    const userRoles = await this.authService.getUserRoles(
      this.authService.currentUser
    );
    const userRoleNames = userRoles.map((userRole) => userRole.attributes.name);
    if (userRoleNames.includes("admin")) return true;
    return false;
  }
  public handleDecryptionEvent(event: boolean) {
    if (event) {
      this.store.dispatch(decryptCases());
      this.dataSource.fetch();
    } else {
      this.store.dispatch(decrypt({ decrypted: false }));
    }
  }
}
