import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { DataService } from "@telespot/web-core";
import { AIModel, Query, TAnalysisModelItem } from "@telespot/sdk";
import { defer, Observable } from "rxjs";

@Component({
  selector: "ts-analysis-option-editor",
  templateUrl: "./analysis-option-editor.component.html",
  styleUrls: ["./analysis-option-editor.component.scss"],
  // changeDetection : ChangeDetectionStrategy.OnPush
})
export class AnalysisOptionEditorComponent {
  private _option: TAnalysisModelItem;
  @Input()
  set option(option: TAnalysisModelItem) {
    this._option = option;
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(option ? option.name || "name" : "name", [
        Validators.required,
        Validators.pattern(/^[^.$]+$/),
      ]),
      displayName: new UntypedFormControl(
        option ? option.displayName || "Name" : "Name"
      ),
      color: new UntypedFormControl(option?.color),
    });
  }
  get option() {
    return this._option;
  }

  @Output() submitted: EventEmitter<TAnalysisModelItem> = new EventEmitter();

  form: UntypedFormGroup;

  constructor(private dataService: DataService) {}

  submit() {
    this.option.name = this.form.value.name;
    this.option.displayName = this.form.value.displayName;
    this.option.color = this.form.value.color;
    this.submitted.emit(this.option);
  }

  cancel() {
    this.submitted.emit(undefined);
  }

  compareModelName = (val1: AIModel, val2: string) => val1.modelName === val2;
  displayWithName = (val: AIModel) => val.name;

  getModelsSummary(): string {
    return (this.form.value.ai_models || [])
      .map((model) => model.name)
      .join(", ");
  }
}
