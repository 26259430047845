<div class="header" data-cy="confirmation-dialog-title">
  <h3 mat-dialog-title>
    {{ acceptMessage | translate }}
  </h3>
</div>
<mat-dialog-content>
  <div
    class="workspace-container"
    *ngIf="{
      workspaces: data.compatibleWorkspaces | async,
      loading: data.loading | async
    } as state"
  >
    <div *ngFor="let workspace of state.workspaces">
      <label
        class="workspace-option"
        [ngStyle]="
          hoveredWorkspaceId === workspace.id ||
          selectedWorkspaceId === workspace.id
            ? { 'background-color': '#86dcef', color: 'white' }
            : {}
        "
        (mouseenter)="hoveredWorkspaceId = workspace.id"
        (mouseleave)="hoveredWorkspaceId = null"
      >
        <input
          type="radio"
          [value]="workspace.id"
          [(ngModel)]="selectedWorkspaceId"
          class="workspace-radio"
        />
        <span class="flex-row align-items-center"
          ><i class="ri-folder-fill"></i>{{ workspace.name }}</span
        >
      </label>
    </div>
    <div
      class="flex-row align-items-center overflow-hidden"
      *ngIf="state.loading"
    >
      <i class="ri-refresh-line spin" style="margin: auto; flex: 0 2 auto"></i>
    </div>

    <div
      class="flex-row align-items-center overflow-hidden"
      *ngIf="!state.loading && !state.workspaces.length"
    >
      {{ "text.no_compatible_workspaces" | translate }}
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="custom-dialog-actions justify-content-around">
  <button
    #btnCancel
    data-cy="dialog-button-cancel"
    mat-dialog-close
    class="btn m-2 flex-grow-1 btn-danger"
    focused
    (click)="cancel()"
  >
    {{ "button.cancel" | translate | uppercase }}
  </button>
  <button
    #btnAccept
    data-cy="dialog-button-accept"
    mat-dialog-close
    [disabled]="
      (data.compatibleWorkspaces | async).length < 1 || !selectedWorkspaceId
    "
    class="btn m-2 flex-grow-1 btn-primary"
    (click)="accept(selectedWorkspaceId)"
    color="primary"
  >
    {{ acceptMessage | translate | uppercase }}
  </button>
</mat-dialog-actions>
