import { ParseObject } from '@telespot/sdk';

export class TableFilter {
  public readonly name: string;
  public readonly _displayName: string | ((item: TableFilter) => string);
  public readonly column: string;
  private _active: boolean;
  public readonly constraints: FilterConstraints;
  constructor(config: FilterConfig) {
    this.name = config.name;
    this._displayName = config.displayName;
    this.column = config.column;
    this.constraints = config.constraints;
  }
  set active(active: boolean) {
    this._active = active;
  }
  get active() {
    return this._active;
  }
  get displayName() {
    return this._displayName instanceof Function ? this._displayName(this) : (this._displayName as string);
  }
  setActive(active: boolean): TableFilter {
    this._active = active;
    if (!active) {
      this.constraints.containedIn = this.constraints.containedIn?.map((option) => ({ ...option, active: false }));
    }
    return this;
  }
  get activeOptions(): string[] {
    return this.constraints?.containedIn
      ?.filter((filterOption) => filterOption.active)
      .map((filterOption) => filterOption.displayName);
  }
}

export interface FilterConfig {
  name: string;
  displayName?: string | ((item: FilterConfig) => string);
  active?: boolean;
  /**
   * Column that should display this filter
   *
   * @type {boolean}
   * @memberof FilterConfig
   */
  column?: string;
  constraints?: FilterConstraints;
}

export interface FilterContainedInConstraint {
  value: string | string[];
  displayName?: string;
  active?: boolean;
}

interface FilterConstraints {
  before?: Date;
  after?: Date;
  equalTo?: string | number | boolean | ParseObject;
  contains?: string | number | boolean | ParseObject;
  containedIn?: FilterContainedInConstraint[];
}
