import { Label, LabelRepository } from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";
import { LabelTopology, ParseLabelMapper } from "./parse-label.mapper";

export class ParseLabelRepository
  extends ParseBaseRepository
  implements LabelRepository
{
  private readonly labelMapper = new ParseLabelMapper(this.parse);

  public async *getLabelsFromUuids(
    uuids: string[],
    lang?: string
  ): AsyncGenerator<Label[]> {
    const query = new this.parse.Query(LabelTopology.TABLE).containedIn(
      LabelTopology.UUID,
      uuids
    );

    if (lang) query.equalTo(LabelTopology.LANG, lang);

    const generator = this.getGeneratorFromQuery(query, 100, 0);

    for await (const items of generator) {
      yield items.map((l) => this.labelMapper.toDomain(l));
    }
  }
}
