import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import * as ProtocolActions from "./protocol.actions";
import { SampleAnalysisService } from "../../services/sample-analysis/sample-analysis.service";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { EMPTY, from, of } from "rxjs";

import { Store } from "@ngrx/store";
import {
  assetAnalysisLoaded,
  setSelectedROIs,
  updateROILabels,
} from "../analysis/analysis.actions";
import {
  selectHasSegmentationTasks,
  selectLabelsArray,
  selectMultipleSelection,
} from "./protocol.selectors";
import { selectActiveSample, selectAsset } from "../../+state";
import { AnalysisLabel } from "../analysis/analysis.reducer";
import { ProtocolService } from "../../services/protocol-service/protocol.service";

@Injectable()
export class ProtocolEffects {
  loadProtocols$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolActions.loadProtocol),
      mergeMap(({ sample }) => {
        const counterFilters = this.protocolService.fetchLabelCounterFilters(
          sample?.case?.caseType?.id
        );

        return this.protocolService.fetchSampleProtocol(sample.id).pipe(
          map((protocols) =>
            ProtocolActions.protocolLoaded({ protocols, counterFilters })
          ),
          catchError((error) =>
            of(
              ProtocolActions.protocolActionError({
                error: `[loadProtocol]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  selectLabelFromROI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSelectedROIs),
      mergeMap(({ rois }) => {
        const firstROILabels =
          rois?.length > 0
            ? rois[0].labels.reduce(
                (acc, label) => [...acc, ...(label as AnalysisLabel).labels],
                []
              )
            : undefined;
        if (!firstROILabels) return EMPTY;
        return of({ labelIds: firstROILabels }).pipe(
          map(({ labelIds }) =>
            ProtocolActions.selectMultipleLabelIds({ labelIds })
          ),
          catchError((error) =>
            of(
              ProtocolActions.protocolActionError({
                error: `[setSelectedROIs]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );
  updateROILabels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolActions.selectLabel),
      withLatestFrom(
        this.store$.select(selectMultipleSelection),
        this.store$.select(selectLabelsArray),
        this.store$.select(selectAsset),
        this.store$.select(selectHasSegmentationTasks)
      ),
      mergeMap(
        ([
          action,
          multiSelect,
          labelList,
          activeAsset,
          hasSegmentationTasks,
        ]) => {
          if (hasSegmentationTasks) return EMPTY;
          const label = {
            activeAssetId: activeAsset.id,
            analysisTypeId: labelList.find(
              (label) =>
                label?.category === action.task &&
                label?.value === action.option
            ).analysisTypeId,
            labels: [`category:${action.task}/value:${action.option}`],
          };
          const authUserId = this.sampleAnalysisService.authUserId();
          return of({
            label,
            replacePrevLabels: !multiSelect,
            authUserId,
          }).pipe(
            map(({ label, replacePrevLabels, authUserId }) =>
              updateROILabels({ label, replacePrevLabels, authUserId })
            ),
            catchError((error) =>
              of(
                ProtocolActions.protocolActionError({
                  error: `[selectLabel]: ${error.message}`,
                })
              )
            )
          );
        }
      )
    )
  );

  assetAnalysisLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assetAnalysisLoaded),
      mergeMap(({ customLabels }) => from(customLabels)),
      map(({ category, value, analysisTypeId }) =>
        ProtocolActions.addCustomLabel({
          task: category,
          option: value,
          analysisTypeId,
        })
      )
    )
  );

  saveCounterFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolActions.updateOptionFromCounter),
      withLatestFrom(this.store$.select(selectActiveSample)),
      mergeMap(([{ option, task }, sample]) => {
        const counterFilters = this.protocolService.setCounterFiltersInStorage(
          task,
          option,
          sample?.case?.caseType?.id
        );
        this.sampleAnalysisService.setCounterFilterQueryParam(counterFilters);
        return of({ option, task }).pipe(
          map((payload) => ProtocolActions.optionForCounterSaved(payload)),
          catchError((error) =>
            of(
              ProtocolActions.protocolActionError({
                error: `[saveCounterFilters]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  setCounterFiltersFromQueryParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolActions.setInitialCounterFilters),
      mergeMap(({ counterFilters, sampleId }) => {
        this.protocolService.setInitialCounterFilters(counterFilters, sampleId);
        return of(
          ProtocolActions.counterFiltersFromParamsSaved({ counterFilters })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private sampleAnalysisService: SampleAnalysisService,
    private protocolService: ProtocolService,
    private store$: Store
  ) {}
}
