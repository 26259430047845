import { RDT } from "@telespot/domain";
import { ResourceTopology } from "./parse-resource.mapper";

export class ParseRDTsMapper {
  public static toView(rdt): RDT {
    return {
      id: rdt.id,
      label: rdt.get(ResourceTopology.METADATA)?.label ?? 'N/A',
      name: rdt.get(ResourceTopology.NAME),
      responses: rdt.get(ResourceTopology.RESOURCES)?.length ?? 0,
      type: rdt.get(ResourceTopology.TYPE),
    };
  }
}
