import { File as ParseFile, Role, User as ParseUser } from 'parse';

import { ParseObject } from '../..';
import { Affiliate } from '../affiliate/affiliate.model';
import { Profile } from './user.profile';

ParseUser.allowCustomUserClass(true);
export class User extends ParseUser {
  static className = '_User';

  /**
   *
   *
   * @private
   * @type {string[]}
   * @memberof User
   */
  private _userRoles: Role[] = [];
  private _userAffiliations: Affiliate[] = [];

  constructor() {
    super();
  }

  // static async logIn(username: string, password: string, options?: SuccessFailureOptions): Promise<User> {
  //   const user = await super.logIn(username, password, options);
  //   return user as User;
  // }

  static fromParseUser(parseUser: ParseUser): User {
    return User.fromJSON({ ...parseUser.toJSON(), className: User.className }, false) as User;
  }

  /**
   *
   *
   * @type {string}
   * @memberof User
   */
  get username(): string {
    return super.getUsername();
  }
  set username(value: string) {
    super.setUsername(value);
  }

  /**
   *
   *
   * @type {string}
   * @memberof User
   */
  get email(): string {
    return super.getEmail();
  }
  set email(newEmail) {
    super.setEmail(newEmail);
  }

  /**
   *
   *
   * @type {Role[]}
   * @memberof User
   */
  get roles(): Role[] {
    return this._userRoles || [];
  }
  set roles(newRoles: Role[]) {
    this._userRoles = newRoles;
  }

  /**
   *
   *
   * @type {Affiliate[]}
   * @memberof User
   */
  get affiliations(): Affiliate[] {
    return this._userAffiliations || [];
  }
  set affiliations(newOrg: Affiliate[]) {
    this._userAffiliations = newOrg;
  }

  set avatar(value: ParseFile) {
    this.set('avatar', value);
  }
  get avatar(): ParseFile {
    return this.get('avatar');
  }

  get profile(): Profile {
    return this.get('profile');
  }

  set profile(value: Profile) {
    this.set('profile', value);
  }

  get displayName(): string {
    return this.profile?.alias ?? this.username;
  }

  get emailVerified(): boolean {
    return this.get('emailVerified');
  }
  get verification_token(): boolean {
    return this.get('verification_token');
  }
  /**
   *
   *
   * @memberof User
   */
  set password(value: string) {
    super.setPassword(value);
  }

  /**
   *
   *
   * @type {string[]}
   * @memberof User
   */
  get roleNames(): string[] {
    return this.roles ? this.roles.map((r) => r.get('name')) : [];
  }

  /**
   *
   *
   * @type {string[]}
   * @memberof User
   */
  get organizationNames(): string[] {
    return this.affiliations ? this.affiliations.map((a) => a.organization.name) : [];
  }

  is(allowedRoles: string[]): boolean {
    return allowedRoles.some((allowed) => this.roles.some((role) => role.get('name') === allowed));
  }

  isOnly(roleName: string): boolean {
    return this.roles.length === 1 && this.roles[0].get('name') === roleName;
  }

  get isAdmin(): boolean {
    return this.is(['admin']);
  }

  get isTechnician(): boolean {
    return this.is(['technician']);
  }
  get isTechnicianManager(): boolean {
    return this.is(['technicianmanager']);
  }

  get isAnalyst(): boolean {
    return this.is(['analyst']);
  }

  get isGuest(): boolean {
    return this.is(['guest']);
  }

  get isOnlyGuest(): boolean {
    return this.isOnly('guest');
  }

  canDelete<T extends ParseObject>(object?: T): boolean {
    return object?.getACL()
      ? object.getACL().getPublicWriteAccess() ||
          object.getACL().getWriteAccess(this) ||
          this.roles.some((role) => object.getACL().getRoleWriteAccess(role))
      : false;
  }

  canUpdate<T extends ParseObject>(object?: T): boolean {
    return object?.getACL()
      ? object.getACL().getPublicWriteAccess() ||
          object.getACL().getWriteAccess(this) ||
          this.roles.some((role) => object.getACL().getRoleWriteAccess(role))
      : false;
  }
}
