import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@telespot/web-core';
import { Asset, IAssetROIWithModels, RoiModel } from '@telespot/sdk';
import { environment } from '@telespot/shared/environment';
import { concat, merge, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { defaultAnalyzeConfig } from '../i-analyze-config.interface';
import { IAssetTile } from '../i-asset-tile.interface';
import { IRoiProvider, IRoiProviderResult } from './i-ai-roi-provider';

@Injectable({
  providedIn: 'root',
})
export class TelespotROIProvider implements IRoiProvider {
  constructor(private _http: HttpClient, private _auth: AuthService) {}

  analyzeAsset(asset: Asset, model: RoiModel): Observable<IRoiProviderResult> {
    return of({
      rois: [{ x: 500, y: 500, w: 64, h: 64, assetId: asset?.id, isAIresult: true }],
      progress: 50,
    });
  }

  analyzeTiles(tiles: IAssetTile[], model: RoiModel): Observable<IRoiProviderResult> {
    const aiModelEndpoint: string = model.ai_models[0];
    const tileSizeRegex = /[0-9]+/gi;
    const modelSizeSubstr = aiModelEndpoint.match(tileSizeRegex);
    let modelSize = Number(modelSizeSubstr[0]);
    const tileSubGridSize = 1;
    if (modelSizeSubstr?.length) {
      modelSize = Number(modelSizeSubstr[0]);
      if (tiles[0].tileSize !== modelSize) {
        if (tiles[0].tileSize % modelSize === 0) {
          console.warn(
            `The specified model "${aiModelEndpoint}" was trained for ${modelSize}x${modelSize}px images, but the input tiles are ${tiles[0].tileSize}x${tiles[0].tileSize}`
          );
          // tileSubGridSize = Math.floor(tiles[0].tileSize / modelSize);
        } else {
          console.warn(
            `The specified model "${aiModelEndpoint}" was trained for ${modelSize}x${modelSize}px images, but the input tiles are ${tiles[0].tileSize}x${tiles[0].tileSize}`
          );
        }
      }
    } else {
      throw `Couldn't determine model size from name "${aiModelEndpoint}"`;
    }
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    const requests = tiles.map((tile) => {
      return this._http
        .get(tile.url, {
          headers: {
            'X-Parse-Session-Token': this._auth.sessionToken,
          },
          responseType: 'blob',
        })
        .pipe(
          switchMap(
            (value) =>
              new Observable<string[]>((observer) => {
                const img = new Image();
                img.src = URL.createObjectURL(value);
                img.onload = (l) => {
                  // TODO: review why the DOCUMENT injection doesn't work
                  const c = document.createElement<'canvas'>('canvas');
                  c.width = modelSize || tile.tileSize;
                  c.height = modelSize || tile.tileSize;
                  const ctx = c.getContext('2d');
                  const instances: string[] = [];
                  for (let subImgX = 0; subImgX < tileSubGridSize; subImgX++) {
                    for (let subImgY = 0; subImgY < tileSubGridSize; subImgY++) {
                      ctx.drawImage(
                        img,
                        subImgX * modelSize,
                        subImgY * modelSize,
                        modelSize,
                        modelSize,
                        0,
                        0,
                        modelSize,
                        modelSize
                      );
                      instances.push(c.toDataURL('image/jpeg', 1).split(',').slice(-1)[0]);
                    }
                  }
                  observer.next(instances);
                  observer.complete();
                };
                img.onerror = (e) => {
                  console.log(`Error loading image for AI`);
                  observer.error(e);
                };
              })
          )
        )
        .pipe(
          switchMap((imageData: string[]) =>
            this._http
              .post(
                environment.external_links.ai.analyze,
                {
                  ...defaultAnalyzeConfig,
                  ...{
                    model: model.ai_models[0],
                    threshold: 0.1,
                  },
                  ...{
                    instances: imageData.map((image) => ({ image })),
                  },
                },
                { headers: headers }
              )
              .pipe(
                map(
                  (response: {
                    predictions: Array<Array<{ x: number; y: number; w: number; h: number; score: number; class?: number }>>;
                  }) => {
                    const rois: IAssetROIWithModels[] = response.predictions.reduce(
                      (allRois: IAssetROIWithModels[], sector, sectorIndex) => {
                        const sectorROIs = sector.map((roi) => ({
                          x: roi.x + tile.x * tile.tileSize + Math.floor(sectorIndex / tileSubGridSize) * modelSize,
                          y: roi.y + tile.y * tile.tileSize + (sectorIndex % tileSubGridSize) * modelSize,
                          w: roi.w,
                          h: roi.h,
                          isAIresult: true,
                          score: roi.score,
                          // REMOVE IN FUTURE (JUST TO PAPER)
                          classAI: roi.class === 1 ? 'Ascaris' : 'Trichuris',
                        }));
                        return [...allRois, ...sectorROIs];
                      },
                      []
                    ) as IAssetROIWithModels[];
                    return {
                      rois,
                      progress: 50,
                    };
                  }
                )
              )
          ),
          catchError(() => of({ rois: [], progress: 50 }))
        );
    });

    // Divide request into chunks
    const chunksArray: Observable<IRoiProviderResult>[] = [];
    while (requests.length > 0) {
      const chunk = merge(
        ...[
          requests.shift(),
          requests.shift(),
          requests.shift(),
          requests.shift(),
          requests.shift(),
          requests.shift(),
        ].filter((r) => r !== undefined)
      );
      chunksArray.push(chunk);
    }

    return concat(...chunksArray);
  }
}
