import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  assetFilters,
  updateAssetFilters,
} from "@telespot/analysis-refactor/data-access";

@Component({
  selector: "ts-asset-filters",
  templateUrl: "./asset-filters.component.html",
  styleUrls: ["./asset-filters.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetFiltersComponent {
  public assetFilters$ = this._store.select(assetFilters);

  constructor(private _store: Store) {}

  public updateFilter(filter: string) {
    this._store.dispatch(updateAssetFilters({ filter }));
  }
}
