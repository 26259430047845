import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import {
  AnalysisUtils,
  TAnalysisModelItem,
  TAnalysisTask,
} from "@telespot/sdk";
import * as ProtocolActions from "./protocol.actions";

export const protocolFeatureKey = "protocol";

export enum Context {
  SAMPLE = "sample",
  ASSET = "asset",
}

export interface ProtocolSpecification {
  id: string;
  name: string;
  displayName: string;
  tasks: ProtocolAnalysisTask[];
  mobile: boolean;
}

export interface ProtocolAnalysisTask extends TAnalysisTask {
  collapsed: boolean;
}

export function getProtocols(state: ProtocolState): ProtocolSpecification[] {
  return Object.values(state.protocols.entities ?? {});
}

export function getAllPossibleCounterFilters(counterFilters: string[]) {
  const sortedFilters = counterFilters.sort();
  const counterFiltersResult = [];
  const generateFilterCombination = (
    currentFilter: string[],
    startIndex: number,
    remainingLength: number
  ) => {
    if (remainingLength === 0) {
      counterFiltersResult.push(currentFilter.join("/"));
      return;
    }

    for (let i = startIndex; i <= sortedFilters.length - remainingLength; i++) {
      const newFilter = [...currentFilter, sortedFilters[i]];
      generateFilterCombination(newFilter, i + 1, remainingLength - 1);
    }
  };

  for (let length = 2; length <= sortedFilters.length; length++) {
    generateFilterCombination([], 0, length);
  }
  return counterFiltersResult;
}

const protocolAdapter = createEntityAdapter<ProtocolSpecification>({
  selectId: (protocol) => protocol.id,
});

export const labelAdapter = createEntityAdapter<Label>({
  selectId: ({ category, value }) => `category:${category}/value:${value}`,
});

// TODO: rename interface
export interface SelectedLabels {
  [key: string]: string[];
}

export interface Label {
  category: string;
  value: string;
  color?: string;
  pinned: boolean;
  removeFromCounter: boolean;
  selected: boolean;
  visible: boolean;
  analysisTypeId: string;
}

export interface ProtocolState {
  protocols: EntityState<ProtocolSpecification>;
  loading: boolean;
  context: Context;
  multiselectEnabled: boolean;
  showPinnedOnly: boolean;
  labels: EntityState<Label>;
  error?: string;
}

export const initialProtocolState: ProtocolState = {
  protocols: protocolAdapter.getInitialState(),
  loading: false,
  context: Context.ASSET,
  multiselectEnabled: false,
  showPinnedOnly: false,
  labels: labelAdapter.getInitialState(),
};

export const protocolReducer = createReducer(
  initialProtocolState,

  on(ProtocolActions.resetProtocolState, () => initialProtocolState),

  on(ProtocolActions.protocolActionError, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProtocolActions.toggleMultiSelect, (state) => {
    const firstSelectedLabelIndex = Object.values(
      state.labels.entities ?? {}
    ).findIndex((label) => label?.selected);
    const firstSelectedLabelId = state.labels.ids[firstSelectedLabelIndex];
    return {
      ...state,
      multiselectEnabled: !state.multiselectEnabled,
      labels: state.multiselectEnabled
        ? labelAdapter.updateMany(
            state.labels.ids.map((id) => ({
              id,
              changes: { selected: id === firstSelectedLabelId },
            })),
            state.labels
          )
        : state?.labels,
    };
  }),

  on(ProtocolActions.selectLabel, (state, { option, task }) => {
    const labelId = `category:${task}/value:${option}`;
    const label = state.labels.entities[labelId];

    const { ids, entities } = state.labels;

    const labelChanges = {
      id: labelId,
      changes: { selected: !label.selected },
    };

    const disableRemainingChanges = (ids as string[])
      .filter((id) => id !== labelId)
      .map((id) => ({
        id,
        changes: { ...entities[id], selected: false },
      }));

    const updatedLabels = state.multiselectEnabled
      ? labelAdapter.updateOne(labelChanges, state.labels)
      : labelAdapter.updateMany(
          [...disableRemainingChanges, labelChanges],
          state.labels
        );

    return {
      ...state,
      labels: updatedLabels,
    };
  }),
  on(ProtocolActions.selectMultipleLabelIds, (state, { labelIds }) => {
    const { ids, entities } = state.labels;

    const labelChanges = labelIds.map((id) => ({
      id,
      changes: { selected: true },
    }));

    const disableRemainingChanges = (ids as string[])
      .filter((id) => !labelIds.includes(id))
      .map((id) => ({
        id,
        changes: { ...entities[id], selected: false },
      }));

    const updatedLabels = labelAdapter.updateMany(
      [...disableRemainingChanges, ...labelChanges],
      state.labels
    );

    return {
      ...state,
      labels: updatedLabels,
      multiselectEnabled:
        labelIds.length > 1 ? true : state?.multiselectEnabled,
    };
  }),

  on(ProtocolActions.loadProtocol, (state) => ({
    ...state,
    loading: true,
  })),

  on(
    ProtocolActions.protocolLoaded,
    (state, { protocols, counterFilters }) => ({
      ...state,
      protocols: protocolAdapter.addMany(protocols, state.protocols),
      labels: labelAdapter.upsertMany(
        AnalysisUtils.flatten(
          protocols
            .map((at) => ({ tasks: [...at.tasks], analysisTypeId: at.id }))
            .map((taskGroup) =>
              AnalysisUtils.flatten(
                taskGroup.tasks
                  .filter(
                    (task) => task.roiSelection || task.type === "segmentation"
                  )
                  .filter(
                    (task) =>
                      task.options !== undefined && task.options !== null
                  )
                  .map((task) =>
                    task.options.map((option) => ({
                      value: option.name,
                      category: task.name,
                      color: option?.color,
                      selected: false,
                      visible: true,
                      pinned: false,
                      removeFromCounter: counterFilters.includes(
                        `category:${task.name}/value:${option.name}`
                      ),
                      analysisTypeId: taskGroup.analysisTypeId,
                    }))
                  )
              )
            )
        ),
        state.labels
      ),
      loading: false,
    })
  ),

  on(ProtocolActions.setContext, (state, { context }) => ({
    ...state,
    context,
  })),

  on(ProtocolActions.togglePinnedOptionsOnly, (state) => ({
    ...state,
    showPinnedOnly: !state.showPinnedOnly,
  })),

  on(ProtocolActions.pinTaskOption, (state, { option, task }) => {
    const labelId = `category:${task}/value:${option}`;
    const label = state.labels.entities[labelId];

    return {
      ...state,
      labels: labelAdapter.updateOne(
        {
          id: labelId,
          changes: {
            pinned: !label.pinned,
          },
        },
        state.labels
      ),
    };
  }),

  on(ProtocolActions.hideLabel, (state, { option, task }) => {
    const labelId = `category:${task}/value:${option}`;
    const label = state.labels.entities[labelId];

    return {
      ...state,
      labels: labelAdapter.updateOne(
        {
          id: labelId,
          changes: {
            visible: !label.visible,
          },
        },
        state.labels
      ),
    };
  }),

  on(
    ProtocolActions.addCustomLabel,
    (state, { option, task, analysisTypeId }) => {
      const protocolTasks = [
        ...(state.protocols.entities[analysisTypeId].tasks ?? []),
      ];
      const taskToModify = protocolTasks.find((t) => t.name === task);

      const shouldNotModify = taskToModify?.options?.some(
        (o) => o.name === option
      );

      if (shouldNotModify) {
        return state;
      }

      const modifiedTasks = protocolTasks.map((t) => {
        if (t.name === taskToModify.name) {
          return {
            ...t,
            options: [
              ...(taskToModify.options as TAnalysisModelItem[]),
              {
                name: option,
                displayName: option,
              },
            ],
          };
        }
        return t;
      });

      const labels = labelAdapter.addOne(
        {
          value: option,
          category: task,
          analysisTypeId,
          pinned: false,
          visible: true,
          removeFromCounter: false,
          selected: false,
        },
        state.labels
      );

      return {
        ...state,
        labels,
        protocols: protocolAdapter.updateOne(
          {
            id: analysisTypeId,
            changes: {
              tasks: modifiedTasks,
            },
          },
          state.protocols
        ),
      };
    }
  ),
  on(
    ProtocolActions.setCollapseOption,
    (state, { task, collapsed, analysisTypeId }) => {
      const protocolTasks = [
        ...(state.protocols.entities[analysisTypeId].tasks ?? []),
      ];
      const modifiedTasks = protocolTasks.map((t) => {
        if (t.name === task) {
          return { ...t, collapsed };
        }
        return t;
      });
      return {
        ...state,
        protocols: protocolAdapter.updateOne(
          {
            id: analysisTypeId,

            changes: {
              tasks: modifiedTasks,
            },
          },
          state.protocols
        ),
      };
    }
  ),

  on(ProtocolActions.clearAllLabels, (state) => {
    return {
      ...state,
      labels: labelAdapter.updateMany(
        state.labels.ids.map((id) => ({
          id,
          changes: { selected: false },
        })),
        state.labels
      ),
    };
  }),
  on(ProtocolActions.optionForCounterSaved, (state, { option, task }) => {
    const labelId = `category:${task}/value:${option}`;
    const label = state.labels.entities[labelId];

    return {
      ...state,
      labels: labelAdapter.updateOne(
        {
          id: labelId,
          changes: {
            removeFromCounter: !label.removeFromCounter,
          },
        },
        state.labels
      ),
    };
  }),
  on(
    ProtocolActions.counterFiltersFromParamsSaved,
    (state, { counterFilters }) => {
      const { ids, entities } = state.labels;
      const labelChanges = counterFilters.map((id) => ({
        id,
        changes: { removeFromCounter: true },
      }));

      const disableRemainingChanges = (ids as string[])
        .filter((id) => !counterFilters.includes(id))
        .map((id) => ({
          id,
          changes: { ...entities[id], removeFromCounter: false },
        }));

      const updatedLabels = labelAdapter.updateMany(
        [...disableRemainingChanges, ...labelChanges],
        state.labels
      );

      return {
        ...state,
        labels: updatedLabels,
      };
    }
  )
);
