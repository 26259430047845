<div class="login-form" *ngIf="loginForm">
  <img src="assets/media/logo/telespot_logo_text.svg" style="width: 100%; margin-bottom: 48px" alt="telespot-logo" />

  <form [formGroup]="loginForm" (submit)="login()">
    <mat-form-field class="full-width" floatLabel="always">
      <mat-label>
        {{ 'core.username_or_email' | translate | uppercase }}
      </mat-label>
      <input matInput autofocus type="email" formControlName="username" data-cy="username" />
    </mat-form-field>

    <mat-form-field class="full-width" floatLabel="always">
      <mat-label>
        {{ 'label.password' | translate | uppercase }}
      </mat-label>
      <input matInput [attr.type]="showPassword ? 'text' : 'password'" formControlName="password" data-cy="password" />
      <button
        type="button"
        mat-icon-button
        matSuffix
        data-cy="btn-toggle-show-password"
        style="margin-left: 4px"
        class="text-primary"
        (click)="toggleShowPassword()"
        [attr.aria-label]="'label.show_hide_password' | translate"
      >
        <i [class.ri-eye-line]="!showPassword" [class.ri-eye-off-line]="showPassword"></i>
      </button>
    </mat-form-field>

    <mat-error *ngIf="formErrors.password" style="text-align: center" data-cy="invalid-password">
      {{ formErrors.password | translate }}
    </mat-error>

    <mat-error *ngIf="formErrors.roles" style="text-align: center" data-cy="invalid-roles">
      {{ formErrors.roles | translate }}
    </mat-error>

    <mat-error *ngIf="formErrors.organizations" style="text-align: center" data-cy="invalid-organizations">
      {{ formErrors.organizations | translate }}
    </mat-error>

    <mat-error *ngIf="formErrors.verification" style="text-align: center" data-cy="unverified-user">
      {{ formErrors.verification | translate }}
    </mat-error>

    <button
      class="btn btn-primary btn-block"
      type="submit"
      [disabled]="!loginForm.valid || state.processing"
      style="margin-top: 20px"
      data-cy="login"
      [attr.aria-label]="'button.login' | translate"
      *ngIf="{ processing: processing$ | async } as state"
    >
      {{ (!state.processing ? 'button.login' : 'label.logging_in') | translate | uppercase }}
    </button>
  </form>
  <div style="text-align: center; margin-top: 20px">
    <a class="btn btn-flat-primary login-action" [routerLink]="['/register']" data-cy="register-with-code">
      {{ 'button.register_with_invite_code' | translate | uppercase }}
    </a>
  </div>
  <div style="text-align: center">
    <a class="btn btn-flat-gray login-action" [routerLink]="['/password-reset']" data-cy="reset-password">
      {{ 'button.forgot_password' | translate | uppercase }}
    </a>
  </div>
</div>
