import { inject, InjectionToken } from '@angular/core';
import { User } from '@telespot/sdk';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth/auth.service';

const currentUserProviderFactory = (authService: AuthService) => {
  return authService.currentUser$;
};

export const CURRENT_USER = new InjectionToken<Observable<User>>('CURRENT_USER', {
  providedIn: 'root',
  factory: () => inject(AuthService).currentUser$,
});

export const currentUserProvider = {
  provide: CURRENT_USER,
  useFactory: currentUserProviderFactory,
  deps: [AuthService],
};
