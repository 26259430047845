import { Directive } from "@angular/core";
import { MasksPluginService } from "@telespot/shared/viewers/data-access";

@Directive({
  selector: "[tsMasksPlugin]",
  exportAs: "masksPlugin",
})
export class MasksPluginDirective {
  constructor(private _plugin: MasksPluginService) {}

  public readonly available$ = this._plugin.available$;
  public readonly masks$ = this._plugin.masks$;
  public readonly show$ = this._plugin.show$;
  public readonly toggleLayers = this._plugin.toggleLayers.bind(this._plugin);
  public readonly toggleMask = this._plugin.toggleMask.bind(this._plugin);
  public readonly opacity$ = this._plugin.opacity$;
  public readonly strokeWidth$ = this._plugin.strokeWidth$;
  public readonly changeOpacity = this._plugin.changeOpacity.bind(this._plugin);
  public readonly changeStrokeWidth = this._plugin.changeStrokeWidth.bind(
    this._plugin
  );
}
