import { IRunWorkflowsHandler, RunWorkflows, RunWorkflowsResponse } from "@telespot/use-cases";

import { ParseBaseRepository } from "../../parse-base.repository";

export class RunWorkflowsHandler extends ParseBaseRepository implements IRunWorkflowsHandler {

  public execute(command: RunWorkflows): Promise<RunWorkflowsResponse> {
    return this.parse.Cloud.run("runWorkflows", command.details);
  }

}
