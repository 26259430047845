import { Injectable, Optional, Provider } from '@angular/core';
import { ANALYTICS_PROVIDER, AnalyticsProvider, AnalyticsService } from '@shared/analytics';
import { AuthService } from '@telespot/web-core';
import { RecorderService } from '@telespot/shared/session-recorder';
import { LoggerService } from '@telespot/shared/logger/feature/util';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
  deps: [AuthService, AnalyticsService],
})
class AuthAnalyticsProvider extends AnalyticsProvider {
  constructor(
    private authService: AuthService,
    private analytics: AnalyticsService,
    private recorderService: RecorderService,
    @Optional() private logger: LoggerService
  ) {
    super();
    this.trackOrganization();
    this.trackUser();
  }

  private trackOrganization() {
    this.authService.currentOrganization$
      .pipe(
        filter(() => this.analytics.isEnabled),
        distinctUntilChanged((org1, org2) => org1?.id === org2?.id),
        takeUntil(this.destroy$)
      )
      .subscribe(async (organization) => {
        await this.analytics.setUserProperties({
          organization: organization?.name,
        });
        await this.analytics.logEvent('organization_change');
      });
  }

  private trackUser() {
    this.authService.currentUser$
      .pipe(
        filter(() => this.recorderService.hasConcent),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        if (user === undefined) return;
        try {
          const id = `${user.affiliations[0].organization.name}_${user.username}`;
          this.recorderService.identify(id, {
            displayName: user.username,
            email: user.email,
          });
          this.logger?.log(`Recorder identified`);
        } catch (err) {
          this.logger?.error(`Recorder identification failed: ${err.message}`);
        }
      });
  }
}

export const AUTH_ANALYTICS: Provider = {
  provide: ANALYTICS_PROVIDER,
  useClass: AuthAnalyticsProvider,
  multi: true,
};
