<div class="main-container">
  <div class="side">
    <ts-user-detail (avatarChange)="updateProfilePicture($event)"></ts-user-detail>
  </div>
  <div class="main flex-column align-items-stretch p-2 mw-100">
    <button class="btn btn-outline-primary align-self-end" (click)="redeemCode()">
      <i class="ri-add-circle-line"></i>
      {{ 'button.redeem_invite_code' | translate | uppercase }}
    </button>

    <ts-organization-selector
      class="flex-grow-1 w-100 mt-2"
      (selectOrganization)="activateOrganization($event)"
      [currentUser]="this.currentUser"
    ></ts-organization-selector>
  </div>
</div>
