import { InjectionToken } from "@angular/core";

import { SupportedLanguage } from "../models/supported-language";

const DEFAULT_SUPPORTED_LANGUAGES: SupportedLanguage[] = [
  {
    value: "en",
    label: "languages.english",
    flag: "/assets/media/flags/en_flag.svg",
  },
  {
    value: "es",
    label: "languages.spanish",
    flag: "/assets/media/flags/es_flag.svg",
  },
  {
    value: "pt",
    label: "languages.portuguese",
    flag: "/assets/media/flags/pt_flag.svg",
  },
  {
    value: "fr",
    label: "languages.french",
    flag: "/assets/media/flags/fr_flag.svg",
  },
];

export const SUPPORTED_LANGUAGES = new InjectionToken<SupportedLanguage[]>(
  "SUPPORTED_LANGUAGES",
  {
    providedIn: "root",
    factory: () => DEFAULT_SUPPORTED_LANGUAGES,
  }
);
