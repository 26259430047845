import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import {
  addDecryptedCases,
  applyCaseFilter,
  caseCopied,
  caseDeleted,
  caseMoved,
  casesActionError,
  casesCounted,
  casesListed,
  casesLoaded,
  changePage,
  compatibleWorkspacesListed,
  copyCase,
  copyFindingsFromSample,
  currWorkspaceLoaded,
  decryptCases,
  deleteCase,
  findingsFromSampleCopied,
  listCompatibleWorkspaces,
  listSampleAnalysts,
  loadCases,
  moveCase,
  refreshCaseList,
  removeCaseFilter,
  sampleAnalystsListed,
  saveCase,
  setCurrWorkspace,
  sortCases,
  totalWorkspaceCasesCounted,
  updateCaseData,
  updateCurrWorkspace,
  updateDecryptedCaseData,
} from "./cases.actions";
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import { EMPTY, from, of } from "rxjs";
import {
  currentUserId,
  selectCases,
  selectCurrentWorkspace,
  selectDecryptedCases,
  selectUndecryptedCases,
  selectWSEncrypted,
  selectWorkspaceAndFilters,
  selectWorkspaceFilterPaginationAndSorts,
  selectdecrypted,
} from "./cases.selectors";
import { Store } from "@ngrx/store";
import { Algorithms, Case, CloudFunctions, User } from "@telespot/sdk";
import { DataService } from "@telespot/web-core";
import { CaseAnalysisService } from "../../services/case-analysis/case-analysis.service";
import { CasesService } from "../../services/case-service/cases.service";
import { FindingService } from "../../services/finding-service/finding.service";

@Injectable()
export class CasesEffects {
  constructor(
    private readonly actions$: Actions,
    private store$: Store,
    private dataService: DataService,
    private caseAnalysisService: CaseAnalysisService,
    private cases: CasesService,
    private findings: FindingService
  ) {}

  /**
   * update current workspace by fetching it on delete or move
   */
  updateCurrentWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(caseMoved, caseDeleted),
      withLatestFrom(this.store$.select(selectCurrentWorkspace)),
      map(([_, workspace]) => updateCurrWorkspace({ id: workspace?.id }))
    )
  );

  listCompatibleWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listCompatibleWorkspaces),
      withLatestFrom(this.store$.select(selectCurrentWorkspace)),
      switchMap(([_, workspace]) =>
        from(this.cases.listCompatibleWorkspaces(workspace)).pipe(
          map((compatibleWorkspaces) =>
            compatibleWorkspacesListed({ compatibleWorkspaces })
          ),
          catchError((error) =>
            of(
              casesActionError({
                error: `[listCompatibleWorkspacesEffect]: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  moveCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(moveCase),
      switchMap(({ id, destination }) =>
        from(this.cases.move(id, destination)).pipe(
          map(() => caseMoved()),
          catchError((error) =>
            of(
              casesActionError({ error: `[moveCaseEffect]: ${error.message}` })
            )
          )
        )
      )
    )
  );

  copyCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(copyCase),
      switchMap(({ id, destination }) =>
        from(this.cases.copy(id, destination)).pipe(
          map(() => caseCopied()),
          catchError((error) =>
            of(
              casesActionError({ error: `[caseCaseEffect]: ${error.message}` })
            )
          )
        )
      )
    )
  );

  copyFindings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(copyFindingsFromSample),
      switchMap(({ sampleId, currUserId, analyst }) =>
        from(this.findings.copyFindings(sampleId, currUserId, analyst)).pipe(
          map((result) =>
            findingsFromSampleCopied({
              findingDetails: result?.findingsInfo ?? [],
              enableMosaicView: result?.enableMosaicView,
            })
          ),
          catchError((error) =>
            of(
              casesActionError({ error: `[caseCaseEffect]: ${error.message}` })
            )
          )
        )
      )
    )
  );

  listSampleAnalysts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listSampleAnalysts),
      withLatestFrom(this.store$.select(currentUserId)),
      switchMap(([{ sample }, currentUserId]) =>
        from(this.caseAnalysisService.fetchsampleAnalysts(sample)).pipe(
          map((sampleAnalysts) => {
            const analysts = sampleAnalysts
              .map((state) => {
                const analyst = state?.user ?? state?.algorithm;
                const entity =
                  analyst.className === "Algorithms" ? "algorithm" : "user";
                return {
                  id: analyst.id,
                  name:
                    entity === "algorithm"
                      ? (analyst as Algorithms)?.name
                      : (analyst as User).username,
                  entity,
                };
              })
              .filter((an) => an.id !== currentUserId);
            return sampleAnalystsListed({ analysts, sampleId: sample.id });
          }),
          catchError((error) =>
            of(
              casesActionError({
                error: `[listSampleAnalystsEffect]: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  deleteCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCase),
      switchMap(({ id }) =>
        from(this.cases.delete(id)).pipe(
          map(() => caseDeleted()),
          catchError((error) =>
            of(
              casesActionError({ error: `[caseCaseEffect]: ${error.message}` })
            )
          )
        )
      )
    )
  );

  listCases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        refreshCaseList,
        currWorkspaceLoaded,
        sortCases,
        applyCaseFilter,
        removeCaseFilter,
        changePage
      ),
      withLatestFrom(
        this.store$.select(selectWorkspaceFilterPaginationAndSorts)
      ),
      switchMap(([_, options]) =>
        !options.workspace
          ? of(casesListed({ caseList: [] }))
          : from(this.cases.list(options)).pipe(
              map((items) => casesListed({ caseList: items })),
              catchError((error) =>
                of(
                  casesActionError({
                    error: `[listCasesEffect]: ${error.message}`,
                  })
                )
              )
            )
      )
    )
  );

  countCases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        refreshCaseList,
        currWorkspaceLoaded,
        applyCaseFilter,
        removeCaseFilter
      ),
      withLatestFrom(this.store$.select(selectWorkspaceAndFilters)),
      switchMap(([_, options]) =>
        from(this.cases.count(options)).pipe(
          map((count) => casesCounted({ count })),
          catchError((error) =>
            of(
              casesActionError({
                error: `[countCasesEffect]: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  countTotalCases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshCaseList, currWorkspaceLoaded),
      withLatestFrom(this.store$.select(selectCurrentWorkspace)),
      switchMap(([_, workspace]) =>
        from(this.cases.count({ workspace: workspace })).pipe(
          map((count) => totalWorkspaceCasesCounted({ count })),
          catchError((error) =>
            of(
              casesActionError({
                error: `[countTotalCasesEffect]: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  caseListed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(casesListed),
      map(({ caseList }) =>
        loadCases({
          cases: caseList?.map((c) => c.case),
          currentUserId: User.current().id,
        })
      ),
      catchError((error) =>
        of(casesActionError({ error: `[casesListedEffect]: ${error.message}` }))
      )
    )
  );

  loadCases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCases),
      withLatestFrom(
        this.store$.select(selectdecrypted),
        this.store$.select(selectWSEncrypted),
        this.store$.select(selectUndecryptedCases),
        this.store$.select(selectDecryptedCases)
      ),
      filter(([{ cases }]) => cases && cases.length > 0),
      switchMap(
        ([
          { cases, currentUserId },
          decrypted,
          wsEncripted,
          undecryptedCases,
          decryptedCases,
        ]) => {
          if (wsEncripted && decrypted) {
            const caseIdsToDecrypt = cases
              ?.filter(
                (c) =>
                  !decryptedCases.find(
                    (decryptedCase) => c.objectId === decryptedCase.objectId
                  ) && c.createdBy?.objectId !== currentUserId
              )
              .map((_case) => _case.objectId);

            if (!caseIdsToDecrypt || caseIdsToDecrypt.length < 1)
              return of(
                casesLoaded({ cases, currentUserId, decryptedCases: [] })
              );
            return from(
              CloudFunctions.decryptCases(caseIdsToDecrypt, false)
            ).pipe(
              map((decryptedCases) => {
                return casesLoaded({
                  cases,
                  currentUserId,
                  decryptedCases:
                    decryptedCases?.map((_case) => _case.toJSON()) ?? [],
                });
              }),
              catchError((error) =>
                of(
                  casesActionError({
                    error: `[loadCasesEffect]: ${error.message}`,
                  })
                )
              )
            );
          }
          const caseIds = cases?.filter(
            (c) =>
              !undecryptedCases.find((_case) => c.objectId === _case.objectId)
          );

          if (!caseIds || caseIds.length < 1)
            return of(
              casesLoaded({ cases, currentUserId, decryptedCases: [] })
            );

          return of(
            casesLoaded({
              cases,
              currentUserId,
              decryptedCases: [],
            })
          );
        }
      )
    )
  );
  decryptCases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(decryptCases),
      withLatestFrom(this.store$.select(selectUndecryptedCases)),
      switchMap(([_, undecryptedCases]) => {
        const ids = undecryptedCases.map((c) => c.objectId);
        return from(CloudFunctions.decryptCases(ids, false)).pipe(
          map((decryptedCases) => {
            return addDecryptedCases({
              decryptedCases:
                decryptedCases?.map((_case) => _case.toJSON()) ?? [],
            });
          }),
          catchError((error) =>
            of(
              casesActionError({
                error: `[decryptCasesEffect]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );
  saveCaseData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveCase),
      withLatestFrom(
        this.store$.select(selectDecryptedCases),
        this.store$.select(selectCases)
      ),
      switchMap(([{ id, data, currentUserId }, decryptedCases, cases]) => {
        const currCase =
          decryptedCases.length > 0
            ? decryptedCases.find((c) => c.objectId === id)
            : cases.find((c) => c.objectId === id);

        const caseToUpdate = Case.fromJSON(
          {
            ...currCase,
            className: "Case",
            objectId: id,
          },
          true
        ) as Case;
        caseToUpdate.data = data;
        if (data["case_identifier"])
          caseToUpdate.name = data["case_identifier"];

        return from(this.dataService.save(caseToUpdate)).pipe(
          mergeMap((_case) => {
            if (decryptedCases.length < 1) {
              return of(updateCaseData({ id, data, currentUserId }));
            }
            return [
              updateCaseData({ id, data, currentUserId }),
              updateDecryptedCaseData({ id, data }),
            ];
          }),
          catchError((error) =>
            of(
              casesActionError({
                error: `[saveCaseData$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );
  setCurrentWS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrWorkspace),
      withLatestFrom(this.store$.select(selectCurrentWorkspace)),
      switchMap(([{ id }, workspace]) => {
        if (id === workspace?.id) return EMPTY;
        return this.caseAnalysisService.getWorkspace(id).pipe(
          map((_workspace) => {
            return currWorkspaceLoaded({ workspace: _workspace.toJSON() });
          }),
          catchError((error) =>
            of(
              casesActionError({
                error: `[setCurrentWS$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );
  updateCurrentWS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCurrWorkspace),
      withLatestFrom(this.store$.select(selectCurrentWorkspace)),
      switchMap(([{ id }, workspace]) => {
        if (id !== workspace?.id) return EMPTY;
        return this.caseAnalysisService.getWorkspace(id).pipe(
          map((_workspace) => {
            return currWorkspaceLoaded({ workspace: _workspace.toJSON() });
          }),
          catchError((error) =>
            of(
              casesActionError({
                error: `[updateCurrentWS$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );
}
