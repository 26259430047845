import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, DataService } from '@telespot/web-core';
import { Affiliate, Member, Query, User, Workspace } from '@telespot/sdk';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ts-workspace-members',
  templateUrl: './workspace-members.component.html',
  styleUrls: ['./workspace-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceMembersComponent implements OnInit, OnDestroy {
  private _update$ = new BehaviorSubject<null>(null);
  public readonly workspace$ = this._route.parent?.paramMap.pipe(
    map((p) => p.get('workspaceId')),
    filter((p) => !!p),
    distinctUntilChanged(),
    switchMap((id) => this._dataService.get(id, new Query(Workspace)))
  );
  public readonly users$: Observable<User[]> = this._authService.currentOrganization$.pipe(
    switchMap((organization) =>
      this._dataService.find(new Query(Affiliate).equalTo('organization', organization).include('user'))
    ),
    map((affiliates) => affiliates.map((affiliate) => affiliate.user).filter((user) => !!user))
  );
  public readonly members$: Observable<Member[]> = combineLatest([this.workspace$, this._update$]).pipe(
    switchMap(([worspace, _]) => this._dataService.find(new Query(Member).equalTo('workspace', worspace).include('user')))
  );
  public readonly availableUsers$: Observable<User[]> = combineLatest([this.users$, this.members$]).pipe(
    map(([users, members]) => {
      return users.filter((u) => !members.some((m) => m.user?.id === u?.id));
    })
  );
  private _workspace: Workspace;

  public readonly showMobileLayout$: Observable<boolean> = this._breakpoint
    .observe([Breakpoints.HandsetPortrait, Breakpoints.XSmall])
    .pipe(map((r) => r.matches));

  private _destroy$ = new Subject<void>();
  constructor(
    private _authService: AuthService,
    private _dataService: DataService,
    private _route: ActivatedRoute,
    private _breakpoint: BreakpointObserver
  ) { }

  ngOnInit() {
    this.workspace$.pipe(takeUntil(this._destroy$)).subscribe((workspace) => (this._workspace = workspace));
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  public async removeMember(member: Member) {
    await this._dataService.delete(member);
    this._update$.next(null);
  }

  public async addMember(user: User) {
    const member = new Member();
    member.user = user;

    member.workspace = this._workspace;
    await this._dataService.save(member);
    this._update$.next(null);
  }
}
