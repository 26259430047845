import type Parse from "parse";

import { CaseType } from "@telespot/domain";

import { ParseMapper } from "../../parse.mapper";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

import { MethodTypeTopology } from "./parse-method-type.mapper";

export enum CaseTypeTopology {
  TABLE = "CaseType",
  NAME = "name",
  FIELDS = "fields",
  METHOD_TYPES = "methodTypes",
}

export class ParseCaseTypeMapper extends ParseMapper<CaseType> {

  public toDomain(parseObject: Parse.Object) {
    return new CaseType({
      id: parseObject.id,
      name: parseObject.get(CaseTypeTopology.NAME),
      fields: parseObject.get(CaseTypeTopology.FIELDS),
      methodTypeIds: parseObject.get(CaseTypeTopology.METHOD_TYPES)?.map(mt => mt.id),
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
      deletedAt: parseObject.get(SoftDeletableTopology.DELETED_AT),
    });
  }

  public fromDomain(caseType: CaseType) {
    const parseMethodTypes = caseType.methodTypeIds
      .map(item => this.subclasses.getSubclass(MethodTypeTopology.TABLE).createWithoutData(item))

    const parseObject = this.parse.Object.fromJSON({
      className: CaseTypeTopology.TABLE,
      [ObjectTopology.ID]: caseType.id,
      [ObjectTopology.CREATED_AT]: caseType.createdAt,
      [ObjectTopology.UPDATED_AT]: caseType.updatedAt,
    });

    parseObject.set({
      [SoftDeletableTopology.DELETED_AT]: caseType.deletedAt,
      [CaseTypeTopology.NAME]: caseType.name,
      [CaseTypeTopology.FIELDS]: caseType.fields,
      [CaseTypeTopology.METHOD_TYPES]: parseMethodTypes,
    })

    return parseObject;
  }
}
