<div class="main-container">
  <div
    class="title"
    *ngIf="{
      workspace: detail.workspace$ | async,
      deletable: detail.canBeDeleted$ | async,
      exporting: exporting$ | async
    } as state"
  >
    <span class="breadcrumbs"
      ><a href="/workspaces">{{ "title.workspaces" | translate }}</a> /
      {{ state.workspace?.name }}</span
    >
    <ng-container *ngIf="state.workspace">
      <ng-container *ngIfRole="['admin']">
        <button
          mat-icon-button
          class="btn btn-flat-danger"
          *ngIf="state.deletable"
          (click)="detail.deleteWorkspace()"
          [matTooltip]="'core.delete' | translate"
          data-cy="button-delete-workspace"
        >
          <i class="ri-delete-bin-6-line"></i>
        </button>
      </ng-container>
      <ng-container
        *ngIf="
          state.workspace.organization.license.features.acquisition_available
            .enabled
        "
      >
        <ng-container
          *ngIfRole="['analystmanager', 'technicianmanager', 'admin']"
        >
          <button
            class="btn btn-primary ms-auto"
            [routerLink]="
              state.workspace.caseType
                ? ['/acquire', 'new', state.workspace.id]
                : []
            "
            data-cy="button-new-workspace"
          >
            <i class="ri-add-circle-line"></i>
            {{ "button.new_case" | translate | uppercase }}
          </button>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="state.workspace.config?.environment === 'tiraspot'">
        <button
          *ngIfRole="
            ['admin', 'analystmanager', 'analyst'];
            features: ['exportWorkspace']
          "
          (click)="selectExportOption(state.workspace)"
          class="btn btn-primary"
          [matTooltip]="'tooltip.export_workspace' | translate"
          data-cy="button-export-workspace"
          #exportButton
        >
          <i class="ri-file-excel-line"></i>
          {{ "button.export_data" | translate }}
        </button>
      </ng-container>
    </ng-container>

    <ng-template #exportModeDialog>
      <div mat-dialog-title style="margin: 0 0 40px">
        <button
          mat-icon-button
          class="ri-close-line text-danger"
          matDialogClose
          (click)="cancelWorkspaceExport()"
          style="float: right"
        ></button>
      </div>
      <mat-dialog-content>
        <div style="text-align: center">
          <button
            class="btn btn-outline-primary card-actions__action"
            data-cy="button-export-all-data"
            id="exportAllButton"
            style="flex-grow: 0"
            (click)="exportWorkspace(state.workspace, false, false)"
          >
            {{ "button.export_all_data" | translate | uppercase }}
          </button>
          <button
            class="btn btn-outline-primary card-actions__action"
            data-cy="button-discrepants-only"
            *ngIf="hasAIprotocol"
            id="discrepantsButton"
            style="flex-grow: 0"
            (click)="exportWorkspace(state.workspace, false, true)"
          >
            {{ "button.export_discrepants" | translate | uppercase }}
          </button>
          <button
            class="btn btn-outline-primary card-actions__action"
            style="flex-grow: 0"
            id="filterButton"
            data-cy="button-filter-data"
            matDialogClose
            (click)="defineFilters()"
          >
            {{ "button.filter_data" | translate | uppercase }}
          </button>
        </div>
      </mat-dialog-content></ng-template
    >
    <ng-template #filtersDialog>
      <div mat-dialog-title style="margin: 25 0 0px">
        <button
          mat-icon-button
          style="float: right"
          class="ri-close-line text-danger"
          matDialogClose
          (click)="cancelWorkspaceExport()"
        ></button>
      </div>
      <mat-dialog-content>
        <form [formGroup]="formGroup" *ngIf="formGroup">
          <h5>{{ "label.filterByTest" | translate | uppercase }}</h5>

          <div formGroupName="testFilter" style="margin-bottom: 35px">
            <h5>{{ "label.filterByTest" | translate | uppercase }}</h5>
            <div *ngFor="let poct of pocts">
              <div>
                <mat-checkbox [formControlName]="poct">{{ poct }}</mat-checkbox>
              </div>
            </div>
          </div>
          <div formGroupName="resultFilter" style="margin-bottom: 35px">
            <h5>{{ "label.filterByResult" | translate | uppercase }}</h5>
            <div *ngFor="let result of results">
              <mat-checkbox [formControlName]="result.displayName">{{
                result.displayName
              }}</mat-checkbox>
            </div>
          </div>

          <div formGroupName="dateFilter" style="margin-bottom: 35px">
            <h5>{{ "label.filterByDate" | translate | uppercase }}</h5>
            <mat-radio-group formControlName="date">
              <div>
                <mat-radio-button value="today">{{
                  "label.date_today" | translate
                }}</mat-radio-button>
              </div>
              <div>
                <mat-radio-button value="last_week">{{
                  "label.date_last_week" | translate
                }}</mat-radio-button>
              </div>
              <div>
                <mat-radio-button value="last_month">{{
                  "label.date_last_month" | translate
                }}</mat-radio-button>
              </div>
              <div>
                <mat-radio-button value="last_6_months">{{
                  "label.date_last_6_months" | translate
                }}</mat-radio-button>
              </div>
              <div>
                <mat-radio-button value="anytime">{{
                  "label.anytime" | translate
                }}</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div style="margin-top: 50px">
            <button
              class="btn btn-outline-primary card-actions__action"
              data-cy="button-cancel-export"
              style="flex-grow: 0"
              matDialogClose
              (click)="cancelWorkspaceExport()"
            >
              {{ "button.cancel" | translate | uppercase }}
            </button>
            <button
              class="btn btn-primary card-actions__action"
              style="flex-grow: 0"
              data-cy="button-export-filtered-data"
              matDialogClose
              (click)="exportWorkspace(state.workspace, true, false)"
            >
              {{ "button.export" | translate | uppercase }}
            </button>
          </div>
        </form>
      </mat-dialog-content></ng-template
    >

    <ng-template #exportingDialog let-data>
      <div mat-dialog-title>
        <div class="flex-row align-items-center justify-content-between">
          <h3>{{ "button.export" | translate }}</h3>
          <button
            mat-icon-button
            class="ri-close-line text-danger"
            matDialogClose
            (click)="cancelWorkspaceExport()"
          ></button>
        </div>
      </div>
      <mat-dialog-content data-cy="workspace-export-dialog">
        <ng-container *ngIf="data | async as data; else processing">
          <ng-container *ngIf="data.download as download; else error">
            <ng-container *ngIf="data.empty === false; else empty">
              <div class="flex-column align-items-center">
                <p>{{ "label.download_ready" | translate }}</p>
                <a
                  [href]="download.link"
                  authHref
                  [download]="download.fileName"
                  target="__blank"
                  class="flex-row align-items-center"
                  matDialogClose
                >
                  <i class="ri-download-2-line"></i>
                  {{ download.fileName }}
                </a>
              </div>
            </ng-container>
            <ng-template #empty>
              <div class="flex-column align-items-center">
                <p>{{ "label.filteredData" | translate }}</p>
              </div>
            </ng-template>
          </ng-container>
          <ng-template #error>
            <div class="flex-column align-items-center">
              <i class="ri-error-warning-line text-danger"></i>
              <p>Error: {{ data.error | translate }}</p>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #processing>
          <mat-progress-bar
            mode="indeterminate"
            class="mb-2"
          ></mat-progress-bar>
          <p>{{ "dialog.exporting_workspace" | translate }}</p>
        </ng-template>
      </mat-dialog-content>
    </ng-template>
  </div>
  <ts-workspace-detail #detail></ts-workspace-detail>
</div>
