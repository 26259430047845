<div class="crop-detail-wrapper">
  <div class="crop-detail-container">
    <button class="close-button" (click)="onClose()">
      <i class="ri-close-line"></i>
    </button>
    <div class="header">
      <span class="title"
        >{{ cropDetail?.currentLabel }} | {{ cropDetail?.cropId }}</span
      >
    </div>
    <ts-image [url]="imageUrl"></ts-image>
    <div class="labels-container" *ngIf="labels.length > 0">
      <div class="label-item" *ngFor="let label of labels">
        <span class="label-name">{{ label.key }}</span>
        <div class="bar-container">
          <div class="bar" [ngStyle]="{ width: label.value }"></div>
        </div>
        <span class="label-value">{{ label.value }}</span>
      </div>
    </div>
  </div>
</div>
