export type TaskTypeName =
  | "boolean"
  | "textedit"
  | "selection"
  | "switcher"
  | "number"
  | "segmentation";

export enum StepAction {
  USER_EXIT = "user:exit",
  USER_CAPTURE = "user:capture",
  ASSET_CREATED = "asset:created",
  SAMPLE_CREATED = "sample:created",
  ACQUISITION_FRAME = "acquisition:frame",
  ACQUISITION_EXIT = "acquisition:exit",
}
export enum StepTask {
  TEXT = "text",
  CLASSIFICATION = "classification",
  ROIDETECTION = "roidetection",
  SEGMENTATION = "segmentation",
  POSITION = "position",
  OCR = "ocr",
}
