import { AnalysisType, AnalysisTypeRepository } from "@telespot/domain";

import { ParseBaseRepository } from "../../parse-base.repository";
import { AnalysisTypeTopology, ParseAnalysisTypeMapper } from "./parse-analysis-type.mapper";

export class ParseAnalysisTypeRepository extends ParseBaseRepository implements AnalysisTypeRepository {

  private readonly mapper = new ParseAnalysisTypeMapper(this.parse);

  public async getById(id: string): Promise<AnalysisType> {
    const reference = await new this.parse.Query(AnalysisTypeTopology.TABLE)
      .get(id, this.options);

    return this.mapper.toDomain(reference);
  }

  public async delete(...ids: string[]): Promise<void> {
    if (ids?.length < 1) return;

    const ParseAnalysisType = this.subclasses.getSubclass(AnalysisTypeTopology.TABLE);

    const parseAnalysisTypes = ids.map(id => ParseAnalysisType.createWithoutData(id));

    await this.parse.Object.destroyAll(parseAnalysisTypes, this.options);
  }

  public async save(analysisType: AnalysisType): Promise<string> {
    const parseObject = this.mapper.fromDomain(analysisType);

    const { id } = await parseObject.save(null, this.options);

    return id;
  }
}
