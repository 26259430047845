import { Object as ParseObject } from 'parse';

import { Asset, Sample } from '../..';

export class SampleAsset extends ParseObject {
  static className = 'SampleAsset';

  constructor({
    asset,
    sample,
    index,
    dateOfCapture,
    tag
  }: { asset?: Asset; sample?: Sample; index?: number; dateOfCapture?: Date; tag?: string } = {}) {
    super(SampleAsset.className);
    this.asset = asset;
    this.sample = sample;
    this.index = index;
    this.dateOfCapture = dateOfCapture;
    this.tag = tag;
  }

  set sample(sample: Sample) {
    this.set('sample', sample);
  }
  get sample(): Sample {
    return this.get('sample');
  }

  set asset(asset: Asset) {
    this.set('asset', asset);
  }
  get asset(): Asset {
    return this.get('asset');
  }

  set index(index: number) {
    this.set('index', index);
  }
  get index(): number {
    return this.get('index');
  }

  set tag(value: string) {
    this.set('tag', value);
  }
  get tag(): string {
    return this.get('tag');
  }

  set dateOfCapture(value: Date) {
    this.set('dateOfCapture', value);
  }
  get dateOfCapture(): Date {
    return this.get('dateOfCapture');
  }
}
