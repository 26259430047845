<div class="main-container">
  <div class="title">
    <h1>
      {{ 'title.users' | translate | uppercase }}
    </h1>
    <button class="btn btn-primary me-2" *ngIfRole="['admin', 'analystmanager']" [routerLink]="['new']">
      <i class="ri-add-circle-line"></i>
      {{ 'button.new_user' | translate | uppercase }}
    </button>
  </div>

  <ts-invites *ngIfRole="['admin']"></ts-invites>

  <div class="card padded">
    <div class="card-body">
      <h2>
        {{ 'core.manage_users' | translate | uppercase }}
      </h2>
      <ul class="list-group list-group-flush">
        <!-- User list -->
        <li
          class="list-group-item flex-column align-items-start justify-content-between"
          *ngFor="let item of affiliates$ | async"
          data-cy="user-row"
        >
          <span class="flex flex-row w-100 justify-content-between mb-2">
            <span [routerLink]="[item.affiliate.user.id, 'edit']" class="flex flex-row align-items-center">
              <span style="flex: 0 1 auto; margin-right: 6px">
                <img
                  class="user-avatar user-avatar--32"
                  [src]="item.affiliate.user.avatar?.url() || '../../../assets/media/user-avatar.png'"
                  alt="user avatar"
                  fallbackSrc="assets/media/user-avatar.png"
                />
              </span>

              <span>
                <div class="flex-row align-items-center">
                  {{ item.affiliate.user?.username }}
                  <i
                    class="ri-mail-line ms-2"
                    *ngIf="!item.affiliate.user.emailVerified"
                    [matTooltip]="'info.email_not_verified' | translate"
                  ></i>
                </div>
              </span>
            </span>
            <div class="button-bar float-right stretch flex-shrink-0" *ngIfRole="['admin', 'analystmanager']">
              <button mat-icon-button class="btn btn-primary" type="button" [routerLink]="[item.affiliate.user.id, 'edit']">
                <i class="ri-pencil-line"></i>
              </button>
              <button class="btn btn-flat-danger btn-auto" type="button" (click)="delete(item.affiliate)">
                <i class="ri-delete-bin-6-line"></i>
              </button>
            </div>
          </span>
          <div class="">
            <ts-role-badge *ngFor="let role of item.roles$ | async" [role]="role">
              {{ role | translate | uppercase }}
            </ts-role-badge>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
