<div class="main-container" *ngIf="assetType">
  <div class="title">
    <span class="breadcrumbs">
      <a href="['protocols', 'assetTypes']">{{ 'title.asset_types' | translate }}</a> /
      {{ assetType.isNew() ? 'new' : assetType.name }}
    </span>
  </div>

  <div class="card">
    <form [formGroup]="form" *ngIf="form">
      <div class="card-body">
        <mat-form-field class="full-width" [floatLabel]="'never'">
          <mat-label>{{ 'label.asset_type.name' | translate | uppercase }}</mat-label>
          <input
            id="asset-type-name"
            matInput
            type="text"
            formControlName="name"
            placeholder="{{ 'label.asset_type.name' | translate }}"
          />
        </mat-form-field>

        <mat-form-field class="full-width" [floatLabel]="'never'">
          <input
            id="asset-type-version"
            matInput
            type="text"
            formControlName="version"
            placeholder="{{ 'label.asset_type.version' | translate }}"
          />
        </mat-form-field>

        <mat-form-field class="full-width" [floatLabel]="'never'">
          <mat-label>{{ 'label.asset_type.description' | translate | uppercase }}</mat-label>
          <textarea
            id="asset-type-description"
            matInput
            formControlName="description"
            placeholder="{{ 'label.asset_type.description' | translate }}"
          ></textarea>
        </mat-form-field>

        <mat-label>{{ 'label.asset_type.description' | translate | uppercase }}</mat-label>
        <mat-radio-group class="full-width" formControlName="type">
          <mat-radio-button id="asset-type-image" [value]="'image'">
            {{ 'core.image' | translate }}
          </mat-radio-button>
          <mat-radio-button id="asset-type-video" [value]="'video'">
            {{ 'core.video' | translate }}
          </mat-radio-button>
        </mat-radio-group>

        <mat-form-field class="full-width" [floatLabel]="'never'">
          <mat-label>{{ 'label.asset_type.device_types' | translate }}</mat-label>
          <mat-select
            formControlName="device_types"
            [multiple]="true"
            placeholder="{{ 'label.asset_type.device_types' | translate }}"
            [compareWith]="sameId"
            id="asset-type-device-types"
          >
            <mat-option *ngFor="let deviceType of deviceTypes$ | async" [value]="deviceType">
              {{ deviceType.name }} v.{{ deviceType.version }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="card-footer card-actions no-hr">
        <button class="btn btn-outline-primary btn-outline--white-bg card-actions__action" type="button" (click)="cancel()">
          <i class="ri-close-fill"></i>
          {{ 'core.cancel' | translate }}
        </button>
        <button class="btn btn-primary card-actions__action" type="submit" (click)="save()">
          <i class="ri-save-line"></i>
          {{ 'core.save' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
