import type Parse from "parse";

import { Affiliate } from "@telespot/domain";
import { ParseUserMapper } from "../accounts";
import { OrganizationTopology } from "./parse-organization.mapper";
import { ParseMapper } from "../../parse.mapper";
import { ObjectTopology } from "../../parse.topology";

export enum AffiliateTopology {
  TABLE = "Affiliate",
  USER = "user",
  ORGANIZATION = "organization",
}

export class ParseAffiliateMapper extends ParseMapper<Affiliate> {

  private readonly userMapper = new ParseUserMapper(this.parse);

  public toDomain(parseObject: Parse.Object): Affiliate {
    return new Affiliate({
      id: parseObject.id,
      user: this.userMapper.toDomain(parseObject.get(AffiliateTopology.USER)),
      organizationId: parseObject.get(AffiliateTopology.ORGANIZATION).id,
      language: parseObject.get(AffiliateTopology.ORGANIZATION).get(OrganizationTopology.LICENSE)?.language ?? "en",
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
    })
  }

  public fromDomain(affiliate: Affiliate): Parse.Object {
    const parseUser = this.parse.User.createWithoutData(affiliate.user.id);

    const parseOrganization = this.subclasses
      .getSubclass(OrganizationTopology.TABLE)
      .createWithoutData(affiliate.organizationId);

    const parseObject = this.parse.Object.fromJSON({
      className: AffiliateTopology.TABLE,
      [ObjectTopology.ID]: affiliate.id,
      [ObjectTopology.CREATED_AT]: affiliate.createdAt,
      [ObjectTopology.UPDATED_AT]: affiliate.updatedAt,
    });

    parseObject.set({
      [AffiliateTopology.USER]: parseUser,
      [AffiliateTopology.ORGANIZATION]: parseOrganization,
    });

    return parseObject;
  }
}
