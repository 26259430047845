import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@telespot/web-core';
import { CloudFunctions } from '@telespot/sdk';
import { User } from 'parse';
import { BehaviorSubject } from 'rxjs';

interface TVerificationData {
  userId: string;
  token: string;
}

const samePasswordValidation: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const password2 = control.get('password2');
  return password?.value !== password2?.value ? { password: true } : null;
};

class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective): boolean {
    return !!(form?.control.controls['password']?.touched && form?.control.controls['password2']?.touched && form?.invalid);
  }
}

@Component({
  selector: 'ts-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit {
  user: User;
  isExisting = false;

  resetPasswordForm: UntypedFormGroup;

  public readonly matcher = new CustomErrorStateMatcher();

  private resetPasswordData: TVerificationData;
  private verifyUserData: TVerificationData;
  mode: 'reset' | 'verify';

  private _backendError$ = new BehaviorSubject<string>('');
  public readonly backendError$ = this._backendError$.asObservable();

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    // this.user = this.authService.currentUser;
    this.resetPasswordData = this.route.snapshot.data['passwordResetData'];
    this.verifyUserData = this.route.snapshot.data['userVerificationData'];

    if (this.resetPasswordData || this.verifyUserData) {
      this.isExisting = true;
    }

    this.mode = this.resetPasswordData ? 'reset' : this.verifyUserData ? 'verify' : undefined;

    this.resetPasswordForm = new UntypedFormGroup(
      {
        email: new UntypedFormControl(this.user !== undefined ? this.user.getEmail() : '', [Validators.email]),
        username: new UntypedFormControl(''),
        password: new UntypedFormControl('', Validators.required),
        password2: new UntypedFormControl('', Validators.required),
      },
      { validators: samePasswordValidation }
    );

    this.isExisting && this.resetPasswordForm.controls['email'].disable();
  }

  async save() {
    if (!this.resetPasswordForm.valid || !this.checkPasswords()) return;

    if (this.isExisting) {
      // Change password
      if (this.verifyUserData) {
        try {
          const res = await CloudFunctions.VerifyUser(
            this.verifyUserData.userId,
            this.verifyUserData.token,
            this.resetPasswordForm.value['password'],
            this.resetPasswordForm.value['username']
          );
          await this.authService.login(res['email'], this.resetPasswordForm.value['password']);
          this.router.navigateByUrl('/me');
        } catch (err) {
          this._backendError$.next(err?.error ?? err.message);
        }
      } else if (this.resetPasswordData) {
        try {
          const res = await CloudFunctions.ResetPassword(
            this.resetPasswordData.userId,
            this.resetPasswordData.token,
            this.resetPasswordForm.value['password']
          );
          await this.authService.login(res['email'], this.resetPasswordForm.value['password']);
          this.router.navigateByUrl('/me');
        } catch (err) {
          this._backendError$.next(err?.error ?? err.message);
        }
      }
    } else {
      // Register user
      const username = this.user.getUsername() || (this.resetPasswordForm.value['email'] as string).split('@')[0];
      this.user.setEmail(this.resetPasswordForm.value['email']);
      this.user.setUsername(username);
      this.user.setPassword(this.resetPasswordForm.value['password']);

      throw new Error('User registration not available yet');
      this.router.navigateByUrl('/me');
    }
  }

  checkPasswords(): boolean {
    const passwordsMatch = this.resetPasswordForm.value['password'] === this.resetPasswordForm.value['password2'];
    return passwordsMatch;
  }
}
