import { Object as ParseObject, Query } from "parse";

import { Asset } from "../asset/asset.model";
import { Case } from "../case/case.model";
import { Device } from "../device/device.model";
import { MethodType } from "../method-type/method-type.model";
import { Organization } from "../organization/organization.model";
import { SampleAsset } from "../sample-asset/sample-asset.model";
import { User } from "../user/user.model";

export class Sample extends ParseObject {
  static className = "Sample";

  constructor({
    name,
    methodType,
    numAssets,
    device,
    case: _case,
    organization,
    uuid,
  }: {
    name?: string;
    methodType?: MethodType;
    numAssets?: number;
    device?: Device;
    case?: Case;
    organization?: Organization;
    uuid?: string;
  } = {}) {
    super(Sample.className);
    this.name = name;
    this.methodType = methodType;
    this.set("numAssets", numAssets);
    this.device = device;
    this.case = _case;
    this.organization = organization;
    this._assets = this.get("assets");
    this.uuid = uuid;
  }

  get case(): Case {
    return this.get("case");
  }
  set case(value: Case) {
    this.set("case", value);
  }

  get name(): string {
    return this.get("name") ?? "";
  }
  set name(value: string) {
    this.set("name", value);
  }

  get createdBy(): User {
    return this.get("createdBy");
  }

  private _assets: Asset[] = [];
  get assets(): Asset[] {
    return this._assets || this.get("assets");
  }
  set assets(assets: Asset[]) {
    this._assets = assets;
  }

  get numAssets() {
    return this.get("numAssets") || (this.assets || []).length;
  }

  set data(value: { [key: string]: unknown }) {
    this.set("data", value);
  }
  get data(): { [key: string]: unknown } {
    return this.get("data");
  }

  get state(): any {
    return this.get("state");
  }
  set state(state: any) {
    this.set("state", state);
  }

  set methodType(value: MethodType) {
    this.set("methodType", value);
  }
  get methodType(): MethodType {
    return this.get("methodType");
  }

  set organization(value: Organization) {
    this.set("organization", value);
  }
  get organization(): Organization {
    return this.get("organization");
  }

  public get referenceAsset(): Asset {
    return this.assets
      ? this.assets.find((a) => a.isMarked) || this.assets[0]
      : undefined;
  }

  set device(value: Device) {
    this.set("device", value);
  }
  get device(): Device {
    return this.get("device");
  }

  get uuid(): string {
    return this.get("uuid");
  }
  set uuid(value: string) {
    this.set("uuid", value);
  }

  static async fetchSampleAssets(
    sample: Sample,
    {
      updateCallback = null,
      batch_size = 50,
    }: {
      updateCallback?: (progress?: number) => void;
      batch_size?: number;
    } = {}
  ): Promise<Sample> {
    const sampleAssetsQuery = new Query(SampleAsset)
      .equalTo("sample", sample)
      .include(["sample", "asset"])
      .ascending(["index", "dateOfCapture"]);
    const numSampleAssets = await sampleAssetsQuery.count();
    const sampleAssets: SampleAsset[] = [];
    const numBatches = Math.ceil(numSampleAssets / batch_size);
    updateCallback?.(0);

    for (let batch = 0; batch < numBatches; batch++) {
      const sampleAssetsBatch: SampleAsset[] = await sampleAssetsQuery
        .skip(batch * batch_size)
        .limit(batch_size)
        .find();
      sampleAssets.push(...sampleAssetsBatch);
      updateCallback?.(Math.floor((batch / numBatches) * 100));
    }
    if (sampleAssets.length) {
      sample.assets = sampleAssets.map((sa) => sa.asset);
    } else if (sample?.assets) {
      // REVIEW: legacy support
      // console.warn(`Failed to load SampleAssets for legacy sample, fetching Sample.assets instead`);
      // fix: parse... stringify... used here to overcome out of date parse typings
      try {
        await sample.fetch(JSON.parse(JSON.stringify({ include: ["assets"] })));
      } catch (err) {
        //
      }
    }
    return sample;
  }

  public static createWithoutData(id: string): Sample {
    return ParseObject.fromJSON({
      className: this.className,
      objectId: id,
    }) as Sample;
  }
}
