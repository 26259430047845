import type Parse from "parse";

import { Device } from "@telespot/domain";

import { ParseDeviceTypeMapper } from "./parse-device-type.mapper";
import { ParseMapper } from "../../parse.mapper";
import { OrganizationTopology } from "../organization";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

export enum DeviceTopology {
  TABLE = "Device",
  NAME = "name",
  TYPE = "deviceType",
  ORGANIZATION = "organization",
}

export class ParseDeviceMapper extends ParseMapper<Device> {

  private readonly deviceTypeMapper = new ParseDeviceTypeMapper(this.parse);

  public toDomain(parseObject: Parse.Object): Device {

    const parseDeviceType = parseObject.get(DeviceTopology.TYPE);

    const type = parseDeviceType ? this.deviceTypeMapper.toDomain(parseDeviceType) : undefined;

    return new Device({
      id: parseObject.id,
      name: parseObject.get(DeviceTopology.NAME),
      type,
      organizationId: parseObject.get(DeviceTopology.ORGANIZATION).id,
      deletedAt: parseObject.get(SoftDeletableTopology.DELETED_AT),
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
    })
  }

  public fromDomain(device: Device): Parse.Object {
    const parseOrganization = this.subclasses
      .getSubclass(OrganizationTopology.TABLE)
      .createWithoutData(device.organizationId);

    const parseObject = this.parse.Object.fromJSON({
      className: DeviceTopology.TABLE,
      [ObjectTopology.ID]: device.id,
      [ObjectTopology.CREATED_AT]: device.createdAt,
      [ObjectTopology.UPDATED_AT]: device.updatedAt,
    });

    parseObject.set({
      [SoftDeletableTopology.DELETED_AT]: device.deletedAt,
      [DeviceTopology.NAME]: device.name,
      [DeviceTopology.TYPE]: device.deviceType ? this.deviceTypeMapper.fromDomain(device.deviceType) : undefined,
      [DeviceTopology.ORGANIZATION]: parseOrganization,
    })

    return parseObject;
  }
}
