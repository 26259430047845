import { Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export abstract class AnalyticsProvider implements OnDestroy {
  protected destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
  }
}

export const ANALYTICS_PROVIDER = new InjectionToken<AnalyticsProvider>('ANALYTICS_PROVIDER');
