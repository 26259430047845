import {
  Algorithm,
  ProtocolAlgorithm,
  AlgorithmRepository,
  FindOneAlgorithmFilters,
  ExecutionEnvironment,
} from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";
import {
  MethodTypeTopology,
  ParseProtocolAlgorithmMapper,
  ProtocolAlgorithmTopology,
} from "../protocols";
import {
  AlgorithmTopology,
  ParseAlgorithmMapper,
} from "./parse-algorithm.mapper";

export class ParseAlgorithmRepository
  extends ParseBaseRepository
  implements AlgorithmRepository {
  private readonly algoMapper = new ParseAlgorithmMapper(this.parse);
  private readonly protoAlgoMapper = new ParseProtocolAlgorithmMapper(
    this.parse
  );

  public async getById(id: string): Promise<Algorithm> {
    const parseAlgorithm = await new this.parse.Query(
      AlgorithmTopology.TABLE
    ).get(id, this.options);

    return this.algoMapper.toDomain(parseAlgorithm);
  }

  public async getProtoAlgoById(id: string): Promise<ProtocolAlgorithm> {
    const parseProtoAlgorithm = await new this.parse.Query(
      ProtocolAlgorithmTopology.TABLE
    ).include([
      ProtocolAlgorithmTopology.METHOD_TYPE,
      ProtocolAlgorithmTopology.MODEL,
    ]).get(id, this.options);

    return this.protoAlgoMapper.toDomain(parseProtoAlgorithm);
  }

  public async findProtocolForMethodType(
    methodTypeId: string
  ): Promise<ProtocolAlgorithm[]> {
    const methodType = this.subclasses
      .getSubclass(MethodTypeTopology.TABLE)
      .createWithoutData(methodTypeId);

    const cloudAlgorithms = new this.parse.Query(
      AlgorithmTopology.TABLE
    ).exists(AlgorithmTopology.CLOUD_CONFIG);

    const workflow = await new this.parse.Query(ProtocolAlgorithmTopology.TABLE)
      .matchesQuery(ProtocolAlgorithmTopology.MODEL, cloudAlgorithms)
      .include([
        ProtocolAlgorithmTopology.METHOD_TYPE,
        ProtocolAlgorithmTopology.MODEL,
      ])
      .equalTo(ProtocolAlgorithmTopology.METHOD_TYPE, methodType)
      .find(this.options);

    return workflow.map((w) => this.protoAlgoMapper.toDomain(w));
  }

  public async saveProtocolAssignment(
    assignment: ProtocolAlgorithm
  ): Promise<void> {
    await this.protoAlgoMapper.fromDomain(assignment).save(null, this.options);
  }

  public async saveAlgorithm(algorithm: Algorithm): Promise<string> {
    const { id } = await this.algoMapper
      .fromDomain(algorithm)
      .save(null, this.options);
    return id;
  }

  public async findOne(filters: FindOneAlgorithmFilters): Promise<Algorithm> {
    const { name, version, environment } = filters;

    const configKey = !environment
      ? undefined
      : environment === ExecutionEnvironment.CLOUD
        ? AlgorithmTopology.CLOUD_CONFIG
        : AlgorithmTopology.MOBILE_CONFIG;

    const algorithmQuery = new this.parse.Query(AlgorithmTopology.TABLE)
      .equalTo(AlgorithmTopology.NAME, name)
      .equalTo(AlgorithmTopology.VERSION, version);

    if (environment) algorithmQuery.exists(configKey);

    const parseAlgorithm = await algorithmQuery.first(this.options);

    return parseAlgorithm
      ? this.algoMapper.toDomain(parseAlgorithm)
      : undefined;
  }
}
