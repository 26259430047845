import { Entity } from "./entity";

export interface InviteRole {
  id: string;
  name: string;
}

export interface InviteAttributes extends Entity {
  code: string;
  uses: number;
  organizationId: string;
  initialRoles: InviteRole[];
  initialWorkspaces: string[];
}

export class Invite implements Entity {

  constructor(private readonly attributes: InviteAttributes) { }

  public get id() {
    return this.attributes.id;
  }

  public get code() {
    return this.attributes.code;
  }

  public get uses() {
    return this.attributes.uses;
  }

  public get organizationId() {
    return this.attributes.organizationId;
  }

  public get initialRoles(): ReadonlyArray<InviteRole> {
    return this.attributes.initialRoles ?? [];
  }

  public get initialWorkspaces(): ReadonlyArray<string> {
    return this.attributes.initialWorkspaces ?? [];
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }

  public use() {
    if (!this.hasUses) throw new Error("InvitationError: exceeded maximum number of uses");

    this.attributes.uses--;
  }

  public get hasUses(): boolean {
    return this.attributes.uses > 0;
  }
}
