<div class="card">
  <ts-data-table
    (migrateItem)="migrateCase($event)"
    (copyItem)="copyCase($event)"
    (removeItem)="deleteCase($event)"
    (selectItem)="goToCaseDetail($event)"
    (addFilter)="applyFilter($event)"
    (deleteFilter)="removeFilter($event)"
    (changePage)="changePage($event)"
    (sortItems)="sortCases($event)"
    (searchItem)="applyFilter($event)"
    [config]="tableConfig"
    [dataSource]="dataSource"
    [isAdmin]="isAdmin$ | async"
    [isEncrypted]="decrypted$ | async"
    (decryptCases)="toggleDecryption($event)"
  >
    <ng-template dataTableHeader="userState" [dataTableHeaderLayout]="'mobile'">
      <i class="ri-bookmark-line"></i>
    </ng-template>

    <ng-template dataTableCell="userState" let-state let-mobile="mobile">
      <ng-container *ngIf="!mobile; else mobileState">{{
        state | translate | uppercase
      }}</ng-container>
      <ng-template #mobileState>
        <span class="status-indicator status-indicator--{{ state }}">
          <ng-container [ngSwitch]="state">
            <ng-container *ngSwitchCase="'read'"
              ><i class="ri-alert-line"></i
            ></ng-container>
            <ng-container *ngSwitchDefault
              ><i class="ri-checkbox-blank-circle-fill"></i
            ></ng-container>
          </ng-container>
        </span>
      </ng-template>
    </ng-template>
    <ng-template dataTableCell="created_at" let-created_at let-mobile="mobile">
      <ts-cell-created-by
        [createdBy]="created_at"
        matTooltip="{{
          'label.acquired_by_x_on_y'
            | translate
              : {
                  user: created_at.user?.username || ('unknown' | translate),
                  date: created_at.date | date : 'dd/MM/yyyy hh:mm a'
                }
        }}"
        [showDate]="!mobile"
      ></ts-cell-created-by>
    </ng-template>

    <ng-template dataTableCell="analysing" let-analysing>
      <span class="flex-row align-items-center analyst-list">
        <ts-user-avatar
          *ngFor="let userState of analysing"
          [user]="userState.user"
          [class.in-progress]="true"
          matTooltip="{{ userState.user?.username || ('unknown' | translate) }}"
        ></ts-user-avatar>
      </span>
    </ng-template>

    <ng-template dataTableCell="analyzed" let-analyzed>
      <span class="flex-row align-items-center analyst-list">
        <ts-user-avatar
          *ngFor="let userState of analyzed"
          [user]="userState.user"
          [class.analyzed]="true"
          matTooltip="{{ userState.user?.username || ('unknown' | translate) }}"
        ></ts-user-avatar>
      </span>
    </ng-template>
  </ts-data-table>
</div>
