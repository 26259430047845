import { Directive } from "@angular/core";
import { MetricScalePluginService } from "@telespot/shared/viewers/data-access";

@Directive({
  selector: "[tsMetricScalePlugin]",
  exportAs: "metricScalePlugin",
})
export class MetricScalePluginDirective {
  constructor(private _plugin: MetricScalePluginService) {}
  public readonly rulerIcon$ = this._plugin.rulerIcon$;
  public readonly changeRulerIcon = this._plugin.changeRulerIcon.bind(
    this._plugin
  );
  public readonly rulerSize$ = this._plugin.rulerSize$;
  public readonly changeRulerSize = this._plugin.changeRulerSize.bind(
    this._plugin
  );
}
