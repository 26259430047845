<div class="card">
  <div class="card-header">
    <h3>{{ "title.assign_to_workspaces" | translate | uppercase }}</h3>
  </div>
  <div class="card-body flex-row align-items-center" *ngIf="caseType.isNew()">
    <i class="ri-alert-line text-warn"></i>
    The caseType must be saved first before updating workspaces
  </div>
  <ul class="list-group list-group-flush" id="workspaces" *ngIf="caseType">
    <ts-data-table [config]="tableConfig" [dataSource]="dataSource">
      <ng-template dataTableCell="workspaces_list" let-item>
        <li class="list-group-item">
          <label>
            <i class="ri-file-copy-2-line"></i>
            {{ item.resource.name | uppercase }}
          </label>
          <span class="button-bar float-right">
            <button
              class="btn btn-outline-danger"
              [class.btn-outline-danger]="
                _getState(item.resource) === 'assigned' ||
                _getState(item.resource) === 'other'
              "
              [class.btn-outline-success]="_getState(item.resource) === 'empty'"
              [disabled]="
                caseType.isNew() || _getState(item.resource) === 'assigned'
              "
              (click)="
                onWorkspaceToggled(
                  item,
                  _getState(item.resource) === 'assigned' ? null : caseType
                )
              "
            >
              <ng-container [ngSwitch]="_getState(item.resource)">
                <ng-template ngSwitchCase="empty">
                  <i class="ri-links-fill"></i>{{ "label.assign" | translate }}
                </ng-template>
                <ng-template ngSwitchCase="other">
                  <i class="ri-refresh-line"></i
                  >{{ "label.replace" | translate }} "{{
                    item.resource.caseType?.name
                  }}"
                </ng-template>
                <ng-template ngSwitchCase="assigned">
                  {{ "label.assigned" | translate }}
                </ng-template>
              </ng-container>
            </button>
          </span>
        </li>
      </ng-template>
    </ts-data-table>
    <!--<li class="list-group-item" *ngIf="!workspaceItems?.length">({{ 'info.no_workspaces' | translate }})</li> -->
  </ul>
</div>
