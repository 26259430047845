<div class="card login-form" *ngIf="{ affiliate: affiliate$ | async } as state">
  <ng-container *ngIf="!state.affiliate; else welcomeCard">
    <div class="card-header text-center">
      <h3>{{ "button.redeem_invite_code" | translate }}</h3>
    </div>
    <small class="card-body">
      <span class="justify">{{ "help.redeem_invite_dialog" | translate }}</span>
    </small>
    <div class="card-body">
      <form *ngIf="form" [formGroup]="form" (submit)="submit()">
        <mat-form-field class="full-width">
          <mat-label> {{ "label.invite_code" | translate }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="inviteCode"
            autocomplete="off"
          />
          <mat-error *ngIf="form.controls['inviteCode'].errors as errors">{{
            errors.invalid
          }}</mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>
            {{ "label.email" | translate }}
          </mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
            autocomplete="off"
          />
          <mat-error *ngIf="form.controls['email'].invalid">
            {{ "error.enter_valid_email" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-checkbox
          class="full-width mt-5"
          matInput
          formControlName="accept_gdpr"
          data-cy="check-gdpr"
          >{{ "label.accept_gdpr" | translate }}
        </mat-checkbox>
        <a [routerLink]="[]" (click)="showPrivacyNotice()">{{
          "label.more_info" | translate
        }}</a>

        <button
          class="btn btn-primary full-width mt-4"
          type="submit"
          [disabled]="form.invalid"
        >
          <ng-container
            *ngIf="(submitting$ | async) === false; else submittingTpl"
          >
            {{ "button.submit" | translate }}
          </ng-container>
          <ng-template #submittingTpl>
            <i class="ri-loader-4-line spin"></i>
            {{ "label.processing" | translate }}
          </ng-template>
        </button>

        <mat-error *ngIf="form.errors">
          {{ form.errors | json }}
        </mat-error>
      </form>
    </div>
  </ng-container>

  <ng-template #welcomeCard>
    <div class="card-body">
      <div class="text-center">
        <i class="ri-check-double-line text-green"></i>
      </div>
      <p *ngIf="state.affiliate">
        {{ "label.added_to_organization" | translate }}
      </p>
      <h4 class="text-center flex-row align-items-center">
        <img
          class="small-logo"
          [src]="state.affiliate.organization.logoUrl"
          alt="{{ state.affiliate.organization.name }} logo"
        />
        <strong class="">{{ state.affiliate.organization.name }}</strong>
      </h4>
      <p *ngIf="!state.affiliate?.user?.emailVerified; else gotoLogin">
        {{ "label.check_and_verify_your_email" | translate }}
      </p>
      <ng-template #gotoLogin>
        <a [routerLink]="['/login']"> {{ "button.login" | translate }} </a>
      </ng-template>
    </div>
  </ng-template>
</div>
