import { Asset, AssetRepository, CountByState } from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";
import { AssetTopology, ParseAssetMapper } from "./parse-asset.mapper";
import { SampleQueryBuilder } from "./parse-sample-query.builder";
import { SampleAssetTopology } from "./parse-sample-asset.mapper";
import { ObjectTopology } from "../../parse.topology";

export class ParseAssetRepository extends ParseBaseRepository implements AssetRepository {
  private readonly assetMapper = new ParseAssetMapper(this.parse);

  public async getById(id: string): Promise<Asset> {
    const parseAsset = await new this.parse.Query(AssetTopology.TABLE).get(id, this.options);

    return parseAsset ? this.assetMapper.toDomain(parseAsset) : undefined;
  }

  public async save(asset: Asset): Promise<string> {
    const parseAsset = this.assetMapper.fromDomain(asset);

    const { id } = await parseAsset.save(null, this.options);

    return id;
  }

  public async findAssetWithFilename(assetFile: string): Promise<Asset> {
    const parseAsset = await new this.parse.Query(AssetTopology.TABLE)
      .equalTo(AssetTopology.ASSET_FILE, assetFile)
      .first(this.options);

    return parseAsset ? this.assetMapper.toDomain(parseAsset) : undefined;
  }

  public async countByState(query: CountByState): Promise<number> {
    const { level, id, statusType, statusValue } = query;

    const statusKey = `${statusType}.state`;

    const sampleQuery = new SampleQueryBuilder(this.parse)
      .withIds([id])
      .withLevel(level)
      .build();

    const sampleAssetQuery = new this.parse.Query(SampleAssetTopology.TABLE)
      .matchesQuery(SampleAssetTopology.SAMPLE, sampleQuery)
      .exists(SampleAssetTopology.ASSET);

    const assetQuery = new this.parse.Query(
      AssetTopology.TABLE
    ).matchesKeyInQuery(
      ObjectTopology.ID,
      `${SampleAssetTopology.ASSET}.${ObjectTopology.ID}`,
      sampleAssetQuery
    );

    return assetQuery.equalTo(statusKey, statusValue).count(this.options);
  }
}
