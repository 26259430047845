import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { DataTableConfig, SplDataSource } from "@shared/ui";
import {
  DataFilterComponent,
  FindRequest,
  IColumn,
  RowData,
} from "@shared/utils";
import { Algorithms } from "@telespot/sdk";
import { DataService } from "@telespot/web-core";

interface AIModelsRowData extends RowData {
  resource: Algorithms;
}

interface DialogResponse {
  model: Algorithms;
  action: "cancel" | "save";
}

@Component({
  selector: "ts-ai-available-models",
  templateUrl: "./ai-available-models.component.html",
  styleUrls: ["./ai-available-models.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIAvailableModelsComponent
  extends DataFilterComponent
  implements OnInit
{
  @Input() isCloud;
  @Output() submitted = new EventEmitter<DialogResponse>();

  @Input() modelSelected: Algorithms;

  public previousRequest: FindRequest;
  public oldAvailable: Algorithms[];
  public tableName = "ai_models_list";
  public dataSource = new SplDataSource<AIModelsRowData>(this);
  public tableConfig: DataTableConfig<AIModelsRowData>;

  public klass = Algorithms.className;
  public columns: IColumn[];

  constructor(public dataService: DataService) {
    super(dataService);
  }

  ngOnInit() {
    this.columns = [
      {
        colName: "core.name",
        key: "modelName",
        sortable: true,
        value: (item) => item,
        displayName: "Names",
        name: "name",
        queryFilters: [
          {
            comparison: "exists",
            key: this.isCloud ? "cloud_config" : "mobile_config",
            value: undefined,
          },
        ],
      },
      {
        colName: "core.description",
        key: "description",
        value: (item) => item,
        displayName: "Description",
        name: "description",
      },
      {
        colName: "core.version",
        key: "version",
        value: (item) => item,
        displayName: "Version",
        name: "version",
      },
      {
        colName: "core.regulated",
        key: "regulated",
        value: (item) => item,
        displayName: "Regulated",
        name: "regulated",
      },
    ];

    this.configureTable({
      hideHeader: false,
      rowClass: (item) =>
        this.modelSelected?.id === item.id ? "mat-row--primary" : undefined,
    });
  }

  onRowClicked(event: AIModelsRowData) {
    if (this.modelSelected?.id === event.id) {
      this.modelSelected = null;
      return;
    }
    this.modelSelected = Algorithms.fromJSON({
      ...event.resource,
      objectId: (event.resource as any)._id,
      className: "Algorithms",
    }) as Algorithms;
  }

  public url(item: any) {
    return null;
  }

  cancel() {
    this.submitted.emit({ model: null, action: "cancel" });
  }

  save() {
    this.submitted.emit({ model: this.modelSelected, action: "save" });
  }
}
