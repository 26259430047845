import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@telespot/web-core';
import { Subject } from 'rxjs';

@Component({
  selector: 'ts-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  formErrors: { username?: string; password?: string; verification?: string; roles?: string; organizations?: string } = {};

  loginForm: UntypedFormGroup;

  showPassword: boolean;
  private _processing$ = new Subject<boolean>();
  readonly processing$ = this._processing$.asObservable();

  constructor(private authService: AuthService, public router: Router) {}

  ngOnInit() {
    if (this.authService.currentUser) this.router.navigate(['/workspaces']);

    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
    });
  }

  login(): Promise<void> {
    this._processing$.next(true);
    this.formErrors = {};
    return this.authService
      .login(this.loginForm.value['username'], this.loginForm.value['password'])
      .then(
        (_) => {
          const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/me';
          this.router.navigate([redirect]);
        },
        (error) => {
          if (/not verified/i.test(error)) {
            this.formErrors.verification = 'info.email_not_verified';
          } else if (/roles/i.test(error)) {
            this.formErrors.roles = 'info.missing_user_roles';
          } else if (/organization/i.test(error)) {
            this.formErrors.organizations = 'info.missing_user_organizations';
          } else {
            this.formErrors.password = 'info.invalid_user_or_password';
          }
          throw error;
        }
      )
      .finally(() => this._processing$.next(false));
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }
}
