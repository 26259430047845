import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SampleCounterState } from "./sample-counters.reducer";

export const sampleCounterFeatureKey = "sample-counter";

export const selectSampleCounterFeature =
  createFeatureSelector<SampleCounterState>(sampleCounterFeatureKey);

export const labelCountIds = createSelector(
  selectSampleCounterFeature,
  (state: SampleCounterState) => state?.labelCount.ids
);

export const selectActiveReviewCount = createSelector(
  selectSampleCounterFeature,
  (state: SampleCounterState) => {
    return state?.totalCountReview;
  }
);

export const selectActiveLabelCountReview = createSelector(
  selectSampleCounterFeature,
  (state: SampleCounterState) => {
    return state?.labelCountReview;
  }
);

export const selectActiveMultiLabelReviewCount = createSelector(
  selectSampleCounterFeature,
  (state: SampleCounterState) => {
    return state?.multiLabelReviewCount;
  }
);
export const selectActiveSingleLabelReviewCount = createSelector(
  selectSampleCounterFeature,
  (state: SampleCounterState) => {
    return state?.singleLabelReviewCount;
  }
);

export const labelCountReviewIsEmpty = createSelector(
  selectSampleCounterFeature,
  (state: SampleCounterState) => {
    return state?.labelCountReview.labelCount.length === 0;
  }
);
