import {
  ComponentFactoryResolver,
  Directive,
  Host,
  Input,
  Renderer2,
  Self,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ROI,
  RoiService,
  SampleAnalysisService,
} from "@telespot/analysis-refactor/data-access";
import { IAssetROI, TRoiSelectionType } from "@telespot/sdk";
import { TOsdActiveAction } from "@telespot/shared/viewers/data-access";

export const MIN_ROI_SIZE = 8;

// @Directive()
@Directive()
export abstract class RoisDirective<T> {
  @Input()
  set action(action: TOsdActiveAction) {
    this._activeMode = action;
  }
  protected _activeMode: TOsdActiveAction = TOsdActiveAction.drawing;
  protected _drawingMode: TRoiSelectionType = TRoiSelectionType.center;

  get activeMode(): TOsdActiveAction {
    return this._activeMode;
  }

  protected _tempROI: IAssetROI;
  protected _defaultROIsize = 128;
  protected _safeDragThreshold: number = MIN_ROI_SIZE;
  constructor(
    @Host() @Self() protected viewerComponent: T,
    protected renderer: Renderer2,
    protected resolver: ComponentFactoryResolver,
    protected snackbar: MatSnackBar,
    protected _roiService: RoiService,
    protected _sampleAnalysisService: SampleAnalysisService
  ) {}
  protected removeROIs(rois: ROI[]) {
    this._roiService.removeROIs(rois);
  }
  protected abstract _removeEventHandlers();
  protected abstract _setupEventHandlers();
  protected addNewROIToModel(roi: IAssetROI) {
    try {
      this._roiService.addROIs([roi]);
      this._roiService.selectROIs(null);
    } catch (err) {
      this.snackbar.open(`${err.message}`, null, { duration: 800 });
    }
  }

  getModelColor(labels: string[], opaque?: boolean) {
    return this._roiService.getModelColor(labels, opaque);
  }

  getLabelName(labels: string[]) {
    return this._roiService.getLabelName(labels);
  }

  selectROI(rois: ROI | ROI[], replace: boolean) {
    return this._roiService.selectROIs(rois, replace);
  }
}
