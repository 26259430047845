import { createReducer, on } from "@ngrx/store";
import { IAnalysis, NewXOI, ROI } from "../analysis";
import * as HistoryActions from "./history.actions";
export const historyFeatureKey = "history";

export interface HistoryOperations {
  timestamp: number;
  operationType: string;
  details:
    | CreateAnalysisDetails[]
    | RefStripDetails
    | RoiActionDetails
    | UpdateAnalysisDetails
    | SetROIActionDetails;
}
export interface CreateAnalysisDetails {
  createdBy: Parse.Pointer;
  sampleId: string;
  assetId?: string;
  analysisTypeId: string;
}

export interface RefStripDetails {
  assetId: string;
  analysisStateId: string;
}
export interface UpdateAnalysisDetails {
  analysis: IAnalysis;
}

export interface RoiActionDetails {
  rois: ROI[];
}

export interface SetROIActionDetails {
  xois: NewXOI[];
}

export interface HistoryState {
  actions: HistoryOperations[];
  error: string;
}
export const initialHistoryState: HistoryState = {
  actions: [],
  error: undefined,
};

export const historyReducer = createReducer(
  initialHistoryState,
  on(HistoryActions.historyActionError, (state, { error }) => ({
    ...state,
    error,
  })),
  on(HistoryActions.registerAction, (state, { operationType, details }) => ({
    ...state,
    actions: [
      ...(state?.actions || []),
      { timestamp: Date.now(), operationType, details },
    ],
  })),
  on(HistoryActions.clearHistoryState, (state) => initialHistoryState)
);
