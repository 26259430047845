import type Parse from "parse";

import {
  IntelispotAnalysis,
  FindIntelspotAnalysisQuery,
  IntelispotAnalysisRepository,
  ExtendedIntelispotAnalysis,
  QueryContext,
} from "@telespot/domain";

import { ParseBaseRepository } from "../../parse-base.repository";
import { AssetTopology, DeviceTopology, SampleAssetTopology, SampleQueryBuilder, SampleTopology } from "../acquisition";
import { UserTopology } from "../accounts";
import { AnalysisStateTopology, AnalysisTopology } from "../analysis";

import { ParseIntelispotAnalysisMapper } from "./parse-intelispot-analysis.mapper";
import { ParseExportedAssetMapper } from "./parse-exported-asset.mapper";
import { ObjectTopology } from "../../parse.topology";
import { MethodTypeTopology } from "../protocols";
import { CaseTopology, WorkspaceTopology } from "../workspace";

const JOIN_OPERATOR = ".";
const SCAN_PREFIX = "scan-";

const ORGANIZATION_FIELD = [
  SampleAssetTopology.SAMPLE,
  SampleTopology.CASE,
  CaseTopology.WORKSPACE,
  WorkspaceTopology.ORGANIZATION,
].join(JOIN_OPERATOR);

const DEVICE_TYPE_FIELD = [
  AnalysisTopology.ASSET,
  AssetTopology.DEVICE,
  DeviceTopology.TYPE,
].join(JOIN_OPERATOR);

const ACQUIRED_BY_FIELD = [
  AnalysisTopology.ASSET,
  AssetTopology.CREATED_BY,
].join(JOIN_OPERATOR);

const METHOD_TYPE_FIELD = [
  AnalysisTopology.ASSET,
  AssetTopology.METHOD_TYPE,
  MethodTypeTopology.ANALYSIS_TYPES,
].join(JOIN_OPERATOR);

export class ParseIntelispotAnalysisRepository extends ParseBaseRepository implements IntelispotAnalysisRepository {

  public count(query: FindIntelspotAnalysisQuery): Promise<number> {
    return this.mapToAnalysisQuery(query).count(this.options);
  }

  public async *find(
    query: FindIntelspotAnalysisQuery
  ): AsyncGenerator<(IntelispotAnalysis | ExtendedIntelispotAnalysis)[]> {
    const { limit, level, ids, includeAssets } = query;

    const includeFields: string[] = [
      AnalysisTopology.CREATED_BY,
    ];

    const assetFields = [
      DEVICE_TYPE_FIELD,
      ACQUIRED_BY_FIELD,
      METHOD_TYPE_FIELD,
    ];

    if (includeAssets) includeFields.push(...assetFields);

    const parseQuery = this.mapToAnalysisQuery(query).include(includeFields);

    const generator = this.getTokenPaginationGenerator({ query: parseQuery, limit });

    for await (const analysis of generator) {
      const assetRefs = analysis.map((item) => item.get(AnalysisTopology.ASSET));

      const analysisStateQuery = this.mapToStateQuery({ level, ids });
      const sampleAssetQuery = this.mapToSampleAssetQuery({ level, ids });

      sampleAssetQuery
        .containedIn(SampleAssetTopology.ASSET, assetRefs)
        .include(ORGANIZATION_FIELD);

      const analysisStateGenerator = this.getTokenPaginationGenerator({ query: analysisStateQuery, limit });
      const sampleAssetGenerator = this.getTokenPaginationGenerator({ query: sampleAssetQuery, limit });

      const aStateArray = [];
      const saArray = [];
      const domainData = [];

      for await (const analysisStates of analysisStateGenerator) aStateArray.push(...analysisStates);
      for await (const sampleAssets of sampleAssetGenerator) saArray.push(...sampleAssets);

      for (const anObj of analysis) {
        const extraObj = {};

        const analysisAsset = anObj.get(AnalysisTopology.ASSET);
        const sampleAsset = saArray.find(sa => sa.get(SampleAssetTopology.ASSET).id === analysisAsset.id);
        const analysisCaseId = sampleAsset.get(SampleAssetTopology.SAMPLE).get(SampleTopology.CASE).id;

        for (const aState of aStateArray) {
          const stateCaseId = aState.get(AnalysisStateTopology.CASE).id;
          const stateUserId = aState.get(AnalysisStateTopology.USER).id;

          if (analysisCaseId !== stateCaseId) continue;

          extraObj[stateUserId] = aState.get(AnalysisStateTopology.STATE);
        }

        const intelAnalysis = ParseIntelispotAnalysisMapper.toDomain(anObj, sampleAsset);
        const asset = includeAssets && analysisAsset
          ? ParseExportedAssetMapper.toDomain(sampleAsset, extraObj)
          : undefined;

        domainData.push({ ...intelAnalysis, asset });
      }

      yield domainData;
    }
  }

  // REVIEW: this is reusable
  public mapToAnalysisQuery(query: FindIntelspotAnalysisQuery): Parse.Query {
    const { scansOnly, createdBy, level, ids } = query;

    const sampleAssetQuery = this.mapToSampleAssetQuery({ level, ids });
    const assetQuery = new this.parse.Query(AssetTopology.TABLE);
    const userQuery = new this.parse.Query(this.parse.User);
    const analysisQuery = new this.parse.Query(AnalysisTopology.TABLE);

    assetQuery.startsWith(AssetTopology.ASSET_FILE, SCAN_PREFIX);
    userQuery.equalTo(UserTopology.USERNAME, createdBy);

    if (scansOnly) sampleAssetQuery.matchesQuery(SampleAssetTopology.ASSET, assetQuery)
    if (createdBy) analysisQuery.matchesQuery(AnalysisTopology.CREATED_BY, userQuery);

    analysisQuery
      .exists(AnalysisTopology.ASSET)
      .matchesKeyInQuery(AnalysisTopology.ASSET, SampleAssetTopology.ASSET, sampleAssetQuery)
      .addDescending(ObjectTopology.CREATED_AT);

    return analysisQuery;
  }

  // REVIEW: this is reusable
  private mapToStateQuery(context: QueryContext) {
    const { level, ids } = context;

    const sampleQuery = new SampleQueryBuilder(this.parse)
      .withLevel(level)
      .withIds(ids)
      .build();

    const analysisStateQuery = new this.parse.Query(AnalysisStateTopology.TABLE)

    analysisStateQuery
      .matchesKeyInQuery(AnalysisStateTopology.CASE, SampleTopology.CASE, sampleQuery)
      .exists(AnalysisStateTopology.CASE);

    return analysisStateQuery;
  }

  // REVIEW: this is reusable
  private mapToSampleAssetQuery(context: QueryContext) {
    const { level, ids } = context;

    const sampleQuery = new SampleQueryBuilder(this.parse)
      .withLevel(level)
      .withIds(ids)
      .build();

    const sampleAssetQuery = new this.parse.Query(SampleAssetTopology.TABLE)
      .matchesQuery(SampleAssetTopology.SAMPLE, sampleQuery);

    return sampleAssetQuery;
  }
}
