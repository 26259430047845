import { Product } from "../value-objects";
import { Entity } from "./entity";
import { ProtocolAlgorithm } from "./protocol-algorithm";

export interface AlgorithmItem {
  id: string;
  name: string;
  version: string;
  regulated: boolean;
  environment: ExecutionEnvironment;
  application: Product;
  createdAt: Date;
}

export enum ExecutionEnvironment {
  CLOUD = "cloud",
  MOBILE = "mobile",
}

export interface AlgorithmAttributes extends Entity {
  name: string;
  version: string;
  description?: string;
  regulated: boolean;
  config: any | any[],
  environment: ExecutionEnvironment;
  application: Product;
}

export enum AlgorithmException {
  INVALID_NAME = "name is required",
  INVALID_VERSION = "version is required",
  INVALID_CONFIG = "config not provided",
  INVALID_ENVIRONMENT = "execution environment not provided",
}

export interface Assignment {
  methodTypeId: string;
  analysisTypeId?: string;
}

export class Algorithm implements Entity {
  constructor(private attributes: AlgorithmAttributes) {
    if (!attributes.name) throw new Error(AlgorithmException.INVALID_NAME);
    if (!attributes.version) throw new Error(AlgorithmException.INVALID_VERSION);
    if (!attributes.config) throw new Error(AlgorithmException.INVALID_CONFIG);
    if (!attributes.environment) throw new Error(AlgorithmException.INVALID_ENVIRONMENT);
  }


  public get id(): string | undefined {
    return this.attributes.id;
  }

  public get name(): string {
    return this.attributes.name;
  }

  public get version(): string {
    return this.attributes.version;
  }

  public get description(): string | undefined {
    return this.attributes.description;
  }

  public get regulated(): boolean {
    return this.attributes.regulated;
  }

  public get config(): any | any[] {
    return this.attributes.config;
  }

  public get environment(): ExecutionEnvironment {
    return this.attributes.environment;
  }

  public get application(): Product {
    return this.attributes.application ?? Product.ADAPTASPOT; // REVIEW default value
  }

  public get createdAt(): Date | undefined {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date | undefined {
    return this.attributes.updatedAt;
  }

  public executableOn(env: ExecutionEnvironment): boolean {
    return this.environment === env;
  }

  public updateConfig(config: any | any[]): Algorithm {
    this.attributes.config = config;
    return this;
  }

  public assign(params: Assignment): ProtocolAlgorithm {
    return new ProtocolAlgorithm({ ...params, model: this });
  }

  public updateDescription(value: string): Algorithm {
    this.attributes.description = value;
    return this;
  }

  public changeRegulatedStatus(status: boolean): Algorithm {
    this.attributes.regulated = status;
    return this;
  }

  public is(product: Product): boolean {
    return this.application === product;
  }
}
