
export enum Product {
  ADAPTASPOT = "adaptaspot",
  TIRASPOT = "tiraspot",
  MICRASPOT = "micraspot",
}

export enum Platform {
  IOS = "ios",
  ANDROID = "android",
}
