import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";

import * as AnalysisActions from "./analysis.actions";
import { EMPTY, from, of } from "rxjs";
import { Action, Store } from "@ngrx/store";
import { getROIsInsideRegion, IAnalysis } from "./analysis.reducer";
import {
  selectAnalysis,
  selectMode,
  selectRois,
  selectUnsyncedAnalysis,
} from "./analysis.selectors";
import { AnalysisService } from "../../services/analysis-service/analysis.service";
import {
  selectedLabels,
  selectHasSegmentationTasks,
  selectLabels,
} from "../protocol/protocol.selectors";
import {
  activeAnalysisIds,
  selectActiveAssetContextAnalysis,
  selectActiveROIS,
  selectAssetROIsFromUser,
} from "../interfeature.selectors";
import {
  sampleAnalysisStateFetched,
  setAsset,
} from "../../+state/rois.actions";
import { analysisState, selectActiveSample, selectAsset } from "../../+state";
import { SampleAnalysisService } from "../../services/sample-analysis/sample-analysis.service";

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MaskViewerService } from "@telespot/shared/viewers/data-access";

@Injectable()
export class AnalysisEffects {
  loadAssetAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.loadAssetAnalysis),
      withLatestFrom(this.store$.select(selectAnalysis)),
      mergeMap(([{ assetId, sampleId, createdBy }, cache]) => {
        const cachedAnalysis = cache.filter(
          (analysis: IAnalysis) =>
            !analysis.isSampleAnalysis &&
            analysis.assetId === assetId &&
            analysis.createdBy.className === createdBy.className &&
            analysis.createdBy.objectId === createdBy.objectId &&
            analysis.sampleId === sampleId
        );

        if (cachedAnalysis.length > 0)
          return of(AnalysisActions.updateLoading({ loading: false }));

        return this.analysisService
          .loadAssetAnalysis({ assetId, sampleId, createdBy })
          .pipe(
            map(({ analysis, rois, customLabels }) => {
              console.log("asset analysis loaded");
              console.log(rois);
              return AnalysisActions.assetAnalysisLoaded({
                analysis,
                rois,
                customLabels,
              });
            }),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[loadAssetAnalysis]: ${error.message}`,
                })
              )
            )
          );
      })
    )
  );

  loadSampleAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.loadSampleAnalysis),
      withLatestFrom(this.store$.select(selectAnalysis)),
      mergeMap(([{ sampleId, createdBy }, cache]) => {
        const cachedAnalysis = cache.filter(
          (analysis: IAnalysis) =>
            analysis.isSampleAnalysis &&
            analysis.createdBy.className === createdBy.className &&
            analysis.createdBy.objectId === createdBy.objectId &&
            analysis.sampleId === sampleId
        );

        if (cachedAnalysis.length > 0) return EMPTY;

        return this.analysisService
          .loadSampleAnalysis({ sampleId, createdBy })
          .pipe(
            map((analysis) =>
              AnalysisActions.sampleAnalysisLoaded({ analysis })
            ),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[loadSampleAnalysis]: ${error.message}`,
                })
              )
            )
          );
      })
    )
  );

  preSyncAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.preSyncAnalysis),
      withLatestFrom(
        this.store$.select(selectHasSegmentationTasks),
        this.store$.select(selectActiveAssetContextAnalysis)
      ),
      mergeMap(([action, hasSegmentationTasks, activeAnalysis]) => {
        if (
          hasSegmentationTasks &&
          activeAnalysis !== undefined &&
          activeAnalysis?.analysisArray?.length > 0
        ) {
          this._maskViewerService.saveMaskOnLocalStorage.emit({
            save: true,
            id: activeAnalysis.analysisArray[0].id,
          });
          return EMPTY;
        }
        return of(AnalysisActions.syncAnalysis());
      })
    )
  );

  syncAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.syncAnalysis),
      withLatestFrom(
        this.store$.select(selectUnsyncedAnalysis),
        this.store$.select(selectRois),
        this.store$.select(selectLabels),
        this.store$.select(selectMode),
        this.store$.select(selectHasSegmentationTasks)
      ),
      mergeMap(([_, analysis, rois, labels, mode, hasSegmentationTasks]) =>
        from(
          this.analysisService.saveAnalysis(
            analysis,
            rois,
            labels,
            mode,
            hasSegmentationTasks
          )
        ).pipe(
          map((idChanges) => {
            this._sampleAnalysisService.giveUserFeedback("Changes saved");
            return AnalysisActions.analysisSynced({ idChanges });
          }),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[syncAnalysis]: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  selectROIs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.selectROIsFromRegion),
      withLatestFrom(this.store$.select(selectRois)),
      mergeMap(([selectROIsReq, rois]) => {
        if (!selectROIsReq.bounds) return EMPTY;
        return of({
          rois: getROIsInsideRegion(
            rois,
            selectROIsReq.bounds,
            selectROIsReq.activeAnalysisIds
          ),
          replace: false,
        }).pipe(
          map(({ rois, replace }) =>
            AnalysisActions.setSelectedROIs({ rois, replace })
          ),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[selectROIsFromRegion]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  copyAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.copyAnalysis),
      mergeMap(
        ({ authUser }) =>
          of(authUser).pipe(
            withLatestFrom(
              this.store$.select(activeAnalysisIds),
              this.store$.select(selectActiveROIS),
              this.store$.select(selectAssetROIsFromUser(authUser))
            )
          ),
        (authUser, latestStoreData) => latestStoreData
      ),
      mergeMap(([authUser, activeAnalysisIds, newROIs, oldROIs]) => {
        return of({ authUser, activeAnalysisIds, newROIs, oldROIs });
      }),
      mergeMap(({ authUser, activeAnalysisIds, newROIs, oldROIs }) => [
        AnalysisActions.analysisCopied({ authUser, activeAnalysisIds }),
        AnalysisActions.setReviewCounters({ newROIs, oldROIs, authUser }),
      ]),
      catchError((error) =>
        of(AnalysisActions.analysisActionError({ error: error.message }))
      )
    )
  );

  deselectROIs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sampleAnalysisStateFetched, setAsset),
      mergeMap((_) => {
        return of(_).pipe(
          map((_) =>
            AnalysisActions.setSelectedROIs({ rois: [], replace: true })
          ),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[sampleAnalysisStateFetched, setAsset]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  createSegmAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.createSegmAnalysis),
      withLatestFrom(
        this.store$.select(analysisState),
        this.store$.select(selectAsset),
        this.store$.select(selectActiveSample),
        this.store$.select(selectedLabels)
      ),
      mergeMap(([action, analysisState, asset, sample, selectedLabels]) => {
        return of({
          createdBy: analysisState.user.toPointer(),
          assetId: asset?.id,
          sampleId: sample?.id,
          analysisTypeId: selectedLabels[0].analysisTypeId,
        });
      }),
      map((payload) => AnalysisActions.segmAnalysisCreated(payload))
    )
  );

  clearMasksFromStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.exitAnalysis),
      tap(() => {
        Object.keys(localStorage)
          .filter((item) => item.startsWith("localMask/"))
          .forEach((item) => localStorage.removeItem(item));
      }),
      map(() => {
        return { type: "LocalStorageCleared" } as Action;
      })
    )
  );

  discardAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.discardAnalysis),
      withLatestFrom(
        this.store$.select(selectAnalysis),
        this.store$.select(selectHasSegmentationTasks)
      ),
      mergeMap(
        ([
          { analysisDiscarded, hasROI },
          allAnalysis,
          hasSegmentationTasks,
        ]) => {
          const analysisToDiscard = analysisDiscarded
            .map((analysisInfo) => {
              return allAnalysis.find(
                (a) =>
                  a.analysisTypeId === analysisInfo.analysisTypeId &&
                  a?.createdBy.objectId === analysisInfo?.createdBy.objectId &&
                  a?.assetId === analysisInfo?.assetId &&
                  a?.sampleId === analysisInfo?.sampleId
              );
            })
            .filter((a) => a);

          const analysisDiscardedIds = analysisToDiscard.map((a) => a?.id);

          if (hasSegmentationTasks) {
            analysisDiscardedIds.map((id) =>
              localStorage.removeItem(`localMask/${id}`)
            );
          }

          return of({ analysisDiscardedIds, hasROI }).pipe(
            map(({ analysisDiscardedIds, hasROI }) => {
              return AnalysisActions.analysisDiscarded({
                analysisDiscardedIds,
                hasROI,
              });
            }),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[discardAnalysis]: ${error.message}`,
                })
              )
            )
          );
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private analysisService: AnalysisService,
    private _sampleAnalysisService: SampleAnalysisService,
    private _maskViewerService: MaskViewerService
  ) {}
}
