import type jsonpath from "jsonpath";

import {
  PipelineStep,
  ProtocolAlgorithm,
  Resource,
  Algorithm,
  Asset,
} from "../entities";

export class AlgorithmService {
  public static getExecutionInput(
    jp: typeof jsonpath,
    workflow: ProtocolAlgorithm | PipelineStep,
    asset: Asset,
    model: Algorithm,
    resource?: Resource
  ) {
    const config = model.config;

    const taskInput = Object.keys(config.task_input ?? {}).reduce(
      (acc, curr_key) => {
        const curr_value = config.task_input[curr_key];
        const resolved_value = String(curr_value).startsWith("$")
          ? jp.value(asset, curr_value)
          : curr_value;

        acc[curr_key] = resolved_value;
        return acc;
      },
      { ...resource?.metadata }
    );

    const databaseMapping = {
      ...config.database_mapping,
      workflowId: workflow.id,
      isTiraspot: resource ? true : false,
      model_name: model.name,
      object: {
        className: "Asset",
        objectId: asset.id,
      },
    };

    return {
      ...config,
      database_mapping: databaseMapping,
      task_input: taskInput,
    };
  }
}
