<div id="div1" class="flex-row">
  <video
    width="100%"
    height="82%"
    [controls]="false"
    id="video"
    #video
    preload="auto"
    seekable="true"
    [attr.webkit-playsinline]="isIOS"
    [attr.playsinline]="isIOS"
    crossorigin
    (canplaythrough)="videoBar.hidden = false; loading.hidden = true"
    (progress)="loadingProgress.width = $event"
    (timeupdate)="updateProgress($event)"
    [ngStyle]="{ display: useCanvas ? 'none' : 'visible' }"
  >
    <source id="videoSource" #source type="video/mp4" />
    Your browser does not support the video tag.
  </video>

  <div id="bigCanvasContainer">
    <canvas #canvas id="bigCanvas" [hidden]="!useCanvas"> </canvas>
    <ng-content select="[videoOverlay]"></ng-content>
  </div>

  <ng-container *ngIf="enableDebug$ | async">
    <div
      *ngIfRole="['admin']"
      style="position: absolute; top: 200px; right: 30px; width: 200px; padding: 20px; color: white; background-color: gray"
    >
      <ng-container *ngIf="canvasTransform$ | async as transform">
        Transform:
        <p>scale: {{ transform[0] }}</p>
        <p>dx: {{ transform[4] }}px</p>
        <p>dy: {{ transform[5] }}px</p>
      </ng-container>
    </div>
  </ng-container>

  <div class="video-selection-overlay-container" *ngIf="!isMobile">
    <ng-container [ngTemplateOutlet]="videoSelectionTemplate" *ngIf="showVideoSelectionOverlay$ | async"> </ng-container>
  </div>

  <ng-template #videoSelectionTemplate>
    <div id="video-selection-overlay" data-cy="video-selection-panel">
      <div class="flex-row justify-content-center">
        <button mat-icon-button class="btn btn-flat-primary" (click)="toggleVideoSelectionOverlay()">
          <i class="ri-arrow-down-s-line"></i>
        </button>
      </div>
      <div class="flex-column video-selection-list">
        <div
          class="video-selection-list__source"
          *ngFor="let source of sources; let videoIndex = index"
          (click)="selectVideo(videoIndex); toggleVideoSelectionOverlay()"
          [class.selected]="videoIndex === selectedAssetId"
        >
          <img [src]="source.thumbnail | preprocess | async" alt="" />
          <div>Video {{ videoIndex + 1 }}</div>
        </div>
      </div>
    </div>
  </ng-template>

  <div id="videoBar" #videoBar class="d-flex" style="align-items: center">
    <button id="btn-stop" mat-icon-button class="btn" (click)="stop()" matTooltip="{{ 'button.stop' | translate }}">
      <i class="ri-stop-fill"></i>
    </button>
    <button
      data-cy="video-play"
      id="btn-play"
      mat-icon-button
      class="btn"
      (click)="playPause()"
      matTooltip="{{ 'button.play_pause' | translate }}"
    >
      <i [ngClass]="{ 'ri-play-mini-fill': video.paused, 'ri-pause-fill': !video.paused }"></i>
    </button>
    <button
      mat-icon-button
      id="btn-bookmark"
      class="btn"
      (click)="bookmark()"
      matTooltip="{{ 'button.bookmark_frame' | translate }}"
    >
      <i class="ri-bookmark-line"></i>
    </button>
    <div id="videoBarDiv">
      <div class="videoProgress" #trackbar (click)="progressBarClicked($event)">
        <div class="videoProgressLine">
          <div class="videoProgressLineFilled" [style.width]="relativeTime() + '%'"></div>
        </div>
        <div class="videoTracker" id="tracker" #tracker [ngClass]="{ slow: slowSeek | async }"></div>

        <ng-content selector="[progressBarItem]"></ng-content>
      </div>
    </div>
    <span id="label-time">
      <span
        class="label-video-index"
        *ngIf="(selectedVideoIndex$ | async) + 1 as index"
        [matTooltip]="'tooltip.playing_video_x_of_n' | translate : { i: index, n: numItems }"
        >[{{ index }}]</span
      >
      {{ videoCurrentTime | duration }} / {{ videoDuration | duration }}
    </span>
    <button
      id="btn-previous"
      mat-icon-button
      class="btn"
      matTooltip="{{ 'button.previous_video' | translate }}"
      [disabled]="selectedAssetId === 0"
      (click)="selectVideo(selectedAssetId - 1)"
    >
      <i class="ri-skip-back-fill"></i>
    </button>
    <button
      id="btn-next"
      mat-icon-button
      class="btn"
      matTooltip="{{ 'button.next_video' | translate }}"
      [disabled]="selectedAssetId >= playListCount - 1"
      (click)="selectVideo(selectedAssetId + 1)"
    >
      <i class="ri-skip-forward-fill"></i>
    </button>
    <button
      id="btn-select-video"
      mat-icon-button
      class="btn"
      matTooltip="{{ 'button.select_video' | translate }}"
      [disabled]="playListCount <= 1"
      (click)="toggleVideoSelectionOverlay()"
      data-cy="button-select-video"
    >
      <i
        class="ri-film-line"
        [class.text-primary]="showVideoSelectionOverlay$ | async"
        [matBadge]="numItems > 1 ? numItems : null"
        matBadgeSize="small"
      ></i>
    </button>
  </div>
  <div #loading>
    <div class="progress" style="width: 100%">
      <div
        #loadingProgress
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {{ 'label.loading' | translate }}...
      </div>
    </div>
  </div>
</div>
