import { Entity } from "./entity";

export enum PipelineEnvironment {
  CLOUD = "cloud",
  EDGE = "edge",
}

export interface PipelineParameters extends Entity {
  id?: string;
  env: PipelineEnvironment;
  name: { [key: string]: string };
}

export class Pipeline implements Entity {
  constructor(private attributes: PipelineParameters) {}
  public get id(): string {
    return this.attributes.id;
  }

  public get env(): PipelineEnvironment {
    return this.attributes.env;
  }

  public get name(): { [key: string]: string } {
    return this.attributes.name;
  }

  public get createdAt(): Date | undefined {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date | undefined {
    return this.attributes.updatedAt;
  }
}
