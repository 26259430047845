import { WorkspaceItem } from "@telespot/domain";
import { IListWorkspacesHandler, ListWorkspaces } from "@telespot/use-cases";

import { OrganizationTopology, WorkspaceTopology, ParseWorkspaceMapper } from "../../repositories";
import { ParseBaseRepository } from "../../parse-base.repository";
import { ObjectTopology } from "../../parse.topology";

/**
 * Implementation of {@link IListWorkspacesHandler} interface.
 *
 * @group Workspaces
 * @category Handlers
 */
export class ListWorkspacesHandler extends ParseBaseRepository implements IListWorkspacesHandler {

  public async execute(command: ListWorkspaces): Promise<WorkspaceItem[]> {
    const { organizationId, limit, nextToken } = command.details;

    const organization = this.parse.Object
      .extend(OrganizationTopology.TABLE)
      .createWithoutData(organizationId);

    const workspaceQuery = new this.parse.Query(WorkspaceTopology.TABLE)
      .equalTo(WorkspaceTopology.ORGANIZATION, organization)
      .ascending(ObjectTopology.ID)
      .limit(limit);

    if (nextToken) workspaceQuery.greaterThan(ObjectTopology.ID, nextToken.id);

    const workspaces = await workspaceQuery.find(this.options);

    return workspaces.map(ParseWorkspaceMapper.toWorkspaceItem);
  }
}
