import type Parse from "parse";

export class ParseSubclassFactory {

  private readonly subclasses: Map<string, typeof Parse.Object> = new Map();

  private static instance: ParseSubclassFactory;

  public static getInstance(parse: typeof Parse): ParseSubclassFactory {
    this.instance = this.instance ?? new ParseSubclassFactory(parse);

    return this.instance;
  }

  private constructor(protected readonly parse: typeof Parse) { }

  public getSubclass(className: string): typeof Parse.Object {
    const subclass = this.subclasses.get(className) ?? this.parse.Object.extend(className) as typeof Parse.Object;

    if (!this.subclasses.get(className)) this.subclasses.set(className, subclass);

    return subclass;
  }
}
