import { CommonModule } from "@angular/common";
import { Directive, HostListener, Input, NgModule } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  TelespotAnalysisRefactorDataAccessModule,
  addCustomLabel,
} from "@telespot/analysis-refactor/data-access";
import { AnalysisTask } from "@telespot/sdk";

@Directive({
  selector: "[tsNewTagTrigger]",
})
export class NewTagTriggerDirective {
  @Input() tsNewTagTrigger: {
    analysisTask: AnalysisTask;
    analysisTypeId: string;
  };

  @HostListener("click") add() {
    const displayName = this._getDisplayName();
    if (!displayName) return;

  }

  private _getDisplayName(): string {
    return prompt("New tag name");
  }

  constructor(private store: Store) {}
}

@NgModule({
  declarations: [NewTagTriggerDirective],
  imports: [CommonModule, TelespotAnalysisRefactorDataAccessModule],
  exports: [NewTagTriggerDirective],
  providers: [],
})
export class NewTagTriggerModule {}
