import type Parse from "parse";

import { Case, CaseItem } from "@telespot/domain";
import { ParseMapper } from "../../parse.mapper";
import { WorkspaceTopology } from "./parse-workspace.mapper";
import { OrganizationTopology } from "../organization";
import { CaseTypeTopology } from "../protocols";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

export enum CaseTopology {
  TABLE = "Case",
  NAME = "name",
  WORKSPACE = "workspace",
  ORGANIZATION = "organization",
  DATA = "data",
  CREATED_BY = "createdBy",
  CASE_TYPE = "caseType",
  STATE = "state",
  UUID = "uuid",
}

export class ParseCaseMapper extends ParseMapper<Case> {
  public toDomain(parseObject: Parse.Object): Case {
    return new Case({
      id: parseObject.id,
      name: parseObject.get(CaseTopology.NAME),
      workspaceId: parseObject.get(CaseTopology.WORKSPACE).id,
      organizationId: parseObject.get(CaseTopology.ORGANIZATION).id,
      data: parseObject.get(CaseTopology.DATA),
      createdById: parseObject.get(CaseTopology.CREATED_BY)?.id,
      caseTypeId: parseObject.get(CaseTopology.CASE_TYPE).id,
      state: parseObject.get(CaseTopology.STATE),
      uuid: parseObject.get(CaseTopology.UUID),
      deletedAt: parseObject.get(SoftDeletableTopology.DELETED_AT),
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
    });
  }

  public fromDomain(object: Case): Parse.Object {
    const workspace = this.subclasses
      .getSubclass(WorkspaceTopology.TABLE)
      .createWithoutData(object.workspaceId);

    const organization = this.subclasses
      .getSubclass(OrganizationTopology.TABLE)
      .createWithoutData(object.organizationId);

    const caseType = this.subclasses
      .getSubclass(CaseTypeTopology.TABLE)
      .createWithoutData(object.caseTypeId);

    const createdBy = this.parse.User.createWithoutData(object.createdById);

    const parseObject = this.parse.Object.fromJSON({
      className: CaseTopology.TABLE,
      [ObjectTopology.ID]: object.id,
      [ObjectTopology.CREATED_AT]: object.createdAt,
      [ObjectTopology.UPDATED_AT]: object.updatedAt,
    }, false);

    parseObject.set({
      [CaseTopology.NAME]: object.name,
      [CaseTopology.WORKSPACE]: workspace,
      [CaseTopology.ORGANIZATION]: organization,
      [CaseTopology.DATA]: object.data,
      [CaseTopology.CREATED_BY]: createdBy,
      [CaseTopology.CASE_TYPE]: caseType,
      [CaseTopology.STATE]: object.state,
      [CaseTopology.UUID]: object.uuid,
      [SoftDeletableTopology.DELETED_AT]: object.deletedAt,
    });

    return parseObject;
  }

  public static toCaseItem(parseObject: Parse.Object): CaseItem {
    return {
      id: parseObject.id,
      name: parseObject.get(CaseTopology.NAME),
    };
  }
}
