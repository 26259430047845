import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService, DataService } from "@telespot/web-core";
import { CloudFunctions, Member, Query, Workspace } from "@telespot/sdk";
import { Observable } from "rxjs";
import { filter, map, share, switchMap, tap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { selectTotalWorkspaceCases } from "@telespot/analysis-refactor/data-access";

@Component({
  selector: "ts-workspace-detail",
  templateUrl: "./workspace-detail.component.html",
  styleUrls: ["./workspace-detail.component.scss"],
})
export class WorkspaceDetailComponent {
  private _workspace: Workspace;
  public aiStatus: string;
  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private _authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private store: Store
  ) {}

  public readonly numCases$ = this.store.select(selectTotalWorkspaceCases);

  workspace$: Observable<Workspace> = this.route.paramMap.pipe(
    switchMap(async (params) => {
      const fetchedWorkspace = this.route.snapshot.data["workspace"];
      if (fetchedWorkspace) {
        const aiStateInfo = await CloudFunctions.requestAIState(
          "workspace",
          fetchedWorkspace.id
        );
        this.aiStatus =
          aiStateInfo.failed > 0
            ? "ai_error"
            : aiStateInfo.processing > 0
            ? "ai_processing"
            : aiStateInfo.succeeded > 0
            ? "ai_ready"
            : undefined;

        return fetchedWorkspace;
      }
      const id = params.get("workspaceId");
      if (id) {
        try {
          return this._authService.currentUser.is([
            "admin",
            "technicianmanager",
            "analystmanager",
          ])
            ? this.dataService.get(id, new Query(Workspace).include("caseType"))
            : this.dataService
                .first(
                  new Query(Member)
                    .equalTo("workspace", Workspace.createWithoutData(id))
                    .include(["workspace.caseType"])
                )
                .then((m) => m.workspace);
        } catch (err) {
          return Promise.resolve(undefined);
        }
      } else {
        return Promise.resolve(undefined);
      }
    }),
    filter((workspace) => workspace !== undefined),
    tap((workspace: Workspace) => {
      this._authService.setOrganization(workspace.organization);
      this._workspace = workspace;
    }),
    share()
  );

  canBeDeleted$: Observable<boolean> = this.numCases$.pipe(
    map((count) => count === 0)
  );

  public readonly showMobileLayout$: Observable<boolean> =
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.HandsetPortrait])
      .pipe(map((r) => r.matches));

  public async deleteWorkspace(): Promise<void> {
    this.dataService
      .softDelete(this._workspace)
      .then((ws) => this.router.navigate(["/workspaces"]));
  }
}
