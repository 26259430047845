<div class="canvas-wrapper">
  <canvas
    osdOverlay
    #canvas
    id="canvasMask"
    [style.opacity]="opacity"
    units="px"
    [position]="position"
    style="pointer-events: all"
  ></canvas>
  <div
    osdOverlay
    class="spinner-overlay"
    *ngIf="maskLoading$ | async"
    [position]="spinnerPosition"
    units="px"
  >
    <div class="spinner"></div>
  </div>
</div>
