import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ProtocolService } from "@telespot/protocols/data-access";
import { TaskOption } from "@telespot/sdk";

import { Observable, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { v4 } from "uuid";
import { LabelLangSelectorDialogComponent } from "../label-lang-selector-dialog/label-lang-selector-dialog.component";

@Component({
  selector: "ts-label-suggester",
  templateUrl: "./label-suggester.component.html",
  styleUrls: ["./label-suggester.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelSuggesterComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() inputValue: Observable<string>;
  @Output() addNewName: EventEmitter<string> = new EventEmitter();
  @Output() filteredNamesEvt$: EventEmitter<Observable<TaskOption[]>> =
    new EventEmitter();
  @Output() optionSelected = new EventEmitter<any>();

  filteredLabels$: Observable<TaskOption[]>;
  public currentValue: any;
  private _dialogRef: MatDialogRef<any>;

  private _destroy$ = new Subject<void>();

  constructor(
    private protocolService: ProtocolService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.filteredLabels$ = (
      this.control ? this.control.valueChanges : this.inputValue
    ).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((value) => {
        this.currentValue = value;
        return this.filterNames(value);
      })
    );

    this.filteredNamesEvt$.emit(this.filteredLabels$);
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  filterNames(value: string): Observable<TaskOption[]> {
    return this.protocolService.getMatchingLabels(value).pipe(
      map((labels) =>
        labels.map((label) => ({
          name: label.value,
          uuid: label.uuid,
          value: { [label.lang]: label.value },
        }))
      )
    );
  }

  onOptionClicked(option: any, newOption: boolean) {
    if (newOption) {
      this._dialogRef = this.dialog.open(LabelLangSelectorDialogComponent, {
        width: "400px",
        hasBackdrop: true,
        data: {
          option,
        },
      });
      this._dialogRef
        .afterClosed()
        .pipe(
          take(1),
          tap((_) => (this._dialogRef = undefined)),
          takeUntil(this._destroy$)
        )
        .subscribe((value) => {
          if (value) {
            const optionSelected = {
              name: option,
              uuid: v4(),
              value,
              new: newOption,
            };
            this.optionSelected.emit(optionSelected);
          }
          this._dialogRef = undefined; // Reset dialog reference
        });
    } else {
      const optionSelected = option;
      this.optionSelected.emit(optionSelected);
    }
  }

  getLanguageLabel(label): string {
    const langCode = (Object.keys(label?.value) || []).find(
      (lang) => label.value[lang] === label.name
    );

    switch (langCode) {
      case "es":
        return "[español]";
      case "en":
        return "[english]";
      case "fr":
        return "[francois]";
      case "pt":
        return "[português]";
      default:
        return langCode;
    }
  }
}
