import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { DROP_LIST_SERVICE, GridIndexPath, MoveEvent } from "@shared/ui";
import {
  DragDropService,
  POI,
  ROI,
  changeCropLabels,
  dragCrop,
  loadMore,
  resetMosaicSelectedLabel,
  selectLoadingAnalysis,
  selectActiveCount,
  getProtocolTasks,
  getProtocolOptions,
  preSyncAnalysis,
  RoiService,
  protocolTaskCount,
  cropsPerLabel,
  cropCountPerSection,
  visibleSections,
  hasMoreLabels,
} from "@telespot/analysis-refactor/data-access";
import {
  TAnalysisProtocolTask,
  TaskOption,
} from "@telespot/sdk";

import {
  CropDetail,
  CropInfo,
} from "@telespot/web-core";

export enum OS {
  MacOs = "MacOs",
  Windows = "Windows",
  Linux = "Linux",
}

@Component({
  selector: "ts-sample-analysis-mosaics",
  templateUrl: "./sample-analysis-mosaics.component.html",
  styleUrls: ["./sample-analysis-mosaics.component.scss"],
})
export class SampleAnalysisMosaicsComponent implements OnInit, OnDestroy, AfterViewInit {

  // Contextual Menu variables
  public contextMenuVisible = false;
  public cropDetailMenuVisible = false;
  public contextMenuPosition = { x: 0, y: 0 };
  public contextMenuOrigin: string;
  public selectedItemLabels: Array<string> = [];
  public selectedCropDetail: CropDetail;
  public selectedCropUrl: SafeUrl;
  public scale = 1;
  public cellSize = 128;
  public cellsPerRow: number;

  @ViewChild("contextMenu", { read: ElementRef }) contextMenuRef: ElementRef<HTMLElement>;
  @ViewChild("selectionBox") selectionBox: ElementRef<HTMLElement>;
  @ViewChildren("cropElement") cropElements: QueryList<ElementRef<HTMLElement>>;

  isDragging = false;
  startPoint = { x: 0, y: 0 };
  draggedSelection = false;

  public UNLABELED_COLOR = "#F08F43";
  public MIN_ZOOM = 1;
  public MAX_ZOOM = 2;
  public STEP_ZOOM = 0.5;
  public INIT_COLUMNS = 10;

  // Protocol and Mosaics variables
  public readonly rois$ = this._roiService.mosaicsVisibleROIs$;
  public readonly sampleStats$ = this._store.select(selectActiveCount);
  public readonly assetProtocol$ = this._store.select(getProtocolTasks);
  public readonly protocolOptions$ = this._store.select(getProtocolOptions);
  public readonly loading$ = this._store.select(selectLoadingAnalysis);
  public readonly labelsLeft$ = this._store.select(hasMoreLabels);
  public readonly protocolTaskCount$ = this._store.select(protocolTaskCount);
  public readonly cropsPerLabel$ = this._store.select(cropsPerLabel);
  public readonly cropCountPerSection$ = this._store.select(cropCountPerSection);
  public readonly visibleSections$ = this._store.select(visibleSections);
  public readonly selectedCrops$ = this.dragDropService.selectedCrops$;

  private readonly attrKey = "roi-id";

  public currentLang: string;
  public isSelecting = false;

  private _os: OS;
  public hoverLabel = "";

  constructor(
    @Inject(DROP_LIST_SERVICE) private readonly dragDropService: DragDropService,
    private readonly _roiService: RoiService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _store: Store,
    private readonly translateService: TranslateService,
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {
    this.currentLang =
      localStorage.getItem("user_language") ||
      this.translateService.currentLang;
  }

  /* COMPONENT LYFECYCLE MANAGEMENT FUNCTIONS */

  public ngOnInit() {
    this._store.dispatch(resetMosaicSelectedLabel());
  }

  public ngAfterViewInit(): void {
    this.updateCellsPerRow();
  }

  public ngOnDestroy() {
    this.deselectAll();
  }

  /* CONTEXTUAL MENU FUNCTIONS */

  public openContextMenu(event: MouseEvent, cropInfo: CropInfo, _rois: (ROI | POI)[], selectedCrops: CropInfo[]): void {
    event.preventDefault();
    event.stopPropagation();

    if (this._os && this._os === OS.MacOs && event.button === 0) {
      this.onCropClick(event, cropInfo, selectedCrops);
      return;
    }

    const isSelected = this.isSelected(cropInfo, selectedCrops);

    if (!isSelected) {
      if (!this.isSelecting) this.dragDropService.cleanSelectedCrops();
      this.dragDropService.addSelectedCrop(cropInfo);
      selectedCrops.push(cropInfo);
    }

    this.contextMenuPosition.x = event.clientX;
    this.contextMenuPosition.y = event.clientY;

    const findRois = (roiId) => _rois.find((roi) => roi.id === roiId);
    const findLabelsForFinding = (roi, findingId) =>
      (roi?.labels || []).find((label) => label.findingId === findingId);

    const labels = selectedCrops.flatMap((crop) => {
      const roi = findRois(crop.roiID);
      const labelItem = findLabelsForFinding(roi, crop.findingId);

      return Object.keys(labelItem.labels);
    });

    labels.forEach((uuid) => this.selectedItemLabels.push(uuid));

    this.contextMenuVisible = true;
    this._cdr.detectChanges();

    const rect = this.contextMenuRef?.nativeElement.getBoundingClientRect();

    const { clientHeight, clientWidth } = this.elementRef.nativeElement;

    if (event.clientY + rect.height > clientHeight) this.contextMenuPosition.y -= rect.height;
    if (event.clientX + rect.width > clientWidth) this.contextMenuPosition.x -= rect.width;
  }

  public closeContextMenu(): void {
    this.contextMenuVisible = false;
    this.selectedItemLabels = [];
    this.hoverLabel = "";
    this.contextMenuOrigin = "";
  }

  public getDoubleCheckedOptions(_rois: (POI | ROI)[], options: TaskOption[], selectedCrops: CropInfo[]): string[] {
    if (selectedCrops.length < 2) return [];

    return this.selectedItemLabels.filter((labelId) =>
      this.isLabelInAllCrops(this.findLabel("uuid", labelId, options)?.name, _rois, options, selectedCrops)
    );
  }

  public onMenuClick(
    selectedLabel: TaskOption,
    selectedCategory: TAnalysisProtocolTask,
    protocolLabels: TaskOption[],
    _rois: (POI | ROI)[],
    selectedCrops: CropInfo[],
  ): void {
    const labelsToReplace = selectedCategory.options.filter((option) =>
      this.selectedItemLabels.includes(option.uuid)
    );

    const unselectLabel =
      labelsToReplace.length === 1 &&
      labelsToReplace[0].name === selectedLabel.name;

    const isLabelSelected = this.selectedItemLabels.includes(
      selectedLabel.uuid
    );
    const isInAllCrops = this.isLabelInAllCrops(selectedLabel.name, _rois, protocolLabels, selectedCrops);

    let actions;

    if (isLabelSelected && isInAllCrops) {
      this.selectedItemLabels = this.selectedItemLabels.filter(
        (l) => l !== selectedLabel.uuid
      );

      actions = selectedCrops.map((crop) =>
        changeCropLabels({
          roiId: crop.roiID,
          findingId: crop.findingId,
          newLabel: selectedLabel.uuid,
        })
      );

      if (
        !this.selectedItemLabels.length ||
        this.contextMenuOrigin === selectedLabel.uuid
      )
        this.deselectAll();
    } else if (isLabelSelected) {
      const crops = this.getCropsWithoutLabel(selectedLabel.uuid, _rois, selectedCrops);

      actions = crops.map((crop) =>
        changeCropLabels({
          roiId: crop.roiID,
          findingId: crop.findingId,
          newLabel: selectedLabel.uuid,
        })
      );
    } else if (labelsToReplace.length && !unselectLabel) {
      this.selectedItemLabels = protocolLabels
        .filter((l) => !labelsToReplace.some((rl) => rl.uuid === l.uuid))
        .map((l) => l.uuid);

      actions = selectedCrops.map((crop) =>
        dragCrop({
          roiId: crop.roiID,
          previousLabels: labelsToReplace.map((label) => label.uuid),
          newLabels: [selectedLabel.uuid],
        })
      );

      this.dragDropService.cleanSelectedCrops();

      this.closeContextMenu();
    } else {
      actions = selectedCrops.map((crop) =>
        changeCropLabels({
          roiId: crop.roiID,
          findingId: crop.findingId,
          newLabel: selectedLabel.uuid,
        })
      );

      this.selectedItemLabels.push(selectedLabel.uuid);
    }

    actions.forEach((a) => this._store.dispatch(a));

    // if (unlabeledOrigin)
    this.deselectAll();
  }

  /* DRAG AND DROP FUNCTIONS */

  public onDrop(
    event: MoveEvent,
    visibleSections: string[],
    tasks: TaskOption[],
    selectedCrops: CropInfo[],
    assetProtocol: TAnalysisProtocolTask[],
  ): void {
    const { previousIndex, currentIndex } = event;

    if (previousIndex.section === currentIndex.section) {
      this.unmarkAsDragging();
      this.deselectAll();
      return;
    };

    const originTask = tasks.find(task => task.name === visibleSections[previousIndex.section]);
    const destTask = tasks.find(task => task.name === visibleSections[currentIndex.section]);

    const isOriginUnlabeled = !originTask;
    const isDestUnlabeled = !destTask;

    const originOptions = this.getCategoryOptionsFromLabels([originTask?.uuid], assetProtocol);

    this.dragDropService.onDrop(
      [originTask?.uuid],
      [destTask?.uuid],
      originOptions,
      isOriginUnlabeled,
      isDestUnlabeled,
      selectedCrops,
    );

    this.unmarkAsDragging();
    this.deselectAll();
  }

  public onDragStart(
    indexPath: GridIndexPath,
    mosaics: Record<string, CropInfo[]>,
    tasks: string[],
    selectedCrops: CropInfo[]
  ): void {
    const cropInfo = this.getCellForIndexPath(indexPath, mosaics, tasks);
    const isSelected = this.isSelected(cropInfo, selectedCrops);

    if (this.contextMenuVisible) this.closeContextMenu();
    if (this.cropDetailMenuVisible) this.closeCropDetail();

    if (!isSelected) {
      this.dragDropService.cleanSelectedCrops();
      this.dragDropService.addSelectedCrop(cropInfo);
    }

    if (this.isSelecting && !isSelected)
      this.dragDropService.addSelectedCrop(cropInfo);

    this.markAsDragging(selectedCrops);
  }

  private unmarkAsDragging() {
    this.cropElements.forEach(element => element.nativeElement.classList.remove("dragging"));
  }

  private markAsDragging(selectedCrops: CropInfo[]) {
    selectedCrops.forEach((crop) => {
      const element = this.cropElements.find(ref => ref.nativeElement.getAttribute(this.attrKey) === crop.roiID);
      if (element) element.nativeElement.classList.add("dragging");
    });
  }

  /* HELPER FUNCTIONS */

  public getCropUrl(roiId: string) {
    return localStorage.getItem(`crop_${roiId}`);
  }

  public onScrolledDown(labelsLeft: boolean) {
    if (!labelsLeft) return;
    this._store.dispatch(loadMore({ labelId: undefined }));
  }

  public getLabelsHints(cropInfo: CropInfo, _rois: (POI | ROI)[]): number {
    const labels = Object.keys(this.getLabelsFromRoi(cropInfo, _rois));
    return labels?.length || 0;
  }

  private getLabelsFromRoi(cropInfo: CropInfo, _rois: (POI | ROI)[]) {
    return _rois
      .find((roi) => {
        return roi.id === cropInfo.roiID;
      })
      ?.labels.find((label) => label.findingId === cropInfo.findingId)?.labels;
  }

  public onCropClick(event: MouseEvent, cropInfo: CropInfo, selectedCrops: CropInfo[]): void {
    if (this.cropDetailMenuVisible) this.closeCropDetail();

    if (!this.isSelecting) {
      this.deselectAll();

      this.dragDropService.addSelectedCrop(cropInfo);

      event.stopPropagation();
      return;
    }

    this.isSelected(cropInfo, selectedCrops)
      ? this.dragDropService.removeSelectedCrop(cropInfo)
      : this.dragDropService.addSelectedCrop(cropInfo);

    event.stopPropagation();
  }

  public onOutsideClick(): void {
    if (this.cropDetailMenuVisible) this.closeCropDetail();
    if (this.draggedSelection) {
      //Avoid cleaning selected crops just after of selecting crops through dragging
      this.draggedSelection = false;
      return;
    }
    this.deselectAll();
  }

  @HostListener('window:resize')
  onResize() {
    this.updateCellsPerRow();
    this._cdr.detectChanges();
  }

  @HostListener("document:keydown.meta", ["$event"])
  @HostListener("document:keydown.control", ["$event"])
  @HostListener("document:keydown.shift", ["$event"])
  public onMultiselectShortcutPress(event: KeyboardEvent) {
    this.isSelecting = true;

    if (!event.ctrlKey) return;

    const userAgent = window.navigator.userAgent.toLowerCase();

    this._os = userAgent.includes("windows")
      ? OS.Windows
      : userAgent.includes("mac os")
        ? OS.MacOs
        : OS.Linux;
  }

  @HostListener("document:keydown.meta.s", ["$event"])
  @HostListener("document:keydown.control.s", ["$event"])
  public onSaveShortcut(event: KeyboardEvent) {
    this._store.dispatch(preSyncAnalysis());
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener("document:keydown.escape")
  public onEscape() {
    this.deselectAll();
    this.closeCropDetail();
    this.unmarkAsDragging();
  }

  @HostListener("document:keyup.meta", ["$event"])
  @HostListener("document:keyup.control", ["$event"])
  @HostListener("document:keyup.shift", ["$event"])
  public onMultiselectShortcutRelease(event: KeyboardEvent): void {
    this.isSelecting = false;

    if (!event.ctrlKey) return;

    this._os = undefined;
  }

  public onMouseDown(event: MouseEvent, selectedCrops: CropInfo[]): void {
    if (!this.isSelecting || selectedCrops.length) return;

    event.preventDefault();

    this.isDragging = true;

    const galleryRect =
      this.elementRef.nativeElement.getBoundingClientRect();

    this.startPoint = {
      x: event.clientX - galleryRect.left,
      y: event.clientY - galleryRect.top,
    };

    this.selectionBox.nativeElement.style.position = "absolute";
    this.selectionBox.nativeElement.style.left = `${this.startPoint.x}px`;
    this.selectionBox.nativeElement.style.top = `${this.startPoint.y}px`;
    this.selectionBox.nativeElement.style.width = "0px";
    this.selectionBox.nativeElement.style.height = "0px";
    this.selectionBox.nativeElement.style.display = "block";
  }

  @HostListener("mousemove", ["$event"])
  public onMouseMove(event: MouseEvent): void {
    if (!this.isSelecting || !this.isDragging) return;

    const containerRect =
      this.elementRef.nativeElement.getBoundingClientRect();

    const currentPoint = {
      x: event.clientX - containerRect.left,
      y: event.clientY - containerRect.top,
    };

    const width = currentPoint.x - this.startPoint.x;
    const height = currentPoint.y - this.startPoint.y;

    // Update selection box dimensions
    this.selectionBox.nativeElement.style.width = `${Math.abs(width)}px`;
    this.selectionBox.nativeElement.style.height = `${Math.abs(height)}px`;

    if (width < 0) {
      this.selectionBox.nativeElement.style.left = `${currentPoint.x}px`;
    }
    if (height < 0) {
      this.selectionBox.nativeElement.style.top = `${currentPoint.y}px`;
    }
  }

  public onMouseUp(rois: (POI | ROI)[], options: TaskOption[], selectedCrops: CropInfo[]): void {
    if (this.isDragging && this.isSelecting) {
      this.checkSelection(rois, options);
      this.selectionBox.nativeElement.style.display = "none";
      this.draggedSelection = true;
      this.isDragging = false;
    }

    if (selectedCrops.length > 0) return;

    this.isDragging = false;

    this.selectionBox.nativeElement.style.display = "none";
  }

  public doubleClick(
    cropInfo: CropInfo,
    index: GridIndexPath,
    cropId: string,
    sections: string[],
    _rois: (POI | ROI)[],
    options: TaskOption[],
  ): void {
    if (this.isSelecting) return;

    const label = sections[index.section];

    this.cropDetailMenuVisible = true;
    this.selectedCropUrl = this.getCropUrl(cropInfo.roiID);
    this.selectedCropDetail = {
      currentLabel: label,
      labels: this.getLabelInfoFromCrop(cropInfo, _rois, options),
      cropId,
    };

    this._cdr.detectChanges();
  }

  private checkSelection(_rois: (POI | ROI)[], options: TaskOption[]): void {
    const selectionRect = this.selectionBox.nativeElement.getBoundingClientRect();

    const label = this.findLabel("name", this.elementRef.nativeElement.id, options)?.uuid;

    this.dragDropService.cleanSelectedCrops();

    for (const crop of this.cropElements) {
      const cropRect = crop.nativeElement.getBoundingClientRect();
      const cropId = crop.nativeElement.getAttribute(this.attrKey);

      if (!this.intersects(selectionRect, cropRect)) continue;

      const roi = _rois.find((roi) => roi.id === cropId);
      const score = roi.labels[0].labels[label];
      const roiInfo = {
        roiID: roi.id,
        findingId: roi.labels[0].findingId,
        crop: undefined,
        score,
      };

      this.dragDropService.addSelectedCrop(roiInfo);
    }
  }

  private intersects(rect1: DOMRect, rect2: DOMRect): boolean {
    return !(
      rect1.right < rect2.left ||
      rect1.left > rect2.right ||
      Math.abs(rect1.bottom) < rect2.top ||
      rect1.top > rect2.bottom
    );
  }

  public deselectAll() {
    if (this.contextMenuVisible) this.closeContextMenu();
    this.dragDropService.cleanSelectedCrops();
  }

  private findLabel<K extends keyof TaskOption>(
    key: K,
    value: TaskOption[K],
    tasks: TaskOption[],
  ): TaskOption {
    return tasks.find((t) => t[key] === value);
  }

  public isLabelInAllCrops(
    labelName: string,
    _rois: (POI | ROI)[],
    options: TaskOption[],
    selectedCrops: CropInfo[]
  ): boolean {
    const crops = this.getCropsWithLabel(labelName, _rois, options, selectedCrops);
    return crops.length === selectedCrops.length;
  }

  public getCropsWithLabel(
    labelName: string,
    _rois: (POI | ROI)[],
    options: TaskOption[],
    selectedCrops: CropInfo[]
  ): CropInfo[] {
    const crops = selectedCrops.filter((crop) => {
      const roi = _rois.find((roi) => roi.id === crop.roiID);
      const labels = roi.labels.find(
        (label) => label.findingId === crop.findingId
      ).labels;
      return Object.keys(labels)
        .map((l) => this.findLabel("uuid", l, options)?.name)
        .includes(labelName);
    });

    return crops;
  }

  public getCropsWithoutLabel(labelUuid: string, _rois: (POI | ROI)[], selectedCrops: CropInfo[]): CropInfo[] {
    const crops = selectedCrops.filter((crop) => {
      const roi = _rois.find((roi) => roi.id === crop.roiID);
      const labels = roi.labels.find(
        (label) => label.findingId === crop.findingId
      ).labels;
      return !Object.keys(labels).includes(labelUuid);
    });
    return crops;
  }

  public getComplementaryColor(index: GridIndexPath, visibleSections: string[], protocolOptions: TaskOption[]): string {
    const color = this.getColor(index, visibleSections, protocolOptions);
    return this._roiService.getComplementaryColor(color);
  }

  public getColor(index: GridIndexPath, visibleSections: string[], protocolOptions: TaskOption[]) {
    const sectionName = visibleSections[index.section];
    const protocolOption = protocolOptions.find((option) => option.name === sectionName);

    return this._roiService.getModelColor([protocolOption?.uuid], true);
  }

  public isSelected(cropInfo: CropInfo, selectedCrops: CropInfo[]): boolean {
    return selectedCrops.some((crop) => crop.roiID === cropInfo.roiID);
  }

  public isLabelHover(
    cropInfo: CropInfo,
    _rois: (POI | ROI)[],
    options: TaskOption[],
    selectedCrops: CropInfo[]
  ): boolean {
    const label = this.findLabel("name", this.hoverLabel, options);
    const crops = this.getCropsWithLabel(label?.name, _rois, options, selectedCrops);
    return crops.some((crop) => crop.roiID === cropInfo.roiID);
  }

  private getLabelInfoFromCrop(cropInfo: CropInfo, _rois: (POI | ROI)[], options: TaskOption[]) {
    const labels = this.getLabelsFromRoi(cropInfo, _rois);
    return Object.keys(labels || {}).reduce(
      (acc, l) => ({ ...acc, [this.findLabel("uuid", l, options)?.name]: labels[l] }),
      {}
    );
  }

  public closeCropDetail() {
    this.cropDetailMenuVisible = false;
    this.selectedCropDetail = null;
  }

  public getCellForIndexPath(indexPath: GridIndexPath, mosaics: Record<string, CropInfo[]>, tasks: string[]): CropInfo {
    const section = tasks[indexPath.section];
    const index = indexPath.row * this.cellsPerRow + indexPath.column;
    return mosaics[section]?.[index];
  }

  private calculateCellsPerRow(): number {
    const componentWidth = this.elementRef?.nativeElement?.clientWidth ?? window.innerWidth;
    return Math.floor((componentWidth - this.cellSize / 3) / (this.cellSize * this.scale));
  }

  private updateCellsPerRow() {
    this.cellsPerRow = this.calculateCellsPerRow();
    this._cdr.detectChanges();
  }

  public updateScale(value: number) {
    this.scale = value;
    this.updateCellsPerRow();
  }

  public getCategoryOptionsFromLabels(origin: string[], assetProtocol: TAnalysisProtocolTask[]): TaskOption[] {
    const originOptions = [];

    origin.forEach((uuid) => {
      const foundTask = assetProtocol.find((task) =>
        task.options.some((opt) => opt.uuid === uuid)
      );
      if (!foundTask) return;

      originOptions.push(...foundTask.options);
    });

    return originOptions;
  }
}
