import { Label } from "@telespot/domain";
import { ParseMapper } from "../../parse.mapper";

export enum LabelTopology {
  TABLE = "Label",
  UUID = "uuid",
  LANG = "lang",
  VALUE = "value",
}

export class ParseLabelMapper extends ParseMapper<Label> {
  public toDomain(parseObject: Parse.Object): Label {
    return new Label({
      id: parseObject.id,
      uuid: parseObject.get(LabelTopology.UUID),
      value: parseObject.get(LabelTopology.VALUE),
      lang: parseObject.get(LabelTopology.LANG),
    });
  }

  public fromDomain(label: Label) {
    const ParseLabel = this.subclasses.getSubclass(LabelTopology.TABLE);

    const parseObject = label.id
      ? ParseLabel.createWithoutData(label.id)
      : new ParseLabel();

    parseObject.set(LabelTopology.UUID, label.uuid);
    parseObject.set(LabelTopology.LANG, label.lang);
    parseObject.set(LabelTopology.VALUE, label.value);

    return parseObject;
  }
}
