<ng-container *ngIf="!embedInCard; else standaloneFormTemplate">
  <div mat-dialog-title class="main-title">
    <h1>{{ 'Resource editor' | translate }}</h1>
  </div>
  <div mat-dialog-content class="card">
    <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
  </div>
  <mat-dialog-actions class="justify-content-around">
    <ng-container *ngTemplateOutlet="formActionsTemplate"></ng-container>
  </mat-dialog-actions>
</ng-container>

<ng-template #standaloneFormTemplate>
  <div class="main-container">
    <div class="card">
      <div class="card-body">
        <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
      </div>
      <div class="card-body">
        <div class="flex-row align-items-center justify-content-around">
          <ng-container *ngTemplateOutlet="formActionsTemplate"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #formTemplate>
  <div class="card-body" style="overflow-x: auto" *ngIf="form">
    <form [formGroup]="form">
      <mat-error *ngIf="form.touched && form.errors">
        {{ form.errors?.form }}
      </mat-error>

      <mat-form-field class="full-width" [floatLabel]="'always'">
        <mat-label>{{ 'core.resource.name' | translate }}</mat-label>
        <input matInput data-cy="resource-name" type="text" formControlName="name" />
      </mat-form-field>

      <mat-error *ngIf="form.touched && form.controls['name'].errors">
        {{ 'name is required' }}
      </mat-error>

      <mat-form-field class="full-width" [floatLabel]="'always'">
        <mat-label>{{ 'core.resource.type' | translate }}</mat-label>
        <mat-select
          aria-label="type"
          formControlName="type"
          [compareWith]="same"
          placeholder="Select one option"
          data-cy="resource-type"
          (valueChange)="updateDestKeys($event)"
          aria-label="type"
        >
          <mat-option *ngFor="let type of types" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-error *ngIf="form.touched && form.controls['type'].errors">
        {{ 'type is required' }}
      </mat-error>

      <ng-container
        *ngIf="form.value.type === 'mask' || form.value.type === 'response' || form.controls.type.status === 'DISABLED'"
      >
        <label>{{ 'label.display_name' | translate }}</label>
        <ts-language-input [languages]="supportedLanguages" formControlName="displayName"></ts-language-input>

        <mat-error *ngIf="form.touched && form.controls['displayName'].errors?.displayNameVal === 'INVALID'">
          {{ 'You must define at least one translation' }}
        </mat-error>
      </ng-container>

      <ng-container *ngIf="form.value.type !== null">
        <div *ngIf="preview$ | async as resourcePreview" class="flex-row justify-content-center">
          <img [src]="resourcePreview" alt="" class="resource-preview" />
        </div>

        <div class="filedrop-area flex-column align-items-center" tsFileDrop (filesDropped)="updateFile($event)">
          <p class="text-gray">{{ fileInput.value ? valueInput.value : ('label.drop_files_here' | translate) }}</p>
          <button class="btn btn-primary" type="button" (click)="fileInput.click()">
            {{ 'label.upload_files' | translate }}
          </button>
        </div>
        <input type="file" hidden #fileInput [attr.multiple]="false" (change)="updateFile($event.target.files)" />
        <mat-error *ngIf="form.controls['file'].errors">
          {{ 'label.resource_upload_failed' | translate }}: {{ form.controls['file'].errors?.upload | translate }}
        </mat-error>

        <mat-form-field hidden class="full-width" [floatLabel]="'always'">
          <mat-label *ngIf="form.value.type === 'mask'">{{ 'core.value' | translate }}</mat-label>
          <mat-label *ngIf="form.value.type === 'response'">{{ 'core.value' | translate }}</mat-label>
          <input matInput type="text" formControlName="value" #valueInput />
        </mat-form-field>

        <mat-error hidden *ngIf="form.touched && form.controls['value'].errors">
          {{ 'value is required' }}
        </mat-error>
      </ng-container>

      <ng-container
        *ngIf="form.value.type === 'mask' || form.value.type === 'response' || form.controls.type.status === 'DISABLED'"
      >
        <mat-form-field class="full-width" [floatLabel]="'always'">
          <mat-label *ngIf="form.value.type === 'mask'">{{ 'core.value.mask' | translate }}</mat-label>
          <mat-label *ngIf="form.value.type === 'response' || form.controls.type.status === 'DISABLED'">{{
            'core.value.response' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="label" #valueInput />
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="form.value.type !== 'mask' && form.value.type !== null">
        <mat-form-field class="full-width" [floatLabel]="'always'">
          <mat-label>{{ 'core.description' | translate }}</mat-label>
          <input matInput type="text" formControlName="description" />
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="form.value.type !== null">
        <mat-form-field class="full-width" [floatLabel]="'always'">
          <mat-label>{{ 'core.version' | translate }}</mat-label>
          <input matInput type="number" formControlName="version" />
        </mat-form-field>
      </ng-container>

      <mat-error *ngIf="form.touched && form.controls['version'].errors">
        {{ 'version is required' }}
      </mat-error>

      <ng-container *ngIf="form.value.type === 'mask'">
        <mat-form-field class="full-width" [floatLabel]="'always'">
          <mat-label>{{ 'core.coordinates' | translate }}</mat-label>
          <textarea type="text" matInput formControlName="coordinates" cdkTextareaAutosize (change)="formatConfig()">
          </textarea>
        </mat-form-field>
        <mat-error *ngIf="form.controls.coordinates.errors?.invalidJSON"> Invalid JSON </mat-error>

        <ts-mask-results-assignment [resources]="form?.controls.resources.value" (resultsChanged)="updateResults($event)">
        </ts-mask-results-assignment>
      </ng-container>
    </form>
  </div>
</ng-template>

<ng-template #formActionsTemplate>
  <button class="btn btn-outline-danger" (click)="cancel()" type="button" data-cy="button-cancel-resource">
    <i class="ri-close-fill"></i>
    {{ 'button.cancel' | translate }}
  </button>
  <button
    class="btn btn-primary"
    [disabled]="!form || !form.valid"
    type="button"
    data-cy="button-save-resource"
    (click)="submit()"
  >
    <ng-template *ngIf="saving$ | async; else save">
      <i class="ri-loader-4-line spin"></i>
      {{ 'Saving' | translate }}...
    </ng-template>
    <ng-template #save>
      <i class="ri-save-line"></i>
      {{ 'button.save' | translate }}
    </ng-template>
  </button>
</ng-template>
