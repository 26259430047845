import { Injectable } from "@angular/core";
import { BehaviorSubject, merge, Observable } from "rxjs";
import {
  distinctUntilChanged,
  map,
  mapTo,
  shareReplay,
  skip,
} from "rxjs/operators";

import { IAssetMask, IAssetMaskState } from "../../models/asset-mask";
import { TSegmentationAction } from "../../models/segmentation-action";

@Injectable({
  providedIn: "root",
})
export class MasksPluginService {
  private _masks$ = new BehaviorSubject<IAssetMaskState[]>([]);

  private _opacity$ = new BehaviorSubject<number>(0.5);
  private _strokeWidth$ = new BehaviorSubject<number>(20);

  public readonly masks$: Observable<IAssetMaskState[]> =
    this._masks$.asObservable();

  private _show$ = new BehaviorSubject<boolean>(false);

  public readonly show$ = merge(
    this._show$,
    this._opacity$.pipe(skip(1), mapTo(true))
  ).pipe(distinctUntilChanged());

  public readonly available$: Observable<boolean> = this._masks$.pipe(
    map((masks) => !!masks.length)
  );
  public readonly opacity$ = this._opacity$.asObservable();
  public readonly strokeWidth$ = this._strokeWidth$.asObservable();
  private _activeSegmentationMode$ = new BehaviorSubject<TSegmentationAction>(
    TSegmentationAction.paint
  );
  activeSegmentationMode$ = this._activeSegmentationMode$
    .asObservable()
    .pipe(distinctUntilChanged(), shareReplay(1));

  setAvailableMasks(masks: IAssetMask[]) {
    this._masks$.next(masks.map((m) => ({ ...m, visible: true })));
  }

  public toggleSegmentationMode(mode: TSegmentationAction) {
    this._activeSegmentationMode$.next(mode);
  }
  public toggleMask(mask: IAssetMaskState, visible: boolean = !mask.visible) {
    mask.visible = visible;
    this._masks$.next(this._masks$.value);
  }

  public toggleLayers(show = !this._show$.value) {
    this._show$.next(show);
  }

  public changeOpacity(value: number) {
    this._opacity$.next(value);
  }

  public changeStrokeWidth(value: number) {
    this._strokeWidth$.next(value);
  }
}
