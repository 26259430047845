import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Case, Query } from '@telespot/sdk';
import { from, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AuthService, DataService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class CaseResolver implements Resolve<Case> {
  constructor(private _dataService: DataService, private _authService: AuthService) {}
  resolve(route: import('@angular/router').ActivatedRouteSnapshot): Observable<Case> {
    if (this._authService.currentUser.isOnly('guest')) return;
    const id = route.params['caseid'];
    return from(
      this._dataService.get(id, new Query(Case).include(['caseType.methodTypes.assetType', 'workspace', 'createdBy']))
    ).pipe(
      switchMap(async (_case) => {
        return _case.fetchWithInclude('methodTypes.assetType');
      }),
      tap((_case) => this._authService.setOrganization(_case.organization))
    );
  }
}
