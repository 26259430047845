import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Case, Device } from "@telespot/sdk";
import { TMethodGroup, TSampleGroup } from "../../models/forms-config";

@Component({
  selector: "ts-samples-form",
  templateUrl: "./samples-form.component.html",
  styleUrls: ["./samples-form.component.scss"],
})
export class SamplesFormComponent {
  /** PRIVATE VARIABLES */
  private _case: Case;

  /** INPUTS & OUTPUTS VARIABLES */
  @Input()
  set case(_case: Case) {
    if (!_case) return;
    this._case = _case;
  }

  get case() {
    return this._case;
  }

  @Input() methodGroups: TMethodGroup[];
  @Input() devices: Device[];
  @Output() addSample = new EventEmitter<TMethodGroup>();
  @Output() editSample = new EventEmitter<TSampleGroup>();
  @Output() deleteSample = new EventEmitter<[TMethodGroup, TSampleGroup]>();

  /* UI Handlers Methods */

  add(methodGroup: TMethodGroup) {
    this.addSample.emit(methodGroup);
  }

  edit(sampleData: TSampleGroup) {
    this.editSample.emit(sampleData);
  }

  delete(methodGroup: TMethodGroup, sample: TSampleGroup) {
    this.deleteSample.emit([methodGroup, sample]);
  }
}
