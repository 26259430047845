import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldType, TFieldTypeName } from '@telespot/sdk';

@Component({
  selector: 'ts-field-types',
  templateUrl: './field-types.component.html',
  styleUrls: ['./field-types.component.css'],
})
export class FieldTypesComponent {
  // UI
  fieldEditorVisible = false;

  // Data
  dataField: FieldType;

  // I/O
  @Input() caseFields: FieldType[];
  @Output() caseFieldsOrderChanged = new EventEmitter<FieldType[]>();
  @Output() caseFieldsSelectedForEditing = new EventEmitter<FieldType>();

  processDrag(event: CdkDragDrop<FieldType[]>) {
    moveItemInArray(this.caseFields, event.previousIndex, event.currentIndex);
    this.caseFieldsOrderChanged.emit([...this.caseFields]);
  }

  create() {
    this.edit(null);
  }

  // ** Sets the dataField to edit and shows the field editor component */
  edit(dataField: FieldType) {
    this.caseFieldsSelectedForEditing.emit(dataField);
  }

  // ** Notifies the parent component */
  remove(dataField: FieldType) {
    if (dataField.type === TFieldTypeName.case_name) {
      return;
    }
    this.caseFieldsOrderChanged.emit(this.caseFields.filter((f) => f !== dataField));
  }

  trackFcn(index, item) {
    return index;
  }
}
