<ng-container *ngIf="multiModelSelection$ | async">
  <ng-container *ngIf="{ selectedLabels: selectedLabels$ | async } as data">
    <div class="roi-seletion-panel-container custom-scrollbar">
      <div class="flex-wrap align-items-start text-container">
        <span [style.font-size]="'14px'">{{
          "label.tags_selected" | translate | uppercase
        }}</span>
        <span [style.font-size]="'small'" [style.color]="'rgb(0, 153, 187)'"
          ><a [style.cursor]="'pointer'" (click)="clearLabels()">{{
            "label.tags_clear" | translate
          }}</a>
        </span>
      </div>

      <div
        class="flex-row flex-wrap align-items-start labels-container custom-scrollbar"
      >
        <ng-container *ngFor="let selectedLabel of data?.selectedLabels">
          <span class="roi-badge">
            <span
              class="roi-badge__label"
              [style.borderColor]="getLabelColor(selectedLabel)"
              [style.color]="getLabelColor(selectedLabel)"
              >{{ selectedLabel.value }}
              <i
                class="ri-close-fill roi-badge__close-icon"
                (click)="clearLabel(selectedLabel)"
              ></i>
            </span>
          </span>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
