import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { UsedStorageComponentModule, UserAvatarModule, RoleBadgeModule } from '@shared/ui';
import { StoragePipeModule } from '@telespot/shared/util';

import { BillingComponent } from './components/billing/billing.component';
import { DevicesComponent } from './components/devices/devices.component';
import { OrganizationCasetypesComponent } from './components/organization-casetypes/organization-casetypes.component';
import { OrganizationDetailComponent } from './components/organization-detail/organization-detail.component';
import { OrganizationEditorComponent } from './components/organization-editor/organization-editor.component';
import { OrganizationsListComponent } from './components/organizations-list/organizations-list.component';
import { UserStatsComponent } from './components/user-stats/user-stats.component';
import { WorkspacesStatsComponent } from './components/workspaces-stats/workspaces-stats.component';
import { TelespotDashboardRoutingModule } from './telespot-dashboard-routing.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    UserAvatarModule,
    UsedStorageComponentModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    MatTabsModule,
    TelespotDashboardRoutingModule,
    StoragePipeModule,
    RoleBadgeModule,
  ],
  declarations: [
    OrganizationEditorComponent,
    WorkspacesStatsComponent,
    BillingComponent,
    UserStatsComponent,
    OrganizationsListComponent,
    OrganizationDetailComponent,
    OrganizationCasetypesComponent,
    DevicesComponent,
  ],
})
export class TelespotDashboardFeatureModule {}
