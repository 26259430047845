import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SupportedLanguage, SUPPORTED_LANGUAGES } from '@shared/localization';
import { DataService, FileUploaderService } from '@telespot/web-core';
import { Resource, Query } from '@telespot/sdk';
import { environment } from '@telespot/shared/environment';
import { PreprocessFilePipe } from '@telespot/shared/util';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { JSONValidator } from '../../util/validator-json';

@Component({
  selector: 'ts-resource-editor',
  templateUrl: './resource-editor.component.html',
  styleUrls: ['./resource-editor.component.scss'],
  providers: [PreprocessFilePipe],
})
export class ResourceEditorComponent implements OnInit {
  private _destroy$ = new Subject<void>();
  private _preview$ = new Subject<SafeResourceUrl | string>();
  public readonly preview$ = this._preview$.asObservable().pipe(distinctUntilChanged());
  private _resource: Resource;
  @Input() set resource(resource: Resource) {
    this._resource = resource;
    this.setupForm();
  }
  get resource() {
    return this._resource;
  }
  @Input() embedInCard = true;
  @Output() submitted = new EventEmitter<Resource>();

  private _saving$ = new Subject<boolean>();
  public readonly saving$ = this._saving$.asObservable();

  public readonly types: string[] = ['mask', 'response', 'tflite', 'tflite_config'];

  private _endpoint = environment.api.url + '/support-files/';

  form: UntypedFormGroup;

  constructor(
    private dataService: DataService,
    private _sanitizer: DomSanitizer,
    private _preprocessPipe: PreprocessFilePipe,
    private _uploader: FileUploaderService,
    @Inject(SUPPORTED_LANGUAGES) public supportedLanguages: SupportedLanguage[]
  ) {}

  ngOnInit() {
    this.setupForm();
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  displayNameValidatorFactory = (supportedLanguages: SupportedLanguage[]) => (control: UntypedFormControl) => {
    return supportedLanguages.some((languageDescription) => control.value[languageDescription.value])
      ? null
      : { displayNameVal: 'INVALID' };
  };
  setupForm() {
    if (!this.resource) return;
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.resource.name, Validators.required),
      value: new UntypedFormControl(this.resource.value),
      label: new UntypedFormControl(this.resource.metadata?.label),
      file: new UntypedFormControl(),
      version: new UntypedFormControl(this.resource.version ?? 1, Validators.required),
      description: new UntypedFormControl(this.resource.description ?? ''),
      type: new UntypedFormControl(this.resource.type, Validators.required),
      coordinates: new UntypedFormControl(
        JSON.stringify(
          {
            offsetXmin: Number(this.resource.metadata?.offsetXmin ?? 0.31),
            offsetYmin: Number(this.resource.metadata?.offsetYmin ?? 0.31),
            offsetXmax: Number(this.resource.metadata?.offsetXmax ?? 0.65),
            offsetYmax: Number(this.resource.metadata?.offsetYmax ?? 0.64),
          },
          undefined,
          2
        ),
        [JSONValidator]
      ),
      resources: new UntypedFormControl(this.resource.resources),
      displayName: new UntypedFormControl(this.resource?.displayName ?? ''),
    });

    this.form.controls['value'].disable();

    this.form.controls['type'].valueChanges.subscribe((value) => {
      if (value === 'response' || value === 'mask') {
        this.form.controls['value'].setValidators([Validators.required]);
        this.form.controls['label'].setValidators([Validators.required]);
        this.form.controls['displayName'].setValidators(this.displayNameValidatorFactory(this.supportedLanguages));
      } else {
        this.form.controls['value'].clearValidators();
        this.form.controls['label'].clearValidators();
        this.form.controls['displayName'].clearValidators();
      }

      this.form.controls['value'].updateValueAndValidity();
      this.form.controls['label'].updateValueAndValidity();
      this.form.controls['displayName'].updateValueAndValidity();
    });

    if (this.resource.type === 'response') {
      this.form.controls['type'].disable();
    }

    if (this.resource.value?.length) {
      // REVIEW: I don't like using the preprocessPipe here
      this._preprocessPipe
        .transform(encodeURI(`${this._endpoint}${this.resource.value.replace('/files', '')}`))
        .pipe(takeUntil(this._destroy$))
        .subscribe((url) => this._preview$.next(url));
    }
  }

  async submit() {
    this._saving$.next(true);
    if (this.form.controls['file'].dirty) {
      const uploadUrl = await this._uploadFile(this.form.value.file).catch((err) => {
        this.form.controls['file'].setErrors({
          upload: 'server error',
        });
        return;
      });
      if (!uploadUrl) return;
      this.form.controls['value'].enable();
      this.form.patchValue({
        value: uploadUrl,
      });
    }
    const { name, label, value, version, description, type, coordinates, displayName } = this.form.value;
    this.resource.name = name;
    this.resource.value = value;
    this.resource.metadata = { label: label };

    if (type === 'mask') {
      this.resource.metadata = {
        label: label,
        offsetXmin: Number(JSON.parse(coordinates)['offsetXmin']),
        offsetYmin: Number(JSON.parse(coordinates)['offsetYmin']),
        offsetXmax: Number(JSON.parse(coordinates)['offsetXmax']),
        offsetYmax: Number(JSON.parse(coordinates)['offsetYmax']),
      };
    }
    this.resource.version = version;
    this.resource.description = description;

    if (this.form.controls['type'].status !== 'DISABLED') {
      this.resource.type = type;
    }

    if (this.resource.type === 'mask' || this.resource.type === 'response') {
      this.resource.displayName = displayName;
    }

    if (this.resource.dirty()) {
      await this.dataService.save(this.resource);
    }
    this._saving$.next(false);
    this.submitted.emit(this.resource);
  }

  private async _uploadFile(file: File): Promise<string> {
    return this._uploader.upload(file, 'support-files');
  }

  cancel() {
    this.resource.revert();
    this.submitted.emit(null);
  }
  same = (el1, el2) => el1 === el2;

  updateDestKeys(type) {
    this.form.patchValue({
      type,
    });
  }

  updateFile(files: File[] | FileList) {
    this.form.controls['file'].setErrors(undefined);
    const file = files[0];
    this.form.patchValue({ file: file, value: file.name });
    this.form.controls['file'].markAsDirty();
    if (file.type.startsWith('image/')) {
      const preview = this._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(files[0]));
      this._preview$.next(preview);
    } else {
      this._preview$.next(undefined);
    }
    this.form.controls['value'].disable();
  }

  updateResults(resources: Resource[]) {
    this.resource.resources = resources;
    this.form.markAsDirty();
  }

  formatConfig() {
    if (!this.form.controls['coordinates'].errors) {
      this.form.patchValue({
        config: JSON.stringify(JSON.parse(this.form.value.coordinates), undefined, 2),
      });
    }
  }
}
