<div
  class="viewport"
  *ngIf="{
    protocolOptions: protocolOptions$ | async,
    assetProtocol: assetProtocol$ | async,
    loading: loading$ | async,
    cropsPerLabel: cropsPerLabel$ | async,
    visibleSections: visibleSections$ | async,
    cropCountPerSection: cropCountPerSection$ | async,
    labelsLeft: labelsLeft$ | async,
    rois: rois$ | async,
    selectedCrops: selectedCrops$ | async
  } as state"
>
  <ts-grid-view
    (mouseup)="
      onMouseUp(state.rois, state.protocolOptions, state.selectedCrops)
    "
    (mousedown)="onMouseDown($event, state.selectedCrops)"
    [paddingNodes]="5"
    [draggingDisabled]="isSelecting"
    [rowNumeralTemplate]="rowNumber"
    [headerTemplate]="headerRow"
    [cellTemplate]="mosaicCell"
    [cellSize]="cellSize * scale"
    [cellGap]="4"
    [cellsPerRow]="cellsPerRow"
    [cellsPerSection]="state.cropCountPerSection"
    [scrollThreshold]="0.3"
    (scrollThresholdReached)="onScrolledDown(state.labelsLeft)"
    (cellMoved)="
      onDrop(
        $event,
        state.visibleSections,
        state.protocolOptions,
        state.selectedCrops,
        state.assetProtocol
      )
    "
    (cellDraggingStarted)="
      onDragStart(
        $event,
        state.cropsPerLabel,
        state.visibleSections,
        state.selectedCrops
      )
    "
  ></ts-grid-view>

  <ng-template #headerRow let-index let-sticky="isSticky">
    <div class="flexed">
      <div class="gallery-label" *ngIf="state.cropCountPerSection[index] > 0">
        {{ state.visibleSections[index] }}
      </div>

      <ts-slider
        *ngIf="!!sticky"
        [minValue]="1"
        [maxValue]="2"
        [stepValue]="0.5"
        [value]="scale"
        (valueChange)="updateScale($event)"
      ></ts-slider>
    </div>
  </ng-template>

  <ng-template #mosaicCell let-index>
    <ng-container
      *ngIf="
        getCellForIndexPath(
          index,
          state.cropsPerLabel,
          state.visibleSections
        ) as cropInfo
      "
    >
      <div
        class="mosaic-item"
        [style.border]="
          isSelected(cropInfo, state.selectedCrops)
            ? '3px solid ' +
              getColor(index, state.visibleSections, state.protocolOptions)
            : 'none'
        "
        [style.borderColor]="
          isLabelHover(
            cropInfo,
            state.rois,
            state.protocolOptions,
            state.selectedCrops
          ) && state.selectedCrops.length > 1
            ? getComplementaryColor(
                index,
                state.visibleSections,
                state.protocolOptions
              )
            : getColor(index, state.visibleSections, state.protocolOptions)
        "
        [style.boxShadow]="
          isSelected(cropInfo, state.selectedCrops)
            ? isLabelHover(
                cropInfo,
                state.rois,
                state.protocolOptions,
                state.selectedCrops
              ) && state.selectedCrops.length > 1
              ? '0 0 5px 2px ' +
                getComplementaryColor(
                  index,
                  state.visibleSections,
                  state.protocolOptions
                )
              : '0 0 5px 2px ' +
                getColor(index, state.visibleSections, state.protocolOptions)
            : 'none'
        "
        (click)="onCropClick($event, cropInfo, state.selectedCrops)"
        (contextmenu)="
          openContextMenu($event, cropInfo, state.rois, state.selectedCrops)
        "
        (dblclick)="
          doubleClick(
            cropInfo,
            index,
            cropInfo.crop.slice(-5),
            state.visibleSections,
            state.rois,
            state.protocolOptions
          )
        "
        [attr.roi-id]="cropInfo.roiID"
        #cropElement
      >
        <ts-image
          [url]="getCropUrl(cropInfo.roiID)"
          [placeholderTemplate]="imageLoader"
        >
          <ts-badge [count]="getLabelsHints(cropInfo, state.rois)"></ts-badge>
        </ts-image>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #imageLoader>
    <div class="diagonal-line"></div>
  </ng-template>

  <ng-template #rowNumber let-index>
    <div
      class="row-marker-container"
      [style.background-color]="
        getColor(index, state.visibleSections, state.protocolOptions)
      "
    >
      <span class="row-marker">{{ index.row * cellsPerRow + 1 }}</span>
    </div>
  </ng-template>

  <div #selectionBox class="selection-box"></div>

  <div class="sticky-bottom" *ngIf="state.loading">
    <mat-progress-bar mode="query"></mat-progress-bar>
  </div>

  <div class="selected-badge" *ngIf="state.selectedCrops.length > 0">
    <div>
      {{ state.selectedCrops.length }} {{ "info.selected_items" | translate }}
    </div>
    <button (click)="deselectAll()">
      {{ "button.unselect_all" | translate }}
    </button>
  </div>

  <ts-label-context-menu
    *ngIf="contextMenuVisible"
    [categories]="state.assetProtocol"
    [checkedOptions]="selectedItemLabels"
    [doubleCheckedOptions]="
      getDoubleCheckedOptions(
        state.rois,
        state.protocolOptions,
        state.selectedCrops
      )
    "
    (optionSelected)="
      onMenuClick(
        $event.option,
        $event.category,
        state.protocolOptions,
        state.rois,
        state.selectedCrops
      )
    "
    (optionHovered)="hoverLabel = $event.value"
    (optionUnhovered)="hoverLabel = ''"
    #contextMenu
    class="fixed"
    [style.top.px]="contextMenuPosition.y"
    [style.left.px]="contextMenuPosition.x"
  ></ts-label-context-menu>

  <ts-crop-detail
    *ngIf="cropDetailMenuVisible"
    [imageUrl]="selectedCropUrl"
    [cropDetail]="selectedCropDetail"
    (closeDetail)="closeCropDetail()"
  ></ts-crop-detail>
</div>
