import type Parse from "parse";

import { SessionItem, ComparableDate } from "@telespot/domain";

export enum SessionTopology {
  TOKEN = "sessionToken",
  EXPIRES_AT = "expiresAt",
  USER = "user",
}

export class ParseSessionMapper {
  public static toView(session: Parse.Session): SessionItem {

    const currentTime = new Date();

    const createdAt = ComparableDate.create(session.get(SessionTopology.EXPIRES_AT));

    return {
      userId: session.get(SessionTopology.USER).id,
      token: session.get(SessionTopology.TOKEN),
      sessionId: session.id,
      isCurrent: false,
      created: ComparableDate.create(currentTime).daysSince(session.createdAt),
      expiresIn: createdAt.daysSince(currentTime) ? 0 : createdAt.daysSince(currentTime),
      device: null,
      isExpired: createdAt.isBefore(currentTime),
    }
  }
}
