import { Injectable } from "@angular/core";
import { AuthService, DataService } from "@telespot/web-core";
import {
  AnalysisState,
  Asset,
  Case,
  Query,
  Sample,
  SampleAsset,
  SecondOpinion,
  StateName,
  Workspace,
} from "@telespot/sdk";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import {
  BehaviorSubject,
  combineLatest,
  defer,
  from,
  Observable,
  Subject,
} from "rxjs";
import { shareReplay, skipWhile, startWith, switchMap } from "rxjs/operators";

import { ISampleState } from "../../models/i-sample-state";
import { TiraspotService } from "../../tiraspot/services/tiraspot.service";
import { Store } from "@ngrx/store";
import {
  caseAnalysisState,
  caseAnalysisStateFetched,
  checkCaseAnalysisState,
} from "../../+state";

@Injectable({
  providedIn: "root",
})
export class CaseAnalysisService {
  constructor(
    private _dataService: DataService,
    private _authService: AuthService,
    private _logger: LoggerService,
    private _tiraspotService: TiraspotService,
    private _store: Store
  ) {
    this.currentUserState$.subscribe((analysisState) => {
      this._caseStateValue = analysisState;
    });
  }

  private readonly _updateSecondOptions$ = new Subject<void>();

  // Case
  private _caseB$ = new BehaviorSubject<Case>(undefined);
  readonly case$ = this._caseB$
    .asObservable()
    .pipe(skipWhile((_case) => !_case));

  // Analysis state
  public readonly allUsersStates$: Observable<AnalysisState[]> =
    this.case$.pipe(
      switchMap((_case) => this._getUserStates(_case)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  public readonly currentUserState$: Observable<AnalysisState> =
    this._store.select(caseAnalysisState);

  // Second Opinion
  readonly secondOpinions$ = combineLatest([
    this.case$,
    this._updateSecondOptions$.asObservable().pipe(startWith([null])),
  ]).pipe(switchMap(([_case, _]) => this._loadSecondOpinions()));

  // Case samples
  public readonly samples$ = this.case$.pipe(
    switchMap((_case) => this._loadCaseSamples({ obtainedCase: _case })),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _caseStateValue: AnalysisState;

  private _getUserStates(_case): Observable<AnalysisState[]> {
    if (!_case) return new Observable<undefined>();
    return from(
      this._dataService.find(
        new Query(AnalysisState)
          .equalTo("case", _case)
          .include("user")
          .ascending("state")
      )
    ).pipe();
  }

  public getWorkspace(id: string) {
    return from(
      this._dataService.first(
        new Query(Workspace).equalTo("objectId", id).include("caseType.fields")
      )
    );
  }

  // Change case status in other screens
  public setCase(_case: Case) {
    this._logger.info(`Loading case "${_case?.name}"`);
    this._store.dispatch(checkCaseAnalysisState({ caseId: _case.id }));
    this._caseB$.next(_case);
  }

  public async updateUserState(state: StateName) {
    if (this._caseStateValue) {
      this._caseStateValue.state = state;
      await this._dataService.save(this._caseStateValue);
    }
    this._store.dispatch(
      caseAnalysisStateFetched({ analysisState: this._caseStateValue })
    );
  }

  public checkUserAnalysisState(caseId: string) {
    if (!caseId) return;
    const _case = Case.createWithoutData(caseId);
    const caseAnalysisStateQuery = new Query(AnalysisState)
      .equalTo("case", _case.toPointer())
      .equalTo("user", this._authService.currentUser);

    return defer(() => this._dataService.first(caseAnalysisStateQuery));
  }

  public createCaseAnalysisState(caseId: string) {
    return defer(() =>
      this._dataService.save(
        new AnalysisState(
          this._authService.currentUser,
          Case.createWithoutData(caseId),
          StateName.read
        )
      )
    );
  }

  public fecthSecondOptions() {
    this._updateSecondOptions$.next();
  }

  private _loadSecondOpinions(): Promise<SecondOpinion[]> {
    return this._authService.currentOrganizationValue?.license?.features
      ?.second_opinion
      ? this._dataService.find(
          new Query(SecondOpinion)
            .equalTo("createdBy", this._authService.currentUser)
            .matchesQuery(
              "sample",
              new Query(Sample).equalTo("case", this._caseB$.value)
            )
            .include(["sentTo", "sample", "analysisTypes"])
        )
      : Promise.resolve([]);
  }

  private async _loadCaseSamples({
    obtainedCase,
  }: {
    obtainedCase: Case;
  }): Promise<ISampleState[]> {
    this._logger.debug(`Loading case ${obtainedCase?.id} samples...`);
    if (!obtainedCase) {
      return [];
    }

    const sampleQuery = new Query(Sample)
      .equalTo("case", obtainedCase)
      .include(["methodType.assetType", "device.deviceType"])
      .doesNotMatchKeyInQuery(
        "objectId",
        "sample.objectId",
        new Query(SecondOpinion).matchesQuery(
          "sample",
          new Query(Sample).equalTo("case", this._caseB$.value)
        )
      )
      .doesNotMatchKeyInQuery(
        "objectId",
        "sample.objectId",
        new Query(SampleAsset)
          .matchesQuery(
            "sample",
            new Query(Sample).equalTo("case", this._caseB$.value)
          )
          .startsWith("tag", "scan-")
      );
    const samples = await this._dataService.find(sampleQuery);

    // Retrieve sample analysis status
    const sampleStatus = this._authService.currentUser
      ? await this._dataService.find(
          new Query(AnalysisState)
            .equalTo("user", this._authService.currentUser.toPointer())
            .include("sample")
            .containedIn(
              "sample",
              (samples || []).map((s) => s.toPointer())
            )
        )
      : [];

    return Promise.all(
      samples.map(async (sample) => {
        // REVIEW:
        // Look for first favourite asset
        const favQuery = new Query(Asset)
          .equalTo("case", obtainedCase.toPointer())
          .equalTo("fav", true);
        const favSampleAsset = (await this._dataService.first(
          new Query(SampleAsset)
            .ascending(["index", "dateOfCapture"])
            .equalTo("sample", sample)
            .matchesQuery("asset", favQuery)
            .include(["asset"])
        )) as SampleAsset;
        // ... retrieve the first asset otherwise
        const firstSampleAsset = !favSampleAsset
          ? ((await this._dataService.first(
              new Query(SampleAsset)
                .equalTo("sample", sample)
                .ascending(["index", "dateOfCapture"])
                .include(["asset"])
            )) as SampleAsset)
          : undefined;
        const sampleAssets = favSampleAsset || firstSampleAsset;
        if (!sampleAssets) {
          if (sample.assets?.length) {
            this._logger.warn(
              "COMPATIBILITY: SampleAssets not found, retrieving legacy assets"
            );
            // Fetch first asset only
            const firstAsset = sample.assets[0];
            await firstAsset.fetch();
          }
        } else {
          sample.assets = [sampleAssets.asset];
        }

        const sampleData: ISampleState = {
          sample,
          state: sampleStatus.find((ss) => ss.sample.id === sample.id),
        };
        // Tiraspot
        if (obtainedCase.workspace?.config?.environment === "tiraspot") {
          const tiraspotSampleData = await this._tiraspotService
            .getSampleData(sample)
            .toPromise();
          sampleData.warning = tiraspotSampleData.some((td) => td.disagreement);
          sampleData.corrected = tiraspotSampleData.some(
            (td) => !!td.correctionAnalysis?.length
          );
        }
        return sampleData;
      })
    );
  }

  public clear() {
    this._caseB$.next(undefined);
  }
}
