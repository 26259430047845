<div class="card">
  <div class="card-body2">
    <ts-data-table 
      mat-table 
      width="100%" 
      [config]="tableConfig"
      [dataSource]="dataSource" 
      class="spotlab-table" 
      matSort
    >
      <ng-template
        dataTableCell="name" 
        let-item
      >
        <td>          
          {{ item.resource.name }}
        </td>
      </ng-template> 
      <ng-template
        dataTableCell="casetypes" 
        let-item
      >
        <td>           
           {{ item.resource.usedByCaseTypes | async }}
        </td>
      </ng-template>
      <ng-template
        dataTableCell="samples" 
        let-item
      >
        <td>           
           {{ item.resource.usedBySamples | async }}
        </td>
      </ng-template>
      <ng-template
        dataTableCell="_actions" 
        let-item
      >
           <td  (click)="$event.stopPropagation()">
          <button mat-icon-button [matMenuTriggerFor]="menu" data-cy="button-menu">
            <i class="ri-more-line"></i>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="delete(item)"
              class="flex-row align-items-center"
              data-cy="button-menu-delete"
            >
              <i class="ri-delete-bin-6-line"></i>
              {{ 'core.delete' | translate }}
            </button>
          </mat-menu>
        </td>
      </ng-template>
    </ts-data-table>
  </div>
</div>
