import { Pipe, PipeTransform } from '@angular/core';

interface IStorageUnits {
  value: number;
  unit: 'MB' | 'GB' | 'TB';
}

@Pipe({
  name: 'storage',
})
export class StoragePipe implements PipeTransform {
  getSizeUnits(MB: number, refUnits?: IStorageUnits): IStorageUnits {
    const units: Array<'TB' | 'GB' | 'MB'> = ['MB', 'GB', 'TB'];
    let unitsValue: IStorageUnits = { value: Math.trunc(MB / 100) * 100, unit: 'MB' };
    for (let i = 0; i < (refUnits ? 1 + units.findIndex((u) => u === refUnits.unit) : units.length); i++) {
      const value = Math.trunc((MB / Math.pow(1024, i)) * 100) / 100;
      if (value >= 1) {
        unitsValue = {
          value,
          unit: units[i],
        };
      }
    }
    return unitsValue;
  }

  transform(value: any, ...args: any[]): any {
    const units = this.getSizeUnits(value);
    return `${units.value} ${units.unit}`;
  }
}
