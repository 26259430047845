import { HttpClient } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { AuthService } from '@telespot/web-core';

import { FakeROIProvider } from './ai-providers/fake-ai-provider';
import { IRoiProvider } from './ai-providers/i-ai-roi-provider';
import { TelespotROIProvider } from './ai-providers/telespot-ai-provider';

// REVIEW: not working on ivy
export const AI_ROI_PROVIDER = new InjectionToken<IRoiProvider>('AI_ROI_PROVIDER');
export const FAKE_ROI_PROVIDER = { provide: AI_ROI_PROVIDER, useClass: FakeROIProvider } as const;
export const TELESPOT_ROI_PROVIDER = {
  provide: AI_ROI_PROVIDER,
  useClass: TelespotROIProvider,
  deps: [HttpClient, AuthService],
} as const;
