import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { SUPPORTED_LANGUAGES, SupportedLanguage } from "@shared/localization";
import { ProtocolService } from "@telespot/protocols/data-access";
import { TAnalysisModelItem, TaskOption } from "@telespot/sdk";

import chroma from "chroma-js";

@Component({
  selector: "ts-task-option-editor",
  templateUrl: "./task-option-editor.component.html",
  styleUrls: ["./task-option-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TaskOptionEditorComponent implements OnInit {
  DEFAULT_COLOR = "rgb(0, 153, 187)";
  private _option: TaskOption;

  @Input()
  set option(option: TaskOption) {
    this._option = option;
    this.buildForm();
  }
  get option() {
    return this._option;
  }

  @Input() isCloud;

  @Output() submitted: EventEmitter<TAnalysisModelItem> = new EventEmitter();

  form: UntypedFormGroup;

  constructor(
    private protocolService: ProtocolService,
    @Inject(SUPPORTED_LANGUAGES) public supportedLanguages: SupportedLanguage[],
    private renderer: Renderer2,
    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    if (this.option?.uuid && !this.option?.new) {
      const localeLabels = await this.protocolService.getLocaleFromLabel(
        this.option.uuid
      );

      this.option.value = {
        ...(localeLabels || []).reduce(
          (acc, l) => ({ ...acc, [l.lang]: l.value }),
          {}
        ),
      };
    }

    this.buildForm();
  }

  private buildForm() {
    const commonControls = {
      name: new UntypedFormControl({
        value: this.option ? this.option.value : {},
        disabled: true,
      }),
      color: new UntypedFormControl(
        this.option?.color ? chroma(this.option?.color).css() : undefined
      ),
      alpha: new UntypedFormControl(
        this.option?.alpha ? this.option?.alpha : 0.2
      ),
      thresholdDisplay: new UntypedFormControl(this.option?.thrdis ?? 0, [
        Validators.required,
        Validators.min(0),
        Validators.max(1),
      ]),
    };

    this.form = new UntypedFormGroup(commonControls);
  }

  submit() {
    if (this.form.value.color) {
      this.option.color = chroma(this.form.value.color).hex();
      this.option.alpha = this.form.value.alpha;
    }

    this.option.thrdis = this.form.value.thresholdDisplay;

    this.submitted.emit(this.option);
  }

  getColorWithAlpha() {
    const color = this.form.value.color ?? this.DEFAULT_COLOR;
    const colorWithAlpha = `rgba${color.slice(3, -1)}, ${
      this.form.value?.alpha ?? 0.2
    })`;
    const thumbLabel = this.el.nativeElement.querySelector(
      ".mat-slider-thumb-label"
    );
    this.renderer.setStyle(thumbLabel, "background-color", colorWithAlpha);
    this.cdr.detectChanges();
  }

  cancel() {
    this.submitted.emit(undefined);
  }
}
