import { Workspace, Member, WorkspaceRepository } from "@telespot/domain";

import { ParseBaseRepository } from "../../parse-base.repository";
import { UserTopology } from "../accounts";

import { MemberTopology, ParseMemberMapper } from "./parse-member.mapper";
import { ParseWorkspaceMapper, WorkspaceTopology } from "./parse-workspace.mapper";

export class ParseWorkspaceRepository extends ParseBaseRepository implements WorkspaceRepository {

  private readonly workspaceMapper = new ParseWorkspaceMapper(this.parse);
  private readonly memberMapper = new ParseMemberMapper(this.parse);

  public async getById(id: string): Promise<Workspace> {
    const query = new this.parse.Query(WorkspaceTopology.TABLE);

    const parseWorkspace = await query.get(id, this.options);

    return this.workspaceMapper.toDomain(parseWorkspace);
  }

  public async findMemberInWorkspaceByName(workspaceId: string, username: string): Promise<Member> {

    const workspace = this.subclasses
      .getSubclass(WorkspaceTopology.TABLE)
      .createWithoutData(workspaceId);

    const userQuery = new this.parse.Query(this.parse.User)
      .equalTo(UserTopology.USERNAME, username);

    const parseMember = await new this.parse.Query(MemberTopology.TABLE)
      .matchesQuery(MemberTopology.USER, userQuery)
      .equalTo(MemberTopology.WORKSPACE, workspace)
      .include(MemberTopology.USER)
      .first(this.options);

    return parseMember ? this.memberMapper.toDomain(parseMember) : undefined;
  }

  public async saveMember(member: Member): Promise<string> {
    const { id } = await this.memberMapper.fromDomain(member).save(null, this.options);

    return id;
  }

  public async save(workspace: Workspace): Promise<string> {
    const { id } = await this.workspaceMapper
      .fromDomain(workspace)
      .save(null, this.options);

    return id;
  }

  public async saveAll(...workspaces: Workspace[]): Promise<string[]> {
    const parseWorkspaces = workspaces.map(w => this.workspaceMapper.fromDomain(w));

    const results = await this.parse.Object.saveAll(parseWorkspaces, this.options);

    return results.map(({ id }) => id);
  }

}
