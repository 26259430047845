import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InviteCodeResolver implements Resolve<string> {
  resolve(route: import('@angular/router').ActivatedRouteSnapshot): Observable<string> {
    return of(route.queryParamMap.get('invite'));
  }
}
