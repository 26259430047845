import { OrganizationItem } from "@telespot/domain";
import { IListOrganizationsHandler, ListOrganizations } from "@telespot/use-cases";

import { OrganizationTopology, ParseOrganizationMapper } from "../../repositories";
import { ParseBaseRepository } from "../../parse-base.repository";
import { ObjectTopology } from "../../parse.topology";

/**
 * @group Organizations
 * @category Handlers
 */
export class ListOrganizationsHandler extends ParseBaseRepository implements IListOrganizationsHandler {

  public async execute(command: ListOrganizations): Promise<OrganizationItem[]> {

    const { limit, nextToken } = command.details;

    const parseQuery = new this.parse.Query(OrganizationTopology.TABLE)
      .ascending(ObjectTopology.ID)
      .limit(limit);

    if (nextToken) parseQuery.greaterThan(ObjectTopology.ID, nextToken.id);

    const parseObjects = await parseQuery.find(this.options);

    return parseObjects.map(ParseOrganizationMapper.toListView);
  }

}
