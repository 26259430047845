import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class UserResolver implements Resolve<any> {
  constructor(private authService: AuthService) {}
  resolve(
    route: import('@angular/router').ActivatedRouteSnapshot,
    state: import('@angular/router').RouterStateSnapshot
  ): Observable<any> {
    return this.authService.logout().pipe(
      switchMap((_) =>
        of({
          userId: `${route.queryParamMap.get('id')}`,
          token: `${route.queryParamMap.get('token')}`,
        })
      )
    );
  }
}
