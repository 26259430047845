import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Optional, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TAnalysisProtocolTask, TaskOption } from '@telespot/sdk';

export interface OptionSelectionEvent {
  option: TaskOption;
  category: TAnalysisProtocolTask;
}

@Component({
  selector: 'ts-label-context-menu',
  templateUrl: './label-context-menu.component.html',
  styleUrls: ['./label-context-menu.component.scss']
})
export class LabelContextMenuComponent {

  @Input() categories: TAnalysisProtocolTask[];

  @Input() @Optional() checkedOptions: string[] = [];
  @Input() @Optional() doubleCheckedOptions: string[] = [];

  @Output() optionSelected = new EventEmitter<OptionSelectionEvent>();
  @Output() optionHovered = new EventEmitter<TaskOption>();
  @Output() optionUnhovered = new EventEmitter<TaskOption>();

  public isDoubleChecked(option: TaskOption): boolean {
    return this.doubleCheckedOptions.includes(option.uuid);
  }

  public isChecked(option: TaskOption): boolean {
    return this.checkedOptions.includes(option.uuid) && !this.isDoubleChecked(option);
  }
}

@NgModule({
  declarations: [LabelContextMenuComponent],
  imports: [CommonModule, TranslateModule],
  exports: [LabelContextMenuComponent],
})
export class LabelContextMenuModule { }
