import { DeviceType } from "./device-type";
import { Entity, SoftDeletable } from "./entity";

export interface DeviceAttributes extends Entity, SoftDeletable {
  organizationId: string;
  type: DeviceType;
  name: string;
}

export class Device implements Entity, SoftDeletable {
  constructor(private attributes: DeviceAttributes) { }

  public get id(): string | undefined {
    return this.attributes.id;
  }

  public get name(): string {
    return this.attributes.name;
  }

  public get organizationId(): string {
    return this.attributes.organizationId;
  }

  public get deviceType(): DeviceType {
    return this.attributes.type;
  }

  public get deletedAt(): Date {
    return this.attributes.deletedAt;
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }
}
