import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  SyncState,
  SyncedItemType,
  getLabelTrack,
  getSyncedItems,
} from "./sync.reducer";

export const syncFeatureKey = "sync";

export const selectSyncFeature =
  createFeatureSelector<SyncState>(syncFeatureKey);

export const selectSyncedItems = createSelector(
  selectSyncFeature,
  getSyncedItems
);

export const selectLabelTracking = createSelector(
  selectSyncFeature,
  getLabelTrack
);

export const hasMoreLabels = createSelector(selectLabelTracking, (labels) => labels?.length > 0)

export const selectSyncedItemsByType = (type: SyncedItemType) =>
  createSelector(selectSyncedItems, (items) =>
    items.filter((i) => i.type === type)
  );

export const selectAssetsFromSyncedFindings = createSelector(
  selectSyncedItemsByType(SyncedItemType.FINDING),
  (findings) => {
    const uniqueAssets = new Set<string>();

    for (const f of findings) {
      if (uniqueAssets.has(f?.assetId)) continue;
      uniqueAssets.add(f?.assetId);
    }

    return Array.from(uniqueAssets);
  }
);

export const nextSyncedFinding = createSelector(
  selectSyncedItemsByType(SyncedItemType.FINDING),
  (findings) => {
    return findings.length ? findings[0] : undefined;
  }
);

export const referenceAnalyst = createSelector(
  selectSyncFeature,
  (state) => state.referenceAnalyst
);

export const nextLabelId = createSelector(selectSyncFeature, (state) =>
  getLabelTrack(state).length !== 0
    ? getLabelTrack(state)[0]?.labelId
    : undefined
);

export const labelTrackingReady = createSelector(
  selectSyncFeature,
  (state) => state.labelTrackingReady
);
