import { Component, Inject, Optional, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { PipelineEditorComponent } from "../pipeline-editor/pipeline-editor.component";
import { MethodType, Pipeline } from "@telespot/sdk";

@Component({
  selector: "ts-pipeline-editor-dialog",
  templateUrl: "./pipeline-editor-dialog.component.html",
  styleUrls: ["./pipeline-editor-dialog.component.scss"],
})
export class PipelineEditorDialogComponent {
  @ViewChild(PipelineEditorComponent)
  pipelineEditor: PipelineEditorComponent;

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<PipelineEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      pipeline: Pipeline;
      methodType: MethodType;
    } = { pipeline: undefined, methodType: undefined }
  ) {}

  closeDialog(data: any) {
    this.dialogRef?.close(data);
  }
}
