<span class="button-bar align-right">
  <button class="btn btn-flat-primary btn-auto" (click)="create()">
    <i class="ri-add-circle-line"></i>
    {{ "button.new_pipeline" | translate | uppercase }}
  </button>
</span>
<div
  style="
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  "
  *ngIf="!showPipelineEditor"
>
  <div class="column">
    <h4>
      {{ "title.existing_pipelines" | translate }}
    </h4>

    <ts-data-table [config]="tableConfig" [dataSource]="dataSource">
      <ng-template dataTableCell="available_pipelines" let-item>
        <ng-container>
          <li>
            <span> {{ getPipelineName(item.name) }}</span>
            <span class="button-bar stretch">
              <button
                mat-icon-button
                class="btn btn-flat-primary align-right"
                data-cy="button-add-pipeline"
                (click)="addPipeline(item)"
              >
                <i class="ri-add-circle-line"></i>
              </button>
            </span>
          </li>
        </ng-container>
      </ng-template>
    </ts-data-table>
  </div>
  <div class="column">
    <h4>
      {{ "title.pipelines_assigned" | translate }}
    </h4>
    <ul class="list-group">
      <ng-container *ngIf="pipelines.length; else noItems">
        <li
          class="list-group-item"
          *ngFor="let pipeline of pipelines"
          cdkDrag
          [cdkDragData]="pipeline"
          data-bs-toggle="tooltip"
        >
          <span>{{ getPipelineName(pipeline.name) }}</span>
          <span class="button-bar stretch">
            <button
              mat-icon-button
              class="btn btn-flat-primary"
              data-cy="edit-pipeline"
              (click)="edit(pipeline)"
            >
              <i class="ri-pencil-line"></i>
            </button>
            <button
              mat-icon-button
              class="btn btn-flat-danger"
              (click)="removePipeline(pipeline)"
            >
              <i class="ri-delete-bin-6-line"></i>
            </button>
          </span>
        </li>
      </ng-container>
    </ul>
    <ng-template #noItems> Add some pipeline </ng-template>
  </div>
</div>
