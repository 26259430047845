import type Parse from "parse";

import { ExportedAsset } from "@telespot/domain";

export class ParseExportedAssetMapper {
  public static toDomain(
    parseObject: Parse.Object,
    infoExtraObject?: object
  ): ExportedAsset {
    const metadataJSON = parseObject.get("asset").toJSON();
    const sampleJSON = parseObject.get("sample").toJSON();

    // flatten data
    metadataJSON.sample = sampleJSON;
    metadataJSON.case = metadataJSON.sample.case;
    if (infoExtraObject) metadataJSON.case.analysisState = infoExtraObject;
    metadataJSON.workspace = metadataJSON.sample.case?.workspace;
    metadataJSON.organization =
      metadataJSON.sample.case?.workspace?.organization;
    metadataJSON.dateOfCapture = parseObject.get("dateOfCapture") ?? this.getDateOfCaptureFrom(metadataJSON.assetFile);
    metadataJSON.url = `analyze/cases/${metadataJSON.case.objectId}/samples/${metadataJSON.sample.objectId}?asset=${metadataJSON.objectId}`;

    // omit asset fields
    delete metadataJSON.assetType;

    // omit sample fields
    delete metadataJSON.sample.case;
    delete metadataJSON.sample.createdBy;
    delete metadataJSON.sample.methodType;
    delete metadataJSON.sample.organization;

    // omit case fields
    delete metadataJSON.case?.organization;
    delete metadataJSON.case?.workspace;
    delete metadataJSON.case?.caseType;

    // omit workspace fields
    delete metadataJSON.workspace?.organization;
    delete metadataJSON.workspace?.caseType;

    // omit device fields
    delete metadataJSON.device?.organization;

    // omit ACLs on all objects
    delete metadataJSON.ACL;
    delete metadataJSON.createdBy?.ACL;
    delete metadataJSON.sample.ACL;
    delete metadataJSON.case?.ACL;
    delete metadataJSON.workspace?.ACL;
    delete metadataJSON.organization?.ACL;

    return metadataJSON;
  }

  public static getDateOfCaptureFrom(assetFile?: string) {
    const formatValidationRegex = /^(adapta|tira|micra)Spot_\d{4}-\d{2}-\d{2}_\d{2}-\d{2}-\d{2}_\d{3}-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\.jpg$/
    const productNameRegex = /^(adapta|tira|micra)Spot_/;
    const uuidAndExtenstionRegex = /-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\.jpg$/

    if (!assetFile || !formatValidationRegex.test(assetFile)) return;

    const [date, time, ms] = assetFile
      .replace(productNameRegex, "")
      .replace(uuidAndExtenstionRegex, "")
      .split("_");

    return new Date(`${date}T${time.replaceAll("-", ":")}.${ms}Z`);
  }
}
