<ng-container *ngIf="embedInCard; else embeddedInDialogTemplate">
  <div class="main-container">
    <div class="card">
      <div mat-dialog-title class="card-header">
        <h1>{{ "title.pipeline_editor" | translate }}</h1>
      </div>
      <div class="card-body">
        <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
      </div>
      <div class="card-footer card-actions">
        <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #embeddedInDialogTemplate>
  <div mat-dialog-title class="main-title">
    <h1>{{ "title.pipeline_editor" | translate }}</h1>
  </div>
  <mat-dialog-content>
    <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-around">
    <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
  </mat-dialog-actions>
</ng-template>

<ng-template #formTemplate>
  <div class="card-body">
    <form [formGroup]="form" *ngIf="form">
      <mat-label class="field-label">{{
        "label.display_name" | translate | uppercase
      }}</mat-label>
      <ts-language-input
        [languages]="supportedLanguages"
        formControlName="displayName"
      ></ts-language-input>

      <mat-error *ngIf="isDisplayNameRequiredError()">
        {{ "error.en_translation" | translate }}
      </mat-error>

      <div>
        <mat-label class="field-label">{{
          "core.environment" | translate | uppercase
        }}</mat-label>
        <mat-radio-group formControlName="environment">
          <div class="radio-button-container">
            <mat-radio-button
              value="cloud"
              [disabled]="currentPipeline.tasks.length > 0"
              [matTooltip]="
                currentPipeline.tasks.length > 0
                  ? ('tooltip.environment_disabled' | translate)
                  : null
              "
            >
              Cloud
            </mat-radio-button>
          </div>

          <div class="radio-button-container">
            <mat-radio-button
              value="edge"
              [disabled]="currentPipeline.tasks.length > 0"
              [matTooltip]="
                currentPipeline.tasks.length > 0
                  ? ('tooltip.environment_disabled' | translate)
                  : null
              "
              >Edge</mat-radio-button
            >
          </div>
        </mat-radio-group>
      </div>

      <div>
        <div style="max-height: 340px; overflow: auto">
          <ul
            class="list-group list-group-flush"
            cdkDropList
            (cdkDropListDropped)="drop($event)"
          >
            <li
              class="list-group-item"
              *ngFor="let step of getSteps(); let i = index"
              cdkDrag
              cdkDragLockAxis="y"
            >
              <div>
                <span class="task-name">{{ "Step " + (i + 1) }}</span>
                <span class="asset-specific-flag">[{{ step.type }}]</span>
              </div>
              <span class="button-bar stretch" *ngIfRole="['admin']">
                <button
                  class="btn btn-flat-primary btn-auto"
                  type="button"
                  (click)="editTask(step)"
                >
                  <i class="ri-pencil-line"></i>
                </button>
                <button
                  class="btn btn-flat-danger btn-auto"
                  type="button"
                  (click)="removeTask(step)"
                >
                  <i class="ri-delete-bin-6-line"></i>
                </button>
              </span>
              <div *cdkDragPlaceholder class="drag-placeholder"></div>
            </li>
          </ul>
        </div>
        <button
          class="btn btn-flat btn-flat-primary btn-auto"
          (click)="newField()"
        >
          <i class="ri-add-circle-line" style="margin-right: 8px"></i>
          {{ "button.add_step" | translate | uppercase }}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #actionsTemplate>
  <button
    class="btn btn-outline-primary card-actions__action"
    (click)="cancel()"
  >
    <i class="ri-close-fill"></i>{{ "button.cancel" | translate }}
  </button>
  <button
    class="btn btn-primary card-actions__action"
    (click)="submit()"
    [disabled]="!form || !form.valid || loading"
  >
    <i class="ri-save-line"></i>{{ "button.save" | translate }}
  </button>
</ng-template>
