import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Sort, SortDirection } from "@angular/material/sort";
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  of,
  Subject,
  Subscription,
} from "rxjs";
import {
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";

import { TableFilter } from "../components/data-table/models/data-table-filter";

type SplDataSourceUpdateFcn<T> = (
  sorts: { [key: string]: SortDirection },
  filters: TableFilter[],
  skip?: number,
  limit?: number
) => Promise<{ items: Observable<T[]>; count?: Observable<number> }>;
export interface SplDataSourceConfig<T> {
  updateFcn: SplDataSourceUpdateFcn<T>;
  deleteFcn?: (row: T) => void | Promise<void>;
  migrateFcn?: (row: T) => void | Promise<void>;
  copyFcn?: (row: T) => void | Promise<void>;
}

export class SplDataSource<T> implements DataSource<T> {
  constructor(private settings: SplDataSourceConfig<T>) { }

  private _data$ = new BehaviorSubject<T[]>([]);
  private _data$_sub: Subscription;
  private loadingSubject$ = new Subject<boolean>();
  private _update$ = new Subject<null>();

  public loading$ = this.loadingSubject$.asObservable();

  private _params$ = new BehaviorSubject<{
    filters: TableFilter[];
    sorts: { [key: string]: SortDirection };
    pagination: { pageIndex: number; pageSize: number };
  }>({
    filters: [],
    sorts: {},
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
  });
  public readonly params$ = this._params$
    .asObservable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  public activeFilters$ = this._params$.pipe(
    map((params) => params.filters)
  );
  public numPages$: Observable<number>;

  private _updateEvent$ = combineLatest([
    this._params$,
    this._update$.pipe(startWith([null])),
  ]);
  private _updateFcn$ = this._updateEvent$.pipe(
    tap(() => this.loadingSubject$.next(true)),
    switchMap(
      ([
        {
          sorts,
          filters,
          pagination: { pageIndex, pageSize },
        },
      ]) =>
        from(
          this.settings.updateFcn(
            sorts,
            filters,
            pageIndex * pageSize,
            pageSize
          )
        )
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    this._data$_sub = this._updateFcn$
      .pipe(
        tap(() => {
          this.loadingSubject$.next(true);
          this._data$.next([]);
        }),
        switchMap((fcn) => {
          return fcn.items;
        }),
        tap(() => this.loadingSubject$.next(false))
      )
      .subscribe((rows) => this._data$.next(rows));
    this.numPages$ = this._updateFcn$.pipe(
      switchMap((fcn) => fcn.count ?? of(-1))
    );
    return this._data$.asObservable();
  }
  disconnect(collectionViewer: CollectionViewer): void {
    this._data$_sub?.unsubscribe();
  }

  removeFilter(filter: string | TableFilter): void {
    let filters: TableFilter[];
    if (!(filter instanceof TableFilter)) {
      filters = this._params$.value.filters.filter((fv) => fv.name === filter);
    } else {
      filters = [filter];
    }
    filters.forEach((filter) => {
      filter.setActive(false);
    });
    this._params$.next({
      ...this._params$.value,
      filters: this._params$.value.filters.filter(
        (activeFilter) => !filters.includes(activeFilter)
      ),
    });
  }

  applyFilter(inputFilters: TableFilter | TableFilter[]) {
    const newFilters =
      inputFilters instanceof Array ? inputFilters : [inputFilters];
    const filters = [
      ...this._params$.value.filters.filter(
        (previousFilter) =>
          !newFilters.some(
            (newFilter) => newFilter.name === previousFilter.name
          )
      ),
      ...newFilters.map((filter) => filter.setActive(true)),
    ];
    this._params$.next({
      ...this._params$.value,
      filters,
      pagination: {
        ...this._params$.value.pagination,
        pageIndex: filters?.length
          ? 0
          : this._params$.value.pagination.pageIndex,
      },
    });
  }

  applySort(event: Sort | Sort[]) {
    const sortEvents = event instanceof Array ? event : [event];
    const sorts = sortEvents.reduce(
      (sortObject, event) => ({
        ...sortObject,
        [event.active]: event.direction,
      }),
      {}
    );
    this._params$.next({
      ...this._params$.value,
      sorts: { ...this._params$.value.sorts, ...sorts },
    });
  }

  /** Functions to define the visualization of actions buttons over the cases in a list */

  public get removable(): boolean {
    return !!this.settings.deleteFcn;
  }
  public get canBeMigrated(): boolean {
    return !!this.settings.migrateFcn;
  }
  public get canBeCopied(): boolean {
    return !!this.settings.copyFcn;
  }

  /** Functions to handle the click over the actions allowed in a particular case from a list of cases */

  async removeItem(row: T, optimistic?: boolean): Promise<void> {
    // Actually remove the item
    if (this.settings.deleteFcn) {
      const result = this.settings.deleteFcn(row);
      if (result instanceof Promise) {
        await result;
      }
      this._update$.next(null);
    }
    // Optimistic update
    if (optimistic) {
      this._data$.next(this._data$.value.filter((v) => v !== row));
    } else {
      this._update$.next(null);
    }
  }

  async migrateItem(row: T): Promise<void> {
    const result = this.settings.migrateFcn(row);
    if (result instanceof Promise) {
      await result;
    }

    this._update$.next(null);
  }

  async copyItem(row: T): Promise<void> {
    const result = this.settings.copyFcn(row);
    if (result instanceof Promise) {
      await result;
    }

    this._update$.next(null);
  }

  updatePagination(
    event: Partial<{ pageIndex: number; pageSize: number }>
  ): void {
    const currentPagination = this._params$.value.pagination;
    if (
      currentPagination.pageSize ===
      (event.pageSize ?? currentPagination.pageSize) &&
      currentPagination.pageIndex ===
      (event.pageIndex ?? currentPagination.pageIndex)
    ) {
      return;
    }
    this._params$.next({
      ...this._params$.value,
      pagination: {
        ...this._params$.value.pagination,
        ...event,
      },
    });
  }

  get pageIndex$(): Observable<number> {
    return this._params$.pipe(map((params) => params.pagination.pageIndex));
  }

  get pageSize$(): Observable<number> {
    return this._params$.pipe(map((params) => params.pagination.pageSize));
  }

  /**
   * Force update
   *
   * @memberof SplDataSource
   */
  public fetch(): void {
    this._update$.next(null);
  }
}
