import { Component, Inject, Optional } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DataDialog {
  option: string;
  existingOptions?: string[];
}

@Component({
  selector: 'ts-field-type-editor-dialog',
  templateUrl: './field-type-editor-dialog.component.html',
  styleUrls: ['./field-type-editor-dialog.component.scss'],
})
export class FieldTypeEditorDialogComponent {
  private _option: UntypedFormControl;
  get option() {
    return this._option;
  }
  constructor(
    @Optional() public dialogRef: MatDialogRef<FieldTypeEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataDialog
  ) {
    this._option = new UntypedFormControl(data.option, [_uniqueOptionValidator(data.existingOptions)]);
  }

  submit() {
    this.dialogRef.close(this._option.value);
  }

  cancel() {
    this.dialogRef.close();
  }
}

function _uniqueOptionValidator(existingValues: string[] = []) {
  return function _validateOption(control: AbstractControl): { [key: string]: any } | null {
    const invalid = existingValues.some(
      (existingOption) => existingOption.toLocaleLowerCase() === control.value.toLowerCase()
    );
    return invalid ? { invalid: 'Duplicated option value' } : null;
  };
}
