import { State } from "../value-objects";

import { AnalyzerEntity } from "./analysis";
import { Case } from "./case";
import { Entity } from "./entity";
import { Sample } from "./sample";

export type AnalysisSubject = "sample" | "case"; // REVIEW for duplications

export enum AnalysisStateValue {
  IN_PROGRESS = "inProgress",
  ANALYZED = "analyzed",
}

export interface AnalysisStateAttributes extends Entity {
  analizerEntity: AnalyzerEntity;
  analizerId: string;
  subjectEntity: AnalysisSubject;
  subjectId: string;
  state: State | AnalysisStateValue;
  assetBookmarkId?: string;
  favoritAssetIds?: string[];
}

export class AnalysisState implements Entity {
  constructor(private attributes: AnalysisStateAttributes) {}

  public get id(): string | undefined {
    return this.attributes.id;
  }

  public get analizerEntity(): AnalyzerEntity {
    return this.attributes.analizerEntity;
  }

  public get analizerId(): string {
    return this.attributes.analizerId;
  }

  public get subjectEntity(): AnalysisSubject {
    return this.attributes.subjectEntity;
  }

  public get subjectId(): string {
    return this.attributes.subjectId;
  }

  public get state(): State | AnalysisStateValue {
    return this.attributes.state;
  }

  public get assetBookmarkId(): string {
    return this.attributes.assetBookmarkId;
  }

  public get favoritAssetIds(): string[] {
    return this.attributes.favoritAssetIds ?? [];
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }

  public updateStatus(state: State | AnalysisStateValue): this {
    this.attributes.state = state;

    return this;
  }

  public changeBookmarkedAsset(value: string) {
    this.attributes.assetBookmarkId = value;
  }

  public copyTo(destination: Case | Sample): AnalysisState {
    const { id, ...copyAttr } = this.attributes;

    return new AnalysisState({
      ...copyAttr,
      subjectEntity: destination instanceof Case ? "case" : "sample",
      subjectId: destination.id,
    });
  }
}
