<div class="main-container">
  <div class="title">
    <h1>
      {{ "core.protocols" | translate }}
    </h1>
    <button
      class="btn btn-primary float-right"
      #btn
      data-cy="button-protocol-new"
      [matMenuTriggerFor]="appMenu"
      matTooltip="Create new protocol"
    >
      <i class="ri-add-circle-line"></i
      >{{ "label.new" | translate | uppercase }}
    </button>
    <mat-menu #appMenu="matMenu">
      <button
        mat-menu-item
        [routerLink]="['new']"
        [style.minWidth.px]="btn.clientWidth"
        class="flex-row align-items-center"
        data-cy="dropdown-new-case-type"
      >
        <i class="ri-file-copy-2-line"></i>
        {{ "core.case_type" | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="['assetTypes', 'new']"
        [style.minWidth.px]="btn.clientWidth"
        class="flex-row align-items-center"
        data-cy="dropdown-new-asset-type"
      >
        <i class="ri-focus-2-line"></i>

        {{ "core.assettype" | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="['resources', 'new']"
        [style.minWidth.px]="btn.clientWidth"
        class="flex-row align-items-center"
        data-cy="dropdown-new-resource"
      >
        <i class="ri-stack-line"></i>
        {{ "core.resource" | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="['analysisTypes', 'new']"
        [style.minWidth.px]="btn.clientWidth"
        class="flex-row align-items-center"
        data-cy="dropdown-new-analysis-type"
      >
        <i class="ri-list-unordered"></i>
        {{ "core.analysis_type" | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="['deviceTypes', 'edit', 'new']"
        [style.minWidth.px]="btn.clientWidth"
        class="flex-row align-items-center"
        data-cy="dropdown-new-device-type"
      >
        <i class="ri-device-line"></i>
        {{ "core.device_type" | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="['methodTypes', 'edit', 'new']"
        [style.minWidth.px]="btn.clientWidth"
        class="flex-row align-items-center"
        data-cy="dropdown-new-method-type"
      >
        <i class="ri-screenshot-2-line"></i>
        {{ "core.method_type" | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="['pipelines', 'new']"
        [style.minWidth.px]="btn.clientWidth"
        class="flex-row align-items-center"
        data-cy="dropdown-new-pipeline"
      >
        <i class="ri-list-unordered"></i>
        {{ "core.pipeline" | translate }}
      </button>
    </mat-menu>
  </div>
  <ng-container *ngIf="{ mobile: showMobileLayout$ | async } as state">
    <div class="card">
      <nav mat-tab-nav-bar class="full-width">
        <a
          class="button text-primary"
          mat-tab-link
          [routerLink]="['caseTypes']"
          routerLinkActive
          #rlCaseTypes="routerLinkActive"
          [active]="rlCaseTypes.isActive"
        >
          <div class="flex-row align-items-center">
            <i class="ri-file-copy-2-line" *ngIf="!state.mobile"></i>
            <span>{{
              (state.mobile ? "core.cases" : "core.casetypes")
                | translate
                | uppercase
            }}</span>
          </div>
        </a>
        <a
          class="button text-primary"
          mat-tab-link
          [routerLink]="['assetTypes']"
          *ngIfRole="['admin', 'analystmanager']"
          routerLinkActive
          #rlAssetTypes="routerLinkActive"
          [active]="rlAssetTypes.isActive"
        >
          <div class="flex-row align-items-center">
            <i class="ri-focus-2-line" *ngIf="!state.mobile"></i>
            <span>{{
              (state.mobile ? "Asset" : "title.asset_types")
                | translate
                | uppercase
            }}</span>
          </div>
        </a>
        <a
          class="button text-primary"
          mat-tab-link
          [routerLink]="['resources']"
          *ngIfRole="['analystmanager', 'technicianmanager', 'admin']"
          routerLinkActive
          #rlResources="routerLinkActive"
          [active]="rlResources.isActive"
        >
          <div class="flex-row align-items-center">
            <i class="ri-stack-line" *ngIf="!state.mobile"></i>
            <span>{{ "core.resource" | translate | uppercase }}</span>
          </div>
        </a>
        <a
          class="button text-primary"
          mat-tab-link
          [routerLink]="['analysisTypes']"
          *ngIfRole="['analystmanager', 'technicianmanager', 'admin']"
          routerLinkActive
          #rlAnalysisTypes="routerLinkActive"
          [active]="rlAnalysisTypes.isActive"
        >
          <div class="flex-row align-items-center">
            <i class="ri-list-unordered" *ngIf="!state.mobile"></i>
            <span>{{
              (state.mobile ? "core.analysis" : "core.analysis_types")
                | translate
                | uppercase
            }}</span>
          </div>
        </a>
        <a
          class="button text-primary"
          mat-tab-link
          [routerLink]="['deviceTypes']"
          *ngIfRole="['analystmanager', 'technicianmanager', 'admin']"
          routerLinkActive
          #rlAIWorkflows="routerLinkActive"
          [active]="rlAIWorkflows.isActive"
        >
          <div class="flex-row align-items-center">
            <i class="ri-device-line" *ngIf="!state.mobile"></i>
            <span>{{
              (state.mobile ? "core.device" : "core.device_types")
                | translate
                | uppercase
            }}</span>
          </div>
        </a>
        <a
          class="button text-primary"
          mat-tab-link
          [routerLink]="['methodTypes']"
          *ngIfRole="['analystmanager', 'technicianmanager', 'admin']"
          routerLinkActive
          #rlMethodTypes="routerLinkActive"
          [active]="rlMethodTypes.isActive"
        >
          <div class="flex-row align-items-center">
            <i class="ri-screenshot-2-line" *ngIf="!state.mobile"></i>
            <span>{{
              (state.mobile ? "core.method_type" : "core.method_types")
                | translate
                | uppercase
            }}</span>
          </div>
        </a>
        <a
          class="button text-primary"
          mat-tab-link
          [routerLink]="['pipelines']"
          *ngIfRole="['analystmanager', 'technicianmanager', 'admin']"
          routerLinkActive
          #rlPipelines="routerLinkActive"
          [active]="rlPipelines.isActive"
        >
          <div class="flex-row align-items-center">
            <i class="ri-list-unordered" *ngIf="!state.mobile"></i>
            <span>{{
              (state.mobile ? "pipelines" : "pipelines") | translate | uppercase
            }}</span>
          </div>
        </a>
      </nav>
    </div>
  </ng-container>

  <router-outlet></router-outlet>
</div>
