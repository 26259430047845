<div class="card flex-column align-items-center profile-card shadow-hard" *ngIf="currentUser$ | async as user">
  <div class="avatar-container flex-column justify-content-center">
    <label class="flex-column justify-content-center align-items-center" for="fileInput">
      <img
        class="user-avatar user-avatar--60 shadow-hard"
        [src]="user.avatar?.url() || 'assets/media/user-avatar.png'"
        fallbackSrc="assets/media/user-avatar.png"
        alt="" />
      <i class="ri-pencil-line text-white avatar-container__change-avatar"></i
    ></label>
    <input type="file" id="fileInput" accept="image/*" hidden (change)="updateLogo($event)" />
  </div>
  <div style="max-width: 360px">
    <div class="flex-row align-items-center mt-2">
      <span class="text-gray">
        {{ 'core.name' | translate }}
      </span>
      <span class="ms-auto">{{ user.displayName }}</span>
      <span>
        <!-- <button mat-icon-button class="text-primary">
          <i class="ri-pencil-line"></i>
        </button> -->
      </span>
    </div>

    <div class="flex-row align-items-center mt-2">
      <span class="text-gray">
        {{ 'label.email' | translate }}
      </span>
      <span class="ms-auto">{{ user.email }}</span>
      <span>
        <!-- <button mat-icon-button class="text-primary">
          <i class="ri-pencil-line"></i>
        </button> -->
      </span>
    </div>

    <div class="flex-row align-items-top mt-2">
      <span class="text-gray">
        {{ 'core.roles' | translate }}
      </span>
      <span class="ms-auto flex flex-row align-items-center flex-wrap justify-content-end">
        <ts-role-badge *ngFor="let role of user.roleNames" [role]="role">
          {{ role | translate | uppercase }}
        </ts-role-badge>
      </span>
    </div>
  </div>

  <div class="mt-3">
    <a [routerLink]="['/password-reset']" [queryParams]="{ email: user.email }">
      {{ 'label.change_password' | translate }}
    </a>
  </div>
</div>
