import { AnalysisTask } from "./analysis-task";
import {
  NO_TAGS_COLOR,
  FUZZY_COLOR,
  ROI_COLORS,
  DEFAULT_ROI_COLOR,
} from "./colors";

export class AnalysisTypeUtils {
  getLabelCategoryAndValue(label: string) {
    const labelCategory = label.substring(
      label.indexOf("category:") + 9,
      label.indexOf("/")
    );
    const labelValue = label.substring(label.indexOf("value:") + 6);
    return { labelCategory, labelValue };
  }

  getLabelColor(labels: string[], opaque = false, activeTasks: AnalysisTask[]) {
    if (!labels) return;
    if (!labels.length) return NO_TAGS_COLOR;

    let color: string;
    if (labels.length > 1) {
      color = FUZZY_COLOR;
    } else {
      const { labelCategory, labelValue } = this.getLabelCategoryAndValue(
        labels[0]
      );
      const task = activeTasks.find((task) => task.name === labelCategory);
      const option = task.options.find((o) => o.name === labelValue);
      color = option?.color;
      const taskIndex = task.options.indexOf(option);

      if (!color)
        color =
          ROI_COLORS?.[taskIndex % ROI_COLORS.length] || DEFAULT_ROI_COLOR;
    }

    return opaque ? color.replace(/[^,]*(?=\))/, "1") : color;
  }

  getModelDisplayName(labels: string[], activeTasks: AnalysisTask[]) {
    if (!labels || !labels.length) return;
    const { labelCategory, labelValue } = this.getLabelCategoryAndValue(
      labels[0]
    );
    const option = activeTasks
      .find((task) => task.name === labelCategory)
      .options.find((o) => o.name === labelValue);
    if (!option) return labelValue;
    return option.displayName;
  }
}
