<form (submit)="submit()">
  <div class="card-body">
    <mat-form-field style="width:100%">
      <input matInput type="text" placeholder="{{ 'label.edit_option' | translate | uppercase }}" [formControl]="option" />
      <mat-error *ngIf="option.errors">
        {{ option.errors['invalid'] }}
      </mat-error>
    </mat-form-field>

    <div class="card-actions">
      <!-- Editor buttons -->
      <button class="btn btn-flat-primary card-actions__action card-actions__action--grow" type="button" (click)="cancel()">
        <i class="ri-close-fill"></i>{{ 'button.cancel' | translate }}
      </button>
      <button
        class="btn btn-primary card-actions__action card-actions__action--grow"
        type="submit"
        [disabled]="option.touched && option.invalid"
      >
        <i class="ri-save-line"></i>{{ 'button.save' | translate }}
      </button>
    </div>
  </div>
</form>
