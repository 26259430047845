import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@telespot/web-core';
import { AnalysisTask, AnalysisType, Query, TaskTypeName } from '@telespot/sdk';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { AnalysisTaskEditorDialogComponent } from '../analysis-task-editor-dialog/analysis-task-editor-dialog.component';

@Component({
  selector: 'ts-analysis-type-editor',
  templateUrl: './analysis-type-editor.component.html',
  styleUrls: ['./analysis-type-editor.component.scss'],
})
export class AnalysisTypeEditorComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  @Input() analysisType: AnalysisType;
  @Input() embedInCard = true;
  @Input() redirectBack = true;
  @Output() submitted = new EventEmitter<AnalysisType>();

  showFieldEditor = false;

  // Fields

  selectedField: AnalysisTask;

  // FIX: ideally we should get this from the backend or from Object.keys(FieldTypeName)
  types: TaskTypeName[] = ['boolean', 'selection', 'switcher', 'textedit'];

  form: UntypedFormGroup;

  isNewField = false;
  private _dialogRef: MatDialogRef<any>;

  constructor(
    @Optional() private dialog: MatDialog,
    private route: ActivatedRoute,
    private _location: Location,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this._destroy$)).subscribe(async (params) => {
      const id = params.get('analysisTypeId');
      if (id) {
        this.analysisType = await this.dataService.get(id, new Query(AnalysisType));
        this.setupForm();
      }
    });

    if (!this.analysisType) {
      this.analysisType = new AnalysisType();
      this.setupForm();
    } else {
      this.setupForm();
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  setupForm() {
    this.form = new UntypedFormGroup({
      displayName: new UntypedFormControl(this.analysisType.displayName || 'Nuevo tipo de análisis', [Validators.required]),
      description: new UntypedFormControl(this.analysisType.description || ''),
      tasks: new UntypedFormControl(this.analysisType.tasks || []),
    });
  }

  newField() {
    const newField: AnalysisTask = new AnalysisTask({
      name: undefined,
      displayName: 'field_name',
      type: 'textedit',
    });
    this.editField(newField, true);
  }

  editField(field: AnalysisTask, isNew?: boolean) {
    this.isNewField = isNew;
    this.selectedField = field;
    // this.showFieldEditor = true;
    this._dialogRef =
      this._dialogRef ||
      this.dialog.open(AnalysisTaskEditorDialogComponent, {
        width: '780px',
        hasBackdrop: true,
        data: {
          analysisTask: field,
        },
      });
    this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe((response) => {
        this.addField(response);
      });
  }

  addField(task: AnalysisTask) {
    if (!task) {
      this.showFieldEditor = false;
      return;
    }
    const currentTasks = this.form.value.tasks || [];
    if (currentTasks.findIndex((existingTask) => existingTask?.name === task?.name) === -1) {
      currentTasks.push(task);
    } else {
      // fix: this prevents editing tasks
      // throw new Error(`This AnalysisType already has a task named "${task.name}"`);
    }
    this.form.controls.tasks.patchValue(currentTasks);

    if (this.analysisType.addField(this.selectedField)) {
      this.showFieldEditor = false;
      this.selectedField = null;
    }
  }

  cancelFieldEditing() {
    this.showFieldEditor = false;
    // FIX: changes are already applied, re-load fields / use temp. Field clone
  }

  removeField(field: AnalysisTask) {
    this.form.controls.tasks.patchValue(this.form.value.tasks.filter((task) => task.name !== field.name));
    this.analysisType.removeField(field);
  }

  async submit() {
    this.analysisType.displayName = this.form.value.displayName ?? '';
    this.analysisType.description = this.form.value.description;
    if (this.analysisType.isNew()) {
      this.analysisType.name = this.analysisType.displayName;
    }
    this.analysisType.tasks = this.form.value.tasks.map((a) => new AnalysisTask(a));

    if (this.analysisType.dirty()) {
      await this.dataService.save(this.analysisType);
    }

    this.submitted.emit(this.analysisType);
  }

  cancel() {
    this.analysisType.revert();
    this.submitted.emit(null);
    if (this.redirectBack) {
      this._location.back();
    }
  }

  drop(event: CdkDragDrop<AnalysisTask[]>) {
    const tasks = [...this.form.value.tasks];
    moveItemInArray(tasks, event.previousIndex, event.currentIndex);
    this.form.patchValue({
      tasks: tasks,
    });
  }
}
