import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ImageModule } from "@shared/ui";

interface CropDetail {
  currentLabel: string;
  labels: { [key: string]: number };
  cropId: string;
}

@Component({
  selector: "ts-crop-detail",
  templateUrl: "./crop-detail.component.html",
  styleUrls: ["./crop-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropDetailComponent {
  @Input() imageUrl: string;
  @Input() cropDetail: CropDetail | null = null;
  @Output() closeDetail = new EventEmitter<void>();

  get labels() {
    if (!this.cropDetail) return [];

    return Object.entries(this.cropDetail.labels).map(([key, value]) => ({
      key,
      value: `${(value * 100).toFixed(0)}%`,
    }));
  }

  onClose() {
    this.closeDetail.emit();
  }
}

@NgModule({
  declarations: [CropDetailComponent],
  imports: [CommonModule, TranslateModule, ImageModule],
  exports: [CropDetailComponent],
})
export class CropDetailModule { }
