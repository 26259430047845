import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnalysisType } from '@telespot/sdk';

import { AnalysisTypeEditorComponent } from '../analysis-type-editor/analysis-type-editor.component';

@Component({
  selector: 'ts-analysis-type-editor-dialog',
  templateUrl: './analysis-type-editor-dialog.component.html',
  styleUrls: ['./analysis-type-editor-dialog.component.scss'],
})
export class AnalysisTypeEditorDialogComponent {
  @ViewChild(AnalysisTypeEditorComponent) analysisTypeEditor: AnalysisTypeEditorComponent;

  constructor(
    @Optional() public dialogRef: MatDialogRef<AnalysisTypeEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      analysisType: AnalysisType;
    } = { analysisType: undefined }
  ) { }

  closeDialog(data: any) {
    this.dialogRef?.close(data);
  }
}
