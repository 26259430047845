import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Host, Inject, OnDestroy, Optional } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '@telespot/web-core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { NAV_BAR_ITEMS, NavBarItem } from '../../providers/nab-bar-items.token';

@Component({
  selector: 'ts-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnDestroy {
  readonly currentUser$ = this.authService.currentUser$;
  readonly logout = this.authService.logout;

  constructor(
    private authService: AuthService,
    private breakpoint: BreakpointObserver,
    @Optional() @Host() private _sidenav: MatSidenav,
    @Inject(NAV_BAR_ITEMS) public links: NavBarItem[]
  ) {
    this.breakpoint
      .observe(['(min-width: 992px)'])
      .pipe(
        filter((b) => b.matches),
        takeUntil(this._destroy)
      )
      .subscribe((_) => {
        if (this._sidenav?.opened) {
          this._sidenav.close();
        }
      });
  }

  private _destroy = new Subject<void>();
  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  close() {
    this._sidenav?.close();
  }
}
