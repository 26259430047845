import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { ConfirmationDialogComponent, PRESETS } from "@shared/ui";
import {
  discardAcquisitionDataChanges,
  saveAcquisitionData,
  updateAcquisitionData,
} from "@telespot/analysis-refactor/data-access";
import { take, tap } from "rxjs/operators";

@Component({
  selector: "ts-acquisition-data",
  templateUrl: "./acquisition-data.component.html",
  styleUrls: ["./acquisition-data.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcquisitionDataComponent {
  @Input() acquisitionData: any;
  @Input() isEditable: boolean;
  @Input() assetFields: any[];

  public editMode = false;
  private _dialogRef: MatDialogRef<any>;
  assetDataSynced;

  constructor(private _store: Store, private _dialog: MatDialog) {}

  public extractFieldValue(fieldPath, acquisitionData) {
    const keys = fieldPath.split(".");

    for (const key of keys) {
      if (!Object.keys(acquisitionData).includes(key)) return null;
      acquisitionData = acquisitionData[key];
    }
    return acquisitionData;
  }

  public isObject(value: any) {
    return typeof value === "object" && value !== null;
  }

  public updateAssetData(fieldPath, value) {
    this._store.dispatch(
      updateAcquisitionData({ key: fieldPath, value: value })
    );
  }
  preventEnterKey(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      event.preventDefault();
      (event.target as HTMLElement).blur();
    }
  }

  setEditMode() {
    this.editMode = true;
  }

  save() {
    this._dialogRef = this._dialog.open(
      ConfirmationDialogComponent,
      PRESETS.DIALOG_SAVE_ASSET_DATA
    );

    return this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined))
      )
      .subscribe(async (result: any) => {
        switch (result) {
          case "accept":
            this.editMode = false;
            this._store.dispatch(saveAcquisitionData());
            return true;
          case "cancel":
          default:
            return true;
        }
      });
  }

  discardChanges() {
    this.editMode = false;
    this._store.dispatch(discardAcquisitionDataChanges());
  }
}
