import type Parse from "parse";

import { MethodType } from "@telespot/domain";
import { ParseResourceMapper, ResourceTopology } from "./parse-resource.mapper";
import { AnalysisTypeTopology, ParseAnalysisTypeMapper } from "./parse-analysis-type.mapper";
import { ParseMapper } from "../../parse.mapper";
import { AssetTypeTopology } from "./parse-asset-type.mapper";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

export enum MethodTypeTopology {
  TABLE = "MethodType",
  NAME = "name",
  CONFIG = "config",
  RESOURCES = "resources",
  ASSET_TYPE = "assetType",
  ANALYSIS_TYPES = "analysisTypes",
  ASSET_DATA_FIELDS = "assetDataFields",
}

export class ParseMethodTypeMapper extends ParseMapper<MethodType> {

  private readonly analysisTypeMapper = new ParseAnalysisTypeMapper(this.parse);
  private readonly resourceMapper = new ParseResourceMapper(this.parse);

  public toDomain(parseObject: Parse.Object): MethodType {
    const parseAnalysisTypes = parseObject.get(MethodTypeTopology.ANALYSIS_TYPES) ?? [];
    const parseResources = parseObject.get(MethodTypeTopology.RESOURCES) ?? [];

    return new MethodType({
      id: parseObject.id,
      name: parseObject.get(MethodTypeTopology.NAME),
      config: parseObject.get(MethodTypeTopology.CONFIG),
      resources: parseResources.map((r) => this.resourceMapper.toDomain(r)),
      assetTypeId: parseObject.get(MethodTypeTopology.ASSET_TYPE)?.id,
      analysisTypes: parseAnalysisTypes.map((at) => this.analysisTypeMapper.toDomain(at)),
      assetDataFields: parseObject.get(MethodTypeTopology.ASSET_DATA_FIELDS),
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
      deletedAt: parseObject.get(SoftDeletableTopology.DELETED_AT),
    });
  }

  public fromDomain(methodType: MethodType) {
    const analysisTypes = methodType.analysisTypes
      .map(at => this.subclasses.getSubclass(AnalysisTypeTopology.TABLE).createWithoutData(at.id))

    const resources = methodType.resources
      .map(r => this.subclasses.getSubclass(ResourceTopology.TABLE).createWithoutData(r.id));

    const assetType = this.subclasses
      .getSubclass(AssetTypeTopology.TABLE)
      .createWithoutData(methodType.assetTypeId);

    const parseObject = this.parse.Object.fromJSON({
      className: MethodTypeTopology.TABLE,
      [ObjectTopology.ID]: methodType.id,
      [ObjectTopology.CREATED_AT]: methodType.createdAt,
      [ObjectTopology.UPDATED_AT]: methodType.updatedAt,
    });

    parseObject.set({
      [SoftDeletableTopology.DELETED_AT]: methodType.deletedAt,
      [MethodTypeTopology.ASSET_TYPE]: assetType,
      [MethodTypeTopology.NAME]: methodType.name,
      [MethodTypeTopology.CONFIG]: methodType.config,
      [MethodTypeTopology.ANALYSIS_TYPES]: analysisTypes,
      [MethodTypeTopology.ASSET_DATA_FIELDS]: methodType.assetDataFields,
      [MethodTypeTopology.RESOURCES]: resources,
    })

    return parseObject;
  }
}
