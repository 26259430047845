import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subject } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';

import { INotificationPayload, ISwAppData, LS_PENDING_KEY } from './updater-service.data';

@Injectable({
  providedIn: 'root',
})
export class UpdaterService {
  private _notifications$ = new Subject<INotificationPayload>();

  public notifications$ = this._notifications$.asObservable().pipe(
    startWith(this._checkPendingNotification()),
    filter((payload) => !!payload),
    map<INotificationPayload, INotificationPayload>((payload) => ({
      ...payload,
      actionData: payload.actionData ?? {
        action: 'core.dismiss',
      },
    })),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(@Optional() private swUpdater: SwUpdate, @Optional() @Inject(DOCUMENT) private _document: Document) {
    if (this.swUpdater?.isEnabled) {
      this.swUpdater.activated.subscribe((event) => {
        const appData: ISwAppData = event.current.appData;
        if (!event.previous) {
          console.log(`%c, SW installed: ${appData.changelog}`, 'color: purple');
        } else {
          console.log(`%c, SW updated: ${appData.changelog}`, 'color: purple');
        }
        if (appData.forceUpdate) {
          // Save notification to localStorage & force page reload
          this._scheduleNotification('alert.app_updated');
          this._document?.location.reload();
        } else {
          // Prompt the user for page reload
          this._notifications$.next({
            message: `alert.app_updated_prompt_reload`,
            actionData: {
              action: 'core.reload',
              callback: () => {
                window.location.reload();
              },
            },
          });
        }
      });
      this.swUpdater.available.subscribe((event) => {
        const appData: ISwAppData = event.current.appData;
        if (appData.forceUpdate) {
          // Activate update without waiting for user approval
          this.swUpdater.activateUpdate();
        } else {
          // Prompt user for update
          this._notifications$.next({
            message: `alert.new_version_available`,
          });
          this.swUpdater.activateUpdate();
        }
      });
    } else {
      console.warn('No Service Worker detected by the UpdaterService');
    }
  }

  private _checkPendingNotification(): INotificationPayload {
    const message = localStorage.getItem(LS_PENDING_KEY);
    if (message) {
      localStorage.removeItem(LS_PENDING_KEY);
      return {
        message,
        duration: 4000,
      };
    }
  }

  private _scheduleNotification(message: string) {
    localStorage.setItem(LS_PENDING_KEY, message);
  }
}
