import { PipelineStep, StepTask } from "../entities";

export class PipelineService {
  public static getLabelUuids(steps: PipelineStep[]) {
    const uuids: Array<string> = [];
    steps.forEach((step) => {
      if (!step.params) return;
      if (step.task === StepTask.TEXT || step.task === StepTask.OCR) {
        const category = step.params?.categorization[0]?.category;
        if (category) return uuids.push(category);
      }
      return (step.params?.categorization || []).forEach((c) => {
        uuids.push(c.category, ...c.options);
      });
    });
    return uuids;
  }
}
