<div class="pdf-report-container" *ngIf="case$ | async as case; else fallbackTemplate">
  <h1 style="text-align: center">{{ 'core.case' | translate }}: "{{ case?.name }}"</h1>
  <hr />

  <div id="title">
    <h1>
      {{ 'title.case_info' | translate | uppercase }}
    </h1>
    <button
      id="reOpenButton"
      style="margin-right: 8px"
      class="btn btn-outline-primary"
      data-bs-toggle="tooltip"
      title="Re-open this case for further analysis"
      (click)="reopenCase(case)"
      [routerLink]="['workspaces', case.workspace, 'cases']"
    >
      <i class="ri-eye-line"></i>
      {{ 'Re-open' | translate }}
    </button>
    <button id="printButton" class="btn btn-primary float-right" (click)="openPrintDialog()">
      <i class="ri-download-2-line"></i>
      {{ 'core.print' | translate }}
    </button>
  </div>

  <div class="row data-field">
    <div class="col-md-4">{{ 'props.acquired_by' | translate }}:</div>
    <div class="col-md-8">
      {{ case?.createdBy?.username || 'unknown' }}
    </div>
  </div>

  <div class="row data-field">
    <div class="col-md-4">{{ 'prop.analyzed_by' | translate }}:</div>
    <div class="col-md-8" *ngIf="allAnalysts$ | async as allAnalysts">
      {{ allAnalysts || ('core.nobody' | translate) }}
    </div>
  </div>

  <div class="row data-field">
    <div class="col-md-4">{{ 'core.type' | translate }}:</div>
    <div class="col-md-8">
      {{ case.caseType.name }}
    </div>
  </div>
  <div class="row data-field">
    <div class="col-md-4">{{ 'props.acquired_on' | translate }}:</div>
    <div class="col-md-8">
      {{ case.createdAt | date: 'short' }}
    </div>
  </div>

  <hr />

  <h1>{{ 'title.case_data' | translate | uppercase }}</h1>

  <ts-case-data [case]="case" [printable]="true"></ts-case-data>

  <hr />

  <h1>{{ 'label.analysis_results' | translate | uppercase }}</h1>

  <div *ngFor="let mg of dataByUser$ | async">
    <h2>
      {{ mg.methodType.name }}
    </h2>

    <div *ngFor="let sample of mg.samples" class="section-sample">
      <ng-container>
        <h3>
          {{ sample.sample.name }}
        </h3>
        <div class="flex-row align-items-center">
          <table>
            <tr>
              <td>
                <div>
                  {{ 'props.acquired_by' | translate }}:
                  <b>{{ sample.sample.createdBy?.username }}</b>
                </div>
                <div>
                  {{ 'props.acquired_on' | translate }}:
                  <b>{{ sample.sample.createdAt | date }}</b>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <b>{{ 'core.device' | translate }}:</b>
                  {{ sample.sample.device?.deviceType.name || 'unknown' }}
                </div>
                <div>
                  <b>SN:</b>
                  {{ sample.sample.device?.serialNumber || 'unknown' }}
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div *ngFor="let analysisType of sample.results" style="margin-top: 6px">
          <ts-results-table [data]="analysisType" [context]="context.sample"> </ts-results-table>
        </div>

        <ng-container *ngIf="sample.counters.length">
          <div style="margin-top: 30px">
            <h3>{{ 'label.total_rois_count' | translate | uppercase }}</h3>
          </div>
          <div *ngFor="let analysisType of sample.counters" style="margin-top: 6px">
            <ts-results-table [data]="analysisType" [context]="context.counter"> </ts-results-table>
          </div>
        </ng-container>

        <ng-container *ngFor="let assetResults of sample.assetResults; let index = index">
          <div style="margin-top: 30px">
            <h3>{{ 'title.asset_results' | translate | uppercase }}</h3>
          </div>
          <div *ngFor="let analysisType of assetResults.results" style="margin-top: 10px">
            <div style="margin-bottom: 10px">
              <h3 *ngIf="analysisType?.hasRelevantTasks && analysisType?.userResults.length">
                {{ 'label.asset_i_results' | translate: { index: index + 1 } }}
              </h3>
            </div>
            <ts-results-table [data]="analysisType" [context]="context.asset" [asset]="assetResults.asset">
            </ts-results-table>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #fallbackTemplate>
  <div class="main-container">
    <div class="card">
      <div class="card-body" style="text-align: center">
        <ng-container *ngIf="loading | async; else notFound" style="text-align: center">
          <i class="ri-refresh-line spin"></i>
          <p>Loading...</p>
        </ng-container>
        <ng-template #notFound> Case not found </ng-template>
      </div>
    </div>
  </div>
</ng-template>
