import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
} from "@angular/core";

@Component({
  selector: "ts-image-placeholder",
  templateUrl: "./image-placeholder.component.html",
  styleUrls: ["./image-placeholder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePlaceholderComponent {
  constructor(private cdr: ChangeDetectorRef) {}

  // TODO: in the future wait for the image to also be loaded before showing it
  @Input() imageUrl: string;

  onImageLoad() {
    this.cdr.detectChanges();
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [ImagePlaceholderComponent],
  exports: [ImagePlaceholderComponent],
})
export class ImagePlaceholderModule {}
