import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

interface IStorageUnits {
  value: number;
  unit: 'MB' | 'GB' | 'TB';
}

@Component({
  selector: 'ts-used-storage',
  templateUrl: './used-storage.component.html',
  styleUrls: ['./used-storage.component.scss'],
})
export class UsedStorageComponent {
  private _used = 0;
  /**
   * Used storage (GB)
   *
   * @type {number}
   * @memberof UsedStorageComponent
   */
  @Input()
  set used(used: number) {
    this._used = used || 0;
  }
  get used() {
    return this._used;
  }

  /**
   * Total storage (GB / -1 if unlimited)
   *
   * @type {number}
   * @memberof UsedStorageComponent
   */
  @Input() total = -1;

  get usedPercentage() {
    return this.total === -1 ? -1 : (this.used / this.total) * 100;
  }

  get mode() {
    return this.total !== -1 ? 'determinate' : 'buffer';
  }

  get usedStorage() {
    const usedUnits = this.getSizeUnits(this.used);
    return `${usedUnits.value}${usedUnits.unit}`;
  }

  get usedStorageOutOfTotal() {
    const totalUnits = this.getSizeUnits(this.total);
    const usedUnits = this.getSizeUnits(this.used, totalUnits);
    return `${usedUnits.value}${usedUnits.unit} / ${totalUnits.value}${totalUnits.unit}`;
  }

  get color(): string {
    return this.total === -1
      ? `primary`
      : this.usedPercentage >= 75
        ? `warn`
        : this.usedPercentage >= 50
          ? `accent`
          : `primary`;
  }

  getSizeUnits(MB: number, refUnits?: IStorageUnits): IStorageUnits {
    const units: Array<'TB' | 'GB' | 'MB'> = ['MB', 'GB', 'TB'];
    let unitsValue: IStorageUnits = { value: Math.trunc(MB / 100) * 100, unit: 'MB' };
    for (let i = 0; i < (refUnits ? 1 + units.findIndex((u) => u === refUnits.unit) : units.length); i++) {
      const value = Math.trunc((MB / Math.pow(1024, i)) * 100) / 100;
      if (value >= 1) {
        unitsValue = {
          value,
          unit: units[i],
        };
      }
    }
    return unitsValue;
  }
}

@NgModule({
  imports: [CommonModule, MatProgressBarModule, MatTooltipModule],
  declarations: [UsedStorageComponent],
  exports: [UsedStorageComponent],
})
export class UsedStorageComponentModule { }
