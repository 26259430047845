import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  ProtocolState,
  protocolFeatureKey,
  getProtocols,
  Context,
} from "./protocol.reducer";

export const selectProtocolState =
  createFeatureSelector<ProtocolState>(protocolFeatureKey);

export const selectContext = createSelector(
  selectProtocolState,
  (state) => state.context
);

export const selectTaskGroups = createSelector(
  selectProtocolState,
  getProtocols
);

export const selectTaskGroupsWithROITasks = createSelector(
  selectProtocolState,
  (state) =>
    getProtocols(state)
      .filter((at) =>
        at.tasks.some((task) => task.roiSelection && task?.options?.length)
      )
      .reduce((accTasks, group) => {
        return [...accTasks, ...(group.tasks ?? [])];
      }, [])
      .filter((task) => task.roiSelection && task?.options?.length)
);

export const selectProtocolLoading = createSelector(
  selectProtocolState,
  (state) => state.loading
);

export const selectShowPinnedOnly = createSelector(
  selectProtocolState,
  (state) => state.showPinnedOnly
);

export const selectMultipleSelection = createSelector(
  selectProtocolState,
  (state) => state.multiselectEnabled
);

export const selectLabels = createSelector(
  selectProtocolState,
  (state) => state.labels.entities
);

export const selectLabelsId = createSelector(
  selectProtocolState,
  (state) => state.labels.ids
);

export const selectLabelsArray = createSelector(selectProtocolState, (state) =>
  Object.values(state.labels.entities ?? {})
);

export const selectedLabels = createSelector(
  selectLabelsArray,
  (labels) => labels?.filter((label) => label.selected) ?? []
);

export const selectVisibleLabels = createSelector(
  selectProtocolState,
  (state) =>
    Object.keys(state.labels.entities ?? {}).filter(
      (keyId) => state.labels.entities[keyId].visible
    )
);

export const selectTaskGroupsWithROISelection = createSelector(
  selectProtocolState,
  (state) =>
    getProtocols(state).filter((at) =>
      at.tasks.some((task) => task.roiSelection)
    )
);

export const selectTaskGroupsForActiveContext = createSelector(
  selectTaskGroups,
  selectContext,
  selectLabels,
  (groups, context, labels) =>
    groups
      .filter((group) =>
        group.tasks.some(
          (task) => task.assetSpecific === (context === Context.ASSET)
        )
      )
      .map((group) => ({
        ...group,
        tasks: group.tasks
          .filter((task) => task.assetSpecific === (context === Context.ASSET))
          .map((task) => ({
            ...task,
            options: task.options?.map((option) => ({
              ...option,
              ...(labels[`category:${task.name}/value:${option.name}`] ?? {}),
            })),
          })),
      }))
);

export const selectSegmentationTasks = createSelector(
  selectProtocolState,
  (state) =>
    getProtocols(state)
      .filter((at) =>
        at.tasks.some(
          (task) => task.type === "segmentation" && task?.options?.length
        )
      )
      .reduce((accTasks, group) => {
        return [...accTasks, ...(group.tasks ?? [])];
      }, [])
      .filter((task) => task.type === "segmentation" && task?.options?.length)
);

export const selectHasSegmentationTasks = createSelector(
  selectTaskGroupsForActiveContext,
  (taskGroups) =>
    taskGroups.some((group) =>
      group.tasks.some((task) => task.type === "segmentation")
    )
);

export const selectROISelectionTasks = createSelector(
  selectProtocolState,
  (state) =>
    getProtocols(state)
      .filter((at) =>
        at.tasks.some((task) => task.roiSelection && task?.options?.length)
      )
      .reduce((accTasks, group) => {
        return [...accTasks, ...(group.tasks ?? [])];
      }, [])
      .filter((task) => task.roiSelection && task?.options?.length)
);

export const selectHasROITasks = createSelector(
  selectTaskGroupsForActiveContext,
  (taskGroups) =>
    taskGroups.some((group) => group.tasks.some((task) => task.roiSelection))
);

export const checkTaskTypes = createSelector(
  selectHasROITasks,
  selectHasSegmentationTasks,
  (hasROITasks, hasSegmTasks) => ({ hasROITasks, hasSegmTasks })
);

export const visibleTaskGroups = createSelector(
  selectTaskGroupsForActiveContext,
  selectShowPinnedOnly,
  (analysisTypes, showPinnedOnly) =>
    !showPinnedOnly
      ? analysisTypes
      : analysisTypes.map((at) => ({
          ...at,
          tasks: [
            ...at.tasks
              .filter((task) => task.roiSelection)
              .map((task) => ({
                ...task,
                options: task.options?.filter((option) => option.pinned),
              }))
              .filter((task) => task.options?.length > 0),
            ...at.tasks.filter((task) => !task.roiSelection),
          ],
        }))
);

export const hasSelectedLabels = createSelector(selectLabelsArray, (labels) =>
  labels.some((label) => label.selected)
);

export const disabledLabelsForCounter = createSelector(
  selectLabelsArray,
  (labels) =>
    labels
      .filter((label) => label.removeFromCounter)
      .map((label) => `category:${label.category}/value:${label.value}`)
);
