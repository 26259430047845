import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ts-cases-filter',
  templateUrl: './cases-filter.component.html',
  styleUrls: ['./cases-filter.component.scss'],
})
export class CasesFilterComponent implements OnDestroy {
  @Input() iconFocus: boolean;
  @Output() clicked = new EventEmitter<void>();
  @Input() public set filters(value) {
    this._filters = value;
  }
  public get filters() {
    return this._filters;
  }

  private _tiraspot: boolean;
  public get tiraspot(): boolean {
    return this._tiraspot;
  }

  private _queryParams: any;

  private _filters;

  private _multipleFilterValues = [];

  constructor(private _router: Router, private route: ActivatedRoute) {
    this._filters = this.filters ?? PRESET_FILTERS;
    this.route.parent?.queryParamMap
      .pipe(
        tap((queryParams) => (this._queryParams = queryParams)),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  private _destroy$ = new Subject<void>();

  ngOnDestroy() {
    this._destroy$.next();
  }

  public updateFilter(event) {
    const selectedFilter = this.filters.options.find((o) => o.name === event.source.id);
    this._applyFilter(selectedFilter, event.source.checked);
  }

  public updateDateRangeSelection(event) {
    const toggle = event.source;
    if (toggle) {
      const group = toggle.buttonToggleGroup;
      if (toggle._isSingleSelector) {
        group.value = toggle.value;
      } else {
        if (event.value.some((item) => item === toggle.value)) group.value = [toggle.value];
      }
    }
    const selectedFilter = this._filters
      .find((f) => f.name === event.source.buttonToggleGroup.name)
      .options.find((option) => option.name === event.value);
    this._applyFilter(selectedFilter);
  }

  public isChecked(filterName) {
    const selectedFilter = this.filters.options.find((o) => o.name === filterName);
    const filterValues = selectedFilter.value[selectedFilter.queryParam];
    const isArray = Array.isArray(this._queryParams?.params[selectedFilter.queryParam]);
    return isArray
      ? filterValues.some((fv) => this._queryParams?.params[selectedFilter.queryParam]?.includes(fv))
      : filterValues?.includes(this._queryParams?.params[selectedFilter.queryParam]);
  }

  private _applyFilter(selectedFilter: any, checked = true) {
    const { params } = this._queryParams;
    const filterValues = selectedFilter.value[selectedFilter.queryParam];
    const isArray = Array.isArray(filterValues);
    if (this._filters.multiple) {
      if (!checked) {
        this._multipleFilterValues = this._multipleFilterValues.filter((mfv) =>
          isArray ? !filterValues.includes(mfv) : !(filterValues === mfv)
        );
      } else {
        isArray
          ? filterValues.forEach((v) => this._multipleFilterValues.push(v))
          : this._multipleFilterValues.push(filterValues);
      }
    }
    const filterValue = this._filters.multiple
      ? this._multipleFilterValues.length
        ? { [selectedFilter.queryParam]: this._multipleFilterValues }
        : { [selectedFilter.queryParam]: null }
      : !checked
        ? { [selectedFilter.queryParam]: null }
        : selectedFilter.value;
    const queryParams = Object.keys(params).includes('page')
      ? { ...params, page: 0, ...filterValue }
      : { ...params, ...filterValue };
    this._router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}

const PRESET_FILTERS = [
  {
    name: 'date',
    displayName: 'Date',
    type: 'filter',
    multiple: false,
    options: [
      {
        name: 'newer_than_1_month',
        displayName: '< 1 months',
        type: '<',
        value: {
          newerThan: 1,
          olderThan: null,
        },
        queryParam: 'newerThan',
      },
      {
        name: 'range_1_3_months',
        displayName: '1 to 3 months',
        type: 'range',
        value: { olderThan: 1, newerThan: 3 },
        queryParam: ['oltherThan', 'newerThan'],
      },
      {
        name: 'range_3_6_months',
        displayName: '3 to 6 months',
        type: 'range',
        value: { olderThan: 3, newerThan: 6 },
        queryParam: ['oltherThan', 'newerThan'],
      },
      {
        name: 'older_than_6_months',
        displayName: '> 6 months',
        type: '>',
        value: { olderThan: 6, newerThan: null },
        queryParam: 'oltherThan',
      },
    ],
  },
];
