import { Component, Inject, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SUPPORTED_LANGUAGES, SupportedLanguage } from '@shared/localization';

const existingTranslationsValidatorFactory = (supportedLanguages: SupportedLanguage[]) => (control: UntypedFormGroup) => {
  return supportedLanguages.some((languageDescription) => control.value[languageDescription.value])
    ? null
    : { form: 'You must define at least one translation' };
};

@Component({
  selector: 'ts-default-sample-name-editor-dialog',
  templateUrl: './default-sample-name-editor-dialog.component.html',
  styleUrls: ['./default-sample-name-editor-dialog.component.scss'],
})
export class DefaultSampleNameEditorDialogComponent {
  form: UntypedFormGroup;

  constructor(
    @Optional() public dialogRef: MatDialogRef<DefaultSampleNameEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = { en: '' },
    @Inject(SUPPORTED_LANGUAGES) public supportedLanguages: SupportedLanguage[]
  ) {
    this.form = new UntypedFormGroup(
      supportedLanguages.reduce((conf: { [key: string]: UntypedFormControl }, lang) => {
        conf[lang.value] = new UntypedFormControl(this.data.name[lang.value] ?? '');
        return conf;
      }, {}),
      {
        validators: existingTranslationsValidatorFactory(supportedLanguages),
      }
    );
  }

  submit() {
    this.data.name = this.form.value;
    this.dialogRef?.close(this.data.name);
  }

  close() {
    this.dialogRef?.close();
  }
}
