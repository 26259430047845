import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserDashboardShellComponent } from './components/user-dashboard-shell/user-dashboard-shell.component';

const routes: Routes = [
  {
    path: '',
    component: UserDashboardShellComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserDashboardRoutingModule {}
