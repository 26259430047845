import { Object as ParseObject } from 'parse';

import { CaseType } from '../case-type/case-type.model';
import { IWorkflowState } from '../data/workflow-state';
import { Organization } from '../organization/organization.model';
import { User } from '../user/user.model';
import { Workspace } from '../workspace/workspace.model';

export class Case extends ParseObject {
  static className = 'Case';

  constructor() {
    super(Case.className);
    this.data = {};
  }

  get caseType(): CaseType {
    return this.get('caseType');
  }
  set caseType(newCaseType: CaseType) {
    this.set('caseType', newCaseType);
  }

  get states(): IWorkflowState {
    return this.get('state') ? this.get('state') : null;
  }
  set states(value: IWorkflowState) {
    this.set('state', value);
  }

  get data(): { [key: string]: string | number | string[] | Date } {
    return this.get('data');
  }
  set data(newDevice: { [key: string]: string | number | string[] | Date }) {
    this.set('data', newDevice);
  }

  set name(name: string) {
    this.set('name', name);
  }
  get name(): string {
    return this.get('name') || (this.data?.['case_identifier'] ?? this.id);
  }

  get workspace(): Workspace {
    return this.get('workspace');
  }
  set workspace(newWorkspace: Workspace) {
    this.set('workspace', newWorkspace);
  }

  set organization(value: Organization) {
    this.set('organization', value);
  }
  get organization(): Organization {
    return this.get('organization');
  }

  get createdBy(): User {
    return this.get('createdBy');
  }

  get numReports(): number {
    return this.get('numReports');
  }

  get numAssets(): number {
    return this.get('numAssets');
  }

  get currentState(): IWorkflowState {
    return this.states ? this.states : null;
  }

  get uuid(): string {
    return this.get('uuid');
  }
  set uuid(value: string) {
    this.set('uuid', value);
  }

  public static createWithoutData(id: string): Case {
    return ParseObject.fromJSON({ className: this.className, objectId: id }) as Case;
  }
}
