import { IRequestVerificationHandler, RequestAccountVerification } from "@telespot/use-cases";

import { ParseBaseRepository } from "../../parse-base.repository";

export class ParseRequestVerificationHandler extends ParseBaseRepository implements IRequestVerificationHandler {

  public execute(command: RequestAccountVerification) {
    const payload = {
      email: command.details.email,
      locale: command.details.i18n,
    }

    return this.parse.Cloud.run("sendVerificationEmail", payload, this.options);
  }

}
