import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { TranslateModule } from "@ngx-translate/core";
import { AcquisitionModule, StepperModule } from "@shared/ui";
import { FileDropDirectiveModule } from "@telespot/shared/util";
import { WorkspaceItemComponentModule } from "@telespot/workspaces/ui";

import { AcquisitionRoutingModule } from "./acquisition-routing.module";
import { CaseAcquisitionComponent } from "./components/case-acquisition/case-acquisition.component";

@NgModule({
  imports: [
    CommonModule,
    AcquisitionRoutingModule,
    WorkspaceItemComponentModule,
    TranslateModule,
    MatFormFieldModule,
    FileDropDirectiveModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatDialogModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatRadioModule,
    StepperModule,
    MatInputModule,
    AcquisitionModule,
  ],
  declarations: [CaseAcquisitionComponent],
  providers: [],
})
export class AcquisitionFeatureModule {}
