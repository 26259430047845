/**
 * Represents the available nodes in the graph of tables that connect to the {@link Sample} entity
 */
export enum QueryLevel {
  ORGANIZATION = "organization",
  WORKSPACE = "workspace",
  CASE = "case",
  SAMPLE = "sample",
  CASE_TYPE = "casetype",
  METHOD_TYPE = "methodtype",
}

/**
 * A simplified specification for the query
 */
export interface QueryContext {
  level: QueryLevel;
  ids: string[];
}
