import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, COLLECTION_ENABLED } from '@angular/fire/analytics';
import { COOKIES_FIREBASE_KEY } from '@shared/cookie-consent';
import { environment } from '@telespot/shared/environment';
import { RecorderModule } from '@telespot/shared/session-recorder';

import { ANALYTICS_PROVIDER } from './config/analytics-provider';
import { AnalyticsDirective } from './directives/analytics.directive';
import { AnalyticsService } from './services/analytics.service';
import { TITLE_ANALYTICS_PROVIDER } from './services/title-analytics';

@NgModule({
  imports: [
    CommonModule,
    RecorderModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
  ],
  providers: [
    AnalyticsService,
    { provide: COLLECTION_ENABLED, useValue: JSON.parse(localStorage.getItem(COOKIES_FIREBASE_KEY) ?? 'false') },
    TITLE_ANALYTICS_PROVIDER,
  ],
  declarations: [AnalyticsDirective],
  exports: [AnalyticsDirective, RecorderModule],
})
export class AnalyticsFeatureModule {
  constructor(private analyticsService: AnalyticsService, @Inject(ANALYTICS_PROVIDER) private analyticProviders) {
    console.log(`${this.analyticProviders.length} providers registered`);
  }
}
