import { Asset, IAssetROIWithModels, RoiModel } from '@telespot/sdk';
import { Observable } from 'rxjs';

import { IAssetTile } from '../i-asset-tile.interface';

export interface IRoiProviderResult {
  rois: IAssetROIWithModels[];
  progress: number;
}

export abstract class IRoiProvider {
  abstract analyzeAsset(asset: Asset, model: RoiModel, aiModel?: string): Observable<IRoiProviderResult>;
  abstract analyzeTiles(tiles: IAssetTile[], model: RoiModel, aiModel?: string): Observable<IRoiProviderResult>;
}
