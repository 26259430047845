import type Parse from "parse";

import { Workspace, WorkspaceItem } from "@telespot/domain";

import { ParseMapper } from "../../parse.mapper";
import { OrganizationTopology } from "../organization";
import { CaseTypeTopology } from "../protocols";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

export enum WorkspaceTopology {
  TABLE = "Workspace",
  NAME = "name",
  ORGANIZATION = "organization",
  CASE_TYPE = "caseType",
  CREATED_BY = "createdBy",
  CONFIG = "config",
  NUM_CASES = "numCases",
  NUM_MEMBERS = "numMembers",
  USED_STORAGE = "usedStorage",
}

export class ParseWorkspaceMapper extends ParseMapper<Workspace> {
  public toDomain(parseObject: Parse.Object): Workspace {

    const parseOrganization = parseObject.get(WorkspaceTopology.ORGANIZATION);
    const parseUser = parseObject.get(WorkspaceTopology.CREATED_BY);
    const parseCaseType = parseObject.get(WorkspaceTopology.CASE_TYPE);

    return new Workspace({
      id: parseObject.id,
      organizationId: parseOrganization?.id,
      createdById: parseUser?.id,
      caseTypeId: parseCaseType?.id,
      name: parseObject.get(WorkspaceTopology.NAME),
      numCases: parseObject.get(WorkspaceTopology.NUM_CASES),
      numMembers: parseObject.get(WorkspaceTopology.NUM_MEMBERS),
      config: parseObject.get(WorkspaceTopology.CONFIG),
      usedStorage: parseObject.get(WorkspaceTopology.USED_STORAGE),
      deletedAt: parseObject.get(SoftDeletableTopology.DELETED_AT),
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
    })
  }

  public fromDomain(workspace: Workspace): Parse.Object {
    const createdBy = this.parse.User.createWithoutData(workspace.createdById);

    const organization = this.subclasses
      .getSubclass(OrganizationTopology.TABLE)
      .createWithoutData(workspace.organizationId);

    const caseType = this.subclasses
      .getSubclass(CaseTypeTopology.TABLE)
      .createWithoutData(workspace.caseTypeId);

    const parseObject = this.parse.Object.fromJSON({
      className: WorkspaceTopology.TABLE,
      [ObjectTopology.ID]: workspace.id,
      [ObjectTopology.CREATED_AT]: workspace.createdAt,
      [ObjectTopology.UPDATED_AT]: workspace.updatedAt,
    });

    parseObject.set({
      [WorkspaceTopology.NAME]: workspace.name,
      [WorkspaceTopology.ORGANIZATION]: organization,
      [WorkspaceTopology.CASE_TYPE]: caseType,
      [WorkspaceTopology.NUM_CASES]: workspace.numCases,
      [WorkspaceTopology.NUM_MEMBERS]: workspace.numMembers,
      [WorkspaceTopology.CONFIG]: workspace.config,
      [WorkspaceTopology.USED_STORAGE]: workspace.usedStorage,
      [SoftDeletableTopology.DELETED_AT]: workspace.deletedAt,
      [WorkspaceTopology.CREATED_BY]: createdBy,
    })

    return parseObject;
  }

  public static toWorkspaceItem(parseObject: Parse.Object): WorkspaceItem {
    return {
      id: parseObject.id,
      name: parseObject.get(WorkspaceTopology.NAME),
    }
  }
}
