import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@telespot/web-core';
import { Device, DeviceType, Query } from '@telespot/sdk';
import { defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

interface IDeviceTypeItem {
  deviceType: DeviceType;
  numDevices: Observable<number>;
}

@Component({
  selector: 'ts-device-types',
  templateUrl: './device-types.component.html',
  styleUrls: ['./device-types.component.scss'],
})
export class DeviceTypesComponent implements OnInit {
  public readonly dataSource: MatTableDataSource<IDeviceTypeItem> = new MatTableDataSource<IDeviceTypeItem>([]);

  public readonly showMobileLayout$: Observable<boolean> = this._breakpoint
    .observe(['(max-width: 420px)'])
    .pipe(map((m) => m.matches));

  visibleColumns$: Observable<string[]> = this.showMobileLayout$.pipe(
    map((mobile) => (mobile ? ['name', 'version', 'numDevices', '_actions'] : ['name', 'version', 'numDevices', '_actions']))
  );

  constructor(
    private _dataService: DataService,
    private _breakpoint: BreakpointObserver,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this._loadDeviceTypes();
  }

  private async _loadDeviceTypes(): Promise<void> {
    const deviceTypesQuery = new Query(DeviceType);
    const deviceTypesCount = await this._dataService.count(deviceTypesQuery);
    this._dataService.find(deviceTypesQuery.limit(deviceTypesCount)).then((deviceTypes) => {
      this.dataSource.data = deviceTypes.map((deviceType) => {
        const devicesQuery = new Query(Device).equalTo('deviceType', deviceType.toPointer());
        return {
          deviceType,
          numDevices: defer(() => this._dataService.count(devicesQuery)),
        };
      });
    });
  }

  public async delete(removedItem: IDeviceTypeItem) {
    try {
      await this._dataService.softDelete(removedItem.deviceType);
      this.dataSource.data = this.dataSource.data.filter((item) => item.deviceType?.id !== removedItem.deviceType?.id);
    } catch (error) {
      this.snackBar.open(error, null, { duration: 2000 });
    }
  }
}
