import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TelespotPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data?.preload) {
      return load();
    } else {
      // Hardcoded rules
      switch (route.path) {
        case 'workspaces':
        case 'analyze':
        case 'reports':
          return load();
        default:
          return of(null);
      }
    }
  }
}
