import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from '@telespot/shared/environment';

import { Smartlook } from './providers/smartlook';
// import { FullStory } from './providers/full-story';

import { RecorderService, RECORDER_TOKEN } from './services/recorder/recorder.service';


@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: environment.recorder?.enable ? [
    { provide: RECORDER_TOKEN, useClass: Smartlook },
    RecorderService
  ] : [],
})
export class RecorderModule { }
