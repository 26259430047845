import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@telespot/web-core';
import { Affiliate, Case, Device, Organization, Query } from '@telespot/sdk';
import { from, Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface IOrganizationData {
  organization: { name: string; logoUrl: string };
  stats: {
    numMembers: number;
    numCases: number;
    numDevices: number;
  };
}

@Component({
  selector: 'ts-organizations-list',
  templateUrl: './organizations-list.component.html',
  styleUrls: ['./organizations-list.component.scss'],
})
export class OrganizationsListComponent implements OnInit, OnDestroy {
  visibleColumns$: Observable<string[]> = of(['organization', 'license', 'storage', 'stats']);

  dataSource: MatTableDataSource<IOrganizationData> = new MatTableDataSource([]);

  private _destroy$ = new Subject<void>();
  constructor(private _dataService: DataService) {}

  ngOnInit() {
    from(this._dataService.find(new Query(Organization)))
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (organizations) => {
        const memberCounts = await Promise.all(
          organizations.map(async (organization) => {
            return {
              organization,
              stats: {
                numMembers: await this._dataService.count(
                  new Query(Affiliate).equalTo('organization', organization.toPointer())
                ),
                numCases: await this._dataService.count(new Query(Case).equalTo('organization', organization.toPointer())),
                numDevices: await this._dataService.count(
                  new Query(Device).equalTo('organization', organization.toPointer())
                ),
              },
            };
          })
        );
        this.dataSource.data = memberCounts;
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
  }
}
