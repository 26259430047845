import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'preprocess',
})
export class PreprocessFilePipe implements PipeTransform {
  constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {}

  transform(url: string) {
    if (!url) return;
    return this.http
      .get(url, { responseType: 'blob' })
      .pipe(map((e) => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))));
  }
}

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [PreprocessFilePipe],
  exports: [PreprocessFilePipe],
})
export class PreprocessFilePipeModule {}
