import { CdkDragDrop } from '@angular/cdk/drag-drop';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ChangeDetectorRef,
  OnDestroy,
  InjectionToken,
  Inject,
  AfterViewInit,
  OnInit,
} from '@angular/core';

import { IndexPath } from '../table-view';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IDropListService {
  dropListIdsObservable$: Observable<string[]>;
  addDropListId(id: string): void;
  removeDropListId(id: string): void;
  clearAll(): void;
  getConnectedDropLists(): string[];
}

export const DROP_LIST_SERVICE = new InjectionToken<IDropListService>('MyServiceInterface');

export interface GridIndexPath extends IndexPath {
  column: number;
}

export interface MoveEvent {
  previousIndex: GridIndexPath;
  currentIndex: Partial<GridIndexPath>;
}

@Component({
  selector: 'ts-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridViewComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() public rowNumeralTemplate: TemplateRef<unknown>;
  @Input() public cellTemplate: TemplateRef<unknown>;
  @Input() public headerTemplate: TemplateRef<unknown>;
  @Input() public cellsPerRow = 0;
  @Input() public cellsPerSection: number[] = [];
  @Input() public scrollThreshold = 0;
  @Input() public paddingNodes = 1;
  @Input() public locationRow = 0;
  @Input() public draggingDisabled = false;
  @Input() public cellSize: number;
  @Input() public cellGap = 0;

  @Output() public cellMoved = new EventEmitter<MoveEvent>();
  @Output() public cellDraggingStarted = new EventEmitter<GridIndexPath>();
  @Output() public bottomReached = new EventEmitter<void>();
  @Output() public scrollThresholdReached = new EventEmitter<void>();

  private readonly JOIN_SYMBOL = "#";

  public rowsPerSection: number[];
  public cellIndexes: number[];
  public connectedDropLists$ = this.dropListService.dropListIdsObservable$
    .pipe(
      map(ids => ids.filter(id => !id.includes(this.JOIN_SYMBOL))),
    );

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    @Inject(DROP_LIST_SERVICE) private readonly dropListService: IDropListService,
  ) { }

  public ngOnInit(): void {
    this.calculateSectionsAndCells()
  }

  public ngAfterViewInit(): void {
    this.registerDropLists();
  }

  /**
   * ngOnChanges only recalculates sections and rows when the input values change.
   * It avoids unnecessary recalculations by comparing inputs and ensuring changes.
   */
  public ngOnChanges(changes: SimpleChanges) {
    const { cellsPerRow, cellsPerSection, cellSize } = changes;

    const cellsPerRowUnchanged = !cellsPerRow || cellsPerRow.currentValue === cellsPerRow.previousValue;
    const cellsPerSectionUnchanged = !cellsPerSection || this.arrayEqual(cellsPerSection.currentValue, cellsPerSection.previousValue);
    const cellSizeUnchanged = !cellSize || cellSize.currentValue === cellSize.previousValue;

    if (cellsPerRowUnchanged && cellsPerSectionUnchanged && cellSizeUnchanged) {
      return; // No relevant changes, skip calculations
    }

    this.calculateSectionsAndCells();
  }

  public ngOnDestroy(): void {
    this.rowsPerSection = null;
    this.cellIndexes = null;
  }

  /**
 * Handles the drag-and-drop logic and emits a MoveEvent.
 */
  public move(event: CdkDragDrop<unknown, unknown, GridIndexPath>) {
    const { currentIndex: currentColumnIndex } = event;
    const currentIndexPath = this.parseDropListId(event.container.id);

    this.cellMoved.emit({
      previousIndex: event.item.data,
      currentIndex: {
        ...currentIndexPath,
        column: currentColumnIndex
      }
    });
  }

  /**
   * Track rows by index to improve rendering performance.
   */
  // REVIEW: improve track by
  public trackByIndex(index: number): number {
    return index;
  }

  /**
   * Creates a unique ID for each drop list based on section and row.
   */
  public getDropListId(indexPath: IndexPath): string {
    return `${indexPath.section}${this.JOIN_SYMBOL}${indexPath.row}`;
  }

  /**
   * Parses the drop list ID and returns an IndexPath.
   */
  private parseDropListId(id: string): IndexPath {
    const [section, row] = id.split(this.JOIN_SYMBOL).map(Number);
    return { section, row };
  }

  /**
   * Calculates the number of rows per section based on cells per section and cells per row.
   * It also initializes the cellIndexes array.
   */
  private calculateSectionsAndCells() {
    this.rowsPerSection = [];
    this.cellIndexes = new Array(this.cellsPerRow).fill(null);

    for (let i = 0; i < this.cellsPerSection.length; i++) {
      const rowsForSection = Math.ceil(this.cellsPerSection[i] / this.cellsPerRow);
      // Ensure there's always at least 1 row for drag-and-drop operations, even if the section is empty
      this.rowsPerSection.push(rowsForSection > 0 ? rowsForSection : 1);
    }
  }

  // REVIEW: register onlt visible rows
  private registerDropLists() {
    for (let i = 0; i < this.cellsPerSection.length; i++) {
      for (let row = 0; row < this.rowsPerSection[i]; row++) {
        const id = this.getDropListId({ section: i, row });
        this.dropListService.addDropListId(id);
      }
    }
  }

  /**
   * Helper function to compare two arrays for equality.
   */
  private arrayEqual<T>(a: T[], b: T[]): boolean {
    if (!a || !b) return false;
    if (a === b) return true;
    if (a.length !== b.length) return false;
    return a.every((val, index) => val === b[index]);
  }
}
