import { Finding, FindingsRepository } from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";
import { FindingTopology, ParseFindingMapper } from "./parse-findings.mapper";
import { LatestVersionTopology } from "../latest-version.topology";
import { ObjectTopology } from "../../parse.topology";

export class ParseFindingRepository extends ParseBaseRepository implements FindingsRepository {
  private readonly findingMapper = new ParseFindingMapper(this.parse);

  public async save(finding: Finding): Promise<string> {
    const parseFinding = this.findingMapper.fromDomain(finding);

    const { id } = await parseFinding.save(null, this.options);

    return id;
  }

  public async saveAll(...findings: Finding[]): Promise<string[]> {
    const parseObjects = findings.map((f) => this.findingMapper.fromDomain(f));

    const results = await this.parse.Object.saveAll(parseObjects, this.options);

    return results.map((r) => r.id);
  }

  public async getByUUID(uuid: string): Promise<Finding> {
    const parseFinding = await new this.parse.Query(FindingTopology.TABLE)
      .equalTo(FindingTopology.UUID, uuid)
      .first(this.options);

    return parseFinding ? this.findingMapper.toDomain(parseFinding) : undefined;
  }

  public async getLatestVersion(uuid: string): Promise<Finding> {
    const parseLatestVersionQuery = new this.parse.Query(LatestVersionTopology.TABLE)
      .equalTo(LatestVersionTopology.ENTITY, FindingTopology.TABLE)
      .equalTo(LatestVersionTopology.ENTITY_ID, uuid);

    const parseLatestFindingQuery = new this.parse.Query(FindingTopology.TABLE)
      .matchesKeyInQuery(ObjectTopology.ID, LatestVersionTopology.VERSION_ID, parseLatestVersionQuery)
      .include(FindingTopology.ANALYSIS)
      .include(FindingTopology.PIPELINE_STEP);

    const parseLatestFinding = await parseLatestFindingQuery.first(this.options);

    return parseLatestFinding ? this.findingMapper.toDomain(parseLatestFinding) : undefined;
  }

  public async getVersion(uuid: string, version: number): Promise<Finding> {
    const findingQuery = new this.parse.Query(FindingTopology.TABLE)
      .equalTo(FindingTopology.UUID, uuid)
      .equalTo(FindingTopology.VERSION, version)
      .include(FindingTopology.ANALYSIS)
      .include(FindingTopology.PIPELINE_STEP);

    const findingVersion = await findingQuery.first(this.options);

    return findingVersion ? this.findingMapper.toDomain(findingVersion) : undefined;
  }

  public async *getAllVersions(uuid: string): AsyncGenerator<Finding[]> {
    const findingQuery = new this.parse.Query(FindingTopology.TABLE)
      .equalTo(FindingTopology.UUID, uuid)
      .descending(FindingTopology.VERSION)
      .include(FindingTopology.ANALYSIS)
      .include(FindingTopology.PIPELINE_STEP);

    const batches = this.getGeneratorFromQuery(findingQuery);

    for await (const items of batches) {
      yield items.map(item => this.findingMapper.toDomain(item))
    }
  }
}
