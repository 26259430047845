import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SnackbarAlertComponentModule } from "./components/snackbar-alert/snackbar-alert.component";

@NgModule({
  imports: [CommonModule],
  exports: [SnackbarAlertComponentModule],
})
export class CoreModule {}
