import { Component, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

const COLOR_PICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ColorPickerComponent),
  multi: true,
};

@Component({
  selector: "ts-color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"],
  providers: [COLOR_PICKER_VALUE_ACCESSOR],
})
export class ColorPickerComponent implements ControlValueAccessor {
  public hue: string;
  public color: string;

  onChange: (color: string) => void;

  writeValue(color: string): void {
    this.color = color;
  }

  registerOnChange(fn: (color: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    return;
  }

  change(color: string): void {
    this.onChange(color);
  }
}
