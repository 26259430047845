import { SecondOpinion, SecondOpinionRepository } from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";
import { Parse2ndOpinionMapper, SecondOpinionTopology } from "./parse-second-opinion.mapper";
import { SampleTopology } from "../acquisition";

export class Parse2ndOpinionRepository extends ParseBaseRepository implements SecondOpinionRepository {

  private readonly mapper = new Parse2ndOpinionMapper(this.parse);

  public async getBySampleAndReceiver(sampleId: string, receiverId: string): Promise<SecondOpinion> {
    const ParseSample = this.subclasses.getSubclass(SampleTopology.TABLE);

    const parseSample = ParseSample.createWithoutData(sampleId);
    const parseUser = this.parse.User.createWithoutData(receiverId);

    const parseSecondOpinion = await new this.parse.Query(SecondOpinionTopology.TABLE)
      .equalTo(SecondOpinionTopology.SAMPLE, parseSample)
      .containedIn(SecondOpinionTopology.SEND_TO, [parseUser])
      .includeAll()
      .first(this.options);

    return parseSecondOpinion ? this.mapper.toDomain(parseSecondOpinion) : undefined;
  }

  public async save(secondOpinion: SecondOpinion): Promise<string> {
    const parse2ndOpinion = this.mapper.fromDomain(secondOpinion);

    const { id } = await parse2ndOpinion.save(null, this.options);

    return id;
  }

  public async delete(id: string): Promise<void> {
    const Parse2ndOpinion = this.subclasses.getSubclass(SecondOpinionTopology.TABLE);

    const parse2ndOpinion = Parse2ndOpinion.createWithoutData(id);

    await parse2ndOpinion.destroy(this.options);
  }

  public async findForSample(sampleId: string): Promise<SecondOpinion> {
    const ParseSample = this.subclasses.getSubclass(SampleTopology.TABLE);

    const sample = ParseSample.createWithoutData(sampleId);

    const parse2ndOpinion = await new this.parse.Query(SecondOpinionTopology.TABLE)
      .includeAll()
      .equalTo(SecondOpinionTopology.SAMPLE, sample)
      .first(this.options);

    return parse2ndOpinion ? this.mapper.toDomain(parse2ndOpinion) : undefined;
  }

}
