import type Parse from "parse";

import { IntelispotAnalysis } from "@telespot/domain";

export class ParseIntelispotAnalysisMapper {
  public static toDomain(analysis: Parse.Object, sampleAsset: Parse.Object): IntelispotAnalysis {
    const analysisJSON = analysis.toJSON() as any;
    const sampleAssetJSON = sampleAsset.toJSON() as any;

    analysisJSON.assetId = analysisJSON.asset?.objectId;

    analysisJSON.sample = sampleAssetJSON.sample;
    analysisJSON.case = sampleAssetJSON.sample?.case;
    analysisJSON.workspace = sampleAssetJSON.sample?.case?.workspace;
    analysisJSON.organization = sampleAssetJSON.sample?.case?.workspace?.organization;

    // remove ACLs & duplicated values after mapping
    delete analysisJSON.ACL;

    if (analysisJSON.createdBy) delete analysisJSON.createdBy.ACL;
    if (analysisJSON.algorithm) delete analysisJSON.algorithm.ACL;
    if (analysisJSON.sample.case) delete analysisJSON.sample.case;
    if (analysisJSON.asset) delete analysisJSON.asset;

    return analysisJSON;
  }
}
