import { createFeatureSelector, createSelector } from "@ngrx/store";

import { AnalysisState, getAnalysis, getFindings } from "./analysis.reducer";

export const analysisFeatureKey = "analysis";

export const selectAnalysisFeature =
  createFeatureSelector<AnalysisState>(analysisFeatureKey);

export const selectHasUnsavedChanges = createSelector(
  selectAnalysisFeature,
  (state) => state.lastEditedTimestamp > state.lastSavedTimestamp
);

export const selectUnsyncedAnalysis = createSelector(
  selectAnalysisFeature,
  (state) => getAnalysis(state).filter((analysis) => !analysis.synced)
);

export const selectUnsyncedFindings = createSelector(
  selectAnalysisFeature,
  (state) => getFindings(state).filter((finding) => !finding.synced)
);

export const selectAnalysis = createSelector(
  selectAnalysisFeature,
  getAnalysis
);

export const selectRois = createSelector(selectAnalysisFeature, (state) =>
  Object.values(state.rois.entities || {})
);

export const roisSorted = createSelector(selectRois, (rois) => {
  return [...rois]
    .filter((r) => r.cropFileName)
    .sort((a, b) => {
      if (a.cropFileName && !b.cropFileName) {
        return -1;
      } else if (!a.cropFileName && b.cropFileName) {
        return 1;
      } else {
        return 0;
      }
    });
});

export const selectFindings = createSelector(selectAnalysisFeature, (state) =>
  Object.values(state.findings.entities || {})
);

export const getRoiById = (id: string) =>
  createSelector(selectRois, (rois) => rois.find((roi) => roi.id === id));

export const assetIdsFromAnalysis = (user: Parse.Pointer) =>
  createSelector(selectAnalysis, (analysis) =>
    analysis
      .filter(
        (a) =>
          a?.assetId &&
          a?.createdBy.className === user?.className &&
          a?.createdBy.objectId === user?.objectId
      )
      .map((a) => a.assetId)
  );

export const selectLoadingAnalysis = createSelector(
  selectAnalysisFeature,
  (state) => state.loading
);

export const selectMode = createSelector(
  selectAnalysisFeature,
  (state) => state.mode
);

export const selectmaskLoading = createSelector(
  selectAnalysisFeature,
  (state) => state.maskLoading
);

export const selectSyncingAnalysis = createSelector(
  selectAnalysisFeature,
  (state) => state.syncing
);

export const selectMosaicLoading = createSelector(
  selectAnalysisFeature,
  (state) => state.mosaicsLoading
);

export const selectAssetAnalysisFetched = createSelector(
  selectAnalysisFeature,
  (state) => state.assetsAnalysisFetched
);
