// value object
export class Tag {
  public static create(value: string) {
    // TODO: Validate format using regex
    return new Tag(value);
  }

  private constructor(public readonly value: string) { }

  public get type() {
    const tagComponents = this.value.split("-");
    return tagComponents[0];
  }

  public get size() {
    const tagComponents = this.value.split("-");
    return Number(tagComponents[tagComponents.length - 1]);
  }

  public get scanFileName() {
    return `${this.value}.jpg`;
  }

  public get manifestFileName() {
    return `${this.value}.txt`;
  }
}
