import type Parse from "parse";

import { Invite } from "@telespot/domain";

import { ParseMapper } from "../../parse.mapper";
import { WorkspaceTopology } from "../workspace";
import { OrganizationTopology } from "./parse-organization.mapper";
import { ObjectTopology } from "../../parse.topology";

export enum InviteTopology {
  TABLE = "Invite",
  CODE = "code",
  USES = "uses",
  ROLES = "initialRoles",
  WORKSPACES = "initialWorkspaces",
  ORGANIZATION = "organization",
}

export class ParseInviteMapper extends ParseMapper<Invite> {

  public toDomain(parseInvite: Parse.Object): Invite {
    return new Invite({
      id: parseInvite.id,
      code: String(parseInvite.get(InviteTopology.CODE)),
      uses: Number(parseInvite.get(InviteTopology.USES)),
      initialRoles: parseInvite.get(InviteTopology.ROLES).map((role: Parse.Role) => ({ id: role.id, name: role.getName() })),
      initialWorkspaces: parseInvite.get(InviteTopology.WORKSPACES).map(ws => ws.id),
      organizationId: parseInvite.get(InviteTopology.ORGANIZATION).id,
      createdAt: parseInvite.get(ObjectTopology.CREATED_AT),
      updatedAt: parseInvite.get(ObjectTopology.UPDATED_AT),
    });
  }

  public fromDomain(invite: Invite): Parse.Object {
    const ParseWorkspace = this.subclasses.getSubclass(WorkspaceTopology.TABLE);

    const parseOrganization = this.subclasses
      .getSubclass(OrganizationTopology.TABLE)
      .createWithoutData(invite.organizationId);

    const parseInvite = this.parse.Object.fromJSON({
      className: InviteTopology.TABLE,
      [ObjectTopology.ID]: invite.id,
      [ObjectTopology.CREATED_AT]: invite.createdAt,
      [ObjectTopology.UPDATED_AT]: invite.updatedAt
    })

    parseInvite.set({
      [InviteTopology.CODE]: invite.code,
      [InviteTopology.USES]: invite.uses,
      [InviteTopology.ROLES]: invite.initialRoles.map(role => this.parse.Role.createWithoutData(role.id)),
      [InviteTopology.WORKSPACES]: invite.initialWorkspaces.map(ws => ParseWorkspace.createWithoutData(ws)),
      [InviteTopology.ORGANIZATION]: parseOrganization
    });

    return parseInvite;
  }
}
