import { Object as ParseObject } from 'parse';

import { DeviceType } from '../device-type/device-type.model';

export class AssetType extends ParseObject {
  static className = 'AssetType';

  constructor() {
    super(AssetType.className);
  }

  get name(): string {
    return this.get('name') ?? '';
  }
  set name(value: string) {
    this.set('name', value);
  }

  set description(value: string) {
    this.set('description', value);
  }
  get description(): string {
    return this.get('description');
  }

  set type(value: 'image' | 'video') {
    this.set('type', value);
  }

  get type(): 'image' | 'video' {
    return this.get('type');
  }

  set version(value: string) {
    this.set('version', value);
  }
  get version(): string {
    return this.get('version');
  }

  get deviceTypes(): DeviceType[] {
    return this.get('deviceType');
  }
  setDeviceTypes(deviceTypes: DeviceType[]) {
    this.set('deviceType', []);
    deviceTypes.forEach((deviceType) => {
      this.addUnique('deviceType', deviceType);
    });
  }

  get supportedFileExtensions(): string[] {
    switch (this.type) {
      case 'image':
        return ['.png', '.jpg', '.jpeg', '.dcm'];
      case 'video':
        return ['.mp4', '.avi', '.mov'];
      default:
        return [];
    }
  }
}
