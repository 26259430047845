<div class="workspace-item" *ngIf="workspace" data-cy="workspace-item">
  <div
    class="workspace-item-title"
    data-bs-toggle="tooltip"
    [title]="workspace.name"
  >
    {{ workspace.name | uppercase | truncate : 30 }}
  </div>
  <div class="workspace-item-case-type">
    <!-- {{ 'core.case_type' | translate }}: -->
    <i class="ri-briefcase-4-line"></i>
    <span>{{ workspace.caseType?.name | truncate : 24 }}</span>
  </div>
  <div class="workspace-item-counts">
    <div class="workspace-item-members">
      <i class="ri-group-line"></i>
      {{ workspace.numMembers }}
      {{ (showLabels ? "core.members" : "") | translate | lowercase }}
    </div>
    <div class="workspace-item-cases">
      <i class="ri-file-copy-2-line"></i>
      {{ numCases$ | async }}
      {{ (showLabels ? "core.cases" : "") | translate | lowercase }}
    </div>
  </div>
</div>
