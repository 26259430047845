import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService, DataService } from '@telespot/web-core';
import { Affiliate, Device, DeviceType, Organization, Query } from '@telespot/sdk';
import { defer, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ts-device-editor',
  templateUrl: './device-editor.component.html',
  styleUrls: ['./device-editor.component.scss'],
})
export class DeviceEditorComponent implements OnDestroy {
  private _device: Device;
  deviceTypes$: Observable<DeviceType[]>;
  organizations$: Observable<Organization[]>;
  readonly validateSerialNumbers: boolean = false;

  formGroup: UntypedFormGroup;

  private _destroy$ = new Subject<void>();
  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.deviceTypes$ = defer(() => this.dataService.find(new Query(DeviceType)));
    this.organizations$ = defer(() =>
      this.dataService.find(new Query(Affiliate).equalTo('user', this.authService.currentUser))
    ).pipe(map((affiliates) => affiliates.map((o) => o.organization)));
    this.route.paramMap.pipe(takeUntil(this._destroy$)).subscribe(async (params) => {
      const id = params.get('deviceid');
      if (id) {
        this._device = await this.dataService.get(id, new Query(Device));
      } else {
        this._device = new Device();
      }
      this.formGroup = new UntypedFormGroup({
        serialNumber: new UntypedFormControl(this._device.serialNumber || '', [ValidateSerial]),
        name: new UntypedFormControl(this._device.name || ''),
        deviceType: new UntypedFormControl(this._device.deviceType),
        organization: new UntypedFormControl(this._device.organization),
      });
    });
  }

  deviceTypeName = (deviceType: DeviceType) => (deviceType ? `${deviceType.name} - ${deviceType.version}` : '');
  organizationName = (organization: Organization) => (organization ? organization.name : '');

  ngOnDestroy() {
    this._destroy$.next();
  }

  cancel() {
    this._device.revert();
    this.location.back();
  }

  async save() {
    this._device.serialNumber = this.formGroup.value.serialNumber;
    this._device.name = this.formGroup.value.name;
    this._device.deviceType = this.formGroup.value.deviceType;
    this._device.organization = this.formGroup.value.organization;
    this._device.state = 'active';
    await this.dataService.save(this._device);
    this.location.back();
  }
}

export function ValidateSerial(control: AbstractControl) {
  const value: string = control.value;
  // TODO: implement serial validation when it's stablished
  // if (!value || value.length !== 6) {
  //   return { 'validSerial': true };
  // }
  return null;
}
