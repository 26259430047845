import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(value: number, unit: 'seconds' = 'seconds'): any {
    if (value === undefined || value === null) return null;
    switch (unit) {
      case 'seconds':
        // return `${('00'+Math.floor(value).toString()).slice(-2)}:${('00'+(value-Math.floor(value)).toString()).slice(-2)}`
        return `${('00' + Math.floor(value / 60).toString()).slice(-2)}:${(
          '00' + Math.floor(value - Math.floor(value / 60) * 60).toString()
        ).slice(-2)}`;
    }
  }
}
