import { Object as ParseObject } from "parse";
import { MethodType } from "../method-type/method-type.model";

export enum LabelConfigFields {
  TABLE = "LabelConfig",
  METHODTYPE = "methodType",
  COLOR = "color",
  LABEL = "label",
}

export class LabelConfig extends ParseObject {
  static className = LabelConfigFields.TABLE;

  constructor() {
    super(LabelConfig.className);
  }

  get methodType(): MethodType {
    return this.get(LabelConfigFields.METHODTYPE);
  }

  set methodType(mt: MethodType) {
    this.set(LabelConfigFields.METHODTYPE, mt);
  }

  get color(): string {
    return this.get(LabelConfigFields.COLOR);
  }

  set color(value: string) {
    this.set(LabelConfigFields.COLOR, value);
  }

  get label(): string {
    return this.get(LabelConfigFields.LABEL);
  }
  set label(value: string) {
    this.set(LabelConfigFields.LABEL, value);
  }
}
