import type Parse from "parse";

import { Resource } from "@telespot/domain";

import { ParseMapper } from "../../parse.mapper";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

export enum ResourceTopology {
  TABLE = "Resource",
  NAME = "name",
  TYPE = "type",
  RESOURCES = "resources",
  VERSION = "version",
  DESCRIPTION = "description",
  VALUE = "value",
  METADATA = "metadata",
  DISPLAY_NAME = "displayName",
}

export class ParseResourceMapper extends ParseMapper<Resource> {

  public toDomain(parseObject: Parse.Object): Resource {
    return new Resource({
      id: parseObject.id,
      name: parseObject.get(ResourceTopology.NAME),
      type: parseObject.get(ResourceTopology.TYPE),
      resources: parseObject.get(ResourceTopology.RESOURCES)?.map(r => this.toDomain(r)),
      version: parseObject.get(ResourceTopology.VERSION),
      description: parseObject.get(ResourceTopology.DESCRIPTION),
      value: parseObject.get(ResourceTopology.VALUE),
      metadata: parseObject.get(ResourceTopology.METADATA),
      displayName: parseObject.get(ResourceTopology.DISPLAY_NAME),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      deletedAt: parseObject.get(SoftDeletableTopology.DELETED_AT),
    });
  }

  public fromDomain(resource: Resource): Parse.Object {
    const parseResourceReferences = resource.resources
      .map(r => this.subclasses.getSubclass(ResourceTopology.TABLE).createWithoutData(r.id));

    const parseObject = this.parse.Object.fromJSON({
      className: ResourceTopology.TABLE,
      [ObjectTopology.ID]: resource.id,
      [ObjectTopology.CREATED_AT]: resource.createdAt,
      [ObjectTopology.UPDATED_AT]: resource.updatedAt,
    });

    parseObject.set({
      [SoftDeletableTopology.DELETED_AT]: resource.deletedAt,
      [ResourceTopology.NAME]: resource.name,
      [ResourceTopology.TYPE]: resource.type,
      [ResourceTopology.VERSION]: resource.version,
      [ResourceTopology.VALUE]: resource.value,
      [ResourceTopology.DESCRIPTION]: resource.description,
      [ResourceTopology.RESOURCES]: parseResourceReferences,
      [ResourceTopology.METADATA]: resource.metadata,
      [ResourceTopology.DISPLAY_NAME]: resource.displayName,
    });

    return parseObject;
  }
}
