<div class="acquisition-info">
  <div class="tool-btn-container">
    <ng-container *ngIf="isEditable && !editMode">
      <span class="tool-btn">
        <button
          mat-icon-button
          class="btn btn-flat-primary"
          matTooltip="tooltip.edit_case_data"
          (click)="setEditMode()"
        >
          <i class="ri-pencil-line"></i>
        </button>
      </span>
    </ng-container>
    <ng-container *ngIf="editMode">
      <span class="tool-btn">
        <button
          mat-icon-button
          class="btn btn-outline-danger border-0"
          style="background-color: transparent"
          (click)="discardChanges()"
        >
          <i class="ri-close-fill"></i>
        </button>
        <button
          mat-icon-button
          class="btn btn-flat-primary"
          matTooltip="tooltip.save_case_data"
          (click)="save()"
        >
          <i class="ri-save-2-line"></i>
        </button>
      </span>
    </ng-container>
  </div>
  <ng-container *ngIf="isObject(acquisitionData)">
    <div *ngFor="let assetField of assetFields">
      <ng-container
        *ngIf="
          extractFieldValue(assetField.fieldPath, acquisitionData) as value
        "
      >
        <div class="key" style="display: inline-block">
          {{ assetField.displayName | titlecase }}:
        </div>
        <div style="display: inline-block; padding-bottom: 5px">
          <ng-container *ngIf="!editMode; else editable">
            <span>{{ value }}</span></ng-container
          >
          <ng-template #editable>
            <input
              mat-input
              style="max-width: fit-content"
              [value]="value"
              [size]="value.length"
              type="number"
              (change)="
                updateAssetData(assetField.fieldPath, $event.target.value)
              "
            />
          </ng-template>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
