import { Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { preSyncAnalysis } from "@telespot/analysis-refactor/data-access";
import { AnalysisTask, TRoiSelectionType } from "@telespot/sdk";
import { ViewerService } from "@telespot/shared/viewers/data-access";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { AnalysisPanelDirective } from "../../directives/analysis-panel";

type TMode = "rois" | "other";

@Component({
  selector: "ts-mobile-analysis-panel",
  templateUrl: "./mobile-analysis-panel.component.html",
  styleUrls: ["./mobile-analysis-panel.component.scss"],
})
export class MobileAnalysisPanelComponent
  extends AnalysisPanelDirective
  implements OnDestroy
{
  @Output() onclose = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  private readonly _modeB$ = new BehaviorSubject<TMode>("other");
  readonly mode$ = combineLatest([
    this._modeB$.asObservable(),
    this.context$,
  ]).pipe(
    map(([mode, context]) => (context === "sample" ? "other" : mode)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _destroy$ = new Subject<void>();
  activeDrawingMode$ = this._viewerService.activeDrawingMode$;

  readonly taskFilter = (task: AnalysisTask) =>
    this._modeB$.value === "rois" ? task.roiSelection : !task.roiSelection;

  constructor(private _viewerService: ViewerService, protected store: Store) {
    super(store);
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  // Public API

  setMode(mode: TMode) {
    this._modeB$.next(mode);
  }

  activateDrawingMode(mode: TRoiSelectionType) {
    this._viewerService.toggleDrawingMode(mode);
  }

  toggleFreeHand() {
    console.warn(`Free-hand drawing not implemented`);
  }

  erase() {
    this.delete.emit();
  }

  close() {
    this.onclose.emit();
  }

  saveAnalysis() {
    this.store.dispatch(preSyncAnalysis());
  }
}
