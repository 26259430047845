import { Object as ParseObject } from "parse";

export enum PipelineFields {
  TABLE = "Pipeline",
  NAME = "name",
  ENV = "env",
}

export class Pipeline extends ParseObject {
  static className = PipelineFields.TABLE;

  constructor() {
    super(Pipeline.className);
  }

  set name(value: { [key: string]: string }) {
    this.set("name", value);
  }
  get name(): { [key: string]: string } {
    return this.get("name") ?? {};
  }

  get env(): "cloud" | "edge" {
    return this.get(PipelineFields.ENV);
  }

  set env(value: "cloud" | "edge") {
    this.set(PipelineFields.ENV, value);
  }
}
