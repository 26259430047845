import {
  TAnalysisTask,
  TaskTypeName,
  TAnalysisModelItem,
  TRoiSelectionType,
  RoiModel,
  EAnalysisTaskType,
} from "./interfaces";

export class AnalysisTask implements TAnalysisTask {
  public type?: TaskTypeName;
  public name: string;
  public displayName?: string;
  public required?: boolean = false;
  public assetSpecific?: boolean;

  // selection-specific
  public options?: TAnalysisModelItem[];
  public allowNewOptions?: boolean;
  public multiple?: boolean;
  public roiSelection?: boolean = false;
  public roiSelectionType?: TRoiSelectionType;

  // number-specific
  public decimals?: number;

  // review
  public allOptions?: TAnalysisModelItem[];

  constructor(data: TAnalysisTask) {
    this.name = data.name;
    this.displayName = data.displayName || this.name;
    this.type = data.type;
    // Options re-mapping
    if (data.options) {
      const firstItem = data.options.length ? data.options[0] : undefined;
      if (firstItem) {
        if (typeof firstItem === "string") {
          this.options = (data.options as string[]).map((o) => {
            return {
              name: o,
              displayName: o as string,
            };
          });
        } else if (typeof firstItem === "object") {
          this.options = data.options as TAnalysisModelItem[];
        }
      }
    }
    this.allOptions = this.options;
    this.allowNewOptions = data.allowNewOptions;
    this.multiple = data.multiple;
    this.roiSelection = data.roiSelection;
    this.required = data.required;
    this.assetSpecific = data.assetSpecific;
    this.roiSelectionType =
      data.roiSelectionType ?? TRoiSelectionType.boundingBox;
  }

  public getTaskType(): EAnalysisTaskType {
    switch (this.type) {
      case "boolean":
        return this.assetSpecific
          ? EAnalysisTaskType.asset_checkbox
          : EAnalysisTaskType.sample_checkbox;
        break;
      case "number":
        return EAnalysisTaskType.sample_number;
        break;
      case "selection":
        return this.roiSelection
          ? EAnalysisTaskType.class_rois
          : this.multiple
            ? EAnalysisTaskType.class_counter
            : EAnalysisTaskType.class_presence;
        break;
      case "switcher":
        return EAnalysisTaskType.sample_switcher;
        break;
      case "textedit":
        return this.assetSpecific
          ? EAnalysisTaskType.asset_text
          : EAnalysisTaskType.sample_text;
        break;
    }
  }

  public getModels(): RoiModel[] {
    return (this.options || []).map((o) => ({
      className: o.name,
      displayName: o.displayName,
      ai_models: o.ai_models,
      task: this.toJSON(),
      color: o.color,
    }));
  }

  public toJSON(): TAnalysisTask {
    const { name, displayName, assetSpecific, type, ...extra } = this;
    switch (this.type) {
      case "selection":
        return {
          name,
          displayName,
          type,
          assetSpecific,
          options: extra.options,
          roiSelection: extra.roiSelection,
          roiSelectionType: extra.roiSelectionType,
          allowNewOptions: extra.allowNewOptions,
          multiple: extra.multiple,
        };
      case "number":
        return {
          name,
          displayName,
          type,
          assetSpecific,
        };
      case "textedit":
        return {
          name,
          displayName,
          type,
          assetSpecific,
        };
      case "boolean":
        return {
          name,
          displayName,
          type,
          assetSpecific,
        };
      case "switcher":
        return {
          name,
          displayName,
          type,
          assetSpecific,
          options: extra.options,
        };
    }
  }
}
