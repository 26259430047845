import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CompatibleWSDialogComponent,
  ConfirmDialogConfig,
  ConfirmationDialogComponent,
  DataTableConfig,
  SnackbarAlertComponent,
} from "@shared/ui";
import { Workspace } from "@telespot/sdk";
import { Observable, Subject } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  skip,
  take,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AdaptaspotRowData, columns } from "./adaptaspot-row-data";
import {
  caseActions,
  caseSelectors,
} from "@telespot/analysis-refactor/data-access";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PageEvent } from "@angular/material/paginator";
import { AdaptaspotCaseListDataSource } from "./adaptaspot-data-source";
import { authActions, authSelectors } from "@telespot/auth/data-access";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ts-cases-list-adaptaspot",
  templateUrl: "./cases-list-adaptaspot.component.html",
  styleUrls: ["./cases-list-adaptaspot.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CasesListAdaptaspotComponent implements OnInit, OnDestroy {
  /* Lifecycle parameters */

  private readonly _destroy$ = new Subject<void>();

  /* DataTable configuration parameters */

  public readonly dataSource = new AdaptaspotCaseListDataSource(this.store);
  public tableConfig: DataTableConfig<AdaptaspotRowData>;

  /* State data Observables */

  public readonly onCaseMoved$ = this.applyTerminationCheckPipe(
    this.store.select(caseSelectors.selectMovingCase)
  );
  public readonly onCaseCopied$ = this.applyTerminationCheckPipe(
    this.store.select(caseSelectors.selectCopyingCase)
  );
  public readonly onCaseDeleted$ = this.applyTerminationCheckPipe(
    this.store.select(caseSelectors.selectDeletingCase)
  );

  public readonly decryptingCases$ = this.store.select(
    caseSelectors.selectDecryptingCase
  );
  public readonly compatibleWorkspaces$ = this.store.select(
    caseSelectors.selectCompatibleWorkspaces
  );
  public readonly loadingCompatibleWorkspaces$ = this.store.select(
    caseSelectors.selectLoadingCompatibleWorkspaces
  );
  public readonly decrypted$ = this.store.select(caseSelectors.selectdecrypted);
  public readonly error$ = this.store.select(caseSelectors.selectCaseError);
  public readonly isAdmin$ = this.store.select(authSelectors.selectIsAdmin);

  /* UI instance components and references */

  private loadingPopupDialog: MatDialogRef<ConfirmationDialogComponent, any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private store: Store,
    private i18n: TranslateService
  ) {}

  /* Component lifecycle methods */

  ngOnInit(): void {
    const { id, caseType } = this.route.parent.snapshot.data
      .workspace as Workspace;

    this.tableConfig = this.getTableConfig(caseType.hasEncryptedIdentifier);

    /* Subscribe to relevant events */

    this.onCaseMoved$
      .pipe(withLatestFrom(this.error$))
      .subscribe(([_, error]) => {
        if (error !== null) return;
        this.loadingPopupDialog?.close();
        this.displaySnackMessage("alert.case_migrate_success");
      });

    this.onCaseDeleted$
      .pipe(withLatestFrom(this.error$))
      .subscribe(([_, error]) => {
        if (error !== null) return;
        this.loadingPopupDialog?.close();
        this.displaySnackMessage("alert.case_delete_success");
      });

    this.onCaseCopied$
      .pipe(withLatestFrom(this.error$))
      .subscribe(([_, error]) => {
        if (error !== null) return;
        this.loadingPopupDialog?.close();
        this.displaySnackMessage("alert.case_copied_success");
      });

    this.decryptingCases$
      .pipe(skip(1), distinctUntilChanged(), takeUntil(this._destroy$))
      .subscribe((decrypting) =>
        decrypting
          ? this.displayLoadingSnackMessage("alert.decrypting_cases")
          : this._snackBar.dismiss()
      );

    this.error$
      .pipe(
        filter((value) => !!value),
        takeUntil(this._destroy$)
      )
      .subscribe((error) => {
        this.loadingPopupDialog?.close();
        this.displaySnackMessage(error);
      });

    /* Dispatch actions to initialize state  */

    this.store.dispatch(caseActions.setCurrWorkspace({ id }));
    this.store.dispatch(caseActions.refreshCaseList());
    this.store.dispatch(authActions.fetchRoles());
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this.store.dispatch(caseActions.resetFiltersAndSort());
  }

  /* UI Handlers */

  public sortCases(cryteria) {
    this.store.dispatch(caseActions.sortCases({ cryteria }));
  }

  public applyFilter(filter) {
    this.store.dispatch(caseActions.applyCaseFilter({ filter }));
  }

  public removeFilter(filter) {
    this.store.dispatch(caseActions.removeCaseFilter({ filter }));
  }

  public changePage({ pageIndex, pageSize }: PageEvent) {
    this.store.dispatch(caseActions.changePage({ pageIndex, pageSize }));
  }

  public goToCaseDetail(item): void {
    const routeComponents =
      item.globalState === "analyzed"
        ? ["/reports", "cases", item.case?.objectId]
        : ["/analyze", "cases", item.case?.objectId];

    this.router.navigate(routeComponents);
  }

  public deleteCase(item) {
    const onAccept = (ans) => ans === "accept";

    const deleteCaseNameTitle = this.i18n.instant(
      "dialog.delete_case_x_title",
      { name: `"${item.case.name}"` }
    );

    this.openConfirmationDialog(deleteCaseNameTitle, "dialog.cant_undo_warn")
      .afterClosed()
      .pipe(take(1), filter(onAccept), takeUntil(this._destroy$))
      .subscribe(() => {
        this.loadingPopupDialog = this.openLoadingPopup(
          "dialog.delete_case_title",
          "dialog.delete_case_text"
        );
        this.store.dispatch(caseActions.deleteCase({ id: item.case.objectId }));
      });
  }

  public migrateCase(item) {
    this.store.dispatch(caseActions.listCompatibleWorkspaces());

    const onWorkspaceSelect = (ans) => ans !== "cancel";

    this.openCompatibleWorkspacesPopup("button.migrate")
      .afterClosed()
      .pipe(take(1), filter(onWorkspaceSelect), takeUntil(this._destroy$))
      .subscribe((destination) => {
        this.loadingPopupDialog = this.openLoadingPopup(
          "dialog.move_case_title",
          "dialog.move_case_text"
        );
        this.store.dispatch(
          caseActions.moveCase({ id: item.case.objectId, destination })
        );
      });
  }

  public copyCase(item) {
    this.store.dispatch(caseActions.listCompatibleWorkspaces());

    const onWorkspaceSelect = (ans) => ans !== "cancel";

    this.openCompatibleWorkspacesPopup("button.copy_case")
      .afterClosed()
      .pipe(take(1), filter(onWorkspaceSelect), takeUntil(this._destroy$))
      .subscribe((destination: string) => {
        this.loadingPopupDialog = this.openLoadingPopup(
          "dialog.copy_case_title",
          "dialog.copy_case_text"
        );
        this.store.dispatch(
          caseActions.copyCase({ id: item.case.objectId, destination })
        );
      });
  }

  public toggleDecryption(enable: boolean) {
    const action = enable
      ? caseActions.decryptCases()
      : caseActions.decrypt({ decrypted: false });
    this.store.dispatch(action);
  }

  /* Private functions for component presentation */

  /**
   * Provides a configuration for the cases table.
   *
   * @param encryption a boolean indicating if the table should be configured with decryption in mind
   * @returns an instance of {@link DataTableConfig}
   */
  private getTableConfig(encryption: boolean) {
    return {
      columns,
      showMenu: true,
      showStatusIndicator: true,
      showSearch: true,
      showPaginator: true,
      showFilters: true,
      encryption,
      statusClass: (item) => item.userState,
      useQueryParams: false,
    };
  }

  /**
   * Opens a dialog for selecting compatible workspaces where a given case can be moved or copied.
   *
   * @param acceptMessage a title string for the accept button
   * @returns a {@link MatDialogRef} instance representing a reference to the opened dialog
   */
  private openCompatibleWorkspacesPopup(acceptMessage?: string) {
    const data = {
      compatibleWorkspaces: this.compatibleWorkspaces$,
      acceptMessage,
      loading: this.loadingCompatibleWorkspaces$,
    };
    const compatibleWorkspacesDialogRef = this._dialog.open(
      CompatibleWSDialogComponent,
      { data }
    );

    compatibleWorkspacesDialogRef.disableClose = true;

    return compatibleWorkspacesDialogRef;
  }

  /**
   * Opens up a loading popup with a spinner, a given title and text.
   *
   * @param title string for the popup which indicates the operation performed
   * @param text detail of the operation performed
   * @returns a {@link MatDialogRef} instance representing a reference to the opened dialog
   */
  private openLoadingPopup(title: string, text: string) {
    const data = { title, text, showLoadingSpinner: true };
    const loadingPopupDialogRef = this._dialog.open(
      ConfirmationDialogComponent,
      { data }
    );

    loadingPopupDialogRef.disableClose = true;

    return loadingPopupDialogRef;
  }

  /**
   * Opens a {@link ConfirmationDialogComponent} with a given title and text.
   *
   * @param title string for the confirmation dilog title
   * @param text string for the confirmation  dilog text
   * @returns a {@link MatDialogRef} instance representing a reference to the opened dialog
   */
  private openConfirmationDialog(title: string, text: string) {
    const data: ConfirmDialogConfig = {
      title,
      text,
      acceptButtonText: "core.remove",
      cancelButtonText: "button.cancel",
    };

    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data,
    });

    confirmationDialog.disableClose = true;

    return confirmationDialog;
  }

  /**
   * Display snack message with given title.
   *
   * @param title string for the snack message title
   * @returns a {@link MatSnackBarRef} reference to the opened snack bar
   */
  private displaySnackMessage(title: string) {
    const data = { title, icon: "ri-close-fill" };
    const snackBarRef = this._snackBar.openFromComponent(
      SnackbarAlertComponent,
      { duration: 3000, data }
    );

    return snackBarRef;
  }

  /**
   * Opens a dialog with a spinner and a given title.
   *
   * @param title string for the loading dialog title
   * @returns a {@link MatDialogRef} reference to the opened dialog
   */
  private displayLoadingSnackMessage(title: string) {
    const data = { title, icon: "ri-refresh-line spin" };
    const loadingSnackBarRef = this._snackBar.openFromComponent(
      SnackbarAlertComponent,
      { data }
    );

    return loadingSnackBarRef;
  }

  /* Helper functions */

  /**
   * Transforms a boolean {@link Observable} into another.
   * By skipping the first emission and only emitting when the value has changed to false.
   *
   * @param observable a boolean {@link Observable} to be checked for termination
   * @returns a boolean {@link Observable} which only emits when termination conditions are met
   */
  private applyTerminationCheckPipe(
    observable: Observable<boolean>
  ): Observable<boolean> {
    return observable.pipe(
      skip(1),
      distinctUntilChanged(),
      filter((value) => !value),
      takeUntil(this._destroy$)
    );
  }
}
