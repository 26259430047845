import type Parse from "parse";

import { Sample } from "@telespot/domain";

import { ParseMapper } from "../../parse.mapper";
import { CaseTopology } from "../workspace";
import { OrganizationTopology } from "../organization";
import { MethodTypeTopology } from "../protocols";

import { DeviceTopology } from "./parse-device.mapper";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

export enum SampleTopology {
  TABLE = "Sample",
  ORGANIZATION = "organization",
  CASE = "case",
  NAME = "name",
  CREATED_BY = "createdBy",
  STATE = "state",
  METHOD_TYPE = "methodType",
  DEVICE = "device",
  NUM_ASSETS = "numAssets",
  UUID = "uuid",
}

export class ParseSampleMapper extends ParseMapper<Sample> {

  public toDomain(parseObject: Parse.Object): Sample {
    return new Sample({
      id: parseObject.id,
      organizationId: parseObject.get(SampleTopology.ORGANIZATION)?.id,
      caseId: parseObject.get(SampleTopology.CASE)?.id,
      name: parseObject.get(SampleTopology.NAME),
      createdById: parseObject.get(SampleTopology.CREATED_BY)?.id,
      state: parseObject.get(SampleTopology.STATE)?.state,
      methodTypeId: parseObject.get(SampleTopology.METHOD_TYPE)?.id,
      numAssets: parseObject.get(SampleTopology.NUM_ASSETS),
      deviceId: parseObject.get(SampleTopology.DEVICE)?.id,
      uuid: parseObject.get(SampleTopology.UUID),
      deletedAt: parseObject.get(SoftDeletableTopology.DELETED_AT),
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
    });
  }

  public fromDomain(sample: Sample): Parse.Object {
    const parseCase = this.subclasses
      .getSubclass(CaseTopology.TABLE)
      .createWithoutData(sample.caseId);

    const parseDevice = this.subclasses
      .getSubclass(DeviceTopology.TABLE)
      .createWithoutData(sample.deviceId);

    const parseOrganization = this.subclasses
      .getSubclass(OrganizationTopology.TABLE)
      .createWithoutData(sample.organizationId);

    const parseMethodType = this.subclasses
      .getSubclass(MethodTypeTopology.TABLE)
      .createWithoutData(sample.methodTypeId);

    const parseCreatedBy = this.parse.User.createWithoutData(sample.createdById);

    const parseObject = this.parse.Object.fromJSON({
      className: SampleTopology.TABLE,
      [ObjectTopology.ID]: sample.id,
      [ObjectTopology.CREATED_AT]: sample.createdAt,
      [ObjectTopology.UPDATED_AT]: sample.updatedAt,
    });

    parseObject.set({
      [SampleTopology.ORGANIZATION]: parseOrganization,
      [SampleTopology.CASE]: parseCase,
      [SampleTopology.NAME]: sample.name,
      [SampleTopology.CREATED_BY]: parseCreatedBy,
      [SampleTopology.STATE]: { state: sample.state },
      [SampleTopology.METHOD_TYPE]: parseMethodType,
      [SampleTopology.NUM_ASSETS]: sample.numAssets,
      [SampleTopology.DEVICE]: parseDevice,
      [SampleTopology.UUID]: sample.uuid,
      [SoftDeletableTopology.DELETED_AT]: sample.deletedAt,
    });

    return parseObject;
  }
}
