import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Device, Sample } from "@telespot/sdk";
import { v4 } from "uuid";
import { TFileItem, TSampleGroup } from "../../models/forms-config";

@Component({
  selector: "ts-sample-form",
  templateUrl: "./sample-form.component.html",
  styleUrls: ["./sample-form.component.scss"],
})
export class SampleFormComponent {
  /** PRIVATE VARIABLES */
  private _files: TFileItem[];

  /** PUBLIC VARIABLES */
  public form: UntypedFormGroup;
  public devices: Device[] = [];

  /** INPUTS & OUTPUTS VARIABLES */
  @Input() sample: Sample;

  /** GETTERS & SETTERS */
  get files() {
    return this.form.get("files") as UntypedFormArray;
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private _dialogData,
    @Optional() private _dialogRef: MatDialogRef<SampleFormComponent>
  ) {
    this.sample = this._dialogData?.sample;
    this._files = this._dialogData?.files;
    this.devices = this._dialogData?.devices;
    this.buildForm();
  }

  /* Component Methods */

  private buildForm() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(
        { value: this.sample?.name ?? "", disabled: !this.sample.isNew() },
        [Validators.required]
      ),
      device: new UntypedFormControl(
        { value: this.sample?.device, disabled: !this.sample.isNew() },
        [Validators.required]
      ),
      files: new UntypedFormArray(
        this._files?.map(
          (file) =>
            new UntypedFormGroup({
              file: new UntypedFormControl(file.file),
              fav: new UntypedFormControl(file.fav),
            })
        ) ?? [],
        [Validators.required, Validators.minLength(1)]
      ),
    });
  }

  /* UI Handlers Methods */

  public addFiles(files: File[]) {
    files.forEach((file) => {
      (this.form.controls.files as UntypedFormArray).push(
        new UntypedFormGroup({
          file: new UntypedFormControl(file),
          fav: new UntypedFormControl(false),
        })
      );
    });
  }

  public removeFile(fileIndex: number) {
    (this.form.controls.files as UntypedFormArray).removeAt(fileIndex);
  }

  public onSave() {
    if (this.form.get("name").dirty)
      this.sample.name = this.form.get("name").value;
    if (this.form.get("device").dirty)
      this.sample.device = this.form.get("device").value;
    if (!this.sample.uuid) this.sample.uuid = v4();
    const sampleGroup = {
      sample: this.sample,
      device: this.form.get("device").value,
      files: this.form.get("files").value,
    };
    this._dialogRef?.close(sampleGroup);
  }

  public onRevert() {
    this._dialogRef?.close();
  }

  /* Form Helper Methods */

  public compatibleDevice(device: Device): boolean {
    return this.sample?.methodType.assetType?.deviceTypes?.some(
      (deviceType) => deviceType.id === device.deviceType.id
    );
  }

  public sameId(item1, item2): boolean {
    return item1?.id === item2?.id;
  }
}
