import { Affiliate } from "./affiliate";
import { User } from "./user";
import { ILicenseInfo } from "./license";
import { Entity, SoftDeletable } from "./entity";

export enum AffiliateErrors {
  ORGANIZATION_NOT_FOUND = "Organization does not exist",
  MAX_USER_LIMIT_REACHED = "Error creating Affiliate: reached user limit for the organization's current license",
  USER_ALREADY_BELONGS = "User already belongs to organization",
}

export interface OrganizationItem {
  id: string;
  name: string;
}

export interface OrganizationAttributes extends Entity, SoftDeletable {
  name: string;
  usedStorage: number;
  members: number;
  license: ILicenseInfo;
  logo: string;
}

export class Organization implements Entity, SoftDeletable {
  constructor(private attributes: OrganizationAttributes) { }

  public get id(): string {
    return this.attributes.id;
  }

  public get name(): string {
    return this.attributes.name;
  }

  public get license(): ILicenseInfo {
    return this.attributes.license;
  }

  public get logo(): string {
    return this.attributes.logo;
  }

  public get members(): number {
    return this.attributes.members;
  }

  public get hasEmptySeats(): boolean {
    return this.members < this.license.maxUsers || this.license.maxUsers === -1;
  }

  public get deletedAt(): Date {
    return this.attributes.deletedAt;
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }

  public addUser(user: User): Affiliate {
    if (!this.hasEmptySeats) throw new Error(AffiliateErrors.MAX_USER_LIMIT_REACHED);

    return new Affiliate({
      user,
      organizationId: this.id,
      language: this.license?.language ?? "en",
    });
  }

  public changeLicense(license: ILicenseInfo): void {
    this.attributes.license = license;
  }

  /* OBSOLETE */

  public set usedStorage(value: number) {
    this.attributes.usedStorage = value;
  }
  public get usedStorage(): number {
    return this.attributes.usedStorage;
  }
}
