import { CommonModule } from '@angular/common';
import { ErrorHandler, Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { configure } from '@telespot/sdk';
import { LoggerModule } from '@telespot/shared/logger/feature/util';

import { NgIfRoleDirective } from './directives/ngif-role.directive';
import { httpTokenInterceptorProvider } from './interceptors/token.interceptor';
import { AuthService } from './services/auth/auth.service';
import { DataService } from './services/data/data.service';
import { APIErrorHandler } from './services/error-handler/error-handler.service';

export function DataServiceFactory() {
  let verbose = false;
  try {
    verbose = JSON.parse(localStorage.getItem('verbose') || 'false');
  } catch (err) {
    //
  }
  return new DataService(verbose);
}

@NgModule({
  imports: [CommonModule, LoggerModule],
  declarations: [NgIfRoleDirective],
  exports: [NgIfRoleDirective],
  providers: [httpTokenInterceptorProvider, { provide: ErrorHandler, useClass: APIErrorHandler }],
})
export class TelespotModule {
  constructor(@Inject('environment') environment) {
    configure({
      serverURL: `${environment.api.url}/parse`,
      appId: environment.api.appId,
      javascriptKey: environment.api.jsKey,
      liveQueryServerURL: environment.api.liveQueryServerURL,
    });
  }

  static forRoot(config?: any): ModuleWithProviders<TelespotModule> {
    return {
      ngModule: TelespotModule,
      providers: [
        { provide: 'environment', useValue: config.environment },
        {
          provide: DataService,
          useFactory: DataServiceFactory,
        },
        { provide: AuthService, useClass: AuthService },
        { provide: ErrorHandler, useClass: APIErrorHandler },
      ],
    };
  }
}
