import { CaseRepository, Case } from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";
import { CaseTopology, ParseCaseMapper } from "./parse-case.mapper";
import { ObjectTopology } from "../../parse.topology";

export class ParseCaseRepository extends ParseBaseRepository implements CaseRepository {

  private readonly mapper = new ParseCaseMapper(this.parse);

  public async getByUUID(uuid: string): Promise<Case> {
    const parseCase = await new this.parse.Query(CaseTopology.TABLE)
      .equalTo(CaseTopology.UUID, uuid)
      .first(this.options);

    return parseCase ? this.mapper.toDomain(parseCase) : undefined;
  }

  public async findByIds(...ids: string[]): Promise<Case[]> {
    const parseCases = await new this.parse.Query(CaseTopology.TABLE)
      .containedIn(ObjectTopology.ID, ids)
      .find(this.options);

    return parseCases.map(pc => this.mapper.toDomain(pc));
  }

  public async getById(id: string): Promise<Case> {
    const parseCase = await this.subclasses
      .getSubclass(CaseTopology.TABLE)
      .createWithoutData(id)
      .fetch(this.options);

    return this.mapper.toDomain(parseCase);
  }

  public async save(caseObject: Case): Promise<Case> {
    const result = await this.mapper
      .fromDomain(caseObject)
      .save(null, this.options)

    return this.mapper.toDomain(result);
  }

}
