import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AuthService, DataService } from "@telespot/web-core";
import {
  Analysis,
  AnalysisState,
  Asset,
  Query,
  Sample,
  SecondOpinion,
  User,
  Algorithms,
  AnalysisUtils,
  SampleAsset,
  Case,
} from "@telespot/sdk";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import {
  BehaviorSubject,
  defer,
  forkJoin,
  from,
  Observable,
  of,
  Subject,
} from "rxjs";
import {
  filter,
  finalize,
  map,
  mergeMap,
  shareReplay,
  takeUntil,
  takeWhile,
  tap,
} from "rxjs/operators";
import {
  analysisState,
  sampleAnalysisStateFetched,
  fetchAssetInfo,
  loadAssetIndex,
  markItemAsViewed,
  requestAnalysis,
  selectActiveSample,
  selectAssetId,
  selectAssetIndex,
  setAsset,
  setNumAssets,
  selectAsset,
} from "../../+state";

import { ISampleItem } from "../../models/i-sample-item";
import { selectHasUnsavedChanges } from "../../state";
import { SampleAnalysisProgressTracker } from "../../utils/sample-analysis-progress-tracker";
import { RoiService } from "../roi-service/roi.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarAlertComponent } from "@shared/ui";

const SAMPLEASSETS_QUERY_BATCH_SIZE = 25;
export interface SampleAssetsReq {
  sampleAssets: SampleAsset[];
  skip: number;
}
@Injectable({
  providedIn: "any",
})
export class SampleAnalysisService implements OnDestroy {
  // Internal state --------------------------------------
  // Second Opinion
  private _secondOpinion: SecondOpinion = undefined;

  // State management
  // Action streams

  public readonly selectedAnalysisState$ = this._store.select(analysisState);

  private _progressTracker: SampleAnalysisProgressTracker;

  private _selectedAnalysisState: AnalysisState;
  private _selectedAssetIndex: number;

  private _reviewingUserAnalysis$ = new BehaviorSubject<boolean>(false);
  private _fetchedSampleAssets$ = new BehaviorSubject<SampleAssetsReq>(
    undefined
  );
  public readonly fetchedSampleAssets$ =
    this._fetchedSampleAssets$.asObservable();

  private _sample;
  private _destroy$ = new Subject<void>();
  public readonly sample$ = this._store.select(selectActiveSample).pipe(
    tap((sample) => this._logger.debug(`sample$: ${sample?.name}`)),
    shareReplay(1)
  );
  public readonly copyReview$ = this._reviewingUserAnalysis$.asObservable();

  public readonly isViewMode$ = this.selectedAnalysisState$.pipe(
    filter((_analysisState) => !!_analysisState),
    map(
      (analysisState) =>
        !this.authService.currentUser ||
        !!analysisState.algorithm ||
        analysisState.user?.id !== this.authService.currentUser.id
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * Currently selected asset
   *
   * @memberof SampleAnalysisService
   */

  public readonly selectedAsset$ = this._store.select(selectAsset);

  public readonly dirty$ = this._store.select(selectHasUnsavedChanges);
  private _dirty: boolean;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private _logger: LoggerService,
    private _roiService: RoiService,
    private _store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.dirty$
      .pipe(takeUntil(this._destroy$))
      .subscribe((dirty) => (this._dirty = dirty));

    this._store
      .select(selectActiveSample)
      .pipe(takeUntil(this._destroy$))
      .subscribe((sample) => {
        if (sample) {
          const analysisTypes = sample?.methodType?.analysisTypes;
          const nestedModels = analysisTypes.map((analysisType) =>
            analysisType.getModels()
          );
          const analysisTypeModels = AnalysisUtils.flatten(nestedModels);
          this._roiService.registerModels(undefined, analysisTypeModels);
        }
      });

    this.selectedAnalysisState$.subscribe(
      (analysisState) => (this._selectedAnalysisState = analysisState)
    );
    this._store
      .select(selectAssetIndex)
      .subscribe((index) => (this._selectedAssetIndex = index));
    this._progressTracker = new SampleAnalysisProgressTracker(this._store);
  }
  public fetchAsset(assetId: string): Observable<Asset> {
    const assetQuery = new Query(Asset).equalTo("objectId", assetId);
    return from(assetQuery.first());
  }
  ngOnDestroy() {
    this._destroy$.next();
    this.clear();
  }

  public get progressTracker(): SampleAnalysisProgressTracker {
    return this._progressTracker;
  }

  public nextAsset(): void {
    this._store.dispatch(
      loadAssetIndex({ index: this._selectedAssetIndex, step: 1 })
    );
  }

  public previousAsset(): void {
    this._store.dispatch(
      loadAssetIndex({ index: this._selectedAssetIndex, step: -1 })
    );
  }

  public updateAssetIndex(index: number) {
    this._store.select(selectAssetId).subscribe((assetId) => {
      this.updateURLParams(assetId);
      this._store.dispatch(markItemAsViewed({ assetId: assetId }));
    });
    return of(index);
  }

  public updateURLParams(assetId: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { asset: assetId },
      queryParamsHandling: "merge",
    });
  }

  public getAnalysisState(sampleId: string, historyId?: string) {
    const stateQuery = new Query(AnalysisState).include(["sample", "user"]);
    if (!historyId) {
      stateQuery
        .equalTo("sample", Sample.createWithoutData(sampleId))
        .equalTo("user", this.authService.currentUser);
    }

    return historyId
      ? defer(() => this.dataService.get(historyId, stateQuery))
      : defer(() => this.dataService.first(stateQuery));
  }

  public resolveAnalysisState(sampleId) {
    const sample = Sample.createWithoutData(sampleId);
    const user = this.authService.currentUser;
    const state = "inProgress";

    const newState = new AnalysisState(user, sample, state);

    return defer(() => this.dataService.save(newState));
  }

  public fetchAssetAnalysis(analysisRequest: requestAnalysis) {
    if (!analysisRequest) return;
    const { assetId, createdBy, sampleId } = analysisRequest;
    const sample = Sample.createWithoutData(sampleId);
    const createdByKey =
      createdBy.className === "_User" ? "createdBy" : "algorithm";
    const createdType = createdBy.className === "_User" ? User : Algorithms;
    const createdByObject = createdType.createWithoutData(createdBy?.objectId);
    const asset = Asset.createWithoutData(assetId);

    // Load existing analysis
    const analysisQuery = new Query(Analysis)
      .equalTo("sample", sample)
      .equalTo(createdByKey, createdByObject)
      .exists(createdByKey)
      .exists("analysisType")
      .include(["analysisType", "sample", "asset"])
      .descending("createdAt");

    if (!analysisRequest.sampleAnalysis) {
      analysisQuery.exists("asset").equalTo("asset", asset);
    } else {
      analysisQuery.doesNotExist("asset");
    }

    return defer(() => this.dataService.first(analysisQuery));
  }

  public async setNumAssets(sample) {
    const sampleAssetsQuery = new Query(SampleAsset)
      .equalTo("sample", sample)
      .include(["sample", "asset"])
      .ascending(["index", "dateOfCapture"]);
    const totalSampleAssets = await this.dataService.count(sampleAssetsQuery);
    this._store.dispatch(setNumAssets({ numAssets: totalSampleAssets }));
    return totalSampleAssets;
  }

  public async fetchSampleAssets(sample: Sample, skip: number) {
    const limit = SAMPLEASSETS_QUERY_BATCH_SIZE;
    const sampleAssetsQuery = new Query(SampleAsset)
      .equalTo("sample", sample)
      .include(["sample", "asset"])
      .ascending(["index", "dateOfCapture"]);

    if (skip === 0) {
      this.subscribeToFetchSampleAssets(sample.id);
    }

    sampleAssetsQuery.limit(limit).skip(skip);
    const sampleAssets = await this.dataService.find(sampleAssetsQuery);
    if (sampleAssets?.length !== 0) {
      skip += limit;
      this._fetchedSampleAssets$.next({ sampleAssets, skip });
    }
    return { sampleAssets, skip };
  }

  public subscribeToFetchSampleAssets(sampleId: string) {
    this.fetchedSampleAssets$
      .pipe(
        takeWhile((req) => req === undefined || req.sampleAssets.length >= 25),
        takeUntil(this._destroy$),
        finalize(() => {
          this._fetchedSampleAssets$.next(undefined);
        })
      )
      .subscribe((req) => {
        if (req) {
          if (req.sampleAssets[0].sample.id !== sampleId) return;
          this._store.dispatch(
            fetchAssetInfo({
              selectedSample: req.sampleAssets[0].sample,
              skip: req.skip,
            })
          );
        }
      });
  }

  public mapRefStripItems(sampleAssets: SampleAsset[], skip) {
    return sampleAssets.map((sa, assetIndex) => {
      return {
        assetId: sa?.asset?.id,
        thumbnail: sa?.asset.thumbnail,
        viewed: false,
        reviewed: false,
        hidden: undefined,
        assetIndex: skip - SAMPLEASSETS_QUERY_BATCH_SIZE + assetIndex,
        fav: sa?.asset?.isMarked ?? false,
        analyzed: false,
        favAnalysis: false,
        assetFile: sa?.asset?.assetFile,
        fetched: false,
      };
    }) as ISampleItem[];
  }

  public fetchSampleAndCaseRef(sample: Sample) {
    const nextCaseQuery = new Query(Case)
      .greaterThan("createdAt", new Date(sample.case.createdAt))
      .ascending("createdAt")
      .equalTo("workspace", sample.case.workspace);

    const previousCaseQuery = new Query(Case)
      .lessThan("createdAt", new Date(sample.case.createdAt))
      .descending("createdAt")
      .equalTo("workspace", sample.case.workspace);

    const nextSampleQuery = new Query(Sample)
      .greaterThan("createdAt", new Date(sample.createdAt))
      .ascending("createdAt")
      .equalTo("case", sample.case);
    const previousSampleQuery = new Query(Sample)
      .lessThan("createdAt", new Date(sample.createdAt))
      .descending("createdAt")
      .equalTo("case", sample.case);

    const nextCase$ = from(nextCaseQuery.first());
    const previousCase$ = from(previousCaseQuery.first());
    const nextSample$ = from(nextSampleQuery.first());
    const previousSample$ = from(previousSampleQuery.first());

    return forkJoin([
      nextCase$,
      previousCase$,
      nextSample$,
      previousSample$,
    ]).pipe(
      map(([nextCase, previousCase, nextSample, previousSample]) => ({
        nextCase,
        previousCase,
        nextSample,
        previousSample,
      }))
    );
  }

  public fetchSampleRefFromCases(
    nextCase: Case,
    previousCase: Case,
    nextSample: Sample,
    previousSample: Sample
  ) {
    const nextCaseSampleQuery = nextCase?.id
      ? new Query(Sample)
          .equalTo("case", Case.createWithoutData(nextCase?.id))
          .exists("numAssets")
      : undefined;
    const prevCaseSampleQuery = previousCase?.id
      ? new Query(Sample)
          .equalTo("case", Case.createWithoutData(previousCase?.id))
          .exists("numAssets")
      : undefined;

    const nextCaseSampleQuery$ = from(
      nextCaseSampleQuery ? nextCaseSampleQuery.first() : of(null)
    );
    const prevCaseSampleQuery$ = from(
      prevCaseSampleQuery ? prevCaseSampleQuery.first() : of(null)
    );

    return forkJoin([nextCaseSampleQuery$, prevCaseSampleQuery$]).pipe(
      map(([nextCaseSample, prevCaseSample]) => ({
        nextCase,
        previousCase,
        nextSample,
        previousSample,
        nextCaseSample,
        prevCaseSample,
      }))
    );
  }
  public isAuthUser(user: Parse.Pointer) {
    if (!user) return false;
    return this.authService.currentUser.id === user.objectId;
  }

  public authUserId() {
    return this.authService.currentUser.id;
  }
  public clear() {
    this._logger.log("[SampleAnalysisService] - Clear");

    this._roiService.clearModels();
    this._secondOpinion = undefined;
    this._fetchedSampleAssets$.next(undefined);
    this._reviewingUserAnalysis$.next(false);
    this._store.dispatch(
      sampleAnalysisStateFetched({ analysisState: undefined })
    );
  }

  public async updateAnalysisState(specificState = null) {
    const analysisState = this._selectedAnalysisState;
    analysisState.state =
      specificState ||
      (this._secondOpinion?.analysisTypes ? "analyzed" : "inProgress");
    this._store.dispatch(sampleAnalysisStateFetched({ analysisState }));
    await this.saveAnalysisState(analysisState);
  }

  public async saveAnalysisState(analysisState: AnalysisState) {
    await this.dataService.save(analysisState);
  }

  public get isDirty() {
    return this._dirty;
  }

  public giveUserFeedback(msg) {
    this._snackBar.openFromComponent(SnackbarAlertComponent, {
      duration: 3000,
      data: {
        title: msg,
        icon: "ri-close-fill",
      },
    });
  }

  public setCounterFilterQueryParam(counterFilters: string[]) {
    this.router.navigate([], {
      queryParams: {
        counterFilter: counterFilters.map((counterFilter) =>
          encodeURIComponent(counterFilter)
        ),
      },
      queryParamsHandling: "merge",
    });
  }
}
