import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { WorkspaceService } from "../../services/workspace-service/workspace.service";
import {
  applyWorkspacesFilter,
  removeWorkspacesFilter,
  setCurrOrganization,
  workspacesActionError,
  workspacesChangePage,
  workspacesCounted,
  workspacesListed,
} from "./workspaces.actions";
import { from, of } from "rxjs";
import {
  selectCurrOrganization,
  selectWorkspacesPaginationandFilters,
} from "./workspaces.selectors";

@Injectable()
export class WorkspacesEffects {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private workspaceService: WorkspaceService
  ) {}

  listWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        workspacesChangePage,
        applyWorkspacesFilter,
        removeWorkspacesFilter
      ),
      withLatestFrom(this.store$.select(selectWorkspacesPaginationandFilters)),
      switchMap(([_, options]) =>
        !options.organization
          ? of(workspacesListed({ workspacesList: [] }))
          : from(this.workspaceService.list(options)).pipe(
              map((items) => workspacesListed({ workspacesList: items })),
              catchError((error) =>
                of(
                  workspacesActionError({
                    error: `[listWorkspacesEffect]: ${error.message}`,
                  })
                )
              )
            )
      )
    )
  );

  refreshWorkspacesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrOrganization),
      withLatestFrom(this.store$.select(selectCurrOrganization)),
      switchMap(([_, options]) =>
        !options.organization
          ? of(workspacesListed({ workspacesList: [] }))
          : from(this.workspaceService.list(options)).pipe(
              map((items) => workspacesListed({ workspacesList: items })),
              catchError((error) =>
                of(
                  workspacesActionError({
                    error: `[refreshWorkspacesListEffect]: ${error.message}`,
                  })
                )
              )
            )
      )
    )
  );

  countWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        setCurrOrganization,
        applyWorkspacesFilter,
        removeWorkspacesFilter
      ),
      withLatestFrom(this.store$.select(selectWorkspacesPaginationandFilters)),
      switchMap(([_, options]) =>
        from(this.workspaceService.count(options)).pipe(
          map((count) => workspacesCounted({ count })),
          catchError((error) =>
            of(
              workspacesActionError({
                error: `[countWorkspacesEffect]: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );
}
