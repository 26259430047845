import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  ImageFilter,
  ImageFilterType,
} from "@telespot/shared/viewers/data-access";

@Component({
  selector: "ts-image-filters",
  templateUrl: "./image-filters.component.html",
  styleUrls: ["./image-filters.component.scss"],
})
export class ImageFiltersComponent {
  @Input() filters: ImageFilter[];
  @Input() selectedPreset: number | null;
  @Output() updateFilter = new EventEmitter<ImageFilter>();
  @Output() resetFilter = new EventEmitter<void>();
  @Output() presetFilters = new EventEmitter<{
    preset: number;
    filters: ImageFilter[];
  }>();

  // public selectedPreset: number | null = null;
  public FILTER_PRESETS = {
    1: [
      // Contrast and Sharpness Enhancement
      {
        filter: ImageFilterType.BRIGHTNESS,
        value: 10,
      },
      {
        filter: ImageFilterType.CONTRAST,
        value: 20,
      },
      {
        filter: ImageFilterType.SATURATION,
        value: -10,
      },
      {
        filter: ImageFilterType.NEGATIVE,
        value: 0,
      },
    ],
    2: [
      // Darkfield Observation
      {
        filter: ImageFilterType.BRIGHTNESS,
        value: 30,
      },
      {
        filter: ImageFilterType.CONTRAST,
        value: 45,
      },
      {
        filter: ImageFilterType.SATURATION,
        value: 0,
      },
      {
        filter: ImageFilterType.NEGATIVE,
        value: 1,
      },
    ],
    3: [
      // Color Enhancement
      {
        filter: ImageFilterType.BRIGHTNESS,
        value: -5,
      },
      {
        filter: ImageFilterType.CONTRAST,
        value: 15,
      },
      {
        filter: ImageFilterType.SATURATION,
        value: 30,
      },
      {
        filter: ImageFilterType.NEGATIVE,
        value: 0,
      },
    ],
  };

  public getFilterSlider(): ImageFilterType[] {
    return Object.values(ImageFilterType).filter(
      (filter) => filter !== ImageFilterType.NEGATIVE
    );
  }

  public getFilterToggle(): ImageFilterType[] {
    return Object.values(ImageFilterType).filter(
      (filter) => filter === ImageFilterType.NEGATIVE
    );
  }

  public getFilterValue(filter: string): number {
    return this.filters.find((f) => f.filter === filter)?.value;
  }

  public onSliderChange(filter: ImageFilterType, value: number) {
    this.updateFilter.emit({ filter, value });
  }

  public onToggleChange(filter: ImageFilterType, checked: boolean) {
    const value = checked ? 1 : 0;
    this.updateFilter.emit({ filter, value });
  }

  public onPresetClick(preset: number) {
    if (this.selectedPreset === preset) {
      this.resetFilters();
    } else {
      this.presetFilters.emit({ preset, filters: this.FILTER_PRESETS[preset] });
    }
  }

  public resetFilters(): void {
    this.resetFilter.emit();
  }
}
