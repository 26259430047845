<div class="flex-column align-items-center" style="height: 100%">
  <button
    class="text-primary"
    mat-mini-fab
    color="white"
    (click)="osd?.zoomIn()"
    [matTooltip]="'tooltip.zoom_in' | translate"
  >
    <i class="ri-zoom-in-line"></i>
  </button>

  <mat-slider
    class="flex-grow-1 spotlab-slider"
    style="min-height: 80px"
    *ngIf="osd"
    [min]="osd?.minZoom"
    [max]="osd?.maxZoom"
    [value]="zoom$ | async"
    (input)="setZoom($event)"
    [vertical]="true"
    step="0.0001"
  ></mat-slider>

  <button
    class="text-primary"
    mat-mini-fab
    color="white"
    (click)="osd?.zoomOut()"
    [matTooltip]="'tooltip.zoom_out' | translate"
  >
    <i class="ri-zoom-out-line"></i>
  </button>
</div>
