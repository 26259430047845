import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  AnalysisState,
  AnalysisUtils,
  CloudFunctions,
  User,
} from "@telespot/sdk";
import {
  map,
  switchMap,
  catchError,
  mergeMap,
  withLatestFrom,
  filter,
} from "rxjs/operators";
import { defer, EMPTY, from, of } from "rxjs";
import { SampleAnalysisService } from "../services/sample-analysis/sample-analysis.service";
import * as RoisActions from "./rois.actions";
import {
  analysisState,
  assetsFiltered,
  numAssets,
  refStripItemsNotFetched,
  selectActiveSample,
  selectAsset,
  selectAssetIndex,
} from "./rois.selectors";
import { Store } from "@ngrx/store";
import {
  analysisActionError,
  analysisCopied,
  analysisSynced,
  assetIdsFromAnalysis,
  createAnalysis,
  createAnalysisFromROIs,
  historyActionError,
  Mode,
  protocolActionError,
  removeROIs,
  ROI,
  segmAnalysisCreated,
  selectActiveAssetContextAnalysis,
  selectHasSegmentationTasks,
  selectMode,
  selectSelectedROIS,
  updateROILabels,
} from "../state";
import { AnalysisReviewService } from "../services/analysis-review/analysis-review.service";
import { CaseAnalysisService } from "../services/case-analysis/case-analysis.service";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MaskViewerService } from "@telespot/shared/viewers/data-access";

@Injectable()
export class RoiEffects {
  constructor(
    private actions$: Actions,
    private _sampleAnalysisService: SampleAnalysisService,
    private store$: Store,
    private _analysisReviewService: AnalysisReviewService,
    private _caseAnalysisService: CaseAnalysisService,
    private _maskViewerService: MaskViewerService
  ) {}

  fetchAnalysisState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoisActions.updateSampleAnalysisState),
      withLatestFrom(
        this.store$.select(selectHasSegmentationTasks),
        this.store$.select(selectActiveAssetContextAnalysis),
        this.store$.select(analysisState)
      ),
      switchMap(
        ([
          { historyId, sample },
          hasSegmentationTasks,
          activeAnalysis,
          currAnalysisState,
        ]) => {
          return this._sampleAnalysisService
            .getAnalysisState(sample, historyId)
            .pipe(
              switchMap((analysisState) =>
                of({ _analysisState: analysisState, sampleId: sample })
              ),
              map(({ _analysisState, sampleId }) => {
                if (_analysisState) {
                  this._maskViewerService.saveMaskOnStorageIfNeeded(
                    hasSegmentationTasks,
                    activeAnalysis,
                    this._sampleAnalysisService.isAuthUser(
                      currAnalysisState?.user?.toPointer() ?? undefined
                    )
                  );
                  return RoisActions.sampleAnalysisStateFetched({
                    analysisState: _analysisState,
                  });
                }
                return RoisActions.createAnalysisState({ sampleId });
              }),
              catchError((error) =>
                of(
                  RoisActions.roisActionError({
                    error: `[updateSampleAnalysisState]: ${error.message}`,
                  })
                )
              )
            );
        }
      )
    )
  );

  createAnalysisState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoisActions.createAnalysisState),
      switchMap(({ sampleId }) => {
        return this._sampleAnalysisService.resolveAnalysisState(sampleId).pipe(
          map((_analysisState) => {
            return RoisActions.sampleAnalysisStateFetched({
              analysisState: _analysisState as AnalysisState,
            });
          }),
          catchError((error) =>
            of(
              RoisActions.roisActionError({
                error: `[createAnalysisState]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  checkCaseAnalysisState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoisActions.checkCaseAnalysisState),
      switchMap(({ caseId }) => {
        return this._caseAnalysisService.checkUserAnalysisState(caseId).pipe(
          map((analysisState) => {
            if (analysisState) {
              return RoisActions.caseAnalysisStateFetched({
                analysisState: analysisState as AnalysisState,
              });
            }
            return RoisActions.createCaseAnalysisState({ caseId });
          }),
          catchError((error) =>
            of(
              RoisActions.roisActionError({
                error: `[checkCaseAnalysisState]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  createCaseAnalysisState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoisActions.createCaseAnalysisState),
      switchMap(({ caseId }) => {
        return this._caseAnalysisService.createCaseAnalysisState(caseId).pipe(
          map((analysisState) => {
            return RoisActions.caseAnalysisStateFetched({
              analysisState: analysisState as AnalysisState,
            });
          }),
          catchError((error) =>
            of(
              RoisActions.roisActionError({
                error: `[createCaseAnalysisState]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  updateRefStripData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoisActions.requestStripData),
      mergeMap(({ requestRefStripData }) =>
        of(requestRefStripData).pipe(
          withLatestFrom(
            this.store$.select(
              assetIdsFromAnalysis(requestRefStripData.createdBy)
            ),
            this.store$.select(
              refStripItemsNotFetched(requestRefStripData.limits)
            )
          ),
          map(([req, assetIds, itemsToFetch]) =>
            this._sampleAnalysisService.isAuthUser(
              requestRefStripData.createdBy
            )
              ? { req, assetIds, isAuthUser: true, itemsToFetch }
              : { req, assetIds: [], isAuthUser: false, itemsToFetch }
          )
        )
      ),
      mergeMap(({ req, assetIds, isAuthUser, itemsToFetch }) => {
        if (
          req.createdBy === undefined ||
          req.sampleId === undefined ||
          itemsToFetch.length === 0
        )
          return EMPTY;
        return defer(() =>
          CloudFunctions.GetRefStripData({
            sampleId: req.sampleId,
            createdBy: req.createdBy,
            itemsToFetch,
          })
        ).pipe(
          switchMap((refStripDataResponse) =>
            of({ itemsRequested: itemsToFetch, refStripDataResponse, assetIds })
          ),
          map(({ itemsRequested, refStripDataResponse, assetIds }) =>
            RoisActions.refStripLoaded({
              refStripDataResponse,
              itemsRequested,
              assetIds,
              isAuthUser,
            })
          ),
          catchError((error) => {
            return of(
              RoisActions.roisActionError({
                error: `[requestStripData]: ${error.message}`,
              })
            );
          })
        );
      })
    )
  );

  loadAssetIndex$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoisActions.loadAssetIndex),
      withLatestFrom(
        this.store$.select(selectAssetIndex),
        this.store$.select(numAssets),
        this.store$.select(assetsFiltered)
      ),
      mergeMap(([{ index, step }, prevIndex, numAssets, assetsFiltered]) => {
        let newAssetIndex;
        // if (numAssets) {
        //   index = Math.min(Math.max(index, 0), (numAssets ?? 1) - 1);
        // }
        // if (index + (step ?? 0) === prevIndex) return EMPTY;

        if (step) {
          const itemIndex = assetsFiltered.findIndex(
            (a) => a.assetIndex === index
          );
          newAssetIndex =
            assetsFiltered.find((item, index) => index === itemIndex + step)
              ?.assetIndex ?? index;
        } else {
          newAssetIndex = index;
        }

        return this._sampleAnalysisService.updateAssetIndex(newAssetIndex).pipe(
          map((newAssetIndex) =>
            RoisActions.assetIndexLoaded({ index: newAssetIndex })
          ),
          catchError((error) => {
            return of(
              RoisActions.roisActionError({
                error: `[loadAssetIndex]: ${error.message}`,
              })
            );
          })
        );
      })
    )
  );

  availableAnalysts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoisActions.setSelectedSample),
      mergeMap(({ selectedSample }) => {
        return this._analysisReviewService
          .getAnalysisStatesFromSample(selectedSample)
          .pipe(
            map(({ analysisStates, currentUserId }) => {
              return RoisActions.setAvailableAnalysts({
                analysisStates,
                currentUserId,
              });
            }),
            catchError((error) => {
              return of(
                RoisActions.roisActionError({
                  error: `[setSelectedSample]: ${error.message}`,
                })
              );
            })
          );
      })
    )
  );

  markAsReviewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(analysisSynced),
      withLatestFrom(this.store$.select(selectMode)),
      filter(([action, mode]) => mode === Mode.REVIEW),
      map(([action]) => action),
      mergeMap(({ idChanges }) => {
        const assetIds = Array.from(
          new Set(
            idChanges
              .filter(
                (change) =>
                  change.previous.startsWith("copy:") && change?.assetId
              )
              .map((change) => change?.assetId)
          )
        );
        return of(assetIds).pipe(
          map((assetIds) => {
            return RoisActions.markAssetAsReviewed({ assetIds });
          }),
          catchError((error) => {
            return of(
              RoisActions.roisActionError({
                error: `[analysisSynced]: ${error.message}`,
              })
            );
          })
        );
      })
    )
  );

  markAsAnalyzed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        createAnalysisFromROIs,
        createAnalysis,
        segmAnalysisCreated,
        analysisCopied
      ),
      withLatestFrom(this.store$.select(selectAsset)),
      mergeMap(([action, currAsset]) => {
        return of(RoisActions.markAssetAsAnalyzed({ assetId: currAsset.id }));
      })
    )
  );

  extractAssetInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoisActions.extractAssetsInfo, RoisActions.fetchAssetInfo),
      mergeMap((action) => {
        if (action.skip === 0) {
          return from(
            this._sampleAnalysisService.setNumAssets(action.selectedSample)
          ).pipe(
            map((numAssets) => ({
              skip: action.skip,
              sample: action.selectedSample,
            }))
          );
        }
        return of({
          sample: action.selectedSample,
          skip: action.skip,
        });
      }),
      mergeMap(({ sample, skip }) => {
        return from(
          this._sampleAnalysisService.fetchSampleAssets(sample, skip)
        );
      }),
      map(
        ({ sampleAssets, skip }) => {
          const refStripItems = this._sampleAnalysisService.mapRefStripItems(
            sampleAssets,
            skip
          );
          return RoisActions.loadRefStripElements({ refStripItems });
        },
        catchError((error) =>
          of(
            RoisActions.roisActionError({
              error: `[extractAssetsInfo, fetchAssetInfo]: ${error.message}`,
            })
          )
        )
      )
    )
  );
  getSampleReferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoisActions.setSelectedSample),
      mergeMap(({ selectedSample }) => {
        return this._sampleAnalysisService.fetchSampleAndCaseRef(
          selectedSample
        );
      }),
      mergeMap(({ nextCase, previousCase, nextSample, previousSample }) => {
        return this._sampleAnalysisService
          .fetchSampleRefFromCases(
            nextCase,
            previousCase,
            nextSample,
            previousSample
          )
          .pipe(
            map((references) => {
              return RoisActions.loadCaseSampleRef({ ...references });
            }),
            catchError((error) => {
              return of(
                RoisActions.roisActionError({
                  error: `[setSelectedSample (getSampleReferences)]: ${error.message}`,
                })
              );
            })
          );
      })
    )
  );

  fetchStateErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        analysisActionError,
        protocolActionError,
        historyActionError,
        RoisActions.roisActionError
      ),
      mergeMap(({ error }) => {
        this._sampleAnalysisService.giveUserFeedback(error);
        return of(RoisActions.noOpAction());
      })
    )
  );

  changeAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoisActions.setAsset),
      withLatestFrom(
        this.store$.select(selectHasSegmentationTasks),
        this.store$.select(selectActiveAssetContextAnalysis),
        this.store$.select(analysisState)
      ),
      mergeMap(
        ([action, hasSegmentationTasks, activeAnalysis, currAnalysisState]) => {
          this._maskViewerService.saveMaskOnStorageIfNeeded(
            hasSegmentationTasks,
            activeAnalysis,
            this._sampleAnalysisService.isAuthUser(
              currAnalysisState?.user?.toPointer() ?? undefined
            )
          );
          // if (action.asset)
          //   this._sampleAnalysisService.updateURLParams(action.asset);
          return of(RoisActions.assetLoaded({ asset: action.asset }));
        }
      )
    )
  );
}
