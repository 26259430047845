import { Component, Inject, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PipelineTask } from "@telespot/sdk";

@Component({
  selector: "ts-pipeline-step-editor-dialog",
  templateUrl: "./pipeline-step-editor-dialog.component.html",
  styleUrls: ["./pipeline-step-editor-dialog.component.scss"],
})
export class PipelineStepEditorDialogComponent {
  constructor(
    @Optional()
    public dialogRef: MatDialogRef<PipelineStepEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      pipelineTasks: PipelineTask[];
      tasksFromOtherSteps: PipelineTask[];
      isCloud: boolean;
      insideMethodType: boolean;
    } = {
      pipelineTasks: [],
      tasksFromOtherSteps: [],
      isCloud: true,
      insideMethodType: false,
    }
  ) {}

  close(value) {
    this.dialogRef?.close(value);
  }
}
