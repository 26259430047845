import type { Workspace } from "./workspace";

import { Entity, SoftDeletable } from "./entity";

/**
 * Readonly Case representation ment to be used when listing a case.
 * Has minimal information to be displayed.
 *
 * @group Workspaces
 * @category Entities
 */
export interface CaseItem {
  id: string;
  name: string;
}

/**
 * Attributes for the {@link Case} constructor
 *
 * @group Workspaces
 * @category Entities
 */
export interface CaseCreationAttributes extends Entity, SoftDeletable {
  name: string;
  workspaceId: string;
  organizationId: string;
  data: any; // REVIEW: consider defining Value Object
  createdById: string;
  caseTypeId: string;
  state: any;
  uuid: string;
}

/**
 * Case domain errors
 *
 * @group Workspaces
 * @category Entities
 */
export enum CaseErrors {
  CANT_MOVE = "Can't move case to workspace. CaseTypes incompatible or case already belongs to workspace",
  CANT_COPY = "Can't copy case to workspace. CaseTypes incompatible or case already belongs to workspace",
}

/**
 * Represents a Telespot case object.
 *
 * @group Workspaces
 * @category Entities
 */
export class Case {

  constructor(private attributes: CaseCreationAttributes) { }

  public get id(): string | undefined {
    return this.attributes.id;
  }

  public get name(): string {
    return this.attributes.name;
  }

  public get workspaceId(): string {
    return this.attributes.workspaceId;
  }
  public get data(): any {
    return this.attributes.data;
  }

  public get createdById(): string {
    return this.attributes.createdById;
  }

  public get organizationId(): string {
    return this.attributes.organizationId;
  }

  public get caseTypeId(): string {
    return this.attributes.caseTypeId;
  }

  public get uuid(): string {
    return this.attributes.uuid;
  }

  public get deletedAt(): Date | undefined {
    return this.attributes.deletedAt;
  }

  public get createdAt(): Date | undefined {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date | undefined {
    return this.attributes.updatedAt;
  }

  // REVIEW: define state representation
  public get state() {
    return this.attributes.state;
  }

  /* BUSINESS OPERATIONS THAT CAN BE PERFORMED */

  /**
   * Changes the cases name
   *
   * @param name new case name
   * @returns the case itself
   */
  public rename(name: string): Case {
    this.attributes.name = name;
    return this;
  }

  /**
   * Changes the case's data state
   *
   * @deprecated use updateMetadata instead
   * @param data the data payload to be stored as the case's metadata
   * @returns the case itself
   */
  public updateData(data: any): Case {
    this.attributes.data = data;
    return this;
  }

  /**
   * Changes the case's data state
   *
   * @param data the data payload to be stored as the case's metadata
   * @returns the case itself
   */
  public updateMetadata(data: any): Case {
    this.attributes.data = data;
    return this;
  }

  /**
   * Attempts to move the case to another workspace.
   *
   * @param workspace destination {@link Workspace} where the case will be moved to
   * @throws a {@link CaseErrors.CANT_MOVE} exception when incompatible with workspace
   * @returns the case itself
   */
  public moveToWorkspace(workspace: Workspace): Case {
    if (!this.isCompatibleWith(workspace)) throw new Error(CaseErrors.CANT_MOVE);

    this.attributes.workspaceId = workspace.id;

    return this;
  }

  /**
   * Attempts to copy the case to another workspace.
   *
   * @param workspace destination workspace where the case will be moved to
   * @param uuid a v4 uuid to be used as the new case's uuid
   * @throws a {@link CaseErrors.CANT_COPY} exception when incompatible with workspace
   * @returns a copy of the original case, with a new uuid and workspaceId set to the provided workspaceId
   */
  public copyToWorkspace(workspace: Workspace, uuid: string): Case {
    if (!this.isCompatibleWith(workspace)) throw new Error(CaseErrors.CANT_COPY);

    const { id, ...copyAttr } = this.attributes;

    const copiedCase = new Case({
      ...copyAttr,
      uuid,
      workspaceId: workspace.id,
    });

    return copiedCase;
  }

  /* VALIDATIONS */

  /**
   * Checks compatibility between the case and the workspace.
   *
   * Case is compatible with workspace when all three are true:
   * 1. the case is not in the same workspace
   * 2. the case has the same case type as the workspace
   * 3. the case is in the same organization as the workspace
   *
   * @param workspace target workspace to check agains
   * @returns true if the case is compatible with the workspace, false otherwise
   */
  public isCompatibleWith(workspace: Workspace): boolean {
    const isWithinWorkspace = this.workspaceId === workspace.id;
    const caseTypesAreCompatible = this.caseTypeId === workspace.caseTypeId;
    const isWithinOrganization =
      this.organizationId === workspace.organizationId;

    return !isWithinWorkspace && caseTypesAreCompatible && isWithinOrganization;
  }
}
