import { State } from "../value-objects";
import { Entity, SoftDeletable } from "./entity";

export interface SampleCreationAttributes extends Entity, SoftDeletable {
  organizationId: string;
  caseId: string;
  name: string;
  createdById: string;
  state: State;
  methodTypeId: string;
  numAssets: number;
  deviceId?: string;
  uuid: string;
}

export class Sample implements Entity, SoftDeletable {
  constructor(private attributes: SampleCreationAttributes) { }

  public get id(): string | undefined {
    return this.attributes.id;
  }

  public get organizationId(): string {
    return this.attributes.organizationId;
  }

  public set organizationId(value: string) {
    this.attributes.organizationId = value;
  }

  public get numAssets(): number {
    return this.attributes.numAssets;
  }

  public get deviceId(): string | undefined {
    return this.attributes.deviceId;
  }

  public get uuid(): string {
    return this.attributes.uuid;
  }

  public get deletedAt(): Date | undefined {
    return this.attributes.deletedAt;
  }

  public get updatedAt(): Date | undefined {
    return this.attributes.updatedAt;
  }

  public get createdAt(): Date | undefined {
    return this.attributes.createdAt;
  }

  public addAsset() {
    this.attributes.numAssets++;
  }

  public transferOwnership(organizationId: string): Sample {
    this.attributes.organizationId = organizationId;
    return this;
  }

  public copyToCase(newCaseId: string, newUuid: string): Sample {
    const { id, uuid, caseId: _, ...atributesCopy } = this.attributes;

    return new Sample({
      ...atributesCopy,
      caseId: newCaseId,
      numAssets: 0,
      uuid: newUuid,
    });
  }

  /* OBSOLETE */

  public get caseId(): string {
    return this.attributes.caseId;
  }
  public get name(): string {
    return this.attributes.name;
  }
  public get createdById(): string {
    return this.attributes.createdById;
  }
  public set createdById(userId: string) {
    this.attributes.createdById = userId;
  }

  public get state(): State {
    return this.attributes.state;
  }

  public set state(value) {
    this.attributes.state = value;
  }
  public get methodTypeId(): string {
    return this.attributes.methodTypeId;
  }
}
