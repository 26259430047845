<div class="card padded">
  <div class="card-body column">
    <ts-data-table [config]="tableConfig" [dataSource]="dataSource">
      <ng-template dataTableCell="analysistypes_list" let-item>
        <ul class="list-group list-group-flush">
          <!-- User list -->
          <li class="list-group-item">
            <span [routerLink]="[item.id]" class="flex-row">
              <span class="analysis-type-isMobile">{{
                item.resource.mobile === true ? "mobile" : "cloud"
              }}</span>
              {{ item.resource.name }}
              <i
                class="ri-question-answer-line"
                style="margin-left: 10px"
                *ngIf="item.resource.description"
                data-bs-toggle="tooltip"
                [title]="item.resource.description"
              ></i>
            </span>
            <div class="button-bar float-right stretch">
              <button
                class="btn btn-flat-primary btn-auto"
                [disabled]="item.resource.mobile === true"
                type="button"
                [routerLink]="[item.id]"
              >
                <i class="ri-pencil-line"></i>
              </button>
              <button
                class="btn btn-flat-danger btn-auto disabled"
                disabled
                type="button"
                (click)="removeAnalysisType(item.resource)"
              >
                <i class="ri-delete-bin-6-line"></i>
              </button>
            </div>
          </li>
        </ul>
      </ng-template>
    </ts-data-table>
  </div>
</div>
