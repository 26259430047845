import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { FaqContentsComponent } from './components/faq-contents/faq-contents.component';
import { FaqComponent } from './components/faq/faq.component';
import { SupportRoutingModule } from './support-routing.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '_faq.json');
}

@NgModule({
  declarations: [FaqComponent, FaqContentsComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
      isolate: true,
    }),
    SupportRoutingModule,
  ],
  exports: [],
})
export class SupportFeatureModule {
  constructor(private translateService: TranslateService) {
    this.translateService.setDefaultLang('en');
  }
}
