import { Object as ParseObject } from 'parse';

import { ILicenseInfo } from './i-license-info';

export class License extends ParseObject {
  static className = 'License';

  constructor({ name, info }: { name?: string; info?: ILicenseInfo } = {}) {
    super(License.className);
    this.name = name;
    this.info = info;
  }

  get name(): string {
    return this.get('name') ?? '';
  }

  set name(newName: string) {
    this.set('name', newName);
  }

  get info(): ILicenseInfo {
    return this.get('info');
  }

  set info(newInfo: ILicenseInfo) {
    this.set('info', newInfo);
  }
}
