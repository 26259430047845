import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@telespot/web-core';
import { CaseType, Query, Workspace } from '@telespot/sdk';
import { from, Observable, of, Subject } from 'rxjs';
import { shareReplay, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ts-case-type-detail',
  templateUrl: './case-type-detail.component.html',
  styleUrls: ['./case-type-detail.component.scss'],
})
export class CaseTypeDetailComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  caseType$: Observable<CaseType>;
  workspaces$: Observable<Workspace[]>;

  constructor(private route: ActivatedRoute, private dataService: DataService) {}

  ngOnInit() {
    this.caseType$ = this.route.paramMap.pipe(
      switchMap((params) => {
        const id = params.get('id');
        return id
          ? from(
              this.dataService.get(
                params.get('id'),
                new Query(CaseType).include(['methodTypes.analysisTypes', 'methodTypes.assetType.deviceType'])
              )
            )
          : of(undefined);
      }),
      takeUntil(this._destroy$),
      shareReplay()
    );

    this.workspaces$ = this.caseType$.pipe(
      switchMap((caseType) => {
        return caseType ? from(this.dataService.find(new Query(Workspace).equalTo('caseType', caseType))) : of([]);
      })
    );
  }

  ngOnDestroy() {
    this._destroy$.next();
  }
}
