import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, CaseResolver } from '@telespot/web-core';

import { PrintableReportComponent } from './components/printable-report/printable-report.component';
import { ReportComponent } from './components/report/report.component';

const routes: Routes = [
  {
    path: 'cases/:caseid',
    children: [
      { path: '', component: ReportComponent, canActivate: [AuthGuard], data: { title: 'Case report' } },
      { path: 'print', component: PrintableReportComponent, canActivate: [AuthGuard], data: { title: 'Print case report' } },
    ],
    resolve: { case: CaseResolver },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
