import type Parse from "parse";

import { Organization, OrganizationItem } from "@telespot/domain";
import { ParseMapper } from "../../parse.mapper";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

export enum OrganizationTopology {
  TABLE = "Organization",
  NAME = "name",
  USED_STORAGE = "usedStorage",
  LOGO = "logo",
  LICENSE = "license",
}

export class ParseOrganizationMapper extends ParseMapper<Organization> {

  public static toListView(obj: Parse.Object): OrganizationItem {
    return {
      id: obj.id,
      name: obj.get(OrganizationTopology.NAME)
    }
  }

  public toDomain(parseObject: Parse.Object, members: number): Organization {
    return new Organization({
      id: parseObject.id,
      name: parseObject.get(OrganizationTopology.NAME),
      usedStorage: parseObject.get(OrganizationTopology.USED_STORAGE),
      logo: (parseObject.get(OrganizationTopology.LOGO) as Parse.File)?.name(),
      license: parseObject.get(OrganizationTopology.LICENSE),
      members,
      deletedAt: parseObject.get(SoftDeletableTopology.DELETED_AT),
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
    });
  }

  public fromDomain(organization: Organization): Parse.Object {
    const parseObject = this.parse.Object.fromJSON({
      className: OrganizationTopology.TABLE,
      [ObjectTopology.ID]: organization.id,
      [ObjectTopology.CREATED_AT]: organization.createdAt,
      [ObjectTopology.UPDATED_AT]: organization.updatedAt,
    });

    parseObject.set({
      // [OrganizationTopology.LOGO]: organization.logo, // TODO: implement logo change correctly.
      [OrganizationTopology.LICENSE]: organization.license,
      [OrganizationTopology.USED_STORAGE]: organization.usedStorage,
      [OrganizationTopology.NAME]: organization.name,
      [SoftDeletableTopology.DELETED_AT]: organization.deletedAt,
    });

    return parseObject;
  }
}
