import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@telespot/shared/environment';
import * as StackTrace from 'stacktrace-js';

import { ILoggerConfig, LOGGER_CONFIG, LoggerLevel } from '../logger.config';

@Injectable({
  providedIn: 'root',
  deps: [HttpClient],
})
export class LoggerService {
  private _headers;
  private _serverLoggingEnabled: boolean;
  private _fullStoryLoggingEnabled: boolean;

  constructor(private _http: HttpClient, @Inject(LOGGER_CONFIG) private _loggerConfig: ILoggerConfig) {
    this._serverLoggingEnabled = !!environment.serverLogging;
    if (this._serverLoggingEnabled) {
      this._headers = _loggerConfig.httpHeaders;
    }
    this._fullStoryLoggingEnabled = _loggerConfig.fullStoryLogs;
  }

  public trace(message, ...additional: unknown[]): void {
    this._log(LoggerLevel.TRACE, message, ...additional);
  }

  public debug(message, ...additional: unknown[]): void {
    this._log(LoggerLevel.DEBUG, message, ...additional);
  }

  public info(message, ...additional: unknown[]): void {
    this._log(LoggerLevel.INFO, message, ...additional);
  }

  public log(message, ...additional: unknown[]): void {
    this._log(LoggerLevel.LOG, message, ...additional);
  }

  public warn(message, ...additional: unknown[]): void {
    this._log(LoggerLevel.WARN, message, ...additional);
  }

  public error(error: Error | string, ...additional: unknown[]): void {
    if (error instanceof Error) {
      this._logError(LoggerLevel.ERROR, error);
    } else {
      this._log(LoggerLevel.ERROR, error, ...additional);
    }
  }

  public fatal(error: Error | string, ...additional: unknown[]): void {
    if (error instanceof Error) {
      this._logError(LoggerLevel.FATAL, error);
    } else {
      this._log(LoggerLevel.FATAL, error, ...additional);
    }
  }

  private _log(level: LoggerLevel, message: string, ...additional: unknown[]) {
    if (!!this._loggerConfig.level && level >= this._loggerConfig.level) {
      this._logToConsole(level, message as string, ...additional);
    }
    if (!!this._loggerConfig.serverLogLevel && this._serverLoggingEnabled && level >= this._loggerConfig.serverLogLevel) {
      this._logToServer(level, message as string, ...additional);
    }
    if (this._fullStoryLoggingEnabled && level >= this._loggerConfig.fullStoryLogLevel && level < this._loggerConfig.level) {
      // Forward hidden logs to FullStory
      // this._logToFullStory(level, message);
    }
  }

  private _logError(level: LoggerLevel, error: Error) {
    if (!!this._loggerConfig.level && level >= this._loggerConfig.level) {
      this._logToConsoleAsError(error);
    }
    if (!!this._loggerConfig.serverLogLevel && this._serverLoggingEnabled && level >= this._loggerConfig.serverLogLevel) {
      this._logToServer(level, error);
    }
    if (this._fullStoryLoggingEnabled && level >= this._loggerConfig.fullStoryLogLevel && level < this._loggerConfig.level) {
      // Forward hidden logs to FullStory
      // this._logToFullStory(level, error.message);
    }
  }

  private _logToConsole(level: LoggerLevel, message: string, ...additional: unknown[]) {
    switch (level) {
      case LoggerLevel.TRACE:
        console.log(`%ctrace\t- ${message}`, `color: gray`, ...additional);
        break;
      case LoggerLevel.DEBUG:
        console.log(`%cdebug\t- ${message}`, `color: gray`, ...additional);
        break;
      case LoggerLevel.INFO:
        console.log(`%cinfo\t- ${message}`, `color: #4ca8be`, ...additional);
        break;
      case LoggerLevel.LOG:
        console.log(`%clog\t\t- ${message}`, `color: black`, ...additional);
        break;
      case LoggerLevel.WARN:
        console.warn(`warn\t- ${message}`, ...additional);
        break;
      case LoggerLevel.ERROR:
      case LoggerLevel.FATAL:
        // These should be dealt with _logToConsoleAsError
        this._logToConsoleAsError(message, ...additional);
        break;
    }
  }

  private _logToConsoleAsError(error: Error | string, ...additional) {
    console.error(error, ...additional);
  }

  // private _logToFullStory(level: LoggerLevel, message: string) {
  //   switch (level) {
  //     case LoggerLevel.DEBUG:
  //       FullStory.log(`debug`, message);
  //       break;
  //     case LoggerLevel.INFO:
  //     case LoggerLevel.LOG:
  //       FullStory.log(`info`, message);
  //       break;
  //     case LoggerLevel.WARN:
  //       FullStory.log(`warn`, message);
  //       break;
  //     case LoggerLevel.ERROR:
  //     case LoggerLevel.FATAL:
  //       FullStory.log(`error`, message);
  //       break;
  //   }
  // }

  private async _logToServer(level: LoggerLevel, message: string | Error, ...additional: unknown[]) {
    if (message instanceof Error) {
      try {
        const stack = await StackTrace.fromError(message);
        additional = [stack[0], ...additional];
      } catch (err) {
        //
      }
    }
    const body = {
      message: message instanceof Error ? message.toString() : message,
      timestamp: new Date().toString(),
      level,
      ...(additional.length ? { additional } : undefined),
    };
    this._http
      .post(environment.serverLogging.url, body, {
        headers: this._headers,
        responseType: 'json',
      })
      .subscribe({ error: (err) => console.warn(`Failed to send error to backend: ${err.message}`) });
  }
}
