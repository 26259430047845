import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { SUPPORTED_LANGUAGES, SupportedLanguage } from "@shared/localization";
import { AssetDataField, Resource } from "@telespot/sdk";

@Component({
  selector: "ts-asset-fields",
  templateUrl: "./asset-fields.component.html",
  styleUrls: ["./asset-fields.component.scss"],
})
export class AssetFieldsComponent {
  @Input() resources: Resource[] = [];

  @ViewChild("assetFieldEditorDialog") assetFieldsTemplate: TemplateRef<any>;

  @Output() saved = new EventEmitter<AssetDataField[]>();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  private _originalFields: AssetDataField[] = [];
  private _modifiedFields: AssetDataField[] = [];
  @Input() set assetFields(fields: AssetDataField[]) {
    this._originalFields = (fields ?? []).map((f) => ({ ...f }));
    this._modifiedFields = (fields ?? []).map((f) => ({ ...f }));
  }
  get assetFields() {
    return this._modifiedFields;
  }

  constructor(
    @Inject(SUPPORTED_LANGUAGES) public supported_languages: SupportedLanguage[]
  ) {}

  form: UntypedFormGroup;

  private _editedField: AssetDataField;
  deleteField(field: AssetDataField) {
    this._modifiedFields = this._modifiedFields.filter((mf) => mf !== field);
  }
  edit(field?: AssetDataField) {
    if (!field) {
      field = {
        displayName: "",
        fieldPath: "",
      };
    } else {
      this._editedField = field;
    }
    this.form = new UntypedFormGroup({
      displayName: new UntypedFormControl({
        value: field.displayName ?? "",
        disabled: false,
      }),
      fieldPath: new UntypedFormControl({
        value: field.fieldPath ?? "",
        disabled: false,
      }),
    });
  }

  saveField() {
    this._modifiedFields = [
      ...this._modifiedFields.filter((af) => af !== this._editedField),
      this.form.getRawValue(),
    ];

    this._editedField = undefined;
    this.form = undefined;
  }

  // REVIEW: ñapa
  revert() {
    this._modifiedFields = this._originalFields.map((of) => ({ ...of }));
  }
  save() {
    this.saved.emit(this.assetFields);
  }
}
