import { AbstractControl, ValidationErrors } from '@angular/forms';

export function JSONValidator(control: AbstractControl): ValidationErrors | null {
  try {
    JSON.parse(control.value);
  } catch (err) {
    return {
      invalidJSON: true,
    };
  }
  return null;
}
