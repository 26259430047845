import { AnalysisState, Asset, Sample, SecondOpinion } from "@telespot/sdk";
import { ISampleItem } from "../models/i-sample-item";

export interface IAnalystData {
  analysisState: AnalysisState;
  secondOpinion?: SecondOpinion;
  selected: boolean;
  isCurrentUser?: boolean;
  isAI?: boolean;
}

export interface CaseNavigationReference {
  caseId: string;
  sampleId: string;
}

export interface AssetFilters {
  analyzed: boolean;
  fav: boolean;
  favAnalysis: boolean;
  largeObjects: boolean;
}

export interface MosaicViewer {
  mosaicMode: boolean;
  mosaicView: string;
  selectedLabels: string[];
}

export interface ViewerContextState {
  selectedSample: Sample;
  selectedAsset: Asset;
  error: string | null;
  caseAnalysisState: AnalysisState;
  sampleAnalysisState: AnalysisState;
  refStripItems: ISampleItem[];
  selectedAssetIndex: number;
  availableAnalysts: IAnalystData[];
  numAssets: number;
  previousCase: CaseNavigationReference;
  nextCase: CaseNavigationReference;
  previousSample: string;
  nextSample: string;
  assetFilters: AssetFilters;
  viewerType: string;
  mosaics: MosaicViewer;
  multipleFiltersEnabled: boolean; //Specific for mosaic view
  assetDataSynced: boolean;
}

export const initialState: ViewerContextState = {
  selectedSample: undefined,
  selectedAsset: undefined,
  error: null,
  caseAnalysisState: undefined,
  sampleAnalysisState: undefined,
  refStripItems: [],
  selectedAssetIndex: 0,
  availableAnalysts: [],
  numAssets: undefined,
  previousCase: undefined,
  nextCase: undefined,
  previousSample: undefined,
  nextSample: undefined,
  assetFilters: {
    analyzed: false,
    fav: false,
    favAnalysis: false,
    largeObjects: false,
  },
  viewerType: undefined,
  mosaics: { mosaicMode: false, mosaicView: "grid", selectedLabels: [] },
  multipleFiltersEnabled: false,
  assetDataSynced: true,
};
