import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnalysisTask } from '@telespot/sdk';

@Component({
  selector: 'ts-analysis-task-editor-dialog',
  templateUrl: './analysis-task-editor-dialog.component.html',
  styleUrls: ['./analysis-task-editor-dialog.component.scss'],
})
export class AnalysisTaskEditorDialogComponent {
  constructor(
    @Optional() public dialogRef: MatDialogRef<AnalysisTaskEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      analysisTask: AnalysisTask;
    } = { analysisTask: undefined }
  ) {}

  close(value) {
    this.dialogRef?.close(value);
  }
}
