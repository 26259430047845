import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ts-cases-search',
  templateUrl: './cases-search.component.html',
  styleUrls: ['./cases-search.component.scss'],
})
export class CasesSearchComponent implements OnInit, OnDestroy {
  private _queryParams: ParamMap;

  @Input() iconFocus: boolean;

  @Output() clicked = new EventEmitter<void>();

  search: string;
  private _destroy$ = new Subject<void>();
  private readonly _search$ = new Subject<string>();

  constructor(private _router: Router, private _route: ActivatedRoute) {
    this._route.parent.queryParamMap
      .pipe(
        tap((queryParams) => {
          this._queryParams = queryParams;
          this.search = queryParams?.get('filter') ?? '';
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  ngOnInit(): void {
    this._search$
      .pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this._destroy$))
      .subscribe((searchTerm) => this._applySearch(searchTerm));
  }

  updateSearch(searchValue: string) {
    this._search$.next(searchValue);
  }

  private _applySearch(searchValue: any) {
    this.search = searchValue;
    const params = this._queryParams;
    const queryParams = Object.keys(params).includes('page')
      ? { ...params, page: 0, filter: this.search ?? '' }
      : { ...params, filter: this.search ?? '' };
    this._router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
  }
}
