export class FolderName {

  public static create(value: string): FolderName {
    const specialChars = /\//gi;
    const escapedValue = String(value).replaceAll(specialChars, "_");

    return new FolderName(escapedValue);
  }

  private constructor(public readonly value: string) { }
}
