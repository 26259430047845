import { Asset, CaseType, SampleAsset } from "@telespot/sdk";

import * as labelMap from "./label-map.json";
import * as labelStyle from "./label-style.json";

interface LabelStyle {
  style: string;
  color?: string;
  background?: string;
  borderColor?: string;
}

export class TiraspotUtils {
  static get resultMap(): Map<string, string> {
    const keys = Object.keys(labelMap);
    const toLowerCaseLabelMap = {};

    let n = keys.length;

    while (n--) {
      const key = keys[n];
      toLowerCaseLabelMap[key.toLowerCase()] = labelMap[key];
    }

    return new Map(Object.entries<string>(toLowerCaseLabelMap));
  }

  static styleMap = new Map(Object.entries<LabelStyle>(labelStyle));

  static getTiraspotAnswer(response: string): string[] {
    const lowercaseResponse = response?.toLowerCase();
    return !this.resultMap.has(lowercaseResponse)
      ? []
      : this.resultMap.get(lowercaseResponse).split(",");
  }

  static getTiraspotAnswerStyle(answer: string): any {
    return this.styleMap.get(answer);
  }

  static getTestFilterOptionsFrom(caseType: CaseType) {
    const tests = [].concat(
      ...(caseType?.methodTypes.map((mt) => mt.tiraspotTestsOptions) || [])
    );

    if (tests.length > 0) {
      const testOptions = tests.map((test) => test.metadata?.label);
      return testOptions.filter(
        (option, index) => testOptions.indexOf(option) === index
      );
    }

    const testsAssetFields = [].concat(
      ...(caseType?.methodTypes.map((mt) => mt.tiraspotTests) || [])
    );

    return testsAssetFields.map((test) => test?.value);
  }

  static getAnswerFilterOptionsFrom(caseType: CaseType) {
    const interpretAndGroup = (acc, ans) => {
      const interpretation = this.findKeyInMap(labelMap, ans.value);

      const filter = acc.get(interpretation) || {
        active: true,
        displayName: interpretation,
        value: [],
      };

      filter.value.push(ans.value);

      acc.set(interpretation, filter);

      return acc;
    };

    const answersFromMask = [].concat(
      ...(caseType?.methodTypes.map((mt) => mt.possibleResponsesForAllMasks) ||
        [])
    );

    if (answersFromMask.length > 0) {
      return Array.from(
        answersFromMask.reduce(interpretAndGroup, new Map()).values()
      );
    }

    const answers = [].concat(
      ...(caseType?.methodTypes.map((mt) => mt.possibleAnswersForAllMasks) ||
        [])
    );

    return Array.from(answers.reduce(interpretAndGroup, new Map()).values());
  }

  static findKeyInMap(map: object, key: string) {
    const matchingKey = Object.keys(map).find(
      (k) => k.toLowerCase() === key.toLowerCase()
    );

    return map[matchingKey] || undefined;
  }

  static warningAI(sampleAsset: SampleAsset | Asset): boolean {
    const asset =
      sampleAsset instanceof SampleAsset ? sampleAsset.asset : sampleAsset;
    const userResult = asset?.data?.tiraspot?.result;
    const AIResult = asset?.data?.tiraspot?.ml_result?.ml_output_label;
    if (!AIResult) return false;
    console.log("Get Tiraspot -- warning AI");
    const userAnswer = TiraspotUtils.getTiraspotAnswer(userResult);
    const AIAnswer = TiraspotUtils.getTiraspotAnswer(AIResult);
    if (userAnswer.length !== AIAnswer.length) return true;
    return (
      userAnswer.length !== AIAnswer.length ||
      !userAnswer.every((ua) => AIAnswer.includes(ua))
    );
  }
}
