import { Resource, ResourceRepository } from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";
import { ParseResourceMapper, ResourceTopology } from "./parse-resource.mapper";

export class ParseResourceRepository extends ParseBaseRepository implements ResourceRepository {

  private readonly mapper = new ParseResourceMapper(this.parse);

  public async getById(id: string): Promise<Resource> {
    const resource = await new this.parse.Query(ResourceTopology.TABLE)
      .include(ResourceTopology.RESOURCES)
      .get(id, this.options);

    return this.mapper.toDomain(resource);
  }

}
