import type Parse from "parse";

import { Scan, Tag, ScanRepository, State } from "@telespot/domain";

import { ParseBaseRepository } from "../../parse-base.repository";

import { ParseScanMapper } from "./parse-scan.mapper";
import { SampleAssetTopology } from "./parse-sample-asset.mapper";
import { SampleTopology } from "./parse-sample.mapper";
import { AssetTopology } from "./parse-asset.mapper";
import { DeviceTopology } from "./parse-device.mapper";

export class ParseScanRepository extends ParseBaseRepository implements ScanRepository {

  private readonly scanMapper = new ParseScanMapper(this.parse);

  constructor(
    parse: typeof Parse,
    options: Parse.FullOptions = { useMasterKey: true },
  ) {
    super(parse, options);
  }

  public async fetchScanBySAId(sampleAssetId: string): Promise<Scan> {
    const sampleAsset = await new this.parse.Query(SampleAssetTopology.TABLE)
      .include(SampleAssetTopology.ASSET)
      .get(sampleAssetId, this.options);

    return this.scanMapper.toDomain(sampleAsset);
  }

  // REVIEW: it would be better to separate fetching from creation logic
  public async getScanById(tag: Tag): Promise<Scan> {
    const sampleAsset = await new this.parse.Query(SampleAssetTopology.TABLE)
      .equalTo(SampleAssetTopology.TAG, tag.value)
      .include(SampleAssetTopology.SAMPLE)
      .addAscending(SampleAssetTopology.DATE_OF_CAPTURE)
      .first(this.options);

    if (!sampleAsset) return;

    const originalSample = sampleAsset?.get(SampleAssetTopology.SAMPLE);

    // find / create new sample for the stitching
    const existingSample = await this.findSampleLike(originalSample);

    const sample = existingSample ?? this.createStitchingSampleFrom(originalSample);

    // find / create new asset for the stitching
    const stitchingSampleAsset = sample.isNew()
      ? this.createStitchingSampleAssetFor(sample)
      : await this.findStitchingSampleAssetIn(sample, tag.scanFileName)
      ?? this.createStitchingSampleAssetFor(sample);

    stitchingSampleAsset.get(SampleAssetTopology.ASSET).set(AssetTopology.ASSET_FILE, tag.scanFileName);
    stitchingSampleAsset.set(SampleAssetTopology.DATE_OF_CAPTURE, sampleAsset.get(SampleAssetTopology.DATE_OF_CAPTURE)); // conserves order when displaying within sample

    const assets = await this.findByTag(tag);

    return this.scanMapper.toDomain(stitchingSampleAsset, assets);
  }

  public async save(scan: Scan): Promise<string> {
    const parseScan = this.scanMapper.fromDomain(scan);

    const { id } = await parseScan.save(null, this.options);

    return id;
  }

  private findStitchingSampleAssetIn(
    sample: Parse.Object,
    assetFile: string
  ): Promise<Parse.Object> {
    const assetQuery = new this.parse.Query(AssetTopology.TABLE).equalTo(AssetTopology.ASSET_FILE, assetFile);

    const sampleAssetQuery = new this.parse.Query(SampleAssetTopology.TABLE)
      .matchesQuery(SampleAssetTopology.ASSET, assetQuery)
      .include(SampleAssetTopology.ASSET)
      .equalTo(SampleAssetTopology.SAMPLE, sample);

    return sampleAssetQuery.first(this.options);
  }

  private createStitchingSampleAssetFor(sample: Parse.Object): Parse.Object {
    const asset = new this.parse.Object(AssetTopology.TABLE);

    asset.set(AssetTopology.ORGANIZATION, sample.get(SampleTopology.ORGANIZATION));
    asset.set(AssetTopology.DEVICE, sample.get(SampleTopology.DEVICE));
    asset.set(AssetTopology.METHOD_TYPE, sample.get(SampleTopology.METHOD_TYPE));
    asset.set(AssetTopology.CREATED_BY, sample.get(SampleTopology.CREATED_BY));
    asset.set(AssetTopology.ASSET_TYPE, sample.get(SampleTopology.METHOD_TYPE).get(AssetTopology.ASSET_TYPE));
    asset.set(AssetTopology.STATE, { state: State.PROCESSING });

    // create new sampleAsset for the stitching
    const stitchingSampleAsset = new this.parse.Object(SampleAssetTopology.TABLE);

    stitchingSampleAsset.set(SampleAssetTopology.ASSET, asset);
    stitchingSampleAsset.set(SampleAssetTopology.SAMPLE, sample);

    return stitchingSampleAsset;
  }

  private findSampleLike(sample: Parse.Object): Promise<Parse.Object> {
    return new this.parse.Query(SampleTopology.TABLE)
      .equalTo(SampleTopology.METHOD_TYPE, sample.get(SampleTopology.METHOD_TYPE))
      .equalTo(SampleTopology.CASE, sample.get(SampleTopology.CASE))
      .equalTo(SampleTopology.ORGANIZATION, sample.get(SampleTopology.ORGANIZATION))
      .equalTo(SampleTopology.DEVICE, sample.get(SampleTopology.DEVICE))
      .equalTo(SampleTopology.CREATED_BY, sample.get(SampleTopology.CREATED_BY))
      .equalTo(SampleTopology.NAME, `${sample.get(SampleTopology.NAME)}-stitching`)
      .first(this.options);
  }

  private createStitchingSampleFrom(sample: Parse.Object): Parse.Object {
    const stitchingSample = sample.clone();

    stitchingSample.set(SampleTopology.NAME, `${sample.get(SampleTopology.NAME)}-stitching`);

    return stitchingSample;
  }

  // asures the same conditions apply for both counting and iterating
  private getAssetsByTagQuery(tag: Tag): Parse.Query {
    const taggedSampleAssetsQuery = new this.parse.Query(SampleAssetTopology.TABLE)
      .equalTo(SampleAssetTopology.TAG, tag.value)
      .ascending([SampleAssetTopology.DATE_OF_CAPTURE, SampleAssetTopology.INDEX]);

    return new this.parse.Query(AssetTopology.TABLE).matchesKeyInQuery(
      "objectId",
      `${SampleAssetTopology.ASSET}.objectId`,
      taggedSampleAssetsQuery
    );
  }

  public countByTag(tag: Tag) {
    return this.getAssetsByTagQuery(tag).count(this.options);
  }

  public async findByTag(tag: Tag): Promise<Parse.Object[]> {
    let taggedAssets = [];
    let skip = 0;
    const limit = 100;

    let hasMoreItems = true;

    while (hasMoreItems) {
      const queryResults = await this.getAssetsByTagQuery(tag)
        // REVIEW: in order to optimize consider including the device type for only first asset
        .include(`${AssetTopology.DEVICE}.${DeviceTopology.TYPE}`)
        .skip(skip)
        .limit(limit)
        .find(this.options);

      hasMoreItems = !!queryResults.length;

      taggedAssets = [...taggedAssets, ...queryResults];

      skip += limit;
    }

    return taggedAssets;
  }
}
