import { CommonModule, DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule, Optional } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '@telespot/shared/environment';

@Component({
  selector: 'ts-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutDialogComponent {
  constructor(@Optional() @Inject(DOCUMENT) private _document: Document) {}

  readonly version: string = environment.VERSION;

  readonly lastModified = new Date(this._document?.lastModified);
}

@NgModule({
  imports: [CommonModule, TranslateModule, MatDialogModule],
  declarations: [AboutDialogComponent],
  exports: [AboutDialogComponent],
})
export class AboutDialogComponentModule {}
