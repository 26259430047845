import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@telespot/web-core';
import { fetchRoles, rolesFetched, roleError } from './auth.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { User } from '@telespot/sdk';

@Injectable()
export class AuthEffects {

  constructor(private actions$: Actions, private authService: AuthService) { }

  fetchRoles$ = createEffect(() => this.actions$.pipe(
    ofType(fetchRoles),
    switchMap(() => from(this.authService.getUserRoles(User.current()!))
      .pipe(
        map((roles) => rolesFetched({ roles: roles.map(role => role.getName()) })),
        catchError((error) => of(roleError({ error: `[fetchRolesEffect]: ${error.message}` })))
      ))
  ))
}
