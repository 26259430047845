import { HttpHeaders } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

/**
 * TRACE < DEBUG < INFO < LOG < WARN < ERROR < FATAL
 *
 * @export
 * @enum {number}
 */
export enum LoggerLevel {
  TRACE = 0,
  DEBUG = 1,
  INFO = 2,
  LOG = 3,
  WARN = 4,
  ERROR = 5,
  FATAL = 6,
  OFF = 7,
}

export interface ILoggerConfig {
  level: LoggerLevel;
  serverLogLevel: LoggerLevel;
  fullStoryLogs?: boolean;
  fullStoryLogLevel?: LoggerLevel;
  httpHeaders?: HttpHeaders;
}

export const LOGGER_CONFIG = new InjectionToken<ILoggerConfig>('Logger config');
