import { Component, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CloudFunctions } from '@telespot/sdk';
import { LoggerService } from '@telespot/shared/logger/feature/util';

@Component({
  selector: 'ts-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordResetComponent {
  passwordResetFormErrors: string;
  passwordResetSent: boolean;

  passwordResetForm = new UntypedFormGroup({
    email: new UntypedFormControl(this.route.snapshot.queryParamMap.get('email') || '', [
      Validators.required,
      Validators.email,
    ]),
  });
  constructor(@Optional() private _logger: LoggerService, private route: ActivatedRoute) {}

  public async submit() {
    const email = (this.passwordResetForm.value['email'] as string)?.toLowerCase();
    try {
      const res = await CloudFunctions.SendResetPasswordEmail(email);
      this._logger?.debug(res); // ok
      this.passwordResetSent = true;
    } catch (err) {
      if (/does\s?.*t exist/.test(err.message)) {
        this.passwordResetFormErrors = 'info.password_reset_failed';
      } else {
        this.passwordResetFormErrors = 'label.server_error';
      }
      this._logger?.fatal(`Failed to request password reset: ${err.message}`);
    }
  }
}
