import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AnalysisTypesComponent } from "./components/analysis-types/analysis-types.component";
import { AnalysisTypeEditorComponent } from "./components/analysis-type-editor/analysis-type-editor.component";
import { AssetTypeEditorComponent } from "./components/asset-type-editor/asset-type-editor.component";
import { AssetTypesComponent } from "./components/asset-types/asset-types.component";
import { CaseTypeDetailComponent } from "./components/case-type-detail/case-type-detail.component";
import { CaseTypeEditorComponent } from "./components/case-type-editor/case-type-editor.component";
import { CaseTypesComponent } from "./components/case-types/case-types.component";
import { DeviceTypeEditorComponent } from "./components/device-type-editor/device-type-editor.component";
import { DeviceTypesComponent } from "./components/device-types/device-types.component";
import { MethodTypeEditorComponent } from "./components/method-type-editor/method-type-editor.component";
import { MethodTypesComponent } from "./components/method-types/method-types.component";
import { ResourcesComponent } from "./components/resources/resources.component";
import { ResourceEditorPageComponent } from "./resource-editor-page/resource-editor-page.component";
import { TypesPageComponent } from "./types-page/types-page.component";
import { PipelineEditorComponent } from "./components/pipeline-editor/pipeline-editor.component";
import { PipelinesComponent } from "./components/pipelines/pipelines.component";

const routes: Routes = [
  { path: "new", component: CaseTypeEditorComponent },
  { path: "assetTypes/new", component: AssetTypeEditorComponent },
  { path: "assetTypes/:id", component: AssetTypeEditorComponent },
  { path: "analysisTypes/new", component: AnalysisTypeEditorComponent },
  {
    path: "analysisTypes/:analysisTypeId",
    component: AnalysisTypeEditorComponent,
  },
  {
    path: "methodTypes/edit/new",
    component: MethodTypeEditorComponent,
  },
  {
    path: "methodTypes/edit/:methodTypeId",
    component: MethodTypeEditorComponent,
  },
  {
    path: "",
    component: TypesPageComponent,
    children: [
      { path: "", redirectTo: "caseTypes", pathMatch: "full" },
      {
        path: "caseTypes",
        component: CaseTypesComponent,
        data: { title: "CaseTypes" },
      },
      {
        path: "assetTypes",
        component: AssetTypesComponent,
        data: { title: "AssetTypes" },
      },
      {
        path: "resources",
        component: ResourcesComponent,
        data: { title: "Resources" },
      },
      {
        path: "analysisTypes",
        component: AnalysisTypesComponent,
        data: { title: "AnalysisTypes" },
      },
      {
        path: "deviceTypes",
        component: DeviceTypesComponent,
        data: { title: "DeviceTypes" },
      },
      {
        path: "methodTypes",
        component: MethodTypesComponent,
        data: { title: "MethodTypes" },
      },
      {
        path: "pipelines",
        component: PipelinesComponent,
        data: { title: "Pipelines" },
      },
    ],
    data: { title: "Protocols" },
    runGuardsAndResolvers: "always",
  },
  { path: "caseTypes/edit/:id", component: CaseTypeEditorComponent },
  { path: "caseTypes/:id", component: CaseTypeDetailComponent },
  { path: "deviceTypes/edit/new", component: DeviceTypeEditorComponent },
  { path: "deviceTypes/edit/:id", component: DeviceTypeEditorComponent },
  { path: "resources/new", component: ResourceEditorPageComponent },
  { path: "resources/:resourceId", component: ResourceEditorPageComponent },
  { path: "pipelines/new", component: PipelineEditorComponent },
  { path: "pipelines/:pipelineId", component: PipelineEditorComponent },

  {
    path: "pipelines/edit/:pipelineId",
    component: PipelineEditorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TelespotProtocolsFeatureRoutingModule {}
