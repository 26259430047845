import { InjectionToken } from '@angular/core';
import { RouterLink } from '@angular/router';

export interface NavBarItem {
  name: string;
  icon: string;
  roles?: string[];
  disabled?: boolean;
  tooltip?: string;
  routerLink: string | RouterLink;
}

export const NAV_BAR_ITEMS = new InjectionToken<NavBarItem[]>('NAV_BAR_ITEMS', {
  providedIn: 'root',
  factory: () => [],
});
