<ng-container *ngIf="!roi.w; else boundingBox">
  <i
    osdOverlay
    [position]="roi"
    (click)="handleOverlayClick($event, roi)"
    class="roi-overlay"
    [style.color]="color"
    [class.ri-focus-3-line]="!roi.isAIresult && !selected"
    [class.ri-focus-3-fill]="!roi.isAIresult && selected"
    [class.ri-focus-line]="roi.isAIresult && !selected"
    [class.ri-focus-fill]="roi.isAIresult && selected"
    [matTooltip]="
      hover ? modelsList || ('tooltip.roi_without_tags' | translate) : ''
    "
    [id]="'roi_' + (roi.id || index)"
    [attr.data-cy]="'poi_' + (roi.isAIresult ? 'ai' : 'user')"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
  ></i>
</ng-container>
<ng-template #boundingBox>
  <div
    osdOverlay
    [position]="roi"
    (click)="handleOverlayClick($event, roi)"
    class="roi-overlay"
    [class.selected]="selected"
    style="font-size: 8px; color: white"
    [style.borderStyle]="modelsList ? 'solid' : 'dashed'"
    [style.backgroundColor]="semitransparentColor"
    [matTooltip]="
      hover ? modelsList || ('tooltip.roi_without_tags' | translate) : ''
    "
    [class.roi-overlay-ai]="roi.isAIresult"
    [id]="'roi_' + (roi.id || index)"
    [attr.data-cy]="'roi_' + (roi.isAIresult ? 'ai' : 'user')"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
  >
    <i class="ri-robot-fill" *ngIf="roi.isAIresult"></i>
  </div>
</ng-template>
