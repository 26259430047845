import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService, DataService } from "@telespot/web-core";
import { CaseType, Query, Workspace } from "@telespot/sdk";
import { defer, Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import {
  DataFilterComponent,
  FindRequest,
  IColumn,
  RowData,
} from "@shared/utils";
import { DataTableConfig, SplDataSource } from "@shared/ui";
import { Store } from "@ngrx/store";
import { updateCurrWorkspace } from "@telespot/analysis/data-access";

interface CaseTypeRowData extends RowData {
  resource: CaseType;
  id: string;
}

// TODO: this will probably have its own table in the future
const ENVIRONMENTS = ["tiraspot"];
@Component({
  selector: "ts-workspace-editor",
  templateUrl: "./workspace-editor.component.html",
  styleUrls: ["./workspace-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceEditorComponent
  extends DataFilterComponent
  implements OnInit
{
  caseTypes$: Observable<CaseType[]>;

  private _workspace: Workspace;

  public form = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required]),
    caseType: new UntypedFormControl("", [Validators.required]),
    environment: new UntypedFormControl(""),
  });

  get isNew() {
    return !this._workspace || this._workspace.isNew();
  }
  public readonly showMobileLayout$: Observable<boolean> = this._breakpoint
    .observe([Breakpoints.Handset])
    .pipe(map((r) => r.matches));

  private _destroy$ = new Subject<void>();

  public readonly environments$ = this._authService.currentOrganization$.pipe(
    map((organization) =>
      ENVIRONMENTS.map((environment) => ({
        environment,
        enabled: organization.license?.[environment],
      }))
    )
  );

  public previousRequest: FindRequest;
  public dataSource = new SplDataSource<CaseTypeRowData>(this);
  public tableConfig: DataTableConfig<CaseTypeRowData>;
  public previousCount = 0;
  public oldAvailable: CaseType[];
  public tableName = "casetypes_list";
  public columns: IColumn[] = [
    {
      name: this.tableName,
      value: (item) => item,
    },
  ];

  public klass = CaseType.className;

  public url(item: any) {
    return null;
  }

  constructor(
    private _dataService: DataService,
    private _authService: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _breakpoint: BreakpointObserver,
    private _store: Store
  ) {
    super(_dataService);
    if (!this._authService.currentUser) return;
    if (this._authService.currentUser.is(["admin"])) {
      this.caseTypes$ = defer(() =>
        this._dataService.find(new Query(CaseType))
      );
    } else if (
      this._authService.currentUser.is(["technicianmanager", "analystmanager"])
    ) {
      this.caseTypes$ = defer(() =>
        this._dataService.find(
          new Query(Workspace)
            .equalTo("organization", this._authService.currentOrganizationValue)
            .include("caseType")
        )
      ).pipe(
        map((workspaces) =>
          workspaces
            .map((ws) => ws.caseType)
            .filter((ws) => ws !== undefined)
            .reduce((prev, cur) => {
              if (prev.findIndex((item) => item.id === cur.id) === -1)
                prev.push(cur);
              return prev;
            }, [])
        )
      );
    }
    this._route.parent.paramMap
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (p) => {
        const id = p.get("workspaceId");
        if (id) {
          this._workspace = await this._dataService.get(
            id,
            new Query(Workspace).include("caseType")
          );
        } else {
          this._workspace = new Workspace();
        }
        this._updateFormWithWorkspaceValues();
      });
  }
  ngOnInit(): void {
    this.configureTable({
      hideHeader: true,
    });
  }

  private _updateFormWithWorkspaceValues() {
    this.form.controls.name.setValue(this._workspace.name);
    this.form.controls.caseType.setValue(this._workspace.caseType);
    this.form.controls.environment.setValue(
      this._workspace.config?.environment
    );
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  async submit() {
    this._workspace.name = this.form.value.name;
    this._workspace.caseType = CaseType.createWithoutData(
      this.form.value.caseType._id
        ? this.form.value.caseType._id
        : this.form.value.caseType.id
    ) as CaseType;
    this._workspace.config = { environment: this.form.value.environment };
    if (this._workspace.isNew()) {
      // Set organization only if the workspace is new
      this._workspace.organization = this._authService.currentOrganizationValue;
    }
    const redirectRoute = this._workspace.isNew() ? "members" : "cases";

    await this._dataService.save(this._workspace);
    this._store.dispatch(
      updateCurrWorkspace({
        id: this._workspace.id,
      })
    );
    this._router.navigate(["/workspaces", this._workspace.id, redirectRoute]);
  }

  public setCaseType(caseType: CaseType) {
    this.form.controls["caseType"].patchValue(caseType);
    this.form.markAllAsTouched();
  }

  cancel() {
    if (this._workspace.isNew()) {
      this._router.navigate(["/workspaces"]);
    } else {
      this._router.navigate(["/workspaces", this._workspace.id, "cases"], {
        queryParams: { page: 0, pageSize: 20 },
      });
    }
  }

  byId = (el1: CaseType, el2: CaseType) => el1?.id === el2?.id;

  onRowClicked(event: CaseTypeRowData) {
    this.setCaseType(event.resource);
  }
}
