import { Case, User } from "@telespot/sdk";

export class CaseMapper {
  public static toAdaptaspotRow(states) {
    return (item: Case) => {

      const currentUserState = states.find(
        (userState) =>
          userState.case?.id === item.id &&
          userState.user?.id === User.current().id,
      )?.state ?? "pending";

      const userStates = states.filter((us) => us.case?.id === item.id);
      const userState = (item.currentState?.state ?? currentUserState) === "analyzed" ? "closed" : currentUserState;
      const createdBy = {
        user: item.createdBy,
        date: item.createdAt,
      };

      return {
        case: item.toJSON(),
        createdBy,
        globalState: item.currentState?.state,
        userStates,
        userState,
        analyzed: userStates.filter((us) => us.state === "analyzed"),
        analysing: userStates.filter((us) => us.state === "inProgress"),
      }
    }
  }
}
