import { CommonModule } from '@angular/common';
import { Component, Inject, NgModule, Optional } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

interface ISnackbarData {
  icon: 'ri-save-line' | 'ri-close-fill';
  title: string;
  message: string;
  action?: string;
}

@Component({
  selector: 'ts-snackbar-alert',
  templateUrl: './snackbar-alert.component.html',
  styleUrls: ['./snackbar-alert.component.scss'],
})
export class SnackbarAlertComponent {
  readonly dismiss = () => this._snackDialogRef.dismissWithAction();

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarData,
    @Optional() private _snackDialogRef: MatSnackBarRef<SnackbarAlertComponent>
  ) {}
}

@NgModule({
  imports: [CommonModule, MatSnackBarModule, TranslateModule],
  declarations: [SnackbarAlertComponent],
  exports: [SnackbarAlertComponent],
})
export class SnackbarAlertComponentModule {}
