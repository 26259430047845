import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  get useSequence() {
    return JSON.parse(localStorage.getItem('useSequence') || 'true');
  }
  set useSequence(value: boolean) {
    localStorage.setItem('useSequence', JSON.stringify(value));
  }
  get useCustomReferenceStrip() {
    return JSON.parse(localStorage.getItem('useCustomReferenceStrip') || 'true');
  }
  set useCustomReferenceStrip(value: boolean) {
    localStorage.setItem('useCustomReferenceStrip', JSON.stringify(value));
  }

  getBoolean(setting: string, defaultValue?: boolean): boolean {
    const value = JSON.parse(localStorage.getItem(setting));
    if (value === null && defaultValue !== undefined) {
      localStorage.setItem(setting, JSON.stringify(defaultValue));
    }
    return value;
  }
  setBoolean(setting: string, value: boolean) {
    localStorage.setItem(setting, JSON.stringify(value));
  }
}
