export enum LicenseName {
  Basic = "Basic",
  Genius = "Genius",
  Magic = "Magic"
}

export interface FeatureConfig {
  enabled: boolean;
}

export type Feature<T extends FeatureConfig> = boolean | T;

export interface AnalysisReviewFeatureConfig extends FeatureConfig {
  manager_review?: boolean;
  reference_only?: boolean;
}

export interface ILicenseFeatures {
  ai?: Feature<FeatureConfig>;
  second_opinion?: Feature<FeatureConfig>;
  export_workspace?: Feature<FeatureConfig>;
  analysis_review?: Feature<AnalysisReviewFeatureConfig>;
}

export interface ILicenseInfo {
  name: LicenseName;
  maxStorage: number;
  maxWorkspaces: number;
  maxUsers: number;
  features: ILicenseFeatures;
  tiraspot?: boolean;
  language?: string;
}

export interface LicenseAttributes {
  name: LicenseName;
  info: ILicenseInfo;
}

export class License {
  constructor(private readonly attributes: LicenseAttributes) { }

  public get name(): LicenseName {
    return this.attributes.name;
  }

  public get info(): ILicenseInfo {
    return this.attributes.info;
  }
}
