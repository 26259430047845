import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SettingsService } from '@telespot/settings/data-access';

@Component({
  selector: 'ts-settings-panel',
  templateUrl: './settings-panel.component.html',
  styleUrls: ['./settings-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPanelComponent {
  constructor(private _settings: SettingsService) {}

  get settings() {
    return this._settings;
  }
}
