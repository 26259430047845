import {
  AnalysisState,
  Asset,
  RoiModel,
  Sample,
  SecondOpinion,
} from "@telespot/sdk";
import { ISampleItem } from "../models/i-sample-item";

export interface IAnalystData {
  analysisState: AnalysisState;
  secondOpinion?: SecondOpinion;
  selected: boolean;
  isCurrentUser?: boolean;
  isAI?: boolean;
}

export interface CaseNavRef {
  caseId: string;
  sampleId: string;
}

export interface AssetFilters {
  analyzed: boolean;
  fav: boolean;
  favAnalysis: boolean;
}

export interface ROIState {
  selectedSample: Sample;
  selectedAsset: Asset;
  models: {
    model: RoiModel; // format is <taskname>_<name>
    visible?: boolean;
    selected?: boolean;
    pinned?: boolean;
  }[];
  error: string | null;
  caseAnalysisState: AnalysisState;
  sampleAnalysisState: AnalysisState;
  refStripItems: ISampleItem[];
  selectedAssetIndex: number;
  availableAnalysts: IAnalystData[];
  numAssets: number;
  previousCase: CaseNavRef;
  nextCase: CaseNavRef;
  previousSample: string;
  nextSample: string;
  assetFilters: AssetFilters;
}

export const initialState: ROIState = {
  selectedSample: undefined,
  selectedAsset: undefined,
  models: [],
  error: null,
  caseAnalysisState: undefined,
  sampleAnalysisState: undefined,
  refStripItems: [],
  selectedAssetIndex: 0,
  availableAnalysts: [],
  numAssets: undefined,
  previousCase: undefined,
  nextCase: undefined,
  previousSample: undefined,
  nextSample: undefined,
  assetFilters: {
    analyzed: false,
    fav: false,
    favAnalysis: false,
  },
};
