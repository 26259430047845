import { AnalysisType } from "./analysis-type";
import { Entity, SoftDeletable } from "./entity";

import { Resource, ResourceTypes } from "./resource";

export interface MethodTypeAttributes extends Entity, SoftDeletable {
  name: string;
  config: any;
  resources?: Resource[];
  assetTypeId?: string;
  analysisTypes?: AnalysisType[];
  assetDataFields?: any[];
}

// TODO: simplify dependency on other entities
// FIX: return readonly arrays instead of mutable ones
export class MethodType {
  constructor(private attributes: MethodTypeAttributes) { }

  public get id(): string {
    return this.attributes.id;
  }

  public get assetTypeId(): string {
    return this.attributes.assetTypeId;
  }

  public get name(): string {
    return this.attributes.name;
  }

  public get analysisTypes(): AnalysisType[] {
    return this.attributes.analysisTypes || [];
  }

  public get config(): any {
    return this.attributes.config ?? {};
  }

  public get assetDataFields(): any[] {
    return this.attributes.assetDataFields || [];
  }

  public get resources(): Resource[] {
    return this.attributes.resources || [];
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }

  public get deletedAt(): Date {
    return this.attributes.deletedAt;
  }

  public get maskResources(): Resource[] {
    return this.resources.filter(
      (resource) => resource.type === ResourceTypes.mask
    );
  }

  public get responseResources(): Resource[] {
    return this.resources.filter(
      (resource) => resource.type === ResourceTypes.response
    );
  }

  public get hasRDTs(): boolean {
    return this.maskResources.length > 0;
  }

  public findResourceByName(name: string): Resource {
    return this.resources.find((resource) => resource.name === name);
  }
}
