import type Parse from "parse";

import { SecondOpinion } from "@telespot/domain";
import { ParseSampleMapper } from "../acquisition";
import { ParseUserMapper } from "../accounts";
import { ParseAnalysisTypeMapper } from "../protocols";
import { ParseMapper } from "../../parse.mapper";
import { ObjectTopology } from "../../parse.topology";

export enum SecondOpinionTopology {
  TABLE = "SecondOpinion",
  SAMPLE = "sample",
  ORIGINAL_SAMPLE = "originalSample",
  SEND_TO = "sendTo",
  ANALYSIS_TYPES = "analysisTypes",
  CREATED_BY = "createdBy",
}

export class Parse2ndOpinionMapper extends ParseMapper<SecondOpinion> {

  private readonly userMapper = new ParseUserMapper(this.parse);
  private readonly sampleMapper = new ParseSampleMapper(this.parse);
  private readonly analysisTypeMapper = new ParseAnalysisTypeMapper(this.parse);

  public fromDomain(opinion: SecondOpinion): Parse.Object {
    const parseObject = this.parse.Object.fromJSON({
      className: SecondOpinionTopology.TABLE,
      [ObjectTopology.ID]: opinion.id,
      [ObjectTopology.CREATED_AT]: opinion.createdAt,
      [ObjectTopology.UPDATED_AT]: opinion.updatedAt,
    })

    parseObject.set({
      [SecondOpinionTopology.SAMPLE]: this.sampleMapper.fromDomain(opinion.sample),
      [SecondOpinionTopology.ORIGINAL_SAMPLE]: this.sampleMapper.fromDomain(opinion.originalSample),
      [SecondOpinionTopology.SEND_TO]: opinion.sendTo.map(st => this.userMapper.fromDomain(st)),
      [SecondOpinionTopology.ANALYSIS_TYPES]: opinion.analysisTypes.map(at => this.analysisTypeMapper.fromDomain(at)),
      [SecondOpinionTopology.CREATED_BY]: this.userMapper.fromDomain(opinion.createdBy),
    })

    return parseObject;
  }

  public toDomain(object: Parse.Object): SecondOpinion {

    const parseOriginalSample = object.get(SecondOpinionTopology.ORIGINAL_SAMPLE);
    const parseSample = object.get(SecondOpinionTopology.SAMPLE);
    const parseSendTo = object.get(SecondOpinionTopology.SEND_TO);
    const parseAnalysisTypes = object.get(SecondOpinionTopology.ANALYSIS_TYPES);
    const parseCreatedBy = object.get(SecondOpinionTopology.CREATED_BY);

    const originalSample = parseOriginalSample ? this.sampleMapper.toDomain(parseOriginalSample) : undefined;
    const sample = parseSample ? this.sampleMapper.toDomain(parseSample) : undefined;
    const sendTo = parseSendTo.map(pst => this.userMapper.toDomain(pst));
    const analysisTypes = parseAnalysisTypes.map(at => this.analysisTypeMapper.toDomain(at));
    const createdBy = parseCreatedBy ? this.userMapper.toDomain(parseCreatedBy) : undefined;

    return new SecondOpinion({
      id: object.id,
      originalSample,
      sample,
      sendTo,
      analysisTypes,
      createdBy,
      createdAt: object.get(ObjectTopology.CREATED_AT),
      updatedAt: object.get(ObjectTopology.UPDATED_AT),
    })
  }
}
