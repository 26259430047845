import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Asset } from "@telespot/sdk";
import { LoggerService } from "@telespot/shared/logger/feature/util";

import { IAssetMask } from "../../models/asset-mask";
import {
  IAssetInfo,
  IAssetInfoWithOverlays,
  ViewerConfig,
} from "../../models/viewer-config";

@Injectable({
  providedIn: "root",
})
export class ViewerConfigService {
  constructor(private _logger: LoggerService, private _http: HttpClient) {}

  private _cache = new Map<Asset, IAssetInfoWithOverlays>();
  public clearCache(): void {
    this._cache.clear();
    this._logger.info("[ViewerConfigService] - cache cleared");
  }

  public async getConfig(assets: Asset | Asset[]): Promise<ViewerConfig> {
    this._logger.info("[ViewerConfigService] - Configurig image viewer...");
    let assetInfo: IAssetInfoWithOverlays;

    if (assets instanceof Array) {
      for (const asset of assets) {
        const infoFile = await this._getAssetInfoFile(asset);
        if (infoFile?.tilesData?.tileSize) {
          assetInfo = infoFile;
          break;
        }
      }
    } else {
      assetInfo = await this._getAssetInfoFile(assets);
    }
    this._logger.debug("[ViewerConfigService] - ASSET INFO", assetInfo);
    if (!assetInfo?.tilesData?.tileSize) {
      throw new Error("Invalid info.json. Missing tileSize");
    } else {
      return ViewerConfig.FromAsset(assetInfo);
    }
  }

  private async _getAssetInfoFile(
    asset: Asset
  ): Promise<IAssetInfoWithOverlays> {
    try {
      const existingAssetInfo = this._cache.get(asset);
      if (existingAssetInfo) {
        return existingAssetInfo;
      } else {
        this._logger.debug(`[ViewerConfigService] - Requesting asset.info`);
        const tilesData = await this._http
          .get<IAssetInfo>(asset.infoFile)
          .toPromise();
        const overlaysData = await this._getAssetOverlays(asset);
        const assetInfo: IAssetInfoWithOverlays = {
          tilesData,
          overlaysData,
          tilesUrl: asset.tiles,
        };
        this._cache.set(asset, assetInfo);
        return assetInfo;
      }
    } catch (err) {
      this._logger.error(
        `[ViewerConfigService] - Failed to load info.json: ${err.message}`
      );
    }
  }

  /**
   * Retrieves available masks for a given asset
   *
   * @private
   * @param {Asset} asset
   * @returns {Promise<IAssetMask[]>}
   * @memberof ViewerConfigService
   */
  private async _getAssetOverlays(asset: Asset): Promise<IAssetMask[]> {
    return this._http
      .get<IAssetMask[]>(asset.overlaysInfoEndpoint)
      .toPromise()
      .catch((err) => {
        this._logger.error(
          "[ViewerConfigService] - Error retrieving masks",
          err.message
        );
        return [];
      });
  }
}
