import { Object as ParseObject } from 'parse';

import { AnalysisType, MethodType, User } from '../..';

export class Workflow extends ParseObject {
  static className = 'Workflow';

  constructor() {
    super(Workflow.className);
  }

  set analysisType(value: AnalysisType) {
    this.set('analysisType', value);
  }
  get analysisType(): AnalysisType {
    return this.get('analysisType');
  }

  set config(value: { [key: string]: unknown }) {
    this.set('config', value);
  }
  get config(): { [key: string]: unknown } {
    return this.get('config');
  }

  set name(value: string) {
    this.set('name', value);
  }
  get name(): string {
    return this.get('name');
  }

  set methodType(value: MethodType) {
    this.set('methodType', value);
  }
  get methodType(): MethodType {
    return this.get('methodType');
  }

  get createdBy(): User {
    return this.get('createdBy');
  }
}
