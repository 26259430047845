import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataTableComponent } from '../data-table/data-table.component';
import { FilterContainedInConstraint, TableFilter } from '../models/data-table-filter';

interface TableFiltersGroup {
  filterGroupName: string;
  filters: TableFilter[];
}

@Component({
  selector: 'ts-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFiltersComponent<T> implements OnInit {
  @ViewChild('filtersDialog', { read: TemplateRef }) private dialogTpl: TemplateRef<unknown>;

  public filterGroups$: Observable<TableFiltersGroup[]>;
  constructor(public dataTable: DataTableComponent<T>, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.filterGroups$ = this.dataTable.filters$.pipe(
      map((filters) =>
        filters.reduce<TableFiltersGroup[]>((groups, filter) => {
          const column = this.dataTable.config.columns.find((column) => column.name === filter.column);
          const existingGroup = groups.find((g) => g.filterGroupName === column.displayName);
          if (existingGroup) {
            existingGroup.filters.push(filter);
          } else {
            groups.push({
              filterGroupName: column.displayName,
              filters: [filter],
            });
          }
          return groups;
        }, [])
      )
    );
  }

  toggleFilterOption(filter: TableFilter, option: FilterContainedInConstraint): void {
    this.dataTable.toggleFilterOption(filter, option);
  }

  toggleFilter(filter: TableFilter): void {
    this.dataTable.toggleFilter(filter);
  }

  openFiltersDialog(filterGroups: TableFiltersGroup[]): void {
    this.dialog.open(this.dialogTpl, {
      data: {
        filterGroups,
      },
    });
  }
}
