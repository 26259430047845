<div class="flex-row align-items-center">
  <div class="overlay-panel-toggle" [class.text-primary]="iconFocus" (click)="this.clicked.emit(); searchInput.focus()">
    <i class="ri-search-line"></i>
  </div>
  <mat-form-field class="flex-grow-1" style="margin-left: 8px; width: auto">
    <mat-label> {{ 'label.search_case_name' | translate }} </mat-label>
    <input
      type="text"
      #searchInput
      matInput
      [(ngModel)]="search"
      (ngModelChange)="updateSearch(search)"
      data-cy="table-search"
    />
  </mat-form-field>
</div>
