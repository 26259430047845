import { Platform } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { Directive, HostBinding, NgModule, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@telespot/web-core';
import { environment } from '@telespot/shared/environment';
import { LoggerService } from '@telespot/shared/logger/feature/util';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

interface TSupportData {
  username: string;
  lang: string;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appSupportLink]',
})
export class SupportLinkDirective implements OnInit, OnDestroy {
  @HostBinding('href') private _href: string;
  readonly supportUrls = environment.external_links.support;
  private _destroy$ = new Subject<void>();

  constructor(
    private _translateService: TranslateService,
    private _authService: AuthService,
    private _platform: Platform,
    private _logger: LoggerService
  ) {}

  ngOnInit() {
    combineLatest([this._translateService.onLangChange, this._authService.currentUser$])
      .pipe(
        map(([_, user]) => {
          return {
            username: user !== undefined ? user.username : '',
            lang: this._translateService.currentLang,
          };
        }),
        map((data: TSupportData) => {
          return this.supportUrls[data.lang]
            .replace('__username__', data.username)
            .replace('__browser__', this.getBrowser())
            .replace('__OS__', this.getOS());
        }),
        takeUntil(this._destroy$)
      )
      .subscribe((url) => {
        this._href = url;
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  private getBrowser() {
    if (this._platform.SAFARI) {
      return 'Safari';
    }
    if (this._platform.EDGE) {
      return 'Edge';
    }
    if (this._platform.FIREFOX) {
      return 'Firefox';
    }
    try {
      if (/internet explorer/i.test(window.navigator.userAgent)) {
        return 'Internet Explorer';
      } else {
        return /chrome/i.test(window.navigator.userAgent) ? 'Chrome' : 'Other';
      }
    } catch (err) {
      console.error(`Unable to retrieve browser: ${err.message}`);
    }
    return 'Chrome'; // Assume Google Chrome
  }

  private getOS() {
    if (this._platform.IOS) {
      return 'iPhone';
    }
    if (this._platform.ANDROID) {
      return 'Android';
    }
    try {
      const userAgent = window.navigator.userAgent;
      const oses = {
        Windows: /windows/i,
        Mac: /mac/i,
        Linux: /linux/i,
      };
      for (const key in oses) {
        if (oses[key].test(userAgent)) {
          return key;
        }
      }
    } catch (err) {
      this._logger.warn(`Unable to retrieve OS: ${err.message}`);
    }
    return 'Other';
  }
}

@NgModule({
  declarations: [SupportLinkDirective],
  imports: [CommonModule],
  exports: [SupportLinkDirective],
  providers: [],
})
export class SupportLinkDirectiveModule {}
