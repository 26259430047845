import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DataService } from "@telespot/web-core";
import { AnalysisType, Query, SecondOpinion } from "@telespot/sdk";
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  Subject,
} from "rxjs";
import { map, tap } from "rxjs/operators";
import {
  DataFilterComponent,
  FindRequest,
  IColumn,
  RowData,
} from "@shared/utils";
import { DataTableConfig, SplDataSource } from "@shared/ui";

interface AnalysisTypesRowData extends RowData {
  resource: AnalysisType;
}

@Component({
  selector: "ts-analysis-types",
  templateUrl: "./analysis-types.component.html",
  styleUrls: ["./analysis-types.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisTypesComponent
  extends DataFilterComponent
  implements OnInit
{
  private _destroy$ = new Subject<void>();

  private _loading$ = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this._loading$.asObservable();

  analysisTypes$: Observable<AnalysisType[]> = combineLatest([
    from(this._dataService.findAll(new Query(AnalysisType))),
    from(this._dataService.findAll(new Query(SecondOpinion))),
  ]).pipe(
    map(([analysisTypes, secondOpinions]) =>
      analysisTypes
        .filter(
          (aType) =>
            !secondOpinions.some((sOp) =>
              sOp.analysisTypes.some((sOpAt) => sOpAt.id === aType.id)
            )
        )
        .sort((at1, at2) =>
          at1.name
            .toLocaleLowerCase()
            .localeCompare(at2.name.toLocaleLowerCase())
        )
    ),
    tap(() => this._loading$.next(false))
  );

  public previousRequest: FindRequest;
  public oldAvailable: AnalysisType[];
  public tableName = "analysistypes_list";
  public dataSource = new SplDataSource<AnalysisTypesRowData>(this);
  public tableConfig: DataTableConfig<AnalysisTypesRowData>;

  public klass = AnalysisType.className;
  public columns: IColumn[] = [
    {
      name: this.tableName,
      value: (item) => item,
    },
  ];

  constructor(private _dataService: DataService, private dialog: MatDialog) {
    super(_dataService);
  }
  ngOnInit() {
    this.configureTable({
      hideHeader: true,
    });
  }
  removeAnalysisType(analysisType) {
    alert("Not implemented");
  }
}
