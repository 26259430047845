import { CommonModule } from "@angular/common";
import { Component, forwardRef, Input, NgModule, OnInit } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { SupportedLanguage } from "../../models/supported-language";

@Component({
  selector: "ts-language-input",
  templateUrl: "./language-input.component.html",
  styleUrls: ["./language-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguageInputComponent),
      multi: true,
    },
  ],
})
export class LanguageInputComponent implements OnInit, ControlValueAccessor {
  private _change;
  private _touched;
  private _disabled = false;
  public get disabled() {
    return this._disabled;
  }

  @Input() languages: SupportedLanguage[] = [];

  public form: UntypedFormGroup;

  writeValue(obj: { [key: string]: string }): void {
    if (obj) this.form.patchValue(obj);
  }
  registerOnChange(fn: any): void {
    this._change = fn;
  }
  registerOnTouched(fn: any): void {
    this._touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  update() {
    this._change(this.form.value);
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup(
      this.languages.reduce(
        (all: { [key: string]: AbstractControl }, lang) => ({
          ...all,
          [lang.value]: new UntypedFormControl(),
        }),
        {}
      )
    );
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    TranslateModule,
  ],
  declarations: [LanguageInputComponent],
  exports: [LanguageInputComponent],
})
export class LanguageInputComponentModule {}
