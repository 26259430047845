import type { Readable } from "node:stream";

import { FileOperation, FileRepository } from "./file.repository";

export class FileDecoratorRepository implements FileRepository {

  constructor(private readonly repository: FileRepository) { }

  public get directory() {
    return this.repository.directory;
  }

  public write(path: string, content: string | Readable | Buffer) {
    return this.repository.write(path, content);
  }

  public append(path: string, content: string) {
    return this.repository.append(path, content);
  }

  public read(path: string) {
    return this.repository.read(path);
  }

  public getSignedURL(path: string, operation: FileOperation) {
    return this.repository.getSignedURL(path, operation);
  }

  public metadata(path: string) {
    return this.repository.metadata(path);
  }

  public exists(path: string) {
    return this.repository.exists(path);
  }

  public fetch(path: string) {
    return this.repository.fetch(path);
  }

  public list(path: string) {
    return this.repository.list(path);
  }

  public deleteFiles(...paths: string[]) {
    return this.repository.deleteFiles(...paths);
  }

  public deleteFolder(path?: string) {
    return this.repository.deleteFolder(path)
  }

  public copyFolder(destination: FileRepository, path?: string) {
    return this.repository.copyFolder(destination, path)
  }
}
