import { Object as ParseObject } from "parse";
import { Analysis, PipelineStep, StepTask } from "../..";

export enum FindingFields {
  TABLE = "Findings",
  CREATOR_ID = "creatorId",
  CREATOR_ENTITY = "creatorEntity",
  ANALYSIS = "analysis",
  PIPELINE_STEP = "pipelineStep",
  VERSION = "version",
  TYPE = "type",
  DATA = "data",
  ORIGIN = "origin",
  UUID = "uuid",
}

export class Finding extends ParseObject {
  static className = FindingFields.TABLE;

  constructor() {
    super(Finding.className);
  }

  get creatorId(): string {
    return this.get(FindingFields.CREATOR_ID);
  }

  set creatorId(value: string) {
    this.set(FindingFields.CREATOR_ID, value);
  }

  get creatorEntity(): "user" | "algorithm" {
    return this.get(FindingFields.CREATOR_ENTITY);
  }

  set creatorEntity(value: "user" | "algorithm") {
    this.set(FindingFields.CREATOR_ENTITY, value);
  }

  get analysis(): Analysis {
    return this.get(FindingFields.ANALYSIS);
  }

  set analysis(value: Analysis) {
    this.set(FindingFields.ANALYSIS, value);
  }

  get pipelineStep(): PipelineStep {
    return this.get(FindingFields.PIPELINE_STEP);
  }

  set pipelineStep(value: PipelineStep) {
    this.set(FindingFields.PIPELINE_STEP, value);
  }

  get version(): number {
    return this.get(FindingFields.VERSION);
  }

  set version(value: number) {
    this.set(FindingFields.VERSION, value);
  }

  get type(): StepTask {
    return this.get(FindingFields.TYPE);
  }

  set type(value: StepTask) {
    this.set(FindingFields.TYPE, value);
  }

  get data(): any {
    return this.get(FindingFields.DATA);
  }

  set data(value: any) {
    this.set(FindingFields.DATA, value);
  }

  get origin(): Finding {
    return this.get(FindingFields.ORIGIN);
  }

  set origin(value: Finding) {
    this.set(FindingFields.ORIGIN, value);
  }

  get uuid(): string {
    return this.get(FindingFields.UUID);
  }
  set uuid(value: string) {
    this.set(FindingFields.UUID, value);
  }
}
