import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService, IOrganizationData, USER_ORGANIZATIONS, userOrganizationsDataProvider } from '@telespot/web-core';
import { ImgFallbackModule } from '@telespot/shared/util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ts-nav-bar-organization',
  templateUrl: './nav-bar-organization.component.html',
  styleUrls: ['./nav-bar-organization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [userOrganizationsDataProvider],
})
export class NavBarOrganizationComponent {
  constructor(
    @Inject(USER_ORGANIZATIONS) public organizations$: Observable<IOrganizationData[]>,
    private authService: AuthService,
    private router: Router,
    private _breakpoint: BreakpointObserver
  ) {}

  currentOrganization$ = this.organizations$.pipe(map((items) => items.find((item) => item.selected)));

  public readonly isMobileView: Observable<boolean> = this._breakpoint
    .observe([Breakpoints.XSmall, Breakpoints.HandsetPortrait])
    .pipe(map((r) => r.matches));

  selectOrganization(organizationItem: IOrganizationData): void {
    this.authService.setOrganization(organizationItem.organization);
    this.router.navigate(['/workspaces']);
  }
}

@NgModule({
  declarations: [NavBarOrganizationComponent],
  imports: [CommonModule, MatMenuModule, TranslateModule, ImgFallbackModule],
  exports: [NavBarOrganizationComponent],
})
export class NavBarOrganizationModule {}
