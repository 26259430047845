<div class="flex-row align-items-center">
  <mat-progress-bar
    [style.height.px]="mode === 'buffer' ? 4 : 8"
    [mode]="mode"
    [value]="usedPercentage"
    [color]="color"
    [bufferValue]="0">
  </mat-progress-bar>
  <span class="text-nowrap storage-indicator" [matTooltip]="usedStorage">
    <ng-container *ngIf="total !== -1; else unlimited">
      {{usedStorageOutOfTotal}}
    </ng-container>
    <ng-template #unlimited>
      {{usedStorage}} / ∞
    </ng-template>
  </span>
</div>
