import { IAssetROIWithModels, ModelWithROIs, RoiModel } from "../value-objects";
import { Entity, SoftDeletable } from "./entity";

export interface AnalysisTypeAttributes extends Entity, SoftDeletable {
  name: string;
  details: any;
  description?: string;
  tasks: Array<Task>;
  mobile: boolean;
}

interface Task {
  name?: string;
  displayName?: string;
  type?: string;
  options?: Array<{
    name: string;
    displayName?: string;
    color?: string;
    ai_models?: string[];
    [key: string]: any;
  }>;
  roiSelection?: boolean;
  multiple?: boolean;
  [key: string]: any;
}

export class AnalysisType {
  constructor(private attributes: AnalysisTypeAttributes) { }

  public get id(): string {
    return this.attributes.id;
  }

  public get name(): string {
    return this.attributes.name;
  }

  public get details(): any {
    return this.attributes.details || {};
  }

  public get displayName() {
    return this.details ? this.details.display_name : undefined;
  }

  public get description() {
    return this.attributes.description;
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }

  public get deletedAt(): Date {
    return this.attributes.deletedAt;
  }

  public get mobile(): boolean {
    return this.attributes.mobile;
  }

  get tasks(): Array<Task> {
    return this.attributes.tasks;
  }

  public getRoiModels(): ModelWithROIs[] {
    const nestedTaskModels = this.tasks
      .filter((task) => task.roiSelection)
      .map((task) =>
        (task.options || []).map((o) => ({
          className: o.name,
          displayName: o.displayName,
          ai_models: o.ai_models,
          task,
          color: o.color,
        }))
      );

    const taskModels: RoiModel[] = [].concat(...nestedTaskModels);

    return taskModels.map((model) => ({
      model,
      rois: new Array<IAssetROIWithModels>(),
    }));
  }
}
