import type Parse from "parse";

import { User } from "@telespot/domain";

import { ParseMapper } from "../../parse.mapper";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

export enum UserTopology {
  TABLE = "_User",
  EMAIL = "email",
  USERNAME = "username",
  PASSWORD = "password",
  VERIFIED = "emailVerified",
  VERIFICATION_TOKEN = "verification_token",
  PROFILE = "profile",
}

export class ParseUserMapper extends ParseMapper<User> {
  public toDomain(parseUser: Parse.User): User {
    return new User({
      id: parseUser.id,
      email: parseUser.getEmail(),
      username: parseUser.getUsername(),
      password: undefined, // parse does not return the password ever, obviously
      verified: Boolean(parseUser.get(UserTopology.VERIFIED)),
      createdAt: parseUser.get(ObjectTopology.CREATED_AT),
      verificationToken: parseUser.get(UserTopology.VERIFICATION_TOKEN),
      profile: parseUser.get(UserTopology.PROFILE),
      deletedAt: parseUser.get(SoftDeletableTopology.DELETED_AT),
      updatedAt: parseUser.get(ObjectTopology.UPDATED_AT),
    })
  }

  public fromDomain(user: User): Parse.User {
    const parseObject = this.parse.User.fromJSON({
      className: UserTopology.TABLE,
      [ObjectTopology.ID]: user.id,
      [ObjectTopology.CREATED_AT]: user.createdAt,
      [ObjectTopology.UPDATED_AT]: user.updatedAt,
    });

    parseObject.set({
      [UserTopology.USERNAME]: user.username,
      [UserTopology.EMAIL]: user.email,
      [UserTopology.PASSWORD]: user.password,
      [UserTopology.VERIFIED]: user.verified,
      [UserTopology.VERIFICATION_TOKEN]: user.verificationToken,
      [UserTopology.PROFILE]: user.profile,
      [SoftDeletableTopology.DELETED_AT]: user.deletedAt,
    });

    return parseObject;
  }
}
