<form
  [formGroup]="form"
  *ngIf="tasks?.length > 0"
  [class.flex-row]="direction === 'horizontal'"
>
  <ng-container *ngFor="let task of tasks">
    <ng-container *ngIf="!task.roiSelection; else roiSelectionTemplate">
      <ng-container
        *ngIf="task.type !== 'segmentation'; else segmentationSelectionTemplate"
      >
        <ts-analysis-input
          style="min-width: fit-content"
          [mobile]="mobile"
          [form]="form"
          [analysisTask]="task"
        >
        </ts-analysis-input>
      </ng-container>
      <ng-template #segmentationSelectionTemplate
        ><ts-segmentation-selection
          [isViewMode]="isViewMode"
          [task]="task"
          [layout]="mobile ? 'mobile' : 'full'"
        ></ts-segmentation-selection>
      </ng-template>
    </ng-container>
    <ng-template #roiSelectionTemplate>
      <ts-roi-selection
        [isViewMode]="isViewMode"
        [task]="{ analysisTask: task, pipelineId: pipelineId }"
        [layout]="mobile ? 'mobile' : 'full'"
      ></ts-roi-selection>
    </ng-template>
  </ng-container>
</form>
