<div class="filter-container">
  <div class="preset-filters">
    <div class="number-buttons">
      <button
        *ngFor="let num of [1, 2, 3]"
        mat-raised-button
        (click)="onPresetClick(num)"
        [color]="selectedPreset === num ? 'primary' : ''"
        class="mat-raised-button"
      >
        {{ num }}
      </button>
    </div>
    <button
      mat-raised-button
      color="primary"
      (click)="resetFilters()"
      class="reset-button mat-raised-button"
    >
      Reset
    </button>
  </div>
  <div *ngFor="let filter of getFilterToggle()" class="filter-item">
    <span class="filter-label">{{ filter | titlecase }}</span>
    <div class="toggle-container">
      <mat-slide-toggle
        class="flex-grow-1"
        (change)="onToggleChange(filter, $event.checked)"
        [checked]="getFilterValue(filter) === 0 ? false : true"
      ></mat-slide-toggle>
    </div>
  </div>
  <div *ngFor="let filter of getFilterSlider()" class="filter-item">
    <span class="filter-label">{{ filter | titlecase }}</span>
    <mat-slider
      class="flex-grow-1 spotlab-slider"
      [min]="-100"
      [max]="100"
      [value]="getFilterValue(filter)"
      [step]="5"
      (input)="onSliderChange(filter, $event.value)"
      (change)="($event.stopPropagation)"
    ></mat-slider>
    <span class="filter-value">{{ getFilterValue(filter) }}%</span>
  </div>
</div>
