import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import {
  ViewerCtxEffects,
  ViewerCtxFeatureKey,
  viewerCtxReducer,
} from "./+state";

import { TELESPOT_ROI_PROVIDER } from "./services/ai-analysis/ai-roi-provider";
import {
  analysisReducer,
  AnalysisEffects,
  ProtocolEffects,
  analysisFeatureKey,
  protocolFeatureKey,
  protocolReducer,
  historyFeatureKey,
  historyReducer,
  HistoryEffects,
  CasesEffects,
  fromCases,
  sampleCounterFeatureKey,
  sampleCounterReducer,
  SampleCounterEffects,
  workspacesFeatureKey,
  workspacesReducer,
  WorkspacesEffects,
  syncReducer,
  syncFeatureKey,
  SyncEffects,
} from "./state";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ViewerCtxFeatureKey, viewerCtxReducer),
    StoreModule.forFeature(analysisFeatureKey, analysisReducer),
    StoreModule.forFeature(protocolFeatureKey, protocolReducer),
    StoreModule.forFeature(historyFeatureKey, historyReducer),
    StoreModule.forFeature(syncFeatureKey, syncReducer),
    StoreModule.forFeature(fromCases.CASES_FEATURE_KEY, fromCases.reducer),
    StoreModule.forFeature(sampleCounterFeatureKey, sampleCounterReducer),
    StoreModule.forFeature(workspacesFeatureKey, workspacesReducer),
    EffectsModule.forFeature([
      ViewerCtxEffects,
      AnalysisEffects,
      ProtocolEffects,
      HistoryEffects,
      CasesEffects,
      SyncEffects,
      SampleCounterEffects,
      WorkspacesEffects,
    ]),
  ],
  providers: [TELESPOT_ROI_PROVIDER],
})
export class TelespotAnalysisRefactorDataAccessModule {}
