import { File as ParseFile, Object as ParseObject } from 'parse';

import { ILicenseInfo } from '../license/i-license-info';

export class Organization extends ParseObject {
  static className = 'Organization';

  constructor({ name, license }: { name?: string; license?: Partial<ILicenseInfo> } = {}) {
    super(Organization.className);
    this.name = name;
    this.set('license', license);
  }

  get name(): string {
    return this.get('name') ?? '';
  }
  set name(name: string) {
    this.set('name', name);
  }

  get logo(): ParseFile {
    return this.get('logo');
  }
  set logo(logo: ParseFile) {
    this.set('logo', logo);
  }

  get logoUrl(): string {
    return this.logo?.url?.();
  }

  get usedStorage(): number {
    return this.get('usedStorage');
  }
  set usedStorage(value: number) {
    this.set('usedStorage', value);
  }

  get license(): ILicenseInfo {
    return this.get('license') || {};
  }

  get environment(): { [key: string]: unknown } {
    return this.get('environment') || {};
  }

  get language(): string {
    return this.license?.language || 'en';
  }

  public static createWithoutData(id: string): Organization {
    return ParseObject.fromJSON({ className: this.className, objectId: id }, true) as Organization;
  }
}
