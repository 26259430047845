import { CommonModule } from '@angular/common';
import { AfterContentChecked, ContentChildren, Directive, HostBinding, NgModule, NgZone } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-select,[mat-select]',
})
export class ReactiveSelectDirective implements AfterContentChecked {
  @ContentChildren('mat-option', {
    read: MatOption,
  })
  options: MatOption[];

  @HostBinding('class.mat-select-no-options') danger = false;

  constructor(private select: MatSelect, private _ngZone: NgZone) {}

  ngAfterContentChecked(): void {
    this._ngZone.runOutsideAngular(() => {
      this.danger = !this.select.options?.length;
    });
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [ReactiveSelectDirective],
  exports: [ReactiveSelectDirective],
})
export class ReactiveSelectDirectiveModule {}
