import {
  SampleAsset,
  FindAssetsQuery,
  SampleAssetRepository,
} from "@telespot/domain";

import { ParseBaseRepository } from "../../parse-base.repository";
import { AssetTopology } from "./parse-asset.mapper";

import {
  ParseSampleAssetMapper,
  SampleAssetTopology,
} from "./parse-sample-asset.mapper";
import { SampleQueryBuilder } from "./parse-sample-query.builder";
import { SampleTopology } from "./parse-sample.mapper";
import { ObjectTopology } from "../../parse.topology";

export class ParseSampleAssetRepository
  extends ParseBaseRepository
  implements SampleAssetRepository {
  private readonly saMapper = new ParseSampleAssetMapper(this.parse);

  public async getById(id: string): Promise<SampleAsset> {
    const sampleAsset = await new this.parse.Query(SampleAssetTopology.TABLE)
      .include(SampleAssetTopology.ASSET)
      .get(id, this.options);

    return sampleAsset ? this.saMapper.toDomain(sampleAsset) : undefined;
  }

  public async saveAll(...sampleAssets: SampleAsset[]): Promise<SampleAsset[]> {
    const parseObjects = sampleAssets.map((sa) => this.saMapper.fromDomain(sa));

    const results = await this.parse.Object.saveAll(parseObjects, this.options);

    return results.map((r) => this.saMapper.toDomain(r));
  }

  public async *forEachInSample(
    ...sampleIds: string[]
  ): AsyncGenerator<SampleAsset> {
    const query = new this.parse.Query(SampleAssetTopology.TABLE)
      .containedIn(SampleAssetTopology.SAMPLE, sampleIds)
      .include(SampleAssetTopology.ASSET);

    const batches = this.getGeneratorFromQuery(query);

    for await (const items of batches) {
      for await (const item of items) {
        yield this.saMapper.toDomain(item);
      }
    }
  }

  public async findForSample(sampleId: string): Promise<SampleAsset[]> {
    const parseSample = this.subclasses
      .getSubclass(SampleTopology.TABLE)
      .createWithoutData(sampleId);

    const parseSampleAssets = await new this.parse.Query(
      SampleAssetTopology.TABLE
    )
      .equalTo(SampleAssetTopology.SAMPLE, parseSample)
      .include(SampleAssetTopology.ASSET)
      .find(this.options);

    return parseSampleAssets.map((psa) => this.saMapper.toDomain(psa));
  }

  public async findForAsset(assetId: string): Promise<SampleAsset> {
    const parseAsset = this.subclasses
      .getSubclass(AssetTopology.TABLE)
      .createWithoutData(assetId);

    const parseSampleAsset = await new this.parse.Query(
      SampleAssetTopology.TABLE
    )
      // REVIEW: toPointer prevents Object in query errors during integration tests
      .equalTo(SampleAssetTopology.ASSET, parseAsset.toPointer())
      .include(SampleAssetTopology.SAMPLE)
      .ascending(ObjectTopology.CREATED_AT)
      .first(this.options);

    return this.saMapper.toDomain(parseSampleAsset);
  }
  public async deleteAll(sampleAssetIds: string[]): Promise<void> {
    const ParseSampleAsset = this.subclasses.getSubclass(
      SampleAssetTopology.TABLE
    );

    const parseSampleAssets = sampleAssetIds.map((i) =>
      ParseSampleAsset.createWithoutData(i)
    );

    await this.parse.Object.destroyAll(parseSampleAssets, this.options);
  }

  public async *find(query: FindAssetsQuery): AsyncGenerator<SampleAsset[]> {
    const { level, ids, scansOnly, createdBy } = query;

    const sampleQuery = new SampleQueryBuilder(this.parse)
      .withLevel(level)
      .withIds(ids)
      .build();

    const sampleAssetQuery = new this.parse.Query(SampleAssetTopology.TABLE)
      .matchesQuery(SampleAssetTopology.SAMPLE, sampleQuery)
      .exists(SampleAssetTopology.ASSET);

    const assetQuery = new this.parse.Query(AssetTopology.TABLE).startsWith(
      AssetTopology.ASSET_FILE,
      "scan-"
    );

    if (createdBy) assetQuery.equalTo(AssetTopology.CREATED_BY, createdBy);

    const scanSampleAssetQuery = new this.parse.Query(
      SampleAssetTopology.TABLE
    ).matchesQuery(SampleAssetTopology.ASSET, assetQuery);

    const mainQuery = scansOnly
      ? this.parse.Query.and(sampleAssetQuery, scanSampleAssetQuery)
      : sampleAssetQuery;

    mainQuery
      .include(SampleAssetTopology.ASSET)
      .addDescending(ObjectTopology.CREATED_AT);

    const generator = this.getTokenPaginationGenerator({ query: mainQuery, limit: 100 });

    for await (const results of generator)
      yield results.map((r) => this.saMapper.toDomain(r));
  }

  public async fetchByReference(
    assetId: string,
    sampleId: string
  ): Promise<SampleAsset> {
    const parseAsset = this.subclasses
      .getSubclass(AssetTopology.TABLE)
      .createWithoutData(assetId);

    const parseSample = this.subclasses
      .getSubclass(SampleTopology.TABLE)
      .createWithoutData(sampleId);

    const sampleAssetQuery = new this.parse.Query(SampleAssetTopology.TABLE)
      .equalTo(SampleAssetTopology.ASSET, parseAsset)
      .equalTo(SampleAssetTopology.SAMPLE, parseSample)
      .include(SampleAssetTopology.ASSET);

    const parseSA = await sampleAssetQuery.first(this.options);

    return parseSA ? this.saMapper.toDomain(parseSA) : undefined;
  }
}
