import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SnackbarAlertComponent } from "@shared/ui";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { AuthService } from "../../services/auth/auth.service";

export const AUTH_GUARD_REDIRECT_OPTIONS = new InjectionToken<string>(
  "AUTH_GUARD_REDIRECT_OPTIONS",
  {
    providedIn: "root",
    factory: () => "/login",
  }
);

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    @Optional()
    @Inject(AUTH_GUARD_REDIRECT_OPTIONS)
    private _fallbackURL: string,
    @Optional() private _logger: LoggerService,
    @Optional() private _snackbar: MatSnackBar
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this._authorizeActivation(next, state);
  }

  canDeactivate(
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.currentUser !== undefined;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this._authorizeActivation(childRoute, state);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this._checkRoleAccess(route);
  }

  private _authorizeActivation(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const user = this.authService.currentUser;
    if (user) {
      // Check role access
      let isAllowed = this._checkRoleAccess(next);
      if (isAllowed && next.data?.acquisition) {
        //Check if acquisition is activated in organization's license
        if (!this.authService.currentUser.isAdmin) {
          isAllowed = this._acquisitionAvailable();
        }
      }
      if (!isAllowed) {
        this._snackbar.openFromComponent(SnackbarAlertComponent, {
          data: {
            icon: "ri-close-fill",
            title: "alert.you_dont_have_permissions",
            message: "",
          },
          duration: 2000,
        });
      }
      return isAllowed ? true : this.router.createUrlTree(["/workspaces"]);
    }
    // Try to become user using token
    const token = next.queryParamMap.get("token");
    if (token) {
      return this.authService.become(token).pipe(
        tap((user) => {
          if (user) {
            this._logger.debug(`Became user ${user.username}`);
          } else {
            this._logger.warn(`Invalid token`);
          }
        }),
        map((user) => !!user)
      );
    } else {
      this.authService.redirectUrl = state.url;
      return this.router.createUrlTree([this._fallbackURL]);
    }
  }

  private _checkRoleAccess(route: Route | ActivatedRouteSnapshot): boolean {
    return route.data.allowedRoles?.length
      ? this.authService.currentUser?.is(route.data.allowedRoles || [])
      : true;
  }

  private _acquisitionAvailable(): boolean {
    return (
      (
        this.authService.currentOrganizationValue?.license?.features
          ?.acquisition_available as any
      )?.enabled ?? false
    );
  }
}
