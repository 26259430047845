import { IListAlgorithmsHandler, ListAlgorithms } from "@telespot/use-cases";
import { ParseBaseRepository } from "../../parse-base.repository";
import { AlgorithmTopology, ParseAlgorithmMapper } from "../../repositories";
import { ObjectTopology } from "../../parse.topology";

export class ParseListAlgorithmsHandler extends ParseBaseRepository implements IListAlgorithmsHandler {

  public async execute(command: ListAlgorithms) {
    const { limit, nextToken } = command.details;

    const parseQuery = new this.parse.Query(AlgorithmTopology.TABLE)
      .descending(ObjectTopology.CREATED_AT)
      .limit(limit);

    if (nextToken) parseQuery.lessThan(ObjectTopology.CREATED_AT, nextToken.createdAt)

    const parseObjects = await parseQuery.find(this.options);

    return parseObjects.map(ParseAlgorithmMapper.toItem);
  }
}
