import { CaseType, CaseTypeRepository } from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";
import { CaseTypeTopology, ParseCaseTypeMapper } from "./parse-case-type.mapper";
import { ObjectTopology } from "../../parse.topology";

export class ParseCaseTypeRepository extends ParseBaseRepository implements CaseTypeRepository {

  private readonly caseTypeMapper = new ParseCaseTypeMapper(this.parse);

  public async getById(id: string): Promise<CaseType> {
    const caseType = await new this.parse.Query(CaseTypeTopology.TABLE)
      .get(id, this.options);

    return this.caseTypeMapper.toDomain(caseType);
  }

  public async findWithIds(...ids: string[]): Promise<CaseType[]> {
    const parseCaseTypes = await new this.parse.Query(CaseTypeTopology.TABLE)
      .containedIn(ObjectTopology.ID, ids)
      .find(this.options);

    return parseCaseTypes.map(pct => this.caseTypeMapper.toDomain(pct));
  }

}
