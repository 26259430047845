import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TRulerOption } from "../../models/metric-scale";

@Injectable({
  providedIn: "root",
})
export class MetricScalePluginService {
  private _rulerSize$ = new BehaviorSubject<number>(null);
  private _rulerIcon$ = new BehaviorSubject<TRulerOption>(TRulerOption.NONE);
  public readonly rulerIcon$ = this._rulerIcon$.asObservable();
  public readonly rulerSize$ = this._rulerSize$.asObservable();

  public changeRulerSize(value: number) {
    this._rulerSize$.next(value);
  }
  public changeRulerIcon(value: TRulerOption) {
    this._rulerIcon$.next(value);
  }
}
