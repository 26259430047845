import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QrcodeComponent } from '@techiediaries/ngx-qrcode';
import { USER_ROLES } from '@telespot/web-core';
import { Invite, Role, Workspace } from '@telespot/sdk';
import { InvitesService } from '@telespot/users/data-access';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { InviteEditorComponent } from '../invite-editor/invite-editor.component';

@Component({
  selector: 'ts-invites',
  templateUrl: './invites.component.html',
  styleUrls: ['./invites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitesComponent implements OnDestroy {
  public invites$ = this._inviteService.invites$;
  @ViewChild('qrDialogTpl', { read: TemplateRef }) qrTemplate: TemplateRef<unknown>;
  @ViewChild('copiedAlertTpl', { read: TemplateRef }) copiedAlertTpl: TemplateRef<unknown>;
  public workspaces$: Observable<Workspace[]> = this._inviteService.workpaces$;
  public readonly showMobileLayout$: Observable<boolean> = this._breakpoint
    .observe([Breakpoints.XSmall, Breakpoints.HandsetPortrait])
    .pipe(map((r) => r.matches));

  constructor(
    private _inviteService: InvitesService,
    private _dialog: MatDialog,
    private _breakpoint: BreakpointObserver,
    @Inject(USER_ROLES) private roles: Observable<Role[]>,
    private snackBar: MatSnackBar
  ) {}

  private _destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public async removeInvite(invite: Invite): Promise<void> {
    this._inviteService.removeInvite(invite);
  }

  public async createInvite(): Promise<void> {
    this._dialog
      .open(InviteEditorComponent, {
        data: {
          form: new UntypedFormGroup({
            uses: new UntypedFormControl(1),
            code: new UntypedFormControl(undefined, [Validators.minLength(6), Validators.maxLength(10)]),
            initialWorkspaces: new UntypedFormControl([]),
            initialRoles: new UntypedFormControl([]),
          }),
          workspaces$: this.workspaces$,
          roles$: this.roles.pipe(
            map((roles) => roles.filter((role) => role.getName() !== 'admin' && role.getName() !== 'guest'))
          ),
        },
      })
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe((data) => {
        if (data) {
          this._inviteService.createInvite({ ...data });
        }
      });
  }

  public getInviteLink(code: string): string {
    return `${window.location.origin}/register?invite=${code}`;
  }

  public showQR(invite): void {
    this._dialog.open(this.qrTemplate, {
      data: {
        link: this.getInviteLink(invite.code),
        code: invite.code,
      },
      disableClose: false,
    });
  }

  public getQRDownloadURL(qr: QrcodeComponent): string {
    return qr.qrcElement?.nativeElement.querySelector('img')?.getAttribute('src');
  }

  public showCopiedAlert(): void {
    this.snackBar.openFromTemplate(this.copiedAlertTpl, {
      duration: 1000,
    });
  }
}
