import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SUPPORTED_LANGUAGES, SupportedLanguage } from "@shared/localization";
import { AuthService, DataService } from "@telespot/web-core";
import {
  Affiliate,
  CloudFunctions,
  License,
  Organization,
  ParseFile,
  Role,
} from "@telespot/sdk";
import { environment } from "@telespot/shared/environment";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import { Query } from "parse";
import { defer, Observable, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

import { FEATURES } from "../../models/organization-features";

@Component({
  selector: "ts-organization-editor",
  templateUrl: "./organization-editor.component.html",
  styleUrls: ["./organization-editor.component.scss"],
})
export class OrganizationEditorComponent implements OnInit, OnDestroy {
  organization: Organization;

  roles: Role[] = [];

  file;
  logoImgSource: string;

  private _destroy$ = new Subject<void>();

  public get features() {
    return [...FEATURES];
  }

  readonly licenses$: Observable<License[]> = defer(() =>
    this.dataService.find(new Query(License))
  ).pipe(
    tap((licenses) => {
      if (!this.organization.license) return;
      const license = licenses.find(
        (li) => li.name === this.organization.license.name
      );
      if (license) {
        this.formGroup.patchValue({ license: license.info });
      }
    })
  );

  constructor(
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private http: HttpClient,
    private authService: AuthService,
    private _logger: LoggerService,
    @Inject(SUPPORTED_LANGUAGES) public languages: SupportedLanguage[]
  ) {}

  formGroup: UntypedFormGroup;

  ngOnInit() {
    this.dataService
      .find(new Query(Role))
      .then((roles) => (this.roles = roles));
    this.route.paramMap
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (params) => {
        const id = params.get("organizationid");
        if (id) {
          this.organization = await this.dataService.get(
            id,
            new Query(Organization)
          );
          this.logoImgSource = this.organization.logo
            ? this.organization.logo.url()
            : "";
        } else {
          this.organization = new Organization();
        }
        this.formGroup = new UntypedFormGroup({
          name: new UntypedFormControl(this.organization.name),
          logo: new UntypedFormControl(""),
          license: new UntypedFormGroup({
            maxStorage: new UntypedFormControl({
              value: Number(this.organization.license?.maxStorage ?? -1),
              disabled: false,
            }),
            maxUsers: new UntypedFormControl({
              value: Number(this.organization.license?.maxUsers ?? -1),
              disabled: false,
            }),
            maxWorkspaces: new UntypedFormControl({
              value: Number(this.organization.license?.maxWorkspaces ?? -1),
              disabled: false,
            }),

            features: new UntypedFormGroup(
              FEATURES.reduce<{ [key: string]: AbstractControl }>(
                (controls, feature) => ({
                  ...controls,
                  [feature.name]: new UntypedFormGroup(
                    (feature.children ?? []).reduce<{
                      [key: string]: AbstractControl;
                    }>(
                      (controls, subFeature) => ({
                        ...controls,
                        [subFeature.name]: new UntypedFormControl(
                          this.organization.license?.features?.[feature.name]?.[
                            subFeature.name
                          ] ?? false
                        ),
                      }),
                      {
                        enabled: new UntypedFormControl(
                          this.organization.license?.features?.[feature.name]
                            ?.enabled ?? false
                        ),
                      }
                    )
                  ),
                }),
                {}
              )
            ),
            tiraspot: new UntypedFormControl(
              this.organization?.license?.tiraspot
            ),
            language: new UntypedFormControl(this.organization?.language),
          }),
        });
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  cancel() {
    this.organization.revert();
    this.location.back();
  }

  async submit() {
    this._logger.info("Saving organization", this.organization);
    const isNew = this.organization.isNew();
    this.organization.name = this.formGroup.value.name;
    // this.organization.logo = this.formGroup.value.logo;
    const savedOrganization = await this.saveOrganization();

    if (
      !this.organization.license ||
      this.formGroup.value.license?.name !== this.formGroup.value.license
    ) {
      // Assign / change license
      if (this.formGroup.value.license) {
        try {
          await CloudFunctions.SetLicense(
            savedOrganization,
            this.formGroup.value.license
          );
        } catch (err) {
          this._logger.error(`Error setting organization license`);
        }
      }
    }
    if (isNew) {
      try {
        const affiliate = new Affiliate(
          this.authService.currentUser,
          savedOrganization
        );
        (await this.dataService.save(affiliate)) as Affiliate;
        await this.authService.updateUserRolesAndOrganizations();
      } catch (err) {
        this._logger.error(
          "Error creating Affiliation for organization creator",
          err
        );
      }
      this.router.navigate(["/organizations", this.organization.id]);
    } else {
      this.location.back();
    }
  }

  async fileChange(event) {
    this.file = event.target.files?.[0];

    // FileReader support
    if (FileReader && this.file) {
      const fr = new FileReader();
      const onload = function () {
        this.logoImgSource = fr.result;
        fr.onload = null;
      };
      fr.addEventListener("load", onload.bind(this));
      fr.readAsDataURL(this.file);
    }
    // this.organization.logo = await this.uploadFile();
  }

  async uploadFile(): Promise<string> {
    let logoUrl: string;
    try {
      const uploadResponse = await this.http
        .post(
          `${environment.api.url}/files/upload/${this.file.name}?prefix=incoming`,
          this.file,
          {
            headers: {
              "Content-Type": "image/png",
            },
          }
        )
        .toPromise();
      logoUrl = uploadResponse["Location"];
    } catch (err) {
      this._logger.error("Error uploading organization logo", err);
      logoUrl = err.error.data.Location;
    }
    return Promise.resolve(logoUrl);
  }

  async saveOrganization(): Promise<Organization> {
    if (this.file) {
      this.organization.logo = new ParseFile(
        (this.file.name.toString() as string)
          .normalize("NFD")
          .replace(/[\u0300-\u036f()%]/g, ""),
        this.file,
        "image/jpeg"
      );
      await this.organization.logo.save();
    }
    return this.dataService.save(this.organization) as Promise<Organization>;
  }

  get selectedLicense() {
    return this.formGroup.controls["license"].value;
  }
  selectLicense(license: License) {
    this.formGroup.controls["license"].patchValue(
      {
        ...license.info,
      },
      {}
    );
  }

  public incrementLimit(limit: string, increment: number) {
    this.formGroup
      .get(["license", limit])
      .setValue(
        Math.max(-1, this.formGroup.get(["license", limit]).value + increment)
      );
  }
}
