<div class="image-container">
  <div class="placeholder" *ngIf="!imageUrl">
    <div class="diagonal-line"></div>
  </div>
  <img
    *ngIf="imageUrl"
    [src]="imageUrl"
    loading="lazy"
    (load)="onImageLoad()"
    draggable="false"
  />
  <ng-content></ng-content>
</div>
