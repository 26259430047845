<div class="main-container">
  <div class="title">
    <h1>{{ 'title.device_type_editor' | translate }}</h1>
  </div>

  <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="save()">
    <div class="card">
      <div class="card-body">
        <mat-form-field class="full-width">
          <input
            autofocus
            id="device-type-name"
            matInput
            [placeholder]="'label.device_type_name' | translate"
            formControlName="name"
          />
          <!-- <mat-hint>Name</mat-hint> -->
        </mat-form-field>
        <mat-form-field class="full-width">
          <input
            id="device-type-version"
            matInput
            [placeholder]="'label.hardware_version' | translate"
            formControlName="version"
          />
          <mat-error *ngIf="formGroup.touched && formGroup.controls['version'].errors?.validVersion">{{
            'label.invalid_version_error' | translate
          }}</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>{{ 'label.compatible_asset_types' | translate }}</mat-label>
          <mat-select id="device-type-assetTypes" multiple formControlName="assetTypes" [compareWith]="sameId">
            <mat-option *ngFor="let assetType of assetTypes$ | async" [value]="assetType">
              {{ assetType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="card-footer card-actions">
        <button
          [style.order]="2"
          class="btn btn-primary card-actions__action"
          [disabled]="!formGroup.valid"
          type="submit"
          data-cy="submit"
        >
          <i class="ri-save-line"></i>
          {{ 'button.submit' | translate | uppercase }}
        </button>
        <button
          [style.order]="1"
          class="btn btn-outline-primary btn-outline--white-bg card-actions__action"
          type="button"
          (click)="cancel()"
        >
          <i class="ri-close-fill"></i>
          {{ 'button.cancel' | translate | uppercase }}
        </button>
      </div>
    </div>
  </form>
</div>
