import { Case, CaseType } from "../entities";
import { Encryption, EncryptionValue } from "../ports";
import { CaseTypeRepository } from "../repositories";

// REVIEW: remove protocols dependency and provide case types as parameter
export class CaseEncryptionService {
  constructor(
    private readonly crypto: Encryption,
    private readonly caseTypes: CaseTypeRepository
  ) {}

  public async decrypt(cases: Case[], namesOnly: boolean) {
    const caseTypesIds = cases.map((c) => c.caseTypeId);
    const uniqueCaseTypeIds = Array.from(new Set(caseTypesIds));

    const cachedCaseTypes = await this.caseTypes.findWithIds(
      ...uniqueCaseTypeIds
    );

    const getFilterPaths = (paths) =>
      namesOnly ? paths.filter((f) => f === "name") : paths;

    const mapCaseIdToPaths = new Map(
      cachedCaseTypes.map((ct) => [
        ct.id,
        getFilterPaths(this.encryptablePropertyPaths(ct)),
      ])
    );

    const fieldValuesToDecrypt = [];

    cases.forEach((c) =>
      fieldValuesToDecrypt.push(
        ...this.getEncryptableFieldValuesForCase(
          c,
          mapCaseIdToPaths.get(c.caseTypeId)
        )
      )
    );

    const decryptedValues = await this.crypto.decrypt({
      values: fieldValuesToDecrypt,
    });

    const decryptedCasesArray = cases.map((item) =>
      this.modifyCaseWithEncryptionValues(
        item,
        decryptedValues.filter((dv) => dv.id === item.id)
      )
    );

    const decryptedCasesMap = new Map(
      decryptedCasesArray.map((i) => [i.id, i])
    );

    return cases.map((item) =>
      decryptedCasesMap.has(item.id) ? decryptedCasesMap.get(item.id) : item
    );
  }

  public async encryptCase(item: Case) {
    const caseType = await this.caseTypes.getById(item.caseTypeId);

    const encryptablePropertyPaths = this.encryptablePropertyPaths(caseType);

    if (!encryptablePropertyPaths.length) return item;

    const valuesToEncrypt = this.getEncryptableFieldValuesForCase(
      item,
      encryptablePropertyPaths
    );

    const encryptedValues = await this.crypto.encrypt({
      key: item.organizationId,
      values: valuesToEncrypt,
    });

    return this.modifyCaseWithEncryptionValues(item, encryptedValues);
  }

  private getNestedPropertyValue(obj: any, path: string): any {
    const parts = path.split(".");
    let currObj = obj;
    for (const part of parts) {
      if (currObj === null || currObj === undefined) {
        return undefined;
      }
      currObj = currObj[part];
    }
    return currObj;
  }

  private getEncryptableFieldValuesForCase(
    item: Case,
    propertyPaths: string[] = []
  ): EncryptionValue[] {
    return propertyPaths
      .map((path) => {
        const value = item
          ? this.getNestedPropertyValue(item, path)
          : undefined;
        return {
          id: item.id,
          propertyPath: path,
          value,
        };
      })
      .filter((p) => p.value !== undefined);
  }

  private encryptablePropertyPaths(caseType: CaseType): string[] {
    const encryptableFieldNames = caseType.fields
      .filter((f) => f.encrypted === true)
      .map((f) => f.name);

    const encryptablePropertyPaths = encryptableFieldNames.map(
      (f) => `data.${f}`
    );

    if (encryptableFieldNames.includes("case_identifier"))
      encryptablePropertyPaths.push(`name`);

    return encryptablePropertyPaths;
  }

  private modifyCaseWithEncryptionValues(
    item: Case,
    encryptionValues: EncryptionValue[]
  ) {
    if (!encryptionValues.length) return item;

    const nameEncrypted = encryptionValues
      .map((ev) => ev.propertyPath)
      .includes("name");

    let { name, data } = item;

    encryptionValues.forEach((encryptionValue) => {
      if (encryptionValue.propertyPath.startsWith("name")) {
        name = encryptionValue.value;
      } else {
        const path = encryptionValue.propertyPath.replace(/^data\./, "");
        data = {
          ...data,
          [path]: encryptionValue.value,
        };
      }
    });

    if (nameEncrypted) item.rename(String(name));

    item.updateData(data);

    return item;
  }
}
