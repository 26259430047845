import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, WorkspaceResolver } from '@telespot/web-core';

import { CasesListLoaderComponent } from './components/cases-list-loader/cases-list-loader.component';
import { WorkspaceDetailPageComponent } from './components/workspace-detail-page/workspace-detail-page.component';
import { WorkspaceEditorComponent } from './components/workspace-editor/workspace-editor.component';
import { WorkspaceMembersComponent } from './components/workspace-members/workspace-members.component';
import { WorkspacesComponent } from './components/workspaces/workspaces.component';

export const routes: Routes = [
  {
    path: 'new',
    component: WorkspaceEditorComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: ['admin', 'technicianmanager', 'analystmanager'], title: 'New workspace' },
  },
  {
    path: ':workspaceId',
    resolve: {
      // REVIEW: Disabled for now
      workspace: WorkspaceResolver,
    },
    component: WorkspaceDetailPageComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'edit',
        component: WorkspaceEditorComponent,
        canActivate: [AuthGuard],
        data: { allowedRoles: ['admin', 'technicianmanager', 'analystmanager'] },
      },
      { path: 'cases', component: CasesListLoaderComponent },
      {
        path: 'members',
        component: WorkspaceMembersComponent,
        canActivate: [AuthGuard],
        data: { allowedRoles: ['admin', 'analystmanager', 'technicianmanager'] },
      },
      {
        path: '',
        redirectTo: 'cases',
        pathMatch: 'full',
      },
    ],
    data: { title: `Workspace: {{workspace.name}}` },
  },
  {
    path: '',
    component: WorkspacesComponent,
    canActivate: [AuthGuard],
    data: { title: 'Workspaces' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WorkspacesRoutingModule {}
