import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Case, FieldType } from "@telespot/sdk";
@Component({
  selector: "ts-case-fields-form",
  templateUrl: "./case-fields-form.component.html",
  styleUrls: ["./case-fields-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseFieldsFormComponent {
  /** PRIVATE VARIABLES */
  private _case: Case;

  /** PUBLIC VARIABLES */
  public caseForm: UntypedFormGroup;
  public caseFields = [];

  /** INPUTS & OUTPUTS VARIABLES */
  @Input()
  set case(_case: Case) {
    if (!_case) return;
    this._case = _case;
    this.buildForm();
  }
  get case() {
    return this._case;
  }
  @Input() formErrorMatcher: ErrorStateMatcher;
  @Output() formValidityChange = new EventEmitter<boolean>();

  /* Component Methods */

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  private buildForm() {
    const formConfig = this.case.caseType.fields
      .filter(
        (field) => field.type !== "label" && field.type !== "sectionHeader"
      )
      .reduce((_formConfig, field) => {
        let default_value = null;
        switch (field.type) {
          case "date":
            default_value = new Date().toISOString();
            break;
          default:
            default_value = field.details.default;
            break;
        }
        _formConfig[field.name] = new UntypedFormControl(
          this.case.data[field.name] || default_value,
          this.addValidators(field)
        );
        return _formConfig;
      }, {});

    this.caseForm = new UntypedFormGroup(formConfig);
    this.caseFields = this.case.caseType.fields;

    this.caseForm.statusChanges.subscribe((status) => {
      this.formValidityChange.emit(this.caseForm.valid);
    });
  }

  private addValidators(fieldType: FieldType) {
    const validators = [];

    if (fieldType.required) {
      validators.push(Validators.required);
    }
    if (
      fieldType.details?.min !== undefined &&
      fieldType.details.min !== null
    ) {
      validators.push(Validators.min(fieldType.details.min as number));
    }

    if (fieldType.details?.max) {
      validators.push(Validators.max(fieldType.details.max as number));
    }
    return validators;
  }

  /* UI Handlers Methods */

  public getLocation(field: FieldType) {
    let value = { lat: 100, long: 100 };
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        value = {
          lat: position.coords.latitude,
          long: position.coords.longitude,
        };
        this.caseForm.patchValue({ [field.name]: value });
        const text = this.translateService.instant("info.location_loaded");
        this.snackBar.open(text, undefined, {
          duration: 2500,
        });
      });
    } else {
      this.caseForm.patchValue({ [field.name]: value });
    }
  }

  public isFieldInvalid(field: string): boolean {
    const control = this.caseForm.get(field);
    return control && control.invalid;
  }
}
