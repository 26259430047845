import { Directive, ElementRef, HostListener, Input, NgModule } from '@angular/core';

const imagePlaceholderPath = 'assets/media/image_placeholder.svg';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img[src]',
})
export class ImgFallbackSrcDirective {
  private _visibility = this._el.nativeElement.style.visibility;
  @Input() fallbackSrc: string = imagePlaceholderPath;
  @Input() fallbackClass = 'using-fallback-src';

  @HostListener('error', []) onError() {
    if (this.isUsingFallback || !this._el.nativeElement) return;
    if (this.fallbackSrc === null) {
      this._el.nativeElement.style.visibility = 'hidden';
    } else {
      this._el.nativeElement.src = this.fallbackSrc;
    }
  }

  @HostListener('load', []) onLoad() {
    if (!this.isUsingFallback) {
      this._el.nativeElement.classList.remove(this.fallbackClass);
    } else {
      this._el.nativeElement.style.visibility = this._visibility;
      this._el.nativeElement.classList.add(this.fallbackClass);
    }
  }

  private get isUsingFallback(): boolean {
    return this._el.nativeElement.src.endsWith(this.fallbackSrc);
  }

  constructor(private _el: ElementRef<HTMLImageElement>) {}
}

@NgModule({
  declarations: [ImgFallbackSrcDirective],
  exports: [ImgFallbackSrcDirective],
})
export class ImgFallbackModule {}
