import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ColorPaletteComponent } from './components/color-palette/color-palette.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ColorSliderComponent } from './components/color-slider/color-slider.component';

@NgModule({
  declarations: [ColorSliderComponent, ColorPaletteComponent, ColorPickerComponent],
  imports: [CommonModule, TranslateModule, FormsModule],
  exports: [ColorPickerComponent],
})
export class ColorPickerModule {}
