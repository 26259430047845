import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CURRENT_USER } from '@telespot/web-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InviteCodeGuard implements CanActivate {
  constructor(private router: Router, @Inject(CURRENT_USER) private user) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.user.pipe(
      map((user) => {
        const inviteCode = route.queryParamMap.get('invite');
        console.log(this.user);
        if (inviteCode && user) {
          return this.router.createUrlTree(['/me'], { queryParams: route.queryParams });
        }
        return true;
      })
    );
  }
}
