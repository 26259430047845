import { Injectable, OnDestroy } from "@angular/core";
import { MethodType, Query, Sample } from "@telespot/sdk";
import { Observable, Subject, from } from "rxjs";
import { ProtocolSpecification } from "../../state";

@Injectable({
  providedIn: "any",
})
export class ProtocolService implements OnDestroy {
  private _destroy$ = new Subject<void>();

  public static toProtocolSpecification(
    methodType: MethodType
  ): ProtocolSpecification[] {
    const analysisTypes = methodType.analysisTypes ?? [];

    const protocolSpecifications = analysisTypes.map(
      ({ id, name, tasks, mobile, details }) => ({
        id,
        name,
        mobile,
        tasks: tasks.map((task) => ({ ...task, collapsed: false })),
        displayName: details?.display_name,
      })
    );

    return protocolSpecifications;
  }

  public fetchLabelCounterFilters(caseTypeId: string) {
    const filters = localStorage.getItem(`counterFilters:${caseTypeId}`);

    return filters ? JSON.parse(filters).labelFilters : [];
  }

  public fetchSampleProtocol(
    sampleId: string
  ): Observable<ProtocolSpecification[]> {
    const sampleQuery = new Query(Sample).equalTo("objectId", sampleId);

    const protocolQuery = new Query(MethodType)
      .include("analysisTypes")
      .matchesKeyInQuery("objectId", "methodType.objectId", sampleQuery);

    const protocolPromise = protocolQuery
      .first()
      .then((mt) => ProtocolService.toProtocolSpecification(mt));

    return from(protocolPromise);
  }

  public setCounterFiltersInStorage(
    task: string,
    option: string,
    caseTypeId: string
  ) {
    const userCounterFilters = this.fetchLabelCounterFilters(caseTypeId);
    const newFilter = `category:${task}/value:${option}`;
    let updatedFilters = [];

    if (userCounterFilters.length > 0) {
      const filterIndex = userCounterFilters.indexOf(newFilter);
      updatedFilters =
        filterIndex !== -1
          ? [...userCounterFilters.filter((filter, i) => filterIndex !== i)]
          : [...userCounterFilters, newFilter];
    } else {
      updatedFilters = [newFilter];
    }
    localStorage.setItem(
      `counterFilters:${caseTypeId}`,
      JSON.stringify({ labelFilters: updatedFilters })
    );

    return updatedFilters;
  }

  public setInitialCounterFilters(counterFilters: string[], sampleId: string) {
    localStorage.setItem(
      `counterFilters:${sampleId}`,
      JSON.stringify({
        labelFilters: counterFilters.map((filter) =>
          decodeURIComponent(filter)
        ),
      })
    );
  }

  ngOnDestroy() {
    this._destroy$.next();
  }
}
