import { Entity } from "./entity";

export interface LabelParameters {
  id?: string;
  uuid: string;
  value: string;
  lang: "en" | "es" | "pt" | "fr";
}

export class Label implements Entity {
  constructor(private attributes: LabelParameters) {}

  public get id(): string {
    return this.attributes.id;
  }

  public get uuid(): string {
    return this.attributes.uuid;
  }

  public get value(): string {
    return this.attributes.value;
  }

  public get lang(): string {
    return this.attributes.lang;
  }
}
