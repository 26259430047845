import { Object as ParseObject } from "parse";

import { TFieldTypeName } from "../data/i-field-type";
import { FieldType } from "../field-type/field-type.model";
import { MethodType } from "../method-type/method-type.model";

export class CaseType extends ParseObject {
  static className = "CaseType";

  set name(value: string) {
    this.set("name", value);
  }
  get name(): string {
    return this.get("name") ?? "";
  }

  get methodTypes(): MethodType[] {
    return this.get("methodTypes") || [];
  }
  set methodTypes(methodTypes: MethodType[]) {
    this.set("methodTypes", methodTypes ?? []);
  }

  get fields(): FieldType[] {
    return (this.get("fields") || []).map((jsonField) =>
      FieldType.fromParseObjectJSON(jsonField)
    );
  }
  set fields(fields: FieldType[]) {
    this.set(
      "fields",
      (fields ?? []).map((field) => field.toParseObjectJSON())
    );
  }

  constructor({ name, fields }: { name?: string; fields?: FieldType[] } = {}) {
    super(CaseType.className);
    this.name = name;
    this.fields = fields ?? [];
    this.methodTypes = [];
  }

  addCaseMethod(method: MethodType) {
    if (method) {
      this.addUnique("methodTypes", method);
    }
  }
  removeCaseMethod(method: MethodType) {
    if (method) {
      this.remove("methodTypes", method);
    }
  }

  removeCaseField(field: FieldType) {
    if (!field) return;
    this.fields = this.fields.filter(
      (f) => f !== field && f.name !== field?.name
    );
  }

  set description(value: string) {
    this.set("description", value);
  }
  get description(): string {
    return this.get("description");
  }

  get hasEncryptedIdentifier(): boolean {
    return this.fields.some(
      (field) => field.type === TFieldTypeName.case_name && field.encrypted
    );
  }

  get isEncrypted(): boolean {
    return this.fields.some((field) => field.encrypted);
  }
}
