import { MethodType } from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";
import { ParseProtocolAlgorithmMapper, ProtocolAlgorithmTopology } from "./parse-protocol-algorithm.mapper";

export class ParseProtocolReadRepository extends ParseBaseRepository {

  private readonly algoMapper = new ParseProtocolAlgorithmMapper(this.parse);

  // REVIEW: check where this comes from and where its used
  public async getAIModelsAssigned(methodTypes: MethodType[]): Promise<Algorithm[]> {
    const protocolAlgorithmQuery = new this.parse.Query(ProtocolAlgorithmTopology.TABLE)
      .containedIn("methodType", methodTypes);

    const results = await protocolAlgorithmQuery.find(this.options);

    const protocolAlgorithms = results.map((pa) => this.algoMapper.toDomain(pa));
    const algorithms: Algorithm[] = protocolAlgorithms
      .map((pa) => pa.model)
      .reduce((acc, cur) => acc.concat(cur), []);

    return algorithms;
  }
}
