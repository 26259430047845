import { InjectionToken } from '@angular/core';
import { Affiliate, CaseType, Device, DeviceType, Member, Organization, Role, User, Workspace } from '@telespot/sdk';

export const SEED_DATA = new InjectionToken<any>('SEED_DATA');

export const ROLES = [
  new Role('admin', undefined),
  new Role('analyst', undefined),
  new Role('analystmanager', undefined),
  new Role('technician', undefined),
  new Role('technicianmanager', undefined),
];

const user_admin = new User();
const user_technician = new User();

export const USERS = [user_admin, user_technician];

const organization_spotlab = new Organization({ name: 'Spotlab' });
const organization_2 = new Organization({ name: 'Organization A' });

export const ORGANIZATIONS = [organization_spotlab, organization_2];

export const AFFILIATES = [
  ...ORGANIZATIONS.map((o) => new Affiliate(undefined, o)),
  ...USERS.map((user) => new Affiliate(user, organization_spotlab)),
];

const deviceType_Micra = new DeviceType({ name: 'MicraSpot', version: '1.0' });
const deviceType_Adapta = new DeviceType({ name: 'AdaptaSpot', version: '1.0' });

export const DEVICE_TYPES = [deviceType_Micra, deviceType_Adapta];

const device_micra_spotlab = new Device({
  name: 'Micra 1',
  serialNumber: '000001',
  deviceType: deviceType_Micra,
  organization: organization_spotlab,
});
const device_adapta_spotlab = new Device({
  name: 'Adapta 1',
  serialNumber: '000002',
  deviceType: deviceType_Adapta,
  organization: organization_spotlab,
});

export const DEVICES = [device_micra_spotlab, device_adapta_spotlab];

const caseType_1 = new CaseType({ name: 'Generic casetype', fields: [] });

const workspace_1 = new Workspace({ name: 'Workspace 1', caseType: caseType_1, organization: organization_spotlab });

export const WORKSPACES = [workspace_1];

export const MEMBERSHIPS = [
  ...WORKSPACES.map((w) => new Member({ user: undefined, workspace: w })),
  ...USERS.map((user) => new Member({ user, workspace: workspace_1 })),
];

// Assign random ids
USERS.forEach((item) => (item.id = Math.floor(Math.random() * 100000).toString()));
ROLES.forEach((item) => (item.id = Math.floor(Math.random() * 100000).toString()));
ORGANIZATIONS.forEach((item) => (item.id = Math.floor(Math.random() * 100000).toString()));
AFFILIATES.forEach((item) => (item.id = Math.floor(Math.random() * 100000).toString()));
DEVICE_TYPES.forEach((item) => (item.id = Math.floor(Math.random() * 100000).toString()));
DEVICES.forEach((item) => (item.id = Math.floor(Math.random() * 100000).toString()));
