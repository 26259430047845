import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SharedLocalizationModule } from '@shared/localization';
import { ConfirmationDialogComponentModule } from '@shared/ui';
import { LoggerModule } from '@telespot/shared/logger/feature/util';
import { ImgFallbackModule, NavigateBackDirectiveModule, TruncatePipeModule } from '@telespot/shared/util';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    LoggerModule,
    ImgFallbackModule,
    NavigateBackDirectiveModule,
    TruncatePipeModule,
    ConfirmationDialogComponentModule,
    SharedLocalizationModule,
    MatTooltipModule,
  ],
  providers: [],
  exports: [
    CommonModule,
    HttpClientModule,
    TruncatePipeModule,
    ImgFallbackModule,
    ConfirmationDialogComponentModule,
    RouterModule,
    LoggerModule,
    NavigateBackDirectiveModule,
    SharedLocalizationModule,
    MatTooltipModule,
  ],
})
export class SharedModule { }
