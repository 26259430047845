import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Resource } from '@telespot/sdk';

import { ResourceEditorComponent } from '../resource-editor/resource-editor.component';

@Component({
  selector: 'ts-resource-editor-dialog',
  templateUrl: './resource-editor-dialog.component.html',
  styleUrls: ['./resource-editor-dialog.component.scss'],
})
export class ResourceEditorDialogComponent {
  @ViewChild(ResourceEditorComponent) resourceEditor: ResourceEditorComponent;

  constructor(
    @Optional() public dialogRef: MatDialogRef<ResourceEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Resource
  ) { }

  closeDialog(data: any) {
    this.dialogRef?.close(data);
  }
}
