<p>
  <b>{{ 'dialogs.gdpr.responsible' | translate }} </b><a href="mailto:info@spotlab.org">info@spotlab.org</a>
</p>
<p>
  <b>{{ 'dialogs.gdpr.goal_title' | translate }}</b
  >: {{ 'dialogs.gdpr.goal_text' | translate }}
</p>
<p>
  <b>{{ 'dialogs.gdpr.rights_title' | translate }}</b
  >: {{ 'dialogs.gdpr.rights_text_1' | translate }} <a href="mailto:dpo@spotlab.org">dpo@spotlab.org</a
  >{{ 'dialogs.gdpr.rights_text_2' | translate }}
</p>
{{ 'dialogs.gdpr.more_info_title' | translate }}:
<a
  href="https://www.spotlab.org/politica-de-privacidad"
  target="__blank"
  [attr.alt]="'dialogs.gdpr.more_info_link' | translate"
  >{{ 'dialogs.gdpr.more_info_link' | translate }}</a
>
