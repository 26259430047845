import type Parse from "parse";

import { Role } from "@telespot/domain";

import { ParseMapper } from "../../parse.mapper";

export enum RoleTopology {
  USERS = "users",
}

export class ParseRoleMapper extends ParseMapper<Role> {

  /**
   * Maps an existing role (with ID) to a Parse.Role.
   * Trying to map an unsaved role will result in an error.
   *
   * @param role an existing role entity
   * @returns
   */
  public fromDomain(role: Role): Parse.Role {
    if (!role.id) throw new Error("Unsaved role!")

    const parseRole = this.parse.Role.createWithoutData(role.id);
    const newUsers = role.usersIds.map(id => this.parse.User.createWithoutData(id));

    parseRole.setName(role.name);
    parseRole.getUsers().add(newUsers);

    return parseRole;
  }

  public toDomain(parseRole: Parse.Role): Role {
    return new Role({
      id: parseRole.id,
      name: parseRole.getName(),
    });
  }
}
