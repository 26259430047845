import { createAction, props } from "@ngrx/store";
import { Workspace } from "@telespot/sdk";

export const clearCasesState = createAction("[Cases] Clear State");
export const decrypt = createAction(
  "[Cases] Decrypt case IDs",
  props<{ decrypted: boolean }>()
);
export const decryptCases = createAction("[Cases] Decrypt cases");
export const addDecryptedCases = createAction(
  "[Cases] Add decrypted cases",
  props<{ decryptedCases: any[] }>()
);
export const loadCases = createAction(
  "[Cases] Load cases",
  props<{ cases: any[]; currentUserId: string }>()
);
export const casesLoaded = createAction(
  "[Cases] Cases loaded",
  props<{ cases: any[]; decryptedCases: any[]; currentUserId: string }>()
);
export const updateDecryptedCaseData = createAction(
  "[Cases] Update current decrypted case Data",
  props<{ id: string; data: any }>()
);
export const updateCaseData = createAction(
  "[Cases] Update current case Data",
  props<{ id: string; data: any; currentUserId: string }>()
);

export const saveCase = createAction(
  "[Cases] Save case",
  props<{ id: string; data: any; currentUserId: string }>()
);

export const casesActionError = createAction(
  "[Cases] Processing failed",
  props<{ error: string }>()
);

export const setCurrWorkspace = createAction(
  "[Cases] Set current workspace",
  props<{ id: string }>()
);

export const currWorkspaceLoaded = createAction(
  "[Cases] Current workspace loaded in state",
  props<{ workspace: any }>()
);

export const updateCurrWorkspace = createAction(
  "[Cases] Update current workspace",
  props<{ id: string }>()
);

export const casesCounted = createAction(
  "[Cases] Cases Counted",
  props<{ count: number }>()
);

export const totalWorkspaceCasesCounted = createAction(
  "[Cases] Total WorkspaceCases Counted",
  props<{ count: number }>()
);
export const casesListed = createAction(
  "[Cases] Cases listed",
  props<{ caseList: any[] }>()
);

export const deleteCase = createAction(
  "[Cases] Delete case",
  props<{ id: string }>()
);

export const caseDeleted = createAction("[Cases] Case deleted");

export const moveCase = createAction(
  "[Cases] Move case",
  props<{ id: string; destination: string }>()
);

export const caseMoved = createAction("[Cases] Case moved");

export const copyCase = createAction(
  "[Cases] Copy case",
  props<{ id: string; destination: string }>()
);

export const caseCopied = createAction("[Cases] Case copied");

export const listCompatibleWorkspaces = createAction(
  "[Cases] List compatible workspaces"
);

export const compatibleWorkspacesListed = createAction(
  "[Cases] Compatible workspaces listed",
  props<{ compatibleWorkspaces: Workspace[] }>()
);

export const sortCases = createAction(
  "[Cases] Sort cases",
  props<{ cryteria: any }>()
);

export const applyCaseFilter = createAction(
  "[Cases] Apply case filter",
  props<{ filter: any }>()
);

export const removeCaseFilter = createAction(
  "[Cases] Remove case filter",
  props<{ filter: any }>()
);

export const changePage = createAction(
  "[Cases] Change page",
  props<{ pageIndex: number; pageSize: number }>()
);

export const refreshCaseList = createAction("[Cases] Refresh case list");
export const resetFiltersAndSort = createAction(
  "[Cases] Reset filters and sort"
);
