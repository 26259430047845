<div class="flex-column justify-content-center">
  <div class="flex-row justify-content-center">
    <div class="spacer"></div>
    <div style="font-size: 24px; margin: 0 auto;">
      {{ 'title.second_opinion_inform' | translate | uppercase }}
    </div>
    <div
      data-cy="second-opinion-reports-logout"
      class="close flex-row align-items-center justify-content-end"
      (click)="close()"
    >
      {{ 'button.logout' | translate }}
      <i class="ri-close-line"></i>
    </div>
  </div>
  <div data-cy="second-opinion-case-type" class="card flex-row align-items-center">
    <i class="ri-briefcase-4-line"></i>
    {{ caseName }}
  </div>
  <ng-container *ngIf="secondOpinions">
    <div *ngFor="let secOp of secondOpinions">
      <div
        [attr.data-cy]="'second-opinion-report-' + secOp.analysisType?.name"
        class="card"
        *ngIf="{ x: secOp.state$ | async } as secOpState"
      >
        <div class="card-section-content">
          <div class="flex-row align-items-center text-ellipsis align-content-start" style=" margin-right: 56px;">
            <span
              class="state-dot"
              [class.state-analyzed-sec-op]="secOpState?.x?.state === 'analyzed'"
              [class.state-pending]="secOpState?.x?.state === 'pending'"
              [class.state-in-progress]="secOpState?.x?.state === 'inProgress'"
            ></span>
            <a
              class="text-ellipsis"
              [class.disabled]="secOpState?.x?.state !== 'analyzed'"
              target="_blank"
              [href]="'analyze/cases/' + secOp.caseId + '/samples/' + secOp.origSampleId + '?historyId=' + secOpState?.x?.id"
              >{{ secOp?.user?.displayName }}</a
            >
          </div>
          <div class="flex-row align-items-center text-ellipsis align-content-end">
            <span class="text-ellipsis display-subject">{{ secOp.analysisType?.displayName | uppercase }}</span>
          </div>
        </div>

        <div class="display-subject display-name">{{ (secOp.analysisType?.tasks)[0]?.displayName }}</div>
        <ng-container *ngIf="secOpState?.x?.state === 'analyzed'; else noAnalysis">
          <div class="display-answer" *ngIf="{ y: secOp.analysis$ | async } as secOpAnl">
            {{ secOpAnl?.y }}
          </div>
        </ng-container>
        <ng-template #noAnalysis>
          <div class="display-answer no-answer">
            {{ 'label.waiting_answer' | translate }}
          </div>
        </ng-template>
      </div>
    </div></ng-container
  >
</div>
