import { TABLE_FILTERS } from '@shared/ui';
import { AnalysisState, Case, User } from '@telespot/sdk';

export interface AdaptaspotRowData {
  case: Case;
  createdBy: User;
  globalState: string;
  userState: string;
  userStates: AnalysisState[];
}

export const createdAtFilters = [
  {
    name: "createdAt",
    displayName: "< 3m",
    constraints: TABLE_FILTERS.GetRelativeDataRange({
      afterMonths: 3,
    }),
  },
  {
    name: "createdAt",
    displayName: "3-6 months",
    constraints: TABLE_FILTERS.GetRelativeDataRange({
      afterMonths: 6,
      beforeMonths: 3,
    }),
  },
  {
    name: "createdBy",
    displayName: "Created by me",
    constraints: {
      equalTo: User.current(),
    },
  },
];

export const columns = [
  {
    name: "userState",
    displayName: "state",
    value: (item) => item.userState,
  },
  {
    name: "name",
    displayName: "case_id",
    value: (item) => item.case.name,
    sortable: true,
    sortKey: "name",
    pinnable: true,
  },
  {
    name: "created_at",
    displayName: "label.created",
    value: (item) => item.createdBy,
    sortable: true,
    sortKey: "createdAt",
    filters: createdAtFilters,
  },
  {
    name: "analysing",
    displayName: "label.analysing",
    value: (item: any) => item.analysing,
  },
  {
    name: "analyzed",
    displayName: "label.analyzed",
    value: (item: any) => item.analyzed,
  },
];
