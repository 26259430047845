import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@telespot/web-core';
import { AssetType, CaseType, MethodType, Query } from '@telespot/sdk';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

interface IAssetTypeItem {
  assetType: AssetType;
  usedBy: {
    caseTypes$: Observable<number>;
    methodTypes$: Observable<number>;
  };
}

@Component({
  selector: 'ts-asset-types',
  templateUrl: './asset-types.component.html',
  styleUrls: ['./asset-types.component.scss'],
})
export class AssetTypesComponent implements OnInit {
  dataSource: MatTableDataSource<IAssetTypeItem> = new MatTableDataSource<IAssetTypeItem>([]);

  private _showMobileLayoutB$ = new BehaviorSubject<boolean>(false);
  get showMobileLayout$() {
    return this._showMobileLayoutB$.asObservable();
  }

  visibleColumns$: Observable<string[]> = this._breakpoint.observe(['(max-width: 420px)']).pipe(
    tap((matches) => this._showMobileLayoutB$.next(matches.matches)),
    map((matches) =>
      matches.matches
        ? ['type', 'assetType', 'usedByCaseTypes', 'usedByMethodTypes', 'compatibleDevices', '_actions']
        : ['type', 'assetType', 'usedByCaseTypes', 'usedByMethodTypes', 'compatibleDevices', '_actions']
    )
  );

  constructor(
    private dataService: DataService,
    private _breakpoint: BreakpointObserver,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this._loadAssetTypes();
  }

  private async _loadAssetTypes(): Promise<void> {
    const assetTypesQuery = new Query(AssetType).include(['deviceTypes']);
    this.dataService.findAll(assetTypesQuery).then((assetTypes) => {
      this.dataSource.data = assetTypes.map((assetType) => {
        const mtQuery = new Query(MethodType).equalTo('assetType', assetType.toPointer());
        return {
          assetType,
          usedBy: {
            caseTypes$: defer(() => this.dataService.count(new Query(CaseType).matchesQuery('methodTypes', mtQuery))),
            methodTypes$: defer(() => this.dataService.count(mtQuery)),
          },
        };
      });
    });
  }

  public async delete(item: IAssetTypeItem) {
    try {
      await this.dataService.softDelete(item.assetType);
      this.dataSource.data = this.dataSource.data.filter((i) => i.assetType?.id !== item.assetType?.id);
    } catch (error) {
      this.snackBar.open(error, null, { duration: 2000 });
    }
  }
}
