import {
  Component,
  Inject,
  Optional,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  IAssetItemWithPOCTData,
  TiraspotService,
} from "@telespot/analysis-refactor/data-access";
import { DataService } from "@telespot/web-core";
import { Asset, Query, Sample, SampleAsset } from "@telespot/sdk";
import { BehaviorSubject, from, Observable, of } from "rxjs";
import { distinctUntilChanged, map, switchMap } from "rxjs/operators";

import { DownloadAssetsDialogComponent } from "../dialogs/download-assets-dialog/download-assets-dialog.component";

@Component({
  selector: "ts-sample-analysis-preview",
  templateUrl: "./sample-analysis-preview.component.html",
  styleUrls: ["./sample-analysis-preview.component.scss"],
})
export class SampleAnalysisPreviewComponent {
  private _totalSamples: Sample[];
  public get totalSamples() {
    return this._totalSamples.length;
  }

  private _sampleIndex$ = new BehaviorSubject<number>(0);
  public readonly sampleIndex$ = this._sampleIndex$.asObservable();

  public readonly sample$: Observable<Sample> = this.sampleIndex$
    .pipe(map((index) => this._totalSamples[index]?.id))
    .pipe(
      distinctUntilChanged(),
      switchMap((sampleId: string) =>
        sampleId
          ? from(this._dataService.get(sampleId, new Query(Sample)))
          : of(undefined)
      )
    );
  public readonly assetPOCTdata$ = this.sample$.pipe(
    distinctUntilChanged(),
    switchMap((sample: Sample) => this.tiraspotService.getSampleData(sample))
  );

  public readonly assets$ = this.assetPOCTdata$.pipe(
    map((items) => items.map((item) => item.sampleAsset))
  );

  public aiEnabled$ = this.sample$.pipe(
    switchMap((sample) => this.tiraspotService.isAIenabled(sample.methodType))
  );

  @ViewChild("thumbnailDialog") _assetThumbnailDialog: TemplateRef<any>;
  public showThumbnail(asset: Asset) {
    this._dialog.open(this._assetThumbnailDialog, {
      disableClose: false,
      data: {
        asset,
      },
      width: "90%",
      height: "90%",
      maxWidth: "400px",
      maxHeight: "500px",
    });
  }

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    dialogData: { sample: Sample; samples: Sample[] },
    @Optional()
    private _dialogRef: MatDialogRef<SampleAnalysisPreviewComponent>,
    private _dialog: MatDialog,
    private _dataService: DataService,
    private tiraspotService: TiraspotService
  ) {
    this._totalSamples = dialogData.samples.map((s) => s["sample"]) ?? [];
    this._sampleIndex$.next(
      Math.max(
        0,
        this._totalSamples.findIndex((s) => s?.id === dialogData.sample?.id)
      )
    );
  }

  public nextSample(way: number) {
    const currentIndex = this._sampleIndex$.value;
    const nextIndex = currentIndex + (way > 0 ? 1 : -1);
    if (nextIndex >= 0 ?? nextIndex < this._totalSamples.length) {
      this._sampleIndex$.next(nextIndex);
    }
  }

  public downloadFile(sample: Sample, assets: SampleAsset[]) {
    this._dialog.open(DownloadAssetsDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      width: "800px",
      data: {
        assets: assets.map((a, index) => {
          return {
            id: a?.asset?.id,
            thumbnail: a?.asset?.thumbnail,
            selected: false,
            index,
            assetFile: a?.asset?.assetFileURL,
          };
        }),
        sampleName: sample.name,
      },
    });
  }

  public async chooseResult(
    item: IAssetItemWithPOCTData,
    result: "ai" | "user" | "unsure"
  ): Promise<void> {
    return this.tiraspotService.chooseResult(item, result);
  }
}
