import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CaseAnalysisService } from "@telespot/analysis-refactor/data-access";
import {
  IMethodSampleATUser,
  ReportContext,
  ReportGeneratorService,
} from "@telespot/reports/data-access";
import { ExportReportDialogComponent } from "@telespot/reports/ui";
import { Case, CloudFunctions, StateName } from "@telespot/sdk";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import { AuthService } from "@telespot/web-core";
import { Observable, Subject } from "rxjs";
import { take, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "ts-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
  providers: [CaseAnalysisService, ReportGeneratorService],
})
export class ReportComponent implements OnDestroy {
  private _case: Case;
  context: typeof ReportContext = ReportContext;

  public case$: Observable<Case> = this._reportGeneratorService.case$.pipe(
    tap((_case) => {
      this._case = _case;
    })
  );

  results$: Observable<IMethodSampleATUser[]> =
    this._reportGeneratorService.reportCase$;
  private _destroy$ = new Subject<void>();

  constructor(
    private _reportGeneratorService: ReportGeneratorService,
    private _caseAnalysisService: CaseAnalysisService,
    private _logger: LoggerService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private _authService: AuthService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._caseAnalysisService.setCase(this.route.snapshot.data["case"]);
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  // async sendReport() {
  //   try {
  //     const savedCase = await CloudFunctions.SetState(this._case, StateName.closed);
  //     this.caseReported.emit(savedCase);

  //     if (this.report) {
  //       await CloudFunctions.SetState(this.report, StateName.closed);
  //     }
  //   } catch (error) {
  //     this.errorHandler.handleError(error);
  //   } finally {
  //     this.goBack();
  //   }
  // }

  // goBack() {
  //   this._location.back();
  // }

  public async reopenCase() {
    try {
      await CloudFunctions.SetState(
        this._case,
        StateName.rewiew,
        this._authService.currentUser
      );
    } catch (error) {
      this._logger.error(`Error changing case state: ${error.message}`);
    }
    this.router.navigate(["/workspaces", this._case.workspace.id, "cases"], {
      queryParams: { page: 0, pageSize: 20 },
    });
  }

  public export(results: IMethodSampleATUser[]) {
    this.dialog
      .open(ExportReportDialogComponent, { width: "400px", hasBackdrop: true })
      .afterClosed()
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe(async (response) => {
        switch (response) {
          case "pdf":
            this.router.navigate(["print"], { relativeTo: this.route });
            break;
          case "csv":
            await this._exportAsCSV(results);
        }
      });
  }

  private async _exportAsCSV(results: IMethodSampleATUser[]) {
    const encodedUri = await this._asCSV(results);
    const link = this._document.createElement("a");
    link.setAttribute("href", encodedUri);
    const date = new Date();
    link.setAttribute(
      "download",
      `case_${this._case.name}_${date.getFullYear()}_${(
        "00" + date.getMonth()
      ).substr(-2)}_${("00" + date.getDay()).substr(-2)}.csv`
    );
    this._document.body.appendChild(link); // Required for FF

    link.click();
  }

  private async _asCSV(results: IMethodSampleATUser[]) {
    let csv = "data:text/csv;charset=utf-8,";

    // Write case data
    csv += `\nCASE DATA`;
    csv += `\nIdentifier,${this._case.name}`;
    this._case.caseType.fields.forEach((field) => {
      switch (field.type) {
        case "selection":
        case "switcher":
          csv += `\n,${field.options.join(",")}`;
          csv += `\n${field.displayName},${field.options.map((o) =>
            (
              this._case.data && field.details.multiple
                ? ((this._case.data[field.name] as unknown[]) || []).includes(o)
                : this._case.data[field.name] === o
            )
              ? "X"
              : ""
          )}`;
          break;
        case "sectionHeader":
        case "label":
        case "case_name":
          break;
        case "location":
          csv += `\n,lat,long`;
          csv += `\n${field.displayName},${
            this._case.data[field.name]?.["lat"]
          },${this._case.data[field.name]?.["long"]}`;
          break;
        default:
          csv += `\n${field.displayName},${this._case.data[field.name]}`;
          break;
      }
    });

    results.forEach((methodResult) => {
      csv += `\n,\n${methodResult.methodType.name}`;
      methodResult.samples.forEach((sampleData) => {
        csv += "\n";

        // Sample results
        csv += `\n,\nSAMPLE,${sampleData.sample.name}`;

        // AnalysisType results
        sampleData.results
          .filter((sampleResult) => sampleResult.hasRelevantTasks)
          .forEach((sampleResult) => {
            csv += `\n${sampleResult.analysisType.displayName}`;
            sampleResult.analysisType.tasks
              .filter((task) => !task.roiSelection && !task.assetSpecific)
              .forEach((task) => {
                // Task header
                csv += `\n${task.displayName},`;
                switch (task.type) {
                  case "boolean":
                  case "textedit":
                  case "number":
                    csv += `value`;

                    break;
                  case "switcher":
                  case "selection":
                    csv += task.options
                      .map((option) => option.displayName)
                      .join(",");
                }

                // Task value
                const userResultsForTask = sampleResult.userResults.filter(
                  (ur) => !!ur.data[task.name]
                );
                if (!userResultsForTask.length) {
                  csv += `\n[No user results]`;
                  return;
                }
                switch (task.type) {
                  case "boolean":
                  case "textedit":
                  case "number":
                    userResultsForTask.forEach((ur) => {
                      csv += `\n${ur.createdBy.username}, "${
                        ur.data[task.name]
                      }"`;
                    });
                    break;
                  case "switcher":
                  case "selection":
                    userResultsForTask.forEach((ur) => {
                      csv += `\n${ur.createdBy.username}, ${task.options
                        .map((option) => {
                          if (task.type === "switcher") {
                            return option.name === ur.data[task.name]
                              ? "x"
                              : "";
                          } else {
                            return ur.data[task.name]?.[option.name] ? "x" : "";
                          }
                        })
                        .join(",")}`;
                    });
                }
              });
          });

        sampleData.assetResults.forEach((assetData, i) => {
          csv += "\n,";

          // Sample results
          csv += `\nASSET,${assetData.asset.name ?? `${i + 1}`}`;

          // AnalysisType results
          assetData.results
            .filter((assetResult) => assetResult.hasRelevantTasks)
            .forEach((assetResult) => {
              csv += `\n${assetResult.analysisType.displayName}`;
              assetResult.analysisType.tasks
                .filter((task) => task.roiSelection || task.assetSpecific)
                .forEach((task) => {
                  // Task header
                  csv += `\n${task.displayName},`;
                  switch (task.type) {
                    case "boolean":
                    case "textedit":
                    case "number":
                      csv += `value`;
                      break;
                    case "switcher":
                    case "selection":
                      csv += task.options
                        .map((option) => option.displayName)
                        .join(",");
                  }
                  // Task value
                  const userResultsForTask = assetResult.userResults.filter(
                    (ur) => !!ur.data[task.name]
                  );
                  if (!userResultsForTask.length) {
                    csv += "\n[No user results]";
                    return;
                  }
                  switch (task.type) {
                    case "boolean":
                    case "textedit":
                    case "number":
                      userResultsForTask.forEach((ur) => {
                        csv += `\n${ur.createdBy.username}, "${
                          ur.data[task.name]
                        }"`;
                      });
                      break;
                    case "switcher":
                    case "selection":
                      userResultsForTask.forEach((ur) => {
                        csv += `\n${ur.createdBy.username}, ${task.options
                          .map((option) => {
                            if (task.roiSelection) {
                              return (
                                ur.data[task.name]?.[option.name]?.length ?? 0
                              );
                            } else {
                              if (task.type === "switcher") {
                                return ur.data[task.name] === option.name
                                  ? "x"
                                  : "";
                              } else {
                                return ur.data[task.name]?.[option.name]
                                  ? "x"
                                  : "";
                              }
                            }
                          })
                          .join(",")}`;
                      });
                  }
                });
            });
        });
      });
    });

    return encodeURI(csv);
  }
}
