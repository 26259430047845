import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

import * as SyncActions from "./sync.actions";

export enum SyncedItemType {
  FINDING = "finding",
}
export interface ISyncedItem {
  readonly id: string;
  readonly versionId?: string;
  version: number;
  type: SyncedItemType;
  assetId?: string;
  nextToken: string;
}

export const syncAdapter = createEntityAdapter<ISyncedItem>({
  selectId: (item) => item.id,
});

export const labelTrackingAdapter = createEntityAdapter<LabelTrack>({
  selectId: (item) => item.labelId,
});

export interface LabelTrack {
  labelId: string;
  nextToken: string;
}
export interface SyncState {
  error: string | null;
  syncedItems: EntityState<ISyncedItem>;
  labelTracking: EntityState<LabelTrack>;
  referenceAnalyst: Parse.Pointer;
  labelTrackingReady: boolean;
}

export const initialSyncState: SyncState = {
  error: null,
  syncedItems: syncAdapter.getInitialState(),
  labelTracking: labelTrackingAdapter.getInitialState(),
  referenceAnalyst: undefined,
  labelTrackingReady: false,
};

export function getSyncedItems(state: SyncState) {
  return Object.values(state.syncedItems.entities ?? {});
}

export function getLabelTrack(state: SyncState) {
  return Object.values(state.labelTracking.entities ?? {});
}

export const syncReducer = createReducer(
  initialSyncState,
  on(SyncActions.syncActionError, (state, { error }) => ({
    ...state,
    error,
  })),
  on(SyncActions.loadSyncedItems, (state, { syncedItems }) => {
    if (!syncedItems) return { ...state };
    return {
      ...state,
      syncedItems: syncAdapter.upsertMany(syncedItems, state.syncedItems),
    };
  }),
  on(SyncActions.clearSyncState, (state) => ({
    ...initialSyncState,
  })),
  on(SyncActions.clearSyncedItems, (state) => ({
    ...state,
    syncedItems: syncAdapter.getInitialState(),
  })),
  on(SyncActions.labelListLoaded, (state, { labelIds }) => ({
    ...state,
    labelTracking: labelTrackingAdapter.addMany(
      labelIds.map((l) => ({ labelId: l, nextToken: undefined })),
      state.labelTracking
    ),
    labelTrackingReady: true,
  })),
  on(SyncActions.updateCropPagination, (state, { nextToken, labelId }) => {
    return {
      ...state,
      labelTracking: nextToken
        ? labelTrackingAdapter.updateOne(
            { id: labelId, changes: { nextToken } },
            state.labelTracking
          )
        : labelTrackingAdapter.removeOne(labelId, state.labelTracking),
    };
  }),
  on(SyncActions.setReferenceAnalyst, (state, { analyst }) => ({
    ...state,
    referenceAnalyst: analyst,
  })),
  on(SyncActions.updateSyncedItemToken, (state, { id, nextToken }) => ({
    ...state,
    syncedItems: syncAdapter.updateOne(
      { id, changes: { nextToken } },
      state.syncedItems
    ),
  })),
  on(SyncActions.deleteSyncedItem, (state, { findingIds }) => {
    if (!findingIds) return { ...state };
    return {
      ...state,
      syncedItems: syncAdapter.removeMany(findingIds, state.syncedItems),
    };
  }),
  on(SyncActions.clearLabelTracking, (state) => ({
    ...state,
    labelTracking: labelTrackingAdapter.getInitialState(),
    labelTrackingReady: false,
  }))
);
