import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TelespotModule } from '@telespot/web-core';

import { AUTH_ANALYTICS } from './providers/auth-analytics';
import { SECOND_OPINION_ANALYTICS } from './providers/second-opinion-analytics';

@NgModule({
  imports: [CommonModule, TelespotModule],
  providers: [AUTH_ANALYTICS, SECOND_OPINION_ANALYTICS],
})
export class TelespotAnalyticsModule {}
