import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DataService } from "@telespot/web-core";
import { AnalysisTask } from "@telespot/sdk";
import { Subject } from "rxjs";
import { take, takeUntil, tap } from "rxjs/operators";

export enum TaskType {
  BOOLEAN = "boolean",
  SWITCHER = "switcher",
  SELECTION = "selection",
  TEXTEDIT = "textedit",
  SEGMENTATION = "segmentation",
}

export enum TaskNames {
  CHECKBOX = "Checkbox",
  OPTIONSELECTION = "Option selection",
  CLASSESSELECTION = "Classes selection",
  TEXTEINPUT = "Text input",
  SEGMENTATION = "Segmentation",
}
export interface AnalysisTypeTask {
  name: string;
  type: string;
}
@Component({
  selector: "ts-analysis-task-editor",
  templateUrl: "./analysis-task-editor.component.html",
  styleUrls: ["./analysis-task-editor.component.scss"],
})
export class AnalysisTaskEditorComponent implements OnDestroy {
  private _task: AnalysisTask;
  private _destroy$ = new Subject<void>();
  readonly optionsFormArray = new UntypedFormArray([]);
  private _dialogRef: MatDialogRef<any>;
  @ViewChild("editOptionDialog") _editOptionDialog: TemplateRef<any>;
  @Input()
  set task(task: AnalysisTask) {
    if (this._task !== undefined && this._task === task) return;
    this._task = task;
    this.setupForm();
  }
  get task() {
    return this._task;
  }

  @Output() submitted = new EventEmitter<AnalysisTask>();

  isNewField = true;
  public readonly analysisTypeTasks: AnalysisTypeTask[] = [
    { name: TaskNames.CHECKBOX, type: TaskType.BOOLEAN },
    { name: TaskNames.OPTIONSELECTION, type: TaskType.SWITCHER },
    { name: TaskNames.CLASSESSELECTION, type: TaskType.SELECTION },
    { name: TaskNames.TEXTEINPUT, type: TaskType.TEXTEDIT },
    { name: TaskNames.SEGMENTATION, type: TaskType.SEGMENTATION },
  ];

  form: UntypedFormGroup;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private dataService: DataService, private dialog: MatDialog) {}

  ngOnDestroy() {
    this._destroy$.next();
  }

  setupForm() {
    this.optionsFormArray.clear();
    this.form = new UntypedFormGroup({
      displayName: new UntypedFormControl(this.task.displayName || "", [
        Validators.required,
      ]),
      type: new UntypedFormControl(this.task.type || undefined, [
        Validators.required,
      ]),
      assetSpecific: new UntypedFormControl(
        this.task.roiSelection || this.task.assetSpecific || false
      ),
      multiple: new UntypedFormControl(this.task.multiple || false),
      allowAddingNewClasses: new UntypedFormControl(
        this.task.allowNewOptions || false
      ),
      roiSelection: new UntypedFormControl(this.task.roiSelection || false),
      roiSelectionType: new UntypedFormControl(this.task.roiSelectionType),
      options: this.optionsFormArray,
    });
    if (this.form.value.roiSelection) {
      this.form.patchValue({
        assetSpecific: true,
        multiple: true,
      });
      this.form.controls.multiple.disable();
    }
    if (this.task?.options?.length) {
      this.task.options.forEach((op) => {
        this.createOption(op);
      });
    }
  }

  createOption(value) {
    const options = this.form.get("options") as UntypedFormArray;
    options.push(
      new UntypedFormGroup({
        name: new UntypedFormControl(value?.name ? value.name : value, [
          Validators.pattern(/^[^.$]+$/),
        ]),
        displayName: new UntypedFormControl(
          value?.displayName ? value.displayName : value
        ),
        color: new UntypedFormControl(
          value?.color ? value.color.replace(/[^,]*(?=\))/, "1") : ""
        ),
        ai_models: new UntypedFormControl(
          value?.ai_models ? value.ai_models : []
        ),
      })
    );
  }

  addOption(event: MatChipInputEvent) {
    const value = event.value;
    const input = event.input;
    if ((value || "").trim()) {
      this.createOption(value);
    }
    if (input) input.value = "";
  }

  removeOptionAt(index: number) {
    const options = this.form.get("options") as UntypedFormArray;
    options.removeAt(index);
  }

  updateBasedOnType(event) {
    if (event === TaskType.SELECTION || event === TaskType.SEGMENTATION) {
      this.form.controls.assetSpecific.patchValue(true);
    }
  }

  updateBasedOnROIselection(event) {
    if (event.checked) {
      this.form.patchValue({ multiple: true });
      this.form.controls.multiple.disable();
      this.form.controls.roiSelectionType.enable();
    } else {
      this.form.controls.multiple.enable();
      this.form.controls.roiSelectionType.disable();
    }
  }

  set decimals(value: number) {
    this.task.decimals = value;
  }
  get decimals(): number {
    return this.task.decimals;
  }

  cancel() {
    console.warn("Reverting analysis task changes not implemented");
    this.submitted.emit(null);
  }

  submit() {
    // Reminder: disabled control values are not stored in form.value, use form.controls.key.value to access them
    this.task.type = this.form.value.type;
    this.task.displayName = this.form.value.displayName || "";
    if (!this.task.name || this.task.name === "") {
      this.task.name = this.task.displayName
        .trim()
        .toLowerCase()
        .replace(/\s+/g, "_");
    }
    if (
      this.task.type === TaskType.SELECTION ||
      this.task.type === TaskType.SWITCHER ||
      this.task.type === TaskType.SEGMENTATION
    ) {
      this.task.options = this.form.value.options.map((_opt) => {
        _opt.color = _opt.color.replace(/[^,]*(?=\))/, "0.2");
        return _opt;
      });
      this.task.multiple = this.form.controls.multiple.value;
      this.task.allowNewOptions = this.form.value.allowAddingNewClasses;
    }
    this.task.assetSpecific = this.form.controls.assetSpecific.value;
    this.task.roiSelection = this.form.value.roiSelection;
    if (this.task.roiSelection) {
      this.task.roiSelectionType = this.form.value.roiSelectionType;
    } else if (this.task.type !== TaskType.SEGMENTATION) {
      this.task.options = this.task.options?.map((_opt) => {
        const { color, ...rest } = _opt;
        return rest;
      });
    }
    this.submitted.emit(this.task);
  }

  editOptionAt(index) {
    this._dialogRef = this.dialog.open(this._editOptionDialog, {
      width: "400px",
      hasBackdrop: true,
      data: {
        option: this.form.value.options[index],
      },
    });
    this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe((updatedOption) => {
        if (updatedOption) {
          (this.form.get("options") as UntypedFormArray).controls[
            index
          ].patchValue(updatedOption);
        }
      });
  }
  closeDialog(data) {
    this._dialogRef.close(data);
  }
}
