import { Object as ParseObject } from "parse";

import { Algorithms, Organization, Pipeline } from "..";
import { AnalysisType } from "../analysis-type/analysis-type.model";
import { Asset } from "../asset/asset.model";
import { TAnalysisDataJSON } from "../data/analysis-data";
import { Sample } from "../sample/sample.model";
import { User } from "../user/user.model";
import { AssetStatus } from "../data/asset-status";
import { RoiModel } from "../../interfaces";
import uid from "uid";

/**
 * Represents analysis data
 * - Provided by a User
 * - Following a specific AnalysisType
 * - Related to a specific Sample OR Asset. This depends on the tasks defined by the AnalysisType
 *
 * @export
 * @class Analysis
 * @alias telespot:Analysis
 * @extends {ParseObject}
 */
export class Analysis extends ParseObject {
  static className = "Analysis";

  constructor({
    analysisType,
    sample,
    asset,
    data,
  }: {
    analysisType?: AnalysisType;
    sample?: Sample;
    asset?: Asset;
    data?: TAnalysisDataJSON;
  } = {}) {
    super(Analysis.className);
    this.analysisType = analysisType;
    this.sample = sample;
    this.asset = asset;
    this.data = data;
  }

  /**
   * {@link AnalysisType} associated to this Analysis. AnalysisTypes define the analysis tasks, i.e. checkbox fields, comments, labels for ROI selection, etc.
   *
   * @type {AnalysisType}
   * @memberof Analysis
   */
  get analysisType(): AnalysisType {
    return this.get("analysisType");
  }
  set analysisType(value: AnalysisType) {
    this.set("analysisType", value);
  }

  /**
   * The {@link User} that created this analysis
   *
   * @readonly
   * @type {User}
   * @memberof Analysis
   */
  get createdBy(): User {
    return this.get("createdBy");
  }

  /**
   * Analysis data, following the format specified in the {@link AnalysisType}
   *
   * @type {TAnalysisDataJSON}
   * @memberof Analysis
   */
  get data(): TAnalysisDataJSON {
    if (!this.get("data")) this.set("data", {});
    return this.get("data");
  }
  set data(data: TAnalysisDataJSON) {
    this.set("data", data);
  }

  /**
   * (Optional) The {@link Asset} this Analysis refers to, if any.
   *
   * @type {Asset}
   * @memberof Analysis
   */
  get asset(): Asset {
    return this.get("asset");
  }
  set asset(value: Asset) {
    this.set("asset", value);
  }

  /**
   * The {@link Sample} this Analysis refers to
   *
   * @memberof Analysis
   */
  set sample(value: Sample) {
    this.set("sample", value);
  }
  get sample(): Sample {
    return this.get("sample");
  }

  /**
   * The {@link Organization} this Analysis belongs to
   *
   * @memberof Analysis
   */
  set organization(value: Organization) {
    this.set("organization", value);
  }
  get organization(): Organization {
    return this.get("organization");
  }

  /**
   * The Status of the {@link Asset} specified. Used in: Review
   *
   * @memberof Analysis
   */
  set assetStatus(value: AssetStatus) {
    this.set("assetStatus", value);
  }
  get assetStatus(): AssetStatus {
    return this.get("assetStatus");
  }
  get algorithm(): Algorithms {
    return this.get("algorithm");
  }

  get pipeline(): Pipeline {
    return this.get("pipeline");
  }
  set pipeline(value: Pipeline) {
    this.set("pipeline", value);
  }

  get uuid(): string {
    return this.get("uuid");
  }
  set uuid(value: string) {
    this.set("uuid", value);
  }

  /**
   * Registers all rois from the analysis agains an {@link ModelWithROIs} array.
   * Input paramenter is optional. Uses analsys own analysis type if not provided.
   *
   * @param modelsWithROIs {@link ModelWithROIs} array where to store all rois
   * @returns populated models with rois
   */
  registerModels(
    analysisTypeModels: RoiModel[] = this.analysisType.getModels()
  ) {
    const models: RoiModel[] = [];

    for (const model of analysisTypeModels) {
      const customModels = Object.keys(this.data[model.task.name] ?? {})
        .filter(
          (k) => !analysisTypeModels.some((model) => model.className === k)
        )
        .map((className) => ({
          className,
          task: model.task,
          displayName: className,
          custom: true,
        }));

      analysisTypeModels.push(...customModels);

      models.push(model);
    }

    return models;
  }
}
