export enum ProtocolPipelineExceptions {
  INVALID_PIPELINE = "pipeline is required",
  INVALID_PROTOCOL = "methodType is required",
}

export interface ProtocolPipelineAttributes {
  id?: string;
  methodTypeId: string;
  pipelineId: string;
}

export class ProtocolPipeline {
  constructor(private attributes: ProtocolPipelineAttributes) {
    if (!attributes) {
      return;
    }

    if (!attributes.pipelineId) {
      throw new Error(ProtocolPipelineExceptions.INVALID_PIPELINE);
    }
    if (!attributes.methodTypeId) {
      throw new Error(ProtocolPipelineExceptions.INVALID_PROTOCOL);
    }
  }

  public get id(): string {
    return this.attributes.id;
  }

  public get methodTypeId(): string {
    return this.attributes.methodTypeId;
  }

  public get pipelineId(): string | undefined {
    return this.attributes.pipelineId;
  }
}
