import { animate, style, transition, trigger } from '@angular/animations';

export const VerticalExpandAnimation = trigger('collapse', [
  transition(':enter', [
    style({
      height: '0px',
      overflow: 'hidden',
    }),
    animate('0.2s'),
  ]),
  transition(':leave', [
    animate('0.2s'),
    style({
      height: '0px',
      overflow: 'hidden',
    }),
  ]),
]);
