import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { WsService } from "@shared/ws/feature";
import { TiraspotUtils } from "@telespot/analysis-refactor/data-access";
import {
  dataExportFilters,
  ExportTiraspotWorkspaceEvent,
  ExportTiraspotWorkspaceMessage,
  ProtocolAlgorithms,
  Workspace,
} from "@telespot/sdk";
import { environment } from "@telespot/shared/environment";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { Query } from "parse";
import { DataService } from "@telespot/web-core";

@Component({
  selector: "ts-workspace-detail-page",
  templateUrl: "./workspace-detail-page.component.html",
  styleUrls: ["./workspace-detail-page.component.scss"],
  providers: [WsService],
})
export class WorkspaceDetailPageComponent implements OnInit, OnDestroy {
  public readonly exporting$ = this._ws.connected$;
  private destroy$ = new Subject<void>();
  public pocts: string[] = [];
  public results: any[] = [];
  @ViewChild("exportingDialog", { read: TemplateRef }) exportingDialog;
  @ViewChild("exportModeDialog", { read: TemplateRef }) exportModeDialog;
  @ViewChild("filtersDialog", { read: TemplateRef }) filtersDialog;
  @ViewChild("exportButton", { static: false })
  public exportButtonRef: ElementRef;

  private data$ = new Subject<{
    empty?: boolean;
    download?: {
      link: string;
      fileName: string;
    };
    error?: string;
  }>();

  public hasAIprotocol: boolean;
  private responseReceived = false;

  formGroup: UntypedFormGroup;
  constructor(
    private _ws: WsService,
    private dialog: MatDialog,
    private _logger: LoggerService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.exporting$
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialog.open(this.exportingDialog, {
          hasBackdrop: true,
          disableClose: true,
          data: this.data$,
          minWidth: "200px",
          width: "50%",
          maxWidth: "90%",
          autoFocus: false,
        });
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  public exportWorkspace(
    workspace: Workspace,
    filtered: boolean,
    discrepancy: boolean
  ): void {
    const ws2 = this._ws.connect(environment.api.wsURL);
    ws2.pipe(takeUntil(this.destroy$)).subscribe(
      ({ status, emptyRows, link, error }: ExportTiraspotWorkspaceEvent) => {
        this.responseReceived = true;
        switch (status) {
          case "SUCCEEDED":
            this.data$.next({
              empty: emptyRows,
              download: { link, fileName: `${workspace.id}.csv` },
            });
            break;
          case "FAILED":
            this.data$.next({ error: "dialog.error_exporting_workspace" });
            this._logger.error(
              `There's been an error when exporting CSV: "`,
              error
            );
            break;
        }
      },
      (error) => {
        this.data$.next({ error: "dialog.error_exporting_workspace" });
        this._logger.error(`Failed to establish websocket connection`, error);
      },
      () => {
        if (!this.responseReceived) {
          this.data$.next({ error: "dialog.error_exporting_workspace" });
          this._logger.debug("Websocket was disconnected");
        }
        this.responseReceived = false;
      }
    );

    const filters: dataExportFilters = {
      tests: [],
      results: [],
      date: undefined,
    };
    if (filtered) {
      const testFilter = this.formGroup.value.testFilter;
      for (const [test, value] of Object.entries(testFilter)) {
        if (value === true) filters["tests"].push(test);
      }
      const resultFilter = this.formGroup.value.resultFilter;
      for (const [result, value] of Object.entries(resultFilter)) {
        if (value === true) filters["results"].push(result);
      }

      filters["date"] = this.formGroup.value.dateFilter.date;
    }
    const requestMessage: ExportTiraspotWorkspaceMessage = {
      action: "export-tiraspot-workspace",
      workspaceId: workspace.id,
      filters: filtered ? filters : null,
      discrepants: discrepancy,
      format: "csv",
    };
    ws2.next(requestMessage);
  }

  public async selectExportOption(workspace: Workspace) {
    const IAprotocol = await this.dataService.first(
      new Query(ProtocolAlgorithms).containedIn(
        "methodType",
        workspace.caseType.methodTypes
      )
    );

    this.hasAIprotocol = IAprotocol ? true : false;

    this.dialog.open(this.exportModeDialog, {
      hasBackdrop: true,
      disableClose: true,
      position: { top: "168px", right: "125px" },
      minWidth: "200px",
      width: "72%",
      height: "20%",
      maxWidth: "90%",
      autoFocus: false,
    });

    this.pocts = TiraspotUtils.getTestFilterOptionsFrom(workspace.caseType);
    this.results = TiraspotUtils.getAnswerFilterOptionsFrom(workspace.caseType);
    this.formGroup = new UntypedFormGroup({
      testFilter: new UntypedFormGroup(
        this.pocts.reduce(
          (controls: { [key: string]: AbstractControl }, poct) => ({
            ...controls,
            [poct]: new UntypedFormControl(false),
          }),
          {}
        )
      ),
      resultFilter: new UntypedFormGroup(
        this.results.reduce(
          (controls: { [key: string]: AbstractControl }, result) => ({
            ...controls,
            [result.displayName]: new UntypedFormControl(false),
          }),
          {}
        )
      ),
      dateFilter: new UntypedFormGroup({
        date: new UntypedFormControl("anytime", Validators.required),
      }),
    });
  }

  public defineFilters() {
    this.dialog.open(this.filtersDialog, {
      hasBackdrop: true,
      disableClose: true,
      position: { top: "168px", right: "120px" },
      minWidth: "200px",
      height: "80%",
      width: "55%",
      maxWidth: "90%",
      autoFocus: false,
    });
  }

  public cancelWorkspaceExport(): void {
    this._logger.info(`Workspace export cancelled by the user`);
    this.responseReceived = false;
    this._ws.closeConnection();
  }
}
