import { createAction, props } from "@ngrx/store";
import { Algorithms, Sample, User } from "@telespot/sdk";
import { LabelCount } from "./sample-counters.reducer";

export const loadStats = createAction(
  "[SAMPLE-COUNTERS] Load Sample statistics",
  props<{
    sample: Sample;
    createdBy: User | Algorithms;
    analysisStateId: string;
  }>()
);

export const loadStatsSuccess = createAction(
  "[SAMPLE-COUNTERS] Load Sample statistics succeeded",
  props<{
    labelCount: LabelCount[];
    totalCount: number;
    multiLabelCount: any;
    singleLabelCount: any;
    analysisStateId: string;
  }>()
);

export const labelTrackingStatsFetched = createAction(
  "[SAMPLE-COUNTERS] Load Sample stats filtered for labeltracking",
  props<{
    labelCount: LabelCount[];
    totalCount: number;
    multiLabelCount: any;
    singleLabelCount: any;
    analysisStateId: string;
  }>()
);

export const updateStats = createAction(
  "[SAMPLE-COUNTERS] Update Sample statistics",
  props<{
    optionsCount: any;
    roisToUpdate: number;
    singleAndMultiCount: any;
    analysisStateId: string;
  }>()
);

export const updateStatsOnReviewMode = createAction(
  "[SAMPLE-COUNTERS] Update Sample statistics on review mode",
  props<{ optionsCount: any; roisToUpdate: number; singleAndMultiCount: any }>()
);

export const updateReviewCount = createAction(
  "[SAMPLE-COUNTERS] Update review counters",
  props<{
    additionCount: any;
    deletionCount: any;
    roisToUpdate: number;
    analysisStateId: string;
    multiSingleCount: any;
  }>()
);

export const clearSampleCounterState = createAction(
  "[SAMPLE-COUNTERS] Clear sample counters"
);

export const clearReviewCount = createAction(
  "[SAMPLE-COUNTERS] Clear review counters"
);
export const replaceCounter = createAction(
  "[SAMPLE-COUNTERS] Replace original count with reviewCount"
);
export const sampleCounterActionError = createAction(
  "[SAMPLE-COUNTER] Processing failed",
  props<{ error: string }>()
);
