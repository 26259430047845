import { Component, Input, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  AnalysisHotkeysService,
  pinTaskOption,
  RoiService,
  selectedLabels,
  selectLabel,
} from "@telespot/analysis-refactor/data-access";
import { AnalysisTask, TAnalysisModelItem, TaskOption } from "@telespot/sdk";
import {
  MasksPluginService,
  TOsdActiveAction,
  TSegmentationAction,
  ViewerService,
} from "@telespot/shared/viewers/data-access";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "ts-segmentation-selection",
  templateUrl: "./segmentation-selection.component.html",
  styleUrls: ["./segmentation-selection.component.scss"],
})
export class SegmentationSelectionComponent implements OnDestroy {
  @Input() isViewMode: boolean;
  @Input() task: AnalysisTask;
  @Input() layout: "full" | "mobile" = "full";

  private _destroy$ = new Subject<void>();
  private _selectedLabels;

  public readonly selectedLabels$ = this.store.select(selectedLabels);

  constructor(
    private store: Store,
    private _hotkeysService: AnalysisHotkeysService,
    private _maskService: MasksPluginService,
    private _viewerService: ViewerService,
    private _roiService: RoiService
  ) {
    this.selectedLabels$
      .pipe(takeUntil(this._destroy$))
      .subscribe((selectedLabels) => (this._selectedLabels = selectedLabels));
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  public selectOption(option: TaskOption) {
    if (this.isViewMode) return;
    this.store.dispatch(selectLabel({ uuid: option.uuid }));
    if (this._viewerService.currentViewerMode !== TOsdActiveAction.layers) {
      this._viewerService.toggleViewerMode(TOsdActiveAction.layers);
      this._maskService.toggleSegmentationMode(TSegmentationAction.paint);
    }
  }
  public setPinnedOption(option: TaskOption) {
    this.store.dispatch(pinTaskOption({ uuid: option.uuid }));
  }
  public getModelShortcut(option: TAnalysisModelItem): number {
    return this._hotkeysService.getModelHotkey(option.name, this.task.name);
  }
  public getModelColor(labelId: string) {
    return this._roiService.getModelColor([labelId], true, true);
  }

  public isOptionSelected(option: TaskOption) {
    return this._selectedLabels[0]?.uuid === option.uuid;
  }
}
