<span style="height: 32px; background-color: #fff; padding: 0px">
  <div
    class="overlay-panel-toggle"
    [class.active]="iconFocus"
    (click)="clicked.emit()"
    [class.text-primary]="iconFocus"
    style="cursor: pointer"
  >
    <i *ngIf="!tiraspot" class="ri-filter-2-line"></i>
    <i *ngIf="tiraspot" class="ri-filter-3-line"></i>
  </div>
  <ng-container *ngIf="iconFocus">
    <div class="overlay-panel">
      <!-- <ng-container *ngIf="false && !tiraspot">
        <div class="flex-row align-items-center justify-left filter-group" *ngFor="let filter of filters || []">
          <span class="filter-title">{{ filter.displayName | translate }}</span>
          <mat-button-toggle-group
            [name]="filter.name"
            [multiple]="filter.multiple"
            class="custom-button-toggle-group"
            #toggleGroup
            (change)="updateDateRangeSelection($event)"
          >
            <mat-button-toggle
              *ngFor="let option of filter?.options || []"
              class="custom-button-toggle"
              [value]="option.name"
            >
              {{ option.displayName }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div></ng-container
      > -->
      <section *ngIf="!filters?.multiple; else multiple" class="flex-column justify-content-center align-center">
        <mat-radio-group class="flex-column justify-content-center align-center">
          <mat-radio-button
            class="margin"
            *ngFor="let option of filters?.options"
            [value]="option.name"
            [id]="option.name"
            (change)="updateFilter($event)"
            >{{ option.displayName | translate }}</mat-radio-button
          >
        </mat-radio-group>
      </section>
      <ng-template #multiple>
        <section class="flex-column justify-content-start align-items-start">
          <mat-checkbox
            *ngFor="let option of filters?.options || []"
            [id]="option.name"
            [value]="option.name"
            (change)="updateFilter($event)"
            [checked]="isChecked(option.name)"
            >{{ option.displayName | translate }}</mat-checkbox
          >
        </section>
      </ng-template>
    </div>
  </ng-container>
</span>
