import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmationDialogComponent,
  DataTableConfig,
  SplDataSource,
} from "@shared/ui";
import { DataService } from "@telespot/web-core";
import { CaseType, MethodType, Sample } from "@telespot/sdk";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DataFilterComponent, FindRequest, RowData } from "@shared/utils";
interface MethodTypesRowData extends RowData {
  resource: MethodType;
}
@Component({
  selector: "ts-method-types",
  templateUrl: "./method-types.component.html",
  styleUrls: ["./method-types.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MethodTypesComponent
  extends DataFilterComponent
  implements OnDestroy
{
  private _destroy$ = new Subject<void>();

  public previousRequest: FindRequest;
  public dataSource = new SplDataSource<MethodTypesRowData>(this);
  public tableConfig: DataTableConfig<MethodTypesRowData>;
  public previousCount = 0;
  public oldAvailable: MethodType[];
  public tableName = "methodtypes_list";

  public klass = MethodType.className;

  public readonly config = {
    columns: [
      {
        colName: "core.name",
        key: "methodTypeName",
        sortable: true,
        value: (item) => item,
        displayName: "Names",
        name: "name",
      },
      {
        colName: "core.casetypes",
        asyncKey: "usedByCaseTypes",
        value: (item) => item,
        displayName: "CaseTypes",
        name: "casetypes",
        asyncModel: CaseType.className,
        asyncResourceName: "methodTypes",
      },
      {
        colName: "core.samples",
        asyncKey: "usedBySamples",
        value: (item) => item,
        displayName: "Samples",
        name: "samples",
        asyncModel: Sample.className,
        asyncMethod: "equalTo",
        asyncResourceName: "methodType",
      },
      {
        name: "_actions",
        displayName: "Actions",
        value: (item) => item,
      },
    ],
    routeId: "_id",
  };

  public columns = this.config.columns;

  constructor(
    private _dataService: DataService,
    private _dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    super(_dataService);
  }

  public delete(deletedItem: MethodTypesRowData): void {
    this._dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: "title.confirm_delete_method_type",
          title_data: { methodType: deletedItem.resource.name },
          cancelButtonText: "core.cancel",
          acceptButtonText: "core.delete",
        },
      })
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (answer) => {
        if (answer === "accept") {
          try {
            const methodType = MethodType.fromJSON({
              className: "MethodType",
              objectId: deletedItem.id,
            });
            await this._dataService.softDelete(methodType);
            //this.dataSource.data = this.dataSource.data.filter((item) => item._id !== deletedItem._id);
          } catch (error) {
            this.snackBar.open(error, null, { duration: 2000 });
          }
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }
}
