import { Installation, Object as ParseObject } from 'parse';

import { DeviceType } from '../device-type/device-type.model';
import { Organization } from '../organization/organization.model';

export class Device extends ParseObject {
  static className = 'Device';

  /**
   * Creates an instance of Device.
   * @memberof Device
   */
  constructor({
    name,
    serialNumber,
    deviceType,
    organization,
  }: { name?: string; serialNumber?: string; deviceType?: DeviceType; organization?: Organization } = {}) {
    super(Device.className);
    this.name = name;
    this.serialNumber = serialNumber;
    this.deviceType = deviceType;
    this.organization = organization;
  }

  /**
   *
   *
   * @type {DeviceType}
   * @memberof Device
   */
  get deviceType(): DeviceType {
    return this.get('deviceType');
  }
  set deviceType(newDeviceType: DeviceType) {
    this.set('deviceType', newDeviceType);
  }

  /**
   *
   *
   * @type {string}
   * @memberof Device
   */
  get serialNumber(): string {
    return this.get('serialNumber');
  }
  set serialNumber(newSerialNumber: string) {
    this.set('serialNumber', newSerialNumber);
  }

  set name(value: string) {
    this.set('name', value);
  }
  get name(): string {
    return this.get('name') ?? '';
  }

  /**
   *
   *
   * @readonly
   * @type {Installation}
   * @memberof Device
   */
  get installation(): Installation {
    return this.get('installation');
  }

  /**
   *
   *
   * @type {string}
   * @memberof Device
   */
  get installedVersion(): string {
    return this.get('installedVersion');
  }
  set installedVersion(newVersion: string) {
    this.set('installedVersion', newVersion);
  }

  /**
   *
   *
   * @type {string}
   * @memberof Device
   */
  get state(): string {
    return this.get('state');
  }
  set state(state: string) {
    this.set('state', state);
  }

  set organization(value: Organization) {
    this.set('organization', value);
  }
  get organization(): Organization {
    return this.get('organization');
  }
}
