import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@telespot/web-core';
import { Query, Workspace } from '@telespot/sdk';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ts-workspaces-stats',
  templateUrl: './workspaces-stats.component.html',
  styleUrls: ['./workspaces-stats.component.scss'],
})
export class WorkspacesStatsComponent implements OnInit, OnDestroy {
  private _destroyS$ = new Subject<void>();

  constructor(private dataService: DataService, private route: ActivatedRoute) {}

  visibleColumns$: Observable<string[]> = of(['name', 'caseType', 'storage', 'stats']);

  dataSource: MatTableDataSource<Workspace> = new MatTableDataSource([]);

  ngOnInit() {
    if (!this.route.parent) return;
    this.route.parent.data.pipe(takeUntil(this._destroyS$)).subscribe(async (data) => {
      const workspaces = await this.dataService.find(
        new Query(Workspace).equalTo('organization', data.organization).include('caseType')
      );
      this.dataSource.data = workspaces;
    });
  }

  ngOnDestroy() {
    this._destroyS$.next();
  }
}
