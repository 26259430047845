import type Parse from "parse";

import { ParseMapper } from "../../parse.mapper";
import { Finding } from "@telespot/domain";
import { AnalysisTopology, ParseAnalysisMapper } from "./parse-analysis.mapper";
import { PipelineStepTopology } from "../protocols";
import { ObjectTopology, SoftDeletableTopology } from "../../parse.topology";

export enum FindingTopology {
  TABLE = "Findings",
  CREATOR_ENTITY = "creatorEntity",
  CREATOR_ID = "creatorId",
  ANALYSIS = "analysis",
  VERSION = "version",
  TYPE = "type",
  PIPELINE_STEP = "pipelineStep",
  DATA = "data",
  ORIGIN = "origin",
  UUID = "uuid",
}

export class ParseFindingMapper extends ParseMapper<Finding> {
  private readonly analysisMapper = new ParseAnalysisMapper(this.parse);

  public fromDomain(finding: Finding): Parse.Object {
    const parseObject = this.parse.Object.fromJSON({
      className: FindingTopology.TABLE,
      [ObjectTopology.ID]: finding.id,
      [ObjectTopology.CREATED_AT]: finding.createdAt,
      [ObjectTopology.UPDATED_AT]: finding.updatedAt,
    });

    parseObject.set({
      [SoftDeletableTopology.DELETED_AT]: finding.deletedAt,
      [FindingTopology.CREATOR_ENTITY]: finding.creatorEntity,
      [FindingTopology.CREATOR_ID]: finding.creatorId,
      [FindingTopology.ANALYSIS]: this.subclasses
        .getSubclass(AnalysisTopology.TABLE)
        .createWithoutData(finding.analysisId),
      [FindingTopology.VERSION]: finding.version,
      [FindingTopology.TYPE]: finding.type,
      [FindingTopology.PIPELINE_STEP]: this.subclasses
        .getSubclass(PipelineStepTopology.TABLE)
        .createWithoutData(finding.pipelineStepId),
      [FindingTopology.DATA]: finding.data,
      [FindingTopology.ORIGIN]: finding.originId
        ? this.subclasses
          .getSubclass(FindingTopology.TABLE)
          .createWithoutData(finding.originId)
        : undefined,
      [FindingTopology.UUID]: finding.uuid,
    });

    return parseObject;
  }

  public toDomain(object: Parse.Object): Finding {

    const analysis = object.get(FindingTopology.ANALYSIS);

    return new Finding({
      id: object.id,
      creatorEntity: object.get(FindingTopology.CREATOR_ENTITY),
      creatorId: object.get(FindingTopology.CREATOR_ID),
      data: object.get(FindingTopology.DATA),
      analysisId: analysis?.id,
      pipelineStepId: object.get(FindingTopology.PIPELINE_STEP)?.id,
      type: object.get(FindingTopology.TYPE),
      version: object.get(FindingTopology.VERSION),
      assetId: analysis?.get(AnalysisTopology.ASSET)?.id,
      pipelineId: analysis?.get(AnalysisTopology.PIPELINE)?.id,
      originId: object.get(FindingTopology.ORIGIN),
      uuid: object.get(FindingTopology.UUID),
      createdAt: object.get(ObjectTopology.CREATED_AT),
      updatedAt: object.get(ObjectTopology.UPDATED_AT),
    });
  }
}
