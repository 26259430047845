<ng-container *ngIf="{ filterGroups: filterGroups$ | async, mobile: dataTable.mobile$ | async } as state">
  <ng-container *ngIf="!state.mobile; else mobileDialogTriggerTpl">
    <button
      mat-icon-button
      [disabled]="!state.filterGroups.length"
      [matMenuTriggerFor]="filters_menu"
      [matMenuTriggerData]="{ $implicit: state.filterGroups }"
    >
      <i class="ri-filter-2-line" [class.text-primary]="state.filterGroups.length"> </i>
    </button>
  </ng-container>
  <ng-template #mobileDialogTriggerTpl>
    <button mat-icon-button [disabled]="!state.filterGroups.length" (click)="openFiltersDialog(state.filterGroups)">
      <i class="ri-filter-2-line" [class.text-primary]="state.filterGroups.length"> </i>
    </button>
  </ng-template>
</ng-container>

<mat-menu #filters_menu="matMenu">
  <ng-template let-filters matMenuContent>
    <div class="filters-panel">
      <ng-container *ngFor="let filtersGroup of filters; first as isFirst">
        <span class="vd" *ngIf="!isFirst"></span>
        <section *ngIf="filtersGroup.filters?.length" class="filters-panel-group">
          <span class="filters-panel-group__label">{{ filtersGroup.filterGroupName | translate | uppercase }}</span>
          <ng-container *ngFor="let filter of filtersGroup.filters">
            <ng-container *ngIf="filter.constraints.containedIn?.length; else simpleFilter"></ng-container>
            <mat-checkbox
              [checked]="filter.active && option.active"
              *ngFor="let option of filter.constraints.containedIn"
              (change)="toggleFilterOption(filter, option)"
            >
              {{ option.displayName | translate }}
            </mat-checkbox>

            <ng-template #simpleFilter>
              <mat-radio-button [checked]="filter.active" class="custom-button-toggle" (change)="toggleFilter(filter)">
                {{ filter.displayName | translate }}
              </mat-radio-button>
            </ng-template>
          </ng-container>
        </section>
      </ng-container>
    </div>
  </ng-template>
</mat-menu>

<ng-template #filtersDialog let-data>
  <mat-dialog-content>
    <section *ngFor="let filtersGroup of data.filterGroups" class="flex-column">
      {{ filtersGroup.filterGroupName | translate }}
      <ng-container *ngFor="let filter of filtersGroup.filters">
        <ng-container *ngIf="!filter.constraints?.containedIn; else multiFilterTpl">
          <mat-checkbox [checked]="filter.active" (change)="toggleFilter(filter)" matDialogClose>
            {{ filter.displayName }}
          </mat-checkbox>
        </ng-container>
        <ng-template #multiFilterTpl>
          <div class="flex-column">
            <mat-checkbox
              *ngFor="let option of filter.constraints.containedIn"
              [checked]="option.active"
              (change)="toggleFilterOption(filter, option)"
              matDialogClose
            >
              {{ option.displayName }}
            </mat-checkbox>
          </div>
        </ng-template>
      </ng-container>
    </section>
  </mat-dialog-content>
  <mat-dialog-actions class="flex-row flex-nowrap">
    <button class="btn btn-flat-primary" mat-dialog-close>{{ 'button.cancel' | translate }}</button>
    <button class="btn btn-flat-primary" mat-dialog-close disabled>{{ 'button.save' | translate }}</button>
  </mat-dialog-actions>
</ng-template>
