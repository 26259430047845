import { Device, MethodType } from '@telespot/sdk';

import { ISampleState } from './i-sample-state';

export interface IMethodTypeSamples {
  methodType: MethodType;
  samples: ISampleState[];
  device?: Device;
}

export function organizeSamples(samples: ISampleState[]): IMethodTypeSamples[] {
  const sampleGroups: IMethodTypeSamples[] = [];
  const uniqueMethods: MethodType[] = samples.reduce((methods: MethodType[], a: ISampleState) => {
    if (!methods.find((m) => m.id === a.sample.methodType.id)) methods.push(a.sample.methodType);
    return methods;
  }, []);
  (uniqueMethods || []).map((method) => {
    const methodSamples: ISampleState[] = (samples || []).filter((s) => s.sample.methodType.id === method.id);
    sampleGroups.push({
      methodType: method,
      samples: methodSamples,
      device: methodSamples?.[0]?.sample.assets?.[0]?.device,
    });
  });
  return sampleGroups;
}
