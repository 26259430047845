<ng-container *ngIf="form">
  <div class="card padded" style="flex-grow: 1">
    <div class="card-body">
      <h2>
        {{
          (isNew ? "button.new_workspace" : "title.edit_workspace")
            | translate
            | uppercase
        }}
      </h2>

      <form #workspaceForm="ngForm" [formGroup]="form" (submit)="submit()">
        <mat-form-field style="width: 100%">
          <input
            matInput
            autofocus
            placeholder="{{ 'input.workspace_name' | translate | uppercase }}"
            required
            type="text"
            formControlName="name"
            name="name"
            data-cy="workspace-name"
          />
        </mat-form-field>
        <div
          class="form-control-group"
          id="case-type-selection"
          *ngIf="
            (showMobileLayout$ | async) === false;
            else mobileLayoutCaseTypeSelector
          "
        >
          <label class="form-control-label" for="">{{
            "core.case_type" | translate | uppercase
          }}</label>
          <ts-data-table
            (selectItem)="onRowClicked($event)"
            [config]="tableConfig"
            [dataSource]="dataSource"
          >
            <ng-template dataTableCell="casetypes_list" let-item>
              <div class="case-type-selector">
                <button
                  type="button"
                  class="btn"
                  [class.btn-outline-primary]="
                    (form.controls['caseType'].value?.id ||
                      form.controls['caseType'].value?._id) !== item.id
                  "
                  [class.btn-primary]="
                    (form.controls['caseType'].value?.id ||
                      form.controls['caseType'].value?._id) === item.id
                  "
                  [value]=""
                  data-bs-toggle="tooltip"
                  [title]="item.resource.description || ''"
                >
                  {{ item.resource.name }}
                </button>
              </div>
            </ng-template>
          </ts-data-table>
        </div>

        <ng-template #mobileLayoutCaseTypeSelector>
          <!-- <mat-label>{{ 'core.case_type' | translate | uppercase }}</mat-label> -->
          <mat-form-field class="full-width">
            <mat-select
              name=""
              id=""
              formControlName="caseType"
              name="caseType"
              [compareWith]="byId"
              placeholder="{{ 'core.case_type' | translate | uppercase }}"
            >
              <mat-option
                value=""
                *ngFor="let caseType of caseTypes$ | async"
                [value]="caseType"
              >
                {{ caseType.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>

        <mat-form-field class="full-width">
          <mat-label
            >{{ "label.environment" | translate }} ({{
              "core.optional" | translate
            }})
          </mat-label>
          <mat-select formControlName="environment">
            <mat-option>None (default)</mat-option>
            <mat-option
              *ngFor="let item of environments$ | async"
              [value]="item.environment"
              [disabled]="!item.enabled"
            >
              {{ item.environment }}
              <span *ngIf="!item.enabled" class="text-gray ms-2">
                Not included by your organization's current license
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div
      class="card-footer no-hr card-actions"
      [class.mobile]="showMobileLayout$ | async"
    >
      <button
        id="cancel"
        type="button"
        class="btn btn-outline-primary card-actions__action"
        (click)="cancel()"
      >
        <i class="ri-close-fill"></i>
        {{ "button.cancel" | translate | uppercase }}
      </button>
      <button
        id="save"
        data-cy="save-workspace"
        class="btn btn-primary card-actions__action"
        type="button"
        (click)="submit()"
        [disabled]="!workspaceForm.touched || !workspaceForm.valid"
      >
        <i class="ri-save-line"></i>
        {{ "button.save" | translate | uppercase }}
      </button>
    </div>
  </div>
</ng-container>
