import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { DROP_LIST_SERVICE, GridIndexPath } from "@shared/ui";
import {
  DragDropService,
  POI,
  ProtocolSpecification,
  ROI,
  RoiService,
  addMosaicSelectedLabel,
  getProtocolOptions,
  groupPositionTasks,
  labelValues,
  loadMore,
  mosaicSelectedLabels,
  mosaicView,
  multipleFiltersActive,
  preSyncAnalysis,
  removeMosaicSelectedLabel,
  selectPOILabelCount,
  toogleMosaicView,
  unlabeledRoiCount,
  updateOptionFromCounter,
  visibleSections,
} from "@telespot/analysis-refactor/data-access";
import { TaskOption } from "@telespot/sdk";
import { CropInfo } from "@telespot/web-core";
import { Subject, combineLatest } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

interface LabelCount {
  [uuid: string]: { count: number; percentage: number };
}
@Component({
  selector: "ts-sample-analysis-panel-mosaics",
  templateUrl: "./sample-analysis-panel-mosaics.component.html",
  styleUrls: ["./sample-analysis-panel-mosaics.component.scss"],
})
export class SampleAnalysisPanelMosaicsComponent implements OnInit, OnDestroy {
  // Protocol and Mosaics variables
  public labelCounts: LabelCount = {};
  public unlabeledCount = { count: 0, percentage: 0 };

  @Input() protocol: ProtocolSpecification[] = [];
  public assetProtocol$ = this._store.select(groupPositionTasks);
  public totalCount$ = this._roiService.totalCount$;
  public unlabeledCount$ = this._store.select(unlabeledRoiCount);
  public multipleFiltersActive$ = this._store.select(multipleFiltersActive);
  public connectedDropLists$ = this.dragDropService.dropListIdsObservable$;
  public disableDragAndDrop = false;
  public selectedLabels: string[] = [];
  public currentLang: string;

  public readonly protocolOptions$ = this._store.select(getProtocolOptions);
  public readonly visibleSections$ = this._store.select(visibleSections);

  public UNLABELED_COLOR = "#F08F43";
  public unlabeledOption = {
    en: "All unlabeled",
    es: "No etiquetadas",
    fr: "Non etiqueté",
    pt: "Não rotulado",
  };

  public readonly mosaicView$ = this._store.select(mosaicView);
  private _view: string;

  // Component LifeCycle variables
  private destroy$ = new Subject<void>();
  private registeredDropListIds: string[] = [];

  // Drag and Drop variables
  private labelDict: { [label: string]: string } = {};
  public protocolLabels;
  public selectedCrops: CropInfo[];
  public selectedCropsSelector: (ROI | POI)[];

  constructor(
    @Inject(DROP_LIST_SERVICE) private dragDropService: DragDropService,
    private _store: Store,
    private _roiService: RoiService,
    private translateService: TranslateService
  ) {
    this.currentLang =
      localStorage.getItem("user_language") ||
      this.translateService.currentLang;

    this._store
      .select(labelValues)
      .pipe(takeUntil(this.destroy$))
      .subscribe((labels) => {
        const allLabels = [...labels];
        this.protocolLabels = allLabels;
        return allLabels;
      });
  }

  /* COMPONENT LYFECYCLE MANAGEMENT FUNCTIONS */

  ngOnInit() {
    // Whenever assetProtocol$ emits a value (which is after ngOnChanges has triggered), perform the following actions until this component is destroyed.
    this.assetProtocol$
      .pipe(takeUntil(this.destroy$)) // Stops the subscription when destroy$ emits to prevent memory leaks.
      .subscribe((assetProtocol) => {
        // Iterate through each group and their tasks in the filtered protocol.
        assetProtocol.forEach((group) => {
          group.tasks.forEach((task) => {
            task.options?.forEach((option) => {
              // Clear any previously registered drop list IDs to avoid duplicates or stale references.
              this.registeredDropListIds.forEach((id) =>
                this.dragDropService.removeDropListId(id)
              );
              this.registeredDropListIds = [];

              // Register new drop list IDs for the drag and drop functionality.
              // This is crucial for the dragDropService to keep track of valid drop zones.
              this.dragDropService.addDropListId(option.uuid);
              // Construct the label dictionary for Drag and Drop actions
              this.labelDict[option.name] = option.uuid;
            });
          });
        });
        this.dragDropService.removeDropListId(this.getUnlabeledText());
        this.dragDropService.addDropListId(this.getUnlabeledText());
      });

    this.unlabeledCount$
      .pipe(takeUntil(this.destroy$))
      .subscribe((count) => (this.unlabeledCount = count));

    this.multipleFiltersActive$
      .pipe(takeUntil(this.destroy$))
      .subscribe((active) => {
        this.disableDragAndDrop = active;
      });

    this._store.select(mosaicSelectedLabels).subscribe((labels) => {
      this.selectedLabels = labels;
    });

    this.mosaicView$
      .pipe(takeUntil(this.destroy$))
      .subscribe((view) => (this._view = view));

    this.dragDropService.selectedCrops$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedCrops) => (this.selectedCrops = selectedCrops));

    this.dragDropService.selectedCropsSelector$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (selectedCrops) => (this.selectedCropsSelector = selectedCrops)
      );
  }

  ngOnDestroy(): void {
    // It's cleanup time! Signal to the destroy$ observable's subscribers to complete their work as this component is about to be unmounted and destroyed.
    this.destroy$.next();
    this.destroy$.complete();

    // Iterate through registered drop list IDs and remove them from the dragDropService.
    // This prevents memory leaks by ensuring that we don't leave behind any orphaned event listeners or data.
    this.registeredDropListIds.forEach((id) =>
      this.dragDropService.removeDropListId(id)
    );
  }

  /* PROTOCOL FUNCTIONS */

  public getTotalCounts(taskOptions: TaskOption[]): {
    countTotal: number;
    percentageTotal: number;
  } {
    // The `reduce` function aggregates values across all `taskOptions` elements.
    return taskOptions.reduce(
      (acc, option) => {
        const count = this.labelCounts[option.uuid]?.count || 0; // Retrieves the count for the option, defaults to 0 if undefined.
        const percentage = this.labelCounts[option.uuid]?.percentage || 0; // Retrieves the percentage for the option, defaults to 0 if undefined.
        return {
          countTotal: acc.countTotal + count, // Increments the running count total.
          percentageTotal: acc.percentageTotal + percentage, // Increments the running percentage total.
        };
      },
      { countTotal: 0, percentageTotal: 0 } // Initializes the totals to 0 before accumulation starts.
    );
  }

  public updateLabelsForCounter(labelId: string) {
    this._store.dispatch(
      updateOptionFromCounter({
        labelId,
      })
    );
  }

  public selectLabel(label: any) {
    const index = this.selectedLabels.indexOf(label.name);
    if (index > -1) {
      this._store.dispatch(removeMosaicSelectedLabel({ labels: [label.name] }));
    } else if (this._view === "grid") {
      this._store.dispatch(addMosaicSelectedLabel({ label: label.name }));
      this._store.dispatch(toogleMosaicView({ mosaicView: "filter" }));
      this._store.dispatch(loadMore({ labelId: label.uuid }));
    } else {
      const categoryLabels = this.protocolLabels.filter(
        (l) => l.category === label.category
      );
      const labelsToRemove = categoryLabels
        .filter((l) => this.selectedLabels.includes(l.value))
        .map((label) => label.value);
      //Always remove unlabeled because there is no compatible combination.
      this._store.dispatch(
        removeMosaicSelectedLabel({
          labels: [
            ...labelsToRemove,
            this.unlabeledOption[this.currentLang ?? "en"],
          ],
        })
      );
      this._store.dispatch(addMosaicSelectedLabel({ label: label.name }));
      this._store.dispatch(loadMore({ labelId: label.uuid }));
    }
  }

  public selectUnlabeledOption() {
    const labelName = this.unlabeledOption[this.currentLang ?? "en"];
    const index = this.selectedLabels.indexOf(labelName);
    if (index > -1) {
      this._store.dispatch(removeMosaicSelectedLabel({ labels: [labelName] }));
    } else if (this._view === "grid") {
      this._store.dispatch(addMosaicSelectedLabel({ label: labelName }));
      this._store.dispatch(toogleMosaicView({ mosaicView: "filter" }));
    } else {
      const labelsToRemove = this.protocolLabels.map((label) => label.value);
      this._store.dispatch(
        removeMosaicSelectedLabel({
          labels: [...labelsToRemove],
        })
      );
      this._store.dispatch(addMosaicSelectedLabel({ label: labelName }));
    }
  }

  public isSelected(label: TaskOption) {
    return this.selectedLabels.includes(label.name);
  }

  public getUnlabeledText() {
    return this.unlabeledOption[this.currentLang ?? "en"];
  }

  /* DRAG AND DROP FUNCTIONS */

  public onDrop(
    event: CdkDragDrop<CropInfo[]>,
    visibleSections: string[],
    tasks: TaskOption[],
  ) {
    if (event.previousContainer.id === event.container.id) {
      this.cleanUp();
      return;
    }

    if ('section' in event.item.data) {
      this.dropFromGallery(event, visibleSections, tasks);
      return;
    }

    this.dropFromSelector(event);
  }

  private dropFromGallery(
    event: CdkDragDrop<unknown, unknown, GridIndexPath>,
    visibleSections: string[],
    tasks: TaskOption[],
  ) {
    const originTask = tasks.find(t => t.name === visibleSections[event.item.data.section]);

    const origin = originTask?.uuid;
    const dest = event.container.id;

    const isOriginUnlabeled = !origin;
    const isDestUnlabeled = dest === this.getUnlabeledText();

    const originOptions = this.getCategoryOptionsFromLabels([origin]);

    this.dragDropService.onDrop(
      [origin],
      [dest],
      originOptions,
      isOriginUnlabeled,
      isDestUnlabeled,
      this.selectedCrops,
    );

    this.cleanUp();
  }

  private dropFromSelector(event: CdkDragDrop<CropInfo[]>) {
    const origin = this.getLabelsFromContainerId(
      event.previousContainer.id
    ).map((l) => this.getLabelUuid(l));
    const dest = event.container.id;

    const isOriginUnlabeled = origin.some((l) => !l);
    const isDestUnlabeled = dest === this.getUnlabeledText();

    const originOptions = this.getCategoryOptionsFromLabels(origin);

    this.dragDropService.onDrop(
      origin,
      [dest],
      originOptions,
      isOriginUnlabeled,
      isDestUnlabeled,
      event.item.data
    );

    this.cleanUp();
  }

  private cleanUp() {
    this.cleanDraggingClass();
    this.dragDropService.cleanSelectedCrops();
    this.dragDropService.cleanSelectedCropsSelector();
  }

  public saveAnalysis() {
    this._store.dispatch(preSyncAnalysis());
  }

  public getLabelCount(labelId: string) {
    // Combina el Observable para el conteo de la etiqueta con el Observable del conteo total
    return combineLatest([
      this._store.select(selectPOILabelCount(labelId)),
      this.totalCount$,
    ]).pipe(
      map(([labelCount, totalCount]) => {
        if (labelCount === -1) {
          this.labelCounts[labelId] = {
            count: 0,
            percentage: 0,
          };
          return null;
        }
        // Calculamos el porcentaje usando los valores recibidos
        const percentage = totalCount > 0 ? (labelCount / totalCount) * 100 : 0;
        // Creamos el objeto con la estructura deseada
        this.labelCounts[labelId] = {
          count: labelCount,
          percentage: Math.round(percentage * 100) / 100, // Redondear al segundo decimal para una buena medida
        };
        return this.labelCounts[labelId];
      })
    );
  }

  public getLabelsFromContainerId(id: string) {
    const labels = id.includes("+")
      ? id.substring(0, id.length - 5).split("/")
      : [id];
    return labels;
  }

  public getLabelUuid(value: string): string {
    return (this.protocolLabels || []).find((l) => l.value === value)?.uuid;
  }

  getColor(uuid: string) {
    return this._roiService.getModelColor([uuid], true);
  }

  getCategoryOptionsFromLabels(origin: string[]): TaskOption[] {
    const originOptions = [];

    origin.forEach((uuid) => {
      const foundGroup = this.protocol.find((group) =>
        group.tasks.some((task) =>
          task.options.some((opt) => opt.uuid === uuid)
        )
      );
      if (!foundGroup) return;

      const foundTask = foundGroup.tasks.find((task) =>
        task.options.some((opt) => opt.uuid === uuid)
      );

      if (!foundTask) return;

      originOptions.push(...foundTask.options);
    });

    return originOptions;
  }

  cleanDraggingClass() {
    const elements = document.querySelectorAll('.dragging');
    elements.forEach((element) => element.classList.remove('dragging'));
  }
}
