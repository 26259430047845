import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ConfirmationDialogComponent,
  PRESETS,
  SampleFormComponent,
  TFileItem,
  TMethodGroup,
  TSampleGroup,
} from "@shared/ui";
import { DataService, FileUploaderService } from "@telespot/web-core";
import {
  Asset,
  Case,
  Device,
  MethodType,
  Query,
  Sample,
  SampleAsset,
  Workspace,
} from "@telespot/sdk";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import { CanComponentDeactivate } from "@telespot/shared/util";
import { BehaviorSubject, Subject } from "rxjs";
import { map, take, takeUntil, tap } from "rxjs/operators";
import { CaseFieldsFormComponent } from "@shared/ui";
import { v4 } from "uuid";
import { TranslateService } from "@ngx-translate/core";
import uid from "uid";
import { FormControl, UntypedFormGroup } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

export class AcquisitionFormStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return !!(control && control.invalid);
  }
}
@Component({
  selector: "ts-case-acquisition",
  templateUrl: "./case-acquisition.component.html",
  styleUrls: ["./case-acquisition.component.scss"],
})
export class CaseAcquisitionComponent
  implements OnInit, AfterViewInit, OnDestroy, CanComponentDeactivate
{
  /** PRIVATE VARIABLES */
  private _workspace: Workspace;
  private _case: Case;
  private _dialogRef: MatDialogRef<any>;
  private _destroy$ = new Subject<void>();
  private _progress$ = new BehaviorSubject<{
    message?: string;
    progress?: number;
  }>({});

  /** PUBLIC VARIABLES */
  public readonly progress$ = this._progress$.asObservable();
  public methodGroups: TMethodGroup[] = [];
  public devices: Device[] = [];
  @ViewChild(CaseFieldsFormComponent)
  caseFieldsForm: CaseFieldsFormComponent;
  public formValid = false;
  public formErrorMatcher = new AcquisitionFormStateMatcher();

  /** GETTERS & SETTERS */
  get case() {
    return this._case;
  }

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _languageService: TranslateService,
    private _dataService: DataService,
    private _logger: LoggerService,
    private _uploader: FileUploaderService
  ) {}

  /* Component Lifecycle Methods */

  ngOnInit() {
    this.setupWorkspace();
    if (!this._workspace) {
      this.navigateToWorkspaces();
      return;
    }
    this.initializeNewCase();
    this.createMethodGroups(this._case);
  }

  async ngAfterViewInit() {
    await this.loadDevices();
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  /* Component Guards Methods */

  canDeactivate() {
    if (!this._case.dirty()) {
      return true;
    }
    this._dialogRef = this._dialog.open(
      ConfirmationDialogComponent,
      PRESETS.DIALOG_EXIT_WITHOUT_SAVING_NO_SAVE
    );
    return this._dialogRef.afterClosed().pipe(
      take(1),
      tap((_) => (this._dialogRef = undefined)),
      map((res) => res === "discard")
    );
  }

  /* Component Methods */

  private setupWorkspace(): void {
    this._workspace = this._route.snapshot.data["workspace"] ?? undefined;
  }

  private navigateToWorkspaces(): void {
    this._logger.error(`A workspace needs to be specified`);
    this._router.navigate(["/workspaces"]);
  }

  private initializeNewCase(): void {
    this._logger.info(
      `Creating new case '${this._workspace.caseType?.name}' in '${this._workspace.name}'`
    );
    const newCase = new Case();
    newCase.caseType = this._workspace.caseType;
    newCase.workspace = this._workspace;
    newCase.organization = this._workspace.organization;
    this._case = newCase;
  }

  private createMethodGroups(_case: Case): void {
    this.methodGroups = _case.caseType.methodTypes.map((methodType) => {
      const defaultSamples = (methodType.config || {})["defaultSamples"] || [];
      return {
        methodType: methodType,
        samples: defaultSamples.map((ds) => {
          const defaultSample = {
            sample: new Sample({
              methodType,
              name: ds.name[this._languageService.currentLang || "en"],
              uuid: v4(),
            }),
            device: undefined,
            files: [],
          };
          return defaultSample;
        }),
      };
    });
  }

  private async loadDevices(): Promise<void> {
    const query = new Query(Device)
      .equalTo("organization", this._workspace.organization)
      .include(["deviceType"]);
    this.devices = await this._dataService.find(query);
  }

  private mapCaseFields(form: UntypedFormGroup): void {
    if (form.value["case_identifier"]) {
      this._case.name = form.value["case_identifier"];
      delete form.value["case_identifier"];
    }
    this._case.data = form.value;
    this._case.uuid = v4();
  }

  private hasAssets(): boolean {
    return this.methodGroups.some(
      (methodGroup) =>
        methodGroup.samples.length &&
        methodGroup.samples.some((sample) => sample.files.length)
    );
  }

  private confirmSave(): void {
    this._dialogRef = this._dialog.open(
      ConfirmationDialogComponent,
      PRESETS.DIALOG_SAVE_EMPTY_CASE
    );

    this._dialogRef
      .afterClosed()
      .pipe(
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe(async (answer) => {
        if (answer !== "accept") {
          return;
        }
        this._dialogRef = this._dialog.open(ConfirmationDialogComponent, {
          hasBackdrop: true,
          disableClose: true,
          data: {
            title: "alert.saving",
            text: this.progress$.pipe(map((p) => p.message)),
            progress: this.progress$.pipe(map((p) => p.progress)),
            showLoadingSpinner: true,
          },
          width: "400px",
        });
        try {
          await this._dataService.save(this._case);
          this._snackBar.open(`Saved`, undefined, { duration: 2000 });
          this._dialogRef?.close();
          this._router.navigate(["/workspaces", this._workspace.id, "cases"]);
        } catch (err) {
          this._snackBar.open(`Error: ${err.message}`, undefined, {
            duration: 2000,
          });
        }
      });
  }

  private async saveAll(): Promise<void> {
    this._dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        title: "alert.saving",
        text: this.progress$.pipe(map((p) => p.message)),
        progress: this.progress$.pipe(map((p) => p.progress)),
        showLoadingSpinner: true,
      },
      width: "400px",
    });

    try {
      await this._dataService.save(this._case);

      if (this._case.isNew()) {
        alert("The case needs to be saved first");
        return;
      }

      for (const methodGroup of this.methodGroups) {
        for (const sampleGroup of methodGroup.samples) {
          this._progress$.next({
            message: `Uploading sample ${sampleGroup.sample.name}...`,
            progress: 0,
          });

          // Update Sample data
          sampleGroup.sample.case = this._case;
          sampleGroup.sample.organization = this._case.organization;
          sampleGroup.sample.methodType = methodGroup.methodType;

          if (sampleGroup.files.length > 0 && !sampleGroup.sample.isNew()) {
            this._logger.warn(
              `Changing existing samples' assets not yet implemented`
            );
          } else {
            // Save Sample
            const savedSample = (await this._dataService.save(
              sampleGroup.sample
            )) as Sample;

            // FIX - Assign dicom tag file by file
            const isDicom = sampleGroup.files.every((fileItem) =>
              fileItem.file.name.endsWith(".dcm")
            );
            const tag: string = isDicom
              ? `dicom-${uid(10)}-${sampleGroup.files.length}`
              : undefined;

            // Save Assets
            const savedAssets = await this.saveAssets(
              methodGroup.methodType,
              savedSample,
              sampleGroup.files
            );

            // Save SampleAssets
            await this.saveSampleAssets(savedSample, savedAssets, tag);
          }
        }
      }

      this._snackBar.open(`Saved`, undefined, { duration: 2000 });
      this._dialogRef?.close();
      this._router.navigate(["/workspaces", this._workspace.id, "cases"]);
    } catch (err) {
      this._snackBar.open(`Error: ${err.message}`, undefined, {
        duration: 2000,
      });
      this._dialogRef?.close();
    }
  }

  private async saveAssets(
    methodType: MethodType,
    sample: Sample,
    files: TFileItem[]
  ): Promise<Asset[]> {
    const newAssets: Asset[] = [];

    for (const [index, fileItem] of files.entries()) {
      this._progress$.next({
        ...this._progress$.value,
        progress: Math.ceil((index / files.length) * 100),
      });
      try {
        // File Upload
        const assetFile = await this._uploader.upload(fileItem.file, "files");
        // Map asset for each file
        const newAsset = new Asset({
          organization: this._case.organization,
          device: sample.device,
          methodType: methodType,
          assetType: methodType.assetType,
          isMarked: fileItem.fav,
          assetFile,
        });

        const parseAsset = (await this._dataService.save(newAsset)) as Asset;
        newAssets.push(parseAsset);
      } catch (err) {
        this._logger.error(err);
        return Promise.reject(err.message);
      }
    }

    return newAssets;
  }

  private async saveSampleAssets(
    sample: Sample,
    assets: Asset[],
    tag: string
  ): Promise<void> {
    const sampleAssets = assets.map(
      (asset, index) =>
        new SampleAsset({
          asset: Asset.createWithoutData(asset.id),
          sample: sample,
          index,
          dateOfCapture: new Date(),
          tag,
        })
    );
    await this._dataService.save(sampleAssets);
  }

  /* UI Handlers Methods */

  public addSample(methodGroup: TMethodGroup) {
    this._logger.info(
      `Creating new sample 's${(methodGroup.samples.length + 1)
        .toString()
        .padStart(3, "0")}' in methodType '${methodGroup.methodType.name}'`
    );

    const newSample = new Sample();
    newSample.methodType = methodGroup.methodType;
    newSample.name = `s${(methodGroup.samples.length + 1)
      .toString()
      .padStart(3, "0")}`;

    const sampleData: TSampleGroup = {
      sample: newSample,
      device: null,
      files: [],
    };

    this.editSample(sampleData);
  }

  public editSample(sampleData: TSampleGroup) {
    this._dialogRef = this._dialog.open(SampleFormComponent, {
      data: {
        sample: sampleData.sample,
        files: sampleData.files,
        devices: this.devices,
      },
      panelClass: "no-padding",
      disableClose: true,
      hasBackdrop: true,
    });

    this._dialogRef
      .afterClosed()
      .pipe(
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe((result) => {
        if (!result) return;

        sampleData.sample = result.sample;
        sampleData.files = result.files;

        const group = this.methodGroups.find(
          (methodGroup) =>
            methodGroup.methodType?.id === sampleData.sample.methodType?.id
        );

        if (!group) return;

        if (!group.samples.includes(sampleData)) {
          group.samples.push(sampleData);
        }
      });
  }

  public deleteSample(methodGroup: TMethodGroup, sample: TSampleGroup) {
    const dialogText = PRESETS.DIALOG_REMOVE_UNSAVED_SAMPLE;
    dialogText.data.text = `${sample.sample.name} (${sample.files.length})`;

    this._dialogRef = this._dialog.open(
      ConfirmationDialogComponent,
      dialogText
    );

    this._dialogRef
      .afterClosed()
      .pipe(
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe((answer) => {
        if (answer !== "accept") return;
        methodGroup.samples = methodGroup.samples.filter((sg) => sg !== sample);
        this._snackBar.open(`Sample removed`, null, { duration: 1000 });
      });
  }

  public submit() {
    this.mapCaseFields(this.caseFieldsForm.caseForm);

    const hasAssets = this.hasAssets();

    hasAssets ? this.saveAll() : this.confirmSave();
  }

  public cancel() {
    this._case.revert();
    this._router.navigate(["/workspaces", this._workspace.id, "cases"]);
  }

  public onCaseFormValidityChange(isValid: boolean) {
    this.formValid = isValid;
  }
}
