<mat-card
  [class.ai-warning]="sampleItem.warning"
  [class.corrected]="sampleItem.corrected"
  data-cy="sample-card"
>
  <ng-container
    *ngIf="
      sampleItem.sample.organization.license.features
        .acquisition_available as acquisition_feature
    "
  >
    <ng-container *ngIf="acquisition_feature.enabled">
      <ng-container
        *ngIfRole="['analystmanager', 'technicianmanager', 'admin']"
      >
        <button
          class="menu-button"
          (click)="edit.emit(sampleItem); $event.stopPropagation()"
          (mouseenter)="hovering(true)"
          (mouseleave)="hovering(false)"
        >
          <i
            [ngClass]="{
              'ri-file-add-fill': isHovering,
              'ri-file-add-line': !isHovering
            }"
          ></i>
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
  <div
    class="thumbnail-container flex-row align-items-center"
    [class.thumb-shadow]="!sampleItem.sample.referenceAsset?.thumbnail"
  >
    <img
      [src]="sampleItem.sample.referenceAsset?.thumbnail | preprocess | async"
      alt="Thumbnail for sample '{{ sampleItem.sample?.name }}'"
      *ngIf="sampleItem.sample.referenceAsset; else noAsset"
    />
    <div class="ms-2 flex-column align-items-baseline">
      {{ sampleItem.sample?.name }}
      <div class="flex-row align-items-center">
        <span> {{ sampleItem.sample.numAssets }} </span>
        <i
          [ngClass]="{
            'ri-film-line':
              sampleItem.sample.methodType.assetType?.type === 'video',
            'ri-image-line':
              sampleItem.sample.methodType.assetType?.type === 'image',
            'ri-time-line':
              !sampleItem.sample.referenceAsset?.thumbnail &&
              isStitchingSample(sampleItem.sample)
          }"
          style="
            font-size: 24px;
            margin-left: 4px;
            color: #212d3e;
            font-weight: 400;
          "
        ></i>
      </div>
    </div>
  </div>
  <span
    class="thumbnail-container__updated flex-row align-items-center justify-content-center"
    [class.thumbnail-container__updated--highlight]="!!sampleItem.state"
  >
    <ng-container *ngIf="!!sampleItem.state">
      <i class="ri-save-2-line"></i>
      <small>{{
        "label.saved_$date"
          | translate : { date: sampleItem.state?.updatedAt | date : "short" }
          | uppercase
      }}</small>
    </ng-container>
  </span>
  <ng-template #noAsset>
    <ng-container
      *ngIf="!isStitchingSample(sampleItem.sample); else stitchingInProgress"
    >
      <img
        src="assets/media/image_placeholder.svg"
        alt="Missing sample '{{ sampleItem.sample?.name }}' thumbnail"
      />
    </ng-container>
    <ng-template #stitchingInProgress>
      <img
        mat-card-image
        src="assets/media/waiting.svg"
        alt="Processing thumbnail"
      />
    </ng-template>
  </ng-template>
  <ng-container *ngIf="sampleItem.state || { state: 'pending' } as sampleState">
    <mat-card-content>
      <div
        class="flex-row align-items-center justify-content-center status-label"
        *ngIf="!tiraspot"
      >
        <ng-container
          *ngIf="
            !sampleItem.sample.referenceAsset?.thumbnail &&
              !isStitchingSample(sampleItem.sample);
            else sampleStateTpl
          "
        >
          <i class="ri-spam-line text-danger"></i>
          <span class="text-danger">{{
            "info.sample_without_assets" | translate
          }}</span>
        </ng-container>
        <ng-template #sampleStateTpl [ngSwitch]="sampleState.state">
          <ng-container *ngSwitchCase="'pending'">
            <i class="ri-spam-line text-danger"></i>
            <span class="text-danger">{{
              "label.analysis_pending" | translate
            }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'inProgress'">
            <i class="ri-checkbox-circle-line text-primary"></i>
            <span class="text-primary">{{
              "label.sample_analyzed" | translate
            }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'analyzed'">
            <i class="ri-checkbox-circle-line"></i>
            <span>{{ "info_analysis_completed" | translate }}</span>
          </ng-container>
        </ng-template>
      </div>
      <ng-container *ngIf="!tiraspot && sampleItem.sample.numAssets">
        <button
          class="btn btn-block"
          [disabled]="
            !(sampleItem.sample.numAssets && sampleItem.sample.referenceAsset)
          "
          [class.btn-primary]="sampleItem.sample.numAssets"
          [class.btn-outline-gray]="!sampleItem.sample.numAssets"
          [matTooltip]="
            !sampleItem.sample.numAssets
              ? ('info.sample_without_assets' | translate)
              : ''
          "
          [ngClass]="{ 'btn-warning': sampleState === 'analyzed' }"
          [attr.data-cy]="'button-analyze-' + sampleItem.sample.name"
        >
          <ng-container *ngIfRole="['analyst']; else viewButtonTextTpl">
            <ng-container [ngSwitch]="sampleState.state">
              <ng-container *ngSwitchCase="'pending'">
                {{ "button.analyze" | translate | uppercase }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ "button.continue" | translate | uppercase }}
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #viewButtonTextTpl>
            {{ "button.view" | translate | uppercase }}
          </ng-template>
        </button>
      </ng-container>
      <div class="mt-1">
        <ng-content select="button"> </ng-content>
      </div>
    </mat-card-content>
  </ng-container>
</mat-card>
