import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, NgModule, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CURRENT_USER } from '@telespot/web-core';
import { User } from '@telespot/sdk';
import { Observable } from 'rxjs';
import { RoleBadgeModule } from '@shared/ui';

@Component({
  selector: 'ts-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailComponent {
  @Output() avatarChange = new EventEmitter<File>();

  constructor(@Inject(CURRENT_USER) public currentUser$: Observable<User>) {}

  updateLogo($event) {
    const file: File = Array.from($event.target.files)[0] as File;
    if (file) {
      this.avatarChange.emit(file);
    }
  }
}

@NgModule({
  declarations: [UserDetailComponent],
  imports: [CommonModule, TranslateModule, MatButtonModule, RouterModule, RoleBadgeModule],
  exports: [UserDetailComponent],
})
export class UserDetailModule {}
