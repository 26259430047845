<div class="login-form">
  <form [formGroup]="passwordResetForm" (submit)="submit()">
    <ng-container *ngIf="!passwordResetSent; else sentTpl">
      <mat-form-field class="full-width">
        <mat-label>
          {{ 'label.email' | translate }}
        </mat-label>
        <input matInput type="email" formControlName="email" data-cy="reset-email" />
      </mat-form-field>
      <mat-error *ngIf="passwordResetFormErrors" data-cy="invalid-email">
        {{ passwordResetFormErrors | translate }}
      </mat-error>
      <button
        class="btn btn-primary btn-block"
        type="submit"
        [disabled]="!passwordResetForm.valid"
        data-cy="button-submit-reset-password"
      >
        {{ 'button.reset_password' | translate | uppercase }}
      </button>
      <button class="btn btn-outline-primary btn-block mt-2" navigateBack>
        {{ 'core.cancel' | translate }}
      </button>
    </ng-container>
    <ng-template #sentTpl>
      <div data-cy="reset-link-sent">
        {{ 'help.password_reset_sent' | translate }}
      </div>
      <a class="btn btn-primary btn-block" [routerLink]="['/login']" data-cy="back-to-login">
        {{ 'Back to login' | translate }}
      </a>
    </ng-template>
  </form>
</div>
