import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from '@telespot/web-core';
import { MethodType, Query } from '@telespot/sdk';
import { BehaviorSubject } from 'rxjs';

import { MethodTypeSavedEvent } from '../method-type-editor/method-type-editor.component';

@Component({
  selector: 'ts-method-types-assignment',
  templateUrl: './method-types-assignment.component.html',
  styleUrls: ['./method-types-assignment.component.scss'],
})
export class MethodTypesAssignmentComponent implements OnInit {
  // UI
  showMethodEditor = false; // changed to public for prod purpose

  // I/O
  @Input()
  set methods(methods: MethodType[]) {
    this._methods = methods;
  }
  @Output() methodOrderChanged = new EventEmitter<MethodType[]>();
  @Output() methodEditorToggle = new EventEmitter<boolean>();

  _methods: MethodType[] = [];
  set selectedMethods(methods: MethodType[]) {
    this._methods = methods;
    this.methodOrderChanged.emit(this._methods);
  }
  get selectedMethods() {
    return this._methods;
  }

  // Data
  private _existingMethods = new BehaviorSubject<MethodType[]>([]);
  public readonly existingMethods$ = this._existingMethods.asObservable();

  method: MethodType;

  compareIds(val1, val2): boolean {
    return val1?.id === val2?.id;
  }

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.dataService
      .find(new Query(MethodType).include(['assetType', 'resources.resources']).limit(200))
      .then((methodTypes) => this._existingMethods.next(methodTypes));
  }

  create() {
    this.edit(new MethodType());
  }

  edit(method: MethodType) {
    this.method = method;
    this.showMethodEditor = true;
    this.methodEditorToggle.emit(true);
  }

  removeMethod(methodType: MethodType) {
    this._methods = this._methods.filter((method) => method !== methodType);
    this.methodOrderChanged.emit(this._methods);
  }

  onSavedMethod(event: MethodTypeSavedEvent | null) {
    this.showMethodEditor = false;
    this.methodEditorToggle.emit(false);
    // REVIEW: optimistic update, maybe it would be better to indicate it on the event payload
    if (!event) return;
    if (event.isNew) {
      this._existingMethods.next([...this._existingMethods.value, event.methodType]);
      this._methods.push(event.methodType);
    }
    this.methodOrderChanged.emit(this._methods);
  }

  drop(event: CdkDragDrop<MethodType[]>) {
    moveItemInArray(this._methods, event.previousIndex, event.currentIndex);
    this.methodOrderChanged.next(this._methods);
  }
}
