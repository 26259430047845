<div class="flex-row align-items-center">
  <span
    ><b>{{ "core.asset_fields" | translate }}</b></span
  >
  <button
    class="btn btn-flat-primary btn-auto"
    (click)="edit()"
    data-cy="button-new-asset-field"
  >
    <i class="ri-add-circle-line"></i>
    <span class="ms-10">{{ "button.new_asset_field" | translate }}</span>
  </button>

  <button
    mat-icon-button
    class="btn btn-flat-danger ms-auto"
    (click)="revert()"
    matTooltip="revert assetFields"
    data-cy="button-revert-asset-fields"
  >
    <i class="ri-arrow-go-back-line"></i>
  </button>
  <button
    mat-icon-button
    class="btn btn-flat-primary"
    (click)="save()"
    matTooltip="save assetFields"
    data-cy="button-save-asset-fields"
  >
    <i class="ri-save-2-line"></i>
  </button>
</div>

<ul class="list-group list-group-flush" [class.text-gray]="!!form">
  <li *ngFor="let assetField of assetFields" class="list-group-item">
    <span
      ><b>{{ "Display Name" | translate }}</b
      >: {{ assetField.displayName }}</span
    >
    <span
      ><b>{{ "Field path" | translate }}</b
      >: {{ assetField.fieldPath }}</span
    >
    <span class="flex-row align-items-center">
      <button
        mat-icon-button
        class="btn btn-flat-danger"
        data-cy="button-delete-field"
      >
        <i class="ri-close-line" (click)="deleteField(assetField)"></i>
      </button>
      <button
        mat-icon-button
        class="btn btn-flat-primary"
        data-cy="button-edit-field"
      >
        <i class="ri-pencil-line" (click)="edit(assetField)"></i>
      </button>
    </span>
  </li>
</ul>

<ng-container
  *ngTemplateOutlet="assetFieldEditor; context: { $implicit: form }"
></ng-container>

<ng-template #assetFieldEditorDialog let-data>
  <ng-container
    *ngTemplateOutlet="assetFieldEditor; context: { $implicit: data.form }"
  ></ng-container>
</ng-template>

<ng-template #assetFieldEditor let-assetFieldsConfigForm>
  <ng-container *ngIf="assetFieldsConfigForm">
    <div class="flex-row align-items-center">
      <h4>
        {{ "label.asset_field_editor" | translate }}:
        {{ assetFieldsConfigForm.controls["displayName"]?.value }}
      </h4>
      <button
        class="btn btn-flat-primary"
        (click)="saveField()"
        data-cy="button-save-field"
      >
        <i class="ri-save-2-line"></i>
        {{ "core.save" | translate }}
      </button>
    </div>
    <form [formGroup]="assetFieldsConfigForm">
      <mat-form-field class="full-width">
        <mat-label>{{ "label.display_name" | translate }}</mat-label>
        <input matInput type="text" formControlName="displayName" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>{{ "label.field_path" | translate }}</mat-label>
        <input matInput type="text" formControlName="fieldPath" />
      </mat-form-field>
    </form>
  </ng-container>
</ng-template>
