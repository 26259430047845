import { ICookiesGroup } from '.';

export const COOKIES_FIREBASE_KEY = 'firebase_analytics_enabled';
export const COOKIES_FULLSTORY_KEY = 'recording_identification_enabled';

export const cookiesData: ICookiesGroup[] = [
  {
    title: 'dialogs.cookies.essential.title',
    _type: 'essential',
    items: [],
    needed: true,
    description: 'dialogs.cookies.essential.description',
    collapsed: true,
  },
  {
    title: 'dialogs.cookies.performance.title',
    _type: 'performance',
    description: 'dialogs.cookies.performance.description',
    items: [
      {
        type: 'google_analytics',
        title: 'dialogs.cookies.performance_google_analytics.title',
        _key: COOKIES_FIREBASE_KEY,
        default: true,
        group: 'analytics',
        description: 'Basic usage metrics',
      },
      {
        type: 'fullstory',
        title: 'dialogs.cookies.performance_fullstory.title',
        _key: COOKIES_FULLSTORY_KEY,
        default: true,
        group: 'analytics',
        description: 'dialogs.cookies.performance_fullstory.description',
      },
    ],
    collapsed: true,
  },
];
