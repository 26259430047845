/* eslint-disable @angular-eslint/directive-selector */
import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Optional,
} from "@angular/core";
import { IAssetROI } from "@telespot/sdk";
import { Placement, Point, Rect, Viewer } from "openseadragon";
import { Subject } from "rxjs";
import { skipWhile, takeUntil } from "rxjs/operators";

import { OpenseadragonComponent } from "../components/openseadragon/openseadragon.component";

@Directive({
  selector:
    "ts-openseadragon (div[osdOverlay],a[osdOverlay],span[osdOverlay],i[osdOverlay],img[osdOverlay],ts-osd-roi[osdOverlay],canvas[osdOverlay])",
})
export class OsdOverlayDirective implements OnDestroy {
  private _position: IAssetROI;
  private _destroy$ = new Subject<void>();

  @Input() set position(position: IAssetROI) {
    if (position === null) position = { x: 0, y: 0, w: 0, h: 0 };
    this._position = { ...position };
    this._update();
  }
  get position() {
    return { ...this._position };
  }

  @Input() units: "px" | "relative" = "px";

  private _getViewportPosition(): Rect | Point {
    const osdImage = this.osd.viewer.world.getItemAt(0);

    switch (this.units) {
      case "px":
        if (!osdImage) return;
        return !this.position.w || !this.position.h
          ? osdImage.imageToViewportCoordinates(
              new Point(this.position.x, this.position.y)
            )
          : osdImage.imageToViewportRectangle(
              new Rect(
                this.position.x,
                this.position.y,
                this.position.w,
                this.position.h
              )
            );
      case "relative":
        return !this.position.w || !this.position.h
          ? this.osd.viewer.viewport.imageToViewportCoordinates(
              new Point(this.position.x, this.position.y)
            )
          : new Rect(
              this.position.x,
              this.position.y,
              this.position.w,
              this.position.h
            );
    }
  }

  constructor(
    @Optional() private osd: OpenseadragonComponent,
    private _element: ElementRef
  ) {
    if (!this.osd || !this.osd.viewer) {
      _element.nativeElement.hidden = true;
    }
    this.osd?.viewerReady$
      .pipe(takeUntil(this._destroy$))
      .subscribe((ready) => {
        if (ready) {
          _element.nativeElement.hidden = false;
          this._update();
        } else {
          _element.nativeElement.remove();
        }
      });
  }

  private _update() {
    if (!this.osd?.viewer) {
      console.log(`updating pos`);
      this.osd.viewerReady$
        .pipe(
          skipWhile((_) => !_),
          takeUntil(this._destroy$)
        )
        .subscribe((_) =>
          this.osd.viewer.addOnceHandler("tile-drawn", () => this._update())
        );
      return;
    }
    if (this.osd?.viewer && this.position) {
      const viewer: Viewer = this.osd.viewer;
      const coordinates = this._getViewportPosition();
      const positionReference =
        coordinates instanceof Point ? Placement.CENTER : Placement.LEFT;
      if (!viewer.getOverlayById(this._element.nativeElement)) {
        viewer.addOverlay(
          this._element.nativeElement,
          coordinates,
          positionReference
        );
      } else {
        viewer.updateOverlay(
          this._element.nativeElement,
          coordinates,
          positionReference
        );
      }
    } else {
      this.osd.viewer.addOnceHandler("tile-drawn", (_) => this._update());
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    // TODO: check if the overlay needs to be removed from OSD viewer
    if (this.osd?.viewer) {
      this.osd.viewer.removeOverlay(this._element.nativeElement);
    }
    this._element.nativeElement.remove();
  }
}
