<div class="image-container">
  <ng-container *ngIf="loading">
    <ng-container *ngTemplateOutlet="placeholderTemplate"></ng-container>
  </ng-container>
  <img
    loading="lazy"
    draggable="false"
    [src]="safeUrl"
    [class.loading]="loading"
  />
  <ng-content></ng-content>
</div>
