<div class="card padded">
  <div class="card-body column">
    <ts-data-table [config]="tableConfig" [dataSource]="dataSource">
      <ng-template dataTableCell="casetypes_list" let-item>
        <ng-container>
          <li
            class="d-flex align-items-center justify-content-between list-unstyled"
            data-cy="case-type-row"
          >
            <span [routerLink]="[item.id]" class="flex-row">
              {{ item.resource.name }}
              <i
                class="ri-question-answer-line"
                style="margin-left: 10px"
                *ngIf="item.resource.description"
                data-bs-toggle="tooltip"
                [title]="item.resource.description"
              ></i>
            </span>

            <div class="button-bar float-right stretch">
              <button
                class="btn btn-flat-primary btn-auto"
                type="button"
                data-cy="case-type-edit"
                [routerLink]="['edit', item.id]"
              >
                <i class="ri-pencil-line"></i>
              </button>
              <button
                class="btn btn-flat-danger btn-auto"
                type="button"
                (click)="removeCaseType(item.resource)"
                data-cy="case-type-delete"
              >
                <i class="ri-delete-bin-6-line"></i>
              </button>
            </div>
          </li>
        </ng-container>
      </ng-template>
    </ts-data-table>
  </div>
</div>
