import { IAssetROIWithModels, ModelWithROIs } from "../value-objects";
import { AnalysisType } from "./analysis-type";
import { Entity, SoftDeletable } from "./entity";

export enum AssetStatus {
  reviewed = "reviewed",
}

export type AnalyzerEntity = "algorithm" | "user" | "unknown";

export interface ROI {
  x: number;
  y: number;
  w?: number;
  h?: number;
  [key: string]: any;
}

export interface AnalysisRoiData {
  [key: string]: ROI[];
}

export type AnalysisResponseValue = number | string | boolean | AnalysisData;

export interface AnalysisData {
  [key: string]: AnalysisResponseValue | AnalysisRoiData;
}

export interface AnalysisParameters extends Entity, SoftDeletable {
  // REVIEW: in future substitute with SubjectEntity and id
  sampleId: string;
  assetId?: string;
  creatorEntity: AnalyzerEntity;
  creatorId: string;
  assetStatus?: AssetStatus;
  pipelineId?: string;
  uuid?: string;
  analysisType?: AnalysisType;
  data?: AnalysisData;
}

export class Analysis implements Entity, SoftDeletable {
  constructor(private attributes: AnalysisParameters) {}

  public get id(): string | undefined {
    return this.attributes.id;
  }

  public get sampleId(): string {
    return this.attributes.sampleId;
  }

  public get assetId(): string | undefined {
    return this.attributes.assetId;
  }

  public get creatorEntity(): AnalyzerEntity {
    return this.attributes.creatorEntity;
  }

  public get creatorId(): string {
    return this.attributes.creatorId;
  }

  public get assetStatus(): AssetStatus {
    return this.attributes.assetStatus;
  }

  public get pipelineId(): string {
    return this.attributes.pipelineId;
  }

  public get analysisType(): AnalysisType {
    return this.attributes.analysisType;
  }

  public get data(): AnalysisData {
    return this.attributes.data;
  }

  public get uuid(): string {
    return this.attributes.uuid;
  }

  public get deletedAt(): Date | undefined {
    return this.attributes.deletedAt;
  }

  public get createdAt(): Date | undefined {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date | undefined {
    return this.attributes.updatedAt;
  }

  public creatorIs(entity: AnalyzerEntity): boolean {
    return this.creatorEntity === entity;
  }

  public copyToSample(newSampleId: string): Analysis {
    const { id, ...attributesCopy } = this.attributes;

    return new Analysis({
      ...attributesCopy,
      sampleId: newSampleId,
    });
  }

  /**
   * Registers all rois from the analysis agains an {@link ModelWithROIs} array.
   * Input paramenter is optional. Uses analsys own analysis type if not provided.
   *
   * @param modelsWithROIs {@link ModelWithROIs} array where to store all rois
   * @returns populated models with rois
   */
  public registerRoisWithModels(models?: ModelWithROIs[]) {
    const modelsWithROIs = models ?? this.analysisType.getRoiModels();

    for (const model of modelsWithROIs) {
      const customModels = Object.keys(this.data[model.model.task.name] ?? {})
        .filter(
          (k) => !modelsWithROIs.some((model) => model.model.className === k)
        )
        .map((className) => ({
          className,
          task: model.model.task,
          displayName: className,
          custom: true,
        }));

      modelsWithROIs.push(
        ...customModels.map((model) => ({
          model,
          rois: new Array<IAssetROIWithModels>(),
        }))
      );

      model.rois.push(
        ...(
          this.data[model.model.task.name]?.[model.model.className] || []
        ).map((roiData) => ({
          ...roiData,
          assetId: this.assetId,
          models: [model.model],
        }))
      );
    }

    return modelsWithROIs;
  }
}
