<div class="main-container" *ngIf="{ case: case$ | async, results: results$ | async } as state">
  <div class="title">
    <span class="breadcrumbs flex-row flex-align-center" style="justify-content: flex-start">
      <a routerLink="/workspaces">
        {{ 'title.reports' | translate }}
      </a>
      <ng-container *ngIf="state.case">
        <a [routerLink]="['/workspaces', state.case.workspace.id, 'cases']" [queryParams]="{ page: 0, pageSize: 20 }">
          {{ state.case.workspace.name }}
        </a>
        / {{ state.case.name }}
      </ng-container>
    </span>
    <!-- <h1>{{ 'title.report_for_case' | translate }}</h1> -->
    <div class="actions">
      <ng-container *ngIf="state.results?.length; else processingExport">
        <button class="btn btn-outline-primary" (click)="export(state.results)" data-cy="button-export-report">
          <i class="ri-file-download-line"></i>
          {{ 'button.export' | translate | uppercase }}
        </button>
      </ng-container>
      <ng-template #processingExport>
        <button class="btn btn-outline-primary disabled" disabled>{{ 'label.loading' | translate }}...</button>
      </ng-template>
      <!-- TODO: REVIEW: functionality -->
      <!-- <button class="btn btn-outline-primary auto">
        <i class="ri-mail-send-line"></i>
        {{ 'button.send_report' | translate }}
      </button> -->
      <button class="btn btn-primary" (click)="reopenCase()" data-cy="button-reopen-case">
        <i class="ri-folder-5-line"></i>
        {{ 'button.reopen_case' | translate }}
      </button>
    </div>
  </div>

  <div class="flex-row flex-wrap">
    <ts-case-data class="card flex-grow-1" [case]="state.case"></ts-case-data>
    <ts-case-info class="card flex-grow-1"></ts-case-info>
  </div>

  <div style="margin: 20px 10px">
    <h1>{{ 'title.analysis_results' | translate | uppercase }}</h1>
  </div>

  <ng-container *ngIf="state.results?.length; else loadingResults">
    <ts-results-panel [methodGroups]="state.results" [context]="context.sample"></ts-results-panel>
    <ts-results-panel [methodGroups]="state.results" [context]="context.counter"></ts-results-panel>
    <ts-results-panel [methodGroups]="state.results" [context]="context.asset"></ts-results-panel>
  </ng-container>
  <ng-template #loadingResults>
    <div class="card">
      <div class="card-body">
        {{ 'info.loading_data' | translate }}
        <mat-progress-bar mode="query"></mat-progress-bar>
      </div>
    </div>
  </ng-template>
</div>
