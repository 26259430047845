import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatSliderChange } from "@angular/material/slider";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { OpenseadragonComponent } from "../openseadragon/openseadragon.component";

@Component({
  selector: "ts-osd-zoom-controller",
  templateUrl: "./osd-zoom-controller.component.html",
  styleUrls: ["./osd-zoom-controller.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OsdZoomControllerComponent implements OnInit, OnDestroy {
  @Input() osd: OpenseadragonComponent;

  private _zoom$ = new Subject<number>();
  public readonly zoom$ = this._zoom$.asObservable();

  private _destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.osd.viewerReady$
      .pipe(
        filter((ready) => !!ready),
        takeUntil(this._destroy$)
      )
      .subscribe((_) => {
        this._zoom$.next(this.osd.viewer.viewport.getZoom());
        this.osd.viewer?.addHandler("zoom", (level) => {
          this._zoom$.next(level.zoom);
        });
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  public setZoom(event: MatSliderChange): void {
    this.osd.zoom = event.value;
  }
}
