export type TaskTypeName =
  | "boolean"
  | "textedit"
  | "selection"
  | "switcher"
  | "number";

export enum EAnalysisTaskType {
  asset_checkbox = "Asset checkbox",
  asset_text = "Asset text",
  class_counter = "Class ROIs",
  class_presence = "Class presence",
  class_rois = "ROI selection",
  sample_checkbox = "Sample checkbox",
  sample_number = "Counter",
  sample_switcher = "Switcher",
  sample_text = "Sample text",
}

export interface TAnalysisTask {
  name: string;
  displayName?: string;
  type?: TaskTypeName;
  options?: string[] | TAnalysisModelItem[];
  allowNewOptions?: boolean;
  multiple?: boolean;
  roiSelection?: boolean;
  roiSelectionType?: TRoiSelectionType;
  assetSpecific?: boolean;
  required?: boolean;
}

export interface TAnalysisModelItem {
  name: string;
  displayName?: string;
  color?: string;
  ai_models?: string[];
}

export enum TRoiSelectionType {
  center = "center",
  boundingBox = "boundingBox",
  free = "free",
}

export interface IAssetROI {
  time?: number;
  x: number;
  y: number;
  w?: number;
  h?: number;
  assetId?: string;
}

export interface IAssetROIWithModels extends IAssetROI {
  models?: RoiModel[];
  isAIresult?: boolean;
  score?: number;
  id?: string;
  // REMOVE IN FUTURE (JUST TO PAPER)
  classAI?: string;
}

/**
 * Represents a model / label / tag that can be associated to a region or point of interest
 *
 * @export
 * @interface RoiModel
 */
export interface RoiModel {
  /**
   * The analysis task this RoiModel comes from
   *
   * @type {TAnalysisTask}
   * @memberof RoiModel
   */
  task: TAnalysisTask;
  /**
   * ROI Model identifier, i.e 'parasite'
   *
   * @type {string}
   * @memberof RoiModel
   */
  className: string;
  /**
   * ROI Model displayed name, i.e. 'Parásito'
   *
   * @type {string}
   * @memberof RoiModel
   */
  displayName: string;
  /**
   * Array of ML models that produce tags of for this ROI Model
   *
   * @type {string[]}
   * @memberof RoiModel
   */
  ai_models?: string[];
  /**
   * The color to display ROIs of this model
   *
   * @type {string}
   * @memberof RoiModel
   */
  color?: string;
  /**
   * Used to tag custom ROI Model created by the user during analysis
   *
   * @type {boolean}
   * @memberof RoiModel
   */
  custom?: boolean;
}

export interface ModelWithROIs {
  model: RoiModel;
  rois: IAssetROIWithModels[];
}
