import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { DeviceEditorComponent } from "./components/device-editor/device-editor.component";
import { DevicesComponent } from "./components/devices/devices.component";
import { DevicesRoutingModule } from "./telespot-devices-feature-routing.module";
import { DataTableModule } from "@shared/ui";

@NgModule({
  imports: [
    CommonModule,
    DevicesRoutingModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    MatTableModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    DataTableModule,
  ],
  declarations: [DeviceEditorComponent, DevicesComponent],
  exports: [DeviceEditorComponent, DevicesComponent],
})
export class TelespotDevicesFeatureModule {}
