import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ILicenseFeatures, Organization, User } from '@telespot/sdk';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CURRENT_ORGANIZATION, CURRENT_USER } from '../../tokens';

export type TFeatureName =
  | 'acquisition'
  | 'caseTypes'
  | 'ai'
  | 'secondOpinion'
  | 'exportWorkspace'
  | 'masks'
  | 'analysisReview';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService implements OnDestroy {
  private _features: ILicenseFeatures;
  private _destroy$ = new Subject<void>();
  private _currentUser: User;
  constructor(
    @Inject(CURRENT_ORGANIZATION) private _currentOrganization$: Observable<Organization>,
    @Inject(CURRENT_USER) private _currentUser$: Observable<User>
  ) {
    this._currentOrganization$.pipe(takeUntil(this._destroy$)).subscribe((organization) => {
      this._features = organization?.license?.features;
    });
    this._currentUser$.pipe(takeUntil(this._destroy$)).subscribe((user) => (this._currentUser = user));
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  // WIP
  canUse(feature: TFeatureName, subFeature?: string): boolean {
    switch (feature) {
      case 'acquisition':
        return (
          this._currentUser.isAdmin ||
          (this._currentUser.isTechnician && (this._features?.acquisition_available as any)?.enabled) ||
          (this._currentUser.isTechnicianManager && (this._features?.acquisition_available as any)?.enabled)
        );
      case 'caseTypes':
        return this._currentUser.isAdmin;
      case 'ai':
        return typeof this._features?.ai === 'boolean' ? this._features?.ai : this._features?.ai?.[subFeature ?? 'enabled'];
      case 'secondOpinion':
        return typeof this._features?.second_opinion === 'boolean'
          ? this._features?.second_opinion
          : this._features?.second_opinion?.[subFeature ?? 'enabled'];
      case 'exportWorkspace':
        return typeof this._features?.export_workspace === 'boolean'
          ? this._features?.export_workspace
          : this._features?.export_workspace?.[subFeature ?? 'enabled'];
      case 'analysisReview':
        return typeof this._features?.analysis_review === 'boolean'
          ? this._features?.analysis_review
          : this._features?.analysis_review?.[subFeature ?? 'enabled'];
      case 'masks':
      default:
        return this._currentUser.isAdmin;
    }
  }
}
