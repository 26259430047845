import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService, UserProfileService } from '@telespot/web-core';
import { Organization } from '@telespot/sdk';

import { OrganizationSelectorModule } from '../organization-selector/organization-selector.component';
import { RedeemCodeDialogComponent, RedeemCodeDialogModule } from '../redeem-code-dialog/redeem-code-dialog.component';
import { UserDetailModule } from '../user-detail/user-detail.component';

@Component({
  selector: 'ts-user-dashboard-shell',
  templateUrl: './user-dashboard-shell.component.html',
  styleUrls: ['./user-dashboard-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDashboardShellComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private userProfileService: UserProfileService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  public currentUser;

  activateOrganization(organization: Organization) {
    this.userProfileService.setActiveOrganization(organization);
  }

  ngOnInit(): void {
    this.authService.updateUserRolesAndOrganizations();
    const inviteCode = this.route.snapshot.queryParamMap.get('invite');
    if (inviteCode) {
      this.redeemCode(inviteCode);
    }
    this.currentUser = this.authService.currentUser;
  }

  redeemCode(inviteCode?: string) {
    this.dialog.open(RedeemCodeDialogComponent, {
      data: {
        inviteCode,
      },
      disableClose: true,
    });
  }

  updateProfilePicture(file: File): void {
    this.userProfileService.changeProfilePicture(file);
  }
}

@NgModule({
  declarations: [UserDashboardShellComponent],
  imports: [
    CommonModule,
    OrganizationSelectorModule,
    UserDetailModule,
    TranslateModule,
    RedeemCodeDialogModule,
    MatDialogModule,
    RouterModule,
  ],
  exports: [UserDashboardShellComponent],
})
export class UserDashboardShellModule {}
