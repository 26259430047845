<ng-container *ngIf="{ faqItems: faqItems$ | async, section: selectedSection$ | async } as state; else noSection">
  <ng-container *ngIf="state.faqItems">
    <div class="card-header">
      <h2 style="text-transform: uppercase">
        {{ state.faqItems[state.section]?.title | translate }}
      </h2>
    </div>

    <div class="list-group list-group-flush">
      <a class="list-group-item" *ngFor="let item of state.faqItems[state.section]?.questions">
        <div class="text-primary">
          {{ item.title | translate }}
        </div>
        {{ item.description | translate }}
      </a>
    </div>
  </ng-container>
</ng-container>
<ng-template #noSection>
  <h3>Select a section first</h3>
</ng-template>
