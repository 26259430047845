import { createReducer, on } from "@ngrx/store";
import { RoiModel } from "@telespot/sdk";
import { ISampleItem } from "../models/i-sample-item";

import { RoiModelUtils } from "../models/roi-model-utils";

import * as roiActions from "./rois.actions";
import { initialState, ROIState } from "./rois.state";

const _roisReducer = createReducer<ROIState>(
  initialState,
  on(roiActions.setSelectedSample, (state, { selectedSample }) => ({
    ...state,
    selectedSample,
  })),
  on(roiActions.setNumAssets, (state, { numAssets }) => ({
    ...state,
    numAssets,
  })),
  on(roiActions.clearROIState, (state) => initialState),
  on(roiActions.registerModels, (state, props) => ({
    ...state,
    models: props.replace
      ? props.models.map((m) => ({ model: m, visible: true, selected: false }))
      : [
          ...state.models,
          ...props.models
            .filter(
              (m) =>
                !state.models.some((rm) => RoiModelUtils.equalTo(rm.model, m))
            )
            .map((m) => ({ model: m, visible: true, selected: false })),
        ],
  })),
  on(roiActions.selectModels, _selectModels),
  on(roiActions.assetLoaded, (state, { asset }) => ({
    ...state,
    selectedAsset: asset,
  })),

  on(roiActions.roisActionError, (state, { error }) => ({ ...state, error })),
  on(roiActions.sampleAnalysisStateFetched, (state, { analysisState }) => {
    const availableAnalysts = [...(state?.availableAnalysts || [])];
    const currentSelectedAnalystIndex = availableAnalysts.findIndex(
      (_analyst) => _analyst?.selected
    );
    const analystToBeSelectedIndex = availableAnalysts.findIndex(
      (_analyst) => _analyst?.analysisState?.id === analysisState?.id
    );
    if (currentSelectedAnalystIndex > -1) {
      availableAnalysts[currentSelectedAnalystIndex] = {
        ...availableAnalysts[currentSelectedAnalystIndex],
        selected: false,
      };
    }
    if (analystToBeSelectedIndex > -1) {
      availableAnalysts[analystToBeSelectedIndex] = {
        ...availableAnalysts[analystToBeSelectedIndex],
        selected: true,
      };
    }

    const refStripItems = [
      ...(state?.refStripItems.map((i) => ({
        ...i,
        fetched: false,
        favAnalysis: analysisState?.favAssets?.includes(i.assetId) ?? false,
      })) || []),
    ];
    return {
      ...state,
      sampleAnalysisState: analysisState,
      availableAnalysts,
      refStripItems,
    };
  }),
  on(roiActions.caseAnalysisStateFetched, (state, { analysisState }) => {
    return { ...state, caseAnalysisState: analysisState };
  }),
  on(roiActions.loadRefStripElements, (state, { refStripItems }) => {
    const refStripItemsUpdated = [
      ...(refStripItems.map((i) => ({
        ...i,
        favAnalysis:
          state.sampleAnalysisState?.favAssets?.includes(i.assetId) ?? false,
      })) || []),
    ];
    state.selectedAsset
      ? refStripItemsUpdated.find((item) =>
          item.assetId === state.selectedAsset.id
            ? (item.viewed = true)
            : (item.viewed = false)
        )
      : (refStripItemsUpdated[0].viewed = true);
    return {
      ...state,
      refStripItems: state?.refStripItems.concat(refStripItemsUpdated),
      selectedAssetIndex: state.selectedAsset
        ? refStripItemsUpdated.find(
            (item) => item.assetId === state?.selectedAsset.id
          )?.assetIndex
        : 0,
    };
  }),
  on(
    roiActions.refStripLoaded,
    (state, { refStripDataResponse, itemsRequested, assetIds, isAuthUser }) => {
      const refItemsUpdated = state?.refStripItems.map((item) => {
        const elementUpdate = refStripDataResponse.find(
          (elem) => elem?.assetId === item?.assetId
        );

        return elementUpdate
          ? {
              ...item,
              ...elementUpdate,
              fetched: true,
              ...(isAuthUser && {
                analyzed:
                  assetIds?.includes(item.assetId) || elementUpdate.analyzed,
              }),
            }
          : itemsRequested?.includes(item.assetId)
          ? {
              ...item,
              reviewed: false,
              fetched: true,
              analyzed: assetIds?.includes(item.assetId),
            }
          : { ...item };
      });
      return {
        ...state,
        refStripItems: refItemsUpdated,
      };
    }
  ),
  on(roiActions.markItemAsViewed, (state, { assetId }) => {
    const refItemsUpdated = [...(state?.refStripItems || [])];
    if (assetId) {
      const index = refItemsUpdated.findIndex(
        (item) => item?.assetId === assetId
      );
      refItemsUpdated[index] = {
        ...refItemsUpdated[index],
        viewed: true,
      };
    }
    return {
      ...state,
      refStripItems: refItemsUpdated,
    };
  }),
  on(roiActions.setFavAnalysis, (state, { assetId, analysisState }) => {
    const refItemsUpdated = [...(state?.refStripItems || [])];
    if (assetId) {
      const index = refItemsUpdated.findIndex(
        (item) => item?.assetId === assetId
      );
      refItemsUpdated[index] = {
        ...refItemsUpdated[index],
        favAnalysis: analysisState?.favAssets?.includes(assetId),
      };
    }
    return {
      ...state,
      refStripItems: refItemsUpdated,
      sampleAnalysisState: analysisState,
    };
  }),
  on(roiActions.markAssetAsReviewed, (state, { assetIds }) => {
    const refItemsUpdated: ISampleItem[] = [...(state?.refStripItems || [])];
    const indexArray = refItemsUpdated
      .filter((item) => assetIds.includes(item?.assetId))
      .map((item) => item.assetIndex);
    indexArray.map((index) => {
      refItemsUpdated[index] = {
        ...refItemsUpdated[index],
        reviewed: true,
      };
    });
    return {
      ...state,
      refStripItems: refItemsUpdated,
    };
  }),
  on(roiActions.markAssetAsAnalyzed, (state, { assetId }) => {
    const refItemsUpdated: ISampleItem[] = [...(state?.refStripItems || [])];
    const indexArray = refItemsUpdated
      .filter((item) => assetId === item?.assetId)
      .map((item) => item.assetIndex);
    indexArray.map((index) => {
      refItemsUpdated[index] = {
        ...refItemsUpdated[index],
        analyzed: true,
      };
    });
    return {
      ...state,
      refStripItems: refItemsUpdated,
    };
  }),
  on(
    roiActions.discardMarkAsAnalyzed,
    (state, { assetId, analysisStatesId }) => {
      if (analysisStatesId !== state?.sampleAnalysisState?.id)
        return { ...state };
      const refItemsUpdated: ISampleItem[] = [...(state?.refStripItems || [])];
      const indexArray = refItemsUpdated
        .filter((item) => assetId === item?.assetId)
        .map((item) => item.assetIndex);
      indexArray.map((index) => {
        refItemsUpdated[index] = {
          ...refItemsUpdated[index],
          analyzed: false,
        };
      });
      return {
        ...state,
        refStripItems: refItemsUpdated,
      };
    }
  ),
  on(roiActions.assetIndexLoaded, (state, { index }) => ({
    ...state,
    selectedAssetIndex: index,
  })),
  on(roiActions.bookmarkAsset, (state, { analysisState }) => ({
    ...state,
    sampleAnalysisState: analysisState,
  })),
  on(
    roiActions.setAvailableAnalysts,
    (state, { analysisStates, currentUserId }) => {
      const availableAnalysts = (analysisStates ?? []).map((analysisState) => ({
        analysisState,
        selected: analysisState?.id === state?.sampleAnalysisState?.id,
        isCurrentUser: analysisState.user?.id === currentUserId,
        isAI: !!analysisState.algorithm,
      }));
      return {
        ...state,
        availableAnalysts: availableAnalysts,
      };
    }
  ),
  on(
    roiActions.loadCaseSampleRef,
    (
      state,
      {
        nextCase,
        previousCase,
        nextSample,
        previousSample,
        nextCaseSample,
        prevCaseSample,
      }
    ) => ({
      ...state,
      nextCase: {
        caseId: nextCase?.id ?? null,
        sampleId: nextCaseSample?.id ?? null,
      },
      previousCase: {
        caseId: previousCase?.id ?? null,
        sampleId: prevCaseSample?.id ?? null,
      },
      nextSample: nextSample?.id ?? null,
      previousSample: previousSample?.id ?? null,
    })
  ),
  on(roiActions.updateAssetFilters, (state, { filter }) => {
    return {
      ...state,
      assetFilters: {
        ...state?.assetFilters,
        [filter]: !state.assetFilters[filter],
      },
    };
  })
);

function _selectModels(
  state: ROIState,
  props: { models: RoiModel[]; replace?: boolean }
): ROIState {
  return { ...state };
}

export function roisReducer(state, action) {
  return _roisReducer(state, action);
}
