import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  createSegmAnalysis,
  findingsUnsynced,
  getActiveSegmentationFinding,
  IFinding,
  isAuthUserActive,
  Label,
  loadingMask,
  selectedLabels,
  selectmaskLoading,
  selectSegmentationTasks,
  syncAnalysis,
} from "@telespot/analysis-refactor/data-access";
import { AnalysisProtocolTask, IAssetROI, LabelUtils } from "@telespot/sdk";
import { Subject } from "rxjs";
import { filter, pairwise, switchMap, take, takeUntil } from "rxjs/operators";
import { OpenseadragonComponent } from "../openseadragon/openseadragon.component";
import { MouseTracker, Point } from "openseadragon";
import {
  MasksPluginService,
  MaskViewerService,
  OtherTools,
  TOsdActiveAction,
  TSegmentationAction,
  ViewerService,
} from "@telespot/shared/viewers/data-access";
import { AuthService } from "@telespot/web-core";
import OpenSeadragon from "openseadragon";

@Component({
  selector: "ts-mask-viewer",
  templateUrl: "./mask-viewer.component.html",
  styleUrls: ["./mask-viewer.component.scss"],
})
export class MaskViewerComponent implements OnInit {
  @Input() osd: OpenseadragonComponent;
  @Input() opacity = 0.5;
  @Input() strokeWidth = 20;
  @ViewChild("canvas", { static: true }) canvas: ElementRef<HTMLCanvasElement>;

  private _activeTasks: AnalysisProtocolTask[];
  private labelUtils = new LabelUtils();
  private _activeLabels: Label[];
  private _activeFinding: IFinding;
  private _canEditMask: boolean;

  private _destroy$ = new Subject<void>();

  private _mousedown$ = new Subject<any>();
  private mousedown$ = this._mousedown$.asObservable();

  private _mousemove$ = new Subject<any>();
  private mousemove$ = this._mousemove$.asObservable();

  private _mouseleave$ = new Subject<any>();
  private mouseleave$ = this._mouseleave$.asObservable();

  public readonly selectedLabels$ = this.store.select(selectedLabels);
  public readonly canEditMask$ = this.store.select(
    isAuthUserActive(this._authService.currentUser.id)
  );
  public activeFinding$ = this.store.select(getActiveSegmentationFinding);
  public maskLoading$ = this.store.select(selectmaskLoading);

  ctx: CanvasRenderingContext2D;
  public _position: IAssetROI;
  public _spinnerPosition: IAssetROI;
  private segmentationAction: TSegmentationAction;
  private activeMode;

  get position() {
    return { ...this._position };
  }
  get spinnerPosition() {
    return { ...this._spinnerPosition };
  }
  constructor(
    private store: Store,
    private _maskService: MasksPluginService,
    private _viewerService: ViewerService,
    private _authService: AuthService,
    private _maskViewerService: MaskViewerService
  ) {
    this.store
      .select(selectSegmentationTasks)
      .pipe(takeUntil(this._destroy$))
      .subscribe((tasks) => (this._activeTasks = tasks));
    this.store
      .select(selectedLabels)
      .pipe(takeUntil(this._destroy$))
      .subscribe((labels) => (this._activeLabels = labels));

    this._maskService.activeSegmentationMode$
      .pipe(takeUntil(this._destroy$))
      .subscribe((mode) => {
        this.segmentationAction = mode;
      });

    this._viewerService.activeViewerMode$
      .pipe(takeUntil(this._destroy$))
      .subscribe((mode) => {
        this.activeMode = mode;
      });

    this.canEditMask$.subscribe((value) => {
      this._canEditMask = value;
    });

    this._maskViewerService.saveMaskOnLocalStorage
      .pipe(takeUntil(this._destroy$))
      .subscribe((payload) => {
        this.saveMaskOnLocalStorage(payload.id);
        if (payload.save) this.store.dispatch(syncAnalysis());
      });
  }

  ngOnInit(): void {
    this._position = {
      x: 0,
      y: 0,
      w: this.osd?._conf?.width,
      h: this.osd?._conf?.height,
    };
    this._spinnerPosition = {
      x: this.osd?._conf?.width / 2,
      y: this.osd?._conf?.height / 2,
      w: 1,
      h: 1,
    };
    this.ctx = this.canvas.nativeElement.getContext("2d");
    this.ctx.canvas.width = this.osd?._conf?.width;
    this.ctx.canvas.height = this.osd?._conf?.height;

    this.ctx.lineWidth = this.strokeWidth;
    this.ctx.lineCap = "round";

    this.activeFinding$
      .pipe(
        filter((finding) => this._activeFinding?.id !== finding?.id),
        takeUntil(this._destroy$)
      )
      .subscribe(async (finding) => {
        this._activeFinding = finding;
        this.clearCanvas();
        if (!finding) return;
        else {
          this.store.dispatch(loadingMask({ loading: true }));
          const src = await this._maskViewerService.fetchSegmentationMask(
            this._activeFinding
          );

          this.showSegmentationAnalysis(src);
          this.store.dispatch(loadingMask({ loading: false }));
        }
      });

    this.captureEvents(this.canvas.nativeElement);

    new MouseTracker({
      element: this.ctx.canvas,
      pressHandler: (e) => {
        if (this.activeMode === OtherTools.layers && this._canEditMask) {
          this._mousedown$.next({
            clientX: (e as any).originalEvent.clientX,
            clientY: (e as any).originalEvent.clientY,
          });
        }
      },
      releaseHandler: (e) => {
        if (this.activeMode === OtherTools.layers && this._canEditMask) {
          this._mouseleave$.next(true);
        }
      },
      moveHandler: (e) => {
        if (this.activeMode === OtherTools.layers && this._canEditMask) {
          this._mousemove$.next({
            clientX: (e as any).originalEvent.clientX,
            clientY: (e as any).originalEvent.clientY,
          });
        }
      },
      dragHandler: (e) => {
        if (this.activeMode === TOsdActiveAction.idle) {
          // Get the current bounds of the viewport
          const viewportBounds = this.osd.viewer.viewport.getBounds();
          const canvasRect = this.ctx.canvas.getBoundingClientRect();

          // Transform the delta coordinates based on the position of the canvas element
          const deltaX =
            (e as any).delta.x * this.osd.viewer.viewport.getZoom(true);
          const deltaY =
            (e as any).delta.y * this.osd.viewer.viewport.getZoom(true);
          const deltaXT = deltaX / canvasRect.width;
          const deltaYT = deltaY / canvasRect.height;

          const newBounds = new OpenSeadragon.Rect(
            viewportBounds.x - deltaXT,
            viewportBounds.y - deltaYT,
            viewportBounds.width,
            viewportBounds.height,
            viewportBounds.degrees
          );

          // Set the new bounds of the viewport
          this.osd.viewer.viewport.fitBounds(newBounds);
        }
      },
    }).setTracking(true);
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  getColor() {
    return this.labelUtils.getLabelColor(
      this._activeLabels.map((label) => label.uuid),
      true,
      this._activeTasks
    );
  }

  private captureEvents(canvasEl: HTMLCanvasElement) {
    this.mousedown$
      .pipe(
        switchMap((e) => {
          return this.mousemove$.pipe(takeUntil(this.mouseleave$), pairwise());
        })
      )
      .subscribe((res: [any, any]) => {
        if (!this._activeLabels || this._activeLabels.length < 1) return;
        if (!this._activeFinding) {
          this.store.dispatch(createSegmAnalysis());
          this.activeFinding$.pipe(take(1)).subscribe((newFinding) => {
            this._activeFinding = newFinding;
          });
        }
        if (this._activeFinding?.synced) {
          this.store.dispatch(
            findingsUnsynced({ finding: this._activeFinding })
          );
        }

        if (this.segmentationAction === TSegmentationAction.edit) {
          this.ctx.globalCompositeOperation = "destination-out";
          this.ctx.lineWidth = this.strokeWidth;
        } else {
          this.ctx.globalCompositeOperation = "source-over";
          this.ctx.lineWidth = this.strokeWidth;
          this.ctx.strokeStyle = this.getColor();
        }

        const coordprev = this._getCoords(res[0]);
        const coordcurr = this._getCoords(res[1]);

        const { prevPos, currentPos } = this._getPositions(
          coordprev,
          coordcurr
        );
        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  private _getCoords(event): { viewport: Point; image: Point } {
    //TODO: extract this functionality into utils
    const clientPos = new Point(event["clientX"], event["clientY"]);
    const viewportPos = this.osd.viewer.viewport.pointFromPixel(
      clientPos,
      true
    );
    return {
      viewport: viewportPos,
      image: this.osd.viewer.world
        .getItemAt(0)
        .viewportToImageCoordinates(viewportPos),
    };
  }

  private _getPositions(coordprev, coordcurr) {
    // const rect = canvasEl.getBoundingClientRect();
    // previous and current position with the offset
    // const prevPos = {
    //   x: res[0].clientX - rect.left,
    //   y: res[0].clientY - rect.top,
    // };

    // const currentPos = {
    //   x: res[1].clientX - rect.left,
    //   y: res[1].clientY - rect.top,
    // };
    const prevPos = {
      x: coordprev.image.x,
      y: coordprev.image.y,
    };

    const currentPos = {
      x: coordcurr.image.x,
      y: coordcurr.image.y,
    };
    return { prevPos, currentPos };
  }
  private drawOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    if (!this.ctx) {
      return;
    }
    this.ctx.beginPath();
    if (prevPos) {
      this.ctx.moveTo(prevPos.x, prevPos.y);
      this.ctx.lineTo(currentPos.x, currentPos.y);
      this.ctx.stroke();
    }
  }

  public showSegmentationAnalysis(src) {
    if (!src) return;
    const newIm = new Image();
    newIm.src = src;
    newIm.addEventListener("load", (e) => {
      this.ctx.drawImage(newIm, 0, 0);
    });
  }

  public saveMaskOnLocalStorage(activeFindingId) {
    const findingId = activeFindingId;
    const dataURL = this.ctx.canvas.toDataURL("image/png");
    localStorage.setItem(`localMask/${findingId}`, dataURL);
  }

  public clearCanvas() {
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
  }
}
