import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@telespot/web-core';
import { Query, Resource } from '@telespot/sdk';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ts-resource-editor-page',
  templateUrl: './resource-editor-page.component.html',
  styleUrls: ['./resource-editor-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceEditorPageComponent implements OnDestroy {
  private _destroy$ = new Subject<void>();
  resource$: Observable<Resource> = this._route.paramMap.pipe(
    takeUntil(this._destroy$),
    map((params) => params.get('resourceId')),
    switchMap((id) => (id ? this._dataService.get(id, new Query(Resource).include('resources')) : of(new Resource())))
  );

  constructor(private _route: ActivatedRoute, private _dataService: DataService, private _location: Location) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  back() {
    this._location.back();
  }
}
