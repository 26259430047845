<ng-container *ngIf="connectedDropLists$ | async as connectedDropLists">
  <ts-table-view
    cdkDropListGroup
    [locationRow]="locationRow"
    [paddingNodes]="paddingNodes"
    [headerTemplate]="headerTemplate"
    [rowNumeralTemplate]="rowNumeralTemplate"
    [rowTemplate]="rowTemplate"
    [rowHeight]="cellSize + cellGap"
    [sections]="cellsPerSection.length"
    [rowsPerSection]="rowsPerSection"
    [scrollThreshold]="scrollThreshold"
    (scrollThresholdReached)="scrollThresholdReached.emit()"
    (bottomReached)="bottomReached.emit()"
  >
    <ng-template #rowTemplate let-index>
      <div
        class="row"
        cdkDropList
        cdkDropListSortingDisabled="true"
        cdkDropListOrientation="horizontal"
        [style.gap]="cellGap + 'px'"
        [style.padding]="cellGap / 2 + 'px'"
        [id]="getDropListId(index)"
        [cdkDropListConnectedTo]="connectedDropLists"
        (cdkDropListDropped)="move($event)"
      >
        <ng-container
          *ngFor="
            let item of cellIndexes;
            index as column;
            trackBy: trackByIndex
          "
        >
          <div
            cdkDrag
            [style.height]="cellSize + 'px'"
            [style.width]="cellSize + 'px'"
            [cdkDragDisabled]="draggingDisabled"
            [cdkDragData]="{
              section: index.section,
              row: index.row,
              column
            }"
            (cdkDragStarted)="cellDraggingStarted.emit($event.source.data)"
          >
            <ng-container
              *ngTemplateOutlet="
                cellTemplate;
                context: {
                  $implicit: {
                    section: index.section,
                    row: index.row,
                    column
                  }
                }
              "
            ></ng-container>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </ts-table-view>
</ng-container>
