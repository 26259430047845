import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgModule,
  Optional,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ISecondOpinion } from "@telespot/analysis-refactor/data-access";

interface SecondOpinionReportsDialogData {
  secondOpinions: ISecondOpinion[];
  caseName: string;
}

@Component({
  selector: "ts-second-opinion-reports-dialog",
  templateUrl: "./second-opinion-reports-dialog.component.html",
  styleUrls: ["./second-opinion-reports-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecondOpinionReportsDialogComponent {
  readonly secondOpinions: ISecondOpinion[];
  readonly caseName: string;

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<SecondOpinionReportsDialogComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: SecondOpinionReportsDialogData
  ) {
    this.secondOpinions = this.data?.secondOpinions ?? [];
    this.caseName = this.data?.caseName;
  }

  public close() {
    this.dialogRef.close();
  }
}

@NgModule({
  declarations: [SecondOpinionReportsDialogComponent],
  imports: [CommonModule, RouterModule, MatDialogModule, TranslateModule],
  exports: [SecondOpinionReportsDialogComponent],
  providers: [],
})
export class SecondOpinionReportsDialogModule {}
