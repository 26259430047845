import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { DeviceEditorComponent } from "./components/device-editor/device-editor.component";
import { DevicesComponent } from "./components/devices/devices.component";

const routes: Routes = [
  {
    path: "",
    component: DevicesComponent,
    data: { title: "Devices" },
  },
  {
    path: "new",
    component: DeviceEditorComponent,
    data: { title: "New Device" },
  },
  {
    path: "edit/:deviceid",
    component: DeviceEditorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DevicesRoutingModule {}
