import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DataService } from "@telespot/web-core";
import { Resource } from "@telespot/sdk";
import { Subject } from "rxjs";
import { take, takeUntil, tap } from "rxjs/operators";
import {
  DataTableConfig,
  SplDataSource,
  SplDataSourceConfig,
} from "@shared/ui";

import { ResourceEditorDialogComponent } from "../resource-editor-dialog/resource-editor-dialog.component";

import { ResourcesRowData } from "./resources-row-data";
import { DataFilterComponent, IColumn } from "@shared/utils";

interface FindResourcesRequest {
  sorts: any;
  filters: any;
  skip: number;
  limit: number;
}

@Component({
  selector: "ts-resources-assignment",
  templateUrl: "./resources-assignment.component.html",
  styleUrls: ["./resources-assignment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesAssignmentComponent
  extends DataFilterComponent
  implements OnInit, OnDestroy, SplDataSourceConfig<ResourcesRowData>
{
  private _destroy$ = new Subject<void>();

  public tableConfig: DataTableConfig<ResourcesRowData>;

  // I/O
  private _resources: Resource[];
  @Input()
  set resources(value: Resource[]) {
    this._resources = value;
  }
  get resources(): Resource[] {
    return this._resources || [];
  }
  @Output() resourcesChanged = new EventEmitter<Resource[]>();

  // selectedResources: string[] = [];
  selectedResourceIds: string[] = [];

  resource: Resource; // resource passed to the editor
  private _dialogRef: MatDialogRef<any>;

  public previousRequest: FindResourcesRequest;
  public previousCount = 0;
  private resourcesCreated: string[] = [];
  private resourceToInclude = false;

  public dataSource = new SplDataSource<ResourcesRowData>(this);
  public oldAvailable: Resource[];
  public tableName = "available_resources";
  public klass = Resource.className;
  public columns: IColumn[] = [
    {
      name: this.tableName,
      value: (item) => item,
    },
  ];

  constructor(public dataService: DataService, private dialog: MatDialog) {
    super(dataService);
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  create() {
    this.edit(new Resource());
  }

  edit(resource: Resource) {
    this.resource = resource;
    this._dialogRef =
      this._dialogRef ||
      this.dialog.open(ResourceEditorDialogComponent, {
        width: "800px",
        hasBackdrop: true,
        data: resource,
      });
    this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe((changedResource) => {
        this.resourcesCreated.push(resource.id);
        this.addResource(changedResource);
      });
  }

  async addResource(item) {
    if (item) {
      const resource = Resource.fromJSON({
        ...item,
        className: "Resource",
        objectId: item._id,
      }) as Resource;

      this.selectedResourceIds.push(resource.id);
      this.dataSource.fetch();
      const filteredRes = this.resources.filter(
        (res) => res.id === resource.id
      );
      if (filteredRes.length === 0) {
        this.resources.push(resource);
      }
      this.resourcesChanged.emit(this.resources);
    }
  }

  removeResource(resource: Resource) {
    if (this.resourcesCreated.includes(resource.id)) {
      this.resourcesCreated = this.resourcesCreated.filter(
        (id) => id !== resource.id
      );
      this.resourceToInclude = true;
    }
    this.resources = this.resources.filter((at) => at !== resource);
    this.selectedResourceIds = this.selectedResourceIds.filter(
      (id) => id !== resource.id
    );
    this.dataSource.fetch();
    this.resourcesChanged.emit(this.resources);
  }
}
