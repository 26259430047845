import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@telespot/web-core';
import { Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root',
})
export class WsService implements OnDestroy {
  private ws: WebSocketSubject<unknown> | undefined = undefined;
  public connected$ = new Subject<boolean>();

  constructor(private _authService: AuthService) {}

  public connect(url: string): WebSocketSubject<unknown> {
    if (!this.ws) {
      this.ws = webSocket({
        url: `${url}?token=${this._authService.sessionToken}`,
        openObserver: {
          next: (e) => this.connected$.next(true),
        },
        closeObserver: {
          next: (e) => {
            this.ws = undefined;
            this.connected$.next(false);
          },
        },
      });
    }
    return this.ws;
  }

  public closeConnection(): void {
    this.ws?.complete();
  }

  ngOnDestroy() {
    this.ws?.complete();
  }
}
