<ng-container *ngIf="form">
  <div class="scroll-container">
    <form [formGroup]="form" (submit)="submit()">
      <mat-label class="field-label">{{
        "label.option_name" | translate | uppercase
      }}</mat-label>
      <ts-language-input
        [languages]="supportedLanguages"
        formControlName="name"
      ></ts-language-input>

      <mat-label class="field-label">{{
        "label.roi_color" | translate | uppercase
      }}</mat-label>
      <ts-color-picker formControlName="color"></ts-color-picker>

      <mat-label class="field-label">{{
        "label.transparency" | translate | uppercase
      }}</mat-label>

      <mat-slider
        formControlName="alpha"
        [min]="0"
        [max]="1"
        [step]="0.01"
        thumbLabel
        tickInterval="0.1"
        (input)="getColorWithAlpha()"
      ></mat-slider>
      <ng-container>
        <mat-form-field class="full-width">
          <input
            matInput
            type="number"
            step="0.01"
            placeholder="{{
              'label.threshold_display' | translate | uppercase
            }}"
            formControlName="thresholdDisplay"
          />
        </mat-form-field>

        <mat-error
          *ngIf="
            form.get('thresholdDisplay')?.invalid
          "
          >{{ "label.invalid_threshold" | translate }}</mat-error
        >
      </ng-container>
    </form>

    <div class="flex-column align-items-center">
      <button
        class="btn btn-outline-primary"
        (click)="cancel()"
        style="margin: 4px"
      >
        <i class="ri-close-fill"></i>
        {{ "button.cancel" | translate }}
      </button>
      <button
        class="btn btn-primary"
        [disabled]="form.invalid"
        (click)="submit()"
        style="margin: 4px"
      >
        <i class="ri-save-line"></i>
        {{ "button.save" | translate }}
      </button>
    </div>
  </div>
</ng-container>
