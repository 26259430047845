import { CommonModule } from '@angular/common';
import { Component, Inject, Input, NgModule, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { SupportedLanguage } from '../../models/supported-language';
import { SUPPORTED_LANGUAGES } from '../../providers/supported-languages.token';

@Component({
  selector: 'ts-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  public selectedLanguage: SupportedLanguage;

  @Input() showLabel = false;

  constructor(
    private translateService: TranslateService,
    @Inject(SUPPORTED_LANGUAGES) public languages: SupportedLanguage[]
  ) {}

  ngOnInit() {
    const lang = localStorage.getItem('user_language') || this.translateService.currentLang;
    this.selectedLanguage = this.languages.find((l) => l.value === lang);
  }

  selectLanguage(languageItem: SupportedLanguage) {
    localStorage.setItem('user_language', languageItem.value);
    this.selectedLanguage = languageItem;
    this.translateService.use(languageItem.value);
  }
}

@NgModule({
  imports: [CommonModule, MatMenuModule, TranslateModule, MatTooltipModule, MatButtonModule],
  declarations: [LanguageSelectorComponent],
  exports: [LanguageSelectorComponent],
})
export class LanguageSelectorModule {}
