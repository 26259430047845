<canvas
  #canvas
  id="canvas"
  class="color-palette"
  width="250"
  height="250"
  (mousedown)="onMouseDown($event)"
  (mousemove)="onMouseMove($event)"
  (mouseup)="onMouseUp($event)"
>
</canvas>
