import { Entity, SoftDeletable } from "./entity";

export enum CaseTypeErrors {
  METHOD_NOT_IN_CASE_TYPE = "Case type does not contain the specified method type",
  METHOD_ALREADY_IN_CASE_TYPE = "Case type already contains the specified method type",
}

export interface CaseTypeField {
  type: string;
  name: string;
  details: { display_name: string };
  encrypted: boolean;
}

export interface CaseTypeCreationAttributes extends Entity, SoftDeletable {
  name: string;
  fields: CaseTypeField[];
  methodTypeIds: string[];
}

export class CaseType {
  constructor(private attributes: CaseTypeCreationAttributes) { }

  public get id(): string {
    return this.attributes.id;
  }

  public get name(): string {
    return this.attributes.name;
  }

  public get methodTypeIds(): ReadonlyArray<string> {
    return this.attributes.methodTypeIds ?? [];
  }

  public get createdAt(): Date {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date {
    return this.attributes.updatedAt;
  }

  public get deletedAt(): Date {
    return this.attributes.deletedAt;
  }

  public addMethod(methodTypeId: string): CaseType {
    if (this.attributes.methodTypeIds.includes(methodTypeId))
      throw new Error(CaseTypeErrors.METHOD_ALREADY_IN_CASE_TYPE);

    this.attributes.methodTypeIds.push(methodTypeId);

    return this
  }

  public removeMethod(methodTypeId: string): CaseType {
    const index = this.attributes.methodTypeIds.indexOf(methodTypeId);

    if (index < 0) throw new Error(CaseTypeErrors.METHOD_NOT_IN_CASE_TYPE);

    this.attributes.methodTypeIds.splice(index, 1);

    return this
  }

  get fields(): CaseTypeField[] {
    return this.attributes.fields;
  }

  public get hasAIModels(): boolean {
    throw new Error("Not implemented yet!");
  }

  public get hasUnregulatedAIModels(): boolean {
    return this.unregulatedAIModels.length > 0;
  }

  public get hasRegulatedAIModels(): boolean {
    return this.regulatedAIModels.length > 0;
  }

  public get unregulatedAIModels(): Array<any> {
    throw new Error("Not implemented yet!");
  }

  public get regulatedAIModels(): Array<any> {
    throw new Error("Not implemented yet!");
  }
}
