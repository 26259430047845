import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, DataService } from '@telespot/web-core';
import { CloudFunctions, Member, Organization, Query, Role, User, Workspace } from '@telespot/sdk';
import { LoggerService } from '@telespot/shared/logger/feature/util';
import { BehaviorSubject, defer, Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

interface IRoleData {
  role: Role;
  active: boolean;
  enabled: boolean;
}

@Component({
  selector: 'ts-user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.scss'],
})
export class UserEditorComponent implements OnInit, OnDestroy {
  @Input() user: User;

  @ViewChild('savingTemplate') savingDialogTemplate: TemplateRef<any>;

  roles: Role[];

  organization: Organization;
  creatingUser: boolean;

  workspaces$: Observable<Workspace[]> = defer(() =>
    this.dataService.find(new Query(Workspace).equalTo('organization', this.authService.currentOrganizationValue))
  );
  private _workspaces: Workspace[] = []; // temp fix for matchesQuery
  private memberships: Member[];

  userForm: UntypedFormGroup;

  registerWithoutEmail = false;

  private _activeRoles$ = new BehaviorSubject<IRoleData[]>([]);
  public activeRoles$ = this._activeRoles$.asObservable();
  public demotions$ = this.activeRoles$.pipe(map((roles) => roles.some((role) => !role.active && !role.enabled)));

  formErrors = {
    no_role: false,
  };

  private _destroy$ = new Subject<void>();

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private _dialog: MatDialog,
    private _logger: LoggerService
  ) {
    this.workspaces$.pipe(take(1), takeUntil(this._destroy$)).subscribe((workspaces) => (this._workspaces = workspaces));
  }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this._destroy$)).subscribe(async (route) => {
      const id = route.get('id');
      if (id) {
        this.user = await this.dataService.get(id, new Query(User));
        this.creatingUser = false;
      } else {
        this.creatingUser = true;
        this.user = new User();
        this.user.username = ``;
        this.user.email = ``;
      }
      this.organization = this.authService.currentOrganizationValue;
      await this.getRoles();
      await this.getMemberships();
      this._buildForm();
    });
  }

  private _buildForm() {
    this.userForm = new UntypedFormGroup({
      email: new UntypedFormControl(this.user.get('email'), Validators.email),
      password: new UntypedFormControl(
        (Math.random() * 1000000000).toString(),
        this.user.isNew() ? Validators.required : undefined
      ),
      roles: new UntypedFormControl(this.user.roles, undefined, (control) => {
        return Promise.resolve(control.value.length > 0 ? null : { error: 'info.specify_some_roles' });
      }),
      username: new UntypedFormControl({ value: this.user.username, disabled: true }),
      workspaces: new UntypedFormControl((this.memberships || []).map((m) => m.workspace)),
    });
    // alert(this.user.email)
    if (!this.user.isNew()) this.userForm.controls.email.disable();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  async getRoles(): Promise<void> {
    // TODO: restore ability to select technicianmanager role after permissions review
    const hiddenRoles =
      !this.authService.currentUser || !this.authService.currentUser.isAdmin
        ? ['admin', 'analystmanager', 'technicianmanager']
        : [];
    this.roles = (await this.dataService.find(new Query(Role).ascending('name'))).filter(
      (role) => !hiddenRoles.includes(role.get('name'))
    );
    if (!this.user.isNew()) {
      this.user.roles = await this.dataService.find(new Query(Role).containedIn('users', [this.user]));
    }
    this._activeRoles$.next(
      this.roles.map((r) => {
        return {
          role: r,
          active: this.user.roles?.findIndex((ur) => ur.id === r.id) !== -1,
          enabled: !(this.user.roles.findIndex((ur) => ur.id === r.id) !== -1),
        };
      })
    );
  }

  async getMemberships(): Promise<void> {
    if (!this.user || this.user.isNew()) {
      this.memberships = [];
      return;
    }
    const orgQuery = new Query(Workspace).equalTo('organization', this.authService.currentOrganizationValue.toPointer());
    const memberQuery = new Query(Member).equalTo('user', this.user.toPointer()).include(['workspace.organization']);
    this.memberships = await this.dataService.find(memberQuery.matchesQuery('workspace', orgQuery));
  }

  get rolesControl() {
    return this.userForm?.get('roles');
  }

  public updateEmail(email: string) {
    if (!this.registerWithoutEmail) {
      this.userForm.patchValue({
        username: email,
      });
      this.userForm.get('username').disable();
    }
  }

  async save() {
    const message$ = new Subject<string>();

    if (!this.userForm.controls['roles'].value?.length) {
      this.formErrors.no_role = true;
      return;
    }
    const dialog = this._dialog.open(this.savingDialogTemplate, {
      hasBackdrop: true,
      data: {
        message$,
      },
    });
    try {
      const isNewUser = this.user.isNew();
      // Save user
      if (isNewUser) {
        if (!this.registerWithoutEmail) {
          this.user.email = this.userForm.value.email;
        } else {
          this.user.email = undefined;
        }
        this.userForm.get('username').enable();
        this.user.username = this.userForm.value.username.trimEnd().trimStart();
        this.user.password = this.userForm.value.password;
      }

      // Assign organization
      if (isNewUser) {
        message$.next(`dialog.updating_user_affiliate`);

        const savedAffiliate = await CloudFunctions.InviteUser(this.user, this.organization);
        this.user = savedAffiliate.user;
      }

      // Assign roles
      const roleUpdates = this.roles
        .filter((role) => this.userForm.value.roles.includes(role))
        .map((role) => {
          role.relation('users').add(this.user);
          return role.save();
        });
      try {
        !!roleUpdates.length && message$.next(`dialog.updating_user_roles`);
        await Promise.all(roleUpdates);
      } catch (err) {
        this._logger.error(`Error updating roles: ${err.message}`);
      }
      // Assign memberships
      const selectedWorkspaces = this.userForm.value.workspaces as Workspace[];
      const removedMemberships = this.memberships
        .filter((membership) => selectedWorkspaces.findIndex((w) => membership.workspace.id === w.id) === -1)
        .filter(
          // TODO: this is just a fix because matchesQuery is not filtering the proper memberships
          (member) => member?.workspace?.organization?.id === this.authService.currentOrganizationValue?.id
        );
      const addedMemberships = selectedWorkspaces
        .filter((workspace) => this.memberships.findIndex((membership) => workspace.id === membership.workspace.id) === -1)
        .map((workspace) => {
          const member = new Member();
          member.user = this.user;
          member.workspace = workspace;
          return member;
        });
      (!!removedMemberships.length || !!addedMemberships.length) && message$.next(`dialog.updating_user_memberships`);
      removedMemberships.length && (await this.dataService.delete(removedMemberships));
      addedMemberships.length && (await this.dataService.save(addedMemberships));
      dialog.close();
      this.router.navigateByUrl('users');
    } catch (error) {
      this.userForm.setErrors({
        responseError: error.message,
      });
      message$.next(`Error: ${error.message}`);
      setTimeout(() => {
        dialog.close();
      }, 200);
      throw error;
    }
  }

  cancel(): void {
    this.user.revert();
    this.router.navigateByUrl('users');
  }

  toggleRole(selectedRole: IRoleData) {
    const selectedRoles = this._activeRoles$.value.map((role) => ({
      ...role,
      active: selectedRole.role.id === role.role.id ? !selectedRole.active : role.active,
    }));

    this._activeRoles$.next(selectedRoles);
    this.userForm.patchValue({
      roles: selectedRoles.filter((roleStatus) => roleStatus.active).map((roleStatus) => roleStatus.role),
    });
    this.userForm.markAsTouched();
  }

  compareFcn(a1, a2) {
    return a1?.id === a2?.id;
  }

  public updateRegisterWithoutEmail(value): void {
    if (value) {
      this.userForm.get('username').enable();
    } else {
      this.userForm.get('username').disable();
    }
  }

  public toggleAllWorkspaces(event: MatCheckboxChange, allWorkspaces: Workspace[]) {
    const workspaces = event.checked ? allWorkspaces : [];
    this.userForm.patchValue({ workspaces });
  }

  public get selectedWorkspacesCount(): number {
    return this.userForm.controls['workspaces'].value?.length;
  }
}
