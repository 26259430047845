<div class="card">
  <table mat-table [dataSource]="dataSource" class="spotlab-table" matSort>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'core.type' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item">
        <i [class.ri-image-2-line]="item.assetType.type === 'image'" [class.ri-film-line]="item.assetType.type === 'video'">
        </i>
      </td>
    </ng-container>
    <ng-container matColumnDef="assetType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'Name' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item">
        {{ item.assetType.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="usedByCaseTypes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'core.casetypes' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item">
        <div class="flex-row align-items-center justify-left">
          <i class="ri-article-line text-gray" [style.margin-right.px]="8"></i>
          {{ (item.usedBy.caseTypes$ | async) || 0 }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="usedByMethodTypes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'core.method_types' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item">
        <div class="flex-row align-items-center justify-left">
          <i class="ri-archive-line text-gray" [style.margin-right.px]="8"></i>
          {{ (item.usedBy.methodTypes$ | async) || 0 }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="compatibleDevices">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'core.compatible_devices' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item">
        <div class="flex-row align-items-center justify-left">
          <i class="ri-device-line text-gray" [style.margin-right.px]="8"></i>
          {{ item.assetType.deviceTypes?.length || 0 }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="_actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'core.actions' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let item" (click)="$event.stopPropagation()">
        <button mat-icon-button [matMenuTriggerFor]="menu" data-cy="button-menu">
          <i class="ri-more-line"></i>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="delete(item)" class="flex-row align-items-center" data-cy="button-menu-delete">
            <i class="ri-delete-bin-6-line"></i>
            {{ 'core.delete' | translate }}
          </button>
        </mat-menu>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns$ | async"></tr>
    <tr mat-row *matRowDef="let item; columns: visibleColumns$ | async" [routerLink]="[item.assetType.id]"></tr>
  </table>
</div>
