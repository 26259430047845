import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '@telespot/web-core';
import { environment } from '@telespot/shared/environment';
import { map } from 'rxjs/operators';

import { NAV_BAR_ITEMS } from '../../providers/nab-bar-items.token';

// TODO: move language selection logic elsewhere
@Component({
  selector: 'ts-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarComponent {
  readonly useIcons: boolean = true;

  @Input() showLogo: boolean;
  @Input() isDevVersion = false;
  @Input() sidenav: MatSidenav;
  public mobile$ = this._breakpoint.observe([Breakpoints.HandsetPortrait, Breakpoints.Small]).pipe(map((v) => v.matches));

  public readonly version = environment.VERSION;
  public readonly currentUser$ = this.authService.currentUser$;
  public readonly currentOrganization$ = this.authService.currentOrganization$;

  constructor(
    private authService: AuthService,
    private _breakpoint: BreakpointObserver,
    @Inject(NAV_BAR_ITEMS) public links
  ) {}
}
