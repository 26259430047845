import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  Context,
  assetDataSynced,
  createAnalysis,
  preSyncAnalysis,
  setContext,
  updateAnalysis,
} from "@telespot/analysis-refactor/data-access";

import { AnalysisPanelDirective } from "../../directives/analysis-panel";

interface AnalysisCreationParameters {
  pipelineId: string;
  sampleId: string;
  assetId?: string;
  createdBy: Parse.Pointer;
}

interface FindingUpdateParameters {
  id?: string;
  key: string;
  value: string;
  taskId: string;
}

@Component({
  selector: "ts-sample-analysis-panel",
  templateUrl: "./sample-analysis-panel.component.html",
  styleUrls: ["./sample-analysis-panel.component.scss"],
})
export class SampleAnalysisPanelComponent extends AnalysisPanelDirective {
  public expanded = true;
  readonly acquisitionDataEditable = this.store.select(assetDataSynced);

  constructor(protected store: Store) {
    super(store);
  }

  toggleSample(viewSample: boolean = true, currentContext: string) {
    if (viewSample === (currentContext === Context.SAMPLE)) {
      // Just collapse / expand the panel
      this.expanded = !this.expanded;
    } else {
      // Change analysis context
      this.store.dispatch(
        setContext({ context: viewSample ? Context.SAMPLE : Context.ASSET })
      );
      this.expanded = true;
    }
  }

  saveAnalysis() {
    this.store.dispatch(preSyncAnalysis());
  }

  createAnalysis(
    params: AnalysisCreationParameters,
    shouldCreateAnalysis: boolean,
    data?: any
  ) {
    if (shouldCreateAnalysis)
      this.store.dispatch(createAnalysis({ ...params, data }));
  }

  updateAnalysis(findings: any[]) {
    this.store.dispatch(updateAnalysis({ findings }));
  }

  hasAcquisitionData(data: any) {
    return Object.keys(data).length !== 0;
  }
}
