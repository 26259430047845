import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { GridViewComponent } from "./grid-view.component";
import { TableViewModule } from "../table-view";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [GridViewComponent],
  imports: [CommonModule, DragDropModule, TableViewModule],
  exports: [GridViewComponent],
})
export class GridViewModule { }
