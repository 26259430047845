<div class="images-container">
  <div class="small-images">
    <div style="margin-bottom: 10px" class="images-container">
      <div>
        {{ imageFormat ? ('title.images_required' | translate) : ('title.videos_required' | translate | uppercase) }} ({{
          totalAssets$ | async
        }})
      </div>
      <div>
        {{ imageFormat ? ('title.all_images' | translate) : ('title.all_videos' | translate) }}
        <mat-slide-toggle (change)="toggleAllImages($event)" data-cy="all-assets-toggle"></mat-slide-toggle>
      </div>
    </div>
    <div class="assets-container">
      <div
        class="assets"
        *ngFor="let currAsset of assets; let index = index"
        (click)="setAsset(index)"
        [class.selected]="currAsset.selected"
        [class.index]="currAsset.index === (selectedAssetIndex$ | async)"
        [attr.data-cy]="'asset-' + (index + 1)"
      >
        <p class="index-container small">{{ index + 1 }}</p>
      </div>
    </div>
  </div>
  <div class="single-image">
    <div>
      <p class="index-container">{{ asset?.index + 1 }}</p>
    </div>
    <img [src]="asset?.thumbnail | preprocess | async" alt="asset thumbnail" />
  </div>
</div>
