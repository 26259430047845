import { CommonModule } from '@angular/common';
import { Directive, EventEmitter, HostBinding, HostListener, Input, NgModule, Output } from '@angular/core';

@Directive({
  selector: '[tsFileDrop]',
  exportAs: 'tsFileDrop',
})
export class FileDropDirective {
  @Output() filesDropped = new EventEmitter<File[]>();

  @Input() allowedExtensions: string[];

  @HostBinding('class.file-hover') private fileHover = false;

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileHover = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileHover = false;
  }

  @HostListener('dragexit', ['$event']) onDragExit(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileHover = false;
  }

  @HostListener('drop', ['$event']) public async ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileHover = false;

    const filePromises: Promise<File>[] = [];
    const directoryReadPromises: Promise<Promise<File>[]>[] = [];
    for (let i = 0; i < evt.dataTransfer.items.length; i++) {
      const entry =
        evt.dataTransfer.items[i].webkitGetAsEntry() ??
        evt.dataTransfer.items[i].getAsEntry?.() ??
        evt.dataTransfer.items[i]?.getAsFile();
      if (entry.isFile) {
        filePromises.push(new Promise((resolve, reject) => entry.file(resolve, reject)));
      } else if (entry instanceof File) {
        filePromises.push(Promise.resolve(entry));
      } else if (entry.isDirectory) {
        try {
          directoryReadPromises.push(this._readDirectory(entry));
        } catch (err) {
          throw `Directory failed or is not supported by your browser: ${err}`;
        }
      }
    }

    for (const directoryReadOp of directoryReadPromises) {
      const directoryFiles = await directoryReadOp;
      filePromises.push(...directoryFiles);
    }
    const files: File[] = await Promise.all(filePromises);
    this.processFiles(files);
  }

  private async _readDirectory(entry): Promise<Promise<File>[]> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (dirResolve, dirReject) => {
      const directoryReader = entry.createReader();
      const dirFiles: Promise<File>[] = [];
      let pendingFiles = true;
      while (pendingFiles) {
        const batchFiles: Promise<File>[] = await new Promise((dirBatchResolve, dirBatchReject) => {
          // Each batch reads up to 100 items
          directoryReader.readEntries((entries) => {
            dirBatchResolve(
              entries
                .filter((subEntry) => subEntry.isFile)
                .map((subEntry) => new Promise((fileResolve, fileReject) => subEntry.file(fileResolve, fileReject)))
            );
          });
        });
        if (!batchFiles.length) {
          pendingFiles = false;
          break;
        }
        dirFiles.push(...batchFiles);
      }
      dirResolve(dirFiles);
    });
  }

  public processFiles(files: File[] | FileList): void {
    files = files instanceof FileList ? Array.from(files) : files;
    // Filter by allowed extensions
    if (this.allowedExtensions?.length) {
      const filteredFiles = files.filter((file) =>
        this.allowedExtensions.some((ext) => new RegExp(`${ext}$`, 'i').test(file.name.toString()))
      );
      if (filteredFiles.length < files.length) {
        console.warn(
          `Some files were filtered out due to wrong extensions. Only ${this.allowedExtensions.join(',')} files are allowed`
        );
      }
      files = filteredFiles;
    }

    this.filesDropped.emit(files.sort((f1, f2) => f1.name.localeCompare(f2.name)));
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [FileDropDirective],
  exports: [FileDropDirective],
})
export class FileDropDirectiveModule { }
