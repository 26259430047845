<ng-container *ngIf="case && caseForm; else noCase">
  <form [formGroup]="caseForm" #caseFormRef *ngIf="caseForm">
    <ng-container *ngFor="let field of caseFields">
      <div [ngSwitch]="field.type" style="margin-bottom: 20px">
        <ng-container *ngSwitchCase="'sectionHeader'">
          <h3>{{ field.displayName }}</h3>
        </ng-container>

        <ng-container *ngSwitchCase="'label'">
          <h4><i class="ri-font-color"></i>{{ field.displayName }}</h4>
        </ng-container>

        <ng-container *ngSwitchCase="'location'">
          <mat-label>{{ field.displayName }}</mat-label>
          <div>
            <button
              class="btn btn-outline-primary"
              (click)="getLocation(field)"
            >
              <i class="ri-compass-line"></i>
              {{ "label.get_location" | translate }}
            </button>
            <button class="btn btn-outline-primary" disabled>
              <i class="ri-map-pin-5-line"></i>
              {{ "button.map" | translate }}
            </button>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'number'">
          <mat-form-field class="full-width" floatLabel="never">
            <mat-label>{{ field.displayName }}</mat-label>
            <input
              matInput
              type="number"
              [id]="field.name"
              [formControlName]="field.name"
              [placeholder]="field.details.placeholder || '0'"
              [errorStateMatcher]="formErrorMatcher"
            />
            <mat-error>{{ "core.required" | translate }}</mat-error>
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="'case_name'">
          <mat-form-field class="full-width" floatLabel="never">
            <mat-label>{{ field.displayName }}</mat-label>
            <input
              matInput
              type="text"
              [id]="field.name"
              [formControlName]="field.name"
              [placeholder]="
                field.details.placeholder || 'patient or case identifier'
              "
              [errorStateMatcher]="formErrorMatcher"
            />
            <mat-error>{{ "core.required" | translate }}</mat-error>
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="'switcher'">
          <mat-label>{{ field.displayName }}</mat-label>
          <mat-error *ngIf="isFieldInvalid(field.name)">{{
            "core.required" | translate
          }}</mat-error>
          <mat-radio-group
            [id]="field.name"
            [formControlName]="field.name"
            class="flex-column"
          >
            <mat-radio-button
              *ngFor="let option of field.options"
              [value]="option"
            >
              {{ option }}
            </mat-radio-button>
          </mat-radio-group>
        </ng-container>

        <ng-container *ngSwitchCase="'date'">
          <mat-form-field class="full-width">
            <mat-label>{{ field.displayName }}</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [id]="field.name"
              [formControlName]="field.name"
              [errorStateMatcher]="formErrorMatcher"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error>{{ "core.required" | translate }}</mat-error>
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="'textedit'">
          <ng-template *ngIf="field.details.multiline; else simpleInput">
            <mat-form-field class="full-width" floatLabel="never">
              <mat-label>{{ field.displayName }}</mat-label>
              <textarea
                matInput
                type="text"
                [id]="field.name"
                [formControlName]="field.name"
                [placeholder]="field.details.placeholder || '[...]'"
                [errorStateMatcher]="formErrorMatcher"
              ></textarea>
              <mat-error>{{ "core.required" | translate }}</mat-error>
            </mat-form-field>
          </ng-template>
          <ng-template #simpleInput>
            <mat-form-field class="full-width" floatLabel="never">
              <mat-label>{{ field.displayName }}</mat-label>
              <input
                matInput
                type="text"
                [id]="field.name"
                [formControlName]="field.name"
                [placeholder]="field.details.placeholder || '[...]'"
                [errorStateMatcher]="formErrorMatcher"
              />
              <mat-error>{{ "core.required" | translate }}</mat-error>
            </mat-form-field>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'selection'">
          <mat-form-field class="full-width" floatLabel="never">
            <mat-label>{{ field.displayName }}</mat-label>
            <mat-error>{{ "core.required" | translate }}</mat-error>
            <mat-select
              [id]="field.name"
              [formControlName]="field.name"
              [multiple]="field.details.multiple"
              [placeholder]="
                field.details.placeholder || ('Select' | translate)
              "
              [errorStateMatcher]="formErrorMatcher"
            >
              <mat-option *ngFor="let option of field.options" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="'boolean'">
          <mat-checkbox [id]="field.name" [formControlName]="field.name">
            {{ field.displayName }}
          </mat-checkbox>
          <mat-error *ngIf="isFieldInvalid(field.name)">{{
            "core.required" | translate
          }}</mat-error>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div class="text-danger">
            <i class="ri-alert-line"></i>
            Invalid form type {{ field.name }}
          </div>
        </ng-container>
      </div>
    </ng-container>
  </form>
</ng-container>
<ng-template #noCase> No case </ng-template>
