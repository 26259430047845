import {
  Label,
  LabelConfig,
  MethodType,
  Pipeline,
  PipelineStep,
  PipelineTask,
  StepTask,
} from "@telespot/sdk";
import { PipelineEntity } from "../models/pipeline.entity";

export class ProtocolMapper {
  public static toPipelineEntity(
    pipeline: Pipeline,
    steps: PipelineStep[] = [],
    labels: Label[],
    labelConfig?: LabelConfig[]
  ) {
    const tasks: PipelineTask[] = [];
    steps.forEach((step) => {
      const stepCategories = step.params?.categorization ?? [];

      stepCategories.forEach((sc) => {
        const taskLabel = labels.find((l) => l.uuid === sc.category);

        tasks.push({
          id: step.id,
          name: {
            name: taskLabel?.value,
            uuid: sc.category,
            value: { [taskLabel?.lang]: taskLabel.value },
            new: false,
          },
          type: step.task,
          allowNewOptions: step?.params?.allowNewOptions ?? false,
          enableMosaicView: step?.params?.enableMosaicView ?? false,
          trigger: step.trigger,
          generateFinding: step.generateFinding,
          executor: step?.executor,
          multiple: step?.params?.multiple ?? false,
          counter: step?.params?.counter ?? false,
          ...(step.task !== StepTask.TEXT &&
            step.task !== StepTask.OCR && {
              options: ProtocolMapper.getTaskOptions(
                step.params.options,
                sc.options,
                labels,
                labelConfig
              ),
            }),
        });
      });
    });

    return new PipelineEntity({
      id: pipeline.id,
      name: pipeline.name,
      tasks,
      env: pipeline.env ?? "cloud",
    });
  }

  public static toPipelineSteps(pipeline: PipelineEntity): PipelineStep[] {
    const pipelineSteps: PipelineStep[] = [];
    const isCloudPipeline = pipeline.env === "cloud";

    pipeline.tasks.forEach((t, i) => {
      const existingStepTask = pipelineSteps.find(
        (s) => s.task === t.type && ProtocolMapper.taskIsRelatedToTagging(t)
      );

      if (existingStepTask) {
        existingStepTask.params = {
          ...existingStepTask.params,
          options: {
            ...existingStepTask.params.options,
            ...(t.options || []).reduce((acc, o) => {
              acc = {
                ...acc,
                [o.uuid]: { thrdisp: o?.thrdis ?? 0 },
              };
              return acc;
            }, {}),
          },
          categorization: [
            ...existingStepTask.params.categorization,
            {
              category: t.name?.uuid,
              options: (t.options || []).map((o) => o?.uuid),
            },
          ],
          multiple: t?.multiple,
          allowNewOptions: t.allowNewOptions,
          enableMosaicView: t.enableMosaicView,
        };
        return;
      }
      const pipelineStep =
        t.id && t.id.includes("new:") === false
          ? (PipelineStep.createWithoutData(t.id) as PipelineStep)
          : new PipelineStep();

      pipelineStep.task = t.type;

      const params = {
        options: {
          ...(t.options || []).reduce((acc, o) => {
            acc = {
              ...acc,
              [o.uuid]: { thrdisp: o?.thrdis ?? 0 },
            };
            return acc;
          }, {}),
        },
        categorization: [
          {
            category: t.name?.uuid,
            options: (t.options || []).map((o) => o?.uuid),
          },
        ],
        multiple: t.multiple,
        allowNewOptions: t.allowNewOptions,
        enableMosaicView: t.enableMosaicView,
        counter: t?.counter,
      };

      pipelineStep.params = params;
      pipelineStep.trigger = t.trigger;
      pipelineStep.generateFinding = t.generateFinding;
      pipelineStep.pipeline = Pipeline.createWithoutData(
        pipeline.id
      ) as Pipeline;

      // if (!isCloudPipeline) {
      pipelineStep.changeExecutor(t?.executor);
      // }
      pipelineSteps.push(pipelineStep);
    });

    //set all steps order to 0 for cloud pipeline
    pipelineSteps.forEach(
      (step, index) => (step.order = isCloudPipeline ? 0 : index)
    );

    return pipelineSteps;
  }

  public static getStepsToDelete(
    currSteps: PipelineStep[],
    updatedSteps: PipelineStep[]
  ) {
    return currSteps.filter(
      (s) => !updatedSteps.some((step) => step.id === s.id)
    );
  }

  public static taskIsRelatedToTagging(task: PipelineTask) {
    return (
      task.type === StepTask.POSITION || task.type === StepTask.ROIDETECTION
    );
  }

  public static getTaskOptions(
    optionsWithThresholds: any,
    options: string[],
    labels: Label[],
    labelConfig: LabelConfig[]
  ) {
    return options.reduce((acc, uuid) => {
      const label = labels.find((l) => l.uuid === uuid);
      const config = labelConfig.find((config) => config.label === uuid);

      if (label) {
        acc.push({
          name: label.value,
          uuid: label.uuid,
          color: config?.color ? `#${config?.color.slice(3)}` : "",
          alpha: config?.color
            ? parseInt(config?.color.slice(1, 3), 16) / 255
            : null,
          value: { [label.lang]: label.value },
          configId: config?.id,
          thrdis: optionsWithThresholds[uuid]?.thrdisp ?? 0,
          new: false,
        });
      }

      return acc;
    }, []);
  }

  public static getLabelUuids(steps: PipelineStep[]) {
    const uuids: Array<string> = [];
    steps.forEach((step) => {
      if (!step.params) return;
      if (step.task === StepTask.TEXT || step.task === StepTask.OCR) {
        const category = step.params?.categorization[0]?.category;
        if (category) return uuids.push(category);
      }
      return (step.params?.categorization || []).forEach((c) => {
        uuids.push(c.category, ...c.options);
      });
    });
    return uuids;
  }

  public static filterLabels(labels: Label[], language: string) {
    const filteredLabelsMap = new Map();

    labels.forEach((label) => {
      const existingLabel = filteredLabelsMap.get(label.uuid);

      if (!existingLabel) {
        filteredLabelsMap.set(label.uuid, label);
      } else if (
        label.lang === language ||
        (existingLabel.lang !== language && label.lang === "en")
      ) {
        filteredLabelsMap.set(label.uuid, label);
      }
    });

    return Array.from(filteredLabelsMap.values());
  }

  public static buildLabelConfig(
    pipeline: PipelineEntity,
    methodType: MethodType
  ): LabelConfig[] {
    return (pipeline.tasks || []).flatMap((t) => {
      return (t.options || [])
        .map((o) => {
          if (!o?.configId && !o.color) {
            return null;
          }
          const labelConfigElement = o?.configId
            ? (LabelConfig.createWithoutData(o.configId) as LabelConfig)
            : new LabelConfig();

          const hxColor = o.color;
          const alphaHex = Math.round((o.alpha ?? 0.2) * 255)
            .toString(16)
            .padStart(2, "0");
          const hxColorWithAlpha = `#${alphaHex}${hxColor.slice(1)}`;

          labelConfigElement.label = o.uuid;
          labelConfigElement.color = hxColorWithAlpha;
          labelConfigElement.methodType = methodType;
          return labelConfigElement;
        })
        .filter(Boolean);
    });
  }
}
