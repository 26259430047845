import { Readable } from "stream";

export type FileOperation = "write" | "read";

export enum FileError {
  FILE_EXISTS = "File already exists"
}

export type SignedURL = string;

export interface FileRepository {

  /**
   * Returns the working directory for the file/blob system
   */
  readonly directory: string;

  /**
   * Creates a directory if it does not exist and writes provided content to the file
   *
   * @param path location to save the file within the file system
   * @param content content to write to the file
   */
  write(path: string, content: string | Readable | Buffer): Promise<void>;

  append(path: string, content: string): Promise<void>;

  read(path: string): Readable;

  getSignedURL(path: string, operation: FileOperation): Promise<SignedURL>;

  metadata(path: string): Promise<any>;

  exists(path: string): Promise<boolean>;

  fetch(path: string): Promise<Buffer>;

  list(path: string): Promise<string[]>;

  deleteFiles(...paths: string[]): Promise<void>;

  /**
   * Deletes the provided path to a folder
   * If none is provided, deletes entire working directory
   *
   * @param path optional subpath
   */
  deleteFolder(path?: string): Promise<void>;

  /**
   * Copies the provided path to a different repository
   * If none is provided, copies entire working directory
   *
   * @param path optional subpath
   */
  copyFolder(destination: FileRepository, path?: string): Promise<void>;
}
