import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RoleBadgeModule } from '@shared/ui';
import { AuthService, TelespotModule } from '@telespot/web-core';
import { CloudFunctions } from '@telespot/sdk';
import { InvitesService } from '@telespot/users/data-access';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ts-redeem-code-dialog',
  templateUrl: './redeem-code-dialog.component.html',
  styleUrls: ['./redeem-code-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RedeemCodeDialogComponent implements OnInit, OnDestroy {
  form!: UntypedFormGroup;

  private destroy$ = new Subject<void>();

  public inviteInfo: {
    organization: string;
    initialRoles: string[];
    code: string;
  };

  constructor(
    private _authService: AuthService,
    private _dialogRef: MatDialogRef<RedeemCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { inviteCode?: string },
    private inviteService: InvitesService,
    private router: Router,
    private dialog: MatDialogRef<RedeemCodeDialogComponent>
  ) {}

  ngOnInit() {
    if (this.data.inviteCode) {
      this.inviteService.getInvite(this.data.inviteCode).then((invite) => {
        if (!invite) return;
        this.inviteInfo = {
          organization: invite.organization.name,
          initialRoles: invite.initialRoles.map((role) => role.getName()),
          code: this.data.inviteCode,
        };
      });
    }
    this.form = new UntypedFormGroup({
      code: new UntypedFormControl(this.data.inviteCode ?? ''),
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  submit() {
    CloudFunctions.RedeemInvite({
      user: this._authService.currentUser,
      inviteCode: this.form.get('code').value,
    })
      .then(() => {
        this._authService.updateUserRolesAndOrganizations();
        this.close();
      })
      .catch((err) => {
        this.form.controls['code'].setErrors({
          backendError: err.message,
        });
      });
  }

  close(): void {
    this._dialogRef.close();
  }

  hideInviteInfo(): void {
    this.inviteInfo = undefined;
  }

  registerAsNewUser(): void {
    this._authService
      .logout()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialog.close();
        this.router.navigate(['/register'], { queryParams: { invite: this.form.get('code').value } });
      });
  }
}

@NgModule({
  declarations: [RedeemCodeDialogComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
    MatDialogModule,
    TelespotModule,
    RoleBadgeModule,
    RouterModule,
  ],
  exports: [RedeemCodeDialogComponent],
})
export class RedeemCodeDialogModule {}
