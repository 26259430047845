<div class="card padded">
  <div class="card-body">
    <ts-data-table [config]="tableConfig" [dataSource]="dataSource">
      <ng-template dataTableCell="resources_list" let-item>
        <ng-container>
          <li [routerLink]="[item.resource.id]">
            <span class="flex-row">
              <span class="resource-type">{{ item.resource.type }}</span>
              {{ item.resource.name }}
              <i
                class="ri-question-answer-line"
                style="margin-left: 10px"
                *ngIf="item.resource.description"
                data-bs-toggle="tooltip"
                [title]="item.resource.description"
              ></i>
              <button
                class="btn btn-flat-danger btn-auto"
                data-cy="button-remove"
                (click)="removeResource(item.resource); $event.stopPropagation()"
              >
                <i class="ri-delete-bin-6-line"></i>
              </button>
              <button class="btn btn-flat-primary btn-auto">
                <i class="ri-pencil-line"></i>
              </button>
            </span>
          </li>
        </ng-container>
      </ng-template>
    </ts-data-table>
  </div>
</div>
