import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmationDialogComponent,
  DataTableConfig,
  SplDataSource,
  SplDataSourceConfig,
} from "@shared/ui";
import { DataService } from "@telespot/web-core";
import { MethodType, Query, Resource } from "@telespot/sdk";
import { BehaviorSubject, defer, from, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { ResourcesRowData } from "../resources-assignment/resources-row-data";
import { MatSnackBar } from "@angular/material/snack-bar";

interface FindResourcesRequest {
  sorts: any;
  filters: any;
  skip: number;
  limit: number;
}
@Component({
  selector: "ts-resources",
  templateUrl: "./resources.component.html",
  styleUrls: ["./resources.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesComponent
  implements OnInit, OnDestroy, SplDataSourceConfig<ResourcesRowData>
{
  private _destroy$ = new Subject<void>();

  public tableConfig: DataTableConfig<ResourcesRowData>;
  public dataSource = new SplDataSource<ResourcesRowData>(this);
  private previousRequest: FindResourcesRequest;
  private previousCount = 0;
  public oldAvailableResources: Resource[];
  private resourceDeleted = false;

  private getFindResourceQuery(request: FindResourcesRequest): Query<Resource> {
    const { filters, limit, skip } = request;

    const ResourcesQuery = new Query(Resource);
    if (filters) {
      for (const { constraints } of filters) {
        for (const [comparison, value] of Object.entries(constraints)) {
          //No other verifications are needed since searchbar only uses 'name' filter
          if (typeof value === "string") {
            ResourcesQuery.matches(
              "name",
              new RegExp(`${value.toLowerCase()}`),
              "i"
            );
          }
        }
      }
    }

    ResourcesQuery.limit(limit || 100);
    ResourcesQuery.skip(skip || 0);

    return ResourcesQuery;
  }

  public requestNeeded(prevRequest: FindResourcesRequest): boolean {
    if (!this.previousRequest) {
      return true;
    }

    if (this.resourceDeleted) {
      this.resourceDeleted = false;
      return true;
    }

    if (prevRequest.filters !== this.previousRequest.filters) {
      return true;
    }
    if (prevRequest.sorts !== this.previousRequest.sorts) {
      return true;
    }

    if (prevRequest.skip !== this.previousRequest.skip) {
      return true;
    }
    if (prevRequest.limit !== this.previousRequest.limit) {
      return true;
    }

    return false;
  }

  public async updateFcn(sorts, filters, skip, limit) {
    const request = { sorts, filters, skip, limit };

    const resourceQuery = this.getFindResourceQuery(request);

    const isRequestNeeded = this.requestNeeded(request);

    this.previousRequest = request;

    const items = isRequestNeeded
      ? this._dataService.find(resourceQuery)
      : Promise.resolve(this.oldAvailableResources);
    const count = isRequestNeeded
      ? this._dataService.count(resourceQuery)
      : Promise.resolve(this.previousCount);

    return {
      items: defer(() => items).pipe(
        map((resourcesFound) => {
          this.oldAvailableResources = resourcesFound;

          return resourcesFound.map<ResourcesRowData>((el) => ({
            resource: el,
            id: el.id,
          }));
        })
      ),
      count: from(count).pipe(map((num) => (this.previousCount = num))),
    };
  }

  constructor(
    private _dataService: DataService,
    private _dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.configureTable();
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  private configureTable(): void {
    this.tableConfig = {
      columns: [
        {
          name: "resources_list",
          value: (item) => item,
        },
      ],
      showPaginator: true,
      showFilters: false,
      showSearch: true,
      showStatusIndicator: false,
      showRefreshButton: false,
      useQueryParams: false,
    };
  }

  private async _checkUsedResource(resource: Resource) {
    const query = new Query(MethodType)
      .containedIn("resources", [resource?.toPointer()])
      .select("name");
    return this._dataService.find(query);
  }

  async removeResource(resource: Resource) {
    const usingMethods = await this._checkUsedResource(resource);
    this._dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: "dialog.remove_resource",
          text: usingMethods?.length
            ? `Warning: used by ${usingMethods.length} methods: \n${usingMethods
                .map((method) => `"${method.name}"`)
                .join(", ")}`
            : undefined,
          acceptButtonText: "button.delete",
          cancelButtonText: "button.cancel",
          defaultAction: usingMethods?.length ? "cancel" : "accept",
        },
      })
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (answer) => {
        if (answer === "accept") {
          try {
            await this._dataService.softDelete(resource);
            this.resourceDeleted = true;
            this.dataSource.fetch();
          } catch (error) {
            this.snackBar.open(error, null, { duration: 2000 });
          }
        }
      });
  }
}
