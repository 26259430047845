import { Component, Input, OnInit } from '@angular/core';
import { IResultsGroup, ReportContext } from '@telespot/reports/data-access';
import { AnalysisTask, Asset } from '@telespot/sdk';

@Component({
  selector: 'ts-results-table',
  templateUrl: './results-table.component.html',
  styleUrls: ['./results-table.component.scss'],
})
export class ResultsTableComponent implements OnInit {
  @Input() data: IResultsGroup;
  @Input() context: ReportContext;
  @Input() asset?: Asset;

  private _changeRows = false;
  public get changeRows() {
    return this._changeRows;
  }

  scenario: typeof ReportContext = ReportContext;

  get relevantTasks(): AnalysisTask[] {
    return this.data.analysisType.tasks.filter((task) =>
      this.context === ReportContext.sample
        ? !task.assetSpecific
        : this.context === ReportContext.counter
          ? task.assetSpecific && task.roiSelection
          : task.assetSpecific
    );
  }

  ngOnInit() {
    this._changeRows = this.data.analysisType?.tasks?.length < this.data.userResults.length;
  }
}
