import { Object as ParseObject } from "parse";
export enum LabelFields {
  TABLE = "Label",
  UUID = "uuid",
  LANG = "lang",
  VALUE = "value",
}

export class Label extends ParseObject {
  static className = LabelFields.TABLE;

  constructor() {
    super(Label.className);
  }

  get uuid(): string {
    return this.get(LabelFields.UUID);
  }

  set uuid(value: string) {
    this.set(LabelFields.UUID, value);
  }

  get lang(): string {
    return this.get(LabelFields.LANG) || "en";
  }

  set lang(value: string) {
    this.set(LabelFields.LANG, value);
  }

  get value(): string {
    return this.get(LabelFields.VALUE);
  }

  set value(value: string) {
    this.set(LabelFields.VALUE, value);
  }
}
