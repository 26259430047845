import { InjectionToken } from '@angular/core';

export const EVENT_PAGE_TITLE = 'page_title';
export const EVENT_PAGE_TITLE_PAYLOAD = 'title';

/**
 * Analytics module configuration
 *
 * @export
 * @interface AnalyticsConfig
 */
export interface AnalyticsConfig {
  /**
   * If true, the route title will also be used to set the browser's tab title
   *
   * @type {boolean}
   * @memberof AnalyticsConfig
   */
  change_tab_title?: boolean;
}

export const ANALYTICS_CONFIG = new InjectionToken<AnalyticsConfig>('ANALYTICS_CONFIG', {
  providedIn: 'root',
  factory: () => ({
    change_tab_title: true,
  }),
});
