export interface IFeatureDescription {
  name: string;
  displayName: string;
  description: string;
  children?: IFeatureDescription[];
}

export const FEATURES: IFeatureDescription[] = [
  {
    name: 'ai',
    displayName: 'feature.ai',
    description: 'feature.description.ai',
  },
  {
    name: 'analysis_review',
    displayName: 'feature.analysis_review',
    description: 'feature.description.analysis_review',
    children: [
      {
        name: 'manager_review',
        displayName: 'feature.manager_review',
        description: 'feature.description.manager_review',
      },
      {
        name: 'reference_only',
        displayName: 'feature.reference_only',
        description: 'feature.description.reference_only',
      },
    ],
  },
  {
    name: 'export_workspace',
    displayName: 'feature.export_workspace',
    description: 'feature.description.export_workspace',
  },
  {
    name: 'second_opinion',
    displayName: 'feature.second_opinion',
    description: 'feature.description.second_opinion',
  },
  {
    name: 'acquisition_available',
    displayName: 'feature.acquisition_available',
    description: 'feature.description.acquisition_available',
  },
];
