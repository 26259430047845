import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveSelectDirectiveModule, RoleBadgeModule } from '@shared/ui';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { TelespotModule } from '@telespot/web-core';

import { InviteEditorComponent } from './components/invite-editor/invite-editor.component';
import { InvitesComponent } from './components/invites/invites.component';
import { UserEditorComponent } from './components/user-editor/user-editor.component';
import { UsersComponent } from './components/users/users.component';
import { UsersRoutingModule } from './users-routing.module';

@NgModule({
  imports: [
    CommonModule,
    UsersRoutingModule,
    NgxQRCodeModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    TelespotModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatSelectModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatTableModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatDialogModule,
    MatDividerModule,
    ClipboardModule,
    RoleBadgeModule,
    ReactiveSelectDirectiveModule,
  ],
  declarations: [UsersComponent, InvitesComponent, InviteEditorComponent, UserEditorComponent],
})
export class TelespotUsersFeatureModule {}
