import { CommonModule } from '@angular/common';
import { NgModule, Optional, Pipe, PipeTransform } from '@angular/core';
import { LoggerService } from '@telespot/shared/logger/feature/util';

@Pipe({
  name: 'validateDate',
  pure: true,
})
export class ValidateDatePipe implements PipeTransform {
  constructor(@Optional() private _logger: LoggerService) {}

  transform(value: unknown, args?: any): any {
    let date = new Date((value as string)?.replace(/CEST/, '+0200'));
    // TODO: remove this when dates are correctly submitted from Android & iOS apps
    if (!date.valueOf() && !!value) {
      const values = (value as string).split('/');
      if (Number(values[0]) > 12) {
        date = new Date(`${values[1]}/${values[0]}/${values[2]}`);
      }
      if (!date.valueOf()) {
        this._logger?.debug(`Could not fix wrongly formatted date ${value}`);
      }
    }
    return date.valueOf() ? date : undefined;
  }
}

@NgModule({
  declarations: [ValidateDatePipe],
  imports: [CommonModule],
  exports: [ValidateDatePipe],
  providers: [],
})
export class ValidateDatePipeModule {}
