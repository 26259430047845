<mat-nav-list class="custom-nav-list">
  <ng-container *ngIf="currentUser$ | async as currentUser; else loginLink">
    <a mat-list-item routerLink="me" routerLinkActive="active" (click)="close()">
      <img [src]="currentUser.avatar?.url()" class="user-avatar user-avatar--32" alt="user avatar" />
      <b>{{ currentUser.username | uppercase }}</b>
    </a>

    <hr />

    <ng-container *ngFor="let item of links">
      <a
        mat-list-item
        [routerLink]="item.routerLink"
        (click)="close()"
        *ngIfRole="item.roles"
        routerLinkActive="active"
        [class.disabled]="item.disabled"
      >
        <i class="{{ item.icon }}"></i>
        {{ item.name | translate | uppercase }}
      </a>
    </ng-container>

    <a mat-list-item routerLink="/logout" (click)="logout(); close()">
      <i class="ri-logout-box-line"></i>
      {{ 'button.logout' | translate | uppercase }}
    </a>
  </ng-container>
  <ng-template #loginLink>
    <a mat-list-item routerLink="/login" (click)="close()">
      <i class="ri-logout-box-line"></i>
      {{ 'button.login' | translate | uppercase }}
    </a>
  </ng-template>

  <hr />

  <ts-language-selector
    data-cy="sidenav-language-selector"
    [showLabel]="true"
    style="margin-top: auto; padding: 0px 16px"
  ></ts-language-selector>

  <a class="secondary-sidebar-link" mat-list-item [routerLink]="['/support', 'faq']">
    {{ 'core.help' | translate }}
  </a>

  <a class="secondary-sidebar-link" mat-list-item appSupportLink target="_blank">
    {{ 'core.support' | translate }}
  </a>

  <a class="secondary-sidebar-link" mat-list-item [routerLink]="[]" appAboutPopupTrigger>
    {{ 'label.about' | translate }}
  </a>

  <a class="secondary-sidebar-link" mat-list-item href="https://spotlab.org" target="_blank"> © Spotlab S.L. </a>
</mat-nav-list>
