import { Asset, Entity, SoftDeletable } from "../entities";
import { ProcessingStatus } from "../value-objects";

export interface SampleAssetCreationAttributes extends Entity, SoftDeletable {
  sampleId: string;
  asset: Asset;
  index?: number; // TODO: discuss whats it used for; not always provided
  dateOfCapture?: Date; // TODO: discuss whats it used for; not always provided
  tag?: string;
  uuid?: string;
}

export class SampleAsset implements Entity, SoftDeletable {
  constructor(private attributes: SampleAssetCreationAttributes) { }

  public get id(): string | undefined {
    return this.attributes.id;
  }

  public get deletedAt(): Date | undefined {
    return this.attributes.deletedAt;
  }

  public get createdAt(): Date | undefined {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date | undefined {
    return this.attributes.updatedAt;
  }

  public get isDeleted(): boolean {
    return !!this.deletedAt;
  }

  public get sampleId(): string {
    return this.attributes.sampleId;
  }

  public get asset(): Asset {
    return this.attributes.asset;
  }

  public get tag(): string | undefined {
    return this.attributes.tag;
  }

  public get dateOfCapture(): Date {
    return this.attributes.dateOfCapture;
  }

  public get index(): number | undefined {
    return this.attributes.index;
  }

  public get uuid(): string {
    return this.attributes.uuid;
  }

  public transferOwnership(organizationId: string): SampleAsset {
    this.asset.transferOwnership(organizationId);

    return this;
  }

  public copyToSample(newSampleId: string, uuid: string): SampleAsset {
    const { id, sampleId, ...attributesCopy } = this.attributes;

    return new SampleAsset({
      ...attributesCopy,
      sampleId: newSampleId,
      uuid: uuid,
    });
  }
}
