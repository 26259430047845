<ng-container *ngIf="{ organizations: organizations$ | async } as state">
  <span
    class="flex-row align-items-center organization-trigger"
    [matMenuTriggerFor]="organizationSelectionMenu"
    *ngIf="currentOrganization$ | async as current"
  >
    <img
      alt="organization logo"
      [src]="current.organization?.logoUrl || 'assets/media/organization_placeholder.svg'"
      fallbackSrc="assets/media/organization_placeholder.svg"
    />
    <i class="ri-arrow-down-s-line text-primary"></i>
  </span>

  <mat-menu #organizationSelectionMenu="matMenu">
    <button
      mat-menu-item
      [disabled]="isMobileView | async"
      *ngFor="let org of organizations$ | async"
      (click)="selectOrganization(org)"
      class="flex-row align-items-center"
    >
      <span class="active-indicator" [hidden]="!org.selected"></span>
      <img [src]="org.organization.logoUrl || 'assets/media/organization_placeholder.svg'" />
      <span class="org-name">{{ org.organization.name }}</span>
    </button>
  </mat-menu>
</ng-container>
