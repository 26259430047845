import { Component, Inject, Optional, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService, DataService } from '@telespot/web-core';
import { Analysis, Sample, SampleAsset } from '@telespot/sdk';
import { Query } from 'parse';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { share, switchMap, tap } from 'rxjs/operators';

interface AssetAnalysis {
  analyzed: Array<boolean>;
  totalAnalyzed: number;
  perc: number;
  hasAssetAnalysis: boolean;
}

@Component({
  selector: 'ts-sample-report-preview',
  templateUrl: './sample-report-preview.component.html',
  styleUrls: ['./sample-report-preview.component.scss'],
})
export class SampleReportPreviewComponent {
  private _sample$: Observable<Sample>;
  public data$;
  private _assets$;
  public assetsAnalysis$: Observable<AssetAnalysis>;
  private _commentsIndex$ = new BehaviorSubject<number>(0);
  public get commentsIndex$() {
    return this._commentsIndex$.asObservable();
  }

  private TOTAL_ASSETS;

  @ViewChild('thumbnailDialog') _assetThumbnailDialog: TemplateRef<any>;
  public showThumbnail(thumbnail: string) {
    this._dialog.open(this._assetThumbnailDialog, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        thumbnail,
      },
    });
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData: { sample: Sample },
    @Optional() private _dialogRef: MatDialogRef<SampleReportPreviewComponent>,
    private _dialog: MatDialog,
    private _dataService: DataService,
    private _authService: AuthService
  ) {
    if (dialogData?.sample) {
      this._sample$ = of(dialogData.sample).pipe(
        switchMap((sample) =>
          from(
            this._dataService.first(new Query(Sample).equalTo('objectId', sample.id).include(['methodType.analysisTypes']))
          )
        )
      );
    }

    this._assets$ = this._sample$.pipe(
      switchMap((sample) =>
        from(this._dataService.find(new Query(SampleAsset).equalTo('sample', sample.toPointer()).include(['asset'])))
      ),
      tap((_sampleAssets: SampleAsset[]) => (this.TOTAL_ASSETS = _sampleAssets.length)),
      share()
    );

    this.assetsAnalysis$ = combineLatest([this._sample$, this._assets$]).pipe(
      switchMap(async ([sample, assets]: [Sample, Array<SampleAsset>]) => {
        const allAnalysis = await this._dataService.find(
          new Query(Analysis)
            .containedIn(
              'analysisType',
              sample.methodType.analysisTypes.map((analysisType) => analysisType.toPointer())
            )
            .equalTo('createdBy', this._authService.currentUser)
            .equalTo('sample', sample.toPointer())
        );

        const assetsAnalysis = allAnalysis.filter((a) => !!a.asset);
        return assets.reduce(
          (totalAssets, asset, currentIndex) => {
            const assetAnalysis = assetsAnalysis.filter((a) => a.asset?.id === asset.asset?.id);
            totalAssets.analyzed[asset.index ?? currentIndex] = assetAnalysis.some(
              (analysis) => !!Object.keys(analysis.data).length
            );
            if (totalAssets.analyzed[asset.index ?? currentIndex]) {
              totalAssets.totalAnalyzed = totalAssets.totalAnalyzed + 1;
              totalAssets.perc = Math.floor((totalAssets.totalAnalyzed / this.TOTAL_ASSETS) * 100);
            }
            return totalAssets;
          },
          { analyzed: new Array(this.TOTAL_ASSETS), totalAnalyzed: 0, perc: 0, hasAssetAnalysis: !!assetsAnalysis.length }
        );
      }),
      share()
    );

    this.data$ = combineLatest([this._sample$, this._assets$]).pipe(
      switchMap(async ([sample, assets]: [Sample, Array<SampleAsset>]) => {
        return Promise.all(
          sample.methodType.analysisTypes.map(async (analysisType) => {
            const allAnalysis = await this._dataService.find(
              new Query(Analysis)
                .equalTo('analysisType', analysisType.toPointer())
                .equalTo('createdBy', this._authService.currentUser)
                .equalTo('sample', sample.toPointer())
            );
            const assetsAnalysis = allAnalysis.filter((a) => !!a.asset);
            const sampleAnalysis = allAnalysis.filter((a) => !a.asset);
            const aggregatedData = analysisType.tasks.map((task) => {
              if (!allAnalysis.filter((a) => a.data?.[task.name]).length) return { task, data: [], isSample: false };
              let data;
              const isSample = !!sampleAnalysis.filter((a) => a.data?.[task.name]).length;
              switch (task.type) {
                case 'selection':
                  if (task.roiSelection) {
                    data = task.options.map((option) => ({
                      name: `${option.displayName}`,
                      value: allAnalysis.reduce(
                        (total, analysis) => total + (analysis.data?.[task.name]?.[option.name]?.length ?? 0),
                        0
                      ),
                    }));
                  } else if (task.multiple) {
                    data = task.options.map((option) => ({
                      name: `${option.displayName}`,
                      value: allAnalysis.reduce(
                        (total, analysis) => total + (analysis.data?.[task.name]?.[option.name] ?? 0),
                        0
                      ),
                    }));
                  } else {
                    data = task.options.map((option) => ({
                      name: `${option.displayName}`,
                      value: !isSample
                        ? assetsAnalysis.reduce(
                          (total, analysis, currentIndex) => {
                            const index = assets.find((a) => a.asset?.id === analysis.asset?.id)?.index;
                            total.checks[index ?? currentIndex] = analysis.data?.[task.name]?.[option.name];
                            if (total.checks[index ?? currentIndex]) {
                              total.counter = total.counter + 1;
                              total.perc = Math.floor((total.counter / this.TOTAL_ASSETS) * 100);
                            }
                            return total;
                          },
                          { checks: new Array(this.TOTAL_ASSETS), perc: 0, counter: 0 }
                        )
                        : sampleAnalysis.reduce((total, analysis) => analysis.data?.[task.name]?.[option.name], false),
                    }));
                    break;
                  }
                  break;
                case 'switcher':
                  data = task.options.map((option) => ({
                    name: `${option.displayName}`,
                    value: !isSample
                      ? assetsAnalysis.reduce(
                        (total, analysis, currentIndex) => {
                          const index = assets.find((a) => a.asset?.id === analysis.asset?.id)?.index;
                          total.checks[index ?? currentIndex] = analysis.data?.[task.name] === option.name;
                          if (total.checks[index ?? currentIndex]) {
                            total.counter = total.counter + 1;
                            total.perc = Math.floor((total.counter / this.TOTAL_ASSETS) * 100);
                          }
                          return total;
                        },
                        { checks: new Array(this.TOTAL_ASSETS), perc: 0, counter: 0 }
                      )
                      : sampleAnalysis.reduce((total, analysis) => analysis.data?.[task.name] === option.name, false),
                  }));
                  break;
                case 'boolean':
                  data = [
                    {
                      name: `${task.displayName}`,
                      value: !isSample
                        ? assetsAnalysis.reduce(
                          (total, analysis, currentIndex) => {
                            const index = assets.find((a) => a.asset?.id === analysis.asset?.id)?.index;
                            total.checks[index ?? currentIndex] = analysis.data?.[task.name];
                            if (total.checks[index ?? currentIndex]) {
                              total.counter = total.counter + 1;
                              total.perc = Math.floor((total.counter / this.TOTAL_ASSETS) * 100);
                            }
                            return total;
                          },
                          { checks: new Array(this.TOTAL_ASSETS), perc: 0, counter: 0 }
                        )
                        : sampleAnalysis.reduce((total, analysis) => analysis.data?.[task.name], false),
                    },
                  ];
                  break;
                case 'textedit':
                  data = [
                    {
                      name: `${task.displayName}`,
                      value: !isSample
                        ? assetsAnalysis
                          .reduce((total, analysis, currentIndex) => {
                            const asset = assets.find((a) => a.asset?.id === analysis.asset?.id);
                            if (analysis.data?.[task.name])
                              total[asset?.index ?? currentIndex] = {
                                value: analysis.data?.[task.name],
                                thumbnail: asset?.asset?.thumbnail,
                                assetNumber: asset?.index ?? currentIndex,
                              };
                            return total;
                          }, new Array(this.TOTAL_ASSETS))
                          .filter((v) => !!v)
                        : sampleAnalysis.reduce((total, analysis) => analysis.data?.[task.name], ''),
                    },
                  ];
                  break;
              }
              return {
                task,
                data,
                isSample,
              };
            });
            return {
              analysisType,
              hasAssetData: !aggregatedData.every((d) => d.isSample),
              hasSampleData: aggregatedData.some((d) => d.isSample),
              data: aggregatedData,
            };
          })
        );
      }),
      share()
    );
  }

  public slide(sum: number) {
    const currentIndex = this._commentsIndex$.value;
    if (sum === -1 && currentIndex === 0) sum = 0;
    else if (sum === 1 && currentIndex === this.TOTAL_ASSETS - 2) sum = 0;
    this._commentsIndex$.next(currentIndex + sum);
  }

  public close() {
    this._dialogRef?.close();
  }
}
