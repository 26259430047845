import { RoiModel } from "../..";
import { TaskTypeName } from "./task-type";

export enum EAnalysisTaskType {
  asset_checkbox = "Asset checkbox",
  asset_text = "Asset text",
  class_counter = "Class ROIs",
  class_presence = "Class presence",
  class_rois = "ROI selection",
  sample_checkbox = "Sample checkbox",
  sample_number = "Counter",
  sample_switcher = "Switcher",
  sample_text = "Sample text",
}

export interface TAnalysisModelItem {
  name: string;
  displayName?: string;
  color?: string;
}

export enum TRoiSelectionType {
  center = "center",
  boundingBox = "boundingBox",
  free = "free",
}

export interface TAnalysisTask {
  name: string;
  displayName?: string;
  type?: TaskTypeName;
  options?: string[] | TAnalysisModelItem[];
  allowNewOptions?: boolean;
  multiple?: boolean;
  roiSelection?: boolean;
  roiSelectionType?: TRoiSelectionType;
  assetSpecific?: boolean;
  required?: boolean;
}

export class AnalysisTask implements TAnalysisTask {
  type?: TaskTypeName;
  name: string;
  displayName?: string;
  required?: boolean = false;
  assetSpecific?: boolean;

  // selection-specific
  options?: TAnalysisModelItem[];
  allowNewOptions?: boolean;
  multiple?: boolean;
  roiSelection?: boolean = false;
  roiSelectionType?: TRoiSelectionType;

  // number-specific
  decimals?: number;

  // review
  allOptions?: TAnalysisModelItem[];

  constructor(data: TAnalysisTask) {
    if (data.name !== undefined) {
      // TODO: REVIEW
      // console.warn(`Omit specifying task.name when calling the constructor. Task.name should be generated on creation based on task.displayName value`);
    }
    this.name = data.name;
    this.displayName = data.displayName || this.name;
    this.type = data.type;
    // Options re-mapping
    if (data.options) {
      const firstItem = data.options.length ? data.options[0] : undefined;
      if (firstItem) {
        if (typeof firstItem === "string") {
          this.options = (data.options as string[]).map((o) => {
            return {
              name: o,
              displayName: o as string,
            };
          });
        } else if (typeof firstItem === "object") {
          this.options = data.options as TAnalysisModelItem[];
        }
      }
    }
    this.allOptions = this.options;
    this.allowNewOptions = data.allowNewOptions;
    this.multiple = data.multiple;
    this.roiSelection = data.roiSelection;
    this.required = data.required;
    this.assetSpecific = data.assetSpecific;
    this.roiSelectionType =
      data.roiSelectionType ?? TRoiSelectionType.boundingBox;
  }

  getTaskType(): EAnalysisTaskType {
    switch (this.type) {
      case "boolean":
        return this.assetSpecific
          ? EAnalysisTaskType.asset_checkbox
          : EAnalysisTaskType.sample_checkbox;
        break;
      case "number":
        return EAnalysisTaskType.sample_number;
        break;
      case "selection":
        return this.roiSelection
          ? EAnalysisTaskType.class_rois
          : this.multiple
          ? EAnalysisTaskType.class_counter
          : EAnalysisTaskType.class_presence;
        break;
      case "switcher":
        return EAnalysisTaskType.sample_switcher;
        break;
      case "textedit":
        return this.assetSpecific
          ? EAnalysisTaskType.asset_text
          : EAnalysisTaskType.sample_text;
        break;
    }
  }

  getModels(): RoiModel[] {
    return (this.roiSelection ? this.options : []).map((o) => ({
      className: o.name,
      displayName: o.displayName,
      task: this.toJSON(),
      color: o.color,
    }));
  }

  public toJSON(): TAnalysisTask {
    const { name, displayName, assetSpecific, type, ...extra } = this;
    switch (this.type) {
      case "selection":
        return {
          name,
          displayName,
          type,
          assetSpecific,
          options: extra.options,
          roiSelection: extra.roiSelection,
          roiSelectionType: extra.roiSelectionType,
          allowNewOptions: extra.allowNewOptions,
          multiple: extra.multiple,
        };
      case "segmentation":
        return {
          name,
          displayName,
          type,
          assetSpecific,
          options: extra.options,
        };
      case "number":
        return {
          name,
          displayName,
          type,
          assetSpecific,
        };
      case "textedit":
        return {
          name,
          displayName,
          type,
          assetSpecific,
        };
      case "boolean":
        return {
          name,
          displayName,
          type,
          assetSpecific,
        };
      case "switcher":
        return {
          name,
          displayName,
          type,
          assetSpecific,
          options: extra.options,
        };
    }
  }
}
