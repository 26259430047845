import { ExecutorEntity, PipelineStep } from "@telespot/domain";
import { ParseMapper } from "../../parse.mapper";
import { PipelineTopology } from "./parse-pipeline.mapper";

export enum PipelineStepTopology {
  TABLE = "PipelineStep",
  ORDER = "order",
  EXECUTOR = "executor",
  PIPELINE = "pipeline",
  TRIGGER = "trigger",
  GENERATEFINDING = "generateFinding",
  TASK = "task",
  PARAMS = "params",
}

export class ParsePipelineStepMapper extends ParseMapper<PipelineStep> {
  public toDomain(parseObject: Parse.Object<Parse.Attributes>): PipelineStep {
    return new PipelineStep({
      id: parseObject.id,
      order: parseObject.get(PipelineStepTopology.ORDER),
      executorId: parseObject.get(PipelineStepTopology.EXECUTOR)?.id,
      executorEntity: parseObject.get(PipelineStepTopology.EXECUTOR)
        ? ExecutorEntity.ALGORITHM
        : ExecutorEntity.USER,
      pipelineId: parseObject.get(PipelineStepTopology.PIPELINE)?.id,
      trigger: parseObject.get(PipelineStepTopology.TRIGGER),
      generateFinding: parseObject.get(PipelineStepTopology.GENERATEFINDING),
      task: parseObject.get(PipelineStepTopology.TASK),
      params: parseObject.get(PipelineStepTopology.PARAMS),
    });
  }

  public fromDomain(step: PipelineStep): Parse.Object<Parse.Attributes> {
    const ParsePipelineStep = this.subclasses.getSubclass(
      PipelineStepTopology.TABLE
    );
    const ParsePipeline = this.subclasses.getSubclass(PipelineTopology.TABLE);
    const ParseUser = this.subclasses.getSubclass(PipelineTopology.TABLE);
    const ParseAlgorithm = this.subclasses.getSubclass(PipelineTopology.TABLE);

    const parseObject = step.id
      ? ParsePipelineStep.createWithoutData(step.id)
      : new ParsePipelineStep();

    const parseCreatedBy =
      step.executorEntity === "user"
        ? ParseUser.createWithoutData(step.executorId)
        : ParseAlgorithm.createWithoutData(step.executorId);

    parseObject.set(PipelineStepTopology.ORDER, step.order);
    parseObject.set(PipelineStepTopology.EXECUTOR, parseCreatedBy);
    parseObject.set(
      PipelineStepTopology.PIPELINE,
      ParsePipeline.createWithoutData(step.pipelineId)
    );
    parseObject.set(PipelineStepTopology.TRIGGER, step.trigger);
    parseObject.set(PipelineStepTopology.GENERATEFINDING, step.generateFinding);
    parseObject.set(PipelineStepTopology.TASK, step.task);
    parseObject.set(PipelineStepTopology.PARAMS, step.params);

    return parseObject;
  }
}
