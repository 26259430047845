import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  DataTableConfig,
  SplDataSource,
  SplDataSourceConfig,
} from "@shared/ui";

import { DataService } from "@telespot/web-core";
import { Subject, defer, from } from "rxjs";
import { map, take, takeUntil, tap } from "rxjs/operators";
import { MethodType, Pipeline, Query } from "@telespot/sdk";
import { PipelineEditorDialogComponent } from "../pipeline-editor-dialog/pipeline-editor-dialog.component";
import { TranslateService } from "@ngx-translate/core";

interface FindPipelineRequest {
  sorts: any;
  filters: any;
  skip: number;
  limit: number;
}

@Component({
  selector: "ts-pipelines-assignment",
  templateUrl: "./pipelines-assignment.component.html",
  styleUrls: ["./pipelines-assignment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PipelinesAssignmentComponent
  implements OnInit, OnDestroy, SplDataSourceConfig<Pipeline>
{
  private _destroy$ = new Subject<void>();

  showPipelineEditor = false;

  private _dialogRef: MatDialogRef<any>;
  private _pipelines: Pipeline[];

  @Input()
  set pipelines(value: Pipeline[]) {
    this._pipelines = value;
  }
  get pipelines(): Pipeline[] {
    return this._pipelines || [];
  }

  @Input() methodType: MethodType;

  @Output() pipelinesChanged = new EventEmitter<Pipeline[]>();

  selectedPipelines: string[] = [];

  pipeline: Pipeline; // PipelineEntity passed to editor

  public tableConfig: DataTableConfig<Pipeline>;

  private previousRequest: FindPipelineRequest;
  private previousCount = 0;
  private pipelinesCreated: string[] = [];
  private pipelineToInclude = false;
  public oldAvailablePipelines: Pipeline[];
  public dataSource = new SplDataSource<Pipeline>(this);

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  public getFindPipelinesQuery(request): Query<Pipeline> {
    const { filters, skip, limit } = request;
    const pipelineQuery = new Query(Pipeline);

    if (filters) {
      for (const { constraints } of filters) {
        for (const [comparison, value] of Object.entries(constraints)) {
          if (typeof value === "string") {
            pipelineQuery.matches(
              "name.searchTerm",
              new RegExp(`${value.toLowerCase()}`),
              "i"
            );
          }
        }
      }
    }
    pipelineQuery.limit(limit || 100);
    pipelineQuery.skip(skip || 0);

    return pipelineQuery;
  }

  public requestNeeded(prevRequest: FindPipelineRequest): boolean {
    if (!this.previousRequest) {
      return true;
    }
    if (this.pipelineToInclude) {
      this.pipelineToInclude = false;
      return true;
    }

    if (prevRequest.filters !== this.previousRequest.filters) {
      return true;
    }
    if (prevRequest.sorts !== this.previousRequest.sorts) {
      return true;
    }

    if (prevRequest.skip !== this.previousRequest.skip) {
      return true;
    }
    if (prevRequest.limit !== this.previousRequest.limit) {
      return true;
    }

    return false;
  }

  public async updateFcn(sorts, filters, skip, limit) {
    const request = { sorts, filters, skip, limit };

    const PipelineQuery = this.getFindPipelinesQuery(request);

    const isRequestNeeded = this.requestNeeded(request);
    this.previousRequest = request;

    const items = isRequestNeeded
      ? this.dataService.find(PipelineQuery)
      : Promise.resolve(this.oldAvailablePipelines);
    const count = isRequestNeeded
      ? this.dataService.count(PipelineQuery)
      : Promise.resolve(this.previousCount);

    return {
      items: defer(() => items).pipe(
        map((pipelinesFound: Pipeline[]) => {
          this.oldAvailablePipelines = pipelinesFound;
          pipelinesFound = pipelinesFound.filter(
            (el) =>
              !this.selectedPipelines.includes(el.id) &&
              !this.pipelines.some((p) => p.id === el.id)
          );

          return pipelinesFound;
        })
      ),
      count: from(count).pipe(map((num) => (this.previousCount = num))),
    };
  }

  private configureTable(): void {
    this.tableConfig = {
      columns: [
        {
          name: "available_pipelines",
          value: (item) => item,
        },
      ],
      showPaginator: true,
      showFilters: false,
      showSearch: true,
      showStatusIndicator: false,
      showRefreshButton: false,
      useQueryParams: false,
    };
  }

  ngOnInit() {
    this.selectedPipelines = this.pipelines.map((p) => p.id);
    this.configureTable();
  }

  edit(pipeline: Pipeline) {
    this.pipeline = pipeline;
    this._dialogRef =
      this._dialogRef ||
      this.dialog.open(PipelineEditorDialogComponent, {
        width: "800px",
        hasBackdrop: true,
        data: {
          pipeline,
          methodType: this.methodType,
        },
      });
    this._dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe((changedPipeline) => {
        this.pipelinesCreated.push(pipeline.id);
        this.addPipeline(changedPipeline);
      });
  }

  async addPipeline(pipeline: Pipeline) {
    if (pipeline) {
      this.selectedPipelines.push(pipeline.id);
      this.dataSource.fetch();

      if (this.pipelines.findIndex((p) => p.id === pipeline.id) === -1) {
        this.pipelines.push(pipeline);
      }
      this.pipelinesChanged.emit(this.pipelines);
    }
  }

  create() {
    this.edit(new Pipeline());
  }

  removePipeline(pipeline: Pipeline) {
    if (this.pipelinesCreated.includes(pipeline.id)) {
      this.pipelinesCreated = this.pipelinesCreated.filter(
        (id) => id !== pipeline.id
      );
      this.pipelineToInclude = true;
    }

    this.pipelines = this.pipelines.filter((p) => p !== pipeline);
    this.selectedPipelines = this.selectedPipelines.filter(
      (id) => id !== pipeline.id
    );
    this.dataSource.fetch();
    this.pipelinesChanged.emit(this.pipelines);
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  getPipelineName(name: any) {
    return name[this.translateService.currentLang] ?? name["en"];
  }
}
