<div class="flex-row align-items-center">
  <i class="ri-alert-line"></i>
  {{
    data.username
      ? ('label.save_before_switching_to_x_analyst'
        | translate : { username: data.username === 'ai' ? ('core.ia' | translate) : data.username })
      : ('label.save_before_closing_review_panel' | translate)
  }}
</div>
<div matDialogActions class="flex-row justify-content-around">
  <button class="btn btn-outline-primary" [matDialogClose]="true">{{ 'button.continue_discard' | translate }}</button>
  <button class="btn btn-danger" [matDialogClose]="false">{{ 'button.cancel' | translate }}</button>
</div>
