import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedLocalizationModule } from "@shared/localization";
import { DataTableModule, UserAvatarModule } from "@shared/ui";
import { TelespotModule } from "@telespot/web-core";
import { SecureHrefDirectiveModule } from "@telespot/shared/util";
import { CreatedByCellModule } from "@telespot/ui";
import { WorkspaceItemComponentModule } from "@telespot/workspaces/ui";
import { TelespotAuthDataAccessModule } from "@telespot/auth/data-access";

import { CasesFilterComponent } from "./components/cases-filter/cases-filter.component";
import { CasesListLoaderComponent } from "./components/cases-list-loader/cases-list-loader.component";
import { CasesListAdaptaspotComponent } from "./components/cases-list/adaptaspot/cases-list-adaptaspot/cases-list-adaptaspot.component";
import { CasesListComponent } from "./components/cases-list/cases-list.component";
import { CasesListTiraspotComponent } from "./components/cases-list/tiraspot/cases-list-tiraspot/cases-list-tiraspot.component";
import { CasesSearchComponent } from "./components/cases-search/cases-search.component";
import { WorkspaceDetailPageComponent } from "./components/workspace-detail-page/workspace-detail-page.component";
import { WorkspaceDetailComponent } from "./components/workspace-detail/workspace-detail.component";
import { WorkspaceEditorComponent } from "./components/workspace-editor/workspace-editor.component";
import { WorkspaceMembersComponent } from "./components/workspace-members/workspace-members.component";
import { WorkspacesComponent } from "./components/workspaces/workspaces.component";
import { WorkspacesRoutingModule } from "./workspaces-routing.module";

@NgModule({
  imports: [
    CommonModule,
    SharedLocalizationModule,
    TelespotModule,
    WorkspacesRoutingModule,
    WorkspaceItemComponentModule,
    DataTableModule,
    CreatedByCellModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatTabsModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatMenuModule,
    MatInputModule,
    MatDialogModule,
    MatProgressBarModule,
    UserAvatarModule,
    MatCheckboxModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    SecureHrefDirectiveModule,
    TelespotAuthDataAccessModule
  ],
  declarations: [
    WorkspacesComponent,
    WorkspaceDetailComponent,
    WorkspaceEditorComponent,
    WorkspaceMembersComponent,
    CasesListComponent,
    WorkspaceDetailPageComponent,
    CasesSearchComponent,
    CasesFilterComponent,
    WorkspaceDetailPageComponent,
    CasesListAdaptaspotComponent,
    CasesListTiraspotComponent,
    CasesListLoaderComponent,
  ],
  exports: [],
  providers: [],
})
export class WorkspacesShellModule { }
