<div style="text-align: right">
  <button mat-icon-button class="btn">
    <i style="font-size: 16px" class="ri-close-fill" matDialogClose></i>
  </button>
</div>
<div class="flex-column">
  <div class="flex-row align-items-center block">
    <img data-cy="version-image" class="fab" src="assets/media/labelling/fab.png" />
    <h2 data-cy="version-label">
      <strong>{{ 'core.version' | translate }}</strong>
    </h2>
  </div>
  <div data-cy="version-value">
    v. <strong>{{ version }}</strong>
  </div>
  <div data-cy="last-build-date" class="block" *ngIf="lastModified">
    {{ lastModified | date }}
  </div>
  <div class="flex-row align-items-center block">
    <img data-cy="manufactured-image" class="fab" src="assets/media/labelling/fill_fab.png" />
    <h2 data-cy="manufactured-label">
      <strong>{{ 'core.manufactured' | translate }}</strong>
    </h2>
  </div>
  <div>
    <a data-cy="manufactured-url" href="https://spotlab.org" id="spotlab" target="_blank">SPOTLAB, S.L.</a>
  </div>
  <div data-cy="manufactured-nif" class="block">NIF: B87755146</div>
  <div data-cy="manufactured-address">Paseo de Juan XXIII, 36B</div>
  <div data-cy="manufactured-post-code" class="block">28045 Madrid</div>
  <div data-cy="manufactured-email" class="block">E-mail: support@spotlab.org</div>
  <div class="flex-row bottom-labels" data-cy="regulatory-labels">
    <!-- <img src="assets/media/labelling/symbols.png" /> -->
    <img src="assets/media/labelling/ivd.svg" />
    <img src="assets/media/labelling/caution.svg" />
    <img src="assets/media/labelling/instructions.svg" />
  </div>
  <!-- Not validated -->
  <div style="font-weight: bold; font-style: italic">For performance evaluation only</div>
</div>
