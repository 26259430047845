import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { discardMarkAsAnalyzed } from "../../+state";

import {
  AnalysisRequest,
  CreateAnalysisDetails,
  discardAnalysis,
  discardAnalysisChange,
  discardRemoveROIs,
  discardSetROIs,
  discardSync,
  discardUpdateROI,
  HistoryOperations,
  RefStripDetails,
  ROI,
  RoiActionDetails,
  SetROIActionDetails,
  UpdateAnalysisDetails,
} from "../../state";

export enum OperationType {
  createAnalysis = "[ANALYSIS] Create Analysis",
  createAnalysisFromROI = "[ANALYSIS] Create new Analysis when creating a ROI",
  updateAnalysis = "[ANALYSIS] Update Analysis",
  removeROI = "[ROIS] Remove ROIs",
  updateROI = "[ROIS] Update rois",
  setROI = "[ROIS] Set ROI",
  updateLabel = "[ROIS] Update Label",
  markAsAnalyzed = "[REFERENCE STRIP] Mark strip item as analyzed",
}

@Injectable({
  providedIn: "any",
})
export class HistoryService {
  constructor(private _store: Store) {}

  public mapCreateAnalysisAction(type: string, props: any) {
    switch (type) {
      case OperationType.createAnalysis: {
        const { data, ...params } = props;
        return [{ ...(params as AnalysisRequest) }];
      }
      case OperationType.createAnalysisFromROI:
        return ((props.analysesRequest as AnalysisRequest[]) ?? []).map(
          (req) => ({ ...req })
        );
      default:
        break;
    }
  }

  public mapROIsAction(type: string, props: any) {
    switch (type) {
      case OperationType.removeROI: {
        let rois: ROI[] = props?.selectedROIs ?? props?.rois;
        rois = rois.map((roi) => ({ ...roi, selected: false }));
        return { rois };
      }
      case OperationType.updateROI:
        return { rois: [props.roi as ROI] };
      default:
        break;
    }
  }

  public async discardChanges(operations: HistoryOperations[]): Promise<any> {
    const sortedOperations = operations.slice().sort(function (a, b) {
      return a.timestamp > b.timestamp ? -1 : 1;
    });

    await Promise.all(
      sortedOperations.map(async (op) => {
        switch (op.operationType) {
          case OperationType.createAnalysis:
            this._store.dispatch(
              discardAnalysis({
                analysisDiscarded: op.details as CreateAnalysisDetails[],
                hasROI: false,
              })
            );
            break;
          case OperationType.createAnalysisFromROI:
            this._store.dispatch(
              discardAnalysis({
                analysisDiscarded: op.details as CreateAnalysisDetails[],
                hasROI: true,
              })
            );
            break;
          case OperationType.updateAnalysis:
            this._store.dispatch(
              discardAnalysisChange({
                analysis: (op.details as UpdateAnalysisDetails).analysis,
              })
            );
            break;
          case OperationType.removeROI:
            this._store.dispatch(
              discardRemoveROIs({ rois: (op.details as RoiActionDetails).rois })
            );
            break;
          case OperationType.updateROI:
            this._store.dispatch(
              discardUpdateROI({ rois: (op.details as RoiActionDetails).rois })
            );
            break;
          case OperationType.setROI:
            this._store.dispatch(
              discardSetROIs({ xois: (op.details as SetROIActionDetails).xois })
            );
            break;
          case OperationType.updateLabel:
            this._store.dispatch(
              discardUpdateROI({ rois: (op.details as RoiActionDetails).rois })
            );
            break;
          case OperationType.markAsAnalyzed:
            this._store.dispatch(
              discardMarkAsAnalyzed({
                assetId: (op.details as RefStripDetails).assetId,
                analysisStatesId: (op.details as RefStripDetails)
                  .analysisStateId,
              })
            );
            break;
        }
      })
    );
    return "Changes discarded!";
  }
}
