<div class="context-menu">
  <ul *ngFor="let category of categories">
    <li class="category-item">
      {{ category.name }}
    </li>
    <li
      *ngFor="let option of category.options"
      class="label-item"
      (click)="optionSelected.emit({ option, category })"
      (mouseenter)="optionHovered.emit(option)"
      (mouseleave)="optionUnhovered.emit(option)"
    >
      <span
        *ngIf="isChecked(option)"
        class="ri-check-line"
        style="font-size: 21px"
      ></span>

      <span
        *ngIf="isDoubleChecked(option)"
        class="ri-check-double-line"
        style="font-size: 21px"
      ></span>
      {{ option.name }}
    </li>
  </ul>
</div>
