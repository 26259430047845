import { Injectable, OnDestroy } from "@angular/core";
import {
  Label,
  PipelineEnvironment,
  ProtocolPipeline,
  Query,
  Sample,
  StepAction,
} from "@telespot/sdk";
import { Observable, Subject, from } from "rxjs";
import { ProtocolSpecification } from "../../state";
import {
  PipelineEntity,
  ProtocolMapper,
  ProtocolService,
} from "@telespot/protocols/data-access";

@Injectable({
  providedIn: "any",
})
export class ProtocolAnalysisService implements OnDestroy {
  private _destroy$ = new Subject<void>();
  constructor(private protocolService: ProtocolService) {}

  public toProtocolSpecification(
    pipelines: PipelineEntity[]
  ): ProtocolSpecification[] {
    const protocolSpecifications = pipelines.map(({ id, name, tasks }) => {
      const taskWithNewClassEnabled = tasks.find(
        (t) => ProtocolMapper.taskIsRelatedToTagging(t) && t?.allowNewOptions
      );

      const customLabelTask = taskWithNewClassEnabled
        ? [
            {
              stepId: taskWithNewClassEnabled.id,
              type: taskWithNewClassEnabled.type,
              name: "Custom Labels",
              allowNewOptions: true,
              multiple: true,
              assetSpecific: true,
              roiSelection: true,
              options: [],
              collapsed: false,
            },
          ]
        : [];

      return {
        id,
        name: name[this.protocolService.currentLang] ?? name["en"],
        mobile: false,
        tasks: [
          ...tasks
            .filter((task) => ProtocolMapper.taskIsRelatedToTagging(task))
            .map((task) => ({
              stepId: task.id,
              type: task.type,
              name: task.name.name,
              allowNewOptions: false,
              enableMosaicView: task?.enableMosaicView ?? false,
              multiple: task.multiple,
              assetSpecific: task.generateFinding !== StepAction.SAMPLE_CREATED,
              roiSelection: true,
              options: task?.options,
              collapsed: false,
            })),
          ...customLabelTask,
          ...tasks
            .filter((task) => !ProtocolMapper.taskIsRelatedToTagging(task))
            .map((task) => ({
              stepId: task.id,
              type: task.type,
              name: task.name.name,
              allowNewOptions: false,
              multiple: task.multiple,
              assetSpecific: task.generateFinding !== StepAction.SAMPLE_CREATED,
              roiSelection: false,
              options: task?.options,
              collapsed: false,
            })),
        ],
      };
    });

    return protocolSpecifications;
  }

  public fetchLabelCounterFilters(caseTypeId: string) {
    const filters = localStorage.getItem(`counterFilters:${caseTypeId}`);

    return filters ? JSON.parse(filters).labelFilters : [];
  }

  public fetchSampleProtocol(
    sampleId: string
  ): Observable<ProtocolSpecification[]> {
    const sampleQuery = new Query(Sample).equalTo("objectId", sampleId);
    const protocolQuery = new Query(ProtocolPipeline)
      .matchesKeyInQuery("methodType", "methodType", sampleQuery)
      .include("pipeline");

    const protocolPromise = protocolQuery
      .find()
      .then((protocolPipelines) => {
        const cloudProtoPipelines = protocolPipelines.filter(
          (p) => p.pipeline.env === PipelineEnvironment.CLOUD
        );

        const filteredProtoPipelines =
          cloudProtoPipelines.length !== 0
            ? cloudProtoPipelines
            : protocolPipelines.filter(
                (p) => p.pipeline.env === PipelineEnvironment.EDGE
              );
        return Promise.all(
          filteredProtoPipelines.map((p) =>
            this.protocolService.getPipeline(p.pipeline.id, p.methodType.id)
          )
        );
      })
      .then((pipelines) => this.toProtocolSpecification(pipelines));

    return from(protocolPromise);
  }

  public setCounterFiltersInStorage(labelId: string, caseTypeId: string) {
    const userCounterFilters = this.fetchLabelCounterFilters(caseTypeId);
    let updatedFilters = [];

    if (userCounterFilters.length > 0) {
      const filterIndex = userCounterFilters.indexOf(labelId);
      updatedFilters =
        filterIndex !== -1
          ? [...userCounterFilters.filter((filter, i) => filterIndex !== i)]
          : [...userCounterFilters, labelId];
    } else {
      updatedFilters = [labelId];
    }
    localStorage.setItem(
      `counterFilters:${caseTypeId}`,
      JSON.stringify({ labelFilters: updatedFilters })
    );

    return updatedFilters;
  }

  public setInitialCounterFilters(counterFilters: string[], sampleId: string) {
    localStorage.setItem(
      `counterFilters:${sampleId}`,
      JSON.stringify({
        labelFilters: counterFilters.map((filter) =>
          decodeURIComponent(filter)
        ),
      })
    );
  }

  public saveCustomLabel(customLabel) {
    const allNewLabels: Label[] = [];

    Object.keys(customLabel?.value?.value || {}).forEach((optionValue) => {
      if (customLabel.value?.value[optionValue].length < 1) return;
      const newLabel = new Label();
      newLabel.lang = optionValue;
      newLabel.uuid = customLabel.value.uuid;
      newLabel.value = customLabel.value?.value[optionValue];
      allNewLabels.push(newLabel);
    });

    return from(Label.saveAll(allNewLabels));
  }
  ngOnDestroy() {
    this._destroy$.next();
  }
}
