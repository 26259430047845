import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { PageEvent } from "@angular/material/paginator";
import { Sort, SortDirection } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, startWith, takeUntil } from "rxjs/operators";

import { VerticalExpandAnimation } from "../../../animations/vertical-expand.animation";
import { SplDataSource } from "../../../models/spl-data-source";
import { ColumnConfig, DataTableConfig } from "../models/data-table-config";
import {
  FilterConfig,
  FilterContainedInConstraint,
  TableFilter,
} from "../models/data-table-filter";
import { DataTableCellTemplateDirective } from "./data-table-cell-template.directive";
import { DataTableHeaderTemplateDirective } from "./data-table-header-template.directive";

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE_SIZES = [10, 20];
@Component({
  selector: "ts-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [VerticalExpandAnimation],
})
export class DataTableComponent<T> implements OnInit, OnDestroy {
  @HostBinding("attr.hideHeader")
  get hideHeader() {
    return this.config.hideHeader ? "" : null;
  }
  @Input() config: DataTableConfig<T>;
  @Input() dataSource: SplDataSource<T>;
  @Input() isAdmin = false;
  @Input() isEncrypted = false;
  @Output() decryptCases = new EventEmitter<boolean>();

  @ContentChildren(DataTableCellTemplateDirective, {
    read: DataTableCellTemplateDirective,
  })
  cellTemplates: DataTableCellTemplateDirective[];
  @ContentChildren(DataTableHeaderTemplateDirective, {
    read: DataTableHeaderTemplateDirective,
  })
  headerTemplates: DataTableHeaderTemplateDirective[];

  @Output() public readonly selectItem = new EventEmitter<T>();
  @Output() public readonly migrateItem = new EventEmitter<T>();
  @Output() public readonly copyItem = new EventEmitter<T>();
  @Output() public readonly removeItem = new EventEmitter<T>();
  @Output() public readonly addFilter = new EventEmitter<FilterConfig>();
  @Output() public readonly deleteFilter = new EventEmitter<FilterConfig>();
  @Output() public readonly changePage = new EventEmitter<PageEvent>();
  @Output() public readonly sortItems = new EventEmitter<{ [key: string]: SortDirection }>();
  @Output() public readonly searchItem = new EventEmitter<FilterConfig>();

  private _filters$ = new BehaviorSubject<TableFilter[]>([]);
  public filters$: Observable<TableFilter[]>;

  public decrypt: boolean;
  public decryptedCases: any[] = [];
  public decryptionLoading = false;
  public searchTerm;

  showColumnVisibilityOverlay = false;
  private destroy$ = new Subject<void>();
  public readonly mobile$ = this.breakpoint
    .observe([Breakpoints.HandsetPortrait, Breakpoints.XSmall])
    .pipe(map((state) => state.matches));

  private _updateVisibleColumns$ = new Subject<null>();
  public readonly visibleColumns$: Observable<string[]> = combineLatest([
    this.mobile$,
    this._updateVisibleColumns$.pipe(startWith([null])),
  ]).pipe(
    map(([mobile]) => [
      ...(this.config?.showStatusIndicator ? ["__state"] : []),
      ...this.config.columns
        .filter(
          (column) =>
            !column.hidden &&
            (mobile ? column.layout !== "desktop" : column.layout !== "mobile")
        )
        .map((column) => column.name),
      ...(this.config.showMenu ? ["__menu"] : []),
    ])
  );

  getCellTemplate(columnName: string): TemplateRef<unknown> {
    return this.cellTemplates?.find((tpl) => tpl.dataTableCell === columnName)
      ?.tpl;
  }

  getHeaderTemplate(
    columnName: string,
    mobile?: boolean
  ): TemplateRef<unknown> {
    return this.headerTemplates?.find(
      (templateDirective) =>
        templateDirective.dataTableHeader === columnName &&
        ((mobile
          ? templateDirective.dataTableHeaderLayout === "mobile"
          : templateDirective.dataTableHeaderLayout === "desktop") ||
          templateDirective.dataTableHeaderLayout === "all")
    )?.tpl;
  }

  get activeFilters$() {
    return this.dataSource?.activeFilters$;
  }

  get pageSize(): number {
    return this.config.pageSize ?? DEFAULT_PAGE_SIZE;
  }

  get pageSizes(): number[] {
    return this.config.pageSizes ?? DEFAULT_PAGE_SIZES;
  }

  applyFilter(filter: TableFilter) {
    this.dataSource.applyFilter(filter);
    this.addFilter.emit(this.filterToConfig(filter));
  }

  removeFilter(filter: TableFilter) {
    this.dataSource.removeFilter(filter.name);
    this.deleteFilter.emit(this.filterToConfig(filter));
  }

  updateSort(event: Sort): void {
    const active = this.config.columns.find(
      (column) => column.name === event.active
    )?.sortKey;
    this.dataSource.applySort({
      ...event,
      active,
    });

    this.sortItems.emit({ [active]: event.direction });
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breakpoint: BreakpointObserver,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (!this.config) {
      console.warn(
        `You must provide a valid DataTableConfig object for ts-data-table to work`
      );
      return;
    }
    if (!this.dataSource) {
      console.warn(
        `You didn't provide SplDataSource [dataSource], not every feature will be available`
      );
    }

    if (this.config.useQueryParams) {
      this._configureQueryParamsSync();
    }

    if (this.dataSource instanceof SplDataSource) {
      this.filters$ = combineLatest([
        this._filters$,
        this.dataSource.activeFilters$,
      ]).pipe(
        map(([allFilters, activeFilters]) =>
          allFilters.map(
            (filter) =>
              activeFilters.find((af) => af === filter) ??
              filter?.setActive(false)
          )
        ),
        shareReplay({ bufferSize: 1, refCount: true })
      );
      this._filters$.next(
        this.config.columns.reduce(
          (allFilters, column) => [
            ...allFilters,
            ...(column.filters ?? []).map(
              (f) => new TableFilter({ ...f, column: column.name })
            ),
          ],
          []
        )
      );

      if (this.config.pageSize) {
        this.dataSource.updatePagination({
          pageSize: this.config.pageSize,
        });
      }
    }

    this.dataSource.activeFilters$
      .pipe(
        map(filters => filters.find((filter) => filter.name === "name")),
        filter(searchFilter => !!searchFilter),
        distinctUntilChanged()
      )
      .subscribe(searchFilter => {
        const { equalTo, contains } = searchFilter?.constraints ?? {};
        this.searchTerm = equalTo ?? contains;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  selectCell(column: ColumnConfig<unknown>, cellValue: unknown, $event) {
    $event?.stopPropagation();

    const filter = new TableFilter({
      name: column.name,
      displayName: `${column.name}: "${cellValue}"`,
      constraints: {
        equalTo: cellValue as string,
      },
    });

    this.dataSource.applyFilter(filter);
    this.addFilter.emit(this.filterToConfig(filter));
  }

  updateColumnVisibility(column: ColumnConfig<T>, $event: MatCheckboxChange) {
    column.hidden = !$event.checked;
    this._updateVisibleColumns$.next(null);
  }

  updatePagination($event: PageEvent): void {
    this.dataSource.updatePagination({
      pageIndex: $event.pageIndex,
      pageSize: $event.pageSize,
    });

    this.changePage.emit($event);
  }

  toggleFilter(filter: TableFilter) {
    if (filter.active) {
      this.dataSource.removeFilter(filter);
      this.deleteFilter.emit(this.filterToConfig(filter));
    } else {
      this.dataSource.applyFilter(filter);
      this.addFilter.emit(this.filterToConfig(filter));
    }

  }

  get allFilters() {
    return this.config?.columns.map((column) => ({
      sectionName: column.displayName ?? column.name,
      filters: column.filters,
    }));
  }

  toggleFilterOption(filter: TableFilter, option: FilterContainedInConstraint) {
    option.active = !option.active;
    if (
      filter.constraints.containedIn?.some(
        (filterOption) => filterOption.active
      )
    ) {
      this.dataSource.applyFilter(filter);
      this.addFilter.emit(this.filterToConfig(filter));
    } else {
      this.dataSource.removeFilter(filter);
      this.deleteFilter.emit(this.filterToConfig(filter));
    }
    this.cd.markForCheck();
  }

  columnHasActiveFilters(column: ColumnConfig<T>): Observable<boolean> {
    return this.activeFilters$.pipe(
      map((filters) =>
        filters.some((filter) => filter.column === column.name && filter.active)
      )
    );
  }

  private _configureQueryParamsSync(): void {
    // query > dataSource
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        const { page: pageIndex, pageSize, ...sorts } = params;
        if (!!pageIndex || !!pageSize) {
          this.dataSource.updatePagination({
            pageIndex: Number(pageIndex ?? 0),
            pageSize: Number(pageSize ?? this.config.pageSize ?? DEFAULT_PAGE_SIZE),
          });
        }
        this.dataSource.applySort(
          Object.keys(sorts).reduce(
            (s, key) => [...s, { active: key, direction: sorts[key] }],
            []
          )
        );
      });
    // dataSource > query
    this.dataSource.params$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ sorts, pagination }) => {
        this.router.navigate([], {
          queryParams: {
            page: pagination.pageIndex,
            pageSize: pagination.pageSize,
            ...sorts,
          },
          queryParamsHandling: "merge",
        });
      });
  }

  public isObservable(value): boolean {
    return value instanceof Observable;
  }

  private filterToConfig(filter: TableFilter): FilterConfig {
    return {
      column: filter.column,
      constraints: { ...filter.constraints },
      name: filter.name,
      displayName: filter.displayName,
      active: filter.active,
    };
  }
}
