import { inject, InjectionToken } from '@angular/core';
import { Query, Role } from '@telespot/sdk';
import { defer, Observable } from 'rxjs';

import { DataService } from '../services/data/data.service';

export const USER_ROLES = new InjectionToken<Observable<Role[]>>('USER_ROLES', {
  factory: () => {
    const ds = inject(DataService);
    return defer(() => ds.find(new Query(Role)));
  },
});
