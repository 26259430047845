import { Injectable } from '@angular/core';
import { AuthService, DataService } from '@telespot/web-core';
import { Algorithms, Analysis, CaseType, MethodType, ProtocolAlgorithms, Query, Sample, SampleAsset } from '@telespot/sdk';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { TIRASPOT_POCT_RESULT_CORRECTION_KEY } from '../models';
import { TiraspotUtils } from '../utils/tiraspot.utils';

export interface IAssetItemWithPOCTData {
  sampleAsset: SampleAsset;
  disagreement: boolean;
  correctionAnalysis?: { analysis: Analysis; correctedResults: string[] }[];
  userResults: string[];
  AIResults: string[];
  correctedResults: string[];
}
@Injectable({
  providedIn: 'root',
})
export class TiraspotService {
  constructor(private _authService: AuthService, private _dataService: DataService) {}

  /**
   * Checks if the input methodType has a tiraspot AI workflow
   *
   * @param {MethodType} methodType
   * @return {*}  {Observable<boolean>}
   * @memberof TiraspotService
   */
  public isAIenabled(methodType: MethodType): Observable<boolean>;
  /**
   * Checks if any of input caseType's methodTypes has a tiraspot AI workflow
   *
   * @param {CaseType} caseType
   * @return {*}  {Observable<boolean>}
   * @memberof TiraspotService
   */
  public isAIenabled(caseType: CaseType): Observable<boolean>;
  public isAIenabled(item: CaseType | MethodType): Observable<boolean> {
    const modelQuery = new Query(Algorithms).contains('name', 'tiraspot');
    const query = new Query(ProtocolAlgorithms).matchesQuery('model', modelQuery);
    if (item instanceof CaseType) {
      query.containedIn('methodType', item.methodTypes);
    } else if (item instanceof MethodType) {
      query.equalTo('methodType', item);
    }
    return from(this._dataService.first(query)).pipe(map((w) => !!w));
  }

  public getSampleData(sample: Sample): Observable<IAssetItemWithPOCTData[]> {
    return from(
      this._dataService.find(new Query(SampleAsset).equalTo('sample', sample).include(['asset'])) as Promise<SampleAsset[]>
    ).pipe(
      switchMap((sampleAssets: SampleAsset[]) =>
        from(
          Promise.all<IAssetItemWithPOCTData>(
            sampleAssets.map(async (sampleAsset) => {
              const correctionAnalysis = await this._dataService.find(
                new Query(Analysis).equalTo('asset', sampleAsset.asset).doesNotExist('analysisType').ascending('updatedAt')
              );
              return {
                sampleAsset,
                correctionAnalysis: correctionAnalysis.map((analysis) => ({
                  analysis,
                  correctedResults: TiraspotUtils.getTiraspotAnswer(
                    analysis?.data?.[TIRASPOT_POCT_RESULT_CORRECTION_KEY] as string
                  ),
                })),
                disagreement: TiraspotUtils.warningAI(sampleAsset),
                userResults: TiraspotUtils.getTiraspotAnswer(sampleAsset.asset?.data?.tiraspot?.result),
                AIResults: TiraspotUtils.getTiraspotAnswer(sampleAsset.asset?.data?.tiraspot?.ml_result?.ml_output_label),
                correctedResults: TiraspotUtils.getTiraspotAnswer(
                  correctionAnalysis[-1]?.data?.[TIRASPOT_POCT_RESULT_CORRECTION_KEY] as string
                ),
              };
            })
          )
        )
      )
    );
  }

  /**
   * Choose between the result provided by the user / IA or 'unsure' , and save as Analysis
   *
   * @param {IAssetItemWithPOCTData} item
   * @param {('ai' | 'user' | 'unsure')} result
   * @return {*}  {Promise<void>}
   * @memberof TiraspotService
   */
  public async chooseResult(item: IAssetItemWithPOCTData, result: 'ai' | 'user' | 'unsure'): Promise<void> {
    let finalResult: string;
    switch (result) {
      case 'ai':
        finalResult = item.sampleAsset.asset.data.tiraspot?.ml_result?.ml_output_label;
        break;
      case 'user':
        finalResult = item.sampleAsset.asset.data.tiraspot?.result;
        break;
      case 'unsure':
        finalResult = 'unsure';
        break;
    }

    let userAnalysis = item.correctionAnalysis.find((a) => a.analysis.createdBy?.id === this._authService.currentUser?.id);
    if (!userAnalysis) {
      userAnalysis = {
        analysis: new Analysis({
          sample: item.sampleAsset.sample,
          asset: item.sampleAsset.asset,
        }),
        correctedResults: TiraspotUtils.getTiraspotAnswer(finalResult),
      };
      item.correctionAnalysis.push(userAnalysis);
    }

    userAnalysis.analysis.data = {
      [TIRASPOT_POCT_RESULT_CORRECTION_KEY]: finalResult,
    };

    userAnalysis.analysis = await (this._dataService.save(userAnalysis.analysis) as Promise<Analysis>);
    item.correctionAnalysis = [
      ...item.correctionAnalysis.sort((a1, a2) => a1.analysis.updatedAt?.valueOf() - a2.analysis.updatedAt?.valueOf()),
    ];
    userAnalysis.correctedResults = TiraspotUtils.getTiraspotAnswer(finalResult);
    item.correctedResults = TiraspotUtils.getTiraspotAnswer(finalResult);
  }
}
