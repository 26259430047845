<div class="card">
  <table mat-table [dataSource]="dataSource" class="spotlab-table">
    <ng-container matColumnDef="device">
      <th mat-header-cell *matHeaderCellDef>
        {{'core.name' | translate | uppercase}}
      </th>
      <td mat-cell *matCellDef="let item">
        <a [routerLink]="[]">{{item.deviceType.name}}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef>
        {{'core.version' | translate | uppercase}}
      </th>
      <td mat-cell *matCellDef="let item">
        {{item.deviceType.version | uppercase}}
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>
        {{'label.device_name' | translate | uppercase}}
      </th>
      <td mat-cell *matCellDef="let item">
        {{item.name}}
      </td>
    </ng-container>
    <ng-container matColumnDef="serialNumber">
      <th mat-header-cell *matHeaderCellDef>
        {{'label.serial_number' | translate | uppercase}}
      </th>
      <td mat-cell *matCellDef="let item">
        {{item.serialNumber}}
      </td>
    </ng-container>
    <ng-container matColumnDef="purchaseDate">
      <th mat-header-cell *matHeaderCellDef>
        {{'props.acquired_on' | translate | uppercase}}
      </th>
      <td mat-cell *matCellDef="let item">
        {{item.createdAt | date:'short'}}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns$ | async"></tr>
    <tr mat-row *matRowDef="let item; columns: visibleColumns$ | async" [routerLink]="[]"></tr>
  </table>
</div>
