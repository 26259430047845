import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ts-faq-contents',
  templateUrl: './faq-contents.component.html',
  styleUrls: ['./faq-contents.component.scss'],
})
export class FaqContentsComponent implements OnInit {
  faqItems$: Observable<{
    [key: string]: {
      title: string;
      description: string;
    }[];
  }>;

  readonly selectedSection$: Observable<string>;

  constructor(private route: ActivatedRoute, private translateService: TranslateService) {
    this.selectedSection$ = this.route.fragment;
  }

  ngOnInit() {
    this.faqItems$ = this.translateService.getTranslation('en').pipe(
      map((translation) =>
        Object.keys(translation['FAQS'] || []).reduce((allSections, sectionName) => {
          allSections[sectionName] = {
            title: `FAQS.${sectionName}.title`,
            questions: Object.keys(translation['FAQS'][sectionName].questions).map((questionName) => {
              return {
                title: `FAQS.${sectionName}.questions.${questionName}.title`,
                description: `FAQS.${sectionName}.questions.${questionName}.description`,
              };
            }),
          };
          return allSections;
        }, {})
      )
    );
  }
}
