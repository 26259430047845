import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Organization, Query } from '@telespot/sdk';
import { from, Observable } from 'rxjs';

import { DataService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class OrganizationResolver implements Resolve<Organization> {
  constructor(private _dataService: DataService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<Organization> {
    const id = route.params['organizationid'];
    return from(this._dataService.get(id, new Query(Organization)));
  }
}
