import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  NgModule,
  Optional,
  ViewChild,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { TranslateModule } from "@ngx-translate/core";
import { Observable, of } from "rxjs";

import { ConfirmDialogConfig } from "./confirm-dialog-config";

@Component({
  selector: "ts-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmationDialogComponent implements AfterViewInit {
  @ViewChild("btnCancel") private _btnCancel?: ElementRef;
  @ViewChild("btnDiscard") private _btnDiscard?: ElementRef;
  @ViewChild("btnAccept") private _btnAccept?: ElementRef;

  text$: Observable<string>;
  progress$: Observable<number>;

  constructor(
    @Optional() public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmDialogConfig = { defaultAction: "cancel" }
  ) {
    this.text$ = data.text
      ? data.text instanceof Observable
        ? data.text
        : of(data.text)
      : undefined;
    this.progress$ = data.progress
      ? data.progress instanceof Observable
        ? data.progress
        : of(data.progress)
      : undefined;
  }

  ngAfterViewInit(): void {
    let focusedButton: ElementRef;
    switch (this.data.defaultAction) {
      case "cancel":
        focusedButton = this._btnCancel;
        break;
      case "discard":
        focusedButton = this._btnDiscard;
        break;
      case "accept":
        focusedButton = this._btnAccept;
        break;
    }
    focusedButton?.nativeElement.focus();
  }

  cancel() {
    this.dialogRef?.close("cancel");
  }

  discard() {
    this.dialogRef?.close("discard");
  }

  accept() {
    this.dialogRef?.close("accept");
  }
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatProgressBarModule,
  ],
  declarations: [ConfirmationDialogComponent],
  exports: [ConfirmationDialogComponent],
})
export class ConfirmationDialogComponentModule {}
