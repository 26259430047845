<span class="button-bar align-right">
  <button mat-icon-button class="btn btn-flat-primary" (click)="create()">
    <i class="ri-add-circle-line"></i>
    {{ 'button.new_resource' | translate | uppercase }}
  </button>
</span>
<div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between">
  <div class="column">
    <h4>{{ 'title.existing_resources' | translate }}</h4>
    <ts-data-table [config]="tableConfig" [dataSource]="dataSource">
      <ng-template dataTableCell="available_resources" let-item>
        <ng-container>
          <li>
            <span> {{ item.resource.name }}</span>
            <span class="button-bar stretch">
              <button
                mat-icon-button
                class="btn btn-flat-primary align-right"
                data-cy="button-add-resource"
                (click)="addResource(item.resource)"
              >
                <i class="ri-add-circle-line"></i>
              </button>
            </span>
          </li>
        </ng-container>
      </ng-template>
    </ts-data-table>
  </div>
  <div class="column">
    <h4>
      {{ 'core.resources' | translate }}
    </h4>
    <ul class="list-group">
      <ng-container *ngIf="resources.length; else noItems">
        <li
          class="list-group-item"
          *ngFor="let resource of resources"
          cdkDrag
          [cdkDragData]="resource"
          data-bs-toggle="tooltip"
          [title]="resource.description || ('tooltip.no_description' | translate)"
        >
          <span>{{ resource.name }}</span>
          <span class="button-bar stretch">
            <button mat-icon-button class="btn btn-flat-primary" data-cy="edit-resource" (click)="edit(resource)">
              <i class="ri-pencil-line"></i>
            </button>
            <button
              mat-icon-button
              class="btn btn-flat-danger"
              (click)="removeResource(resource)"
              data-cy="button-remove-resource"
            >
              <i class="ri-delete-bin-6-line"></i>
            </button>
          </span>
        </li>
      </ng-container>
    </ul>
    <ng-template #noItems> Add some resources </ng-template>
  </div>
</div>
