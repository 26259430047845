<div class="main-container">
  <div class="title">
    <h1 style="flex-grow: 1">
      {{ 'button.settings' | translate }}
    </h1>
  </div>
  <div class="card">
    <div class="card-body">
      <h4>Viewer options</h4>
      <div>
        <mat-checkbox type="checkbox" #useSequence id="useSequence" name="useSequence" [(ngModel)]="settings.useSequence">
          Use sequence in viewer instead of collection
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox
          type="checkbox"
          id="useCustomReferenceStrip"
          [disabled]="!useSequence.checked"
          name="useCustomReferenceStrip"
          [(ngModel)]="settings.useCustomReferenceStrip"
        >
          Use custom reference strip in sequence mode
        </mat-checkbox>
      </div>
    </div>

    <div class="card-body">
      <h4>Other</h4>
      <div>
        <mat-checkbox
          type="checkbox"
          #useCanvasVideoViewer
          id="useCanvasVideoViewer"
          name="useCanvasVideoViewer"
          [checked]="settings.getBoolean('useCanvasVideoViewer')"
          (change)="settings.setBoolean('useCanvasVideoViewer', useCanvasVideoViewer.checked)"
        >
          Use custom video viewer
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox
          type="checkbox"
          #enableVideoZoom
          id="enableVideoZoom"
          name="enableVideoZoom"
          [checked]="settings.getBoolean('enableVideoZoom')"
          (change)="settings.setBoolean('enableVideoZoom', enableVideoZoom.checked)"
        >
          Enable video zoom
        </mat-checkbox>
      </div>
    </div>

    <div class="card-footer card-actions">
      <a class="btn btn-outline-primary" [routerLink]="['/me']">
        {{ 'button.cancel' | translate | uppercase }}
      </a>
      <a class="btn btn-primary" [routerLink]="['/me']">
        {{ 'button.save' | translate | uppercase }}
      </a>
    </div>
  </div>
</div>
