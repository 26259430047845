<div class="card-body">
 <mat-form-field style="width: 100%">
  <input
    #inputElement
    matInput
    type="text"
    placeholder="{{ 'label.display_name' | translate | uppercase }}"
    (input)="onInputChange($event)"
  />
  <ts-label-suggester
    *ngIf="isLabelSuggesterVisible"
    [inputValue]="inputValue$"
    (filteredLabels$)="filteredLabels$ = $event"
    (click)="onLabelSuggesterClick($event)"
    (optionSelected)="onCategorySelected($event)"
    class="custom-autocomplete"
  ></ts-label-suggester>
</mat-form-field>


<div class="card-footer card-actions">
  <button class="btn btn-primary card-actions__action card-actions__action--grow" (click)="submit()" style="order: 1">{{ 'button.submit' | translate }}</button>
  <button class="btn btn-outline-primary card-actions__action card-actions__action--grow" (click)="cancel()" style="order: 0">{{ 'button.cancel' | translate }}</button>
</div>
</div>
