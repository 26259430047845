<div>
  <ng-container *ngIf="aiAlgorithms$ | async as aiAlgorithms">
    <mat-form-field class="full-width">
      <mat-label>{{ 'label.select_ai_model' | translate }}</mat-label>
      <mat-select id="selectAIAnalysis" (selectionChange)="changeUser($event)" [value]="aiAlgorithmSelected$ | async">
        <mat-option
          [value]="aiAlgorithm"
          *ngFor="let aiAlgorithm of aiAlgorithms"
          data-cy="check-ai-model"
          style="width: 100%"
        >
          <span class="flex-grow-1">
            <ng-container> AI: {{ aiAlgorithm.analysisState.algorithm?.name }} </ng-container></span
          >
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</div>
