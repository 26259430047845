import { MethodType, MethodTypeRepository, Pipeline } from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";
import {
  MethodTypeTopology,
  ParseMethodTypeMapper,
} from "./parse-method-type.mapper";
import { ResourceTopology } from "./parse-resource.mapper";
import { CaseTypeTopology } from "./parse-case-type.mapper";
import { ProtocolPipelineTopology } from "./parse-protocol-pipeline.mapper";
import { ParsePipelineMapper, PipelineTopology } from "./parse-pipeline.mapper";
import { ObjectTopology } from "../../parse.topology";

export class ParseMethodTypeRepository
  extends ParseBaseRepository
  implements MethodTypeRepository
{
  private readonly methodTypeMapper = new ParseMethodTypeMapper(this.parse);
  private readonly pipelineMapper = new ParsePipelineMapper(this.parse);

  public async getById(id: string): Promise<MethodType> {
    const reference = await this.subclasses
      .getSubclass(MethodTypeTopology.TABLE)
      .createWithoutData(id)
      .fetchWithInclude(
        [
          MethodTypeTopology.ANALYSIS_TYPES,
          [MethodTypeTopology.RESOURCES, ResourceTopology.RESOURCES].join("."),
        ],
        this.options
      );

    return this.methodTypeMapper.toDomain(reference);
  }

  public async findForCaseType(caseTypeId: string): Promise<MethodType[]> {
    try {
      const caseType = await this.subclasses
        .getSubclass(CaseTypeTopology.TABLE)
        .createWithoutData(caseTypeId)
        .fetchWithInclude(
          [
            [
              CaseTypeTopology.METHOD_TYPES,
              MethodTypeTopology.ANALYSIS_TYPES,
            ].join("."),
            [
              CaseTypeTopology.METHOD_TYPES,
              MethodTypeTopology.RESOURCES,
              ResourceTopology.RESOURCES,
            ].join("."),
          ],
          this.options
        );

      return caseType
        .get(CaseTypeTopology.METHOD_TYPES)
        .map((mt) => this.methodTypeMapper.toDomain(mt));
    } catch (error) {
      if (error.message === "Object not found.") return [];
      throw error;
    }
  }

  public async getPipelinesAssigned(methodTypeId: string): Promise<Pipeline[]> {
    const methodType = this.subclasses
      .getSubclass(MethodTypeTopology.TABLE)
      .createWithoutData(methodTypeId);

    const protocolPipelineQuery = new this.parse.Query(
      ProtocolPipelineTopology.TABLE
    ).equalTo(ProtocolPipelineTopology.METHOD_TYPE, methodType);

    const results = await new this.parse.Query(PipelineTopology.TABLE)
      .matchesKeyInQuery(
        ObjectTopology.ID,
        `${ProtocolPipelineTopology.PIPELINE}.${ObjectTopology.ID}`,
        protocolPipelineQuery
      )
      //REVIEW: Provisionally filter cloud pipelines only
      .equalTo(PipelineTopology.ENV, "cloud")
      .find(this.options);

    return results.map((p) => this.pipelineMapper.toDomain(p));
  }
}
