<div
  class="component-container"
  *ngIf="filteredLabels$ | async as filteredLabels"
>
  <div class="scroll-container">
    <ng-container *ngIf="filteredLabels?.length; else noResults">
      <mat-option
        *ngFor="let label of filteredLabels"
        [value]="label"
        (click)="onOptionClicked(label, false)"
        class="label-option"
      >
        <span class="name-label">{{ label.name }}</span>
        <span class="lang">{{ getLanguageLabel(label) }}</span>
        <span class="uuid">({{ label.uuid }})</span>
      </mat-option>
    </ng-container>
  </div>
  <ng-template #noResults>
    <mat-option class="label-option" disabled> No results </mat-option>
  </ng-template>
  <mat-option
    [value]="'AddNew'"
    [disabled]="!currentValue || filteredLabels.indexOf(currentValue) !== -1"
    (click)="onOptionClicked(currentValue, true)"
    class="add-label-option"
  >
    <span class="bold-option name-label">{{
      "button.add_new" | translate
    }}</span>
    "{{ currentValue?.name || currentValue }}"
  </mat-option>
</div>
