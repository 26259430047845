import type Parse from "parse";

import { AnalysisState } from "@telespot/domain";

import { CaseTopology } from "../workspace";
import { ParseMapper } from "../../parse.mapper";
import { AssetTopology, SampleTopology } from "../acquisition";
import { AlgorithmTopology } from "../ai";
import { ObjectTopology } from "../../parse.topology";

export enum AnalysisStateTopology {
  TABLE = "AnalysisState",
  SAMPLE = "sample",
  USER = "user",
  STATE = "state",
  ALGORITHM = "algorithm",
  ASSET_BOOKMARK = "assetBookmark",
  FAVORITE_ASSETS = "favAssets",
  CASE = "case",
  DATA = "data",
}

export class ParseAnalysisStateMapper extends ParseMapper<AnalysisState> {
  public toDomain(object: Parse.Object): AnalysisState {
    return new AnalysisState({
      id: object.id,
      subjectEntity: object.get(AnalysisStateTopology.SAMPLE)
        ? "sample"
        : "case",
      subjectId:
        object.get(AnalysisStateTopology.SAMPLE)?.id ??
        object.get(AnalysisStateTopology.CASE)?.id,
      analizerEntity: object.get(AnalysisStateTopology.USER)
        ? "user"
        : "algorithm",
      analizerId:
        object.get(AnalysisStateTopology.USER)?.id ??
        object.get(AnalysisStateTopology.ALGORITHM)?.id,
      state: object.get(AnalysisStateTopology.STATE),
      assetBookmarkId: object.get(AnalysisStateTopology.ASSET_BOOKMARK)?.id,
      favoritAssetIds: (
        object.get(AnalysisStateTopology.FAVORITE_ASSETS) ?? []
      ).map(({ id }) => id),
      createdAt: object.get(ObjectTopology.CREATED_AT),
      updatedAt: object.get(ObjectTopology.UPDATED_AT),
    });
  }

  public fromDomain(analysisState: AnalysisState): Parse.Object {
    const ParseCase = this.subclasses.getSubclass(CaseTopology.TABLE);
    const ParseSample = this.subclasses.getSubclass(SampleTopology.TABLE);
    const ParseAlgorithm = this.subclasses.getSubclass(AlgorithmTopology.TABLE);

    const ParseAnalystEntity =
      analysisState.analizerEntity === "user"
        ? this.parse.User
        : ParseAlgorithm;
    const parseAnalystKey =
      analysisState.analizerEntity === "user"
        ? AnalysisStateTopology.USER
        : AnalysisStateTopology.ALGORITHM;

    const ParseSubjectEntity =
      analysisState.subjectEntity === "sample" ? ParseSample : ParseCase;
    const parseSubjectKey =
      analysisState.subjectEntity === "sample"
        ? AnalysisStateTopology.SAMPLE
        : AnalysisStateTopology.CASE;

    const favoritAssets = (analysisState.favoritAssetIds ?? []).map((i) =>
      this.subclasses.getSubclass(AssetTopology.TABLE).createWithoutData(i)
    );

    const bookmarkedAsset = this.subclasses
      .getSubclass(AssetTopology.TABLE)
      .createWithoutData(analysisState.assetBookmarkId);

    const parseObject = this.parse.Object.fromJSON({
      className: AnalysisStateTopology.TABLE,
      [ObjectTopology.ID]: analysisState.id,
      [ObjectTopology.CREATED_AT]: analysisState.createdAt,
      [ObjectTopology.UPDATED_AT]: analysisState.updatedAt,
    });

    parseObject.set({
      [parseAnalystKey]: ParseAnalystEntity.createWithoutData(
        analysisState.analizerId
      ),
      [parseSubjectKey]: ParseSubjectEntity.createWithoutData(
        analysisState.subjectId
      ),
      [AnalysisStateTopology.STATE]: analysisState.state,
      [AnalysisStateTopology.FAVORITE_ASSETS]: favoritAssets,
      [AnalysisStateTopology.ASSET_BOOKMARK]: analysisState.assetBookmarkId
        ? bookmarkedAsset
        : undefined,
    });

    return parseObject;
  }
}
