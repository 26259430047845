import { Object as ParseObject } from 'parse';

export class Resource extends ParseObject {
  static className = 'Resource';

  constructor() {
    super(Resource.className);
    this.name = '';
    this.value = '';
  }

  set name(value: string) {
    this.set('name', value);
  }
  get name(): string {
    return this.get('name') ?? '';
  }

  set displayName(value: { [key: string]: unknown }) {
    this.set('displayName', value);
  }
  get displayName(): { [key: string]: unknown } {
    return this.get('displayName') ?? {};
  }

  set value(value: string) {
    this.set('value', value);
  }
  get value(): string {
    return this.get('value');
  }

  set version(value: number) {
    this.set('version', value);
  }
  get version(): number {
    return this.get('version');
  }

  set description(value: string) {
    this.set('description', value);
  }
  get description(): string {
    return this.get('description');
  }

  set type(value: string) {
    this.set('type', value);
  }
  get type(): string {
    return this.get('type');
  }

  set resources(resources: Resource[]) {
    this.set('resources', resources);
  }
  get resources(): Resource[] {
    return this.get('resources');
  }

  set metadata(value: { [key: string]: unknown }) {
    this.set('metadata', value);
  }
  get metadata(): { [key: string]: unknown } {
    return this.get('metadata');
  }
}
