import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@telespot/shared/environment';

@Component({
  selector: 'ts-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const user_language = localStorage.getItem('user_language');
    this.translate.use(user_language || environment.default_language || 'en');
  }
}
