import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';

@Component({
  selector: 'ts-role-badge',
  templateUrl: './role-badge.component.html',
  styleUrls: ['./role-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleBadgeComponent {
  @Input() role: string;
}

@NgModule({
  imports: [CommonModule],
  declarations: [RoleBadgeComponent],
  exports: [RoleBadgeComponent],
})
export class RoleBadgeModule { }
