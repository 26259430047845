<ng-container>
  <div class="main-container">
    <div class="title">
      <h1>{{ "title.your_workspaces" | translate | uppercase }}</h1>

      <button
        class="btn btn-primary ms-auto"
        *ngIfRole="['analystmanager', 'technicianmanager', 'admin']"
        routerLink="new"
        data-cy="button-new-workspace"
      >
        <i class="ri-add-circle-line"></i>
        {{ "button.new_workspace" | translate | uppercase }}
      </button>
    </div>
    <div
      id="workspaces-container"
      class="workspaces-container workspace-rows"
      id="workspaces-container"
    >
      <div class="card padded card-body column">
        <ts-data-table
          (changePage)="changePage($event)"
          (selectItem)="goToWorkspaceDetail($event)"
          (addFilter)="applyFilter($event)"
          (deleteFilter)="removeFilter($event)"
          [config]="tableConfig"
          [dataSource]="dataSource"
        >
          <ng-template dataTableCell="workspaces_list" let-item>
            <ts-workspace-item
              [showLabels]="false"
              data-cy="workspace-row"
              [workspace]="item.workspace"
            >
            </ts-workspace-item>
          </ng-template>
        </ts-data-table>
      </div>
    </div>
  </div>
</ng-container>
