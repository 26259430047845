<div
  class="sample-form-section flex-row align-items-center justify-content-between"
  style="border-bottom: 1px solid rgba(128, 128, 128, 0.5)"
>
  <div>
    <h4 style="margin-bottom: 4px">
      {{ "core.method_type" | translate }}: "{{ sample?.methodType?.name }}"
    </h4>
    <div style="font-size: smaller">
      {{ "core.type" | translate }}:
      {{ sample?.methodType?.assetType.type | translate }}
    </div>
  </div>
  <span class="float-right flex-row align-items-center">
    <button
      data-cy="button-save-sample"
      mat-icon-button
      class="btn btn-flat-primary"
      [disabled]="!form.valid"
      (click)="onSave()"
    >
      <i class="ri-save-line"></i>
    </button>
    <button
      data-cy="button-cancel-sample"
      mat-icon-button
      class="btn btn-flat-primary"
      (click)="onRevert()"
    >
      <i class="ri-close-fill text-danger"></i>
    </button>
  </span>
</div>

<form [formGroup]="form">
  <div class="sample-form-section">
    <mat-form-field class="full-width">
      <mat-label>{{ "label.sample_name" | translate | uppercase }}</mat-label>
      <input
        id="sample-name"
        matInput
        type="text"
        required
        placeholder="Sample ID"
        [formControlName]="'name'"
      />
      <mat-error>
        {{ "core.required" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>{{ "core.device" | translate }}</mat-label>
      <mat-select
        placeholder="Select a device"
        [formControlName]="'device'"
        id="device-select"
        [compareWith]="sameId"
        [class.disabled]="!devices?.length"
      >
        <mat-select-trigger id="device-select-trigger">
          {{ form.controls["device"].value?.name }}
        </mat-select-trigger>
        <ng-container *ngFor="let device of devices">
          <mat-option *ngIf="compatibleDevice(device)" [value]="device">
            {{ device.name }}
            <small>
              {{ "core.device_type" | translate }}:
              {{ device.deviceType.name }} (v. {{ device.deviceType.version }})
            </small>
            <small>{{ device.serialNumber }}</small>
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="form.controls.device.hasError('required')">
        {{ "core.required" | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="sample-form-section">
    <mat-expansion-panel #filesPanel="matExpansionPanel">
      <mat-expansion-panel-header>
        <mat-panel-title
          class="flex-row align-items-center justify-content-between"
        >
          <span>{{ "core.files" | translate | uppercase }}</span>
          <span class="flex-row align-items-center text-primary">
            {{ form.controls.files.value.length }}
            <i class="ri-image-line"></i>
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        formArrayName="files"
        style="max-height: 300px; overflow: auto; padding: 10px"
      >
        <form
          *ngFor="let file of files.controls; let i = index"
          [formGroup]="file"
        >
          <div cdkDrag class="flex-row justify-content-between">
            {{ file.value.file.name }}
            <span class="flex-row align-items-center">
              <mat-checkbox formControlName="fav" class="no-margin"
                >Favourite</mat-checkbox
              >
              <button mat-icon-button class="btn btn-flat-danger ps-1">
                <i class="ri-close-circle-fill" (click)="removeFile(i)"></i>
              </button>
            </span>
          </div>
        </form>
      </div>
    </mat-expansion-panel>

    <div
      style="margin-top: 10px"
      class="file-drop-area flex-column align-items-center"
      tsFileDrop
      #fileDrop="tsFileDrop"
      [allowedExtensions]="sample?.methodType.assetType.supportedFileExtensions"
      (filesDropped)="addFiles($event); filesPanel.open()"
    >
      <div>
        <label
          for="dirInput"
          class="float-right btn btn-flat-primary"
          data-cy="button-upload-folder"
        >
          <i class="ri-folder-upload-line"></i>
          {{ "label.upload_dir" | translate | uppercase }}
        </label>
        <input
          #dirInput
          hidden
          webkitdirectory
          id="dirInput"
          type="file"
          multiple
          (change)="fileDrop.processFiles($event.target.files)"
        />

        <label
          for="filesInput"
          class="float-right btn btn-flat-primary"
          data-cy="button-upload-files"
        >
          <i class="ri-file-upload-line"></i>
          {{ "label.upload_files" | translate | uppercase }}
        </label>
        <input
          #filesInput
          hidden
          id="filesInput"
          type="file"
          multiple
          (change)="fileDrop.processFiles($event.target.files)"
        />
      </div>
      <div
        class="file-drop-area__empty text-gray flex-column align-items-center"
      >
        <div>
          {{ "label.drop_files_here" | translate }}
        </div>
        <div class="flex-row justify-content-center">
          <span
            class="extension"
            *ngFor="
              let ext of sample?.methodType.assetType.supportedFileExtensions
            "
            >{{ ext }}</span
          >
        </div>
      </div>
    </div>
  </div>
</form>
