<nav class="navbar-light bg-light short-height my-nav flex-row justify-content-between">
  <span>
    <a class="gray-link" [routerLink]="['/support', 'faq']" rel="nofollow" data-cy="footer-faq" fragment="workspaces">{{
      'core.help' | translate
    }}</a>
    <span class="vs"></span>
    <a class="gray-link" appSupportLink rel="nofollow" target="_blank" data-cy="footer-support">{{
      'core.support' | translate
    }}</a>
  </span>

  <span>
    <span role="button" appAboutPopupTrigger data-cy="footer-about" class="gray-link">
      {{ 'label.about' | translate }}
    </span>
    <span class="vs"></span>
    <a href="https://spotlab.org" target="_blank" class="gray-link">
      © SpotLab S.L.
    </a>
  </span>
</nav>
