import { Injectable, Optional } from '@angular/core';
import { Organization, ParseFile } from '@telespot/sdk';
import { LoggerService } from '@telespot/shared/logger/feature/util';

import { AuthService } from '../auth/auth.service';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  constructor(
    protected authService: AuthService,
    protected dataService: DataService,
    @Optional() protected logger: LoggerService
  ) {}

  protected validateFileName(fileName: string): string {
    return fileName.normalize('NFD').replace(/[\u0300-\u036f()%]/g, '');
  }

  public async changeProfilePicture(file: File): Promise<void> {
    if (!file) throw new Error(`[ProfileService] changeProfilePicture failed. No file provided.`);
    const newFile = new ParseFile(this.validateFileName(file['name'] as string), file, 'image/png');
    this.authService.currentUser.avatar = await newFile.save();
    await this.dataService.save(this.authService.currentUser);
    this.logger?.info(`[ProfileService] User changed profile picture`);
  }

  public async setActiveOrganization(organization: Organization): Promise<void> {
    this.authService.setOrganization(organization);
  }
}

export class UserProfileTestingService extends UserProfileService {
  public async changeProfilePicture(file: File): Promise<void> {
    const newFile = new ParseFile(this.validateFileName(file['name'] as string), file, 'image/png');
    this.authService.currentUser.avatar = newFile;
  }
}

export const userProfileTestingServiceProvider = {
  provide: UserProfileService,
  useClass: UserProfileTestingService,
};
