<div class="card padded">
  <div class="card-header flex-row align-items-center justify-content-between pe-2">
    <h2 class="no-mb">{{ 'title.manage_invites' | translate | uppercase }}</h2>
    <button class="btn btn-outline-primary me-0 responsive" (click)="createInvite()">
      <i class="ri-add-circle-line"></i>
      {{ 'button.new_invite' | translate | uppercase }}
    </button>
  </div>
  <div class="card-body" style="padding-top: 5px">
    <table mat-table #table [dataSource]="invites$" class="spotlab-table" style="width: 100%">
      <ng-container matColumnDef="uses">
        <th mat-header-cell *matHeaderCellDef>{{ 'core.uses' | translate | uppercase }}</th>
        <td mat-cell *matCellDef="let invite">{{ invite.uses }}</td>
      </ng-container>
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>{{ 'core.code' | translate | uppercase }}</th>
        <td mat-cell *matCellDef="let invite">
          <button
            mat-icon-button
            [cdkCopyToClipboard]="invite.code"
            (cdkCopyToClipboardCopied)="showCopiedAlert()"
            matTooltip="{{ 'tooltip.copy_to_clipboard' | translate }}"
          >
            <i class="ri-file-copy-2-line text-primary"></i></button
          >{{ invite.code }}
        </td>
      </ng-container>
      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef>{{ 'core.link' | translate | uppercase }}</th>
        <td mat-cell *matCellDef="let invite">
          <ng-container *ngIf="{ mobile: showMobileLayout$ | async } as layout">
            <button
              mat-icon-button
              [cdkCopyToClipboard]="getInviteLink(invite.code)"
              (cdkCopyToClipboardCopied)="showCopiedAlert()"
              matTooltip="{{ 'tooltip.copy_to_clipboard' | translate }}"
            >
              <i class="text-primary" [class.ri-file-copy-2-line]="!layout.mobile" [class.ri-link-m]="layout.mobile"></i>
            </button>
            <ng-container *ngIf="!layout.mobile">{{ getInviteLink(invite.code) }}</ng-container>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="qr">
        <th mat-header-cell *matHeaderCellDef>{{ 'core.qr_code' | translate | uppercase }}</th>
        <td mat-cell *matCellDef="let invite">
          <button
            mat-icon-button
            (click)="showQR(invite)"
            data-cy="button-invite-qr"
            matTooltip="{{ 'tooltip.show_qr_code' | translate }}"
          >
            <i class="ri-qr-code-line text-primary"></i>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'core.actions' | translate | uppercase }}</th>
        <td mat-cell *matCellDef="let invite">
          <button mat-icon-button [matMenuTriggerFor]="menu" data-cy="menu">
            <i class="ri-more-line"></i>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item data-cy="menu-delete" (click)="removeInvite(invite)" class="flex-row align-items-center">
              <i class="ri-delete-bin-2-line text-danger"></i>
              {{ 'core.delete' | translate }}
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="['uses', 'code', 'link', 'qr', 'actions']"></tr>
      <tr mat-row *matRowDef="let invite; columns: ['uses', 'code', 'link', 'qr', 'actions']"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9999">
          {{ 'placeholder.no_invites' | translate }}
        </td>
      </tr>
    </table>
  </div>
</div>

<ng-template #qrDialogTpl let-data>
  <div class="flex-column align-items-center">
    <div class="flex-row justify-content-between align-items-center">
      <span>
        <span>{{ 'core.code' | translate }}</span>
        <span class="text-primary">{{ data.code | uppercase }}</span>
      </span>
      <span *ngIf="getQRDownloadURL(qr) as src">
        <a [attr.href]="src" download="qr_{{ data.code }}.png" mat-icon-button>
          <i class="ri-download-2-line"></i>
        </a>
        <button mat-icon-button matDialogClose>
          <i class="ri-close-line"></i>
        </button>
      </span>
    </div>
    <ngx-qrcode #qr class="m-auto" [elementType]="'url'" [value]="data.link"> </ngx-qrcode>
  </div>
</ng-template>

<ng-template #copiedAlertTpl>
  <div class="flex-row align-items-center">
    <i class="ri-clipboard-line text-primary"></i> {{ 'alert.copied_to_clipboard' | translate }}
  </div>
</ng-template>
