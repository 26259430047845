<div class="card-body" *ngIf="currentCase$ | async as currentCase">
  <!-- Users -->
  <ng-container>
    <div class="card-section-title">
      <i class="ri-group-line"></i>
      {{ "title.users" | translate | uppercase }}
    </div>
    <div class="card-section-content">
      <label for="acquired_by">{{ "label.acquired_by" | translate }}</label>
      <div aria-label="Acquired by" id="acquired_by">
        {{ currentCase?.createdBy?.username }}
      </div>
    </div>
    <div class="card-section-content">
      <label for="acquired_on">{{ "props.acquired_on" | translate }}</label>
      <div aria-label="Acquired on" id="acquired_on">
        {{ currentCase?.createdAt | date : "short" }}
      </div>
    </div>
  </ng-container>
  <div class="card-section-content card-section-content--col">
    <label for="">{{ "label.analysing" | translate }}</label>
    <div class="flex-column flex-wrap overflow-auto" aria-label="Analyzed by">
      <ng-container
        *ngIf="analysing$ | async as analysing; else unknownAnalysts"
      >
        <div
          *ngFor="let userState of analysing"
          class="flex-row align-items-center"
          style="align-content: flex-start; margin-right: 10px"
        >
          <span
            class="state-dot"
            [matTooltip]="'tooltip.' + userState?.state | translate"
            [class.state-analyzed]="userState?.state === 'analyzed'"
            [class.state-read]="userState?.state === 'read'"
            [class.state-in-progress]="userState?.state === 'inProgress'"
          ></span>
          <span class="text-ellipsis">{{ userState?.user?.username }}</span>
        </div>
      </ng-container>
      <ng-template #unknownAnalysts>
        <ng-container *ngIf="(loading$ | async) === false; else loading">
          <i>{{ "core.unknown" | translate }}</i>
        </ng-container>
      </ng-template>
      <ng-template #loading>
        <!-- TODO: reivew -->
        <i class="ri-refresh-line spin"></i>
      </ng-template>
    </div>
  </div>
  <div class="card-section-content card-section-content--col">
    <label for="">{{ "label.analyzed" | translate }}</label>
    <div class="flex-column flex-wrap overflow-auto" aria-label="Analyzed by">
      <ng-container *ngIf="analyzed$ | async as analysed; else unknownAnalysts">
        <div
          *ngFor="let userState of analysed"
          class="flex-row align-items-center"
          style="align-content: flex-start; margin-right: 10px"
        >
          <span
            class="state-dot"
            [matTooltip]="'tooltip.' + userState?.state | translate"
            [class.state-analyzed]="userState?.state === 'analyzed'"
            [class.state-read]="userState?.state === 'read'"
            [class.state-in-progress]="userState?.state === 'inProgress'"
          ></span>
          <span class="text-ellipsis">{{ userState?.user?.username }}</span>
        </div>
      </ng-container>
      <ng-template #unknownAnalysts>
        <ng-container *ngIf="(loading$ | async) === false; else loading">
          <i>{{ "core.unknown" | translate }}</i>
        </ng-container>
      </ng-template>
      <ng-template #loading>
        <!-- TODO: reivew -->
        <i class="ri-refresh-line spin"></i>
      </ng-template>
    </div>
  </div>
  <!-- Methods REVIEW: not relevant at the moment-->
  <div class="card-section-title" *ngIf="false">
    <i class="ri-bookmark-line"></i>
    {{ "core.methods" | translate | uppercase }}
  </div>
  <ng-container *ngFor="let sampleGroup of []">
    <div class="card-section-content">
      <label for="">{{ sampleGroup.methodType.name }}</label>
      <div>{{ sampleGroup.samples.length }} samples</div>
    </div>
  </ng-container>
  <!-- Second opinions -->
  <ng-container
    *ngIfRole="
      ['admin', 'analystmanager', 'analyst'];
      features: ['secondOpinion']
    "
  >
    <ng-container *ngIf="secondOpinionsAnalysis | async as secOpsAnalysis">
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
        *ngIf="secOpsAnalysis.length"
      >
        <div class="card-section-title">
          <i class="ri-question-answer-line"></i>
          {{ "title.second_opinions" | translate | uppercase }}
        </div>
        <div
          class="card-section-title reports"
          (click)="
            showReports({
              reports: secOpsAnalysis,
              caseName: currentCase?.name
            })
          "
          data-cy="second-opinion-reports"
        >
          {{ "title.see_reports" | translate | uppercase }}
          <i class="ri-article-line"></i>
        </div>
      </div>
      <ng-container *ngFor="let opinion of secOpsAnalysis">
        <div
          [attr.data-cy]="'second-user-' + opinion.analysisType?.name"
          class="card-section-content"
          *ngIf="{ x: opinion.state$ | async } as secOpState"
        >
          <!-- <label for="">{{ opinion.user.email }}</label> -->
          <div
            class="flex-row align-items-center text-ellipsis"
            style="align-content: flex-start; margin-right: 56px"
          >
            <span
              class="state-dot"
              [class.state-analyzed-sec-op]="
                secOpState?.x?.state === 'analyzed'
              "
              [class.state-pending]="secOpState?.x?.state === 'pending'"
              [class.state-in-progress]="secOpState?.x?.state === 'inProgress'"
            ></span>
            <a
              class="text-ellipsis"
              [class.disabled]="secOpState?.x?.state !== 'analyzed'"
              target="_blank"
              [href]="
                'analyze/cases/' +
                currentCase?.id +
                '/samples/' +
                opinion.origSampleId +
                '?historyId=' +
                secOpState?.x?.id
              "
              >{{ opinion?.user?.displayName }}</a
            >
          </div>
          <div
            class="flex-row align-items-center text-ellipsis"
            style="align-content: flex-end"
          >
            <span class="text-ellipsis display-name">{{
              opinion.analysisType?.displayName | uppercase
            }}</span>

            <div class="btn-group" style="flex-shrink: 1">
              <button
                mat-icon-button
                [attr.data-cy]="
                  'second-opinion-menu-' + opinion.analysisType?.name
                "
                class="btn btn-flat-primary"
                style="margin-left: 10px"
                [matMenuTriggerFor]="menu"
              >
                <i class="ri-more-line"></i>
              </button>
              <mat-menu #menu="matMenu">
                <a
                  class="flex-row align-items-center"
                  [routerLink]="['samples/', opinion.origSampleId]"
                  [queryParams]="{ historyId: secOpState?.x?.id }"
                  [disabled]="secOpState?.x?.state !== 'analyzed'"
                  mat-menu-item
                >
                  <i class="ri-eye-line"></i>
                  <span>{{ "core.view" | translate | uppercase }}</span>
                </a>
                <button
                  *ngIf="false"
                  mat-menu-item
                  [disabled]="secOpState?.x?.state === 'analyzed'"
                  (click)="
                    resendSecondOpinion({
                      caseId: currentCase?.id,
                      analysisStateId: secOpState?.x?.id
                    })
                  "
                  class="flex-row align-items-center"
                >
                  <i class="ri-mail-send-line"></i>
                  <span>{{ "core.resend" | translate | uppercase }}</span>
                </button>
                <button
                  [attr.data-cy]="
                    'second-opinion-delete-' + opinion.analysisType?.name
                  "
                  class="flex-row align-items-center"
                  mat-menu-item
                  (click)="
                    deleteSecondOpinion({ analysisStateId: secOpState?.x?.id })
                  "
                >
                  <i class="ri-delete-bin-6-line text-danger"></i>
                  <span class="text-danger">{{
                    "core.delete" | translate | uppercase
                  }}</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<div class="card-body" style="margin-top: auto">
  <ng-content></ng-content>
</div>
