import {
  CdkVirtualScrollViewport,
  FixedSizeVirtualScrollStrategy,
  VIRTUAL_SCROLL_STRATEGY,
} from "@angular/cdk/scrolling";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Injectable,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  analysisState,
  assetsFiltered,
  AssetState,
  bookmarkedAssetIndex$,
  ISampleItem,
  loadAssetIndex,
  requestStripData,
  selectAssetIndex,
} from "@telespot/analysis-refactor/data-access";

import { combineLatest, Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

@Injectable()
export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(90, 45, 45);
  }
}

@Component({
  selector: "ts-sample-reference-strip",
  templateUrl: "./sample-reference-strip.component.html",
  styleUrls: ["./sample-reference-strip.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SampleReferenceStripComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  private analysisState$ = this._store.select(analysisState);
  private _maxIndex: number;
  private _destroy$ = new Subject<void>();

  public readonly elements$: Observable<ISampleItem[]> =
    this._store.select(assetsFiltered);
  public readonly selectedIndex$: Observable<number> =
    this._store.select(selectAssetIndex);
  public readonly bookmarkedAssetIndex$: Observable<number> =
    this._store.select(bookmarkedAssetIndex$);

  constructor(private _store: Store) {}

  ngOnInit() {
    this.elements$
      .pipe(takeUntil(this._destroy$))
      .subscribe((elements) => (this._maxIndex = elements.length - 1));
  }

  ngAfterViewInit() {
    combineLatest([this.analysisState$, this.viewPort.renderedRangeStream])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([_analysisState, limits]) => {
        const request = {
          createdBy:
            _analysisState?.user?.toPointer() ??
            _analysisState?.algorithm?.toPointer(),
          sampleId: _analysisState?.sample?.id,
          limits,
        };
        this._store.dispatch(
          requestStripData({ requestRefStripData: request })
        );
      });

    this.selectedIndex$.subscribe((index) => {
      this.viewPort.scrollToIndex(index, "smooth");
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent): void {
    this.selectedIndex$.pipe(take(1)).subscribe((currentIndex) => {
      this.handleKeyPress(event, currentIndex);
    });
  }

  selectItem(index: number) {
    this._store.dispatch(loadAssetIndex({ index }));
  }

  isAssetProcessing(state: AssetState) {
    return state === AssetState.PROCESSING ? true : false;
  }

  private handleKeyPress(event: KeyboardEvent, currentIndex: number): void {
    switch (event.key) {
      case "ArrowRight":
        this.selectItem(
          currentIndex + 1 > this._maxIndex ? this._maxIndex : currentIndex + 1
        );
        break;
      case "ArrowLeft":
        this.selectItem(currentIndex - 1 < 0 ? 0 : currentIndex - 1);
        break;
    }
  }
}
