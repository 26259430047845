<h1 class="title">
  {{ "title.specify_language" | translate }}
</h1>
<form [formGroup]="form" *ngIf="form">
  <ts-language-input
    [languages]="supportedLanguages"
    formControlName="optionName"
  ></ts-language-input>
  <mat-error *ngIf="form.get('optionName').hasError('optionNameInvalid')">
    Please provide an option for "en" language.
  </mat-error>
</form>
<div class="flex-row button-container">
  <button class="btn btn-outline-danger" (click)="close()" type="button">
    <i class="ri-close-fill"></i>
    {{ "button.cancel" | translate }}
  </button>
  <button class="btn btn-primary" (click)="submit()" [disabled]="!form.valid">
    <i class="ri-save-line"></i>
    {{ "button.save" | translate }}
  </button>
</div>
