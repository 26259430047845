<div *ngIf="cases$ | async as cases">
  <div class="list-tools flex-row align-items-center w-100 justify">
    <div class="flex-grow-1 flex-row align-items-center">
      <!-- FIXME: could be removed if unsued -->
      <ts-cases-filter
        *ngIf="!tiraspot"
        [iconFocus]="showDateRangePicker"
        (clicked)="showDateRangePicker = !showDateRangePicker"
      ></ts-cases-filter>
      <ts-cases-search
        data-cy="table-search"
        class="flex-grow-1 mw-100"
        (clicked)="showDateRangePicker = false"
        [iconFocus]="!showDateRangePicker"
      ></ts-cases-search>
    </div>
    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      *ngIf="(mobile$ | async) === false"
      (page)="changePage($event)"
      [length]="numCases$ | async"
    ></mat-paginator>
  </div>
  <button
    *ngIf="tiraspot && (tiraspotIAEnabled$ | async)"
    (click)="showWarnings = !showWarnings"
    class="btn btn-flat-primary flex-row align-items-center warning-icon text-danger"
    style="cursor: pointer; width: auto"
  >
    <i
      [class.ri-spam-line]="!showWarnings"
      [class.ri-spam-fill]="showWarnings"
    ></i>
    {{
      (showWarnings
        ? "button.hide_tiraspot_warnings"
        : "button.show_tiraspot_warnings"
      )
        | translate
        | uppercase
    }}
  </button>

  <div
    class="table-container"
    *ngIf="columnsToDisplay$ | async as columnsToDisplay"
  >
    <table
      mat-table
      [dataSource]="cases"
      width="100%"
      matSort
      (matSortChange)="sortCases($event)"
    >
      <!-- start of tiraspot -->
      <ng-container
        *ngIf="getColumn('username') as column"
        [matColumnDef]="column.name"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [mat-sort-header]="column.name"
          [disabled]="!column.sorting"
          style="width: 30%"
        >
          {{ column.displayName | translate | uppercase }}
          <ts-cases-filter
            *ngIf="column.filtering"
            [filters]="column.filters"
            [iconFocus]="showFilters[column?.name]"
            (clicked)="toggleFilter(column.name)"
          ></ts-cases-filter>
        </th>
        <td mat-cell *matCellDef="let d">
          {{ d.sampleAsset?.asset?.case?.name }}
        </td>
      </ng-container>

      <ng-container
        *ngIf="getColumn('test') as column"
        [matColumnDef]="column.name"
      >
        <th
          mat-header-cell
          [mat-sort-header]="column.name"
          [disabled]="!column.sorting"
          *matHeaderCellDef
          style="width: 30%"
        >
          {{ column.displayName | translate | uppercase }}
          <ts-cases-filter
            *ngIf="column.filtering"
            [filters]="column.filters"
            [iconFocus]="showFilters[column?.name]"
            (clicked)="toggleFilter(column.name)"
          ></ts-cases-filter>
        </th>
        <td mat-cell *matCellDef="let d">
          {{ d.sampleAsset?.asset?.data?.tiraspot?.poct }}
        </td>
      </ng-container>

      <ng-container
        *ngIf="getColumn('user_results') as column"
        [matColumnDef]="column.name"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [disabled]="!column.sorting"
          [mat-sort-header]="column.name"
          style="width: 30%"
        >
          {{ column.displayName | translate | uppercase }}
          <ts-cases-filter
            *ngIf="column.filtering"
            [filters]="column.filters"
            [iconFocus]="showFilters[column?.name]"
            (clicked)="toggleFilter(column.name)"
          ></ts-cases-filter>
        </th>
        <td mat-cell *matCellDef="let d">
          <ng-container *ngFor="let result of getTiraspotAnswer(d, 'user')">
            <span
              [ngClass]="[
                'poct-result',
                getTiraspotAnswerStyle(result),
                'margin'
              ]"
              [ngStyle]="getTiraspotAnswerStyleConfig(result)"
              data-cy="poct-user-result"
            >
              {{ result | translate | uppercase }}
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container
        *ngIf="getColumn('tiraspot_result') as column"
        [matColumnDef]="column.name"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [disabled]="!column.sorting"
          [mat-sort-header]="column.name"
          style="width: 30%"
        >
          {{ column.displayName | translate | uppercase }}
          <ts-cases-filter
            *ngIf="column.filtering"
            [filters]="column.filters"
            [iconFocus]="showFilters[column?.name]"
            (clicked)="toggleFilter(column.name)"
          ></ts-cases-filter>
        </th>
        <td mat-cell *matCellDef="let d">
          <ng-container *ngIf="getTiraspotAnswer(d, 'final') as finalResult">
            <ng-container *ngFor="let result of finalResult">
              <span
                [ngClass]="[
                  'poct-result',
                  getTiraspotAnswerStyle(result),
                  'margin'
                ]"
                [ngStyle]="getTiraspotAnswerStyleConfig(result)"
                data-cy="poct-user-result"
              >
                {{ result | translate | uppercase }}
              </span>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <ng-container
        *ngIf="getColumn('ai_results') as column"
        [matColumnDef]="column.name"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [disabled]="!column.sorting"
          [mat-sort-header]="column.name"
          style="width: 30%"
        >
          {{ column.displayName | translate | uppercase }}
          <ts-cases-filter
            *ngIf="column.filtering"
            [filters]="column.filters"
            [iconFocus]="showFilters[column?.name]"
            (clicked)="toggleFilter(column.name)"
          ></ts-cases-filter>
        </th>
        <td mat-cell *matCellDef="let d">
          <ng-container *ngFor="let result of getTiraspotAnswer(d, 'ai')">
            <div
              [ngClass]="[
                'poct-result',
                getTiraspotAnswerStyle(result),
                'margin'
              ]"
              [ngStyle]="getTiraspotAnswerStyleConfig(result)"
              data-cy="poct-ai-result"
            >
              {{ result | translate | uppercase }}
            </div>
          </ng-container>
        </td>
      </ng-container>

      <ng-container
        *ngIf="getColumn('created') as column"
        matColumnDef="dateOfCapture"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [disabled]="!column.sorting"
          [mat-sort-header]="column.name"
          style="width: 30%"
        >
          {{ column.displayName | translate | uppercase }}
          <ts-cases-filter
            *ngIf="column.filtering"
            [filters]="column.filters"
            [iconFocus]="showFilters[column?.name]"
            (clicked)="toggleFilter(column.name)"
          ></ts-cases-filter>
        </th>
        <td mat-cell *matCellDef="let d">
          <div
            style="line-height: 32px"
            class="flex-row align-items-center justify-content-start"
          >
            <ng-container
              *ngIf="{
                user: d.sampleAsset?.asset?.createdBy?.username,
                date: d.sampleAsset?.dateOfCapture | date : 'dd/MM/yyyy hh:mm a'
              } as params"
            >
              <ts-user-avatar
                [user]="d.sampleAsset?.asset?.createdBy"
                matTooltip="{{
                  'label.acquired_by_x_on_y' | translate : params
                }}"
              ></ts-user-avatar>
            </ng-container>
            <span style="margin-left: 10px">{{
              d.sampleAsset?.dateOfCapture | date : "short"
            }}</span>
          </div>
        </td>
      </ng-container>
      <ng-container [matColumnDef]="'move_columns'">
        <th mat-header-cell *matHeaderCellDef style="width: 30%">
          <i
            class="ri-layout-column-line"
            (click)="showColumns = !showColumns"
            style="cursor: pointer"
          ></i>
          <span style="height: 32px; background-color: #fff; padding: 0px">
            <ng-container *ngIf="showColumns">
              <div class="overlay-panel">
                <section
                  class="flex-column justify-content-start align-items-start"
                >
                  <mat-checkbox
                    *ngFor="let column of columnsToShow"
                    [id]="column.name"
                    [value]="column.name"
                    [checked]="column.checked"
                    (change)="
                      column.checked = !column.checked; updateColumns($event)
                    "
                    >{{ column.displayName | translate }}</mat-checkbox
                  >
                </section>
              </div>
            </ng-container>
          </span>
        </th>
        <td mat-cell *matCellDef="let d" style="text-align: center">
          <ng-container [ngSwitch]="d.warning" *ngIf="showWarnings">
            <ng-container *ngSwitchCase="'disagreement'">
              <i
                class="text-danger ri-spam-line"
                [matTooltip]="
                  'tooltip.tiraspot_user_AI_disagreement' | translate
                "
                data-cy="user-ai-disagreement-badge"
              ></i>
            </ng-container>
            <ng-container *ngSwitchCase="'corrected'">
              <i
                class="text-green ri-checkbox-circle-fill"
                [matTooltip]="'tooltip.tiraspot_user_AI_corrected' | translate"
                data-cy="user-ai-correction-badge"
              ></i>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>
      <!-- end of tiraspot -->

      <ng-container matColumnDef="currentStateIndicator">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          id="state-header-cell"
        ></th>
        <td mat-cell style="padding: 0" *matCellDef="let item">
          <div class="status-cell-container">
            <ng-container
              *ngIf="item.globalState as globalState; else defaultState"
            >
              <div
                class="status-indicator-container"
                [ngClass]="{
                  pending: globalState === 'pending',
                  analyzed: globalState === 'analyzed',
                  closed: globalState === 'closed',
                  inprogress: globalState === 'inProgress'
                }"
              >
                <div class="status-indicator"></div>
              </div>
            </ng-container>
            <ng-template #defaultState>
              <div class="status-indicator-container pending">
                <div class="status-indicator"></div>
              </div>
            </ng-template>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="currentState">
        <th mat-header-cell *matHeaderCellDef>
          {{ "state" | translate | uppercase }}
        </th>
        <td
          mat-cell
          style="padding: 0"
          *matCellDef="let item"
          style="color: black"
        >
          {{ item.globalState | translate | uppercase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef>
          {{ "core.label" | translate | uppercase }}
        </th>
        <td mat-cell style="padding: 0" *matCellDef="let item">
          <ng-container
            *ngIf="item.currentState?.state === 'review'; else noReview"
          >
            <span class="label label-review">{{
              "button.review" | translate
            }}</span>
          </ng-container>
          <ng-template #noReview>
            <ng-container *ngIf="item.userState as userState">
              <span class="label label-new" *ngIf="userState === 'pending'">{{
                "label.new" | translate
              }}</span>
            </ng-container>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="label_mobile">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="min-width: 36px; text-align: center"
        >
          <i class="ri-bookmark-2-line"></i>
        </th>
        <td mat-cell style="padding: 0" *matCellDef="let item">
          <ng-container
            *ngIf="item.currentState?.state === 'review'; else noReview"
          >
            <span class="label label-review label-icon-only">
              <i class="ri-flag-2-fill"></i>
            </span>
          </ng-container>
          <ng-template #noReview>
            <span
              class="label label-new label-icon-only"
              *ngIf="!(item.userState === 'pending')"
            >
              <i class="ri-record-circle-fill"></i>
            </span>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="width: 30%"
        >
          {{ "case_id" | translate | uppercase }}
        </th>
        <td mat-cell style="padding: 0" *matCellDef="let item">
          {{ item.case.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "label.created" | translate | uppercase }}
        </th>
        <td mat-cell style="padding: 0" *matCellDef="let item">
          <div
            style="line-height: 32px"
            class="flex-row align-items-center justify-content-start"
          >
            <ng-container
              *ngIf="{
                user: item.case.createdBy?.username,
                date: item.case.createdAt | date : 'dd/MM/yyyy hh:mm a'
              } as params"
            >
              <ts-user-avatar
                [user]="item.case.createdBy"
                matTooltip="{{
                  'label.acquired_by_x_on_y' | translate : params
                }}"
              ></ts-user-avatar>
            </ng-container>
            <span style="margin-left: 10px">{{
              item.case.createdAt | date : "dd/MM/yyyy hh:mm a"
            }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt_mobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "label.created" | translate | uppercase }}
        </th>
        <td mat-cell style="padding: 0" *matCellDef="let item">
          <div
            style="line-height: 32px"
            class="flex-row align-items-center justify-content-start"
          >
            <ng-container
              *ngIf="{
                user: item.case.createdBy?.username,
                date: item.case.createdAt | date : 'dd/MM/yyyy hh:mm a'
              } as params"
            >
              <ts-user-avatar
                [user]="item.case.createdBy"
                matTooltip="{{
                  'label.acquired_by_x_on_y' | translate : params
                }}"
              ></ts-user-avatar>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="numAssets">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="width: 10px"
        >
          {{ "num_assets" | translate | uppercase }}
        </th>
        <td mat-cell style="padding: 0" *matCellDef="let item">
          {{ item.case.numAssets }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "core.role_technician" | translate | uppercase }}
        </th>
        <td mat-cell style="padding: 0" *matCellDef="let item">
          {{ item.case.createdBy?.username }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="width: 130px"
        >
          {{ "analyzed" | translate | uppercase }}
        </th>
        <td mat-cell style="padding: 0" *matCellDef="let item">
          <div class="flex-row align-items-center justify-content-start">
            <ng-container
              *ngIf="{
                userStates: item.userStates,
                mobile: mobile$ | async
              } as state"
            >
              <ts-user-avatar
                *ngIf="
                  state.userStates.length > (state.mobile ? 4 : 8);
                  else allUserStates
                "
                [matTooltip]="
                  'tooltip.x_users' | translate : { x: state.userStates.length }
                "
              >
                {{ state.userStates.length }}
              </ts-user-avatar>
              <ng-template #allUserStates>
                <ng-container *ngFor="let userState of state.userStates">
                  <ng-container
                    *ngIf="{
                      state: userState.state | translate,
                      user: userState.user?.username,
                      date: userState.createdAt | date : 'dd/MM/yyyy hh:mm a'
                    } as params"
                  >
                    <ts-user-avatar
                      [user]="userState.user"
                      matTooltip="{{
                        'label.$state_by_$user_on_$date' | translate : params
                      }}"
                      [class.in-progress]="
                        userState.state === 'inProgress' ||
                        userState.state === 'read'
                      "
                      [class.analyzed]="userState.state === 'analyzed'"
                    >
                      ?
                    </ts-user-avatar>
                  </ng-container>
                </ng-container>
              </ng-template>
            </ng-container>
          </div>
          <div class="actions-cell" *ngIf="false">
            <ng-container [ngSwitch]="item.currentState?.state">
              <ng-container
                *ngIfRole="
                  ['admin', 'analyst', 'analystmanager'];
                  else noAnalyst
                "
              >
                <ng-container
                  *ngSwitchCase="'pending'"
                  [ngTemplateOutlet]="caseActionAnalyze"
                >
                </ng-container>
                <ng-container
                  *ngSwitchCase="'read'"
                  [ngTemplateOutlet]="caseActionAnalyze"
                >
                </ng-container>
                <ng-container
                  *ngSwitchCase="'review'"
                  [ngTemplateOutlet]="caseActionAnalyze"
                >
                </ng-container>
                <ng-container
                  *ngSwitchCase="'analyzed'"
                  [ngTemplateOutlet]="caseActionReport"
                >
                </ng-container>
              </ng-container>
              <ng-template #noAnalyst>
                <ng-container
                  *ngSwitchCase="'pending'"
                  [ngTemplateOutlet]="caseActionView"
                >
                </ng-container>
                <ng-container
                  *ngSwitchCase="'read'"
                  [ngTemplateOutlet]="caseActionView"
                >
                </ng-container>
                <ng-container *ngSwitchDefault [ngTemplateOutlet]="finished">
                </ng-container>
              </ng-template>

              <ng-template #finished>
                <span class="flex-row align-items-center text-gray disabled">
                  <i class="ri-checkbox-line"></i>
                  <a>
                    {{ "core.finished" | translate | uppercase }}
                  </a>
                </span>
              </ng-template>
            </ng-container>

            <ng-template #caseActionView>
              <span
                class="flex-row align-items-center"
                style="text-align: center"
                [routerLink]="['/analyze', 'cases', item.case.id]"
              >
                <i class="ri-restart-line"></i>
                <a>
                  {{ "button.view" | translate | uppercase }}
                </a>
              </span>
            </ng-template>

            <ng-template #caseActionAnalyze>
              <ng-container *ngIf="item.userState || '???' as userState">
                <span
                  class="flex-row align-items-center"
                  style="text-align: center"
                  [routerLink]="['/analyze', 'cases', item.case.id]"
                  [class.text-secondary]="
                    userState === 'analyzed' &&
                    item.case.currentState?.state !== 'review'
                  "
                >
                  <i
                    class="ri-eye-line"
                    [class.ri-flag-line]="
                      item.case.currentState?.state === 'review'
                    "
                    [class.text-warning]="
                      item.case.currentState?.state === 'review'
                    "
                    [matTooltip]="
                      (item.case.currentState?.state === 'review'
                        ? 'tooltip.review_case'
                        : ''
                      ) | translate
                    "
                  >
                  </i>
                  <a>
                    <ng-container [ngSwitch]="userState">
                      <span
                        *ngSwitchCase="'inProgress'"
                        [matTooltip]="
                          'tooltip.continue_case_analysis' | translate
                        "
                      >
                        {{ "button.continue" | translate | uppercase }}
                      </span>
                      <span
                        *ngSwitchCase="'analyzed'"
                        [matTooltip]="
                          'tooltip.review_case_analysis' | translate
                        "
                      >
                        {{ "button.review" | translate | uppercase }}
                      </span>
                      <span
                        *ngSwitchDefault
                        [matTooltip]="'tooltip.analyze_case' | translate"
                      >
                        {{ "button.analyze" | translate | uppercase }}
                      </span>
                    </ng-container>
                  </a>
                </span>
              </ng-container>
            </ng-template>

            <ng-template #caseActionReport>
              <span
                class="flex-row align-items-center"
                style="text-align: center"
                *ngIfRole="['admin', 'analystmanager']; else finished"
                [routerLink]="['/reports', 'cases', item.case.id]"
              >
                <i class="ri-article-line"></i>
                <a>
                  {{ "Report" | translate | uppercase }}
                </a>
              </span>
            </ng-template>

            <div class="btn-group" *ngIfRole>
              <span
                style="
                  margin-left: 10px;
                  height: 100%;
                  width: 20px;
                  border-left: 1px solid rgb(241, 241, 241);
                "
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <!-- <span class="sr-only">Toggle Dropdown</span> -->
                <i class="ri-arrow-down-s-line"></i>
              </span>
              <div class="dropdown-menu">
                <!-- TODO: re-enable edit when editing is allowed -->
                <a class="dropdown-item" *ngIf="false">
                  <i class="ri-pencil-line"></i>
                  <a>{{ "Edit" | translate | uppercase }}</a>
                </a>
                <!-- <div class="dropdown-divider"></div> -->
                <a class="dropdown-item" (click)="removeCase(item.case)">
                  <i class="ri-delete-bin-6-line text-danger"></i>
                  <a>{{ "Remove" | translate | uppercase }}</a>
                </a>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="action-icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell style="padding: 0" *matCellDef="let item">
          <ng-container
            [ngSwitch]="globalState"
            *ngIf="item.globalState as globalState; else defaultState"
          >
            <ng-container *ngSwitchCase="'pending'">
              <i class="ri-eye-line"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'inProgress'">
              <i class="ri-refresh-line"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'analyzed'">
              <i class="ri-article-line"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'closed'">
              <i class="ri-article-line"></i>
            </ng-container>
          </ng-container>
          <ng-template #defaultState>
            <i class="ri-eye-line"></i>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="menu" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          style="padding: 0"
          *matCellDef="let item"
          width="30px"
          style="width: 32px; padding: 0px 10px 0px 4px"
          (click)="$event.stopPropagation()"
        >
          <div class="btn-group" *ngIfRole="['admin']" style="flex-shrink: 1">
            <button
              mat-icon-button
              style="margin-left: 10px; height: 100%; width: 20px"
              [matMenuTriggerFor]="menu"
            >
              <i class="ri-more-line"></i>
            </button>
            <mat-menu #menu="matMenu">
              <!-- <button mat-menu-item *ngIfRole="true" [routerLink]="['/acquire','edit',case.id]">
                <i class="ri-pencil-line"></i>
                <a>{{ 'Edit' | translate | uppercase }}</a>
              </button> -->
              <button
                mat-menu-item
                (click)="removeCase(item.case)"
                data-cy="menu-remove"
              >
                <i
                  style="vertical-align: middle"
                  class="ri-delete-bin-6-line text-danger"
                ></i>
                <a>{{ "Remove" | translate | uppercase }}</a>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let myRowData; columns: columnsToDisplay"
        data-cy="case-row"
        class="caseRow"
        [class.warning]="showWarnings && myRowData.warning === 'disagreement'"
        [class.corrected]="showWarnings && myRowData.warning === 'corrected'"
        [routerLink]="getRoute(myRowData)"
        [queryParamsHandling]="getRoute(myRowData) === null ? 'preserve' : ''"
      ></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9999">
          <ng-container *ngIf="filter">
            <span data-cy="no-results">{{
              "placeholder.no_data_exact_term" | translate : { term: filter }
            }}</span>
          </ng-container>
          <b data-cy="use-exact-term" class="ms-2" *ngIf="encrypted">{{
            "label.encrypted_casename_use_exact_term" | translate
          }}</b>
        </td>
      </tr>
    </table>
  </div>
  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    (page)="changePage($event)"
    [length]="numCases$ | async"
  ></mat-paginator>
</div>
