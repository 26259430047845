import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Directive, ElementRef, HostBinding, HostListener, Input, NgModule, Optional, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LoggerService } from '@telespot/shared/logger/feature/util';
import { map } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[authHref]',
})
export class SecureHrefDirective {
  private _links: { [key: string]: string } = {};

  private _href: string;
  @Input() @HostBinding('attr.href') set href(href: string) {
    if (href) {
      this._links[href] = undefined;
    }
    this._href = href;
  }
  get href() {
    return this._href;
  }

  @HostBinding('class.link-loading') private _loading: boolean;

  @HostListener('click', ['$event']) async onclick($event: Event) {
    if (this.href && !this._links[this.href]) {
      this._loading = true;
      $event.preventDefault();
      try {
        const authorizedLink: SafeUrl = await this._http
          .get(this.href, { responseType: 'blob' })
          .pipe(map((e) => this._domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))))
          .toPromise();
        const authorizedURL = this._domSanitizer.sanitize(SecurityContext.URL, authorizedLink);
        this._links[this.href] = authorizedURL;
        this._logger?.debug(`[authRef] href authorized`, this.href);
        this._elementRef.nativeElement.setAttribute('href', authorizedURL);
        this._elementRef.nativeElement.click();
      } catch (err) {
        this._logger?.error(`[authRef] failed to process href attribute`, err.message);
        throw err;
      }
      this._loading = false;
    }
  }

  constructor(
    private _http: HttpClient,
    private _domSanitizer: DomSanitizer,
    private _elementRef: ElementRef<any>,
    @Optional() private _logger: LoggerService
  ) {}
}

@NgModule({
  declarations: [SecureHrefDirective],
  imports: [CommonModule, HttpClientModule],
  exports: [SecureHrefDirective],
  providers: [],
})
export class SecureHrefDirectiveModule {}
