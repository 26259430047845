import { ExportedFinding, ExportedFindingsRepository, FindFindingsQuery } from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";
import { LatestVersionTopology } from "../latest-version.topology";
import { AnalysisTopology, FindingTopology } from "../analysis";
import { ObjectTopology } from "../../parse.topology";
import { SampleAssetTopology, SampleQueryBuilder, SampleTopology } from "../acquisition";
import { CaseTopology, WorkspaceTopology } from "../workspace";
import { ParseExportedFindingMapper } from "./parse-exported-finding.mapper";

export class ParseExportedFindingRepository extends ParseBaseRepository implements ExportedFindingsRepository {

  public async *find(query: FindFindingsQuery): AsyncGenerator<ExportedFinding[]> {
    const { limit, aggregate } = query;

    const parseQuery = this.mapToFindingQuery(query);

    const batches = this.getTokenPaginationGenerator({ query: parseQuery, limit });

    // non-hardcoded value for the samples graph all the way up to organization
    const sampleFieldToInclude = [
      SampleAssetTopology.SAMPLE,
      SampleTopology.CASE,
      CaseTopology.WORKSPACE,
      WorkspaceTopology.ORGANIZATION,
    ].join(".")

    for await (const batch of batches) {
      if (!aggregate) {
        yield batch.map(f => ParseExportedFindingMapper.toDomain(f));
        continue;
      };

      const sampleAssets = [];

      const assetRefList = batch.map(f => f.get(FindingTopology.ANALYSIS).get(AnalysisTopology.ASSET));

      const sampleAssetQuery = this
        .mapToSampleAssetQuery(query)
        .containedIn(SampleAssetTopology.ASSET, assetRefList)
        .include([sampleFieldToInclude]);

      const saIterator = this.getTokenPaginationGenerator({ query: sampleAssetQuery, limit });

      for await (const saBatch of saIterator) sampleAssets.push(...saBatch);

      const exportedFindings = batch.map(f => {
        const findingAssetId = f.get(FindingTopology.ANALYSIS).get(AnalysisTopology.ASSET).id
        const sampleAsset = sampleAssets.find(sa => sa.get(SampleAssetTopology.ASSET).id === findingAssetId);

        return ParseExportedFindingMapper.toDomain(f, sampleAsset);
      })

      yield exportedFindings;
    };
  }

  public count(query: FindFindingsQuery): Promise<number> {
    return this.mapToFindingQuery(query).count(this.options);
  }

  private mapToFindingQuery(query: FindFindingsQuery) {
    const sampleAssetQuery = this.mapToSampleAssetQuery(query);

    const analysisQuery = new this.parse.Query(AnalysisTopology.TABLE)
      .matchesKeyInQuery(AnalysisTopology.ASSET, SampleAssetTopology.ASSET, sampleAssetQuery)

    const latestVersionQuery = new this.parse.Query(LatestVersionTopology.TABLE)
      .equalTo(LatestVersionTopology.ENTITY, FindingTopology.TABLE)

    const findingQuery = new this.parse.Query(FindingTopology.TABLE)
      .matchesQuery(FindingTopology.ANALYSIS, analysisQuery);

    if (query.latestOnly) findingQuery
      .matchesKeyInQuery(ObjectTopology.ID, LatestVersionTopology.VERSION_ID, latestVersionQuery);

    findingQuery.include(FindingTopology.ANALYSIS);

    return findingQuery;
  }

  private mapToSampleAssetQuery(query: FindFindingsQuery) {
    const { level, ids } = query;

    const sampleQuery = new SampleQueryBuilder(this.parse)
      .withLevel(level)
      .withIds(ids)
      .build();

    const sampleAssetQuery = new this.parse.Query(SampleAssetTopology.TABLE)
      .matchesQuery(SampleAssetTopology.SAMPLE, sampleQuery);

    return sampleAssetQuery;
  }
}
