import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AuthService, DataService } from '@telespot/web-core';
import { Affiliate, Member, Query, User } from '@telespot/sdk';
import { Role } from 'parse';
import { defer, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

interface IUserData {
  user: User;
  numWorkspaces$: Observable<number>;
  affiliateSince: Date;
}

@Component({
  selector: 'ts-user-stats',
  templateUrl: './user-stats.component.html',
  styleUrls: ['./user-stats.component.scss'],
})
export class UserStatsComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  constructor(
    private _dataService: DataService,
    private _authService: AuthService,
    private _route: ActivatedRoute,
    private _breakpoint: BreakpointObserver
  ) {}

  private _roles: { user: User; roles: Role[] }[] = [];
  visibleColumns$: Observable<string[]> = this._breakpoint
    .observe(['(max-width: 420px)'])
    .pipe(map((match) => (match.matches ? ['userNoAvatar', 'roles'] : ['user', 'userSince', 'roles', 'workspaces'])));
  dataSource: MatTableDataSource<IUserData> = new MatTableDataSource([]);

  ngOnInit() {
    if (!this._route.parent) return;
    this._route.parent.data.pipe(takeUntil(this._destroy$)).subscribe(async (data) => {
      const organization = data.organization;
      if (organization) {
        const affiliates = await this._dataService
          .find(new Query(Affiliate).equalTo('organization', organization).include('user'))
          .then((affiliates) => affiliates.filter((affiliate) => !!affiliate.user));
        await Promise.all(
          affiliates.map((affiliate) =>
            this._authService.getUserRoles(affiliate.user).then((roles) => (affiliate.user.roles = roles))
          )
        );
        this.dataSource.data = affiliates.map((affiliate) => ({
          user: affiliate.user,
          numWorkspaces$: defer(() =>
            this._dataService.count(new Query(Member).equalTo('user', affiliate.user.toPointer()))
          ),
          affiliateSince: affiliate.createdAt,
        }));
      } else {
        this.dataSource.data = [];
      }
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  getRoles(user: User): Role[] {
    const item = this._roles.find((r) => r.user === user);
    return item ? item.roles : [];
  }
}
