import { createAction, props } from "@ngrx/store";
import {
  AnalysisState,
  Asset,
  Case,
  IAssetROIWithModels,
  RoiModel,
  Sample,
} from "@telespot/sdk";
import { ISampleItem } from "../models/i-sample-item";
import { Mode } from "../state";

import { AssetFilters } from "./rois.state";
export interface requestAnalysis {
  assetId: string;
  createdBy: Parse.Pointer;
  sampleId: string;
  sampleAnalysis: boolean;
}

export interface requestRefStripData {
  createdBy: Parse.Pointer;
  sampleId: string;
  limits: Limits;
}

export interface Limits {
  end: number;
  start: number;
}

export interface refStripDataResponse {
  assetId: string;
  reviewed: boolean;
  fav: boolean;
  analyzed: boolean;
  favAnalysis: boolean;
}

export const clearROIState = createAction("[ROIS] Clear rois");
export const setSelectedSample = createAction(
  "[ROIS] Set sample",
  props<{ selectedSample: Sample }>()
);
export const extractAssetsInfo = createAction(
  "[ROIS] Extract sample assets info",
  props<{ selectedSample: Sample; skip: number }>()
);
export const fetchAssetInfo = createAction(
  "[ROIS] Extract sample assets info",
  props<{ selectedSample: Sample; skip: number }>()
);
export const setNumAssets = createAction(
  "[ROIS] Set number of assets from sample",
  props<{ numAssets: number }>()
);
export const setAsset = createAction(
  "[ROIS] Change asset",
  props<{ asset: Asset }>()
);

export const assetLoaded = createAction(
  "[ROIS] Asset loaded",
  props<{ asset: Asset }>()
);

export const registerModels = createAction(
  "[ROIS] Register models",
  props<{ models: RoiModel[]; replace?: boolean }>()
);
export const selectModels = createAction(
  "[ROIS] Select model",
  props<{ models: RoiModel[]; replace?: boolean }>()
);

//review
export const acceptAIROIs = createAction(
  "[ROIS] Accept AI ROIs",
  props<{ rois: IAssetROIWithModels[] }>()
);

export const updateSampleAnalysisState = createAction(
  "[ROIS] Update Analysis State",
  props<{ historyId: string; sample: string }>()
);
export const sampleAnalysisStateFetched = createAction(
  "[ROIS] Analysis State succesfully fetched",
  props<{ analysisState: AnalysisState }>()
);

export const bookmarkAsset = createAction(
  "[ROIS] Bookmark current asset",
  props<{ assetId: string; analysisState: AnalysisState }>()
);

export const createAnalysisState = createAction(
  "[ROIS] Create Analysis State",
  props<{ sampleId: string }>()
);
export const checkCaseAnalysisState = createAction(
  "[ROIS] Check if Case Analysis State exists",
  props<{ caseId: string }>()
);
export const createCaseAnalysisState = createAction(
  "[ROIS] Create Case Analysis State",
  props<{ caseId: string }>()
);
export const caseAnalysisStateFetched = createAction(
  "[ROIS] Case Analysis State fetched",
  props<{ analysisState: AnalysisState }>()
);
export const roisActionError = createAction(
  "[ROIS] Processing failed",
  props<{ error: string }>()
);

export const loadRefStripElements = createAction(
  "[REFERENCE STRIP] Load reference strip elements into state",
  props<{ refStripItems: ISampleItem[] }>()
);
export const markItemAsViewed = createAction(
  "[REFERENCE STRIP] Mark strip item as viewed",
  props<{ assetId: string }>()
);
export const setFavAnalysis = createAction(
  "[REFERENCE STRIP] Toggle favAnalysis",
  props<{ assetId: string; analysisState: AnalysisState }>()
);
export const markAssetAsReviewed = createAction(
  "[REFERENCE STRIP] Mark strip item as reviewed",
  props<{ assetIds: string[] }>()
);
export const markAssetAsAnalyzed = createAction(
  "[REFERENCE STRIP] Mark strip item as analyzed",
  props<{ assetId: string }>()
);
export const refStripLoaded = createAction(
  "[REFERENCE STRIP] Ref strip elements loaded",
  props<{
    refStripDataResponse: refStripDataResponse[];
    itemsRequested: string[];
    assetIds: string[];
    isAuthUser: boolean;
  }>()
);
export const requestStripData = createAction(
  "[REFERENCE STRIP] Load reference strip data",
  props<{ requestRefStripData: requestRefStripData }>()
);
export const loadAssetIndex = createAction(
  "[REFERENCE STRIP] Change asset index",
  props<{ index: number; step?: number }>()
);
export const assetIndexLoaded = createAction(
  "[REFERENCE STRIP] Asset index loaded",
  props<{ index: number }>()
);
export const setAvailableAnalysts = createAction(
  "[ANALYSTS] Set available analysts",
  props<{ analysisStates: AnalysisState[]; currentUserId: string }>()
);
export const discardMarkAsAnalyzed = createAction(
  "[ANALYSTS] Unmark assets as analyzed",
  props<{ assetId: string; analysisStatesId: string }>()
);

export const loadCaseSampleRef = createAction(
  "[REFERENCES] Load next and previous references for case and sample",
  props<{
    nextCase: Case;
    previousCase: Case;
    nextSample: Sample;
    previousSample: Sample;
    nextCaseSample: Sample;
    prevCaseSample: Sample;
  }>()
);
export const updateAssetFilters = createAction(
  "[ANALYSTS] Update asset filters",
  props<{ filter: string }>()
);

export const noOpAction = createAction("[ROIS] No action");
