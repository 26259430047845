<div class="card-body" id="scrollable">
  <form [formGroup]="form" *ngIf="form">
    <div mat-dialog-title class="main-title">
      <h1>{{ "Step editor" | translate }}</h1>
    </div>
    <mat-label class="field-label">{{
      "core.type" | translate | uppercase
    }}</mat-label>
    <mat-form-field class="full-width" [floatLabel]="'never'">
      <mat-select formControlName="type" placeholder="'type'">
        <mat-option
          *ngFor="let option of taskTypes"
          [value]="option"
          [disabled]="isTypeDisabled(option)"
          >{{ option }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-label class="field-label">{{
      "title.analysis_tasks" | translate | uppercase
    }}</mat-label>
    <div>
      <div style="max-height: 250px; overflow: auto">
        <ul
          class="list-group list-group-flush"
          cdkDropList
          (cdkDropListDropped)="drop($event)"
        >
          <li
            class="list-group-item"
            *ngFor="let task of form.value.tasks"
            cdkDrag
            cdkDragLockAxis="y"
          >
            <div>
              <span class="task-name">{{ task.name.name }}</span>
              <span class="asset-specific-flag"
                >[{{
                  (task.generateFinding === "asset:created"
                    ? "label.asset"
                    : "core.sample"
                  ) | translate
                }}]</span
              >
            </div>
            <span class="button-bar stretch" *ngIfRole="['admin']">
              <button
                class="btn btn-flat-primary btn-auto"
                type="button"
                (click)="editTask(task)"
              >
                <i class="ri-pencil-line"></i>
              </button>
              <button
                class="btn btn-flat-danger btn-auto"
                type="button"
                (click)="removeTask(task)"
              >
                <i class="ri-delete-bin-6-line"></i>
              </button>
            </span>
            <div *cdkDragPlaceholder class="drag-placeholder"></div>
          </li>
        </ul>
      </div>

      <mat-error *ngIf="tasksValidatorError()">
        {{ "error.min_step_task" | translate }}
      </mat-error>
      <button
        *ngIf="canAddNewTask()"
        class="btn btn-flat btn-flat-primary btn-auto"
        (click)="newField()"
      >
        <i class="ri-add-circle-line" style="margin-right: 8px"></i>
        {{ "button.add_task" | translate | uppercase }}
      </button>
    </div>
    <div *ngIf="isCloud && form.value.type === 'position'">
      <div>
        <mat-checkbox formControlName="enableMosaicView">
          {{ "check.enable_mosaic_view" | translate }}
        </mat-checkbox>
      </div>
    </div>
    <div
      *ngIf="
        isCloud &&
        (form.value.type === 'roidetection' ||
          form.value.type === 'position' ||
          form.value.type === 'segmentation')
      "
    >
      <div>
        <mat-checkbox formControlName="allowAddingNewClasses">
          {{ "check.allow_new_classes" | translate }}
        </mat-checkbox>
      </div>
      <div>
        <mat-label class="field-label">{{
          "label.model_ai" | translate | uppercase
        }}</mat-label>
      </div>
      <div>
        <span class="model-selected-label" *ngIf="aiModelAssigned"
          >{{ "info.model_selected" | translate }}
        </span>
        <span class="model-selected-name" *ngIf="aiModelAssigned">{{
          aiModelAssigned.name
        }}</span>
      </div>
      <div>
        <button mat-raised-button color="primary" (click)="selectAIModel()">
          Select Model
        </button>
      </div>
    </div>

    <div class="card-footer card-actions">
      <!-- Editor buttons -->
      <button
        class="btn btn-flat-primary card-actions__action card-actions__action--grow"
        type="reset"
        (click)="cancel()"
      >
        <i class="ri-close-fill"></i>{{ "button.cancel" | translate }}
      </button>
      <button
        class="btn btn-primary card-actions__action card-actions__action--grow"
        [disabled]="form.invalid"
        (click)="submit()"
        type="submit"
      >
        <i class="ri-save-line"></i>{{ "button.save" | translate }}
      </button>
    </div>
  </form>
</div>
