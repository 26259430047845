import { DOCUMENT } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { POI, ROI } from "@telespot/analysis-refactor/data-access";
import {
  IAssetROI,
  IAssetROIWithModels,
  TRoiSelectionType,
} from "@telespot/sdk";
import { MouseTracker, Placement, Rect, Viewer } from "openseadragon";

@Component({
  selector: "ts-resizable-box",
  templateUrl: "./resizable-box.component.html",
  styleUrls: ["./resizable-box.component.scss"],
})
export class ResizableBoxComponent {
  private _roi: ROI;
  public mode: TRoiSelectionType = TRoiSelectionType.boundingBox;
  @Input()
  set roi(roi: ROI) {
    if (roi === undefined && this.roi === roi) return; // REVIEW
    this.show();
    this._roi = roi;
    if (this.osd) {
      const img = this.osd.world.getItemAt(0);
      if (img) {
        if (!!roi.w && !!roi.h) {
          this.mode = TRoiSelectionType.boundingBox;
          this.renderer.addClass(
            document.getElementById("box"),
            "bounding-box"
          );
          this.setPosition(
            img.imageToViewportRectangle(roi.x, roi.y, roi.w, roi.h)
          );
        } else {
          this.mode = TRoiSelectionType.center;
          this.renderer.removeClass(
            document.getElementById("box"),
            "bounding-box"
          );
          const margin = 100;
          this.setPosition(
            img.imageToViewportRectangle(
              roi.x - margin / 2,
              roi.y - margin / 2,
              margin,
              margin
            )
          );
        }
      }
    }
  }
  get roi() {
    return this._roi;
  }

  private _osd: Viewer;
  @Input()
  set osd(osd: Viewer) {
    if (!osd || this._osd === osd) return;
    this._osd = osd;
    const elt = this._document.getElementById("box");
    this.osd.removeOverlay(elt);
    this.osd.addOverlay(elt, new Rect(0.0, 0.0, 0.0, 0.0));

    new MouseTracker({
      element: this.tr.nativeElement,
      dragHandler: (e) => this.update(e, "tr"),
      releaseHandler: this.sendUpdate.bind(this),
    }).setTracking(true);

    new MouseTracker({
      element: this.tl.nativeElement,
      dragHandler: (e) => this.update(e, "tl"),
      releaseHandler: this.sendUpdate.bind(this),
    }).setTracking(true);

    new MouseTracker({
      element: this.bl.nativeElement,
      dragHandler: (e) => this.update(e, "bl"),
      releaseHandler: this.sendUpdate.bind(this),
    }).setTracking(true);

    new MouseTracker({
      element: this.moveArea.nativeElement,
      dragHandler: (e) => this.update(e, "moveArea"),
      releaseHandler: this.sendUpdate.bind(this),
    }).setTracking(true);

    new MouseTracker({
      element: this.br.nativeElement,
      dragHandler: (e) => this.update(e, "br"),
      releaseHandler: this.sendUpdate.bind(this),
    }).setTracking(true);
  }
  get osd(): Viewer {
    return this._osd;
  }

  @Output() positionChanged: EventEmitter<Rect> = new EventEmitter<Rect>();
  @Output() roiPositionChanged: EventEmitter<{
    roi: ROI;
    position: Partial<ROI>;
  }> = new EventEmitter<{
    roi: ROI;
    position: Partial<ROI>;
  }>();

  @ViewChild("tr", { static: true }) tr;
  @ViewChild("tl", { static: true }) tl;
  @ViewChild("bl", { static: true }) bl;
  @ViewChild("br", { static: true }) br;
  @ViewChild("moveArea", { static: true }) moveArea;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  private update(e, corner: "tl" | "tr" | "bl" | "br" | "moveArea") {
    if (this.mode !== TRoiSelectionType.boundingBox && corner !== "moveArea")
      return;
    const sym = e.shift;
    const overlay = this.osd.getOverlayById("box");
    if (overlay) {
      let bounds = overlay.getBounds(this.osd.viewport);
      const offset = this.osd.viewport.deltaPointsFromPixelsNoRotate(
        e["delta"]
      );
      switch (corner) {
        case "tl":
          if (!sym) {
            bounds.x += offset.x;
            bounds.y += offset.y;
            bounds.width -= offset.x;
            bounds.height -= offset.y;
          } else {
            bounds.x += offset.x;
            bounds.y += offset.y;
            bounds.width -= offset.x * 2;
            bounds.height -= offset.y * 2;
          }
          break;
        case "tr":
          if (!sym) {
            bounds.width += offset.x;
            bounds.y += offset.y;
            bounds.height -= offset.y;
          } else {
            bounds.x -= offset.x;
            bounds.width += offset.x * 2;
            bounds.y += offset.y;
            bounds.height -= offset.y * 2;
          }
          break;
        case "bl":
          if (!sym) {
            bounds.x += offset.x;
            bounds.width -= offset.x;
            bounds.height += offset.y;
          } else {
            bounds.x += offset.x;
            bounds.y -= offset.y;
            bounds.width -= offset.x * 2;
            bounds.height += offset.y * 2;
          }
          break;
        case "br":
          if (!sym) {
            bounds.width += offset.x;
            bounds.height += offset.y;
          } else {
            bounds.x -= offset.x;
            bounds.y -= offset.y;
            bounds.width += offset.x * 2;
            bounds.height += offset.y * 2;
          }
          break;
        case "moveArea":
          bounds = bounds.translate(offset);
          break;
      }
      this.osd.updateOverlay("box", bounds);
    }
  }

  private sendUpdate(e) {
    const rect = this.osd.getOverlayById("box").getBounds(this.osd.viewport);
    if (rect.width < 0) {
      rect.width = -rect.width;
      rect.x -= rect.width;
    }
    if (rect.height < 0) {
      rect.height = -rect.height;
      rect.y -= rect.height;
    }
    this.positionChanged.emit(rect);
    const roi_position: ROI = { ...this.roi };
    if (roi_position) {
      const imgRect = this.osd.world
        .getItemAt(0)
        .viewportToImageRectangle(rect);
      switch (this.mode) {
        case TRoiSelectionType.boundingBox:
          roi_position.x = imgRect.x;
          roi_position.y = imgRect.y;
          roi_position.w = imgRect.width;
          roi_position.h = imgRect.height;
          break;
        case TRoiSelectionType.center:
          roi_position.x = imgRect.x + imgRect.width / 2;
          roi_position.y = imgRect.y + imgRect.height / 2;
          roi_position.w = 0;
          roi_position.h = 0;
          break;
      }
      this.roiPositionChanged.emit({ roi: this.roi, position: roi_position });
    }
  }

  hide() {
    // fix: this is buggy for some reason, using renderer to set visibility as a workaround
    this.renderer.setStyle(
      this._document.getElementById("box"),
      "visibility",
      "hidden"
    );
  }

  show() {
    this.renderer.setStyle(
      this._document.getElementById("box"),
      "visibility",
      "visible"
    );
  }

  setPosition(bounds: Rect) {
    if (!this.osd.getOverlayById("box")) {
      this.osd.addOverlay(this._document.getElementById("box"), bounds);
    } else {
      const elt = this._document.getElementById("box");
      this.renderer.setStyle(elt, "display", "block");
      this.osd.updateOverlay("box", bounds, Placement.CENTER);
    }
  }
}
