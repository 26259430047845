import { Object as ParseObject } from "parse";
import { AnalysisType } from "../analysis-type/analysis-type.model";
import { MethodType } from "../method-type/method-type.model";
import { ProtocolAlgorithms } from "../protocol-algorithms/protocol-algorithms.model";

/**
 * Represents any type of AI model that may be provided to the AI endpoints.
 *
 * @export
 * @class Algorithms
 * @extends {ParseObject}
 */

export enum AlgorithmFields {
  TABLE = "Algorithms",
  NAME = "name",
  VERSION = "version",
  DESCRIPTION = "description",
  REGULATED = "regulated",
  CLOUD_CONFIG = "cloud_config",
  MOBILE_CONFIG = "mobile_config",
  CREATEDBY = "createdBy",
}

export class Algorithms extends ParseObject {
  static className = "Algorithms";

  constructor() {
    super(Algorithms.className);
  }

  get name(): string {
    return this.get("name");
  }

  get version(): string {
    return String(this.get(AlgorithmFields.VERSION));
  }

  set version(value: string) {
    this.set(AlgorithmFields.VERSION, value);
  }

  get description(): string {
    return String(this.get(AlgorithmFields.DESCRIPTION) || "");
  }

  set description(value: string) {
    this.set(AlgorithmFields.DESCRIPTION, value);
  }

  get regulated(): boolean {
    return Boolean(this.get(AlgorithmFields.REGULATED));
  }

  set regulated(value: boolean) {
    this.set(AlgorithmFields.REGULATED, value);
  }

  get cloudConfig(): any | undefined {
    return this.get(AlgorithmFields.CLOUD_CONFIG) || {};
  }

  set cloudConfig(value: any) {
    this.set(AlgorithmFields.CLOUD_CONFIG, value);
  }

  get mobileConfig(): any | undefined {
    return this.get(AlgorithmFields.MOBILE_CONFIG) || {};
  }

  set mobileConfig(value: any) {
    this.set(AlgorithmFields.MOBILE_CONFIG, value);
  }

  get createdBy(): Parse.User {
    return this.get(AlgorithmFields.CREATEDBY);
  }
  set createdBy(user: Parse.User) {
    this.set(AlgorithmFields.CREATEDBY, user);
  }

  public assingToAnalysisType(
    methodType: MethodType,
    analysisType: AnalysisType
  ): ProtocolAlgorithms {
    const newProtocol = new ProtocolAlgorithms();
    newProtocol.methodType = methodType;
    newProtocol.analysisType = analysisType;
    newProtocol.model = this;

    return newProtocol;
  }
}
