import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AuthService, TelespotModule } from "@telespot/web-core";
import { Case, FieldType } from "@telespot/sdk";
import { ValidateDatePipeModule } from "@telespot/shared/util";
import { ConfirmationDialogComponent } from "@shared/ui";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { filter, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import {
  currDecryptedCase,
  decrypt,
  decryptCases,
  loadCases,
  saveCase,
  selectCurrCaseAndWorkspace,
  selectIsCaseEncrypted,
  selectWSEncrypted,
  selectdecrypted,
} from "@telespot/analysis-refactor/data-access";

interface Data {
  [key: string]: string | number | string[] | Date;
}

@Component({
  selector: "ts-case-data",
  templateUrl: "./case-data.component.html",
  styleUrls: ["./case-data.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseDataComponent implements OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("case") _case: Case;

  get case() {
    return this._case;
  }
  @Input() printable = false;

  public decrypted = false;
  public wsEncrypted = false;
  public caseIdEncrypted = false;
  public dataSections: {
    sectionName: string;
    fields: { fieldType: FieldType; data: any }[];
  }[];

  protected destroy$ = new Subject<void>();
  public dateFields: string[] = [];
  public selectionFields: { [x: string]: string[] } = {};
  public originalFormValues;
  public decryptedCase;
  public editMode = false;
  public currentCase: any;
  public currentWorkspace: any;
  public createdByUser: boolean;
  private formBuilt = false;

  caseDataForm: UntypedFormGroup;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private authService: AuthService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    const currCase: Case = this.route.parent.snapshot.data["case"];
    this.caseDataForm = this.fb.group({});

    this.store.dispatch(
      loadCases({
        cases: [currCase.toJSON()],
        currentUserId: this.authService.currentUser.id,
      })
    );
    this.store
      .select(selectWSEncrypted)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.wsEncrypted = value));

    this.store
      .select(selectIsCaseEncrypted)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.caseIdEncrypted = value));

    this.store
      .select(selectdecrypted)
      .pipe(takeUntil(this.destroy$))
      .subscribe((decrypt) => {
        this.decrypted = decrypt;
      });

    this.store
      .select(currDecryptedCase(currCase.id))
      .pipe(takeUntil(this.destroy$))
      .pipe(filter((c) => c !== undefined))
      .subscribe((c) => {
        this.decryptedCase = c;
      });

    this.store
      .select(selectCurrCaseAndWorkspace(currCase.id))
      .pipe(
        filter(
          (value) =>
            value !== undefined &&
            value.workspace !== undefined &&
            value._case !== undefined
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(({ workspace, _case }) => {
        this.currentWorkspace = workspace;
        this.currentCase = _case;
        this.dataSections = this.extractDataSections(!this.decrypted);
        this.createdByUser =
          _case.createdBy.objectId === this.authService.currentUser?.id;

        if (this.formBuilt) {
          this.caseDataForm.reset();
          this.updateForm(!this.decrypted);
        } else {
          this._buildForm();
          this.formBuilt = true;
        }
      });
  }

  private _buildForm() {
    for (const section of this.dataSections) {
      const group = this.fb.group({});

      for (const field of section.fields) {
        let value = field.data;

        if (
          field.fieldType.type === "selection" &&
          field.fieldType.details.multiple
        ) {
          this.selectionFields = {
            ...this.selectionFields,
            [field.fieldType.name]: field.fieldType.details.options,
          };
          const formArray = new FormArray([]);
          field.fieldType.details.options.forEach((option) => {
            const control = this.fb.control({
              value: field.data?.includes(option) ?? false,
              disabled: true,
            });
            formArray.push(control);
          });

          value = new FormGroup({
            options: formArray,
          });

          group.addControl(field.fieldType.name, value);
        } else {
          if (field.fieldType.type === "date") {
            this.dateFields.push(field.fieldType.name);
            const date = new Date(field.data);
            value = date.toISOString().substr(0, 10);
          }

          const validators = this.addValidators(field.fieldType);

          const control = this.fb.control(
            { value, disabled: true },
            validators.length > 0 ? Validators.compose(validators) : null
          );

          group.addControl(field.fieldType.name, control);
        }
      }
      this.caseDataForm.addControl(section.sectionName, group);
    }

    setTimeout(() => {
      this.cdRef.detectChanges();
      this.originalFormValues = this.caseDataForm.getRawValue();
    });
  }

  private updateForm(encrypted) {
    for (const section of this.dataSections) {
      const sectionGroup = this.caseDataForm.get(
        section.sectionName
      ) as FormGroup;
      for (const field of section.fields) {
        const control = sectionGroup.get(field.fieldType.name);
        let value = field.data;
        if (
          field.fieldType.type === "selection" &&
          field.fieldType.details.multiple
        ) {
          const optionsFormArray = (control as FormGroup).get(
            "options"
          ) as FormArray;

          optionsFormArray.controls.forEach((formControl, i) => {
            formControl.setValue(
              value?.includes(field.fieldType.details.options[i]) ?? false
            );
          });
        } else if (field.fieldType.type === "date" && value) {
          value = new Date(value).toISOString().substring(0, 10);
          control.setValue(value);
        } else {
          control.setValue(value);
        }
      }
    }

    setTimeout(() => {
      this.cdRef.detectChanges();
      this.originalFormValues = this.caseDataForm.getRawValue();
    });
  }

  private extractDataSections(encrypted: boolean): {
    sectionName: string;
    fields: { fieldType: FieldType; data: any }[];
  }[] {
    const obj: {
      sectionName: string;
      fields: { fieldType: FieldType; data: any }[];
    }[] = [];
    if (this.currentCase) {
      for (const k of this.currentWorkspace.caseType.fields) {
        if (k.type === "label" || k.type === "sectionHeader") {
          obj.push({
            sectionName: k.details["display_name"],
            fields: [],
          });
        } else if (k.type === "case_name") {
          const createdByUser =
            this.currentCase.createdBy.objectId ===
            this.authService.currentUser.id;
          obj.push({
            sectionName: k.displayName,
            fields: [
              {
                fieldType: k,
                data:
                  !this.wsEncrypted || createdByUser || !this.caseIdEncrypted
                    ? this.currentCase?.name
                    : this.decrypted
                    ? this.decryptedCase?.name
                    : this.currentCase?.objectId,
              },
            ],
          });
        } else if (k.type !== "case_name") {
          if (!obj.some((section) => section.sectionName === "DATA"))
            obj.push({ sectionName: "DATA", fields: [] });
          obj[obj.length - 1].fields.push({
            fieldType: k,
            data:
              this.wsEncrypted &&
              encrypted &&
              k.encrypted &&
              this.currentCase.createdBy.objectId !==
                this.authService.currentUser.id
                ? null
                : this.currentCase.data[k.name],
          });
        }
      }
    }
    return obj;
  }

  getLocationURL(data: any) {
    if (data === undefined || data === null) return "";
    let dataObj;
    let parse = false;
    try {
      parse = !data?.lat;
      if (!parse) dataObj = data;
    } catch (err) {
      parse = true;
    }
    if (parse) {
      const parts = data.split(",");
      if (parts.length === 2) {
        dataObj = {
          lat: Number(parts[0]),
          long: Number(parts[1]),
        };
      }
    }
    return dataObj !== undefined
      ? `https://www.google.com/maps/?q=${dataObj.lat},${dataObj.long}`
      : undefined;
  }

  setEditMode() {
    if (this.wsEncrypted && !this.decrypted && !this.createdByUser) {
      this.dialog.open(ConfirmationDialogComponent, {
        hasBackdrop: true,
        data: {
          title: "alert.decryption_required",
          text: "alert.decryption_required_description",
          cancelButtonText: "Ok",
        },
        width: "550px",
      });
      return;
    }
    this.editMode = true;
    this.caseDataForm.enable();
  }

  getLocation(section, field: FieldType) {
    let value = { lat: 100, long: 100 };
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: { coords: { latitude: number; longitude: number } }) => {
          value = {
            lat: position.coords.latitude,
            long: position.coords.longitude,
          };
          this.caseDataForm.get(section).get(field.name).patchValue(value);
          const text = this.translateService.instant("info.location_loaded");
          this.snackBar.open(text, undefined, {
            duration: 2500,
          });
        }
      );
    }
    this.caseDataForm.get(section).get(field.name).patchValue(value);
  }

  addValidators(fieldType: FieldType) {
    const validators = [];

    if (fieldType.required) {
      validators.push(Validators.required);
    }
    if (
      fieldType.details?.min !== undefined &&
      fieldType.details.min !== null
    ) {
      validators.push(Validators.min(fieldType.details.min as number));
    }

    if (fieldType.details?.max) {
      validators.push(Validators.max(fieldType.details.max as number));
    }
    return validators;
  }

  async save() {
    const updatedData = Object.values(this.caseDataForm.value).reduce(
      (data, section) => {
        const dateFields = Object.keys(section).filter((fieldName) =>
          this.dateFields.includes(fieldName)
        );
        const selectionFields = Object.keys(section).filter((fieldName) =>
          Object.keys(this.selectionFields).includes(fieldName)
        );
        const selectionFieldsUpdated = {
          ...selectionFields.reduce((acc, field) => {
            return {
              ...acc,
              [field]: [
                ...Object.values(this.selectionFields[field]).filter(
                  (f, i) => section[field].options[i]
                ),
              ],
            };
          }, {}),
        };

        return {
          ...(data as any),
          ...{
            ...(section as any),
            ...selectionFieldsUpdated,
            ...dateFields.reduce(
              (acc, field) => ({
                ...acc,
                [field]: new Date(section[field]).toISOString(),
              }),
              {}
            ),
          },
        };
      },
      {}
    ) as Data;
    this.editMode = false;
    this.caseDataForm.disable();
    this.store.dispatch(
      saveCase({
        id: this.currentCase.objectId,
        data: updatedData,
        currentUserId: this.authService.currentUser.id,
      })
    );
  }

  discardChanges() {
    this.caseDataForm.reset(this.originalFormValues);
    this.editMode = false;
    this.caseDataForm.disable();
  }

  async decryptCase() {
    if (!this.decrypted) {
      this.store.dispatch(decryptCases());
    } else {
      this.store.dispatch(decrypt({ decrypted: !this.decrypted }));
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  allowedToEdit() {
    return this.createdByUser
      ? true
      : this.authService.currentUser.roleNames.includes("technicianmanager");
  }
}

@NgModule({
  declarations: [CaseDataComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ValidateDatePipeModule,
    FormsModule,
    ReactiveFormsModule,
    TelespotModule,
  ],
  exports: [CaseDataComponent],
  providers: [],
})
export class CaseDataModule {}
