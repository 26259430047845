export const SPECIAL_CHARS = [
  ".",
  "+",
  "*",
  "?",
  "^",
  "$",
  "(",
  ")",
  "[",
  "]",
  "{",
  "}",
  "|",
  "\\",
];
