import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { mapTo, shareReplay, startWith } from 'rxjs/operators';

@Component({
  selector: 'ts-invite-editor',
  templateUrl: './invite-editor.component.html',
  styleUrls: ['./invite-editor.component.scss'],
})
export class InviteEditorComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  public readonly workspaces$ = this.data.workspaces$.pipe(shareReplay({ bufferSize: 1, refCount: true }));
  public readonly roles$ = this.data.roles$.pipe(shareReplay({ bufferSize: 1, refCount: true }));
  public loading$ = combineLatest([this.workspaces$, this.roles$]).pipe(mapTo(false), startWith(true));

  toggleRole(formControl: UntypedFormControl, role: string) {
    if (formControl.value.includes(role)) {
      formControl.setValue(formControl.value.filter((v) => v !== role));
    } else {
      formControl.setValue([...formControl.value, role]);
    }
  }

  public get enableCustomCode(): boolean {
    return JSON.parse(localStorage.getItem('experiments_custom_invite_code') ?? 'false');
  }
}
