import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StoragePipe } from './storage.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [StoragePipe],
  exports: [StoragePipe],
})
export class StoragePipeModule {}
