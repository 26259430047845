<div #viewport class="viewport" (scroll)="onScroll($event)">
  <div class="content" [style.height]="totalContentHeight + 'px'">
    <div
      class="visible-nodes"
      [style.transform]="'translateY(' + offsetY + 'px)'"
    >
      <ng-container
        *ngFor="let item of visibleItems; let i = index; trackBy: trackByIndex"
      >
        <div #rowElement [id]="i + startNode" class="row-item">
          <ng-container
            *ngTemplateOutlet="
              rowTemplate;
              context: { $implicit: i + startNode }
            "
          ></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
