import { Directive, QueryList, ViewChildren } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  isAnalystCurrentUser,
  selectActiveAnalysisForTaskGroup,
  selectActiveSample,
  selectAsset,
  selectAssetId,
  selectContext,
  selectCurrentAnalyst,
  selectFindingsForActiveAnalysis,
  selectHasROITasks,
  selectHasUnsavedChanges,
  selectLoadingAnalysis,
  selectProtocolLoading,
  visibleTaskGroups,
} from "@telespot/analysis-refactor/data-access";

import { AnalysisFormComponent } from "../components/analysis-form/analysis-form.component";

@Directive()
export class AnalysisPanelDirective {
  @ViewChildren(AnalysisFormComponent)
  analysisForms: QueryList<AnalysisFormComponent>;

  public readonly isAnalystCurrentUser$ =
    this.store.select(isAnalystCurrentUser);
  public readonly selectedAsset$ = this.store.select(selectAsset);
  public readonly currentAnalyst$ = this.store.select(selectCurrentAnalyst);
  public readonly selectedSample$ = this.store.select(selectActiveSample);
  public readonly context$ = this.store.select(selectContext);
  public readonly loadingProtocol$ = this.store.select(selectProtocolLoading);
  public readonly loadingAnalysis$ = this.store.select(selectLoadingAnalysis);
  public readonly saved$ = this.store.select(selectHasUnsavedChanges);
  public readonly selectedAssetId$ = this.store.select(selectAssetId);
  public readonly tasksGroups$ = this.store.select(visibleTaskGroups);

  public readonly hasROITasks$ = this.store.select(selectHasROITasks);

  constructor(protected store: Store) {}

  getActiveFindings(taskGroupId: string) {
    return this.store.select(selectFindingsForActiveAnalysis(taskGroupId));
  }
  getActiveAnalysis(taskGroupId: string) {
    return this.store.select(selectActiveAnalysisForTaskGroup(taskGroupId));
  }
}
