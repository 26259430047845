import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CaseFieldsFormComponent } from "./forms/case-fields-form/case-fields-form.component";
import { SampleFormComponent } from "./forms/sample-form/sample-form.component";
import { SamplesFormComponent } from "./forms/samples-form/samples-form.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FileDropDirectiveModule } from "@shared/utils";
import { StepperModule } from "../stepper/stepper.module";

@NgModule({
  declarations: [
    CaseFieldsFormComponent,
    SampleFormComponent,
    SamplesFormComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDialogModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatRadioModule,
    MatInputModule,
    FileDropDirectiveModule,
    StepperModule,
  ],
  exports: [CaseFieldsFormComponent, SampleFormComponent, SamplesFormComponent],
})
export class AcquisitionModule {}
