import { ChangeDetectionStrategy, Component } from "@angular/core";
import { DataService } from "@telespot/web-core";
import { Device } from "@telespot/sdk";

import { DataFilterComponent, FindRequest, RowData } from "@shared/utils";
import {
  DataTableConfig,
  SplDataSource,
  SplDataSourceConfig,
} from "@shared/ui";
interface DevicesRowData extends RowData {
  resource: Device;
}
@Component({
  selector: "ts-devices",
  templateUrl: "./devices.component.html",
  styleUrls: ["./devices.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesComponent
  extends DataFilterComponent
  implements SplDataSourceConfig<DevicesRowData>
{
  public previousRequest: FindRequest;
  public oldAvailable: Device[];
  public tableName = "devices_list";
  public dataSource = new SplDataSource<DevicesRowData>(this);
  public tableConfig: DataTableConfig<DevicesRowData>;

  public klass = Device.className;

  public readonly config = {
    columns: [
      {
        value: (item) => item,
        displayName: "core.organization",
        name: "organization",
      },
      {
        value: (item) => item,
        displayName: "core.name",
        name: "name",
      },
      {
        value: (item) => item,
        displayName: "core.device_type",
        name: "device_type",
      },
      {
        value: (item) => item,
        displayName: "core.version",
        name: "version",
      },
      {
        value: (item) => item,
        displayName: "label.serial_number",
        name: "serial_number",
      },
    ],
    routeId: "_id",
  };

  public columns = this.config.columns;

  constructor(private _dataService: DataService) {
    super(_dataService);
  }
}
