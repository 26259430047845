import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FaqContentsComponent } from './components/faq-contents/faq-contents.component';
import { FaqComponent } from './components/faq/faq.component';

const routes: Routes = [
  {
    path: 'faq',
    component: FaqComponent,
    children: [{ path: '**', component: FaqContentsComponent }],
    data: { title: 'FAQ' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SupportRoutingModule {}
