<div class="main-container">
  <div class="title">
    <h1>FAQ</h1>
  </div>

  <div class="faq-notice">
    {{ 'faq.outdated' | translate }}
  </div>

  <div class="flex-row">
    <div class="card" style="flex-basis: 30%; height: 100%">
      <div class="list-group list-group-flush">
        <a
          class="list-group-item"
          *ngFor="let section of sections$ | async"
          (click)="activeFragment = section"
          [class.active]="activeFragment === section"
          [routerLink]=""
          [fragment]="section"
          >{{ 'title.' + section | translate | uppercase }}</a
        >
      </div>
    </div>

    <div class="card" style="width: 100%">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
