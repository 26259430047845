import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Organization } from '@telespot/sdk';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ts-organization-detail',
  templateUrl: './organization-detail.component.html',
  styleUrls: ['./organization-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OrganizationDetailComponent {
  public readonly organization$: Observable<Organization> = this._route.data.pipe(map((data) => data.organization));

  constructor(private _route: ActivatedRoute) {}
}
