import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@telespot/shared/environment';

import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
  // if (window && !environment.enable_logs) {
  //   window.console.log = function() {};
  //   window.console.warn = function() {};
  //   window.console.group = function() {};
  //   window.console.groupEnd = function() {};
  //   // window.console.error=function(){};
  // }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    preserveWhitespaces: true,
  })
  .catch((err) => console.log(err));
