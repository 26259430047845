import {
  Pipeline,
  PipelineEnvironment,
  PipelineRepository,
  PipelineStep,
} from "@telespot/domain";
import { ParseBaseRepository } from "../../parse-base.repository";
import { ParsePipelineMapper, PipelineTopology } from "./parse-pipeline.mapper";
import {
  ParsePipelineStepMapper,
  PipelineStepTopology,
} from "./parse-pipeline-step.mapper";
import { ProtocolPipelineTopology } from "./parse-protocol-pipeline.mapper";
import { ObjectTopology } from "../../parse.topology";
import { MethodTypeTopology } from "./parse-method-type.mapper";

export class ParsePipelineRepository
  extends ParseBaseRepository
  implements PipelineRepository
{
  private readonly pipelineMapper = new ParsePipelineMapper(this.parse);
  private readonly pipelineStepMapper = new ParsePipelineStepMapper(this.parse);

  public async getById(id: string): Promise<Pipeline> {
    const parsePipeline = await new this.parse.Query(
      PipelineTopology.TABLE
    ).get(id, this.options);

    return this.pipelineMapper.toDomain(parsePipeline);
  }

  public async getStepById(id: string): Promise<PipelineStep> {
    const parsePipelineStep = await new this.parse.Query(
      PipelineStepTopology.TABLE
    ).get(id, this.options);

    return this.pipelineStepMapper.toDomain(parsePipelineStep);
  }

  public async findStepsForPipeline(
    pipelineId: string
  ): Promise<PipelineStep[]> {
    const ParsePipeline = this.subclasses.getSubclass(PipelineTopology.TABLE);

    const results = await new this.parse.Query(PipelineStepTopology.TABLE)
      .equalTo(
        PipelineStepTopology.PIPELINE,
        ParsePipeline.createWithoutData(pipelineId)
      )
      .find(this.options);

    return results.map((p) => this.pipelineStepMapper.toDomain(p));
  }

  public async findStepsForMethodType(
    methodTypeId: string
  ): Promise<PipelineStep[]> {
    const methodType = this.subclasses
      .getSubclass(MethodTypeTopology.TABLE)
      .createWithoutData(methodTypeId);

    const cloudPipelinesQuery = new this.parse.Query(
      PipelineTopology.TABLE
    ).equalTo(PipelineTopology.ENV, PipelineEnvironment.CLOUD);

    const protocolPipelinesQuery = new this.parse.Query(
      ProtocolPipelineTopology.TABLE
    )
      .equalTo(ProtocolPipelineTopology.METHOD_TYPE, methodType)
      .matchesQuery(ProtocolPipelineTopology.PIPELINE, cloudPipelinesQuery);

    const includedFields = [
      PipelineStepTopology.EXECUTOR,
      PipelineStepTopology.PIPELINE,
    ];

    const results = await new this.parse.Query(PipelineStepTopology.TABLE)
      .matchesKeyInQuery(
        PipelineStepTopology.PIPELINE,
        ProtocolPipelineTopology.PIPELINE,
        protocolPipelinesQuery
      )
      .include(includedFields)
      .find({ useMasterKey: true });

    return results.map((p) => this.pipelineStepMapper.toDomain(p));
  }
}
