import { Component, OnInit } from "@angular/core";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ColumnConfig, DataTableConfig, SplDataSource } from "@shared/ui";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DataService } from "@telespot/web-core";
import { ParseObject, Query } from "@telespot/sdk";
import { defer, Observable, from } from "rxjs";
import { map } from "rxjs/operators";

export interface IColumn extends ColumnConfig<RowData> {
  colName?: string;
  key?: string;
  sortable?: boolean;
  value: (item: any) => any;
  displayName?: string;
  name: string;
  asyncKey?: string;
  asyncModel?: string;
  asyncResourceName?: string;
  asyncMethod?: string;
  select?: string[];
  include?: string[];
  queryFilters?: any[];
}

export interface FindRequest {
  sorts: any;
  filters: any;
  skip: number;
  limit: number;
}

export interface RowData {
  resource: any;
  id: string;
}

interface ISearchTable {
  tableName: string;
  previousCount: number;
  dataService: DataService;
  previousRequest: FindRequest;
  getFindQuery: (any) => Query<ParseObject>;
  requestNeeded: (request: any) => boolean;
  oldAvailable: ParseObject[];
  klass: string;
}

@Component({
  template: "",
})
export class DataFilterComponent implements OnInit, ISearchTable {
  public previousRequest: FindRequest;
  public TypeDeleted = false;
  public selectedResourceIds = [];
  public dataSource = new SplDataSource<RowData>(this);
  public tableConfig: DataTableConfig<RowData>;
  public previousCount = 0;
  public oldAvailable: ParseObject[];
  public klass = ""; //* e.g. CaseTpye
  public tableName = ""; //* e.g. casetypes_list
  public previousQueryFilters = [];
  public columns: IColumn[] = [
    {
      name: this.tableName,
      value: (item) => item,
    },
  ];

  public getFindQuery(request: FindRequest) {
    const { filters, limit, skip } = request;

    const query = new Query(this.klass);

    this.columns.map((column) => {
      const { select, include, queryFilters } = column;

      if (include) query.include(include);
      if (queryFilters) {
        for (const filter of queryFilters) {
          if (filter.comparison === "equalTo")
            if (!filter.key || !filter.value) return;
          query.equalTo(filter.key, filter.value);
          if (filter.comparison === "exists") {
            if (!filter.key) return;
            query.exists(filter.key);
          }
        }
      }
    });

    if (filters) {
      for (const { constraints } of filters) {
        for (const [comparison, value] of Object.entries(constraints)) {
          //No other verifications are needed since searchbar only uses 'name' filter
          if (typeof value === "string") {
            query.matches("name", new RegExp(`${value.toLowerCase()}`), "i");
          }
        }
      }
    }

    query.addDescending("createdAt");
    query.limit(limit || 500);
    query.skip(skip || 0);

    return query;
  }

  public async updateFcn(
    sorts,
    filters,
    skip,
    limit
  ): Promise<{
    items: Observable<RowData[]>;
    count: Observable<number>;
  }> {
    const request = { sorts, filters, skip, limit };

    const QueryType = this.getFindQuery(request);

    const isRequestNeeded = this.requestNeeded(request);

    this.previousRequest = request;

    // TODO: includeAll() added ad-hoc for devices table refactor until refactor component and web
    const isDevice = QueryType.className === "Device" ? true : false;

    const items = isRequestNeeded
      ? isDevice
        ? this.dataService.find(QueryType.includeAll())
        : this.dataService.find(QueryType)
      : Promise.resolve(this.oldAvailable);
    const count = isRequestNeeded
      ? this.dataService.count(QueryType)
      : Promise.resolve(this.previousCount);

    return {
      items: defer(() => items).pipe(
        map((resourcesFound: ParseObject[]) => {
          this.oldAvailable = resourcesFound;
          resourcesFound = resourcesFound.filter(
            (el) => !this.selectedResourceIds.includes(el.id)
          );

          return resourcesFound.map<RowData>((el) => {
            const element = {
              ...el.attributes,
              _id: el.id,
              name: el.attributes.name,
            };

            this.columns.map((column) => {
              const { asyncKey, asyncModel, asyncResourceName, asyncMethod } =
                column;
              if (asyncKey) {
                let asyncQuery;
                if (asyncMethod == "equalTo") {
                  asyncQuery = new Query(asyncModel).equalTo(
                    asyncResourceName,
                    el.toPointer()
                  );
                } else {
                  asyncQuery = new Query(asyncModel).containedIn(
                    asyncResourceName,
                    [el.toPointer()]
                  );
                }

                element[asyncKey] = defer(() =>
                  this.dataService.count(asyncQuery)
                );
              }
            });
            return {
              resource: element,
              id: el.id,
            };
          });
        })
      ),
      count: from(count).pipe(map((num) => (this.previousCount = num))),
    };
  }

  requestNeeded(prevRequest: FindRequest) {
    if (!this.previousRequest) {
      return true;
    }

    if (this.TypeDeleted) {
      this.TypeDeleted = false;
      return true;
    }

    if (prevRequest.filters !== this.previousRequest.filters) {
      return true;
    }
    if (prevRequest.sorts !== this.previousRequest.sorts) {
      return true;
    }

    if (prevRequest.skip !== this.previousRequest.skip) {
      return true;
    }
    if (prevRequest.limit !== this.previousRequest.limit) {
      return true;
    }

    if (this.queryFiltersUpdated()) return true;

    return false;
  }

  queryFiltersUpdated() {
    if (!this.columns[0].queryFilters) return false;

    if (
      this.columns[0].queryFilters.length !== this.previousQueryFilters?.length
    ) {
      this.previousQueryFilters = this.columns[0].queryFilters;
      return true;
    }

    const filtersChanged = this.columns[0].queryFilters.some((newFilter, i) => {
      const previousFilter = this.previousQueryFilters[i];

      return (
        newFilter.key === previousFilter.key &&
        newFilter.value &&
        previousFilter.value &&
        newFilter.value.id !== previousFilter.value.id
      );
    });

    if (filtersChanged) {
      this.previousQueryFilters = this.columns[0].queryFilters;
      return true;
    }

    return false;
  }

  configureTable(props = {}) {
    this.tableConfig = {
      columns: this.columns,
      showPaginator: true,
      showFilters: false,
      hideHeader: false,
      showSearch: true,
      showStatusIndicator: false,
      showRefreshButton: false,
      useQueryParams: false,
      url: this.url,
      ...props,
    };
  }

  public url(item) {
    return ["edit", item.id];
  }

  constructor(public dataService: DataService) {}

  ngOnInit(): void {
    this.configureTable();
  }
}
