import { ProcessingStatus, State } from "../value-objects";
import { Entity, SoftDeletable } from "./entity";

export interface AssetCreationAttributes extends Entity, SoftDeletable {
  organizationId: string;
  createdById: string;
  assetFile: string; // TODO: define Value Object
  assetTypeId: string;
  data: IDataField;
  viewState: ProcessingStatus;
  methodTypeId: string;
  appVersion: string;
  stitchingState?: ProcessingStatus;
  aiStatus: ProcessingStatus;
}

export interface IDataField {
  marked?: boolean;
  local_asset_id?: number;
  [key: string]: any;
}

export class Asset implements Entity, SoftDeletable {
  constructor(private attributes: AssetCreationAttributes) { }

  public get id(): string {
    return this.attributes.id ?? null;
  }

  public get aiStatus(): ProcessingStatus {
    return this.attributes.aiStatus;
  }

  public get organizationId(): string {
    return this.attributes.organizationId;
  }
  public set organizationId(value: string) {
    this.attributes.organizationId = value;
  } // covered with trasferOwnership()
  public get assetFile(): string {
    return this.attributes.assetFile;
  }
  public set assetFile(value: string) {
    this.attributes.assetFile = value;
  } // should be immutable
  public get data(): IDataField {
    return this.attributes.data;
  }
  public get assetTypeId(): string {
    return this.attributes.assetTypeId;
  }
  public get createdById(): string {
    return this.attributes.createdById;
  }

  /**
   * @deprecated should be immutable
   */
  public set createdById(userId: string) {
    this.attributes.createdById = userId;
  }

  public get deletedAt(): Date | undefined {
    return this.attributes.deletedAt;
  }

  public get createdAt(): Date | undefined {
    return this.attributes.createdAt;
  }

  public get updatedAt(): Date | undefined {
    return this.attributes.updatedAt;
  }

  public get viewState(): ProcessingStatus {
    return this.attributes.viewState;
  }

  public get stitchingState(): ProcessingStatus {
    return this.attributes.stitchingState;
  }

  public get methodTypeId(): string {
    return this.attributes.methodTypeId;
  }
  public get appVersion(): string {
    return this.attributes.appVersion;
  }

  public transferOwnership(organizationId: string): Asset {
    this.attributes.organizationId = organizationId;
    return this;
  }

  public markDziAsFailed(cause: string, errorCode: number): this {
    this.attributes.viewState = { state: State.FAILED, cause, errorCode };
    return this;
  }

  public markDziAsComplete(): this {
    this.attributes.viewState = { state: State.SUCCEEDED };
    return this;
  }

  public markInferenceAsFailed(cause: string, errorCode: number): this {
    this.attributes.aiStatus = {
      state: State.FAILED,
      cause,
      errorCode,
    };


    return this;
  }

  public markInferenceAsComplete(): this {
    this.attributes.aiStatus = { state: State.SUCCEEDED };

    return this;
  }

  public markInferenceAsRunning(): this {
    this.attributes.aiStatus = { state: State.PROCESSING };

    return this;
  }

  public addTiraspotResult(ml_result: any): this {
    const user_result = (this.attributes.data.tiraspot || {})
      .result;

    const final_POCT_result =
      ((this.attributes.data || {}).tiraspot || {}).final_POCT_result ||
      (user_result === ml_result ? user_result : undefined);

    this.attributes.data = {
      ...this.attributes.data,
      tiraspot: {
        ...this.attributes.data.tiraspot,
        ...(final_POCT_result ? { final_POCT_result } : {}),
        ml_result,
      },
    }

    return this;
  }

  public updateMetadata(newMetadata: IDataField): this {
    this.attributes.data = newMetadata;
    return this;
  }
}
