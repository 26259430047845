import { CommonModule } from '@angular/common';
import { Directive, HostListener, NgModule } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AboutDialogComponent } from '@telespot/ui';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appAboutPopupTrigger]',
})
export class AboutPopupTriggerDirective {
  @HostListener('click') onclick() {
    this.openDialog();
  }

  openDialog() {
    this._dialog.open(AboutDialogComponent, {
      width: '320px',
      hasBackdrop: true,
      disableClose: false,
    });
  }

  constructor(private _dialog: MatDialog) {}
}

@NgModule({
  declarations: [AboutPopupTriggerDirective],
  imports: [CommonModule],
  exports: [AboutPopupTriggerDirective],
  providers: [],
})
export class AboutPopupTriggerModule {}
