import { createAction, props } from "@ngrx/store";
import { Context, ProtocolSpecification } from "./protocol.reducer";
import { Sample } from "@telespot/sdk";

export interface CustomLabelInfo {
  taskId: string;
  pipelineId: string;
  value: string;
  uuid: string;
}

export interface NewCustomLabel {
  taskId: string;
  pipelineId: string;
  value: any;
}

export const protocolActionError = createAction(
  "[PROTOCOLS] Action Error",
  props<{ error: string }>()
);

export const loadProtocol = createAction(
  "[PROTOCOLS] Load Protocol",
  props<{ sample: Sample }>()
);

export const protocolLoaded = createAction(
  "[PROTOCOLS] Protocol loaded",
  props<{ protocols: ProtocolSpecification[]; counterFilters: Array<string> }>()
);

export const setContext = createAction(
  "[PROTOCOLS] Set Context",
  props<{ context: Context }>()
);

export const selectLabel = createAction(
  "[PROTOCOLS] Select Label",
  props<{ uuid: string }>()
);

export const togglePinnedOptionsOnly = createAction(
  "[PROTOCOLS] Toggle Pinned Options Only"
);

export const pinTaskOption = createAction(
  "[PROTOCOLS] Pin Option",
  props<{ uuid: string }>()
);

export const toggleMultiSelect = createAction("[PROTOCOLS] Toggle Multiselect");

export const hideLabel = createAction(
  "[PROTOCOLS] Hide Label",
  props<{ uuid: string }>()
);

export const resetProtocolState = createAction("[PROTOCOLS] Reset State");

export const selectMultipleLabelIds = createAction(
  "[PROTOCOLS] Select multiple labels",
  props<{ labelIds: string[] }>()
);

export const updateOptionFromCounter = createAction(
  "[PROTOCOLS] Update label option counter contribution",
  props<{ labelId: string }>()
);

export const optionForCounterSaved = createAction(
  "[PROTOCOLS] Label options for counter saved",
  props<{ labelId: string }>()
);

export const setInitialCounterFilters = createAction(
  "[PROTOCOLS] Set initial counter filters from query params",
  props<{ counterFilters: string[]; sampleId: string }>()
);

export const addCustomLabel = createAction(
  "[PROTOCOLS] Add Custom Label",
  props<{ customLabel: NewCustomLabel }>()
);

export const customLabelAdded = createAction(
  "[PROTOCOLS] Custom label saved and added",
  props<{ customLabel: NewCustomLabel }>()
);

export const loadCustomLabels = createAction(
  "[PROTOCOLS] Load Custom Labels",
  props<{ customLabels: CustomLabelInfo[] }>()
);

export const clearAllLabels = createAction(
  "[PROTOCOLS] Clear All Selected Labels"
);

export const setCollapseOption = createAction(
  "[PROTOCOLS] Set collapsed task property",
  props<{ task: string; collapsed: boolean; pipelineId: string }>()
);
export const counterFiltersFromParamsSaved = createAction(
  "[PROTOCOLS] Counter filters from query params saved into state",
  props<{ counterFilters: string[] }>()
);
