import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';

export interface IndexPath {
  section: number;
  row: number;
}

@Component({
  selector: 'ts-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableViewComponent implements OnChanges, OnDestroy {

  @Input() public rowNumeralTemplate: TemplateRef<unknown>;
  @Input() public headerTemplate: TemplateRef<unknown>;
  @Input() public rowTemplate: TemplateRef<unknown>;
  @Input() public sections = 0;
  @Input() public rowsPerSection: number[] = [];
  @Input() public scrollThreshold = 0;
  @Input() public paddingNodes = 1;
  @Input() public locationRow: number;
  @Input() public rowHeight: number;

  @Output() public bottomReached = new EventEmitter<void>();
  @Output() public scrollThresholdReached = new EventEmitter<void>();

  public sectionRows: number[] = [];
  public numberOfRows = 0;
  public currentLocationRow = 0;

  private sectionCache = new Map<number, number>();
  private cacheLimit = 1000;

  constructor(private changeDetector: ChangeDetectorRef) { }

  public ngOnChanges(changes: SimpleChanges) {
    // Only update sections and rows if there are relevant changes.
    const hasSectionChanged = changes['sections'] && changes['sections'].currentValue !== changes['sections'].previousValue;
    const hasRowsChanged = changes['rowsPerSection'] && !this.arrayEqual(changes['rowsPerSection'].currentValue, changes['rowsPerSection'].previousValue);
    const hasRowHeightChanged = changes['rowHeight'] && changes['rowHeight'].currentValue !== changes['rowHeight'].previousValue;

    if (hasSectionChanged || hasRowsChanged) {
      this.sectionCache.clear(); // Clear cache only if input values change
      this.calculateSectionsAndRows();
    }

    if (hasRowHeightChanged) {
      this.calculateSectionsAndRows();
      this.changeDetector.detectChanges();
    }
  }

  public ngOnDestroy(): void {
    this.sectionCache.clear();
  }

  /**
   * Determines if the given rowIndex corresponds to a section header.
   */
  public isSectionRow(rowIndex: number): boolean {
    return this.sectionRows.includes(rowIndex);
  }

  /**
   * Returns the section index for the given rowIndex.
   */
  public getSectionIndexForRow(rowIndex: number): number {
    return this.sectionRows.indexOf(rowIndex);
  }

  /**
   * Returns the section containing the given rowIndex using a binary search
   * and caches the result to avoid redundant lookups.
   */
  public getSectionContainingRow(rowIndex: number): number {
    // Return cached result if available
    if (this.sectionCache.has(rowIndex)) return this.sectionCache.get(rowIndex);

    let low = 0;
    let high = this.sectionRows.length - 1;

    // Binary search for the correct section
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      if (this.sectionRows[mid] <= rowIndex) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }

    const sectionIndex = high;

    // Cache the result
    this.sectionCache.set(rowIndex, sectionIndex);
    this.enforceCacheLimit(); // Ensure the cache doesn't grow too large

    return sectionIndex;
  }

  /**
   * Returns the IndexPath (section and row) for the given rowIndex.
   */
  public getIndexPathForRow(rowIndex: number): IndexPath {
    const sectionIndex = this.getSectionContainingRow(rowIndex);

    if (sectionIndex < 0 || sectionIndex >= this.sectionRows.length) {
      return { section: -1, row: -1 }; // Invalid IndexPath
    }

    return {
      section: sectionIndex,
      row: rowIndex - this.sectionRows[sectionIndex] - 1,
    };
  }

  /**
   * Efficiently calculates the section start indices and total number of rows.
   */
  private calculateSectionsAndRows() {
    if (!this.sections || this.sections <= 0 || !this.rowsPerSection || this.rowsPerSection.length === 0) {
      this.sectionRows = [];
      this.numberOfRows = 0;
      return;
    }

    this.sectionRows = new Array(this.sections);
    this.sectionRows[0] = -1;
    this.numberOfRows = this.sections; // One row for each section header

    for (let i = 0; i < this.sections; i++) {
      this.numberOfRows += this.rowsPerSection[i]; // Add rows for each section
      this.sectionRows[i + 1] = this.sectionRows[i] + this.rowsPerSection[i] + 1; // Account for section header
    }
  }

  /**
   * Compares two arrays for deep equality.
   * @param a First array to compare.
   * @param b Second array to compare.
   */
  private arrayEqual<T>(a: T[], b: T[]): boolean {
    if (!a || !b) return false;
    if (a === b) return true;
    if (a.length !== b.length) return false;
    return a.every((val, index) => val === b[index]);
  }

  /**
   * Limits the cache size to the specified limit by removing the oldest entries.
   */
  private enforceCacheLimit() {
    if (this.sectionCache.size > this.cacheLimit) {
      const firstKey = this.sectionCache.keys().next().value;
      this.sectionCache.delete(firstKey);
    }
  }
}
