import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  Optional,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarAlertComponent } from "@shared/ui";
import { SecondOpinionService } from "@telespot/analysis-refactor/data-access";
import { IAsset } from "@telespot/ui";
import { BehaviorSubject } from "rxjs";

interface DataDialog {
  assets: IAsset[];
  sampleId: string;
  caseId: string;
}

@Component({
  selector: "ts-second-opinion-dialog",
  templateUrl: "./second-opinion-dialog.component.html",
  styleUrls: ["./second-opinion-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecondOpinionDialogComponent implements OnInit {
  private _minAssetSelected = new BehaviorSubject<boolean>(false);
  public get minAssetSelected$() {
    return this._minAssetSelected.asObservable();
  }

  public dialogForm: UntypedFormGroup;

  constructor(
    @Optional() public dialogRef: MatDialogRef<SecondOpinionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataDialog,
    private _snackBar: MatSnackBar,
    private secondOpinionService: SecondOpinionService
  ) {}

  ngOnInit() {
    if (!this.data || !this.data.assets || !this.data.assets.length) return;
    this.dialogForm = new UntypedFormGroup({
      subject: new UntypedFormControl("", [
        Validators.maxLength(40),
        Validators.required,
      ]),
      comments: new UntypedFormControl("", Validators.required),
      guestUsers: new UntypedFormArray(
        [
          new UntypedFormGroup({
            alias: new UntypedFormControl("", Validators.required),
            email: new UntypedFormControl("", Validators.email),
          }),
        ],
        Validators.required
      ),
    });
  }

  public get guestUsersFormArray(): UntypedFormArray {
    return this.dialogForm.get("guestUsers") as UntypedFormArray;
  }

  public addGuestUser() {
    const guestUsers = this.guestUsersFormArray;
    guestUsers.push(
      new UntypedFormGroup({
        alias: new UntypedFormControl("", Validators.required),
        email: new UntypedFormControl("", Validators.email),
      })
    );
  }

  public removeGuestUser(index: number) {
    const guestUsers = this.guestUsersFormArray;
    guestUsers.removeAt(index);
  }

  public receivedData(assets: IAsset[]) {
    this.data.assets = assets;
    this._minAssetSelected.next(this.data.assets.some((a) => a.selected));
  }

  public async accept(): Promise<void> {
    try {
      await this.secondOpinionService.submitSecondOpinionRequest({
        guestUsers: this.dialogForm.value.guestUsers,
        sampleId: this.data.sampleId,
        assets: this.data.assets.filter((a) => a.selected).map((a) => a.id),
        subject: this.dialogForm.value.subject,
        comments: this.dialogForm.value.comments,
        caseId: this.data.caseId,
      });
      this._snackBar.openFromComponent(SnackbarAlertComponent, {
        duration: 2000,
        data: {
          title: `Second opinion sent to ${this.dialogForm.value.guestUsers
            .map((e) => e.email)
            .join(", ")}`,
        },
      });
    } catch (err) {
      this._snackBar.openFromComponent(SnackbarAlertComponent, {
        duration: 2000,
        data: {
          title: `Second opinion request failed: ${err.message}`,
        },
      });
    }
    this.dialogRef.close();
  }
}
