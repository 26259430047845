import { Inject, Injectable, Optional } from '@angular/core';
import { ParseObject, Query } from '@telespot/sdk';
import { LiveQuerySubscription } from 'parse';

import { SEED_DATA } from './seed.data';

@Injectable({
  providedIn: 'root',
})
export class MockDataService {
  constructor(@Optional() @Inject(SEED_DATA) private seed: Array<ParseObject> = []) {}

  async first<T extends ParseObject>(query: Query<T>): Promise<T> {
    try {
      const result = this.seed?.find((o) => o.className === query.className) as T;
      if (result !== undefined) {
        console.log(`%c first ${query.className} (${result.id}) retrieved:`, 'color: purple', result);
      } else {
        console.log(`%c No ${query.className} retrieved:`, 'color: purple', result);
      }
      return Promise.resolve(result);
    } catch (error) {
      console.error(`%c first ${query.className} failed: ${error.message}`, 'color: red');
      throw error;
    }
  }

  async get<T extends ParseObject>(id: string, query: Query<T>): Promise<T> {
    try {
      const result = this.seed
        ? (this.seed.find((item) => item.className === query.className && item.id === id) as T)
        : undefined;
      if (result) {
        console.log(`%c ${query.className} (${result.id}) retrieved:`, 'color: purple', result);
        return result;
      } else {
        throw new Error(`No ${query.className} found with ID ${id}`);
      }
    } catch (error) {
      console.error(`%c get ${query.className} failed: ${error.message}`, 'color: red');
      throw error;
    }
  }

  async find<T extends ParseObject>(query: Query<T>): Promise<T[]> {
    try {
      const results = new Array<T>();
      if (this.seed) {
        results.push(...this.seed.filter((item) => item.className === query.className).map((item) => item as T));
      }
      console.log(`%c ${results.length} ${query.className}(s) retrieved:`, 'color: purple', results);
      return results;
    } catch (error) {
      console.error(`%c find ${query.className} failed: ${error.message}`, 'color: red');
      throw error;
    }
  }

  async count<T extends ParseObject>(query: Query<T>): Promise<number> {
    try {
      const results = 0;
      console.log(`%c ${results} ${query.className}(s) results available:`, 'color: purple', results);
      return results;
    } catch (error) {
      console.error(`%c find ${query.className} failed: ${error.message}`, 'color: red');
      throw error;
    }
  }

  async save<T extends ParseObject>(object: T | T[]): Promise<T | T[]> {
    try {
      const results = object instanceof Array ? object : object;

      if (results instanceof Array && results.length > 0)
        console.log(`%c ${results.length} ${results[0].className}'(s) saved:`, 'color: green', results);
      else console.log(`%c ${results['className']} (${results['id']}) saved:`, 'color: green', results);

      return results;
    } catch (error) {
      if (object instanceof Array)
        console.error(`%c save ${object.map((o) => o.id)} failed: ${error.message}`, 'color: red');
      else console.error(`%c save ${object.id} failed: ${error.message}`, 'color: red');

      throw error;
    }
  }

  async delete<T extends ParseObject>(object: T | T[]): Promise<T | T[]> {
    try {
      const results = object instanceof Array ? object : object;

      if (results instanceof Array && results.length > 0)
        console.log(`%c ${results.length} ${results[0].id}'(s) deleted:`, 'color: green', results);
      else console.log(`%c ${results['className']} (${results['id']}) deleted:`, 'color: green', results);

      return results;
    } catch (error) {
      if (object instanceof Array)
        console.error(`%c delete ${object.map((o) => o.id)} failed: ${error.message}`, 'color: red');
      else console.error(`%c delete ${object.id} failed: ${error.message}`, 'color: red');

      throw error;
    }
  }

  async subscribe<T extends ParseObject>(
    query: Query<T>,
    {
      open,
      close,
      create,
      enter,
      update,
    }: {
      open?: () => void;
      close?: () => void;
      create?: (items: T) => void;
      enter?: (items: T) => void;
      update?: (items: T) => void;
    }
  ): Promise<LiveQuerySubscription> {
    const subscription = await query.subscribe();
    open && subscription.on('open', open);
    create && subscription.on('create', create);
    enter && subscription.on('enter', enter);
    update && subscription.on('update', update);
    close && subscription.on('close', close);
    return subscription;
  }
}
