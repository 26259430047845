<ng-container *ngIf="embedInCard; else embeddedInDialogTemplate">
  <div class="main-container">
    <div class="card">
      <div mat-dialog-title class="card-header">
        <h1>Analysis type editor</h1>
      </div>
      <div class="card-body">
        <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
      </div>
      <div class="card-footer card-actions">
        <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #embeddedInDialogTemplate>
  <div mat-dialog-title class="main-title">
    <h1>Analysis type editor</h1>
  </div>
  <mat-dialog-content>
    <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-around">
    <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
  </mat-dialog-actions>
</ng-template>

<ng-template #formTemplate>
  <div class="card-body">
    <form [formGroup]="form" *ngIf="form">
      <mat-form-field style="width: 100%">
        <input
          matInput
          type="text"
          placeholder="{{ 'label.display_name' | translate | uppercase }}"
          formControlName="displayName"
        />
      </mat-form-field>

      <mat-form-field class="full-width">
        <textarea
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          placeholder="{{ 'help.analysistype_description' | translate | uppercase }}"
          formControlName="description"
        ></textarea>
      </mat-form-field>

      <div>
        <div style="max-height: 340px; overflow: auto">
          <ul class="list-group list-group-flush" cdkDropList (cdkDropListDropped)="drop($event)">
            <li class="list-group-item" *ngFor="let task of form.value.tasks" cdkDrag cdkDragLockAxis="y">
              <span class="task-name">{{ task.displayName }}</span>
              <span class="button-bar stretch" *ngIfRole="['admin']">
                <button class="btn btn-flat-primary btn-auto" type="button" (click)="editField(task)">
                  <i class="ri-pencil-line"></i>
                </button>
                <button class="btn btn-flat-danger btn-auto" type="button" (click)="removeField(task)">
                  <i class="ri-delete-bin-6-line"></i>
                </button>
              </span>
              <div *cdkDragPlaceholder class="drag-placeholder"></div>
            </li>
          </ul>
        </div>
        <button class="btn btn-flat btn-flat-primary btn-auto" (click)="newField()">
          <i class="ri-add-circle-line" style="margin-right: 8px"></i>
          {{ 'Add_field' | translate | uppercase }}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #actionsTemplate>
  <button class="btn btn-outline-primary card-actions__action" (click)="cancel()">
    <i class="ri-close-fill"></i>{{ 'button.cancel' | translate }}
  </button>
  <button class="btn btn-primary card-actions__action" (click)="submit()">
    <i class="ri-save-line"></i>{{ 'button.save' | translate }}
  </button>
</ng-template>
