import { Injectable } from '@angular/core';
import { Asset, RoiModel } from '@telespot/sdk';
import { concat, Observable, of, timer } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { IAssetTile } from '../i-asset-tile.interface';
import { IRoiProvider, IRoiProviderResult } from './i-ai-roi-provider';

@Injectable()
export class FakeROIProvider implements IRoiProvider {
  analyzeAsset(asset: Asset, model: RoiModel, aiModel?: string): Observable<IRoiProviderResult> {
    return of({
      rois: [{ x: 200, y: 300, w: 64, h: 64, assetId: asset?.id, isAIresult: true }],
      progress: 50,
    });
  }

  analyzeTiles(tiles: IAssetTile[], model: RoiModel, aiModel?: string): Observable<IRoiProviderResult> {
    const numPatches = 4;
    const numFakeROIs = 8;
    const results = Array.from(new Array(numPatches).keys())
      .map((_) => [Math.floor(Math.random() + 0.5), Math.floor(Math.random() + 0.5)])
      .map(([gx, gy]) =>
        timer(0 + Math.random() * 500).pipe(
          map((_) =>
            Array.from(new Array(Math.floor(Math.random() * numFakeROIs)).keys()).map((index) => ({
              x: gx * 320 + Math.random() * 320,
              y: gy * 320 + Math.random() * 320,
              w: 16,
              h: 16,
              isAIresult: true,
            }))
          ),
          switchMap((rois) =>
            of({
              rois,
              progress: 50,
            })
          ),
          take(1)
        )
      );
    return concat(...results);
  }
}
